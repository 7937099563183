import axios, { AxiosRequestConfig } from 'axios';
import { AddFishCatchData, FishCatchData } from '../containers/types';

const AIS_SERVER_BASE_PATH = process.env.REACT_APP_AIS_SERVER;

export const PATHS = Object.freeze({
  GET_FISHCATCH_DATA: `fishCatch/get`,
  GET_FISHCATCH_REPORT: `fishCatch/export`,
  ADD_FISHCATCH_DATA: `fishCatch/add`,
  GET_FISHCATCH_FILE: `fishCatch/files`,
  GET_FISHCATCH_RECORDS: `fishCatch/records`,
  ADD_FISHCATCH_FILE: `fishCatch/files`,
  DELETE_FISHCATCH_FILE: `fishCatch/files`,
  DELETE_FISHCATCH_RECORDS: 'fishCatch/records',
  PATCH_FISHCATCH_RECORDS: `fishCatch/records/`,
  POST_FISHCATCH_FILE: `fishCatch/files`,
});

const getFishCatchData = async (
  userName: string,
  fromDate?: string,
  toDate?: string,
  bottomLeft?: string,
  topRight?: string
): Promise<FishCatchData[]> => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: {
        user: userName,
        from_date: fromDate,
        to_date: toDate,
        bottom_left: bottomLeft,
        top_right: topRight,
      },
    };
    const { data: fishCatchData } = await axios.get<FishCatchData[]>(
      AIS_SERVER_BASE_PATH + PATHS.GET_FISHCATCH_DATA,
      config
    );

    return fishCatchData;
  } catch (error) {
    throw error;
  }
};

const getFishCatchReport = async (
  userName: string,
  fromDate: string,
  toDate: string,
  format: string,
  reportFilters: any,
  bottomLeft?: string,
  topRight?: string
): Promise<any> => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: {
        user: userName,
        from_date: fromDate,
        to_date: toDate,
        format: format,
        bottom_left: bottomLeft,
        top_right: topRight,
      },
    };
    const url = await axios.post<any, any>(
      AIS_SERVER_BASE_PATH + PATHS.GET_FISHCATCH_REPORT,
      reportFilters,
      config
    );

    return url;
  } catch (error) {
    throw error;
  }
};

const addFishCatchData = async (
  userName: string,
  fishCatchData: AddFishCatchData[]
) => {
  let config: AxiosRequestConfig = {
    params: {
      user: userName,
    },
  };
  try {
    const { data } = await axios.post(
      AIS_SERVER_BASE_PATH + PATHS.ADD_FISHCATCH_DATA,
      fishCatchData,
      config
    );

    return data;
  } catch (error) {
    throw error;
  }
};

const getFishCatchFile = async (userName: string): Promise<any> => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: {
        user_id: userName,
      },
    };
    const { data: fishCatchDataFile } = await axios.get<any>(
      AIS_SERVER_BASE_PATH + PATHS.GET_FISHCATCH_FILE,
      config
    );

    return fishCatchDataFile;
  } catch (error) {
    throw error;
  }
};

const getFishCatchAllRecords = async (
  userName: string,
  file_id: string
): Promise<any> => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: {
        file_id: file_id,
      },
    };

    const { data: fishCatchRecords } = await axios.get<any>(
      AIS_SERVER_BASE_PATH + PATHS.GET_FISHCATCH_RECORDS,
      config
    );

    return fishCatchRecords;
  } catch (error) {
    throw error;
  }
};

const getFishCatchRecord = async (fileId: string): Promise<any> => {
  try {
    const { data: fishCatchRecord } = await axios.get<any>(
      AIS_SERVER_BASE_PATH + PATHS.GET_FISHCATCH_RECORDS + '/' + fileId
    );

    return fishCatchRecord;
  } catch (error) {
    throw error;
  }
};

const addFishCatchFile = async (userName: string, fishCatchDataFile: any) => {
  let config: AxiosRequestConfig = {
    params: {
      user: userName,
    },
  };

  try {
    const { data } = await axios.post(
      AIS_SERVER_BASE_PATH + PATHS.ADD_FISHCATCH_FILE,
      fishCatchDataFile,
      config
    );

    return data;
  } catch (error) {
    throw error;
  }
};

const postFishCatchFile = async (userName: string, fishCatchData: any) => {
  let config: AxiosRequestConfig = {
    params: {
      user: userName,
    },
  };

  try {
    const { data } = await axios.post(
      AIS_SERVER_BASE_PATH + PATHS.POST_FISHCATCH_FILE,
      fishCatchData,
      config
    );

    return data;
  } catch (error) {
    throw error;
  }
};

const deleteFishCatchFiles = async (userName: string, fileId: string) => {
  let config: AxiosRequestConfig = {
    params: {
      user_id: userName,
    },
  };

  try {
    const { data: deleteFileFishCatch } = await axios.delete<any>(
      AIS_SERVER_BASE_PATH + PATHS.DELETE_FISHCATCH_RECORDS + fileId,
      config
    );

    return deleteFileFishCatch;
  } catch (error) {
    throw error;
  }
};
const deleteFishCatchFile = async (userName: string, fileId: any[]) => {
  let config: AxiosRequestConfig = {
    params: {
      user_id: userName,
    },
    data: {
      file_ids: fileId,
    },
  };

  try {
    const { data: deleteFileFishCatch } = await axios.delete<any>(
      AIS_SERVER_BASE_PATH + PATHS.DELETE_FISHCATCH_FILE,
      config
    );

    return deleteFileFishCatch;
  } catch (error) {
    throw error;
  }
};

const updateFishCatchRecord = async (
  userName: string,
  recordId: string,
  fishCatchDataRecord: any
) => {
  let config: AxiosRequestConfig = {
    params: {
      user_id: userName,
    },
  };

  try {
    const { data: updatedDataFishCatch } = await axios.patch<any>(
      AIS_SERVER_BASE_PATH + PATHS.PATCH_FISHCATCH_RECORDS + recordId,
      fishCatchDataRecord,
      config
    );

    return updatedDataFishCatch;
  } catch (error) {
    throw error;
  }
};

const getFishCatchRecordCSV = async (fileId: string): Promise<any> => {
  try {
    const { data: fishCatchCSV } = await axios.get<any>(
      AIS_SERVER_BASE_PATH +
        PATHS.GET_FISHCATCH_FILE +
        '/' +
        fileId +
        '/download'
    );

    return fishCatchCSV;
  } catch (error) {
    throw error;
  }
};

export interface FishCatchAPIClient {
  readonly getFishCatchData: (
    userName: string,
    fromDate?: string,
    toDate?: string,
    bottomLeft?: string,
    topRight?: string
  ) => Promise<FishCatchData[]>;
  readonly getFishCatchReport: (
    userName: string,
    fromDate: string,
    toDate: string,
    format: string,
    reportFilters: any,
    bottomLeft?: string,
    topRight?: string
  ) => Promise<any>;
  readonly addFishCatchData: (
    userName: string,
    fishCatchData: AddFishCatchData[]
  ) => any;
  readonly postFishCatchFile: (userName: string, fishCatchData: any) => any;
  readonly getFishCatchFile: (userName: string) => Promise<any>;
  readonly getFishCatchAllRecords: (
    userName: string,
    file_id: string
  ) => Promise<any>;
  readonly addFishCatchFile: (userName: string, fishCatchDataFile: any) => any;
  readonly deleteFishCatchFiles: (userName: string, fileId: string) => any;
  readonly deleteFishCatchFile: (userName: string, fileId: any[]) => any;
  readonly updateFishCatchRecord: (
    userName: string,
    recordId: string,
    fishCatchDataRecord: any
  ) => any;
  readonly getFishCatchRecord: (fileId: string) => Promise<any>;
  readonly getFishCatchRecordCSV: (file_id: string) => Promise<any>;
}

const FishCatchClient: FishCatchAPIClient = Object.freeze({
  getFishCatchData,
  getFishCatchReport,
  addFishCatchData,
  getFishCatchFile,
  getFishCatchAllRecords,
  addFishCatchFile,
  deleteFishCatchFiles,
  deleteFishCatchFile,
  updateFishCatchRecord,
  getFishCatchRecord,
  getFishCatchRecordCSV,
  postFishCatchFile,
});

export default FishCatchClient;
