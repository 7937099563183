import React from 'react';
import { Box, Card, Grid, Typography } from '@mui/material';
import { Toggle } from '../Toggle/Toggle';
import { WrapperStyles } from './WrapperStyles';
import { WrapperProps } from './WrapperTypes';

export const Wrapper: React.FC<WrapperProps> = ({
  title,
  titleStyle,
  wrapperStyle,
  showToggle,
  onToggle,
  children,
  checked
}) => {
 
  return (
    <Card sx={{...wrapperStyle}}>
      {(title || showToggle) && (
        <Grid container sx={WrapperStyles.titleContainerStyle}>
          <Grid item md={8}>
            {title && (
              <Typography sx={WrapperStyles.titleStyle}>
                {title}
              </Typography>
            )}
          </Grid>
          <Grid item md={4}>
            {showToggle && (
              <Box sx={WrapperStyles.toggleConatinerStyle}>
                <Toggle checked={checked} onChange={onToggle} />
              </Box>
            )}
          </Grid>
        </Grid>
      )}
      <Box sx={WrapperStyles.childContainerStyle}>{children}</Box>
    </Card>
  );
};

Wrapper.defaultProps = {
  title: '',
  showToggle: false,
  wrapperStyle:  WrapperStyles.wrapperStyle
  
};
