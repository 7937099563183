import { Dispatch, SetStateAction, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import {
  FileFormats,
  FishcatchDBHeaders,
  getEmptyCatchData,
  isEmpty,
  isInvalidDate,
  isInvalidLatLon,
  isInvalidQuantity,
  isInvalidSpecies,
  popUpSteps,
} from '../DashboardConfig';
import { getUserAttributes } from '../../../utils/auth';
import Snackbar from '../../../components/Snackbar/Snackbar';
import FishCatchClient from '../../../api/fishCatchAPIs';
import { useFishCatchDashboardStyles } from '../FishCatchDashboardStyles';
import closeIcon from '../../../assets/icons/close.svg';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../../translations/I18nKey';
import '.././FishCatchDashboard.scss';
import { ImportPopUpDataMapper, MapperFooter } from './ImportPopUpDataMapper';
import { ImportPopupDataUpload, UploadFooter } from './ImportPopupDataUpload';
import { AddFishCatchData } from '../../types';
import { sha256 } from 'crypto-hash';
import { VariantType, closeSnackbar, useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation } from 'react-query';
import { useFetchFishCatch, useNavFishCatch } from '../store/FishCatchStore';
import ErrorIcon from '@mui/icons-material/Error';

export type fileData = string[];

//Integration of getting file metadata
export interface UploadFileMetadata {
  name: string;
  size: number;
  type: string;
  lastModified: number;
}

interface ImportPopUpProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setRefetchData: Dispatch<SetStateAction<boolean>>;
}

interface CatchData {
  catch_date: string;
  vessel_name: string;
  latitude: string;
  longitude: string;
  species: string;
  quantity: string;
  fishing_technique: string;
  catch_details: string;
  total_quantity: string;
  comment: string;
  [key: string]: any; // This allows indexing with a string key
}

type StringKeyOf<T> = Extract<keyof T, string>;
interface FieldInfo {
  index: number;
  field: StringKeyOf<CatchData>;
}

interface ValidationResult {
  isValid: boolean;
  emptyFields: {
    index: number;
    field: string;
  }[];
  wrongFormatFields: {
    index: number;
    field: string;
  }[];
}

const ImportPopUp: React.FC<ImportPopUpProps> = ({
  setOpen,
  setRefetchData,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const nav = useNavFishCatch((state) => state.nav);
  const setReFetchFiles = useFetchFishCatch((state) => state.setReFetchFiles);
  // Stores mapping of CSV data to FishCatchDBHeaders
  const [fishCatchColumnMapping, setFishCatchColumnMapping] = useState<
    Record<string, null | undefined | string>
  >({
    catch_date: null,
    vessel_name: null,
    latitude: null,
    longitude: null,
    species: null,
    quantity: null,
    catch_details: null,
    total_quantity: null,
    comment: null,
    'temp_(c)': null,
    plankton: null,
    ssh: null,
    bathymetry: null,
    salinity: null,
    pressure: null,
    thermocliene_depth: null,
    fishing_technique: null,
    // TODO: Add all these column heads when we add dropdown functionality
    // 'temperature_(25m)': null,
    // 'temperature_(50m)': null,
    // 'temperature_(100m)': null,
    // 'temperature_(150m)': null,
    // 'temperature_(200m)': null,
    // 'temperature_(300m)': null,
    // current_speed: null,
    // current_direction: null,
    // wind_speed: null,
    // wind_direction: null,
    // wave_height: null,
  });
  const [alert, setAlert] = useState({
    type: '',
    display: false,
    message: '',
  });
  const [fileData, setFileData] = useState<fileData[] | []>([]);

  const [fileMetadata, setFileMetadata] = useState<UploadFileMetadata | {}>({});

  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();
  const { userName } = getUserAttributes();
  const steps = popUpSteps();
  const FishCatchDashboardStyles: any = useFishCatchDashboardStyles();
  const [formatErrors, setFormatErrors] = useState<string[]>([]);
  // --- Toast Message ---
  const { enqueueSnackbar } = useSnackbar();

  // --- Call Toast ---
  const action = (snackbarId: any) => {
    return (
      <IconButton
        aria-label="close-toast"
        onClick={() => closeSnackbar(snackbarId)}
      >
        <CloseIcon />
      </IconButton>
    );
  };

  const callToast = (variant: VariantType, message: any) => {
    enqueueSnackbar(message, {
      variant,
      action,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
  };

  const onClosePopUp = () => {
    setOpen(false);
    setActiveStep(0);
  };

  const displayAlert = (type: string, message: string) => {
    setAlert({
      type: type,
      display: true,
      message: message,
    });
    setTimeout(() => {
      setAlert({
        type: '',
        display: false,
        message: '',
      });
    }, 5000);
  };

  const handleNext = () => {
    let tempFishCatchColumnMapping = { ...fishCatchColumnMapping };

    // Auto map fields using regex
    Object.keys(fishCatchColumnMapping).forEach((key) => {
      tempFishCatchColumnMapping[key] =
        fileData[0].find((header) =>
          FishcatchDBHeaders[key].pattern?.test(header)
        ) || null;
    });

    setFishCatchColumnMapping(tempFishCatchColumnMapping);

    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    activeStep > 0 && setActiveStep(activeStep - 1);
    setFormatErrors([]);
    setFileData([]);
    setFileMetadata({});
  };

  //  Upadate the state with the mapped value
  const onMappingSelectionChange = (
    field: string,
    value: { header: string }
  ) => {
    setFishCatchColumnMapping({
      ...fishCatchColumnMapping,
      [field]: value.header,
    });
  };

  // Extract the key to insert the fileData for result.
  const getKeyByValue = (object: any, value: string): string[] | [] =>
    Object.keys(object).filter((key) => object[key] === value);

  // This Function transforms the data to be mapped depending on the mapping selected by the user.
  const transformData = (data: fileData[]): AddFishCatchData[] => {
    let res: AddFishCatchData[] | [] = [],
      refer = data[0],
      catchData = data.slice(1);
    catchData.forEach((row) => {
      let mappedData: any = getEmptyCatchData();
      row.forEach((value, index) => {
        let keys = getKeyByValue(fishCatchColumnMapping, refer[index]);

        if (keys?.length > 0) {
          keys?.forEach((key) => {
            if (FishcatchDBHeaders[key].group === 'catch_conditions') {
              mappedData['catch_conditions'][key] = value || '';
            } else {
              mappedData[key] = value || '';
            }
          });
        }
      });
      if (row.length > 5) {
        mappedData['vessel_id'] = mappedData['vessel_name'];
        res.push(mappedData as never);
      }
    });

    return res;
  };

  // const handleUploadClick = async () => {
  //   console.log("File Data: ", fileMetadata);
  //   console.log("Data Before being transformed: ", fileData);
  //   setIsLoading(true);
  //   let data = transformData(fileData);
  //   //TODO: On this item here must wait for the addFishCatchData API being edited and changed from the original result
  //   console.log("Only Data of the File: ", await sha256(JSON.stringify(data)));
  //   console.log("Data Wished To Be Sent: ", { "fileMetadata" : fileMetadata, "data" : data});
  //   console.log("Whole Data Like Metadata and Data: ", await sha256(JSON.stringify({ "fileMetadata" : fileMetadata, "data" : data})));
  //   try {
  //     await FishCatchClient.addFishCatchData(userName, data);
  //     displayAlert(
  //       'success',
  //       intl.formatMessage({
  //         id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_UPLOAD_SUCCESS,
  //       })
  //     );
  //     setRefetchData(true);
  //     setTimeout(() => onClosePopUp(), 1000);
  //   } catch (err) {
  //     displayAlert(
  //       'error',
  //       intl.formatMessage({
  //         id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_UPLOAD_ERROR,
  //       })
  //     );
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const mutationUploadData: any = useMutation({
    mutationFn: async ({ userName, formatData }: any) => {
      const response = await FishCatchClient.postFishCatchFile(
        userName,
        formatData
      );
      return response;
    },
  });

  function validateData(data: CatchData[] | AddFishCatchData[]): {
    isValid: boolean;
    emptyFields: FieldInfo[];
    wrongFormatFields: FieldInfo[];
  } {
    const fieldsToCheck: StringKeyOf<CatchData>[] = [
      'catch_date',
      'vessel_name',
      'latitude',
      'longitude',
      'species',
      'quantity',
    ];
    let isValid = true;
    const emptyFields: FieldInfo[] = [];
    const wrongFormatFields: FieldInfo[] = [];
    data.forEach((item, index) => {
      fieldsToCheck.forEach((field) => {
        if (isEmpty(item[field])) {
          isValid = false;
          emptyFields.push({ index, field });
        } else {
          //Date format will be verified on BE as FE library does not support dd/mm formats
          // if (field === 'catch_date' && isInvalidDate(item[field])) {
          //   isValid = false;
          //   wrongFormatFields.push({ index, field });
          // }
          if (
            (field === 'latitude' || field === 'longitude') &&
            isInvalidLatLon(item[field])
          ) {
            isValid = false;
            wrongFormatFields.push({ index, field });
          }
          if (field === 'species' && isInvalidSpecies(item[field])) {
            isValid = false;
            wrongFormatFields.push({ index, field });
          }
          if (field === 'quantity' && isInvalidQuantity(item[field])) {
            isValid = false;
            wrongFormatFields.push({ index, field });
          }
        }
      });
    });

    return { isValid, emptyFields, wrongFormatFields };
  }

  // --- Creates the format field for message ---
  function formatFieldName(fieldName: string): string {
    // Split the string on underscores or camelCase
    const words = fieldName.split(/(?=[A-Z])|_/).map(
      (word) =>
        // Capitalize the first letter of each word
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );

    return words.join(' ');
  }

  // --- Recreates the message of validation for the document uploaded ---
  function createValidationMessage(validationResult: ValidationResult): string {
    if (validationResult.isValid) {
      return 'All fields are valid.';
    }

    const uniqueFields = new Set(
      validationResult.emptyFields.map((fieldInfo) => fieldInfo.field)
    );
    const messages = Array.from(uniqueFields)
      .map((field) => `'${formatFieldName(field)}', `)
      .join('');

    return messages;
  }

  //? New Changes of the Metadata
  const handleUploadClick = async () => {
    setFormatErrors([]);
    setIsLoading(true);

    // --- Setting the data to send of the file ---
    let data = transformData(fileData);

    const validation = validateData(data);

    if (validation.isValid === false) {
      if (validation.emptyFields.length) {
        callToast(
          'error',
          `The following fields are required: ${createValidationMessage(
            validation
          )}`
        );
      } else if (validation.wrongFormatFields.length) {
        const uniqueFields = new Set(
          validation.wrongFormatFields.map((fieldInfo) => fieldInfo.field)
        );
        setFormatErrors(Array.from(uniqueFields));
      }

      setIsLoading(false);
    } else {
      // --- Setting the file information metadata ---
      let hashValue = await sha256(JSON.stringify(data));
      const infoFile: UploadFileMetadata | {} = {
        size: (fileMetadata as UploadFileMetadata).size,
        name: (fileMetadata as UploadFileMetadata).name,
        type: (fileMetadata as UploadFileMetadata).type,
        hash: hashValue,
      };

      // Build the file to submit data
      const formatData = {
        metadata: infoFile,
        data: data,
      };

      try {
        await mutationUploadData.mutateAsync({
          userName: userName,
          formatData: formatData,
        });

        displayAlert(
          'success',
          intl.formatMessage({
            id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_UPLOAD_SUCCESS,
          })
        );

        setRefetchData(true);
        if (nav.selection === 'FileTable') setReFetchFiles();
        setTimeout(() => onClosePopUp(), 1000);
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        if (error.response.status === 400) {
          callToast('error', error.response.data.detail);
          console.error(error.response);
        } else {
          callToast('error', 'Error while uploading data, please try again!');
          console.error('Error Message: ', error.message);
          return;
        }
      }
    }
  };

  // Returns the name of the column headers in the CSV file.
  const getMappingData = () => {
    const data = fileData[0].map((header) => {
      let obj: any = {};
      obj['header'] = header;
      return obj;
    });
    return data;
  };

  const popUpBodyComponents = [
    <ImportPopupDataUpload
      displayAlert={displayAlert}
      setFileData={setFileData}
      setFileMetadata={setFileMetadata}
    />,
    <ImportPopUpDataMapper
      fishCatchColumnMapping={fishCatchColumnMapping}
      onMappingSelectionChange={onMappingSelectionChange}
      getMappingData={getMappingData}
    />,
  ];

  const popUpFooterComponents = [
    <UploadFooter fileData={fileData} handleNext={handleNext} />,
    <MapperFooter
      fishCatchColumnMapping={fishCatchColumnMapping}
      handleBack={handleBack}
      handleUploadClick={handleUploadClick}
      isLoading={isLoading}
    />,
  ];

  return (
    <>
      {alert.display && (
        <Snackbar
          type={alert.type}
          display={alert.display}
          message={alert.message}
        ></Snackbar>
      )}
      {
        // Header
        <Box className={FishCatchDashboardStyles.importPopUp}>
          <Box className={FishCatchDashboardStyles.popUpHeaderTitle}>
            <Box className={FishCatchDashboardStyles.popUpHeader}>
              <Typography variant="h6">
                {intl.formatMessage({
                  id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_POPUP_TITLE,
                })}
              </Typography>
            </Box>
            <Button
              className={FishCatchDashboardStyles.closePopUp}
              onClick={onClosePopUp}
              startIcon={
                <img
                  className={FishCatchDashboardStyles.closePopUpImage}
                  src={closeIcon}
                  alt={intl.formatMessage({
                    id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_CLOSE,
                  })}
                />
              }
            ></Button>
          </Box>
          <hr className={FishCatchDashboardStyles.popUpLineBreak} />

          {/* Body */}
          <Box className={FishCatchDashboardStyles.popUpBodyWrapper}>
            <Box className={FishCatchDashboardStyles.popUpBody}>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                className={FishCatchDashboardStyles.stepperAlignment}
              >
                {Object.keys(steps).map((key, index) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};
                  return (
                    <Step active={+key === activeStep} key={key} {...stepProps}>
                      <StepLabel
                        className={
                          +key === activeStep
                            ? `${FishCatchDashboardStyles['active-step-label']}`
                            : ``
                        }
                        StepIconComponent={steps[index].icon}
                        {...labelProps}
                      >
                        {intl.formatMessage({
                          id: steps[index].label,
                        })}
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {popUpBodyComponents[activeStep]}
            </Box>
          </Box>

          {/* Footer */}
          <Box className={FishCatchDashboardStyles.popUpFooterWrapper}>
            <Grid container spacing={2}>
              {popUpFooterComponents[activeStep]}
            </Grid>
          </Box>
        </Box>
      }
      <Dialog
        open={formatErrors.length > 0}
        onClose={() => setFormatErrors([])}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: '#f6e2e2',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Stack direction="row" alignItems="center" gap={1} marginTop={2}>
            <ErrorIcon color="error" />
            <Typography style={{ color: '#d32f2f' }}>
              <b> File data does not match required format!</b>
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <List sx={{ listStyleType: 'disc' }}>
              {formatErrors.map((f: string) => {
                return (
                  <ListItem
                    sx={{
                      display: 'list-item',
                      color: '#d32f2f',
                      marginLeft: '20px',
                    }}
                  >
                    <Typography style={{ color: '#d32f2f' }}>
                      {`Please enter ${formatFieldName(
                        f
                      )} in any of the following format(s):`}
                      <br />
                      <b>{FileFormats[f]}</b>
                    </Typography>
                  </ListItem>
                );
              })}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setFormatErrors([])}
            sx={{
              color: '#d32f2f',
              '&:hover': { backgroundColor: '#b498a05c' },
            }}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImportPopUp;
