import { FC, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

const FishCatchLoadingList: React.FC<any> = ({ option1, option2 }) => {
  return (
    <>
      {option1 === true && (
        <Stack
          spacing={1}
          style={{
            marginBottom: '5px',
            marginRight: '5px',
            marginLeft: '5px',
          }}
        >
          <Skeleton variant="rectangular" height={50} />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={50} />
        </Stack>
      )}

      {option2 === true && (
        <Stack
          spacing={3}
          style={{
            marginBottom: '10px',
            marginRight: '50px',
            marginLeft: '10px',
            marginTop: '1rem',
          }}
        >
          <Skeleton
            variant="rectangular"
            height={100}
            style={{ borderRadius: '10px' }}
          />
          <Skeleton
            variant="rectangular"
            height={100}
            style={{ borderRadius: '10px' }}
          />
          <Skeleton
            variant="rectangular"
            height={100}
            style={{ borderRadius: '10px' }}
          />
          <Skeleton
            variant="rectangular"
            height={100}
            style={{ borderRadius: '10px' }}
          />
          <Skeleton
            variant="rectangular"
            height={100}
            style={{ borderRadius: '10px' }}
          />
          <Skeleton
            variant="rectangular"
            height={100}
            style={{ borderRadius: '10px' }}
          />
          <Skeleton
            variant="rectangular"
            height={100}
            style={{ borderRadius: '10px' }}
          />
          <Skeleton
            variant="rectangular"
            height={100}
            style={{ borderRadius: '10px' }}
          />
          <Skeleton
            variant="rectangular"
            height={100}
            style={{ borderRadius: '10px' }}
          />
        </Stack>
      )}
    </>
  );
};

export default FishCatchLoadingList;
