import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import collapse from '../../assets/icons/collapse.svg';
import expand from '../../assets/icons/expand.svg';
import { theme } from '../../theme';

export const IUUDashboardStyles: any = {
  autocompleteStyle: {
    width: '235px',
    backgroundColor: theme.palette.appBackground?.darkest,
  },
  centerElement: {
    centerElement: {
      fontWeight: 'normal',
      color: theme.palette.text.darkest,
      height: '38px',
      bottom: ' 0',
      position: 'fixed',
      borderTop: '1px solid ' + theme.palette.appBackground?.lighter,
      paddingTop: '3px',
      display: 'initial',
      marginLeft: '-0px !important',
    },
  },
  footer: {
    footer: {
      borderTop: '1px solid ' + theme.palette.appBackground?.lighter,
      display: 'flex',
      width: '100%',
      backgroundColor: theme.palette.appBackground?.lightest,
      marginBottom: '0px',
    },
  },
  section: {
    section: {
      padding: '8px',
      paddingTop: '7px',
      paddingLeft: '14px',
      display: 'flex',
      backgroundColor: theme.palette.appBackground?.lightest,
      color: theme.palette.text.darkest,
      opacity: '0.88',
      borderRadius: 'none',
      paddingBottom: '5px',
    },
  },
  dateRangePickerStyles: {
    color: '#000',
    paddingTop: '17px',
    position: 'relative',
    right: '17px',
  },
  dataTableStyles: {
    '& .tableHeaderStyle': {
      color: theme.palette.appBackground?.lightest,
      backgroundColor: theme.palette.popUpBackground?.main,
    },
    '& .tableCellStyle:focus': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: theme.palette.shadow?.main + ' !important',
    },
    '& .MuiDataGrid-menuIconButton': {
      color: theme.palette.appBackground?.lightest,
    },
    '& .MuiDataGrid-sortIcon': {
      color: theme.palette.appBackground?.lightest,
    },
    padding: '0 !important',
    '& .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
  },
  datePickerLabelExtraStyles: {
    borderRadius: '9px',
    boxShadow: '0px 3px 6px ' + theme.palette.shadow?.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'space-between',
    padding: '8px',
  },
};

export const useIUUDashboardStyles = makeStyles((theme: Theme) => {
  return {
    summaryBoxStyle: {
      height: '260px',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    summaryContainerStyle: {
      display: 'flex',
      width: '90%',
      justifyContent: 'space-around',
      borderRadius: 1,
      paddingTop: '52px',
      height: '97px',
      alignItems: 'center',
    },
    summaryLeftContainerStyle: {
      width: '30%',
      borderRight: '2px solid ' + theme.palette.appBackground?.lightest,
    },
    summaryLeftBoxStyle: {
      display: 'flex',
      width: '100%',
      borderRadius: 1,
      height: '97px',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
    },
    summaryLeftElementStyle: {
      height: '44px',
      width: '56px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    summaryRightElementStyle: {
      flexDirection: 'column',
      height: '100%',
      width: '133px',
      borderRadius: 1,
      paddingLeft: '36px',
    },
    summaryTopTextStyle: {
      fontSize: '20px',
      color: theme.palette.text.lightest,
    },
    summaryBottomTextStyle: {
      fontSize: '45px',
      color: theme.palette.text.lightest,
    },
    summaryCenterContainerStyle: {
      width: '30%',
      borderRight: '2px solid ' + theme.palette.appBackground?.lightest,
    },
    summaryRightContainerStyle: {
      alignContent: 'center',
      width: '30%',
    },
    summaryCenterElementStyle: {
      flexDirection: 'column',
      height: '100%',
      width: '360px',
      borderRadius: 1,
      paddingLeft: '36px',
    },
    summaryCenterBoxStyle: {
      flexDirection: 'column',
      height: '100%',
      width: '320px',
      borderRadius: 1,
      paddingLeft: '36px',
    },
    mainContainerStyle: {
      backgroundColor: theme.palette.appBackground?.lighter,
    },
    filterContainerStyle: {
      position: 'absolute',
      top: '115px',
      right: '0px',
      width: '171px',
      height: '45px',
      background: theme.palette.popUpBackground?.main,
      borderRadius: '9px 0px 0px 9px',
      padding: '0px',
      textAlign: 'left',
      justifyContent: 'left',
      '&:hover': {
        background: theme.palette.popUpBackground?.main,
      },
    },
    filterCloseText: {
      paddingLeft: '23px',
      marginRight: '47px',
    },
    imageStyle: {
      float: 'right',
      height: '30px',
      width: '30px',
    },
    filterHeadingStyle: {
      color: theme.palette.appBackground?.lightest,
      paddingLeft: '19px',
      fontSize: '20px',
      marginRight: '113px',
    },
    filterDrawerStyle: {
      backgroundColor: theme.palette.popUpBackground?.main,
      color: theme.palette.appBackground?.lightest,
    },
    filterBoxStyle: {
      backgroundColor: theme.palette.popUpBackground?.main,
      color: theme.palette.appBackground?.lightest,
      height: '100%',
      boxShadow: '-3px 0px 6px ' + theme.palette.shadow?.main,
      width: '275px',
      display: 'flex',
      flexDirection: 'column',
    },
    widgetBoxStyle: {
      marginTop: '-115px',
      zIndex: 25,
      marginLeft: '75px',
      marginRight: '75px',
    },
    resetBoxStyle: {
      paddingLeft: '30px',
      backgroundColor: 'none',
      color: theme.palette.text.light,
    },
    chartCardStyle: {
      border: '1px solid ' + theme.palette.appBackground?.lighter,
      height: '494px',
      position: 'relative',
      paddingBottom: '85px',
    },
    mapCardStyle: {
      border: '1px solid ' + theme.palette.appBackground?.lighter,
      height: '700px',
      position: 'relative',
    },
    chartHeaderStyle: {
      borderBottom: '1px solid #D3D0D0',
      height: '74px',
      padding: 0,
      paddingLeft: '25px',
    },
    autocompleteStyle: {
      width: '235px',
      backgroundColor: theme.palette.appBackground?.darkest,
    },
    bottomBoxStyle: {
      position: 'absolute',
      bottom: 0,
      fontSize: '18px',
      paddingLeft: '28px',
    },
    applyButtonStyle: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.darkest,
      height: '50px',
      width: '220px',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.darkest,
        height: '50px',
        width: '220px',
        cursor: 'pointer',
      },
    },
    dateRangePickerStyles: {
      color: '#000',
      paddingTop: '17px',
      position: 'relative',
      right: '17px',
    },
    btnResizeCollapse: {
      position: 'absolute',
      width: '27px',
      height: '27px',
      right: '20px',
      top: '98px',
      color: theme.palette.text.lightest,
      zIndex: 99,
      backgroundImage: 'url(' + collapse + ')',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    btnResizeExpand: {
      position: 'absolute',
      width: '27px',
      height: '27px',
      right: '20px',
      top: '98px',
      color: theme.palette.text.lightest,
      zIndex: 99,
      backgroundImage: 'url(' + expand + ')',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    dataTableStyles: {
      '& .tableHeaderStyle': {
        color: theme.palette.appBackground?.lightest,
        backgroundColor: theme.palette.popUpBackground?.main,
      },
      '& .tableCellStyle:focus': {
        outline: 'none !important',
      },
      '& .MuiDataGrid-row.Mui-selected': {
        backgroundColor: theme.palette.shadow?.main + ' !important',
      },
      '& .MuiDataGrid-menuIconButton': {
        color: theme.palette.appBackground?.lightest,
      },
      '& .MuiDataGrid-sortIcon': {
        color: theme.palette.appBackground?.lightest,
      },
      padding: '0 !important',
      '& .MuiDataGrid-columnSeparator': {
        visibility: 'hidden',
      },
    },
    datePickerLabelExtraStyles: {
      borderRadius: '9px',
      boxShadow: '0px 3px 6px ' + theme.palette.shadow?.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'space-between',
      padding: '8px',
    },
    commonFlexCenterStyles: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.appBackground?.lightest,
    },
    'apply-btn-wrapper': {
      '& button.Mui-disabled': { background: theme.palette.primary.disabled },
    },
    actionButton: {
      position: 'absolute',
      bottom: '11px',
      backgroundColor: 'orange !important',
      color: 'black !important',
      fontWeight: '600',
      borderRadius: '9px !important',
      width: '140px !important',
      height: '38px !important',
      marginTop: '11px !important',
      marginRight: '19px !important',
      '&:hover': {
        backgroundColor: 'orange !important',
        color: 'black !important',
      },
    },
  };
});
