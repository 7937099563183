import React from 'react';
import Grid from '@mui/material/Grid';
import { Box, Button, Link } from '@mui/material';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../../translations/I18nKey';
import { useStyles } from '../../../utils/util';
import { PopupFooterProps } from '../SettingsPopupTypes';
import { popupFooterStyles } from './PopupFooterStyles';
import reset from './../../../assets/icons/clear_all_grey.svg';

export const PopupFooter: React.FC<PopupFooterProps> = ({
  footerStyle,
  saveAsDefault,
  resetDefault,
  actionButton,
}) => {
  const intl = useIntl();
  const classes = useStyles(popupFooterStyles, [footerStyle])();

  return (
    <>
      <Box className={classes.footer}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {saveAsDefault && (
              <Link
                component="button"
                underline="always"
                classes={{ root: classes.saveLink }}
                onClick={saveAsDefault?.onClick}
              >
                {intl.formatMessage({ id: saveAsDefault.text })}
              </Link>
            )}
          </Grid>
          <Grid item xs={3}>
            {resetDefault && (
              <Link
                component="button"
                underline="always"
                classes={{ root: classes.resetLink }}
                onClick={resetDefault?.onClick}
              >
                <img
                  className={classes.resetImage}
                  src={reset}
                  alt={resetDefault?.text}
                />
                {intl.formatMessage({ id: resetDefault.text })}
              </Link>
            )}
          </Grid>
          <Grid item xs={3}>
            {actionButton && (
              <Button
                variant="contained"
                disableElevation
                classes={{ root: classes.actionButton }}
                onClick={actionButton?.onClick}
              >
                {intl.formatMessage({ id: actionButton.text })}
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
