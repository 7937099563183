import { Dispatch, FC, SetStateAction } from 'react';

import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useCSVReader } from 'react-papaparse';
import { Cancel, FileOpen } from '@mui/icons-material';

import { I18nKey } from '../../../translations/I18nKey';
import { fileData, UploadFileMetadata } from './ImportPopUp';
import { useFishCatchDashboardStyles } from '../FishCatchDashboardStyles';

interface ImportPopupDataUploadProps {
  setFileData: Dispatch<SetStateAction<[] | fileData[]>>;
  displayAlert: (type: string, message: string) => void;
  setFileMetadata: Dispatch<SetStateAction<{} | UploadFileMetadata>>;
}

interface UploadFooterProps {
  fileData: fileData[] | [];
  handleNext: () => void;
}

export const ImportPopupDataUpload: FC<ImportPopupDataUploadProps> = ({
  setFileData,
  displayAlert,
  setFileMetadata,
}) => {
  const intl = useIntl();
  const { CSVReader } = useCSVReader();
  const FishCatchDashboardStyles: any = useFishCatchDashboardStyles();

  return (
    <>
      <CSVReader
        onUploadAccepted={(results: any) => {
          setFileData(results.data);
        }}
        onUploadRejected={() =>
          displayAlert(
            'error',
            intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_CSV_UPLOAD_ERROR,
            })
          )
        }
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
        }: any) => {
          if (acceptedFile !== null && acceptedFile && acceptedFile.name) {
            setFileMetadata(acceptedFile);
          }
          return (
            <>
              <Box className={FishCatchDashboardStyles.csvReader}>
                <Box className={FishCatchDashboardStyles.acceptedFile}>
                  {acceptedFile && acceptedFile.name ? (
                    acceptedFile.name
                  ) : (
                    <Typography
                      className={FishCatchDashboardStyles.placeholderText}
                    >
                      {intl.formatMessage({
                        id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_PLACEHOLDER,
                      })}
                    </Typography>
                  )}
                </Box>
                {acceptedFile && acceptedFile.name ? (
                  <Tooltip
                    title={intl.formatMessage({
                      id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_REMOVE_FILE,
                    })}
                  >
                    <Button
                      {...getRemoveFileProps()}
                      className={
                        FishCatchDashboardStyles['upload-csv-action-btn']
                      }
                    >
                      <Cancel />
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={intl.formatMessage({
                      id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_BROWSE_FILE,
                    })}
                  >
                    <Button
                      type="button"
                      {...getRootProps()}
                      className={
                        FishCatchDashboardStyles['upload-csv-action-btn']
                      }
                    >
                      <FileOpen />
                    </Button>
                  </Tooltip>
                )}
              </Box>
              <ProgressBar
                className={FishCatchDashboardStyles.progressBarBackgroundColor}
              />
            </>
          );
        }}
      </CSVReader>
    </>
  );
};

export const UploadFooter: FC<UploadFooterProps> = ({
  fileData,
  handleNext,
}) => {
  const intl = useIntl();
  const FishCatchDashboardStyles: any = useFishCatchDashboardStyles();

  return (
    <Grid item xs={12}>
      <Button
        className={`${FishCatchDashboardStyles.popUpFloatRight}
          ${FishCatchDashboardStyles.popUpWarningColor}`}
        variant="contained"
        disabled={fileData.length === 0}
        onClick={handleNext}
      >
        {intl.formatMessage({
          id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_NEXT,
        })}
      </Button>
    </Grid>
  );
};
