export const AutocompleteStyles = {
  clearAllBox: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    fontSize: '14px',
    font: 'normal normal 14px/17px Roboto',
    letterSpacing: '0px',
    color: ' #9d9d9d',
    marginTop: '-29px',
    paddingRight: ' 44px',
  },

  clearAllIcon: {
    marginLeft: '4px',
  },

  clearText: {
    cursor: 'pointer',
    display: 'flex',
  },
};
