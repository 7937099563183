import React, { Fragment, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../../../translations/I18nKey';
import { Icon } from '../../../../components/Icon/Icon';
import { PopupFooter } from '../../../../components/SettingsPopup/PopupFooter/PopupFooter';
import { PopupHeader } from '../../../../components/SettingsPopup/PopupHeader/PopupHeader';
import { SettingsDraggablePopup } from '../../../../components/SettingsPopup/SettingsPopup';
import { FeaturesContext } from '../../FeaturesContext';
import { productSettingDialogBoxStyles } from './ProductSettingsStyles';
import RasterSetting from './RasterSetting';
import ContourSettings from './ContourSettings';
import VectorSettings from './VectorSettings';
import { useContextSelector } from 'use-context-selector';

const ProductSettingDialogBox: React.FC<any> = ({
  onCloseProductSettings,
  selectedProducts,
  productList,
  defaultProductsClick,
  allProducts,
}) => {
  const intl = useIntl();
  const draggleRef = useRef<HTMLDivElement>();
  const [state, setState] = useState({
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, right: 0, bottom: 0 },
    showFooter: false,
  });
  const tileData = useContextSelector(
    FeaturesContext,
    (state) => state.tileData
  );
  const userData = useContextSelector(
    FeaturesContext,
    (state) => state.userData
  );
  const [productsClick, setProductsClick] = useState<any>(defaultProductsClick);
  const [sortedProductList, setSortedProductList] = useState<any[]>([]);

  useEffect(() => {
    if (!_.isEmpty(productList)) {
      setProductsClick({ ...defaultProductsClick, [productList[0]]: true });
    }
  }, []);

  useEffect(() => {
    let sortedList = _.sortBy(productList, function (item) {
      return allProducts[item].productDialogOrder;
    });
    setSortedProductList(sortedList);
    setProductsClick({ ...defaultProductsClick, [sortedList[0]]: true });
  }, [productList]);

  return (
    <SettingsDraggablePopup
      popupStyle={productSettingDialogBoxStyles.popup}
      headerElement={
        <PopupHeader
          title={I18nKey.PRODUCT_SETTING_DIALOG_BOX}
          onClose={onCloseProductSettings}
          dataElement={
            <Box sx={productSettingDialogBoxStyles.buttonRow}>
              {allProducts &&
                sortedProductList.map((product: string, index: number) => {
                  return (
                    <Box key={product} sx={{ marginLeft: '1%' }}>
                      <Icon
                        iconName={allProducts?.[product]?.displayIcon}
                        onClick={function () {
                          setProductsClick({
                            ...defaultProductsClick,
                            [product]: !productsClick[product],
                          });
                        }}
                        clicked={productsClick[product]}
                      ></Icon>
                    </Box>
                  );
                })}
            </Box>
          }
        ></PopupHeader>
      }
      bodyElement={
        <>
          {Object.keys(productsClick).map((product: any) => {
            return (
              productsClick[product] && (
                <Fragment key={`product_setting_${product}`}>
                  <Typography
                    key={`topograpghy_${product}`}
                    sx={{
                      fontSize: '16px',
                      color: '#B8B8B8',
                      marginLeft: '3%',
                    }}
                  >
                    {intl.formatMessage({
                      id: I18nKey[`PRODUCTS_${product}`],
                    })}
                  </Typography>
                  {selectedProducts.raster.includes(product) && (
                    <RasterSetting
                      key={`raster_${product}`}
                      tileData={tileData[product]?.raster?.tile}
                      userData={userData[product]}
                      product={product}
                    />
                  )}
                  {selectedProducts.contour.includes(product) && (
                    <>
                      <br />
                      <ContourSettings
                        key={`contour_${product}`}
                        tileData={tileData[product]?.contour}
                        userData={userData[product]}
                        product={product}
                      />
                    </>
                  )}
                  {selectedProducts.vector.includes(product) && (
                    <>
                      <br />
                      <VectorSettings
                        key={`vector_${product}`}
                        tileData={tileData[product]?.vector}
                        userData={userData[product]?.vector}
                        product={product}
                      />
                    </>
                  )}
                </Fragment>
              )
            );
          })}
        </>
      }
      footerElement={
        <PopupFooter
        //resetDefault={settingsFooter.reset}
        //actionButton={settingsFooter.action}
        ></PopupFooter>
      }
      draggableRef={draggleRef}
      dragState={state}
      setDragState={setState}
    ></SettingsDraggablePopup>
  );
};

export default ProductSettingDialogBox;
