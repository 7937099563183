import React from 'react';
import { Box } from '@mui/material';
import { PopupProps } from './SettingsPopupTypes';
import { settingsPopupStyles } from './SettingsPopupStyles';
import Draggable, {
  DraggableData,
  DraggableEvent,
  DraggableEventHandler,
} from 'react-draggable';
import OpenWithIcon from '@mui/icons-material/OpenWith';
export const SettingsDraggablePopup: React.FC<PopupProps> = ({
  popupStyle,
  headerElement,
  bodyElement,
  footerElement,
  draggableRef,
  dragState,
  setDragState,
}) => {
  const handleOnStart: DraggableEventHandler = (
    e: DraggableEvent,
    uiData: DraggableData
  ) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggableRef.current?.getBoundingClientRect();
    if (targetRect) {
      setDragState((prevState: any) => ({
        ...prevState,
        bounds: {
          left: -targetRect.left + uiData.x,
          right: clientWidth - (targetRect.right - uiData.x),
          top: -targetRect.top + uiData.y,
          bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        },
      }));
    }
  };

  return (
    <Draggable
      onStart={handleOnStart}
      bounds={dragState.bounds}
      handle=".handle"
      nodeRef={draggableRef}
    >
      <Box
        sx={{
          ...settingsPopupStyles.wrapper,
          ...popupStyle.wrapper,
        }}
        id="settingsPopup"
        ref={draggableRef}
      >
        <div style={settingsPopupStyles.dragSection} className="handle">
          <OpenWithIcon sx={settingsPopupStyles.dragIcon} />
        </div>
        {headerElement && headerElement}
        {bodyElement && bodyElement}
        {footerElement && <Box sx={{ marginTop: '10vh' }}>{footerElement}</Box>}
      </Box>
    </Draggable>
  );
};

export const SettingsPopup: React.FC<PopupProps> = ({
  popupStyle,
  headerElement,
  bodyElement,
  footerElement,
}) => {
  return (
    <Box
      sx={{
        ...settingsPopupStyles.wrapper,
        ...popupStyle.wrapper,
      }}
      id="settingsPopup"
    >
      {headerElement && headerElement}
      {bodyElement && bodyElement}
      {footerElement && <Box sx={{ marginTop: '10vh' }}>{footerElement}</Box>}
    </Box>
  );
};
