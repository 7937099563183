import { useState } from 'react';
import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ImageList,
  Typography,
} from '@mui/material';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../translations/I18nKey';
import { useStyles } from '../../utils/util';
import { FeaturesContext } from '../../containers/Map/FeaturesContext';
import { IconGridProps } from './IconGridTypes';
import { Icon } from '../Icon/Icon';
import { iconGridStyles } from './IconGridStyles';
import { useContextSelector } from 'use-context-selector';
import { OtherFeaturesContext } from '../../containers/Map/OtherFeaturesContext';

export const IconGrid: React.FC<IconGridProps> = ({
  categoryData,
  expandIcon,
  expandIconStyle,
  gridStyle,
  headerStyle,
  contentStyle,
}) => {
  const intl = useIntl();
  const classes = useStyles(iconGridStyles, [
    { expandIconStyle },
    { gridStyle },
    { headerStyle },
    { contentStyle },
  ])();
  const setClickedProduct = useContextSelector(
    FeaturesContext,
    (state) => state.setClickedProduct
  );
  const setClickedMultiProduct = useContextSelector(
    FeaturesContext,
    (state) => state.setClickedMultiProduct
  );
  const setClickedNonProduct = useContextSelector(
    OtherFeaturesContext,
    (state) => state.setClickedProduct
  );
  const categoryList = useContextSelector(
    FeaturesContext,
    (state) => state.categoryList
  );

  const [expandedCategory, setExpandedCategory] = useState<string>('');
  let multiplot: any = {};
  if (categoryData?.data)
    Object.values(categoryData?.data).forEach((data) => {
      Object.values(data.items).forEach((i: any) => {
        if (i?.extraConfigs?.multiPlot) {
          i.type.split(',').forEach((t: any) => {
            multiplot[i.iconName[t]] = {
              visited: false,
              multiplot: i.extraConfigs.multiPlot,
              title: i.iconName[t].split('.')[0],
              clicked: false,
            };
          });
        }
      });
    });

  const onCategoryChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedCategory(isExpanded ? panel : '');
    };

  const onButtonClick =
    (category: any, code: string, type?: string) =>
    (event: React.SyntheticEvent) => {
      if (type) setClickedProduct(category, code, type);
      else setClickedNonProduct(category, code);
    };

  const onMultiplotButtonClick =
    (product: any, category: any, code: string) =>
    (event: React.SyntheticEvent) => {
      if (getClicked(product, code, multiplot[code])) {
        setClickedMultiProduct(category, code, multiplot, false);
      } else setClickedMultiProduct(category, code, multiplot, true);
    };

  const getClicked = (prod: any, code: any, multiplotProd: any) => {
    let selected = multiplotProd.multiplot
      .split(',')
      .find(
        (p: any) =>
          categoryData?.data[prod.category]?.items[p.trim().split('-')[0]][
            p.trim().split('-')[1]
          ].selected === true
      );
    multiplot[code].clicked = selected ? true : false;
    return multiplot[code].clicked;
  };

  return (
    <>
      {categoryList?.map((category: any) => {
        if (categoryData && categoryData.data[category].visible) {
          return (
            <Accordion
              key={`${category}category`}
              expanded={expandedCategory === category}
              onChange={onCategoryChange(category)}
              classes={{ root: classes.gridStyle }}
            >
              <AccordionSummary
                classes={{
                  root: classes.headerStyle,
                  expanded: classes.panelExpanded,
                }}
                expandIcon={React.createElement(expandIcon, {
                  className: classes.expandIconStyle,
                })}
              >
                <Typography>
                  {intl.formatMessage({
                    id: I18nKey[`CATEGORY_${category.toUpperCase()}`],
                  })}
                </Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.contentStyle }}>
                <ImageList>
                  {categoryData.data[category].type === 'others'
                    ? categoryData.data[category].productSortedList.map(
                        (item: any, index: number) => {
                          const product =
                            categoryData.data[category].items[item];
                          return !product ? (
                            <EmptyIcon item={item} key={`empty${item}`} />
                          ) : (
                            <Icon
                              title={intl.formatMessage({
                                id: I18nKey[
                                  `OTHER_PRODUCTS_${item.toUpperCase()}`
                                ],
                              })}
                              iconPlacement="left" // icon's placement in grid can be left or right
                              iconName={product.otherConfigs.iconName}
                              clicked={product.selected}
                              key={item}
                              disabled={
                                product.otherConfigs?.dummy ? true : false
                              }
                              onClick={onButtonClick(category, item)}
                              index={index}
                            />
                          );
                        }
                      )
                    : categoryData.data[category].productSortedList.map(
                        (item: any) => {
                          const product =
                            categoryData.data[category].items[item];
                          let types = product?.type.split(',');

                          return !product ? (
                            <EmptyIcon item={item} key={`empty${item}`} />
                          ) : (
                            types.map((type: any, index: number) => {
                              let multiplotObj: any =
                                multiplot[product.iconName[type]];
                              if (multiplotObj) {
                                if (!multiplotObj?.visited && product.visible) {
                                  multiplotObj.visited = true;
                                  return (
                                    <Icon
                                      title={intl.formatMessage({
                                        id: I18nKey[
                                          `PRODUCTS_${multiplotObj?.title.toUpperCase()}`
                                        ],
                                      })}
                                      iconPlacement="left" // icon's placement in grid can be left or right
                                      iconName={product.iconName[type]}
                                      clicked={getClicked(
                                        product,
                                        product.iconName[type],
                                        multiplotObj
                                      )}
                                      key={`${product.code}${type}`}
                                      disabled={product.disabled}
                                      onClick={onMultiplotButtonClick(
                                        product,
                                        category,
                                        product.iconName[type]
                                      )}
                                      index={product.displayOrder - 1}
                                    />
                                  );
                                }
                              } else {
                                if (
                                  product.visible ||
                                  product.extraConfigs.dummy
                                ) {
                                  return (
                                    <Icon
                                      title={intl.formatMessage({
                                        id: I18nKey[
                                          `PRODUCTS_${product.code.toUpperCase()}`
                                        ],
                                      })}
                                      iconPlacement="left" // icon's placement in grid can be left or right
                                      iconName={product.iconName[type]}
                                      clicked={product[type]?.selected}
                                      key={`${product.code}${type}`}
                                      disabled={
                                        product.extraConfigs.dummy
                                          ? true
                                          : product.disabled
                                      }
                                      onClick={onButtonClick(
                                        category,
                                        product.code,
                                        type
                                      )}
                                      index={
                                        types.length === 1
                                          ? product.displayOrder - 1
                                          : index
                                      }
                                    />
                                  );
                                }
                              }
                            })
                          );
                        }
                      )}
                </ImageList>
              </AccordionDetails>
            </Accordion>
          );
        }
      })}
    </>
  );
};

IconGrid.defaultProps = {
  categoryData: { data: {} },
  expandIcon: KeyboardDoubleArrowUpIcon,
  expandIconStyle: iconGridStyles.expandIconStyle,
  gridStyle: iconGridStyles.gridStyle,
  headerStyle: iconGridStyles.headerStyle,
  contentStyle: iconGridStyles.contentStyle,
};

const EmptyIcon: React.FC<any> = (item: any) => {
  const classes = useStyles(iconGridStyles, [])();
  return <Box key={`${item}empty`} className={classes.empty}></Box>;
};
