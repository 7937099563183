export const productSettingDialogBoxStyles: any = {
  popup: {
    wrapper: {
      width: '29%',
      zIndex: 1350,
      // marginTop: '-51px',
    },
  },
  buttonRow: {
    display: 'flex',
    paddingBottom: '1%',
    marginLeft: '2%',
    maxWidth: '95%',
    width: '95%',
    overflowX: 'scroll',
  },
  sliderWrapper: {
    width: '94%',
    height: '82px',
    backgroundColor: '#242424',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  rasterStylesWrapper: {
    width: '216px',
    height: '80px',
    backgroundColor: '#242424',
    marginLeft: '3%',
  },
  rasterFormLabel: {
    '& .MuiTypography-root': {
      color: '#7F7F7F',
      fontSize: '12px',
    },
  },
  contourColorPickerWrapper: {
    width: '45.5%',
    height: '70px',
    backgroundColor: '#242424',
    marginLeft: '3%',
  },
  colorStepperBox: { position: 'relative', display: 'flex' },
  stepperBox: { width: '100%', height: '30px' },
};
