import { FC, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { VariantType, closeSnackbar, useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';

const FishCashMultiSearch: FC<any> = ({
  selectedValues,
  data,
  setFilteredData,
}) => {
  // --- Setter & Getter for the values selected on search ---
  const [selected, setSelected] = useState<string[]>([]);
  const [dataLength, setDataLength] = useState<number>(0);
  // --- Verify if there is any change of the selection ---
  useEffect(() => {
    setSelected([...selectedValues]);
  }, [selectedValues.length]);

  // --- Validation of the inputs of search
  const validationSchema = Yup.object({
    vesselName: Yup.string(),
    catchDate: Yup.date(),
    fishingTechnique: Yup.string(),
    totalQuantity: Yup.number(),
    comment: Yup.string(),
    species: Yup.string(),
    latitude: Yup.number(),
    longitude: Yup.number(),
  });

  // --- Formik Initial Values ---
  const initialValues = {
    vesselName: '',
    catchDate: '',
    fishingTechnique: '',
    totalQuantity: '',
    comment: '',
    species: '',
    latitude: '',
    longitude: '',
  };

  // --- Initialize Formik ---
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission
      console.log('VALUES SUBMIT SEARCH: ', values);
    },
  });

  // --- Validation Checker Selected ---
  function isValueInArray(value: string, array: string[]): boolean {
    return array.includes(value);
  }

  // --- Handles the submit of the search ---
  const handleButtonClick = (values: any) => {
    const searchData = data.filter((d: any) => {
      let vesselNameMatch,
        quantityMatch,
        techniqueMatch,
        catchDateMatch,
        commentMatch,
        speciesMatch = false;
      if (values.vesselName.length > 0)
        vesselNameMatch = d.vessel_name
          .toLowerCase()
          .includes(values.vesselName.toLowerCase());
      if (values.fishingTechnique.length > 0)
        vesselNameMatch = d.fishing_technique
          .toLowerCase()
          .includes(values.fishingTechnique.toLowerCase());
      if (parseInt(values.totalQuantity) >= 0)
        quantityMatch =
          parseInt(d.total_quantity) === parseInt(values.totalQuantity);
      if (values.catchDate.length > 0) {
        catchDateMatch =
          new Date(values.catchDate).toDateString() ===
          new Date(d.catch_date).toDateString();
      }
      if (values.species.length > 0) {
        values.species.split(',').forEach((sp: any) => {
          speciesMatch = Object.values(d.catch_details).some((f: any) =>
            f.name.toLowerCase().includes(sp.toLowerCase())
          );
        });
      }
      if (!_.isNull(d.comment) && values.comment?.length > 0)
        commentMatch = d.comment
          .toLowerCase()
          .includes(values.comment?.toLowerCase());
      return (
        vesselNameMatch ||
        quantityMatch ||
        techniqueMatch ||
        catchDateMatch ||
        commentMatch ||
        speciesMatch
      );
    });
    setDataLength(searchData.length);
    setFilteredData(searchData);
    // if (formik.errors && formik.isValid) {
    //   callToast('error', 'Search values are incorrect, please try again.');
    // }
  };

  // --- Toast Message ---
  const { enqueueSnackbar } = useSnackbar();

  const action = (snackbarId: any) => {
    return (
      <IconButton
        aria-label="close-toast"
        onClick={() => closeSnackbar(snackbarId)}
      >
        <CloseIcon />
      </IconButton>
    );
  };

  // --- Call Toast ---
  const callToast = (variant: VariantType, message: any) => {
    enqueueSnackbar(message, {
      variant,
      action,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container direction="column" spacing={0}>
          {isValueInArray('Vessel Name', selected) && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="vesselName"
                name="vesselName"
                placeholder="Vessel Name"
                variant="outlined"
                style={{
                  border: '1px solid #CDCDCD',
                  borderRadius: '5px',
                  margin: '2px 0px 2px 0px',
                }}
                value={formik.values.vesselName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.vesselName && formik.errors.vesselName && (
                <Typography variant="subtitle2" color="error">
                  {formik.errors.vesselName}
                </Typography>
              )}
            </Grid>
          )}
          {isValueInArray('Catch Date', selected) && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="catchDate"
                name="catchDate"
                placeholder="Catch Date"
                variant="outlined"
                style={{
                  border: '1px solid #CDCDCD',
                  borderRadius: '5px',
                  margin: '2px 0px 2px 0px',
                }}
                type="date"
                value={formik.values.catchDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.catchDate && formik.errors.catchDate && (
                <Typography variant="subtitle2" color="error">
                  {formik.errors.catchDate}
                </Typography>
              )}
            </Grid>
          )}
          {isValueInArray('Fishing Technique', selected) && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="fishingTechnique"
                name="fishingTechnique"
                placeholder="Fishing Technique"
                variant="outlined"
                style={{
                  border: '1px solid #CDCDCD',
                  borderRadius: '5px',
                  margin: '2px 0px 2px 0px',
                }}
                value={formik.values.fishingTechnique}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.fishingTechnique &&
                formik.errors.fishingTechnique && (
                  <Typography variant="subtitle2" color="error">
                    {formik.errors.fishingTechnique}
                  </Typography>
                )}
            </Grid>
          )}
          {isValueInArray('Total Quantity', selected) && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="totalQuantity"
                name="totalQuantity"
                placeholder="Total Quantity"
                variant="outlined"
                style={{
                  border: '1px solid #CDCDCD',
                  borderRadius: '5px',
                  margin: '2px 0px 2px 0px',
                }}
                type="number"
                value={formik.values.totalQuantity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.totalQuantity && formik.errors.totalQuantity && (
                <Typography variant="subtitle2" color="error">
                  {formik.errors.totalQuantity}
                </Typography>
              )}
            </Grid>
          )}
          {isValueInArray('Comments', selected) && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="comment"
                name="comment"
                placeholder="Comments"
                variant="outlined"
                style={{
                  border: '1px solid #CDCDCD',
                  borderRadius: '5px',
                  margin: '2px 0px 2px 0px',
                }}
                value={formik.values.comment}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.comment && formik.errors.comment && (
                <Typography variant="subtitle2" color="error">
                  {formik.errors.comment}
                </Typography>
              )}
            </Grid>
          )}
          {isValueInArray('Species', selected) && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="species"
                name="species"
                placeholder="Species"
                variant="outlined"
                style={{
                  border: '1px solid #CDCDCD',
                  borderRadius: '5px',
                  margin: '2px 0px 2px 0px',
                }}
                value={formik.values.species}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.species && formik.errors.species && (
                <Typography variant="subtitle2" color="error">
                  {formik.errors.species}
                </Typography>
              )}
            </Grid>
          )}
          {isValueInArray('Latitude', selected) && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="latitude"
                name="latitude"
                placeholder="Latitude"
                variant="outlined"
                style={{
                  border: '1px solid #CDCDCD',
                  borderRadius: '5px',
                  margin: '2px 0px 2px 0px',
                }}
                type="number"
                value={formik.values.latitude}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.latitude && formik.errors.latitude && (
                <Typography variant="subtitle2" color="error">
                  {formik.errors.latitude}
                </Typography>
              )}
            </Grid>
          )}
          {isValueInArray('Longitude', selected) && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="longitude"
                name="longitude"
                placeholder="Longitude"
                variant="outlined"
                style={{
                  border: '1px solid #CDCDCD',
                  borderRadius: '5px',
                  margin: '2px 0px 2px 0px',
                }}
                type="number"
                value={formik.values.longitude}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.longitude && formik.errors.longitude && (
                <Typography variant="subtitle2" color="error">
                  {formik.errors.longitude}
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
        <Button
          onClick={() => handleButtonClick(formik.values)}
          style={{
            color: '#FFFFFF',
            background: '#193768',
            borderRadius: '5px',
            border: '1px solid rgba(0, 0, 0, 0.25)',
            width: '100%',
          }}
        >
          Submit
        </Button>
      </form>
      <br />
      {Object.values(formik.values).some((x) => x !== null || x !== '') && (
        <Typography>{dataLength} records match your search</Typography>
      )}
    </div>
  );
};

export default FishCashMultiSearch;
