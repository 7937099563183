export const colorPickerStyles = {
  colorPickerStyle: {
    marginTop: '2px',
    backgroundColor: 'black',
    width: '228px',
    borderRadius: '10px',
    position: 'absolute',
    height: 'auto',
  },
  hueStyle: {
    height: '10px',
    marginBottom: '10px',
    padding: '0px 2px',
    position: 'relative',
    width: '205px',
  },
  saturationStyle: {
    width: '205px',
    height: '205px',
    position: 'relative',
    marginLeft: '11px',
    marginTop: '46px',
  },
  pickerTextStyle: {
    color: 'white',
    fontSize: '13px',
    float: 'left',
    marginLeft: '10px',
    marginTop: '-2px',
  },
  editColorStyle: {
    marginTop: '13px',
    marginLeft: '117px',
  },
  colorInputStyle: {
    '& input': {
      width: '99px',
      height: '25px',
      backgroundColor: '#242424',
      fontSize: '13px',
      color: 'white',
      border: 'unset',
      marginLeft: '-32px',
      position: 'absolute',
      marginTop: '-25px',
      borderRadius: '5px',
    },
  },
  colorLabelStyle: {
    width: '62px',
    height: '25px',
    backgroundColor: '#242424',
    fontSize: '13px',
    color: ' #807c7c',
    display: 'inline',
    marginLeft: '-103px',
    border: 'none',
    borderRadius: '6px',
  },
  colorWindowStyle: {
    marginTop: '-25px',
    float: 'right',
    marginRight: '11px',
    width: '25px',
    height: '25px',
    borderRadius: '5px',
  },
  lineStyle: {
    border: '1px solid #242424',
  },
  colorCloseStyle: {
    float: 'right',
    backgroundColor: 'none',
    color: 'white',
    border: 'none',
    marginTop: '3px',
    marginRight: '-12px',
    marginBottom: '5px',
    cursor: 'pointer',
  },
  headerStyle: {
    color: 'white',
    float: 'left',
    marginLeft: '10px',
    fontSize: '14px',
    marginTop: '7px',
  },
  closeIconStyle: {
    width: '14px',
    height: '14px',
    filter: 'brightness(0) invert(1)',
  },
};
