export const GeofenceStyles = {
  dataTableStyles: {
    '& .MuiDataGrid-root, & .MuiDataGrid-columnHeaders': {
      border: 'none',
    },
    '& .MuiDataGrid-cell': {
      borderBottom: 'none !important',
    },
    '& .tableHeaderStyle': {
      color: '#6F6F6F',
      backgroundColor: '#242424',
    },
    '& .tableCellStyle:focus': {
      outline: 'none !important',
    },
    '& .MuiInputBase-input, & .MuiDataGrid-row, & .MuiDataGrid-menuIconButton, & .MuiDataGrid-sortIcon':
      {
        color: 'white',
        fontSize: '13px',
      },
    '& .MuiButtonBase-root': {
      paddingRight: '4px',
      fontSize: '13px',
    },
    '& .MuiOutlinedInput-input': {
      paddingLeft: 0,
    },
    '& .MuiInputBase-root': {
      paddingRight: 0,
    },
    '& .MuiDataGrid-cellContent': {
      fontSize: '13px',
    },
    '& .MuiInputAdornment-root .MuiSvgIcon-fontSizeMedium': {
      color: 'white',
    },
    '& .Mui-disabled': {
      WebkitTextFillColor: 'white !important',
    },
    '& .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
  },
};

export const GeofenceCRUDStyles: any = {
  geofenceName: {
    '& .MuiOutlinedInput-input': {
      color: 'white',
      width: '100%',
      height: '8px',
      background: '#242424 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 6px #00000029',
      borderRadius: '9px',
    },
    '& .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root': {
      padding: 0,
    },
    '& .MuiInputBase-inputMultiline': {
      padding: '10px',
    },
  },
  geofenceTextArea: {
    '& .MuiOutlinedInput-input': {
      color: 'white',
      width: '100%',
      height: '8px',
      background: '#242424 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 6px #00000029',
      borderRadius: '9px',
    },
    mt: 1,
    padding: 0,
    width: '100%',
  },

  datePicker: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiOutlinedInput-input': {
      color: 'white',
      paddingLeft: 0,
    },
    '& .MuiButtonBase-root': {
      marginRight: '18px',
      paddingLeft: 0,
    },
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
  },
  boxStyle: {
    width: '100%',
  },
  colorPallet: {
    '& .MuiBox-root': {
      top: '0px',
    },
  },
  colorInput: {
    '& .MuiOutlinedInput-input': {
      color: 'white',
      width: '100%',
      background: '#242424 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 6px #00000029',
    },
    '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '2px',
    },
  },
  drawButton: {
    active: 'invert(0.5) sepia(1) saturate(5) hue-rotate(1deg)',
    inActive: 'brightness(0) invert(1)',
  },
  radioButton: {
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: 'rgb(254 253 253 / 38%)',
    },
  },
};

export const GeofenceWatchListStyles: any = {
  noDataMsg: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15%',
  },
};
