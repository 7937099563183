import { LogoSectionProps } from './HeaderTypes';
import './LogoSection.scss';

export const LogoSection: React.FC<LogoSectionProps> = ({
  logo = 'seastar.png',
  logoClass = 'logo__image',
  logoName = 'Seastar',
  route = 'Maps',
  routeClass = 'logo__routeLabel',
}) => {
  const getImagePath = (logo: string) => {
    return require(`./../../assets/icons/${logo}`);
  };

  return (
    <>
      <img src={getImagePath(logo)} alt={logoName} className={logoClass}></img>
      <span className="logo__route"></span>
      <span className={routeClass}>{route}</span>
    </>
  );
};
