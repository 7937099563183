import { useIntl } from 'react-intl';
import Paper from '@mui/material/Paper';
import { Divider, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box/Box';
import { I18nKey } from '../../../../translations/I18nKey';
import closeIcon from '../../../../assets/icons/close.svg';
import { useStyles } from '../../../../utils/util';
import { PopUpStyles } from '../../IUU/PopUp/VesselPopUpStyles';
import { useEffect, useRef, useState } from 'react';

const Value = styled(Paper)(PopUpStyles.ValueStyle);

export const StormPopUp: React.FC<any> = ({
  stormData,
  setShowStormPopUp,
  position,
  isShowPopUp,
}) => {
  const intl = useIntl();
  const classes = useStyles(PopUpStyles, [])();
  const [isVisible, setIsVisible] = useState(isShowPopUp);
  const popupRef = useRef<any>();

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible]);

  const handleClickOutside = (event: any) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsVisible(false);
      onClose();
    }
  };

  const onClose = () => {
    setShowStormPopUp({ display: false, rfData: {} });
  };

  return (
    <Box
      id="storm-popup"
      sx={{
        position: 'fixed',
        backgroundColor: 'black',
        borderRadius: 3,
        width: 'auto',
        top: position.top,
        left: position.left,
      }}
      style={{ display: isVisible ? 'inline-block' : 'none' }}
      ref={popupRef}
    >
      <Box
        sx={{
          backgroundColor: '#3D3D3D',
          display: 'flex',
          flexDirection: 'column',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          padding: 1,
          color: 'white',
          mb: 1,
        }}
      >
        <Typography>{stormData.name}</Typography>
      </Box>
      <Box
        onClick={onClose}
        sx={{ borderRadius: 50, color: 'white', borderColor: 'white' }}
      >
        <img
          src={closeIcon}
          onClick={onClose}
          className={classes.closeIcon}
          alt="x"
        />
      </Box>
      <Grid
        container
        sx={{ p: 1, width: '300px' }}
        columnSpacing={2}
        rowSpacing={0}
      >
        {' '}
        <Grid item xs={6}>
          <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
            {intl.formatMessage({
              id: I18nKey.PRODUCT_STORM_POP_UP_TIME,
            })}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Value>{stormData.time ? `${stormData.time}` : ''}</Value>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
            {intl.formatMessage({
              id: I18nKey.PRODUCT_STORM_POP_UP_BASIN,
            })}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Value>{stormData.basin}</Value>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
            {intl.formatMessage({
              id: I18nKey.PRODUCT_STORM_POP_UP_NOA_NUMBER,
            })}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Value>{stormData.noaa_num}</Value>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
            {intl.formatMessage({
              id: I18nKey.PRODUCT_STORM_POP_UP_MAX_WIND_SPEED,
            })}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Value>{stormData.max_ws ? `${stormData.max_ws} kts` : ''}</Value>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
            {intl.formatMessage({
              id: I18nKey.PRODUCT_STORM_POP_UP_MIN_PRESSURE,
            })}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Value>{stormData.min_bp ? `${stormData.min_bp} mb` : ''}</Value>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
            {intl.formatMessage({
              id: I18nKey.PRODUCT_STORM_POP_UP_SPEED,
            })}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Value>{stormData.sms ? `${stormData.sms} kts` : ''}</Value>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
            {intl.formatMessage({
              id: I18nKey.PRODUCT_STORM_POP_UP_HEADING,
            })}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Value>{stormData.smh ? `${stormData.smh} °` : ''}</Value>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
            {intl.formatMessage({
              id: I18nKey.IUU_POPUP_DETECTED_LOCATION,
            })}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: 'white', fontSize: '14px' }}>
            {stormData.position.lat} <br></br>
            {stormData.position.lon}
          </Typography>
        </Grid>
        {Object.keys(stormData).includes('radii') &&
        JSON.parse(stormData?.radii).length ? (
          <>
            <Divider sx={{ color: '#3d3d3d' }}></Divider>
            <Grid item xs={12}>
              <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
                {intl.formatMessage({
                  id: I18nKey.PRODUCT_STORM_POP_UP_WIND_SPEED,
                })}
              </Typography>
            </Grid>
            {JSON.parse(stormData.radii).map((r: any) => {
              return (
                <>
                  <Grid item xs={3}>
                    <Box
                      width={50}
                      height={15}
                      sx={{
                        margin: '4px',
                        backgroundColor:
                          r.thold === 34
                            ? 'cyan'
                            : r.thold === 50
                            ? 'yellow'
                            : r.thold === 64
                            ? 'red'
                            : 'white',
                      }}
                    ></Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      sx={{ color: 'white', fontSize: '14px', margin: '3px' }}
                    >
                      {r.thold === 34
                        ? '34-50'
                        : r.thold === 50
                        ? '50-64'
                        : r.thold === 64
                        ? '64 +'
                        : null}{' '}
                      (kts)
                    </Typography>
                  </Grid>
                </>
              );
            })}
          </>
        ) : null}
      </Grid>
    </Box>
  );
};
