import { useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import { useIntl } from 'react-intl';
import _, { floor } from 'lodash';
import { Box } from '@mui/material';
import { ColorBox } from '../../../../components/ColorBox/ColorBox';
import { MinMaxSlider } from '../../../../components/Slider/Slider';
import { Stepper } from '../../../../components/Stepper/Stepper';
import { Wrapper } from '../../../../components/Wrapper/Wrapper';
import { I18nKey } from '../../../../translations/I18nKey';
import { ProductUnitsContext } from '../../../Contexts/UnitsContext';
import { FeaturesContext } from '../../FeaturesContext';
import {
  convert,
  getProductPrecisionValue,
  getScaleTypeConvertedValue,
  toAntilog,
  toLog,
  updatePostObDataByUnit,
} from '../../HelperService';
import ButtonSection from './ButtonSection';
import { productSettingDialogBoxStyles } from './ProductSettingsStyles';

const ContourSettings: React.FC<any> = ({ userData, tileData, product }) => {
  const intl = useIntl();
  const precision = 1;
  const getMapTileData = useContextSelector(
    FeaturesContext,
    (state) => state.getMapTileData
  );
  const saveDefault = useContextSelector(
    FeaturesContext,
    (state) => state.saveDefault
  );
  const units = useContextSelector(ProductUnitsContext, (state) => state);
  const [markValue, setMarkValue] = useState([
    {
      value: getScaleTypeConvertedValue(
        userData.scaleType,
        userData?.contour?.scale?.scaleMin,
        toLog
      ),
      label: `${userData?.contour?.scale?.scaleMin}`,
    },
    {
      value: getScaleTypeConvertedValue(
        userData.scaleType,
        userData?.contour?.scale?.scaleMax,
        toLog
      ),
      label: `${userData?.contour?.scale?.scaleMax}`,
    },
  ]);

  const [sliderValue, setSliderValue] = useState([
    getScaleTypeConvertedValue(userData.scaleType, tileData?.min, toLog),
    getScaleTypeConvertedValue(userData.scaleType, tileData?.max, toLog),
  ]);
  const [selectedValue, setSelectedValue] = useState<any>([
    getScaleTypeConvertedValue(userData.scaleType, tileData?.min, toLog),
    getScaleTypeConvertedValue(userData.scaleType, tileData?.max, toLog),
  ]);
  const [color, setColor] = useState(tileData?.color);
  const [interval, setInterval] = useState(
    floor(tileData?.intervalLevel, precision)
  );

  const onChangeHandler = (event: Event, newValue: number[]) => {
    if (product === 'SSHA') {
      if (selectedValue[0] !== newValue[0]) {
        setSelectedValue([newValue[0], Math.abs(newValue[0])]);
      }
      if (selectedValue[1] !== newValue[1]) {
        setSelectedValue([-newValue[1], Math.abs(newValue[1])]);
      }
    } else {
      setSelectedValue(newValue);
    }
  };

  const onChangeColor = (color: any) => {
    setColor(color.hex);
  };

  const onChange = (event: any) => {
    setInterval(floor(event.target.value, precision));
  };

  const onApplyClick = () => {
    let pdata: any = {
      color: color,
      interval_level: interval,
      tile_min: getScaleTypeConvertedValue(
        userData.scaleType,
        selectedValue[0],
        toAntilog
      ),
      tile_max: getScaleTypeConvertedValue(
        userData.scaleType,
        selectedValue[1],
        toAntilog
      ),
    };
    const tdata: any = {
      color: tileData?.color,
      interval_level: tileData?.intervalLevel,
      tile_min: tileData?.min,
      tile_max: tileData?.max,
    };
    if (!_.isEqual(pdata, tdata)) {
      pdata = updatePostObDataByUnit(
        pdata,
        product,
        'tile_min',
        'tile_max',
        pdata.tile_min,
        pdata.tile_max,
        userData?.precision,
        units[product].unit,
        units[product].defaultUnit
      );
      let selectedProd: any = {};
      selectedProd['contour'] = [product];
      getMapTileData(selectedProd, false, false, pdata);
    }
  };

  const onSaveDefault = () => {
    let pdata = {
      color: color,
      interval_level: interval,
      scale_min: userData?.contour?.scale?.scaleMin,
      scale_max: userData?.contour?.scale?.scaleMax,
      user_max: getScaleTypeConvertedValue(
        userData.scaleType,
        selectedValue[1],
        toAntilog
      ),
      user_min: getScaleTypeConvertedValue(
        userData.scaleType,
        selectedValue[0],
        toAntilog
      ),
    };
    pdata = updatePostObDataByUnit(
      pdata,
      product,
      'scale_min',
      'scale_max',
      pdata.scale_min,
      pdata.scale_max,
      userData?.precision,
      units[product].unit,
      units[product].defaultUnit
    );
    pdata = updatePostObDataByUnit(
      pdata,
      product,
      'user_min',
      'user_max',
      pdata.user_min,
      pdata.user_max,
      userData?.precision,
      units[product].unit,
      units[product].defaultUnit
    );
    saveDefault(product, 'contour', pdata);
  };

  const onRestore = () => {
    let [userMin, userMax] = convert(
      userData?.contour?.user?.min,
      userData?.contour?.user?.max,
      product,
      userData?.precision,
      units[product].unit
    );
    setSelectedValue([
      getScaleTypeConvertedValue(userData.scaleType, userMin, toLog),
      getScaleTypeConvertedValue(userData.scaleType, userMax, toLog),
    ]);
    setColor(userData?.contour?.user?.color);
    setInterval(userData?.contour?.user?.intervalLevel);
  };

  return (
    <Box>
      <ButtonSection
        title={intl.formatMessage({
          id: I18nKey.PRODUCT_SETTING_DIALOG_BOX_CONTOUR,
        })}
        onApply={onApplyClick}
        onRestore={onRestore}
        onSaveDefault={onSaveDefault}
      />
      <Wrapper wrapperStyle={productSettingDialogBoxStyles.sliderWrapper}>
        <MinMaxSlider
          selectedValue={selectedValue}
          defaultValue={sliderValue}
          showLogScale={userData.scaleType === 'log10' ? true : false}
          marks={markValue}
          step={getProductPrecisionValue(userData.precision)}
          onChangeHandler={onChangeHandler}
          setSelectedValue={setSelectedValue}
          precision={userData?.precision}
          syncMinMax={product === 'SSHA'}
        />
      </Wrapper>
      <br></br>
      <Box sx={productSettingDialogBoxStyles.colorStepperBox}>
        <Wrapper
          title={intl.formatMessage({
            id: I18nKey.PRODUCT_SETTING_DIALOG_BOX_COLOR,
          })}
          wrapperStyle={productSettingDialogBoxStyles.contourColorPickerWrapper}
        >
          <ColorBox
            color={color}
            showColorPicker
            onChangeColor={onChangeColor}
          />
        </Wrapper>
        <Wrapper
          title={intl.formatMessage({
            id: I18nKey.PRODUCT_SETTING_DIALOG_BOX_INTERVAL,
          })}
          wrapperStyle={productSettingDialogBoxStyles.contourColorPickerWrapper}
        >
          <Box sx={productSettingDialogBoxStyles.stepperBox}>
            <Stepper value={interval} onChange={onChange} precision={1} />
          </Box>
        </Wrapper>
      </Box>
    </Box>
  );
};

export default ContourSettings;
