import React from 'react';
import { Slider, Box } from '@mui/material';
import { DurationSliderStyles, TimeLineSliderStyles } from '../SliderStyles';
import { TrackDurationSliderProps } from '../SliderTypes';

const TrackDurationSlider: React.FC<TrackDurationSliderProps> = ({
  defaultDuration = 60,
  marks,
  onChange,
  value,
}) => {
  return (
    <Box sx={TimeLineSliderStyles.sliderStyles}>
      <Slider
        track={false}
        sx={DurationSliderStyles.sliderStyles}
        defaultValue={defaultDuration}
        marks={marks}
        step={null}
        onChange={onChange}
      />
    </Box>
  );
};

export default TrackDurationSlider;
