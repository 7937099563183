import { FC } from 'react';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const FishCatchErrorList: FC<any> = ({ reFetchData }) => {
  return (
    <Box
      sx={{
        background: '#D9E8F6',
        padding: '50px',
        paddingTop: '25rem',
        paddingBottom: '25rem',
        borderRadius: '5px',
        marginTop: '3rem',
        marginRight: '3.5rem',
        marginLeft: '1rem',
      }}
    >
      <Stack spacing={3} direction="column" alignItems="center">
        <ReportProblemIcon style={{ fontSize: '80px', color: '#193768' }} />
        <h3 style={{ color: '#193768' }}>OOps, something went wrong!</h3>
        <p style={{ color: '#7F7F7F' }}>
          We encountered an error while loading data.
        </p>
        <Button
          variant="contained"
          style={{ background: '#193768' }}
          onClick={reFetchData}
        >
          Try Again
        </Button>
      </Stack>
    </Box>
  );
};

export default FishCatchErrorList;
