import { Box } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import TripClient from '../../../../api/tripAPIs';
import { PopupFooter } from '../../../../components/SettingsPopup/PopupFooter/PopupFooter';
import { PopupHeader } from '../../../../components/SettingsPopup/PopupHeader/PopupHeader';
import { SettingsPopup } from '../../../../components/SettingsPopup/SettingsPopup';
import Snackbar from '../../../../components/Snackbar/Snackbar';
import { I18nKey } from '../../../../translations/I18nKey';
import { getUserAttributes } from '../../../../utils/auth';
import { TripData } from '../../../types';
import { TripsCRUD } from './TripsCRUD';
import { TripsList } from './TripsList';

export const TripsContainer: React.FC<any> = ({ onClose }) => {
  const intl = useIntl();
  const userName = getUserAttributes().userName;
  const [alert, setAlert] = useState({
    type: '',
    display: false,
    message: '',
  });
  const [tripsEditMode, setTripsEditMode] = useState<boolean>(false);
  const [tripsListDataLoading, setTripsListDataLoading] =
    useState<boolean>(false);
  const [tripsListData, setTripsListData] = useState<TripData[]>([]);
  const timerRef = useRef<any>(null);
  const [saveTripChanges, setSaveTripsChanges] = useState<boolean>(false);

  const footerItems = {
    action: {
      text: I18nKey.ANALYSIS_SAVE,
      onClick: () => setSaveTripsChanges(true),
    },
  };

  useEffect(() => {
    getTripsListData();
    return () => clearTimeout(timerRef.current);
  }, []);

  const displayAlert = (messageKey: any, type: string = 'error') => {
    setAlert({
      type,
      display: true,
      message: intl.formatMessage({
        id: messageKey,
      }),
    });
    timerRef.current = setTimeout(() => {
      setAlert({
        type: '',
        display: false,
        message: '',
      });
    }, 5000);
  };

  const getTripsListData = async () => {
    try {
      setTripsListDataLoading(true);
      const data = await TripClient.getTripDetails(userName);
      const tripsData = data.map((item: any) => {
        return {
          ...item,
          distance: item.points.reduce((acc: number, point: any) => {
            const element: any = Object.values(point)[0];
            return acc + element.distance;
          }, 0),
          waypoints: item.points.length,
        };
      });
      setTripsListData(tripsData);
    } catch (error) {
      console.log({ error });
      displayAlert(I18nKey.ANALYSIS_TRIPS_ERROR);
    } finally {
      setTripsListDataLoading(false);
    }
  };

  const exportTripsData = async (params: GridCellParams) => {
    try {
      const data = await TripClient.exportTrip(userName, params.row.tripId);
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${params.row.tripId}.gpx`;
      link.click();
    } catch (error) {
      console.log({ error });
      displayAlert(I18nKey.ANALYSIS_TRIPS_DOWNLOAD_ERROR);
    }
  };

  const deleteTripsData = async (params: GridCellParams) => {
    try {
      const data = await TripClient.deleteTrip(userName, params.row.tripId);
      displayAlert(I18nKey.ANALYSIS_TRIPS_DELETE_SUCCESS, 'success');
      getTripsListData();
    } catch (error) {
      console.log({ error });
      displayAlert(I18nKey.ANALYSIS_TRIPS_DELETE_ERROR);
    }
  };

  const saveTripsData = async (tripData: TripData) => {
    try {
      const data = await TripClient.saveTrip(
        userName,
        tripData.tripId,
        tripData
      );
      displayAlert(I18nKey.ANALYSIS_TRIPS_SAVE_SUCCESS, 'success');
      setTripsEditMode(false);
      getTripsListData();
    } catch (error) {
      console.log({ error });
      displayAlert(I18nKey.ANALYSIS_TRIPS_SAVE_ERROR);
    }
  };

  return (
    <SettingsPopup
      popupStyle={{
        wrapper: {
          height: '491px',
          maxHeight: '491px',
        },
      }}
      headerElement={
        <PopupHeader
          title={I18nKey.ANALYSIS_TRIPS}
          onClose={onClose}
          headerStyle={{
            header: {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          }}
        ></PopupHeader>
      }
      bodyElement={
        <Box sx={{ overflow: 'scroll' }}>
          {alert.display && (
            <Snackbar
              type={alert.type}
              display={alert.display}
              message={alert.message}
            ></Snackbar>
          )}
          {!tripsEditMode && (
            <TripsList
              setTripsEditMode={setTripsEditMode}
              tripsListDataLoading={tripsListDataLoading}
              tripsListData={tripsListData}
              exportTripsData={exportTripsData}
              deleteTripsData={deleteTripsData}
            />
          )}
          {tripsEditMode && (
            <TripsCRUD
              setTripsEditMode={setTripsEditMode}
              saveTripsData={saveTripsData}
              saveTripChanges={saveTripChanges}
              setSaveTripsChanges={setSaveTripsChanges}
            />
          )}
        </Box>
      }
      footerElement={
        tripsEditMode ? (
          <PopupFooter actionButton={footerItems.action} />
        ) : undefined
      }
    ></SettingsPopup>
  );
};
