export const SettingsDefault: { [key: string]: any } = {
  depth: ['m', 'ft', 'fathom'],
  height: ['cm', 'in'],
  temperature: { celcius: '°C', fahrenheit: '°F' },
  basemap: {
    ustopo: 'US-Topo',
    esri: 'ESRI',
    openstreet: 'Open Street Maps',
    worldtopo: 'World Topo',
  },
  languages: {
    en: {name: 'English - EN', desciption: 'English'},
    es: {name: 'Español - ES', desciption: 'Spanish'},
    'zh-TW': {name: '繁體中文 - ZH', desciption: 'Chinese'}
    // zh_CN: '简体中文 - ZH',
    // ko: '한국어 - KO',
  },
  latitude: { format1: 'xx° xx\' xx"', format2: "xx° xx.xxx'" },
  depths: ['10', '50', '100', '150', '200', '300', '400', '500'],
};

export const NoDisplayInSettingsProducts = ['BPS'];
