import { Style } from 'mapbox-gl';

export const MAPBOX_TILES: Style = {
  sources: {
    ustopo: {
      type: 'raster',
      tiles: [
        'https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}',
      ],
      tileSize: 256,
    },
    esri: {
      type: 'raster',
      tiles: [
        'https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}',
      ],
      tileSize: 256,
    },
    openstreet: {
      type: 'raster',
      tiles: ['https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'],
      tileSize: 256,
    },
    worldtopo: {
      type: 'raster',
      tiles: [
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
      ],
      tileSize: 256,
    },
  },
  version: 8,
  glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
  layers: [
    {
      id: 'base',
      type: 'raster',
      source: 'worldtopo',
      minzoom: 0,
      maxzoom: 21,
    },
  ],
};

export const PREVIOUS_DATA_FETCH_DAYS = 1;
