export const CopyRightSectionStyles: any = {
  section: {
    padding: '8px',
    paddingTop: '10px',
    paddingLeft: '14px',
    display: 'flex',
    width: '300px',
    backgroundColor: '#000000',
    color: '#ffffff',
    opacity: '0.6',
    borderRadius: '27px 27px 0px 0px',
    paddingBottom: '10px',
  },
  divider: {
    backgroundColor: '#FFFFFF',
    height: '10px',
    opacity: '061',
    marginTop: '5px',
  },
  logo: {
    height: '16px',
    width: '88px',
    marginRight: '8px',
    marginLeft: '7px',
    opacity: '1',
  },
  defaultText: {
    paddingLeft: '8px',
    paddingRight: '7px',
  },
  copyRightIcon: {
    fontSize: '12px',
  },
};
