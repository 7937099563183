/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import _, { cloneDeep, isEmpty, isNil } from 'lodash';
import axios from 'axios';
import moment from 'moment';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useContextSelector } from 'use-context-selector';
import * as IUUClient from '../../../api/iuuAPIs';
import { ProgressSpinner } from '../../../components/ProgressSpinner/ProgressSpinner';
import Snackbar from '../../../components/Snackbar/Snackbar';
import { I18nKey } from '../../../translations/I18nKey';
import { getUserAttributes } from '../../../utils/auth';
import {
  BreachVesselDetails,
  GeofenceDetails,
  AISData,
  vesselBuoyAPIParams,
  FishCatchData,
} from '../../types';
import {
  defaultAISFilter,
  defaultDataDuration,
  defaultVMSFilter,
  vesselSources,
  AISTrackTimelineConfig,
  defaultBuoyFilter,
  defaultVesselFilter,
  defaultFishCatchFilter,
} from './config';
import { MapContext } from '../MapContext';
import { IUUService } from './IUUService';
import { IUUSettings } from './IUUSettings/IUUSettings';
import { MapMainStyles } from '../MapStyles';
import { UserSettingsContext } from '../../Contexts/UserSettingsContext';
import { VesselTrackingService } from './VesselTrackingService';
import { VesselBreachService } from './VesselBreachService';
import breachCircleRed from '../../../assets/icons/breach_circle_red.png';
import { AISPopUp } from './PopUp/AISPopUp';
import {
  getPositionForPopUp,
  removeLayerSource,
  stringToColorConverter,
} from '../HelperService';
import { IUUTimeline } from './IUUSettings/IUUTimeline';
import { AISLegends } from './AISLegends';
import { dateToString } from '../../../utils/util';
import { RegionContext } from '../../Contexts/RegionContext';
import { RFPopUp } from './PopUp/RFPopUp';
import { DTPopUp } from './PopUp/DTPopUp';
import { LayoutContext } from '../LayoutContext';
import { OtherFeaturesContext } from '../OtherFeaturesContext';
import { FillAreaService } from '../Products/MapLayers/FillAreaService';
import TrackPopUp from './PopUp/TrackPopUp';
import { LicensedFeaturesContext } from '../../Contexts/LicensedFeaturesContext';
import BuoyPopUp from './PopUp/BuoyPopUp';
import VesselsPopUp from './PopUp/VesselsPopUp';
import { trackDefaultDuration } from './IUUSettings/VesselBuoyConfig';
import FishCatchClient from '../../../api/fishCatchAPIs';
import { FishCatchDashboardService } from '../../FishCatchDashboard/FishCatchDashboardService';
import { FishCatchPopUp } from '../../FishCatchDashboard/FishCatchPopUp';

const getUniqueData = (data: any, type: string) => {
  return _.uniqBy(data, type);
};

export const IUUContainer: React.FC<any> = ({ openSettings }) => {
  const userName = getUserAttributes().userName;
  const regions = useContextSelector(RegionContext, (state) => state);
  const features = useContextSelector(
    LicensedFeaturesContext,
    (state) => state.features
  );
  const region = useContextSelector(
    UserSettingsContext,
    (state) => state.userSettings.map?.regionsOfInterest || ''
  );
  const layoutState = useContextSelector(
    LayoutContext,
    (state) => state.layoutState
  );
  const setLayoutState = useContextSelector(
    LayoutContext,
    (state) => state.setLayoutState
  );
  const recommendationLength = useContextSelector(
    LayoutContext,
    (state) => state.recommendationLength
  );
  const date =
    sessionStorage.getItem('date') ||
    `${moment(new Date()).format('YYYYMMDD')}`;
  const intl = useIntl();
  const popupCloseTimeout: { current: NodeJS.Timeout | null } = useRef(null);
  const popupOpenTimeout: { current: NodeJS.Timeout | null } = useRef(null);
  const displayConfig = useContextSelector(
    OtherFeaturesContext,
    (state) => state.displayConfig
  );

  const setClickedProduct = useContextSelector(
    OtherFeaturesContext,
    (state) => state.setClickedProduct
  );
  const map = useContextSelector(MapContext, (state) => state.map);
  const addMapLayerId = useContextSelector(
    MapContext,
    (state) => state.addMapLayerId
  );
  const layerOrder = useContextSelector(
    MapContext,
    (state) => state.layerOrder
  );
  const removeMapLayerId = useContextSelector(
    MapContext,
    (state) => state.removeMapLayerId
  );
  const [showSettings, setShowSettings] = useState(false);
  const [filteredVesselData, setFilteredVesselData] = useState<
    Record<string, AISData[]>
  >({
    AIS: [],
    VMS: [],
  });
  const [AISData, setAISData] = useState<AISData[]>([]);
  const [buoyData, setBuoyData] = useState<any[]>([]);
  const [filteredBuoyData, setFilteredBuoyData] = useState<any[]>([]);
  const [filteredVesselsData, setFilteredVesselsData] = useState<any[]>([]);
  const [vesselData, setVesselData] = useState<any[]>([]);
  const [VMSData, setVMSData] = useState<AISData[]>([]);
  const [geofencesData, setGeofencesData] = useState<GeofenceDetails[]>([]);
  const [breachData, setBreachData] = useState<
    Record<string, BreachVesselDetails>
  >({});
  const [loading, setLoading] = useState(false);
  const [loadingBreachData, setLoadingBreachData] = useState(false);
  const [vesselDataIsLoading, setVesselDataIsLoading] = useState({
    AIS: true,
    VMS: true,
  });
  const [vesselTrackList, setVesselTrackList] = useState<Record<string, any>>({
    AIS: [],
    VMS: [],
  });
  const [vesselTrackIdsList, setVesselTrackIdsList] = useState<any>([]);
  const [buoyTrackIdsList, setBuoyTrackIdsList] = useState<any>([]);
  const [buoyTrackData, setBuoyTrackData] = useState<Record<string, any>>({});
  const [vesselTrackData, setVesselTrackData] = useState<Record<string, any>>(
    {}
  );
  const [buoyFileData, setBuoyFileData] = useState<any>([]);
  const [vesselFileData, setVesselFileData] = useState<any>([]);
  const [trackDuration, setTrackDuration] = useState({
    fromDate: '',
    toDate: '',
  });
  const [buoyTrackDuration, setBuoyTrackDuration] = useState({
    fromDate: '',
    toDate: '',
  });
  const [vesselTrackDuration, setVesselTrackDuration] = useState({
    fromDate: '',
    toDate: '',
  });
  const [dataDuration, setDataDuration] = useState(
    parseInt(
      localStorage.getItem('dataDuration') || defaultDataDuration.toString()
    )
  );
  const [vesselDataFetchToDate, setVesselDataFetchToDate] = useState(
    dateToString(moment(), 'YYYYMMDDHHmmss', true)
  );
  const [filters, setFilters] = useState<Record<string, any>>({
    AIS: JSON.parse(localStorage.getItem('AISFilters') || '{}'),
    VMS: JSON.parse(localStorage.getItem('VMSFilters') || '{}'),
  });
  const [trackData, setTrackData] = useState<Record<string, any>>({});
  const [alert, setAlert] = useState({
    type: '',
    display: false,
    message: '',
  });
  const [showPopUp, setShowPopUp] = useState<Record<string, any>>({
    display: false,
    vessel: {},
    layer: '',
  });
  const [showTracksPopUp, setShowTracksPopUp] = useState<Record<string, any>>({
    display: false,
    trackData: {},
    position: {},
  });
  const [showBuoyPopUp, setShowBuoyPopUp] = useState<Record<string, any>>({
    display: false,
    buoyData: {},
  });
  const [showVesselPopUp, setShowVesselPopUp] = useState<Record<string, any>>({
    display: false,
    vesselData: {},
  });
  const [showRFPopUp, setShowRFPopUp] = useState<Record<string, any>>({
    display: false,
    rfData: {},
  });
  const [showDTPopUp, setShowDTPopUp] = useState<Record<string, any>>({
    display: false,
    rfData: {},
  });
  const [showFishCatchPopUp, setShowFishCatchPopUp] = useState<
    Record<string, any>
  >({
    display: false,
    fcData: {},
    position: {},
  });
  const [geofenceDataLoaded, setGeofenceDataLoaded] = useState<boolean>(false);
  const [geofenceLayerList, setGeofenceLayerList] = useState([] as any);
  const [AISLegendFilteredData, setAISLegendFilteredData] = useState<any>([]);
  const [RFLayerList, setRFLayerList] = useState<Record<string, any>>({});
  const [DTLayerList, setDTLayerList] = useState<Record<string, any>>({});
  const [tracksLeftPosition, setTracksLeftPosition] = useState<string>('31%');
  const [AISLegendLeftPosition, setAISLegendLeftPosition] =
    useState<string>('32%');
  const [fishCatchData, setFishCatchData] = useState<any>([]);

  useEffect(() => {
    if (isNil(localStorage.getItem('AISFilters'))) {
      localStorage.setItem('AISFilters', JSON.stringify(defaultAISFilter));
      setFilters((prevState) => {
        return { ...prevState, AIS: defaultAISFilter };
      });
    }
    if (isNil(localStorage.getItem('VMSFilters'))) {
      localStorage.setItem('VMSFilters', JSON.stringify(defaultVMSFilter));
      setFilters((prevState) => {
        return { ...prevState, VMS: defaultVMSFilter };
      });
    }
    if (isNil(localStorage.getItem('dataDuration'))) {
      localStorage.setItem('dataDuration', defaultDataDuration.toString());
      setDataDuration(defaultDataDuration);
    }
    if (isNil(localStorage.getItem('buoyFilter'))) {
      localStorage.setItem('buoyFilter', JSON.stringify(defaultBuoyFilter));
    }
    if (isNil(localStorage.getItem('vesselFilter'))) {
      localStorage.setItem('vesselFilter', JSON.stringify(defaultVesselFilter));
    }
    if (isNil(localStorage.getItem('fishCatchFilter'))) {
      localStorage.setItem(
        'fishCatchFilter',
        JSON.stringify(defaultFishCatchFilter)
      );
    }
    setTrackDuration(
      VesselTrackingService.getTrackDates(
        AISTrackTimelineConfig.defaultDuration
      )
    );
    setVesselTrackDuration(
      VesselTrackingService.getTrackDates(trackDefaultDuration)
    );
    setBuoyTrackDuration(
      VesselTrackingService.getTrackDates(trackDefaultDuration)
    );
  }, []);

  useEffect(() => {
    // Clearing the AIS data when region changes
    setAISData([]);
    setAISLegendFilteredData([]);
    setGeofencesData([]);
  }, [region]);

  useEffect(() => {
    if (isEmpty(vesselTrackList['AIS']) && isEmpty(vesselTrackList['VMS'])) {
      setTrackDuration(
        VesselTrackingService.getTrackDates(
          AISTrackTimelineConfig.defaultDuration
        )
      );
      setLayoutState((prevState: any) => ({
        ...prevState,
        AISnVMSTracks: false,
      }));
    } else {
      setLayoutState((prevState: any) => ({
        ...prevState,
        AISnVMSTracks: true,
      }));
    }
  }, [vesselTrackList]);

  useEffect(() => {
    toggleTracks();
  }, [
    displayConfig?.data?.IUU?.items.VMS?.selected,
    displayConfig?.data?.IUU?.items.AIS?.selected,
    trackDuration,
    vesselTrackList,
  ]);

  useEffect(() => {
    toggleBuoyTracks();
  }, [
    displayConfig?.data?.IUU?.items.buoys?.selected,
    buoyTrackIdsList,
    buoyTrackDuration,
  ]);

  useEffect(() => {
    toggleVesselTracks();
  }, [
    displayConfig?.data?.IUU?.items.vessel?.selected,
    vesselTrackIdsList,
    vesselTrackDuration,
  ]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (displayConfig?.data?.IUU?.items.buoys?.selected) {
      setLoading(true);
      fetchBuoysData();
    }
  }, [displayConfig?.data?.IUU?.items.buoys?.selected]);

  useEffect(() => {
    if (displayConfig?.data?.IUU?.items.vessel?.selected) {
      setLoading(true);
      fetchVesselsData();
    }
  }, [displayConfig?.data?.IUU?.items.vessel?.selected]);

  useEffect(() => {
    if (displayConfig?.data?.IUU?.items?.fishCatch?.selected) {
      setLoading(true);
      fetchFishCatchRecords();
    }
  }, [displayConfig?.data?.IUU?.items.fishCatch?.selected]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        displayConfig?.data?.IUU?.items.IUUSettings?.selected ||
        displayConfig?.data?.IUU?.items.AIS?.selected ||
        displayConfig?.data?.IUU?.items.VMS?.selected
      ) {
        fetchAISData();
        fetchVMSData();
      }
    }, 4500000);

    return () => clearInterval(interval);
  }, [
    displayConfig?.data?.IUU?.items.IUUSettings?.selected,
    displayConfig?.data?.IUU?.items.AIS?.selected,
    displayConfig?.data?.IUU?.items.VMS?.selected,
  ]);

  useEffect(() => {
    if (!isEmpty(geofenceLayerList)) {
      geofenceLayerList.forEach((layerId: string) => {
        removeMapLayerId('geofence', layerId);
        if (map) {
          removeLayerSource(map, layerId, [layerId, layerId + 'outline']);
        }
      });
      setGeofenceLayerList([]);
    }
    if (displayConfig?.data?.IUU?.items.geofencing?.selected) {
      if (isEmpty(geofencesData)) {
        setLoading(true);
        getGeofencesData();
      } else {
        drawGeofence();
      }
    }
  }, [displayConfig?.data?.IUU?.items.geofencing?.selected]);

  useEffect(() => {
    if (
      displayConfig?.data?.IUU?.items.geofencing?.selected &&
      !isEmpty(geofencesData)
    ) {
      drawGeofence();
    }
  }, [geofencesData]);

  useEffect(() => {
    setShowSettings(displayConfig?.data?.IUU?.items.IUUSettings?.selected);
    if (displayConfig?.data?.IUU?.items.IUUSettings?.selected) {
      getGeofencesData();
      if (isEmpty(AISData) && features.includes('AIS')) fetchAISData();
      if (isEmpty(buoyData) && features.includes('Buoys')) fetchBuoysData();
      if (isEmpty(buoyFileData) && features.includes('Buoys')) fetchBuoyFiles();
      if (isEmpty(vesselData) && features.includes('Vessel'))
        fetchVesselsData();
      if (isEmpty(vesselFileData) && features.includes('Vessel'))
        fetchVesselsFiles();
      if (isEmpty(VMSData) && features.includes('VMS')) fetchVMSData();
      if (isEmpty(fishCatchData) && features.includes('FishCatch'))
        fetchFishCatchRecords();
    }
  }, [displayConfig?.data?.IUU?.items.IUUSettings?.selected]);

  useEffect(() => {
    if (displayConfig?.data?.IUU?.items.AIS?.selected) {
      if (isEmpty(AISData)) {
        setLoading(true);
        setVesselDataIsLoading((prevState) => {
          return { ...prevState, AIS: true };
        });
        fetchAISData();
      }
    } else {
      if (!isEmpty(trackData))
        VesselTrackingService.turnOffVesselTracks(
          map,
          Object.values(trackData),
          setTrackData
        );
    }
  }, [displayConfig?.data?.IUU?.items.AIS?.selected]);

  useEffect(() => {
    if (displayConfig?.data?.IUU?.items.VMS?.selected) {
      if (isEmpty(VMSData)) {
        setLoading(true);
        setVesselDataIsLoading((prevState) => {
          return { ...prevState, VMS: true };
        });
        fetchVMSData();
      }
    } else {
      if (!isEmpty(trackData))
        VesselTrackingService.turnOffVesselTracks(
          map,
          Object.values(trackData),
          setTrackData
        );
    }
  }, [displayConfig?.data?.IUU?.items.VMS?.selected]);

  useEffect(() => {
    if (
      displayConfig?.data?.IUU?.items.geofencing?.selected &&
      (displayConfig?.data?.IUU?.items.VMS?.selected ||
        displayConfig?.data?.IUU?.items.AIS?.selected)
    ) {
      setLoadingBreachData(true);
      getBreachData();
    } else {
      setBreachData({});
    }
  }, [
    displayConfig?.data?.IUU?.items.VMS?.selected,
    displayConfig?.data?.IUU?.items.AIS?.selected,
    displayConfig?.data?.IUU?.items.geofencing?.selected,
  ]);

  useEffect(() => {
    vesselSources.forEach((source: string) => {
      let layer = source + 'BreachLayer';
      if (map?.getSource(layer) && map.getLayer(layer)) {
        map.removeLayer(layer);
        map.removeSource(layer);
      }
      if (
        displayConfig?.data?.IUU?.items.geofencing?.selected &&
        displayConfig?.data?.IUU?.items[source]?.selected &&
        !isEmpty(breachData)
      ) {
        const breachVesselPositions = VesselBreachService.readbreachData(
          filteredVesselData[source],
          breachData
        );
        VesselBreachService.addVesselBreachToMap(map, {
          layerId: layer,
          source: breachVesselPositions,
          breachIcon: breachCircleRed,
        });
      }
    });
  }, [breachData, filteredVesselData]);

  useEffect(() => {
    _.map(RFLayerList, (url, layerID) => {
      if (map) {
        removeMapLayerId('RF', layerID);
        removeLayerSource(map, layerID, [layerID]);
      }
    });
    if (displayConfig?.data?.IUU?.items.RFSignals?.selected) {
      setLoading(true);
      getRFSignalsData();
    }
  }, [displayConfig?.data?.IUU?.items.RFSignals?.selected]);

  useEffect(() => {
    _.map(DTLayerList, (url, layerID) => {
      if (map) {
        removeMapLayerId('darkTargets', layerID);
        removeLayerSource(map, layerID, [layerID]);
      }
    });
    if (displayConfig?.data?.IUU?.items.darkTargets?.selected) {
      setLoading(true);
      getDarkTargetsData();
    } else {
      if (!isEmpty(DTLayerList)) flybackToInitialCoordinates();
    }
  }, [displayConfig?.data?.IUU?.items.darkTargets?.selected]);

  useEffect(() => {
    IUUService.removeLayer(map, 'AIS', removeMapLayerId);
    setVesselTrackList({ AIS: [], VMS: [] });
    if (displayConfig?.data?.IUU?.items.AIS?.selected) {
      if (map && !isEmpty(AISData)) {
        const filteredData = filterVesselData(
          'AIS',
          filters['AIS'],
          dataDuration
        );
        IUUService.drawVeselsOnMap(
          map,
          'AIS',
          filteredData,
          layerOrder,
          addMapLayerId
        );
        loadPopUp('AIS');
        setFilteredVesselData({ ...filteredVesselData, AIS: filteredData });
      }
    }
  }, [displayConfig?.data?.IUU?.items.AIS?.selected, AISData]);

  useEffect(() => {
    IUUService.removeLayer(map, 'VMS', removeMapLayerId);
    setVesselTrackList({ AIS: [], VMS: [] });
    if (displayConfig?.data?.IUU?.items.VMS?.selected) {
      if (map && !isEmpty(VMSData)) {
        const filteredData = filterVesselData(
          'VMS',
          filters['VMS'],
          dataDuration
        );
        IUUService.drawVeselsOnMap(
          map,
          'VMS',
          filteredData,
          layerOrder,
          addMapLayerId
        );
        loadPopUp('VMS');
      }
    }
  }, [displayConfig?.data?.IUU?.items.VMS?.selected, VMSData]);

  useEffect(() => {
    IUUService.removeLayer(map, 'Buoy', removeMapLayerId);
    setBuoyTrackIdsList([]);
    setBuoyTrackDuration(
      VesselTrackingService.getTrackDates(trackDefaultDuration)
    );
    let filters = JSON.parse(localStorage.getItem('buoyFilter') || '{}');
    setFilteredBuoyData(buoyData);
    if (map && !isEmpty(buoyData)) {
      const filteredData = filterBuoyData(
        buoyData,
        _.pick(filters, ['vesselName', 'buoysId'])
      );
      setFilteredBuoyData(filteredData);
      if (displayConfig?.data?.IUU?.items.buoys?.selected) {
        map &&
          map?.flyTo({
            center: [40, 0],
            speed: 0.4,
            zoom: 2,
          });
        IUUService.drawVeselsOnMap(
          map,
          'Buoy',
          filteredData,
          layerOrder,
          addMapLayerId
        );
        map && map?.on('click', 'Buoy', (e: any) => addBuoyPopUp(e));
        if (filters.showTracks) {
          setBuoyTrackIdsList((prev: any) =>
            _.uniq([...prev, ...filters.buoysId])
          );
        }
      }
    }
  }, [displayConfig?.data?.IUU?.items.buoys?.selected, buoyData]);

  useEffect(() => {
    IUUService.removeLayer(map, 'Vessel', removeMapLayerId);
    setVesselTrackIdsList([]);
    setVesselTrackDuration(
      VesselTrackingService.getTrackDates(trackDefaultDuration)
    );
    setFilteredVesselsData(vesselData);
    let filters = JSON.parse(localStorage.getItem('vesselFilter') || '{}');
    if (map && !isEmpty(vesselData)) {
      const filteredData = filterVesselsData(
        vesselData,
        _.pick(filters, ['vesselName'])
      );
      setFilteredVesselsData(filteredData);
      if (displayConfig?.data?.IUU?.items.vessel?.selected) {
        map &&
          map?.flyTo({
            center: [40, 0],
            speed: 0.4,
            zoom: 2,
          });
        IUUService.drawVeselsOnMap(
          map,
          'Vessel',
          filteredData,
          layerOrder,
          addMapLayerId
        );
        map && map?.on('click', 'Vessel', (e: any) => addVesselPopUp(e));
        if (filters.showTracks) {
          setVesselTrackIdsList((prev: any) =>
            _.uniq([...prev, ...filteredData.map((v) => v.vesselId)])
          );
        }
      }
    }
  }, [displayConfig?.data?.IUU?.items.vessel?.selected, vesselData]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    map && FishCatchDashboardService.removeCatchLayers(map);
    let filters = JSON.parse(localStorage.getItem('fishCatchFilter') || '{}');
    let data = cloneDeep(fishCatchData);
    const filteredData = IUUService.filterFishCatchData(data, filters);
    renderCatchesOnMap(filteredData);
  }, [displayConfig?.data?.IUU?.items?.fishCatch?.selected, fishCatchData]);

  useEffect(() => {
    if (
      displayConfig?.data?.IUU?.items.geofencing?.selected &&
      (displayConfig?.data?.IUU?.items.VMS?.selected ||
        displayConfig?.data?.IUU?.items.AIS?.selected)
    )
      getBreachData();
  }, [dataDuration]);

  useEffect(() => {
    const data = getUniqueData(filteredVesselData.AIS, 'vesselType');
    setAISLegendFilteredData(data);
    if (data.length < 3) {
      setTracksLeftPosition('19%');
    } else if (data.length > 4) {
      setTracksLeftPosition('31%');
    } else {
      setTracksLeftPosition('22%');
    }
  }, [filteredVesselData.AIS]);

  useEffect(() => {
    return () => {
      map?.off('click', 'AIS', (e: any) => addPopUp(e, 'AIS'));
      // map?.off('mouseleave', 'AIS', (e: any) => removePopUp('AIS'));
      map?.off('click', 'VMS', (e: any) => addPopUp(e, 'VMS'));
      // map?.off('mouseleave', 'VMS', (e: any) => removePopUp('VMS'));
      map?.off('click', 'RF', (e: any) => addRFPopUp(e));
      // map?.off('mouseleave', 'RF', () => removeRFPopUp());
      map?.off('click', 'DT', (e: any) => addDTPopUp(e));
      // map?.off('mouseleave', 'DT', () => removeDTPopUp());
      map?.off('click', 'Buoy', (e: any) => addBuoyPopUp(e));
      map?.off('click', 'Vessel', (e: any) => addVesselPopUp(e));
      // map?.off('mouseleave', 'DT', () => removeDTPopUp());
    };
  }, []);

  useEffect(() => {
    if (recommendationLength < 3) {
      setAISLegendLeftPosition('20%');
    } else if (recommendationLength > 4) {
      setAISLegendLeftPosition('32%');
    } else {
      setAISLegendLeftPosition('24%');
    }
  }, [recommendationLength]);

  const flybackToInitialCoordinates = () => {
    let selectedRegionDetails = regions.filter((r: any) => r.code === region);
    const initialCoordinates = selectedRegionDetails[0].center
      .split(',')
      .map(Number);
    map && map?.flyTo({ center: initialCoordinates, speed: 0.5 });
  };

  const getFishCatchRecords = async () => {
    const response = await FishCatchClient.getFishCatchData(userName);
    return response.map((s: any) => {
      return { ...s, id: s.uuid };
    });
  };

  const {
    isLoading,
    isError,
    error,
    isFetching,
    refetch: fetchFishCatchRecords,
  } = useQuery({
    queryFn: getFishCatchRecords,
    enabled: false,
    onSuccess(data) {
      setLoading(false);
      setFishCatchData(data);
      if (data.length === 0) {
        displayAlert(I18nKey.ERROR_NO_FISH_CATCH_DATA);
        setClickedProduct('IUU', 'FishCatch');
      }
    },
    onError: (error: any) => {
      setLoading(false);
      console.error(error);
      displayAlert(I18nKey.ERROR_MESSAGE_GET);
      setClickedProduct('IUU', 'FishCatch');
    },
  });

  const renderCatchesOnMap = (catchData: any) => {
    if (map) {
      if (
        displayConfig?.data?.IUU?.items.fishCatch.selected &&
        catchData.length
      ) {
        let catchPosition: any = { type: 'FeatureCollection', features: [] };
        catchData.forEach((fishCatch: any) => {
          catchPosition.features.push(
            FishCatchDashboardService.getCatchLocationGeojson(fishCatch)
          );
        });
        FishCatchDashboardService.addCatchestoMap(map, catchPosition);
        loadFishCatchPopUp(map, 'fishcatches');
      }
    }
  };

  const getUniqueSpeciesList = (data: FishCatchData[]) => {
    const species: any = [];
    const filteredCatchData = _.map(data, function (value: any, index: any) {
      value['catchDetails'] = Object.values(value['catchDetails']);
      return value;
    });
    filteredCatchData.map((data: any) => {
      data['catchDetails'].map((catchData: any) => {
        const obj: any = {};
        obj['vesselId'] = data['vesselId'];
        obj['quantity'] = Number(catchData['quantity']);
        if (species.hasOwnProperty(catchData['name'])) {
          const index = _.findIndex(
            species[catchData['name']],
            (e: any) => {
              return e['vesselId'] == obj['vesselId'];
            },
            0
          );
          if (index != -1) {
            species[catchData['name']][index]['quantity'] += obj['quantity'];
          } else {
            species[catchData['name']].push(obj);
          }
        } else species[catchData['name']] = [obj];
      });
    });

    return species;
  };

  const getFishColorsList = (data: FishCatchData[]) => {
    const species = getUniqueSpeciesList(data);
    let fishColorList = [];
    for (const s of Object.keys(species).sort().reverse()) {
      fishColorList.push({ [s]: stringToColorConverter(s.toLowerCase()) });
    }
    return fishColorList;
  };

  const loadFishCatchPopUp = (map: mapboxgl.Map, source: string) => {
    map?.on('click', source, (e: any) => {
      const point = map && map.project([e.lngLat.lng, e.lngLat.lat]);
      const divElement = document.getElementById('fishcatch-popup');
      const elemHeight = divElement && divElement.clientHeight;
      const elemWidth = divElement && divElement.clientWidth;
      const fcData = e.features[0].properties;
      fcData['position'] = getPositionForPopUp({
        latitude: e.lngLat.lat,
        longitude: e.lngLat.lng,
      });
      setShowFishCatchPopUp({
        display: true,
        fcData: fcData,
        position: {
          top: point.y - (elemHeight || 0),
          left: point.x - (elemWidth || 0),
        },
      });
    });
  };

  const getDarkTargetsData = async () => {
    try {
      const data = await IUUClient.generateProductLayer(
        userName,
        date,
        region,
        'DT',
        'layer',
        true
      );
      setDTLayerList(data);
      _.map(data, (url, signal) => {
        getDTGeojson(url, signal);
      });
      setLoading(false);
      map?.flyTo({
        center: [-77, -13],
        speed: 0.5,
      });
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      setClickedProduct('IUU', 'darkTargets');
      if (error.response.status === 400)
        displayAlert(I18nKey.ERROR_MESSAGE_UNAVAILABLE_FOR_REGION);
      else {
        displayAlert(I18nKey.ERROR_MESSAGE_GET);
      }
    }
  };

  const getRFSignalsData = async () => {
    try {
      const data = await IUUClient.generateProductLayer(
        userName,
        date,
        region,
        'RF',
        'layer',
        true
      );
      setRFLayerList(data);
      _.map(data, (url, signal) => {
        getRFGeojson(url, signal);
      });
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      setClickedProduct('IUU', 'RFSignals');
      if (error.response.status === 400)
        displayAlert(I18nKey.ERROR_MESSAGE_UNAVAILABLE_FOR_REGION);
      else {
        displayAlert(I18nKey.ERROR_MESSAGE_GET);
      }
    }
  };

  const getDTGeojson = async (url: string, layername: string) => {
    try {
      const data = await axios.get(url);
      IUUService.drawDarkTargets(
        map,
        data.data,
        layername,
        layerOrder,
        addMapLayerId
      );
      if (layername === 'targets') loadDTPopUp(layername);
    } catch (error) {
      console.log(error);
    }
  };

  const getRFGeojson = async (url: string, signal: string) => {
    try {
      const data = await axios.get(url);
      IUUService.drawRFSignals(
        map,
        data.data,
        signal,
        layerOrder,
        addMapLayerId
      );
      loadRFPopUp(signal);
    } catch (error) {
      console.log(error);
    }
  };

  const getGeofenceGeojson = async (fence: GeofenceDetails) => {
    const geojson = await axios.get(fence.url || '');
    setGeofenceLayerList((prevState: any[]) => [...prevState, fence.id]);
    if (map) {
      FillAreaService.addFillAreaLayer(
        map,
        fence.id || '',
        geojson.data,
        true,
        {
          'fill-color': fence.display.fillColor,
          'fill-opacity': 0.5,
        },
        true,
        'geofence',
        true,
        {
          'line-color': fence.display.strokeColor,
          'line-width': 3,
        },
        addMapLayerId,
        layerOrder
      );
    }
  };

  const getGeofencesData = async () => {
    try {
      const data = await IUUClient.getGeofenceDetails(userName, region);
      setLoading(false);
      setGeofencesData(data);
      if (
        (!displayConfig?.data?.IUU?.items.IUUSettings?.selected ||
          displayConfig?.data?.IUU?.items.geofencing?.selected) &&
        _.isEmpty(data)
      ) {
        displayAlert(I18nKey.ERROR_MESSAGE_GEOFENCE);
        setClickedProduct('IUU', 'geofencing');
      }
    } catch (error: any) {
      console.log({ error });
      if (
        !displayConfig?.data?.IUU?.items.IUUSettings?.selected &&
        error.response.status === 404
      )
        displayAlert(I18nKey.ERROR_MESSAGE_GEOFENCE);
      else {
        displayAlert(I18nKey.ERROR_MESSAGE_GET);
      }
      setLoading(false);
    } finally {
      setGeofenceDataLoaded(true);
    }
  };

  const drawGeofence = () => {
    try {
      let fenceCount: any = 0;
      geofencesData.forEach((fence) => {
        if (fence && fence.active) {
          getGeofenceGeojson(fence);
          fenceCount++;
        }
      });
      if (fenceCount === 0) {
        displayAlert(I18nKey.ERROR_MESSAGE_NO_ACTIVE_GEOFENCE);
        setClickedProduct('IUU', 'geofencing');
      }
    } catch (error) {
      setClickedProduct('IUU', 'geofencing');
      console.log({ error });
      displayAlert(I18nKey.ERROR_MESSAGE_GET);
    } finally {
      setLoading(false);
    }
  };

  const displayAlert = (
    messageKey: any,
    type: string = 'error',
    isDirectMessage: boolean = false
  ) => {
    setAlert({
      type: type,
      display: true,
      message: isDirectMessage
        ? messageKey
        : intl.formatMessage({
            id: messageKey,
          }),
    });
    setTimeout(() => {
      setAlert({
        type: '',
        display: false,
        message: '',
      });
    }, 10000);
  };

  const onCloseTrackPopup = () => {
    setVesselTrackList({ AIS: [], VMS: [] });
    setBuoyTrackIdsList([]);
    setVesselTrackIdsList([]);
    setVesselTrackDuration(
      VesselTrackingService.getTrackDates(trackDefaultDuration)
    );
    setBuoyTrackDuration(
      VesselTrackingService.getTrackDates(trackDefaultDuration)
    );
  };

  const onCloseSettings = () => {
    if (showSettings) {
      setShowSettings(false);
      setClickedProduct('IUU', 'IUUSettings');
    }
  };

  const onCloseAISLegend = () => {
    setClickedProduct('IUU', 'AIS');
  };

  const onAISLegendSettings = () => {
    setClickedProduct('IUU', 'IUUSettings');
  };

  const filterVesselData = (
    vesselSource: string,
    filters: Record<string, any>,
    dataDurationValue: any
  ) => {
    const filteredData = IUUService.filterVesselData(
      vesselSource === 'AIS' ? AISData : VMSData,
      filters,
      dataDurationValue,
      vesselDataFetchToDate
    );
    setFilteredVesselData((prevState) => ({
      ...prevState,
      ...{ [vesselSource]: filteredData },
    }));
    return filteredData;
  };

  const onApply = (
    type: string,
    filterData: Record<string, any>,
    dataDurationValue: number,
    showTracks: Record<string, any>
  ) => {
    setVesselTrackList({
      AIS: [],
      VMS: [],
    });
    localStorage.setItem(type + 'Filters', JSON.stringify(filterData));
    localStorage.setItem('dataDuration', dataDurationValue.toString());
    setDataDuration(dataDurationValue);
    const filteredData = filterVesselData(type, filterData, dataDurationValue);
    setFilters((prevState) => {
      return { ...prevState, [type]: filterData };
    });
    IUUService.removeLayer(map, type, removeMapLayerId);
    if (displayConfig?.data?.IUU?.items[type]?.selected)
      IUUService.drawVeselsOnMap(
        map,
        type,
        filteredData,
        layerOrder,
        addMapLayerId
      );
    if (showTracks.hasOwnProperty(type) && showTracks[type])
      setVesselTrackList((prevState) => {
        return { ...prevState, [type]: _.map(filteredData, 'vesselId') };
      });
    onCloseSettings();
  };

  const filterBuoyData = (data: any, filters: Record<string, any>) => {
    const filteredData = IUUService.filterBuoyData(data, filters);
    return filteredData;
  };

  const onApplyBuoyFilters = () => {
    setLoading(true);
    let filters = JSON.parse(localStorage.getItem('buoyFilter') || '{}');
    getBuoysData(filters.dataDuration)
      .then((data: any) => {
        IUUService.removeLayer(map, 'Buoy', removeMapLayerId);
        setBuoyTrackIdsList([]);
        setBuoyTrackDuration(
          VesselTrackingService.getTrackDates(trackDefaultDuration)
        );

        setFilteredBuoyData(data);
        if (map && !isEmpty(data)) {
          const filteredData = filterBuoyData(
            data,
            _.pick(JSON.parse(localStorage.getItem('buoyFilter') || '{}'), [
              'vesselName',
              'buoysId',
            ])
          );
          setFilteredBuoyData(filteredData);
          if (displayConfig?.data?.IUU?.items.buoys?.selected) {
            IUUService.drawVeselsOnMap(
              map,
              'Buoy',
              filteredData,
              layerOrder,
              addMapLayerId
            );
            map && map?.on('click', 'Buoy', (e: any) => addBuoyPopUp(e));
            if (filters.showTracks) {
              setBuoyTrackIdsList((prev: any) =>
                _.uniq([...prev, ...filters.buoysId])
              );
            }
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
      });
  };

  const filterVesselsData = (data: any, filters: Record<string, any>) => {
    const nonEmptyFilters = _.omitBy(filters, isEmpty);
    const filteredVesselsForHigherLevel = _.filter(data, (item) => {
      return Object.keys(nonEmptyFilters).every((key) =>
        nonEmptyFilters[key].includes(item[key] || '')
      );
    });
    let filteredData = filteredVesselsForHigherLevel;
    return filteredData;
  };

  const onApplyVesselFilters = () => {
    setLoading(true);
    let filters = JSON.parse(localStorage.getItem('vesselFilter') || '{}');
    getVesselsData(filters.dataDuration)
      .then((data: any) => {
        IUUService.removeLayer(map, 'Vessel', removeMapLayerId);
        setVesselTrackIdsList([]);
        setVesselTrackDuration(
          VesselTrackingService.getTrackDates(trackDefaultDuration)
        );

        setFilteredVesselsData(data);
        if (map && !isEmpty(data)) {
          const filteredData = filterVesselsData(
            data,
            _.pick(JSON.parse(localStorage.getItem('vesselFilter') || '{}'), [
              'vesselName',
            ])
          );
          setFilteredVesselsData(filteredData);
          if (displayConfig?.data?.IUU?.items.vessel?.selected) {
            IUUService.drawVeselsOnMap(
              map,
              'Vessel',
              filteredData,
              layerOrder,
              addMapLayerId
            );
            map && map?.on('click', 'Vessel', (e: any) => addVesselPopUp(e));
            if (filters.showTracks) {
              setVesselTrackIdsList((prev: any) =>
                _.uniq([...prev, ...filteredData.map((v) => v.vesselId)])
              );
            }
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
      });
  };

  const onApplyFishCatchFilters = () => {
    setLoading(true);
    map && FishCatchDashboardService.removeCatchLayers(map);
    let filters = JSON.parse(localStorage.getItem('fishCatchFilter') || '{}');
    let data = cloneDeep(fishCatchData);
    const filteredData = IUUService.filterFishCatchData(data, filters);
    renderCatchesOnMap(filteredData);
    setLoading(false);
  };

  const toggleTracks = () => {
    vesselSources.forEach((source) => {
      if (!isEmpty(trackData)) {
        VesselTrackingService.turnOffVesselTracks(
          map,
          Object.values(trackData),
          setTrackData
        );
        setTrackData({});
      }
      if (displayConfig?.data?.IUU?.items[source]?.selected) {
        if (vesselTrackList[source].length > 0) {
          let tracklist;
          tracklist = vesselTrackList[source].join(',');
          getTracksData(
            userName,
            trackDuration.fromDate,
            trackDuration.toDate,
            tracklist,
            source
          );
        }
      }
    });
  };

  const toggleBuoyTracks = () => {
    if (!isEmpty(buoyTrackData)) {
      VesselTrackingService.turnOffVesselTracks(
        map,
        Object.values(buoyTrackData),
        setBuoyTrackData
      );
      setBuoyTrackData({});
    }
    if (displayConfig?.data?.IUU?.items.buoys?.selected) {
      if (buoyTrackIdsList.length > 0) {
        let tracklist;
        tracklist = buoyTrackIdsList.join(',');
        getVesselBuoyTracksData(
          'buoy',
          tracklist,
          buoyTrackDuration.toDate,
          buoyTrackDuration.fromDate
        );
      }
    }
  };

  const toggleVesselTracks = () => {
    if (!isEmpty(vesselTrackData)) {
      VesselTrackingService.turnOffVesselTracks(
        map,
        Object.values(vesselTrackData),
        setVesselTrackData
      );
      setVesselTrackData({});
    }
    if (displayConfig?.data?.IUU?.items.vessel?.selected) {
      if (vesselTrackIdsList.length > 0) {
        let tracklist;
        tracklist = vesselTrackIdsList.join(',');
        getVesselBuoyTracksData(
          'vessel',
          tracklist,
          vesselTrackDuration.toDate,
          vesselTrackDuration.fromDate
        );
      }
    }
  };

  const getBreachData = async () => {
    try {
      const data = await IUUClient.getBreachDetails(
        userName,
        IUUService.getDates(
          parseInt(localStorage.getItem('dataDuration') || '')
        ).fromDate,
        IUUService.getDates(
          parseInt(localStorage.getItem('dataDuration') || '')
        ).toDate
      );
      const grouped = _.groupBy(data, 'vessel.vesselId');
      let breachVesselData: Record<string, BreachVesselDetails> = {};
      Object.keys(grouped).forEach((vessel) => {
        if (grouped[vessel].length) {
          breachVesselData[vessel] = _.sortBy(
            grouped[vessel],
            function (dateObj) {
              return new Date(dateObj.breachDate);
            }
          )[0];
        }
      });
      setBreachData(breachVesselData);
    } catch (error) {
      setBreachData({});
      console.log({ error });
      displayAlert(I18nKey.BREACH_ERROR);
    } finally {
      setLoadingBreachData(false);
    }
  };
  const addPopUp = (e: any, source: string) => {
    const divElement = document.getElementById('iuu-vessel-popup');
    const elemHeight = divElement && divElement.clientHeight;
    const elemWidth = divElement && divElement.clientWidth;
    const point = map && map?.project([e.lngLat.lng, e.lngLat.lat]);
    const vesselDetails = e.features[0].properties;
    vesselDetails['position'] = getPositionForPopUp({
      latitude: vesselDetails.lat,
      longitude: vesselDetails.lon,
    });
    if (point) {
      setShowPopUp({
        display: true,
        vessel: vesselDetails,
        layer: source,
        position: {
          top: point.y - (elemHeight || 0),
          left: point.x - (elemWidth || 0),
        },
      });
    }
  };

  const loadPopUp = (source: string) => {
    map?.on('click', source, (e: any) => addPopUp(e, source));
  };

  const addTrackPopUp = (e: any) => {
    const divElement = document.getElementById('iuu-vessel-track-popup');
    const elemHeight = divElement && divElement.clientHeight;
    const elemWidth = divElement && divElement.clientWidth;
    const point = map && map.project([e.lngLat.lng, e.lngLat.lat]);
    const trackData = e.features[0].properties;
    trackData['position'] = getPositionForPopUp({
      latitude: e.lngLat.lat,
      longitude: e.lngLat.lng,
    });

    if (point) {
      setShowTracksPopUp({
        display: true,
        trackData: trackData,
        position: {
          top: point?.y - (elemHeight || 0),
          left: point?.x - (elemWidth || 0),
        },
      });
    }
  };

  const loadTrackPopUp = (source: string) => {
    map?.on('click', source, (e: any) => addTrackPopUp(e));
  };

  const addRFPopUp = (e: any) => {
    const divElement = document.getElementById('iuu-rf-popup');
    const elemHeight = divElement && divElement.clientHeight;
    const elemWidth = divElement && divElement.clientWidth;
    const point = map && map.project([e.lngLat.lng, e.lngLat.lat]);
    const rfData = e.features[0].properties;
    rfData['position'] = getPositionForPopUp({
      latitude: e.lngLat.lat,
      longitude: e.lngLat.lng,
    });
    if (point) {
      popupOpenTimeout.current = setTimeout(
        () =>
          setShowRFPopUp({
            display: true,
            rfData: rfData,
            position: {
              top: point?.y - (elemHeight || 0),
              left: point?.x - (elemWidth || 0),
            },
          }),
        1000
      );
    }
  };

  const removeRFPopUp = () => {
    clearTimeout(popupOpenTimeout.current as NodeJS.Timeout);
    popupCloseTimeout.current = setTimeout(
      () =>
        setShowRFPopUp({
          display: false,
          rfData: {},
          position: {},
        }),
      4000
    );
  };

  const loadRFPopUp = (source: string) => {
    map?.on('click', source, (e: any) => addRFPopUp(e));
    map?.on('mouseleave', source, () => removeRFPopUp());
  };

  const addDTPopUp = (e: any) => {
    const divElement = document.getElementById('iuu-dt-popup');
    const elemHeight = divElement && divElement.clientHeight;
    const elemWidth = divElement && divElement.clientWidth;
    const point = map && map.project([e.lngLat.lng, e.lngLat.lat]);
    const dtData = e.features[0].properties;
    dtData['position'] = getPositionForPopUp({
      latitude: dtData.Latitude,
      longitude: dtData.Longitude,
    });
    if (point) {
      popupOpenTimeout.current = setTimeout(
        () =>
          setShowDTPopUp({
            display: true,
            dtData: dtData,
            position: {
              top: point.y - (elemHeight || 0),
              left: point.x - (elemWidth || 0),
            },
          }),
        1000
      );
    }
  };

  const removeDTPopUp = () => {
    clearTimeout(popupOpenTimeout.current as NodeJS.Timeout);
    popupCloseTimeout.current = setTimeout(
      () =>
        setShowDTPopUp({
          display: false,
          dtData: {},
          position: {},
        }),
      4000
    );
  };

  const addBuoyPopUp = (e: any) => {
    const divElement = document.getElementById('buoy-popup');
    const elemHeight = divElement && divElement.clientHeight;
    const elemWidth = divElement && divElement.clientWidth;
    const point = map && map.project([e.lngLat.lng, e.lngLat.lat]);
    const buoyData = e.features[0].properties;
    buoyData['position'] = getPositionForPopUp({
      latitude: buoyData.lat,
      longitude: buoyData.lon,
    });
    if (point) {
      setShowBuoyPopUp({
        display: true,
        buoyData: buoyData,
        position: {
          top: point?.y - (elemHeight || 0),
          left: point?.x - (elemWidth || 0),
        },
      });
    }
  };

  const addVesselPopUp = (e: any) => {
    const divElement = document.getElementById('vessel-popup');
    const elemHeight = divElement && divElement.clientHeight;
    const elemWidth = divElement && divElement.clientWidth;
    const point = map && map.project([e.lngLat.lng, e.lngLat.lat]);
    const vesselData = e.features[0].properties;
    vesselData['position'] = getPositionForPopUp({
      latitude: vesselData.lat,
      longitude: vesselData.lon,
    });
    if (point) {
      setShowVesselPopUp({
        display: true,
        vesselData: vesselData,
        position: {
          top: point?.y - (elemHeight || 0),
          left: point?.x - (elemWidth || 0),
        },
      });
    }
  };

  const loadDTPopUp = (source: string) => {
    map?.on('click', source, (e: any) => addDTPopUp(e));
    map?.on('mouseleave', source, () => removeDTPopUp());
  };

  const deleteGeofence = async (geofenceId: string) => {
    try {
      setLoading(true);
      await IUUClient.deleteGeofence(userName, geofenceId);
      if (geofenceId) {
        removeMapLayerId('geofence', geofenceId);
        if (map) {
          removeLayerSource(map, geofenceId, [
            geofenceId,
            geofenceId + 'outline',
          ]);
        }
        setGeofenceLayerList(
          geofenceLayerList.filter((id: any) => id !== geofenceId)
        );
      }
      getGeofencesData();
    } catch (error) {
      setLoading(false);
      displayAlert(I18nKey.IUU_SETTINGS_GEOFENCE_DELETE_ERROR_MESSAGE);
      console.log({ error });
    }
  };

  const getBuoysFilesList = async () => {
    return await IUUClient.getBuoysFiles();
  };
  const fetchBuoyFiles = () => {
    getBuoysFilesList()
      .then((data) => {
        setBuoyFileData(data);
      })
      .catch((e) => {
        displayAlert(I18nKey.IUU_SETTINGS_GET_ERROR_MSG);
        console.error(e);
      });
  };

  const deleteBuoysFile = async (buoysFileId: any[]) => {
    try {
      await IUUClient.deleteBuoysFiles(buoysFileId);
      fetchBuoysData();
      fetchBuoyFiles();
      displayAlert(I18nKey.IUU_SETTINGS_DELETE_FILE_SUCCESS, 'success');
    } catch (error) {
      displayAlert(I18nKey.IUU_SETTINGS_DELETE_ERROR_MSG);
      console.log({ error });
    }
  };

  const getVesselsFilesList = async () => {
    return await IUUClient.getVesselFiles();
  };
  const fetchVesselsFiles = () => {
    getVesselsFilesList()
      .then((data) => {
        setVesselFileData(data);
      })
      .catch((e) => {
        displayAlert(I18nKey.IUU_SETTINGS_GET_ERROR_MSG);
        console.error(e);
      });
  };

  const deleteVesselsFile = async (vesselFileId: any[]) => {
    try {
      await IUUClient.deleteVesselFiles(vesselFileId);
      fetchVesselsData();
      fetchVesselsFiles();
      displayAlert(I18nKey.IUU_SETTINGS_DELETE_FILE_SUCCESS, 'success');
    } catch (error) {
      displayAlert(I18nKey.IUU_SETTINGS_DELETE_ERROR_MSG);
      console.log({ error });
    }
  };

  const onSaveGeofence = async (
    userName: string,
    geofenceData: GeofenceDetails,
    geofenceId?: string
  ) => {
    try {
      await IUUClient.saveGeofence(userName, geofenceData, geofenceId);
      if (geofenceId) {
        removeMapLayerId('geofence', geofenceId);
        if (map) {
          removeLayerSource(map, geofenceId, [
            geofenceId,
            geofenceId + 'outline',
          ]);
        }
        setGeofenceLayerList(
          geofenceLayerList.filter((id: any) => id !== geofenceId)
        );
      }
      getGeofencesData();
    } catch (error) {
      console.log({ error });
      displayAlert(I18nKey.IUU_SETTINGS_GEOFENCE_SAVE_ERROR_MESSAGE);
    }
  };

  const onUploadVBData = async (dataType: string) => {
    setLoading(true);
    if (dataType === 'buoy') {
      fetchBuoysData();
      fetchBuoyFiles();
    }
    if (dataType === 'vessel') {
      fetchVesselsData();
      fetchVesselsFiles();
    }
  };

  const getTracksData = async (
    userName: string,
    fromDate: string,
    toDate: string,
    vesselId: string,
    vesselSource: string
  ) => {
    try {
      setLoading(true);
      const tracksData = await IUUClient.getVesselTrackDetails(
        userName,
        fromDate,
        toDate,
        vesselId,
        vesselSource
      );
      let obj: { [k: string]: any } = {};
      Object.keys(tracksData).forEach((v: any) => {
        obj[v] = _.find(vesselSource === 'AIS' ? AISData : VMSData, {
          vesselId: v,
        });
        obj[v]['trackData'] = tracksData[v];
        obj[v]['showTrack'] = true;
        obj[v]['source'] = vesselSource;
        setTrackData((prevState) => ({ ...prevState, ...obj }));
        loadTrackPopUp(v + 'dots');

        VesselTrackingService.showVesselTracks(map, obj[v]);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      displayAlert(I18nKey.ERROR_MESSAGE_GET);
      console.log({ error });
    }
  };

  const getVesselBuoyTracksData = async (
    dataType: string,
    ids: string,
    toDate: string,
    fromDate: string
  ) => {
    try {
      setLoading(true);
      const tracksData = await IUUClient.getVesselBuoyTrack(
        dataType,
        ids,
        toDate,
        fromDate
      );
      if (dataType === 'vessel') {
        let obj: { [k: string]: any } = {};
        Object.keys(tracksData).forEach((id: any) => {
          obj[id] = _.find(vesselData, {
            vesselId: id,
          });
          let modifiedTracks = tracksData[id].map((t: any) => {
            return { ...t, ...{ positions: `${t.latitude},${t.longitude}` } };
          });
          obj[id]['trackData'] = modifiedTracks;
          obj[id]['showTrack'] = true;
          obj[id]['source'] = dataType;
          setVesselTrackData((prevState) => ({ ...prevState, ...obj }));
          VesselTrackingService.showVesselTracks(map, obj[id]);
        });
      }
      if (dataType === 'buoy') {
        let obj: { [k: string]: any } = {};
        Object.keys(tracksData).forEach((id: any) => {
          obj[id] = _.find(buoyData, {
            buoysId: id,
          });
          let modifiedTracks = tracksData[id].map((t: any) => {
            return { ...t, ...{ positions: `${t.latitude},${t.longitude}` } };
          });
          obj[id]['vesselId'] = id;
          obj[id]['trackData'] = modifiedTracks;
          obj[id]['showTrack'] = true;
          obj[id]['source'] = dataType;
          setBuoyTrackData((prevState) => ({ ...prevState, ...obj }));
          VesselTrackingService.showVesselTracks(map, obj[id]);
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      displayAlert(I18nKey.ERROR_MESSAGE_GET);
      console.log({ error });
    }
  };

  const userRegion = useContextSelector(
    UserSettingsContext,
    (state) => state.userSettings.map?.regionsOfInterest || 'PCIDN'
  );

  type AisParams = {
    vesselSource: string;
    userName: string;
    fromDate: string;
    toDate: string;
    bottomLeft: string;
    topRight: string;
    region: string;
  };

  const getAISData = async (params: AisParams) => {
    setVesselDataFetchToDate(params.toDate);
    return await IUUClient.getAllAISData(params);
  };

  const { refetch: fetchAISData } = useQuery<any>(
    'aisData',
    () => {
      return (
        features.includes('AIS') &&
        getAISData({
          vesselSource: 'AIS',
          userName: userName,
          fromDate: IUUService.getDates(24).fromDate,
          toDate: IUUService.getDates(24).toDate,
          bottomLeft: IUUService.getBounds(map).bottomLeft,
          topRight: IUUService.getBounds(map).topRight,
          region: userRegion,
        })
      );
    },
    {
      enabled: false,
      retry: false,
      onSuccess: (data: AISData[]) => {
        if (
          data.length === 0 &&
          displayConfig?.data?.IUU?.items.AIS?.selected
        ) {
          // Display error and unselect AIS when no data
          displayAlert(I18nKey.PRODUCTS_NO_DATA_MSG);
          setClickedProduct('IUU', 'AIS');
        }
        setAISData(data);
        setLoading(false);
        setVesselDataIsLoading((prevState) => {
          return { ...prevState, AIS: false };
        });
      },
      onError: (error: any) => {
        console.log({ error });
        setLoading(false);
        setVesselDataIsLoading((prevState) => {
          return { ...prevState, AIS: false };
        });
        displayAlert(I18nKey.ERROR_MESSAGE_GET);
        setClickedProduct('IUU', 'AIS');
      },
    }
  );

  const getVMSData = async (params: any) => {
    setVesselDataFetchToDate(params.toDate);
    return await IUUClient.getAllAISData(params);
  };

  const { refetch: fetchVMSData } = useQuery<any>(
    'vmsData',
    () =>
      getVMSData({
        vesselSource: 'VMS',
        userName: userName,
        fromDate: IUUService.getDates(24).fromDate,
        toDate: IUUService.getDates(24).toDate,
        bottomLeft: IUUService.getBounds(map, 'VMS').bottomLeft,
        topRight: IUUService.getBounds(map, 'VMS').topRight,
      }),
    {
      retry: false,
      enabled: false,
      onSuccess: (data: AISData[]) => {
        if (
          data.length === 0 &&
          displayConfig?.data?.IUU?.items.VMS?.selected
        ) {
          // Display error and unselect VMS when no data
          displayAlert(I18nKey.PRODUCTS_NO_DATA_MSG);
          setClickedProduct('IUU', 'VMS');
        }
        setVMSData(data);
        setLoading(false);
        setVesselDataIsLoading((prevState) => {
          return { ...prevState, VMS: false };
        });
      },
      onError: (error: any) => {
        console.log({ error });
        setLoading(false);
        setVesselDataIsLoading((prevState) => {
          return { ...prevState, VMS: false };
        });
        displayAlert(I18nKey.ERROR_MESSAGE_GET);
        setClickedProduct('IUU', 'VMS');
      },
    }
  );

  const getBuoysData = async (params: vesselBuoyAPIParams) => {
    // setVesselDataFetchToDate(params.toDate);
    return await IUUClient.getAllBuoysData(params);
  };

  const { refetch: fetchBuoysData } = useQuery<any>(
    'buoyData',
    () => {
      return getBuoysData({});
    },
    {
      enabled: false,
      retry: false,
      onSuccess: (data: any[]) => {
        if (
          data.length === 0 &&
          displayConfig?.data?.IUU?.items.buoys?.selected
        ) {
          setLoading(false);
          setBuoyData(() => data);
          displayAlert(I18nKey.IUU_SETTINGS_BUOY_NO_DATA_MSG);
          setClickedProduct('IUU', 'buoys');
        }
        setBuoyData(() => data);
        setLoading(false);
      },
      onError: (error: any) => {
        console.log({ error });
        setLoading(false);
        displayAlert(I18nKey.IUU_SETTINGS_BUOY_NO_DATA_MSG);
        setClickedProduct('IUU', 'buoys');
      },
    }
  );
  const getVesselsData = async (params: vesselBuoyAPIParams) => {
    return await IUUClient.getAllVesselsData(params);
  };

  const { refetch: fetchVesselsData } = useQuery<any>(
    'vesselData',
    () => {
      return getVesselsData({});
    },
    {
      enabled: false,
      retry: false,
      onSuccess: (data: any[]) => {
        if (
          data.length === 0 &&
          displayConfig?.data?.IUU?.items.vessel?.selected
        ) {
          setLoading(false);
          setVesselData(data);
          displayAlert(I18nKey.IUU_SETTINGS_VESSEL_NO_DATA_MSG);
          setClickedProduct('IUU', 'vessel');
        }
        setVesselData(data);
        setLoading(false);
      },
      onError: (error: any) => {
        console.log({ error });
        setLoading(false);
        displayAlert(I18nKey.IUU_SETTINGS_VESSEL_NO_DATA_MSG);
        setClickedProduct('IUU', 'vessel');
      },
    }
  );

  return (
    <>
      {showPopUp.display && (
        <Box sx={{ position: 'fixed' }}>
          <AISPopUp
            vessel={showPopUp.vessel}
            setVesselTrackList={setVesselTrackList}
            vesselTrackList={vesselTrackList}
            layerType={showPopUp.layer}
            setShowPopUp={setShowPopUp}
            position={showPopUp.position}
            breachData={breachData}
            isShowPopUp={showPopUp.display}
          />
        </Box>
      )}
      {showTracksPopUp.display && (
        <Box sx={{ position: 'fixed' }}>
          <TrackPopUp
            trackData={showTracksPopUp.trackData}
            position={showTracksPopUp.position}
            setShowTracksPopUp={setShowTracksPopUp}
            isShowPopUp={showTracksPopUp.display}
          ></TrackPopUp>
        </Box>
      )}
      {showRFPopUp.display && (
        <Box
          sx={{ position: 'fixed' }}
          onMouseEnter={() =>
            clearTimeout(popupCloseTimeout.current as NodeJS.Timeout)
          }
          onMouseLeave={() =>
            setShowRFPopUp({
              display: false,
              rfData: {},
              position: {},
            })
          }
        >
          <RFPopUp
            rfData={showRFPopUp.rfData}
            setShowRFPopUp={setShowRFPopUp}
            position={showRFPopUp.position}
          ></RFPopUp>
        </Box>
      )}
      {showDTPopUp.display && (
        <Box
          sx={{ position: 'fixed' }}
          onMouseEnter={() =>
            clearTimeout(popupCloseTimeout.current as NodeJS.Timeout)
          }
          onMouseLeave={() =>
            setShowDTPopUp({
              display: false,
              dtData: {},
              position: {},
            })
          }
        >
          <DTPopUp
            dtData={showDTPopUp.dtData}
            setShowDTPopUp={setShowDTPopUp}
            position={showDTPopUp.position}
          ></DTPopUp>
        </Box>
      )}
      {showBuoyPopUp.display && (
        <Box sx={{ position: 'fixed' }}>
          <BuoyPopUp
            buoyData={showBuoyPopUp.buoyData}
            setShowBuoyPopUp={setShowBuoyPopUp}
            position={showBuoyPopUp.position}
            setTrackList={setBuoyTrackIdsList}
            trackList={buoyTrackIdsList}
            isShowPopUp={showBuoyPopUp.display}
          ></BuoyPopUp>
        </Box>
      )}
      {showVesselPopUp.display && (
        <Box sx={{ position: 'fixed' }}>
          <VesselsPopUp
            vesselData={showVesselPopUp.vesselData}
            setShowVesselPopUp={setShowVesselPopUp}
            position={showVesselPopUp.position}
            setTrackList={setVesselTrackIdsList}
            trackList={vesselTrackIdsList}
            isShowPopUp={showVesselPopUp.display}
          ></VesselsPopUp>
        </Box>
      )}
      {showFishCatchPopUp.display && (
        <Box sx={{ position: 'fixed' }}>
          <FishCatchPopUp
            fishColorsList={getFishColorsList(fishCatchData)}
            fcData={showFishCatchPopUp.fcData}
            setShowFishCatchPopUp={setShowFishCatchPopUp}
            position={showFishCatchPopUp.position}
            isShowPopUp={showFishCatchPopUp.display}
          ></FishCatchPopUp>
        </Box>
      )}
      {(loading || loadingBreachData) && (
        <ProgressSpinner showSpinner={loading || loadingBreachData} />
      )}
      {alert.display && (
        <Snackbar
          type={alert.type}
          display={alert.display}
          message={alert.message}
        ></Snackbar>
      )}
      {showSettings && (
        <Box
          sx={
            openSettings
              ? MapMainStyles.openSetting
              : MapMainStyles.closeSetting
          }
        >
          <IUUSettings
            onClose={onCloseSettings}
            onApply={onApply}
            AISData={AISData}
            VMSData={VMSData}
            GeofenceListData={geofencesData}
            buoyData={buoyData}
            filteredBuoyData={filteredBuoyData}
            filteredVesselData={filteredVesselsData}
            vesselData={vesselData}
            onUploadVBData={onUploadVBData}
            onApplyBuoyFilters={onApplyBuoyFilters}
            onApplyVesselFilters={onApplyVesselFilters}
            geofenceDataLoaded={geofenceDataLoaded}
            deleteGeofence={deleteGeofence}
            onSaveGeofence={onSaveGeofence}
            dataIsLoading={vesselDataIsLoading}
            setLoading={setLoading}
            displayAlert={displayAlert}
            buoyFileData={buoyFileData}
            deleteBuoyFileData={deleteBuoysFile}
            vesselFileData={vesselFileData}
            deleteVesselFileData={deleteVesselsFile}
            fishCatchData={fishCatchData}
            onApplyFishCatchFilters={onApplyFishCatchFilters}
          />
        </Box>
      )}
      {displayConfig?.data?.IUU?.items.AIS?.selected && (
        <Box
          sx={[
            { position: 'absolute', left: '4%' },
            layoutState.ProductLegend ||
            (layoutState.Recommendations && layoutState.AISnVMSTracks)
              ? { bottom: '26%' }
              : { bottom: '5%' },
            layoutState.Recommendations && !layoutState.AISnVMSTracks
              ? { left: AISLegendLeftPosition }
              : { left: '4%' },
          ]}
        >
          <AISLegends
            onAISLegendSettings={onAISLegendSettings}
            AISLegendFilteredData={AISLegendFilteredData}
            onCloseSettings={onCloseAISLegend}
          />
        </Box>
      )}
      {((displayConfig?.data?.IUU?.items.AIS?.selected &&
        !isEmpty(vesselTrackList['AIS'])) ||
        (displayConfig?.data?.IUU?.items.VMS?.selected &&
          !isEmpty(vesselTrackList['VMS'])) ||
        (displayConfig?.data?.IUU?.items.buoys?.selected &&
          !isEmpty(buoyTrackIdsList)) ||
        (displayConfig?.data?.IUU?.items.vessel?.selected &&
          !isEmpty(vesselTrackIdsList))) && (
        <Box
          sx={[
            { position: 'absolute' },
            layoutState.AISLegend
              ? { left: tracksLeftPosition }
              : { left: '4%' },
            layoutState.ProductLegend ||
            (layoutState.Recommendations && layoutState.AISnVMSTracks)
              ? { bottom: '26%' }
              : { bottom: '5%' },
          ]}
        >
          <IUUTimeline
            onCloseTrackPopup={onCloseTrackPopup}
            setTrackDuration={setTrackDuration}
            setBuoyTrackDuration={setBuoyTrackDuration}
            setVesselTrackDuration={setVesselTrackDuration}
            AISTrackList={vesselTrackList}
            buoyTrackList={buoyTrackIdsList}
            vesselTrackList={vesselTrackIdsList}
          />
        </Box>
      )}
    </>
  );
};
