import { Footer } from '../../components/Footer/Footer';
import { CopyRightSection } from '../../components/Footer/CopyRightSection';
import { IUUDashboardStyles } from './IUUDashboardStyles';

const IUUFooter: React.FC = () => {
  return (
    <Footer
      footerStyle={IUUDashboardStyles.footer}
      centerElementStyle={IUUDashboardStyles.centerElement}
      centerElement={
        <CopyRightSection
          sectionStyle={IUUDashboardStyles.section}
          addDivider={false}
        />
      }
      leftElementStyle=""
      alignGridItems="center"
    />
  );
};

export default IUUFooter;
