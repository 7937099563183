import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import UserClient from '../../api/userAPIs';
import { RegionsList } from '../types';
import { createContext } from 'use-context-selector';

export const RegionContext = createContext([] as any);

export const RegionContextProvider: React.FC = (props) => {
  const [regions, setRegions] = useState<RegionsList[]>([]);

  const getRegionList = async (): Promise<any> => {
    const regions = await UserClient.getRegionsList();
    if (!_.isEmpty(regions)) setRegions(regions);
  };

  useEffect(() => {
    getRegionList();
  }, []);

  return (
    <RegionContext.Provider value={regions}>
      {props.children}
    </RegionContext.Provider>
  );
};
