import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { I18nKey } from '../../translations/I18nKey';
import './Default.scss';
import { ProgressSpinner } from '../../components/ProgressSpinner/ProgressSpinner';

const Default: React.FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const [loading, setLoading] = React.useState(true);

  const goToHome = () => {
    navigate('/');
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);


  return (
    <>
    {loading ? (
      <ProgressSpinner />
    ) : (
    <Box className="wrapper">
      <SentimentVeryDissatisfiedIcon />
      <Typography variant="h1" mt={1}>
        {intl.formatMessage({
          id: I18nKey.NOT_FOUND_TITLE,
        })}
      </Typography>
      <Typography variant="h4">
        {intl.formatMessage({
          id: I18nKey.NOT_FOUND_SUBTITLE,
        })}
      </Typography>
      <Typography mt={3}>
        {intl.formatMessage({
          id: I18nKey.NOT_FOUND_MESSAGE,
        })}
      </Typography>
      <Box className="action-msg-wrapper">
        <Button variant="text" onClick={goToHome}>
          {intl.formatMessage({
            id: I18nKey.NOT_FOUND_BTN_TITLE,
          })}
        </Button>
        <Typography>
          {intl.formatMessage({
            id: I18nKey.NOT_FOUND_ACTION_MSG,
          })}
        </Typography>
      </Box>
    </Box>
    )}
    </>
  );
};
export default Default;
