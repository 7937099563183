import { I18nKey } from '../../translations/I18nKey';

const PathConfig: Record<string, any> = {
  '/sign-in': {
    displayBottomSection: true,
    footerTextKey: Object.keys(I18nKey).indexOf('DONT_HAVE_AN_ACCOUNT'),
    footerAction: 'sign-up',
    footerSubTextKey: Object.keys(I18nKey).indexOf('SIGN_UP'),
  },
  '/sign-up': {
    displayBottomSection: true,
    footerTextKey: Object.keys(I18nKey).indexOf('ALREADY_HAVE_AN_ACCOUNT'),
    footerAction: 'sign-in',
    footerSubTextKey: Object.keys(I18nKey).indexOf('SIGN_IN'),
  },
  '/confirm-sign-up': {
    displayBottomSection: false,
  },
  '/forgot-password': {
    displayBottomSection: false,
  },
};

export const getPathConfig = (path: any) => {
  return Object.keys(PathConfig).includes(path)
    ? PathConfig[path]
    : PathConfig['/sign-in'];
};
