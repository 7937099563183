import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Link,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { point, polygon, multiPolygon } from '@turf/helpers';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import moment from 'moment';
import _ from 'lodash';
import { useContextSelector } from 'use-context-selector';
import { CustomLinearProgress } from '../../../../../components/CustomLinearProgress/CustomLinearProgress';
import { I18nKey } from '../../../../../translations/I18nKey';
import { getUserAttributes } from '../../../../../utils/auth';
import { dateToString, useStyles } from '../../../../../utils/util';
import { UserSettingsContext } from '../../../../Contexts/UserSettingsContext';
import { IUUSettingsStyles, tabIndicatorProps } from '../IUUSettingsStyles';
import { GeofenceGeneral } from './GeofenceGeneral';
import { GeofenceCRUDStyles } from './GeofenceStyles';
import { geofenceGeneralType, geofenceWatchlistType } from './GeofenceTypes';
import { GeofenceWatchlist } from './GeofenceWatchlist';
import { removeLayerSource } from '../../../HelperService';
import { MapContext } from '../../../MapContext';
import { LicensedFeaturesContext } from '../../../../Contexts/LicensedFeaturesContext';

const getListFromArray = (array: any[], key: string) => {
  return array.map((element: any) => element[key]);
};

export const GeofenceCRUD: React.FC<any> = ({
  setGeofenceEditMode,
  selectedGeofenceRow,
  AISData,
  VMSData,
  saveGeofenceData,
  setSaveGeofenceData,
  onSaveGeofence,
  dataIsLoading,
  displayAlert,
}) => {
  const map = useContextSelector(MapContext, (state) => state.map);
  const features = useContextSelector(
    LicensedFeaturesContext,
    (state) => state.features
  );
  const [geofenceName, setGeofenceName] = useState<string>(
    selectedGeofenceRow ? selectedGeofenceRow.name : ''
  );
  const [tabName, setTabName] = useState<string>('GENERAL');
  const userName = getUserAttributes().userName;
  const region = useContextSelector(
    UserSettingsContext,
    (state) => state.userSettings.map?.regionsOfInterest || ''
  );

  const [geofenceGeneral, setGeofenceGeneral] = useState<geofenceGeneralType>({
    active: true,
    type: 'alert',
    startDate: new Date(),
    endDate: new Date(),
    description: '',
    filename: '',
    file: '',
    createdOn: dateToString(moment()),
    display: {
      fillColor: '#8C00AE',
      strokeColor: 'red',
      fillWidth: 3,
    },
    email: '',
    uploadedFileName: '',
    data: 'draw',
  });

  const [geofenceWatchlist, setGeofenceWatchlist] =
    useState<geofenceWatchlistType>({
      ais: {
        selectedVesselType: [],
        selectedCountryName: [],
        selectedVesselName: [],
        selectedMMSI: [],
        speed: {
          fromSpeed: 0,
          toSpeed: 25,
        },
      },
      vms: {
        selectedCountryName: [],
        selectedVesselName: [],
        speed: {
          fromSpeed: 0,
          toSpeed: 25,
        },
      },
    });
  const [error, setError] = useState<any>({
    name: false,
    email: false,
  });

  const classes = useStyles(IUUSettingsStyles, [])();
  const intl = useIntl();

  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabName(newValue);
  };

  const onGeofenceNameChange = (event: any) => {
    setGeofenceName(event.target.value);
    setError({
      name: false,
      email: false,
    });
  };

  const getArrayOfObjects = (array: any[], key: string) => {
    return array.map((element: any) => {
      return { [key]: element };
    });
  };

  const getFeaturesFromPolygon = (filedata: any) => {
    let polygonData: any =
      filedata.features[0].geometry.type === 'MultiPolygon'
        ? multiPolygon(filedata.features[0].geometry.coordinates)
        : polygon(filedata.features[0].geometry.coordinates);
    return polygonData;
  };

  const getVesselsInPolygon = (data: any) => {
    let vesselsInPolygon = data
      .map((v: any) => {
        if (
          booleanPointInPolygon(
            point([v.longitude, v.latitude]),
            getFeaturesFromPolygon(geofenceGeneral.filedata)
          )
        )
          return v.vesselId;
      })
      .filter((d: any) => d !== undefined);
    return vesselsInPolygon.join(',');
  };

  useEffect(
    () => () => {
      map &&
        removeLayerSource(map, 'geofenceDrawing', [
          'geofenceDrawing',
          'geofenceDrawingoutline',
        ]);
    },
    []
  );

  useEffect(() => {
    if (selectedGeofenceRow) {
      setGeofenceGeneral({
        ...geofenceGeneral,
        id: selectedGeofenceRow.id,
        userId: selectedGeofenceRow.userId,
        active: selectedGeofenceRow.active,
        type: selectedGeofenceRow.type,
        startDate: new Date(selectedGeofenceRow.startDate),
        endDate: new Date(selectedGeofenceRow.endDate),
        description: selectedGeofenceRow.descr,
        filename: selectedGeofenceRow.filename,
        createdOn: selectedGeofenceRow.createdOn,
        display: {
          fillColor: selectedGeofenceRow.display.fillColor,
          strokeColor: selectedGeofenceRow.display.strokeColor,
          fillWidth: selectedGeofenceRow.display.strokeThickness,
        },
        email: selectedGeofenceRow.alert.emailId,
        uploadedFileName: selectedGeofenceRow.filename,
        url: selectedGeofenceRow.url,
        data: selectedGeofenceRow.upload ? 'upload' : 'draw',
      });
      setGeofenceWatchlist({
        ais: {
          selectedVesselType: selectedGeofenceRow.aisWatchFilter.type
            ? getArrayOfObjects(
                selectedGeofenceRow.aisWatchFilter.type.split(','),
                'vesselType'
              )
            : [],
          selectedCountryName: selectedGeofenceRow.aisWatchFilter.country
            ? getArrayOfObjects(
                selectedGeofenceRow.aisWatchFilter.country.split(','),
                'flagCountry'
              )
            : [],
          selectedVesselName: selectedGeofenceRow.aisWatchFilter.names
            ? getArrayOfObjects(
                selectedGeofenceRow.aisWatchFilter.names.split(','),
                'vesselName'
              )
            : [],
          selectedMMSI: selectedGeofenceRow.aisWatchFilter.names
            ? getArrayOfObjects(
                selectedGeofenceRow.aisWatchFilter.id.split(','),
                'vesselId'
              )
            : [],
          speed: {
            fromSpeed: selectedGeofenceRow.aisWatchFilter.fromSpeed,
            toSpeed: selectedGeofenceRow.aisWatchFilter.toSpeed,
          },
        },
        vms: {
          selectedCountryName: selectedGeofenceRow.aisWatchFilter.country
            ? getArrayOfObjects(
                selectedGeofenceRow.aisWatchFilter.country.split(','),
                'flagCountry'
              )
            : [],
          selectedVesselName: selectedGeofenceRow.aisWatchFilter.names
            ? getArrayOfObjects(
                selectedGeofenceRow.aisWatchFilter.names.split(','),
                'vesselName'
              )
            : [],
          speed: {
            fromSpeed: selectedGeofenceRow.aisWatchFilter.fromSpeed,
            toSpeed: selectedGeofenceRow.aisWatchFilter.toSpeed,
          },
        },
      });
    }
  }, [selectedGeofenceRow]);

  useEffect(() => {
    if (saveGeofenceData) {
      if (geofenceName.length === 0) {
        setSaveGeofenceData(false);
        setError((prevState: any) => ({ ...prevState, name: true }));
        displayAlert(I18nKey.IUU_SETTINGS_GEOFENCE_NAME_ERROR_MESSAGE);
        return;
      }
      if (moment(geofenceGeneral.startDate) > moment(geofenceGeneral.endDate)) {
        setSaveGeofenceData(false);
        displayAlert(I18nKey.IUU_SETTINGS_GEOFENCE_DATERANGE_ERROR_MESSAGE);
        return;
      }
      if (
        geofenceGeneral.data === 'draw' &&
        _.isEmpty(geofenceGeneral.filedata)
      ) {
        setSaveGeofenceData(false);
        displayAlert(I18nKey.IUU_SETTINGS_GEOFENCE_DATA_ERROR_MESSAGE);
        return;
      }
      if (
        geofenceGeneral.data === 'upload' &&
        geofenceGeneral.uploadedFileName.length === 0
      ) {
        setSaveGeofenceData(false);
        displayAlert(I18nKey.IUU_SETTINGS_GEOFENCE_DATA_ERROR_MESSAGE);
        return;
      }
      if (
        geofenceGeneral.email &&
        geofenceGeneral.email.length &&
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          geofenceGeneral.email
        )
      ) {
        setSaveGeofenceData(false);
        displayAlert(I18nKey.IUU_SETTINGS_GEOFENCE_EMAIL_ERROR_MESSAGE);
        setError((prevState: any) => ({ ...prevState, email: true }));
        return;
      }

      let geofenceData: any = {
        name: geofenceName,
        createdOn: geofenceGeneral.createdOn,
        updateOn: dateToString(moment()),
        active: geofenceGeneral.active,
        startDate: geofenceGeneral.startDate,
        endDate: geofenceGeneral.endDate,
        type: geofenceGeneral.type,
        descr: geofenceGeneral.description,
        baseRegionCode: region,
        userId: userName,
        shared: userName,
        alert: {
          emailId: geofenceGeneral.email,
        },
        display: {
          strokeColor: geofenceGeneral.display.strokeColor,
          fillColor: geofenceGeneral.display.fillColor,
          strokeThickness: geofenceGeneral.display.fillWidth,
          fillPattern: 'line',
        },
        aisWatchFilter: getAISWatchListFilter(geofenceWatchlist.ais),
        vmsWatchFilter: getVMSWatchListFilter(geofenceWatchlist.vms),
        upload: geofenceGeneral.data === 'upload' ? true : false,
      };
      if (geofenceGeneral.hasOwnProperty('id')) {
        geofenceData['id'] = geofenceGeneral.id;
      }
      if (
        geofenceGeneral.uploadedFileName.length > 0 &&
        geofenceGeneral.data === 'upload'
      ) {
        geofenceData['filename'] = geofenceGeneral.uploadedFileName;
      } else {
        geofenceData['filedata'] = geofenceGeneral.filedata;
      }

      if (
        features.includes('AIS') &&
        !_.isEmpty(geofenceData['aisWatchFilter'])
      ) {
        geofenceData['aisWatchFilter']['avoid'] = getVesselsInPolygon(AISData);
      }
      if (
        features.includes('VMS') &&
        !_.isEmpty(geofenceData['vmsWatchFilter'])
      ) {
        geofenceData['vmsWatchFilter']['avoid'] = getVesselsInPolygon(VMSData);
      }
      geofenceData.startDate.setHours(0, 0, 0, 0);
      geofenceData.endDate.setHours(0, 0, 0, 0);
      const fenceData = {
        ...geofenceData,
        ...{
          startDate: dateToString(
            geofenceData.startDate,
            'YYYY-MM-DDTHH:mm:ss'
          ),
          endDate: dateToString(geofenceData.endDate, 'YYYY-MM-DDTHH:mm:ss'),
        },
      };
      onSaveGeofence(userName, fenceData, geofenceGeneral.id);
      setSaveGeofenceData(false);
      setGeofenceEditMode(false);
    }
  }, [saveGeofenceData]);

  const getAISWatchListFilter = (filterData: any) => {
    let watchList = {
      type: getListFromArray(
        filterData.selectedVesselType,
        'vesselType'
      ).toString(),
      names: getListFromArray(
        filterData.selectedVesselName,
        'vesselName'
      ).toString(),
      country: getListFromArray(
        filterData.selectedCountryName,
        'flagCountry'
      ).toString(),
      id: getListFromArray(filterData.selectedMMSI, 'vesselId').toString(),
      fromSpeed: filterData.speed.fromSpeed,
      toSpeed: filterData.speed.toSpeed,
    };
    if (
      watchList.type === '' &&
      watchList.names === '' &&
      watchList.id === '' &&
      watchList.country === ''
    )
      return {};
    return watchList;
  };

  const getVMSWatchListFilter = (filterData: any) => {
    let watchList = {
      names: getListFromArray(
        geofenceWatchlist.vms.selectedVesselName,
        'vesselName'
      ).toString(),
      country: getListFromArray(
        geofenceWatchlist.vms.selectedCountryName,
        'flagCountry'
      ).toString(),
      fromSpeed: geofenceWatchlist.vms.speed.fromSpeed,
      toSpeed: geofenceWatchlist.vms.speed.toSpeed,
    };
    if (watchList.names === '' && watchList.country === '') return {};
    return watchList;
  };

  return (
    <>
      <Box
        sx={{
          m: 2,
          color: 'white',
          minHeight: '454px',
          maxHeight: '454px',
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            m: 1,
          }}
        >
          <Typography>
            {intl.formatMessage({
              id: I18nKey.IUU_SETTINGS_CREATE_GEOFENCE,
            })}
          </Typography>
          <Link
            sx={{
              color: '#6F6F6F',
              cursor: 'pointer',
              zIndex: 5,
            }}
            underline="none"
            onClick={() => setGeofenceEditMode(false)}
          >
            {intl.formatMessage({
              id: I18nKey.IUU_SETTINGS_BACK_TO_GEOFENCE_LIST,
            })}
          </Link>
        </Box>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
                {intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_NAME_LABEL,
                })}
              </Typography>
              <TextField
                error
                id="geofence-read-only-input"
                defaultValue={geofenceName}
                InputProps={{
                  readOnly: selectedGeofenceRow?.name ? true : false,
                }}
                sx={GeofenceCRUDStyles.geofenceName}
                onChange={onGeofenceNameChange}
                helperText={
                  error.name &&
                  intl.formatMessage({
                    id: I18nKey.IUU_SETTINGS_GEOFENCE_NAME_ERROR_MESSAGE,
                  })
                }
              />
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ width: '100%', mt: 2 }}>
          <Box>
            <Tabs
              value={tabName}
              onChange={onTabChange}
              TabIndicatorProps={tabIndicatorProps}
            >
              <Tab
                value="GENERAL"
                label={intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_GENERAL,
                })}
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
              <Tab
                value="WATCHLIST"
                label={intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_FILTERS,
                })}
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            </Tabs>
          </Box>
          <Box>
            <TabPanel name={tabName} index="GENERAL">
              <GeofenceGeneral
                isGeofenceEdit={selectedGeofenceRow?.name ? true : false}
                geofenceGeneral={geofenceGeneral}
                setGeofenceGeneral={setGeofenceGeneral}
                error={error}
                displayAlert={displayAlert}
              />
            </TabPanel>
            <TabPanel name={tabName} index="WATCHLIST">
              {dataIsLoading['AIS'] ? (
                <CustomLinearProgress />
              ) : (
                <GeofenceWatchlist
                  AISData={AISData}
                  VMSData={VMSData}
                  geofenceWatchlist={geofenceWatchlist}
                  setGeofenceWatchlist={setGeofenceWatchlist}
                />
              )}
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const TabPanel: React.FC<any> = (props) => {
  const { children, name, index } = props;
  return <>{name === index && <> {children} </>}</>;
};
