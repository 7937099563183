import React, { useEffect, useState } from 'react';
import { Box, Slider } from '@mui/material';
import { MinMax } from './MinMax';
import { colorSliderStyle, RasterSliderStyles } from '../SliderStyles';
import { RasterSliderProps } from '../SliderTypes';
import { toAntilog, toLog } from '../../../containers/Map/HelperService';

const RasterSlider: React.FC<RasterSliderProps> = ({
  style,
  showLogScale,
  selectedValue,
  defaultValue,
  unit,
  marks,
  step,
  continuous,
  onChangeHandler,
  colorScale,
  setSelectedValue,
  precision,
  syncMinMax = false,
}) => {
  const left =
    ((selectedValue[0] - marks[0].value) /
      (marks[marks.length - 1].value - marks[0].value)) *
    100;
  const right =
    ((selectedValue[1] - marks[0].value) /
      (marks[marks.length - 1].value - marks[0].value)) *
    100;

  const [minMaxState, setMinMaxState] = useState([
    selectedValue[0],
    selectedValue[1],
  ]);

  useEffect(() => {
    setMinMaxState([
      showLogScale
        ? toAntilog(selectedValue[0]).toFixed(precision)
        : selectedValue[0],
      showLogScale
        ? toAntilog(selectedValue[1]).toFixed(precision)
        : selectedValue[1],
    ]);
  }, [selectedValue]);

  const handleMinTextBox = (e: any) => {
    if (e.key === 'Enter' || e.type === 'blur') {
      const newValue = Number(
        Number(showLogScale ? toLog(minMaxState[0]) : minMaxState[0]).toFixed(
          precision
        )
      );
      const scaleMax = Number(marks[marks.length - 1].value);
      const scaleMin = Number(marks[0].value);
      if (syncMinMax) {
        if (newValue > scaleMax || newValue < scaleMin) {
          setSelectedValue([scaleMin, scaleMax]);
          setMinMaxState([scaleMin, scaleMax]);
        } else {
          setSelectedValue([-Math.abs(newValue), Math.abs(newValue)]);
          setMinMaxState([-Math.abs(newValue), Math.abs(newValue)]);
        }
      } else {
        if (newValue > scaleMax || newValue < scaleMin) {
          setSelectedValue((prevState: any[]) => [scaleMin, prevState[1]]);
          setMinMaxState((prevState: any[]) => [scaleMin, selectedValue[1]]);
        } else if (newValue > selectedValue[1]) {
          setSelectedValue((prevState: any[]) => [prevState[1], newValue]);
          setMinMaxState((prevState: any[]) => [
            selectedValue[1],
            showLogScale ? toAntilog(newValue).toFixed(precision) : newValue,
          ]);
        } else {
          setSelectedValue((prevState: any) => [newValue, prevState[1]]);
          setMinMaxState((prevState: any[]) => [
            showLogScale ? toAntilog(newValue).toFixed(precision) : newValue,
            selectedValue[1],
          ]);
        }
      }
    }
  };

  const handleMaxTextBox = (e: any) => {
    if (e.key === 'Enter' || e.type === 'blur') {
      const newValue = Number(
        Number(showLogScale ? toLog(minMaxState[1]) : minMaxState[1]).toFixed(
          precision
        )
      );
      const scaleMax = Number(marks[marks.length - 1].value);
      const scaleMin = Number(marks[0].value);
      if (syncMinMax) {
        if (newValue > scaleMax || newValue < scaleMin) {
          setSelectedValue([scaleMin, scaleMax]);
          setMinMaxState([scaleMin, scaleMax]);
        } else {
          setSelectedValue([-Math.abs(newValue), Math.abs(newValue)]);
          setMinMaxState([-Math.abs(newValue), Math.abs(newValue)]);
        }
      } else {
        if (newValue > scaleMax || newValue < scaleMin) {
          setSelectedValue((prevState: any[]) => [prevState[0], scaleMax]);
          setMinMaxState((prevState: any[]) => [selectedValue[0], scaleMax]);
        } else if (newValue < selectedValue[0]) {
          setSelectedValue((prevState: any[]) => [newValue, prevState[0]]);
          setMinMaxState((prevState: any[]) => [
            showLogScale ? toAntilog(newValue).toFixed(precision) : newValue,
            selectedValue[0],
          ]);
        } else {
          setSelectedValue((prevState: any) => [prevState[0], newValue]);
          setMinMaxState((prevState: any[]) => [
            selectedValue[0],
            showLogScale ? toAntilog(newValue).toFixed(precision) : newValue,
          ]);
        }
      }
    }
  };

  const onChangeMin = (e: any) => {
    setMinMaxState((prevState: any[]) => [e.target.value, prevState[1]]);
  };

  const onChangeMax = (e: any) => {
    setMinMaxState((prevState: any[]) => [prevState[0], e.target.value]);
  };

  const scaleValues = (value: number) => {
    return showLogScale ? toAntilog(value) : value;
  };

  return (
    <Box sx={{ width: '95%', marginLeft: '3px' }}>
      <MinMax
        minMaxState={minMaxState}
        onChangeMin={onChangeMin}
        onChangeMax={onChangeMax}
        showLogScale={showLogScale}
        value={selectedValue}
        handleMinBox={handleMinTextBox}
        handleMaxBox={handleMaxTextBox}
        unit={unit}
        min={
          showLogScale
            ? toAntilog(marks[0].value).toFixed(precision)
            : marks[0].value
        }
        max={
          showLogScale
            ? toAntilog(marks[marks.length - 1].value).toFixed(precision)
            : marks[marks.length - 1].value
        }
        step={step}
      />
      <Slider
        min={marks[0].value}
        max={marks[marks.length - 1].value}
        value={selectedValue}
        step={step}
        sx={{
          ...colorSliderStyle(left, right, continuous, colorScale),
          ...RasterSliderStyles,
          marginLeft: '6px',
        }}
        aria-labelledby="track-inverted-range-slider"
        defaultValue={defaultValue}
        onChange={onChangeHandler}
        marks={marks}
        disableSwap={true}
        scale={scaleValues}
      />
    </Box>
  );
};

RasterSlider.defaultProps = {
  selectedValue: [30, 60],
  continuous: true,
  marks: [
    {
      value: 0,
      label: '0',
    },
    {
      value: 100,
      label: '100',
    },
  ],
  defaultValue: [30, 60],
};

export default RasterSlider;
