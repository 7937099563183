import mapboxgl from 'mapbox-gl';

export const addGraticule = (
  map: mapboxgl.Map,
  currentZoom: number,
  zoom: number
) => {
  if (zoom < 2) {
    zoom = 1;
  } else if (zoom < 3) {
    zoom = 2;
  } else if (zoom < 4) {
    zoom = 4;
  } else if (zoom < 5) {
    zoom = 6;
  } else if (zoom < 6) {
    zoom = 12;
  } else if (zoom < 7) {
    zoom = 30;
  } else if (zoom < 8) {
    zoom = 60;
  } else if (zoom < 9) {
    zoom = 120;
  } else if (zoom < 10) {
    zoom = 240;
  } else if (zoom < 11) {
    zoom = 360;
  } else if (zoom < 12) {
    zoom = 720;
  } else if (zoom < 13) {
    zoom = 1800;
  } else if (zoom < 22) {
    zoom = 3600;
  }
  if (currentZoom !== zoom) {
    currentZoom = zoom;
    addGraticuleLayer(map, createGraticuleDataSource(zoom));
  }
  return currentZoom;
};

export const addGraticuleLayer = (map: mapboxgl.Map, graticule: any) => {
  map?.addSource('graticule', {
    type: 'geojson',
    data: graticule,
  });
  map?.addLayer({
    id: 'graticule',
    type: 'line',
    source: 'graticule',
    paint: {
      'line-color': 'black',
    },
  });
  map?.addLayer({
    id: 'graticuleLabels',
    type: 'symbol',
    source: 'graticule',
    layout: {
      'symbol-placement': 'line',
      'text-field': '{lable}',
      'text-size': +'11',
      'symbol-spacing': +'400',
      'text-rotation-alignment': 'viewport',
      'text-offset': [2, -1],
      'text-justify': 'left',
    },
    paint: {
      'text-color': 'black',
    },
  });
};

export const getLabel = (val: number, isLat: boolean) => {
  if (isLat) {
    return val < 0 ? 'S ' + getDegrees(val) : 'N ' + getDegrees(val);
  } else {
    return val < 0 ? 'W ' + getDegrees(val) : 'E ' + getDegrees(val);
  }
};

export const getDegrees = (val: any) => {
  val = Math.abs(val);
  if (Number.isInteger(val)) {
    return val.toString() + '°';
  } else {
    return (
      Math.floor(val) +
      '°' +
      Math.round((+(val % 1).toFixed(2).substring(2) * 60) / 100) +
      "'"
    );
  }
};

export const createGraticuleDataSource = (zoom: number) => {
  const graticule: any = {
    type: 'FeatureCollection',
    features: [],
  };
  for (let lngDegree = -180; lngDegree <= 180; lngDegree += 60 / zoom) {
    graticule.features.push({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [
          [lngDegree, -90],
          [lngDegree, 90],
        ],
      },
      properties: {
        value: lngDegree,
        lable: getLabel(lngDegree, false),
      },
    });
  }
  for (let latDegree = -90; latDegree <= 90; latDegree += 60 / zoom) {
    graticule.features.push({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [
          [-180, latDegree],
          [180, latDegree],
        ],
      },
      properties: {
        value: latDegree,
        lable: getLabel(latDegree, true),
      },
    });
  }
  return graticule;
};
