import { FC, Fragment } from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  Button,
  Grid,
  Paper,
  Link,
  Typography,
  TextField,
  Autocomplete,
} from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { styled } from '@mui/styles';
import { mappingCSVColumnConfig } from '../../../FishCatchDashboard/DashboardConfig';
import AutoComplete from '../../../../components/AutoComplete/AutoComplete';
import { I18nKey } from '../../../../translations/I18nKey';
import { VesselBuoyStyles, useVesselUploadStyles } from './VesselBuoyStyles';
import { dbHeadersConfig } from './VesselBuoyConfig';

interface ImportPopUpDataMapperProps {
  dataType: string;
  columnMapping: Record<string, null | undefined | string> | any;
  onMappingSelectionChange: (
    field: string,
    value: {
      header: string;
    }
  ) => void;
  getMappingData: () => any[];
  vesselName?: string;
  onNameChange?: any;
  allVesselNames?: any;
}

interface MapperFooterProps {
  dataType: string;
  handleBack?: () => void;
  columnMapping?: any | Record<string, null | undefined | string>;
  handleUploadClick?: () => any;
  isLoading: boolean;
  vesselName?: string;
}

export const VesselBuoyImportDataMapper: FC<ImportPopUpDataMapperProps> = ({
  dataType,
  columnMapping,
  onMappingSelectionChange,
  getMappingData,
  vesselName,
  onNameChange,
  allVesselNames,
}) => {
  const Item = styled(Paper)(() => VesselBuoyStyles.item);
  const intl = useIntl();
  const vesselUploadClasses: any = useVesselUploadStyles();

  return (
    <Box>
      <Box className={vesselUploadClasses.mapperWrapper}>
        {dataType === 'vessel' ? (
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Box
                sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
              >
                <Typography
                  sx={{ fontSize: '14px', color: '#6F6F6F' }}
                  className={`${vesselUploadClasses['fishcatch__mapper__required-label']}`}
                >
                  Vessel Name
                </Typography>
                <Autocomplete
                  sx={VesselBuoyStyles.vesselName}
                  value={vesselName}
                  autoSelect
                  onChange={(event, newValue) => {
                    onNameChange(newValue);
                  }}
                  options={allVesselNames.map((v: any) => v.vesselName)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(e) => onNameChange(e.target.value)}
                    />
                  )}
                  freeSolo
                />
              </Box>
              <br></br>
            </Grid>
          </Grid>
        ) : null}
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {columnMapping &&
            Object.keys(columnMapping).map((field: string, index: number) => {
              return (
                <Fragment key={field + index}>
                  <Grid item xs={5} className={vesselUploadClasses.alignCenter}>
                    <Item
                      className={
                        dbHeadersConfig[dataType][field].required
                          ? `${vesselUploadClasses['fishcatch__mapper__required-label']} ${vesselUploadClasses.mapperLabel}`
                          : `${vesselUploadClasses.mapperLabel}`
                      }
                    >
                      {intl.formatMessage({
                        id: dbHeadersConfig[dataType][field].label,
                      })}
                    </Item>
                  </Grid>
                  <Grid item xs={2} className={vesselUploadClasses.alignCenter}>
                    <DragHandleIcon />
                  </Grid>
                  <Grid item xs={5} className={vesselUploadClasses.alignCenter}>
                    <AutoComplete
                      key={'header'}
                      data={getMappingData()}
                      style={VesselBuoyStyles.mappingSelect}
                      columnConfig={mappingCSVColumnConfig}
                      onSelectionChange={(e: any) =>
                        onMappingSelectionChange(field, e)
                      }
                      showSelectAllToggle={false}
                      enableSingleSelection={true}
                      showClearAll={false}
                      showChips={false}
                      selectionData={{
                        header: columnMapping ? columnMapping[field] : '',
                      }}
                    ></AutoComplete>
                  </Grid>
                </Fragment>
              );
            })}
        </Grid>
      </Box>
    </Box>
  );
};

export const VesselBuoyMapperFooter: FC<MapperFooterProps> = ({
  dataType,
  handleBack,
  columnMapping,
  handleUploadClick,
  isLoading,
  vesselName,
}) => {
  const intl = useIntl();
  const requiredValues =
    columnMapping &&
    Object.keys(dbHeadersConfig[dataType])
      .filter((key) => dbHeadersConfig[dataType][key].required)
      .map((key) => columnMapping[key]);
  const vesselUploadClasses: any = useVesselUploadStyles();

  return (
    <Grid item xs={12}>
      <Box className={vesselUploadClasses.popUpFloatRight}>
        <Link className={vesselUploadClasses.popUpPrevBtn} onClick={handleBack}>
          {intl.formatMessage({
            id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_BACK,
          })}
        </Link>
        <Button
          className={vesselUploadClasses.popUpWarningColor}
          variant="contained"
          disabled={
            (requiredValues && Object.values(requiredValues).includes(null)) ||
            isLoading
          }
          onClick={handleUploadClick}
        >
          {intl.formatMessage({
            id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_UPLOAD,
          })}
        </Button>
      </Box>
    </Grid>
  );
};
