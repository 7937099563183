import { useIntl } from 'react-intl';
import Paper from '@mui/material/Paper';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box/Box';
import { I18nKey } from '../../../../translations/I18nKey';
import closeIcon from '../../../../assets/icons/close.svg';
import { useStyles } from '../../../../utils/util';
import { PopUpStyles } from './VesselPopUpStyles';


const Value = styled(Paper)(PopUpStyles.ValueStyle);

export const RFPopUp: React.FC<any> = ({
  rfData,
  setShowRFPopUp,
  position,
}) => {
  const intl = useIntl();
  const classes = useStyles(PopUpStyles, [])();

  const onClose = () => {
    setShowRFPopUp({ display: false, rfData: {} });
  };

  return (
    <Box
      id="iuu-rf-popup"
      sx={{
        position: 'fixed',
        backgroundColor: 'black',
        borderRadius: 3,
        width: 'auto',
        top: position.top,
        left: position.left,
      }}
    >
      <Box
        sx={{
          backgroundColor: '#3D3D3D',
          display: 'flex',
          flexDirection: 'column',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          padding: 1,
          color: 'white',
          mb: 1,
        }}
      >
        <Typography>{rfData.soi}</Typography>
      </Box>
      <Box
        onClick={onClose}
        sx={{ borderRadius: 50, color: 'white', borderColor: 'white' }}
      >
        <img
          src={closeIcon}
          onClick={onClose}
          className={classes.closeIcon}
          alt="x"
        />
      </Box>
      <Grid
        container
        sx={{ p: 1, width: '260px' }}
        columnSpacing={6}
        rowSpacing={0}
      >
        <Grid item xs={4}>
          <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
            {intl.formatMessage({
              id: I18nKey.IUU_POPUP_ORIENTATION,
            })}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Value>{rfData.orientation.toFixed(3)}</Value>
        </Grid>

        <Grid item xs={4}>
          <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
            {intl.formatMessage({
              id: I18nKey.IUU_POPUP_DETECTED_LOCATION,
            })}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography sx={{ color: 'white', fontSize: '14px' }}>
            {rfData.position.lat} <br></br>
            {rfData.position.lon}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
