/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { useIndexedDB } from 'react-indexed-db';
import _, { isEmpty, isNil } from 'lodash';
import { useQuery } from 'react-query';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../translations/I18nKey';
import ProductClient from '../../api/productAPIs';
import { getUserAttributes } from '../../utils/auth';
import Snackbar from '../../components/Snackbar/Snackbar';
import { FeatureDisplayDefaults, ProductDefaults } from '../types';
import { UserSettingsContext } from '../Contexts/UserSettingsContext';
import { ProductTileService } from './Products/MapLayers/ProductTileService';
import { MapContext } from './MapContext';
import { ProductUnitsContext } from '../Contexts/UnitsContext';
import { mapCursorData } from './Products/MapLayers/CursordataService';
import {
  convert,
  getDateAndDepth,
  getSelectedProductsFromStorage,
  getUniqueProducts,
  initDepthToStorage,
  updateTileDataByUnit,
} from './HelperService';
import { ProgressSpinner } from '../../components/ProgressSpinner/ProgressSpinner';
import { deleteFromCacheStorage } from '../../api/cacheUtils';
import { OtherFeaturesContext } from './OtherFeaturesContext';
import moment from 'moment';
import { PREVIOUS_DATA_FETCH_DAYS } from './config';

export interface Features {
  displayConfig: any;
  setDisplayConfig: any;
  categoryList: any;
  userData: any;
  setUserData: any;
  tileData: any;
  setTileData: any;
  setClickedProduct: any;
  setClickedMultiProduct: any;
  getMapTileData: any;
  removeMultipleMapTiles: any;
  saveDefault: any;
  getselectedTimeOrDepthProducts: any;
  onDepthAndFrequencyChange: any;
  depthSliderValue: number;
  setDepthSliderValue: any;
  selectedDepthProducts: any;
  usePreviousDate: boolean;
  setUsePreviousDate: any;
  productAvailabilityDate: any;
}

export const FeaturesContext = createContext<Features>({
  displayConfig: {},
  setDisplayConfig: () => [],
  categoryList: [],
  userData: {},
  setUserData: () => {},
  tileData: {},
  setTileData: () => {},
  setClickedProduct: () => {},
  setClickedMultiProduct: () => {},
  getMapTileData: () => {},
  removeMultipleMapTiles: () => {},
  saveDefault: () => {},
  getselectedTimeOrDepthProducts: () => {},
  onDepthAndFrequencyChange: () => {},
  depthSliderValue: 0,
  setDepthSliderValue: () => {},
  selectedDepthProducts: {},
  usePreviousDate: false,
  setUsePreviousDate: () => {},
  productAvailabilityDate: {},
});

export const FeaturesContextProvider: React.FC = (props) => {
  const intl = useIntl();
  const { getByIndex } = useIndexedDB('cursorData');
  const addToIndexedDB = useIndexedDB('cursorData').add;
  const map = useContextSelector(MapContext, (state) => state.map);
  const selectedDate = useContextSelector(
    MapContext,
    (state) => state.selectedDate
  );
  const date = useContextSelector(MapContext, (state) => state.date);
  const addMapLayerId = useContextSelector(
    MapContext,
    (state) => state.addMapLayerId
  );
  const removeMapLayerId = useContextSelector(
    MapContext,
    (state) => state.removeMapLayerId
  );
  const layerOrder = useContextSelector(
    MapContext,
    (state) => state.layerOrder
  );
  const userSettings = useContextSelector(
    UserSettingsContext,
    (state) => state.userSettings
  );
  const initDataNSortNonProducts = useContextSelector(
    OtherFeaturesContext,
    (state) => state.initDataNSortNonProducts
  );
  const units = useContextSelector(ProductUnitsContext, (state) => state);
  const { userName, seastarId } = getUserAttributes();
  const region: any = useRef();
  const [displayConfig, setDisplayConfig] = useState<any>({});
  const [userData, setUserData] = useState<any>({});
  const [tileData, setTileData] = useState<any>({});
  const [depthSliderValue, setDepthSliderValue] = useState(0);
  const [selectedDepthProducts, setSelectedDepthProducts] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    type: '',
    display: false,
    message: '',
  });
  const [featureDefaults, setFeatureDefaults] = useState<any>({});
  const [productDefaults, setProductDefaults] = useState<any>({});
  const [loadDisplay, setLoadDisplay] = useState<boolean>(false);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [usePreviousDate, setUsePreviousDate] = useState<boolean>(false);
  //productAvailabilityDate is being used to store the date per product code which is being fetched for the current state
  const [productAvailabilityDate, setProductAvailabilityDate] = useState<any>(
    {}
  );

  useEffect(() => {
    fetchFeatureDisplayDefaults();
    sessionStorage.setItem('time', '00');
    const depth = initDepthToStorage();
  }, []);

  useEffect(() => {
    if (!isEmpty(userSettings.map?.regionsOfInterest)) {
      region.current = userSettings.map?.regionsOfInterest;
      setLoading(true);
      setLoadDisplay(false);
      fetchProductDefaults();
    }
  }, [userSettings.map?.regionsOfInterest]);

  useEffect(() => {
    if (!isEmpty(featureDefaults)) {
      let displayData = initDefaultData(featureDefaults);
      let displayDefaults = setUserProductDefaults(
        productDefaults,
        displayData
      );
      setDisplayConfig({ ...displayDefaults });
      setLoading(false);
      setLoadDisplay(true);
    }
  }, [featureDefaults, productDefaults]);

  useEffect(() => {
    if (
      userSettings?.products?.length &&
      !isEmpty(featureDefaults) &&
      !isEmpty(displayConfig) &&
      loadDisplay
    ) {
      updateProductsVisibility();
    }
  }, [userSettings.products, loadDisplay]);

  useEffect(() => {
    if (
      userSettings?.products?.length &&
      !isEmpty(featureDefaults) &&
      !isEmpty(displayConfig) &&
      loadDisplay
    ) {
      loadDisplay && reloadProducts(displayConfig);
    }
  }, [loadDisplay]);

  useEffect(() => {
    if (selectedDate && !isEmpty(featureDefaults) && !isEmpty(productDefaults))
      onDateChangeGetMaptile();
  }, [selectedDate]);

  useEffect(() => {
    if (!isEmpty(selectedDepthProducts)) depthProductApiCall();
  }, [depthSliderValue]);

  useEffect(() => {
    setSelectedDepthProducts(getselectedTimeOrDepthProducts('depth'));
  }, [tileData]);

  const updateProductsVisibility = () => {
    let displayDefaults = Object.assign({}, displayConfig);
    userSettings.products?.forEach((c: any) => {
      if (c.hasOwnProperty('visible')) {
        displayDefaults.data[c.category]['visible'] = c.visible;
        c.products.forEach((p: any) => {
          displayDefaults.data[c.category].items[p.code].visible =
            p.showProduct;
        });
      }
    });
    setDisplayConfig({ ...displayConfig, ...displayDefaults });
  };

  const initDefaultData = (displayData: any) => {
    let initialTileData = {};
    let mergedData: any = mergeFeatureCategories(displayData);
    setCategoryList(sortByCategory(mergedData));
    initDataNSortNonProducts(
      _.pick(
        Object.assign({}, mergedData),
        getTypeKeysList(mergedData, 'others')
      )
    );
    [mergedData, initialTileData] = initDataNSortProducts(
      _.pick(
        Object.assign({}, mergedData),
        getTypeKeysList(mergedData, 'products')
      ),
      tileData
    );

    setTileData(initialTileData);
    return { data: mergedData };
  };

  const reloadProducts = (displayDefaults: any) => {
    let products = getSelectedProductsFromStorage();
    Object.keys(products).forEach((t: any) => {
      if (products[t].length) {
        products[t].forEach((c: any) => {
          let prod = displayDefaults.data[userData[c]?.category]?.items[c];
          if (prod && t && prod[t]) {
            prod[t].selected = !prod[t]['selected'];
            if (prod[t].selected) {
              let selectedProduct: any = {};
              selectedProduct[t] = [c];
              getMapTileData(selectedProduct, true, true, {}, true);
            }
          }
        });
      }
    });
    setDisplayConfig({ ...displayConfig, ...displayDefaults });
  };

  const getFeatureDisplayDefaults = async () =>
    await ProductClient.getFeatureDisplayDefaults(userName, seastarId);

  const getProductDefaults = async () =>
    await ProductClient.getUserProductDefaults(
      userName,
      region.current || '',
      seastarId
    );

  const clearAlert = (timeout: number) =>
    setTimeout(() => {
      setAlert({
        type: '',
        display: false,
        message: '',
      });
    }, timeout);

  const setAlertMessage = (
    message: string,
    type: string,
    clearTimeout: number = 5000
  ) => {
    setAlert({
      type,
      display: true,
      message,
    });
    clearAlert(clearTimeout);
  };

  const { refetch: fetchFeatureDisplayDefaults } =
    useQuery<FeatureDisplayDefaults>(
      'FeatureDisplayDefaults',
      getFeatureDisplayDefaults,
      {
        retry: false,
        enabled: false,
        onSuccess: (data: FeatureDisplayDefaults) => {
          if (!isNil(data)) {
            setFeatureDefaults(data);
          }
        },
        onError: (error: any) => {
          setAlertMessage(
            intl.formatMessage({
              id: I18nKey.ERROR_MESSAGE_GET,
            }),
            'error'
          );
        },
      }
    );

  const { refetch: fetchProductDefaults } = useQuery<ProductDefaults>(
    'ProductDefaults',
    getProductDefaults,
    {
      retry: false,
      enabled: false,
      onSuccess: (data: ProductDefaults) => {
        if (!isNil(data)) {
          let userData = _.cloneDeep(data);
          setUserData({ ...userData });
          setProductDefaults(data);
        }
      },
      onError: (error: any) => {
        setLoading(false);
        setAlertMessage(
          intl.formatMessage({
            id: I18nKey.ERROR_MESSAGE_GET,
          }),
          'error'
        );
      },
    }
  );

  const generateMapTileAPICall = async (
    userName: string,
    date: string,
    region: any,
    tileDetails: any
  ) => {
    try {
      setLoading(true);
      const data = await ProductClient.generateMapTile(
        userName,
        date || '',
        region || '',
        tileDetails.productCode,
        tileDetails.productType,
        tileDetails.depth,
        tileDetails.defaultTile,
        tileDetails.pTileData
      );
      if (tileDetails.productType === 'raster') {
        setProductAvailabilityDate((prev: any) => {
          return { ...prev, ...{ [tileDetails.productCode]: date } };
        });
        let tileData = _.cloneDeep(data[0]);
        setTileResponse(tileDetails, tileData);
      } else {
        setProductAvailabilityDate((prev: any) => {
          return { ...prev, ...{ [tileDetails.productCode]: date } };
        });
        getDataFromPresignedURL({
          url: data,
          tileInputData: tileDetails,
        });
      }
    } catch (error: any) {
      let inputDate = moment(date, 'YYYYMMDD').toDate();
      let todaysDate = new Date();
      //In case where data files arrive late for any product, we make use of pervious day's data
      //This case will be handled only when current date will be the same as the date passed in the API call
      //We fetch the data and cursor data with new date, on apply also the previous day's date will be used
      //For ex. if data for 20240702 is not present, date will be set to 20240701
      if (
        error.response.status === 404 &&
        inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)
      ) {
        setUsePreviousDate(true);
        let newDate = moment()
          .subtract(PREVIOUS_DATA_FETCH_DAYS, 'd')
          .format('YYYYMMDD');
        let isTimeProduct = !_.isEmpty(
          userData[tileDetails.productCode].timeFrequency
        );
        let dateValue = isTimeProduct
          ? newDate + sessionStorage.getItem('time')
          : newDate;
        generateMapTileAPICall(userName, dateValue, region, tileDetails);
        displayConfig.data[userData[tileDetails.productCode].category].items[
          tileDetails.productCode
        ].cursorData &&
          dateValue &&
          populateCursorData(
            userName,
            dateValue,
            region,
            tileDetails.productCode,
            tileDetails.depth || '-1'
          );
      } else {
        setLoading(false);
        setClickedProduct(
          userData[tileDetails.productCode]?.category,
          tileDetails.productCode,
          tileDetails.productType
        );
        if (
          error.response.status === 400 &&
          tileDetails.productCode === 'PRPA' &&
          error.response.data.detail.includes('projection')
        ) {
          setAlertMessage(
            intl.formatMessage({
              id: I18nKey.PRECIPITATION_NO_DATA_MSG,
            }),
            'error'
          );
        } else {
          setAlertMessage(
            intl.formatMessage({
              id: I18nKey.PRODUCTS_NO_DATA_MSG,
            }),
            'error'
          );
        }
      }
    }
  };

  const getDataFromPresignedURL = async (urlData: any) => {
    try {
      const data = await axios.get<any>(urlData.url);
      setTileResponse(urlData.tileInputData, data);
    } catch (error) {
      setLoading(false);
      setClickedProduct(
        userData[urlData.tileInputData.productCode]?.category,
        urlData.tileInputData.productCode,
        urlData.tileInputData.productType
      );

      setAlertMessage(
        intl.formatMessage({
          id: I18nKey.PRODUCTS_ERR_MSG,
        }),
        'error',
        1500
      );
    }
  };

  const setTileResponse = (tileInputData: any, data?: any) => {
    switch (tileInputData.productType) {
      case 'raster':
        setRasterTile(tileInputData, data);
        break;
      case 'contour':
        setContourTile(tileInputData, data);
        break;
      case 'vector':
        setVectorTile(tileInputData, data);
        break;
      case 'weather':
        setWeatherSubProduct(tileInputData, data);
        break;
      default:
        console.log('Product type out of scope');
        break;
    }
  };

  const setRasterTile = (tileInputData: any, data: any) => {
    if (!isEmpty(tileInputData)) {
      let tileDataOb = Object.assign({}, tileData);
      if (isEmpty(tileInputData.pTileData) && tileInputData.isSelected) {
        updateStorage(
          tileInputData.productCode,
          tileInputData.productType,
          true
        );
        updateScaleDataByUnit(
          tileInputData.productCode,
          tileInputData.productType,
          false
        );
      }
      if (map)
        ProductTileService.removeMapLayer(
          map,
          tileInputData.productCode,
          tileInputData.productType
        );
      tileDataOb[tileInputData.productCode][tileInputData.productType] =
        data[tileInputData.productType];
      [
        tileDataOb[tileInputData.productCode][tileInputData.productType].tile
          .min,
        tileDataOb[tileInputData.productCode][tileInputData.productType].tile
          .max,
      ] = convert(
        tileDataOb[tileInputData.productCode][tileInputData.productType].tile
          .min,
        tileDataOb[tileInputData.productCode][tileInputData.productType].tile
          .max,
        tileInputData.productCode,
        userData[tileInputData.productCode].precision,
        units[tileInputData.productCode].unit
      );

      addMapLayer(
        tileInputData.productCode,
        tileInputData.productType,
        tileDataOb[tileInputData.productCode][tileInputData.productType]
      );
      setTileData(tileDataOb);
    }
  };

  const setContourTile = (tileInputData: any, data: any) => {
    if (!isEmpty(tileInputData)) {
      let tileDataOb = Object.assign({}, tileData);
      if (tileInputData.isSelected) {
        tileDataOb = addUserData(
          tileInputData.productCode,
          tileInputData.productType
        );
        tileDataOb = updateTileDataByUnit(
          tileDataOb,
          tileInputData.productCode,
          tileInputData.productType,
          'min',
          'max',
          tileDataOb[tileInputData.productCode][tileInputData.productType].min,
          tileDataOb[tileInputData.productCode][tileInputData.productType].max,
          userData[tileInputData.productCode].precision,
          units[tileInputData.productCode].unit
        );
        updateScaleDataByUnit(
          tileInputData.productCode,
          tileInputData.productType,
          false
        );
        updateStorage(
          tileInputData.productCode,
          tileInputData.productType,
          true
        );
      }
      if (!isEmpty(tileInputData.pTileData)) {
        tileDataOb[tileInputData.productCode][tileInputData.productType].color =
          tileInputData.pTileData.color;
        tileDataOb[tileInputData.productCode][
          tileInputData.productType
        ].intervalLevel = tileInputData.pTileData.interval_level;

        tileDataOb = updateTileDataByUnit(
          tileDataOb,
          tileInputData.productCode,
          tileInputData.productType,
          'min',
          'max',
          tileInputData.pTileData.tile_min,
          tileInputData.pTileData.tile_max,
          userData[tileInputData.productCode].precision,
          units[tileInputData.productCode].unit
        );
      }
      if (!tileInputData.isSelected && isEmpty(tileInputData.pTileData)) {
        removeMapLayerId(
          tileInputData.productType,
          tileInputData.productCode + tileInputData.productType
        );
      }
      if (map)
        ProductTileService.removeMapLayer(
          map,
          tileInputData.productCode,
          tileInputData.productType
        );

      tileDataOb[tileInputData.productCode][tileInputData.productType]['data'] =
        data.data;

      addMapLayer(
        tileInputData.productCode,
        tileInputData.productType,
        tileDataOb[tileInputData.productCode][tileInputData.productType]
      );
      setTileData(tileDataOb);
    }
  };

  const setVectorTile = (tileInputData: any, data?: any) => {
    if (!isEmpty(tileInputData)) {
      let tileDataOb = Object.assign({}, tileData);
      if (data) {
        tileDataOb[tileInputData.productCode][tileInputData.productType][
          'data'
        ] = data?.data;
      }
      if (tileInputData.isSelected) {
        tileDataOb = addUserData(
          tileInputData.productCode,
          tileInputData.productType
        );
        updateStorage(
          tileInputData.productCode,
          tileInputData.productType,
          true
        );
      }
      if (!isEmpty(tileInputData.pTileData)) {
        tileDataOb[tileInputData.productCode][tileInputData.productType] =
          _.merge(
            tileDataOb[tileInputData.productCode][tileInputData.productType],
            tileInputData.pTileData
          );
      }
      if (!tileInputData.isSelected && isEmpty(tileInputData.pTileData)) {
        removeMapLayerId(
          tileInputData.productType,
          tileInputData.productCode + tileInputData.productType
        );
      }
      if (map)
        ProductTileService.removeMapLayer(
          map,
          tileInputData.productCode,
          tileInputData.productType
        );
      addMapLayer(
        tileInputData.productCode,
        tileInputData.productType,
        tileDataOb[tileInputData.productCode][tileInputData.productType]
      );
      setTileData(tileDataOb);
    }
  };

  const setWeatherSubProduct = (tileInputData: any, data?: any) => {
    if (!isEmpty(tileInputData)) {
      let tileDataOb = Object.assign({}, tileData);
      if (data) {
        tileDataOb[tileInputData.productCode][tileInputData.productType][
          'data'
        ] = data?.data;
      }
      if (tileInputData.isSelected) {
        updateStorage(
          tileInputData.productCode,
          tileInputData.productType,
          true
        );
      }
      if (!isEmpty(tileInputData.pTileData)) {
        tileDataOb[tileInputData.productCode][tileInputData.productType] =
          _.merge(
            tileDataOb[tileInputData.productCode][tileInputData.productType],
            tileInputData.pTileData
          );
      }
      if (!tileInputData.isSelected && isEmpty(tileInputData.pTileData)) {
        removeMapLayerId(
          tileInputData.productType,
          tileInputData.productCode + tileInputData.productType
        );
      }
      if (map)
        ProductTileService.removeMapLayer(
          map,
          tileInputData.productCode,
          tileInputData.productType
        );
      addMapLayer(
        tileInputData.productCode,
        tileInputData.productType,
        tileDataOb[tileInputData.productCode][tileInputData.productType]
      );
      setTileData(tileDataOb);
    }
  };

  const setClickedProduct = (category: any, code: any, type?: any) => {
    let displayDefaults = Object.assign({}, displayConfig);
    if (type) {
      setSelectedProducts(category, code, type);
    }
    setDisplayConfig({ ...displayConfig, ...displayDefaults });
  };

  const setClickedMultiProduct = (
    category: any,
    code: any,
    multiplotOb: any,
    isSetSelected: boolean
  ) => {
    let displayDefaults = Object.assign({}, displayConfig);
    setSelectedMultiProducts(
      category,
      multiplotOb[code].multiplot,
      isSetSelected
    );
    setDisplayConfig({ ...displayConfig, ...displayDefaults });
  };

  const populateCursorData = (
    userName: string,
    date: string,
    region: string,
    code: string,
    depth: string
  ) => {
    getByIndex('name', date + region + code + depth).then((data: any) => {
      if (isNil(data) && code !== 'HAB') {
        getcursorDataURL(userName, date, region, code, parseInt(depth));
      }
    });
  };

  const setSelected = (value: any, type: string) => {
    if (value[type]) {
      value[type].selected = !value[type]['selected'];
      if (value[type].selected) {
        let selectedProduct: any = {};
        selectedProduct[type] = [value.code];
        if (type === 'raster') {
          updateProductSelection(value.code, true);
        }
        getMapTileData(selectedProduct, true, true, {});
      } else {
        type === 'raster'
          ? updateProductSelection(value.code, false)
          : removeMapTile(value.code, type);
      }
    }
  };

  const setMultiplotSelected = (
    value: any,
    type: string,
    selectionValue: boolean
  ) => {
    if (value[type]) {
      value[type].selected = selectionValue;
      if (value[type].selected) {
        let selectedProduct: any = {};
        selectedProduct[type] = [value.code];
        if (type === 'raster') {
          updateProductSelection(value.code, true);
        }
        getMapTileData(selectedProduct, true, true, {});
      } else {
        type === 'raster'
          ? updateProductSelection(value.code, false)
          : removeMapTile(value.code, type);
      }
    }
  };

  const getMapTileData = (
    selectedProducts: any,
    selected: boolean,
    fetchCursorData: boolean,
    postObject: any,
    onReload?: boolean //TODO: find another way to handle reload case
  ) => {
    Object.keys(selectedProducts).forEach((productType) => {
      selectedProducts[productType].forEach((product: string) => {
        const [date, depth] = getDateAndDepth(userData[product]);
        date &&
          getMapTile(
            product,
            productType,
            depth,
            postObject,
            selected,
            date,
            onReload
          );
      });
    });
    fetchCursorData && fetchCursorDataValues(selectedProducts);
  };

  const getcursorDataURL = async (
    userName: string,
    date: string,
    region: string,
    productCode: string,
    depth: number
  ) => {
    try {
      const url = await ProductClient.getCursorData(
        userName,
        date,
        region,
        productCode,
        depth,
        'json'
      );
      getCursorDataJSON(url, date, region, productCode, depth);
    } catch (error) {
      console.log(error);
    }
  };

  const getCursorDataJSON = async (
    url: string,
    date: string,
    region: string,
    productCode: string,
    depth: number
  ) => {
    try {
      const data = await axios.get<any>(url);
      let cursorData;
      if (!isEmpty(data.data)) {
        cursorData = mapCursorData(data.data, productCode);
        addToIndexedDB({
          name: date + region + productCode + depth,
          data: cursorData,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMapTile = (
    pCode: any,
    pType: string,
    depth: any,
    pTileData: any,
    selected?: boolean,
    iDate?: string,
    onReload?: boolean
  ) => {
    const inputTileData = {
      productCode: pCode,
      productType: pType,
      depth: parseInt(depth),
      defaultTile: isEmpty(pTileData),
      isSelected: selected,
      onReload: onReload,
      pTileData,
    };
    if (!isEmpty(pTileData) && pType === 'vector') {
      setTileResponse(inputTileData);
    } else {
      generateMapTileAPICall(
        userName,
        iDate || date,
        region.current,
        inputTileData
      );
    }
  };

  const addUserData = (pCode: string, pType: string) => {
    let tileDataOb = Object.assign({}, tileData);
    tileDataOb[pCode][pType] = _.merge(
      tileDataOb[pCode][pType],
      userData[pCode][pType].user
    );
    return tileDataOb;
  };

  const addMapLayer = (pCode: string, pType: string, tileDataObj: any) => {
    if (map) {
      ProductTileService.addMapLayer(
        map,
        pCode,
        pType,
        tileDataObj,
        displayConfig.data,
        userSettings,
        units,
        addMapLayerId,
        layerOrder,
        setLoading
      );
    }
  };

  const removeMapTile = (pCode: any, pType: string) => {
    updateStorage(pCode, pType);
    removeMapLayerId(pType, pCode + pType);
    if (map) ProductTileService.removeMapLayer(map, pCode, pType);
    removeTileData(pCode, pType);
    if (pType !== 'vector') updateScaleDataByUnit(pCode, pType, true);
  };

  const removeMultipleMapTiles = (productList: any) => {
    let tileDataOb = Object.assign({}, tileData);
    productList.forEach((p: any) => {
      updateStorage(p.code, p.type);
      removeMapLayerId(p.type, p.code + p.type);
      if (map) ProductTileService.removeMapLayer(map, p.code, p.type);
      tileDataOb[p.code][p.type] = {};
      if (p.type !== 'vector') updateScaleDataByUnit(p.code, p.type, true);
    });
    setTileData(tileDataOb);
  };

  const updateStorage = (pCode: any, pType: string, insert = false) => {
    let selectedProducts = getSelectedProductsFromStorage();
    if (insert) {
      if (pType === 'raster') selectedProducts[pType] = [];
      if (
        selectedProducts[pType]?.length >= 0 &&
        !_.includes(selectedProducts[pType], pCode)
      ) {
        selectedProducts[pType].push(pCode);
      } else selectedProducts[pType] = [pCode];
    } else {
      selectedProducts[pType] = selectedProducts[pType].filter((c: string) => {
        return c !== pCode;
      });
      let ar: any[] = [];
      Object.values(selectedProducts).forEach((t: any) => {
        ar = [...ar, ...t];
      });
      let prodAvailability: any = {};
      _.keys(productAvailabilityDate).forEach((k) => {
        if (ar.includes(k)) prodAvailability[k] = productAvailabilityDate[k];
      });
      setProductAvailabilityDate(prodAvailability);
    }
    localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
  };

  const removeTileData = (pCode: string, pType: string) => {
    let tileDataOb = Object.assign({}, tileData);
    tileDataOb[pCode][pType] = {};
    setTileData(tileDataOb);
  };

  const updateScaleDataByUnit = (
    pCode: string,
    pType: string,
    setDefault: boolean
  ) => {
    let userDataOb = Object.assign({}, userData);
    if (userDataOb[pCode][pType]?.scale) {
      [
        userDataOb[pCode][pType].scale.scaleMin,
        userDataOb[pCode][pType].scale.scaleMax,
      ] = setDefault
        ? convert(
            userDataOb[pCode][pType].scale?.scaleMin,
            userDataOb[pCode][pType].scale?.scaleMax,
            pCode,
            userData[pCode].precision,
            userDataOb[pCode][pType]['currentUnit'],
            units[pCode].defaultUnit
          )
        : convert(
            userDataOb[pCode][pType].scale?.scaleMin,
            userDataOb[pCode][pType].scale?.scaleMax,
            pCode,
            userData[pCode].precision,
            units[pCode].unit
          );
      userDataOb[pCode][pType]['currentUnit'] = units[pCode].unit;
      setUserData(userDataOb);
    }
  };

  const setSelectedProducts = (category: any, code: any, type: any) => {
    let displayDefaults = Object.assign({}, displayConfig);
    let prod = displayDefaults.data[category]?.items[code.trim()];
    setSelected(prod, type);
  };

  const setSelectedMultiProducts = (
    category: any,
    code: any,
    selectionValue: boolean
  ) => {
    let displayDefaults = Object.assign({}, displayConfig);
    let prodCodes = code.split(',');
    prodCodes.forEach((plot: any) => {
      let pCode = plot.split('-');
      let prod = displayDefaults.data[category]?.items[pCode[0].trim()];
      if (
        selectionValue &&
        pCode.length > 1 &&
        pCode[0]?.trim() === 'WIND' &&
        pCode[1]?.trim() === 'raster'
      )
        userSettings.map?.showWindRaster &&
          setMultiplotSelected(prod, 'raster', selectionValue);
      else setMultiplotSelected(prod, pCode[1]?.trim(), selectionValue);
    });
  };

  const updateProductSelection = (pCode: string, selected: boolean) => {
    let selectedProducts = getSelectedProductsFromStorage();
    const displayDefaults = Object.assign({}, displayConfig);
    let productList: { code: string; type: string }[] = [];
    if (selectedProducts) {
      if (selected) {
        Object.keys(selectedProducts).forEach((type) => {
          _.filter(selectedProducts[type], (p) => p !== pCode).forEach(
            (code: string) => {
              productList.push({ code: code, type: type });
              displayDefaults.data[userData[code].category].items[code][
                type
              ].selected = false;
            }
          );
        });
        // This code is required to reset depth on raster selection
        // only if its corresponding contour / vector is not selected
        // If its contour/vector is selected, depth will not be reset
        handleDepthOnRasterSelection(displayDefaults, selectedProducts, pCode);
      } else {
        Object.keys(selectedProducts).forEach((type) => {
          if (selectedProducts[type].includes(pCode)) {
            productList.push({ code: pCode, type: type });
            displayDefaults.data[userData[pCode].category].items[pCode][
              type
            ].selected = false;
          }
        });
      }
      removeMultipleMapTiles(productList);
      setDisplayConfig(displayDefaults);
    }
  };

  const handleDepthOnRasterSelection = (
    displayDefaults: any,
    selectedProducts: any,
    pCode: string
  ) => {
    let subProductType = _.filter(
      displayDefaults.data[userData[pCode].category].items[pCode].type.split(
        ','
      ),
      (t) => t !== 'raster'
    );
    if (
      (subProductType.length &&
        !selectedProducts[subProductType[0]].includes(pCode)) ||
      subProductType.length === 0
    ) {
      const depth = initDepthToStorage();
    }
  };

  const saveDefault = async (
    product_code: string,
    product_type: string,
    defaults: ProductDefaults
  ) => {
    try {
      setLoading(true);
      await ProductClient.saveProductDefaults(
        userName,
        region.current || '',
        product_code,
        product_type,
        defaults
      );
      deleteFromCacheStorage(
        `product=${product_code}&product_type=${product_type}`
      );
      setLoading(false);
      let updatedUserData = userData;
      if (product_type === 'raster' && !_.isNil(defaults)) {
        updatedUserData[product_code].raster.user.min = defaults.user_min;
        updatedUserData[product_code].raster.user.max = defaults.user_max;
        updatedUserData[product_code].raster.user.colorScaleType =
          defaults.user_color_scale_type;
      }
      if (product_type === 'contour' && !_.isNil(defaults)) {
        updatedUserData[product_code].contour.user.color = defaults.color;
        updatedUserData[product_code].contour.user.intervalLevel =
          defaults.interval_level;
        updatedUserData[product_code].contour.user.min = defaults.user_min;
        updatedUserData[product_code].contour.user.max = defaults.user_max;
      }
      if (product_type === 'vector' && !_.isNil(defaults)) {
        updatedUserData[product_code].vector.user.color = defaults.color;
        updatedUserData[product_code].vector.user.size = defaults.size;
        updatedUserData[product_code].vector.user.style = defaults.style;
      }
      setUserData({ ...updatedUserData });
      setAlertMessage(
        intl.formatMessage({
          id: I18nKey.PRODUCT_SETTING_DIALOG_BOX_SAVEMESSAGE,
        }),
        'success',
        5000
      );
    } catch (err) {
      setLoading(false);
      setAlertMessage(
        intl.formatMessage({
          id: I18nKey.PRODUCTS_NO_DATA_MSG,
        }),
        'error',
        5000
      );
    }
  };

  const onDateChangeGetMaptile = () => {
    getMapTileData(getSelectedProductsFromStorage(), false, true, {});
  };

  const fetchCursorDataValues = (selectedProducts: any) => {
    let selectedProductList: any = getUniqueProducts(selectedProducts);
    selectedProductList.forEach((code: string) => {
      const [date, depth] = getDateAndDepth(userData[code]);
      displayConfig.data[userData[code].category].items[code].cursorData &&
        date &&
        populateCursorData(userName, date, region.current, code, depth || '-1');
    });
  };

  const getselectedTimeOrDepthProducts = (productType: string) => {
    let products: any = { raster: [], contour: [], vector: [], weather: [] };

    let selectedProducts = getSelectedProductsFromStorage();
    if (!_.isEmpty(selectedProducts)) {
      Object.keys(selectedProducts).forEach((type) => {
        selectedProducts[type].forEach((product: string) => {
          if (
            !_.isEmpty(userData[product]?.timeFrequency) &&
            productType === 'time'
          ) {
            products[type].push(product);
          }
          if (
            userData[product]?.depths[0] !== '-1' &&
            productType === 'depth'
          ) {
            products[type].push(product);
          }
        });
      });
    }
    return products;
  };

  function depthProductApiCall() {
    onDepthAndFrequencyChange(selectedDepthProducts);
  }

  const onDepthAndFrequencyChange = (selectedDepthAndTimeProducts: any) => {
    getMapTileData(selectedDepthAndTimeProducts, false, true, {});
  };

  return (
    <FeaturesContext.Provider
      value={{
        displayConfig,
        setDisplayConfig,
        categoryList,
        userData,
        setUserData,
        tileData,
        setTileData,
        setClickedProduct,
        setClickedMultiProduct,
        getMapTileData,
        removeMultipleMapTiles,
        saveDefault,
        getselectedTimeOrDepthProducts,
        onDepthAndFrequencyChange,
        depthSliderValue,
        setDepthSliderValue,
        selectedDepthProducts,
        usePreviousDate,
        setUsePreviousDate,
        productAvailabilityDate,
      }}
    >
      {alert.display && (
        <Snackbar
          type={alert.type}
          display={alert.display}
          message={alert.message}
        ></Snackbar>
      )}
      {loading && <ProgressSpinner showSpinner={loading} />}
      {props.children}
    </FeaturesContext.Provider>
  );
};

const mergeFeatureCategories = (categoryData: any) => {
  let data: any = {};
  Object.keys(categoryData).forEach((k) => {
    Object.keys(categoryData[k]).forEach((d: any) => {
      categoryData[k][d]['type'] = k;
      data[d] = categoryData[k][d];
    });
  });
  return data;
};

const sortByCategory = (data: any) => {
  return _.orderBy(
    Object.keys(data),
    (category) => data[category].displayOrder
  );
};

const getTypeKeysList = (data: any, type: string) => {
  return _.filter(
    Object.keys(data),
    (category) => data[category].type === type
  );
};

const initDataNSortProducts = (data: any, tileData: any): any => {
  Object.values(data).forEach((category: any) => {
    sortNInitProducts(category, tileData);
  });
  return [data, tileData];
};

const sortNInitProducts = (category: any, tileData: any) => {
  category['productSortedList'] = _.orderBy(
    Object.keys(category.items),
    (pr) => category.items[pr].displayOrder
  );
  if (category.displayName === 'inland') {
    category.productSortedList.splice(2, 0, 'EMPTY');
  }
  Object.keys(category.items).forEach((p) => {
    tileData[p] = {};
    category.items[p].type.split(',').forEach((t: any) => {
      category.items[p][t] = { selected: false };
      tileData[p][t] = {};
    });
  });
};

const setUserProductDefaults = (userData: any, productDisplayConfig: any) => {
  let displayConfig = Object.assign({}, productDisplayConfig);
  let displayConfigData = _.map(
    _.filter(displayConfig.data, { type: 'products' }),
    (p) => {
      return p.items;
    }
  ).reduce((r, c) => ({ ...r, ...c }), {});

  Object.keys(userData).forEach((prod: any) => {
    if (_.has(displayConfigData, prod)) {
      displayConfigData[prod]['disabled'] = userData[prod].disabled;
      displayConfigData[prod]['ncVariable'] = userData[prod].ncVariable;
      displayConfigData[prod]['precision'] = userData[prod].precision;
      displayConfigData[prod]['scaleType'] = userData[prod].scaleType;
      displayConfigData[prod]['timeFrequency'] = userData[prod].timeFrequency;
      displayConfigData[prod]['depths'] = userData[prod].depths;
      displayConfigData[prod]['category'] = userData[prod].category;
      if (userData[prod].raster) {
        displayConfigData[prod]['raster'] = Object.assign(
          {},
          userData[prod].raster
        );
        delete displayConfigData[prod].raster.user;
      }
      if (userData[prod].contour) {
        displayConfigData[prod]['contour'] = Object.assign(
          {},
          userData[prod].contour
        );
        delete displayConfigData[prod].contour.user;
      }
      if (userData[prod].vector) {
        displayConfigData[prod]['vector'] = Object.assign(
          {},
          userData[prod].vector
        );
        delete displayConfigData[prod].vector.user;
      }
    }
  });
  return displayConfig;
};
