export const radioButtonStyles: any = {
  borderRadius: '50%',
  width: 15,
  height: 15,
  boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
  backgroundColor: '#3D3D3D',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#3D3D3D',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(57,75,89,.5)',
  },
};

export const iconStyle: any = {
  backgroundColor: 'orange',
  'input:hover ~ &': {
    backgroundColor: 'orange',
  },
};
