import { IUUSettingsAISAndVMSDisplayConfigType } from './IUUSettings/IUUSettingsTypes';
import aisShipIcon from './../../../assets/icons/ais_ship.png';
import vmsShipIcon from './../../../assets/icons/vms_ship.png';
import buoyIcon from './../../../assets/icons/bouy-new.png';
import { I18nKey } from '../../../translations/I18nKey';

export const FENCE_CHART_COLORS: any = { safe: '#379203', alert: '#921703' };

export const vesselIcons: Record<string, any> = {
  AIS: aisShipIcon,
  Vessel: vmsShipIcon,
  Buoy: buoyIcon,
};

export const VMSBounds: Record<string, any> = {
  topRight: '35.997633702482915,-13.20482348252915',
  bottomLeft: '-2.0810284993495003,-154.74224230902658',
};

export const IUU_SETTINGS_AIS_VMS_COLUMNS = {
  vesselTypeColumnConfig: [
    {
      name: 'vesselType',
      icon: false,
      includeInSearch: true,
      displayInOption: true,
      displayInChip: true,
    },
  ],
  vesselNameColumnConfig: [
    {
      name: 'vesselName',
      icon: false,
      includeInSearch: true,
      displayInOption: true,
      displayInChip: true,
    },
  ],
  MMSIColumnConfig: [
    {
      name: 'vesselId',
      icon: false,
      includeInSearch: true,
      displayInOption: true,
      displayInChip: true,
    },
    {
      name: 'vesselName',
      icon: false,
      includeInSearch: false,
      displayInOption: true,
      displayInChip: false,
    },
  ],
  countryColumnConfig: [
    {
      name: 'flagCountry',
      icon: false,
      includeInSearch: true,
      displayInOption: true,
      displayInChip: true,
    },
  ],
};

export const IUU_SETTINGS_VESSEL_COLUMNS = {
  vesselNameColumnConfig: [
    {
      name: 'vesselName',
      icon: false,
      includeInSearch: true,
      displayInOption: true,
      displayInChip: true,
    },
  ],
};

export const IUU_SETTINGS_BUOY_COLUMNS = {
  vesselNameColumnConfig: [
    {
      name: 'vesselName',
      icon: false,
      includeInSearch: true,
      displayInOption: true,
      displayInChip: true,
    },
  ],
  buoyIdColumnConfig: [
    {
      name: 'buoysId',
      icon: false,
      includeInSearch: true,
      displayInOption: true,
      displayInChip: true,
    },
  ],
};

export const IUU_SETTINGS_FISHCATCH_COLUMNS = {
  vesselColumnConfig: [
    {
      name: 'vessel',
      icon: false,
      includeInSearch: true,
      displayInOption: true,
      displayInChip: true,
    },
  ],
  speciesColumnConfig: [
    {
      name: 'name',
      icon: false,
      includeInSearch: true,
      displayInOption: true,
      displayInChip: true,
    },
  ],
  fishingTechniqueColumnConfig: [
    {
      name: 'fishingTechnique',
      icon: false,
      includeInSearch: true,
      displayInOption: true,
      displayInChip: true,
    },
  ],
};

export const IUU_SETTINGS_DEFAULT_DATE_DURATION = '24';

export const IUU_SETTINGS_AIS_DISPLAY_CONFIG: IUUSettingsAISAndVMSDisplayConfigType =
  {
    showVesselTypeAutocomplete: true,
    showCountryNameAutocomplete: true,
    showVesselNameAutocomplete: true,
    showMMSIAutocomplete: true,
    showSpeed: true,
    showTracks: true,
    localStorageKey: 'AISFilters',
  };

export const IUU_SETTINGS_VMS_DISPLAY_CONFIG: IUUSettingsAISAndVMSDisplayConfigType =
  {
    showVesselTypeAutocomplete: false,
    showCountryNameAutocomplete: true,
    showVesselNameAutocomplete: true,
    showMMSIAutocomplete: false,
    showSpeed: true,
    showTracks: true,
    localStorageKey: 'VMSFilters',
  };
export const defaultVMSFilter = {
  vesselName: [],
  country: [],
  speed: {
    to: 25,
    from: 0,
  },
};

export const defaultAISFilter = {
  vesselType: ['Fishing'],
  vesselId: [],
  flagCountry: [],
  vesselName: [],
  speed: {
    to: 25,
    from: 0,
  },
};

export const defaultBuoyFilter = {
  vesselName: [],
  buoysId: [],
  dataDuration: {
    fromDate: null,
    toDate: null,
  },
  dataDurationValue: '0',
  showTracks: false,
};

export const defaultVesselFilter = {
  vesselName: [],
  dataDuration: {
    fromDate: null,
    toDate: null,
  },
  dataDurationValue: '0',
  showTracks: false,
};

export const defaultFishCatchFilter = {
  vessels: [],
  species: [],
  fishingTechniques: [],
  dataDuration: {
    fromDate: null,
    toDate: null,
  },
  dataDurationValue: '0',
};

export const durationListForVesselBuoys = [
  { value: 7, name: I18nKey.IUU_SETTINGS_7_DAYS },
  { value: 30, name: I18nKey.IUU_SETTINGS_30_DAYS },
  { value: 90, name: I18nKey.IUU_SETTINGS_3_MONTHS },
  { value: 180, name: I18nKey.IUU_SETTINGS_6_MONTHS },
  { value: 365, name: I18nKey.IUU_SETTINGS_1_YEAR },
  { value: 0, name: I18nKey.IUU_SETTINGS_ALL },
];

export const GEOFENCE_AIS_DISPLAY_CONFIG: IUUSettingsAISAndVMSDisplayConfigType =
  {
    showVesselTypeAutocomplete: true,
    showCountryNameAutocomplete: true,
    showVesselNameAutocomplete: true,
    showMMSIAutocomplete: true,
    showSpeed: true,
    localStorageKey: 'GeofenceAISFilters',
  };
export const GEOFENCE_VMS_DISPLAY_CONFIG: IUUSettingsAISAndVMSDisplayConfigType =
  {
    showCountryNameAutocomplete: true,
    showVesselNameAutocomplete: true,
    showSpeed: true,
    localStorageKey: 'GeofenceVMSFilters',
  };

export const defaultDataDuration = 24;

export const trackPointsTypeConfig = {
  start: 0,
  middle: 1,
  end: 2,
};

export const trackLineConfig = {
  'line-color': 'gray',
  'line-width': 2,
  'line-dasharray': [2, 1],
};

export const trackDotsConfig = {
  'circle-color': 'brown',
  'circle-radius': 5,
};

export const vesselSources = ['AIS', 'VMS'];

export const AISTrackTimelineConfig = {
  totalDuration: 30,
  defaultDuration: 7,
  dateFormat: 'YYYYMMDDHHmmss',
};

export const geofencePolygonColorConfig = [
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'fill-color': '#0000ff',
      'fill-outline-color': '#ffa500',
      'fill-opacity': 0.5,
    },
  },
  {
    id: 'gl-draw-polygon-fill-active',
    type: 'fill',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': '#fbb03b',
      'fill-outline-color': '#fbb03b',
      'fill-opacity': 0.1,
    },
  },
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 3,
      'circle-color': '#fbb03b',
    },
  },
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#ffa500',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#fbb03b',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'LineString'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#fbb03b',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#fbb03b',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#fbb03b',
    },
  },
  {
    id: 'gl-draw-point-point-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-opacity': 1,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#3bb2d0',
    },
  },
  {
    id: 'gl-draw-point-stroke-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['!=', 'meta', 'midpoint'],
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['!=', 'meta', 'midpoint'],
      ['==', 'active', 'true'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fbb03b',
    },
  },
  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': '#404040',
      'fill-outline-color': '#404040',
      'fill-opacity': 0.1,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#404040',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#404040',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-point-static',
    type: 'circle',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#404040',
    },
  },
];

export const RFColors: { [key: string]: string } = {
  VHFellipses: 'purple',
  Lbandmulti: 'red',
  Xbandellipses: 'black',
  Lbandellipses: 'red',
  Xbandmulti: 'black',
  VHFmulti: 'purple',
};

export const DTColors: { [key: string]: string } = {
  extents: 'purple',
  targets: 'red',
};

export const flyToConfig = {
  speed: 0.5,
  trackZoomLevel: 7,
};
