export const FooterStyles = {
  divider: {
    backgroundColor: '#6F6F6F',
  },
  leftElement: {
    width: '300px',
    borderRadius: '0px 19px 19px 0px',
    opacity: '0.88',
    color: '#000000',
    position: 'initial',
  },
  footer: {
    borderTop: 'none',
    display: 'flex',
    backgroundColor: 'none',
    //width: 'auto',
    marginBottom: '-3px',
  },
  section: {
    padding: '0px',
    paddingTop: '7px',
    display: 'flex',
    width: '300px',
    backgroundColor: '#ffffff',
    color: '#000000',
    opacity: '0.88',
    borderRadius: '0px 19px 19px 0px',
    paddingBottom: '7px',
  },
  centerElement: {
    fontSize: '14px',
    fontWeight: 'bold',
    paddingTop: '6px',
    width: '300px',
    borderTop: 'none',
    position: 'initial',
    backgroundColor: '#FFFFFF',
    borderRadius: '27px 27px 0px 0px',
    paddingBottom: '7px',
    display: 'initial',
    //marginLeft: '-278px !important',
  },
};

export const MapMainStyles = {
  settingsContainer: { position: 'relative' },
  useSettingsContainer: { position: 'absolute', right: '0%' },
  openSetting: { position: 'absolute', right: '569px', top: '80px' },
  closeSetting: { position: 'absolute', right: '216px', top: '80px' },
};

export const psdbStyle = (depthSliderOpen: boolean) => {
  let marginTop = depthSliderOpen ? '-280px' : '0px';
  return {
    openSetting: { position: 'relative', right: '569px', marginTop },
    closeSetting: { position: 'relative', right: '216px', marginTop },
  };
};
