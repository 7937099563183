import { Box } from '@mui/system';
import { UserSettings } from './UserSettings/UserSettings';
import { FeatureNavigation } from './FeatureMenu/FeatureNavigation';
import { ProductContainer } from './Products/ProductContainer';
import './Map.scss';
import { MapMainStyles } from './MapStyles';
import { IUUContainer } from './IUU/IUUContainer';
import { AnalysisContainer } from './Analysis/AnalysisContainer';

export const MapMain: React.FC<any> = ({ openSettings, onCloseSettings }) => {
  return (
    <>
      <Box sx={MapMainStyles.settingsContainer}>
        <Box sx={MapMainStyles.useSettingsContainer}>
          {openSettings && <UserSettings onClose={onCloseSettings} />}
          <Box>
            <FeatureNavigation />
          </Box>
        </Box>
      </Box>
      <ProductContainer openSettings={openSettings} />
      <Box>
        <IUUContainer openSettings={openSettings} />
        <AnalysisContainer openSettings={openSettings} />
      </Box>
    </>
  );
};
