export const userSettingsStyles: any = {
  popup: {
    wrapper: {
      height: '80vh',
      zIndex: 1300,
    },
  },
  tabs: {
    marginLeft: '37px',
  },
  tabPanelRoot: {
    marginTop: '17px',
    padding: '0',
    maxHeight: 'calc(70vh - 52px)',
    overflowY: 'auto',
  },
  tabRoot: {
    color: '#6F6F6F !important',
    fontSize: '16px',
  },
  tabSelected: {
    color: 'white !important',
    fontSize: '16px',
  },
};

export const tabIndicatorProps: any = {
  style: {
    background: 'orange',
    marginTop: '20px',
    height: '3px',
  },
};
