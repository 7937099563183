export const PopUpStyles: any = {
  headerStyle: {
    backgroundColor: 'orange',
    padding: 11,
    textAlign: 'center',
    color: 'white',
    width: '80%',
  },
  LabelStyle: {
    backgroundColor: 'black',
    padding: 1,
    textAlign: 'left',
    color: 'white',
    align: 'left',
    height: 30,
    width: 70,
    paddingLeft: '0',
  },
  ValueStyle: {
    backgroundColor: 'black',
    padding: 1,
    textAlign: 'left',
    color: 'white',
    height: 20,
    align: 'left',
    fontSize: '14px'
  },
  closeIcon: {
    width: '35px',
    height: '35px',
    position: 'absolute',
    right: '-15px',
    top: '-15px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
};
