import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { FooterProps } from './FooterTypes';
import { CopyRightSection } from './CopyRightSection';
import { FooterStyles } from './FooterStyles';

export const Footer: React.FC<FooterProps> = ({
  footerStyle,
  leftElement,
  leftElementStyle,
  centerElement,
  centerElementStyle,
  rightElement,
  rightElementStyle,
  alignGridItems,
  centerAlignSelf,
  rightAlignSelf,
  leftAlignSelf,
}) => {
  const useStyles = makeStyles({
    ...FooterStyles,
    ...footerStyle,
  });
  const classes = useStyles();
  const centerUseStyles = makeStyles({...centerElementStyle});
  const leftUseStyles = makeStyles({...leftElementStyle});
  const rightUseStyles = makeStyles({...rightElementStyle});

  const centerClasses = centerUseStyles();
  const leftClasses = leftUseStyles();
  const rightCalsses = rightUseStyles();

  return (
    <Grid
      container
      className={`${classes.defaultStyle} ${classes.footer}`}
      direction="column"
    >
      <Grid
        id="footerLeftElement"
        item
        xs={4}
        md={4}
        className={leftClasses.leftElement}
        // alignSelf={leftAlignSelf}
      >
        {leftElement}
      </Grid>
      <Grid
        id="footerCenterElement"
        item
        xs={4}
        md={4}
        alignSelf={centerAlignSelf}
        className={centerClasses.centerElement}
      >
        {centerElement}
      </Grid>
      <Grid
        id="footerRightElement"
        item
        xs={4}
        md={4}
        alignSelf="auto"
        className={rightCalsses.rightElement}
      >
        {rightElement}
      </Grid>
    </Grid>
  );
};

Footer.defaultProps = {
  footerStyle: FooterStyles,
  leftElement: <></>,
  centerElement: <CopyRightSection />,
  rightElement: <></>,
  alignGridItems: 'center',
  centerAlignSelf: 'center',
};
