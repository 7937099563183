export const ProductLegendStyles: any = {
  scaleDisplay: {
    width: '366px',
    position: 'relative',
    height: ' 23px',
    marginLeft: '31px',
    marginTop: '13px',
  },
  scaleValue: {
    fontSize: '14px',
    marginRight: '12px',
    display: 'inline',
    transform: 'rotate(270deg)',
    marginTop: '15px',
  },
  wrapper: {
    height: '165px',
  },
  scalePointer: {
    height: '0px',
    width: '0px',
    borderTop: '10px solid #FF6363',
    borderRight: '12px solid transparent',
    borderLeft: '12px solid transparent',
    display: 'block',
    position: ' relative',
    marginBottom: '-9px',
  },
  scalePointerValue: {
    position: 'relative',
    display: 'block',
    top: '-31px',
    right: '12px',
    fontSize: '14px',
  },
  scale: { marginLeft: '19px', width: 'inherit' },
  boxStyle: {
    width: '40px',
    height: '13px',
    cursor: 'auto',
    border: '1px #777171 solid',
  },
  boxTextStyle: {
    fontSize: '14px',
    marginTop: '-3px',
    marginLeft: '9px',
  },
  rightElement: {
    paddingLeft: '27px',
  },
  leftElement: {},
  dateIcon: {
    color: '#f74b4b',
    fontSize: '15px',
    marginRight: '5px',
    marginLeft: '5px',
    marginTop: '13px',
  },
  date: {
    color: '#f74b4b',
    fontSize: '13px',
    marginTop: '11px',
    marginRight: '5px',
  },
  dateSection: {
    marginTop: '2px',
    marginBottom: '13px',
    display: 'flex',
  },
  rightElementDateIcon: {
    color: '#f74b4b',
    fontSize: '15px',
    marginRight: '1px',
  },
  rightElementDate: {
    color: '#f74b4b',
    fontSize: '13px',
  },
};
