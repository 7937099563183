import { useEffect, useRef, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import _ from 'lodash';
import { useContextSelector } from 'use-context-selector';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../../translations/I18nKey';
import { PopupFooter } from '../../../components/SettingsPopup/PopupFooter/PopupFooter';
import { PopupHeader } from '../../../components/SettingsPopup/PopupHeader/PopupHeader';
import { SettingsDraggablePopup } from '../../../components/SettingsPopup/SettingsPopup';
import Snackbar from './../../../components/Snackbar/Snackbar';
import { ProgressSpinner } from '../../../components/ProgressSpinner/ProgressSpinner';
import { GeneralSettings } from './GeneralSettings';
import { ProductSettings } from './ProductSettings';
import { TabPanelProps } from './UserSettingsTypes';
import {
  saveSettingsStatus,
  UserSettingsContext,
} from '../../Contexts/UserSettingsContext';
import { useStyles } from '../../../utils/util';
import { tabIndicatorProps, userSettingsStyles } from './UserSettingsStyles';

export const UserSettings: React.FC<any> = ({ onClose }) => {
  const draggableRef = useRef<HTMLDivElement>();
  const [state, setState] = useState({
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, right: 0, bottom: 0 },
    showFooter: false,
  });
  const userSettings = useContextSelector(
    UserSettingsContext,
    (state) => state
  );

  const [settings, setSettings] = useState(
    _.cloneDeep(userSettings.userSettings)
  );
  const [initialUserSettings, setIntitialUserSettings] = useState(
    _.cloneDeep(userSettings.userSettings)
  );
  const [alert, setAlert] = useState({
    type: '',
    display: false,
    message: '',
  });
  const [loading, setLoading] = useState(false);

  const resetSettings = () => {
    setSettings(_.cloneDeep(initialUserSettings));
  };

  useEffect(() => {
    if (userSettings.saveStatus === saveSettingsStatus.success) {
      setLoading(false);
      onClose();
    } else if (userSettings.saveStatus === saveSettingsStatus.error) {
      setLoading(false);
    }
  }, [userSettings.saveStatus]);

  const saveSettings = () => {
    if (settings.products.length) {
      let weatherProducts =
        settings.products[
          _.findIndex(settings.products, { category: 'weather' })
        ];
      if (weatherProducts?.products) {
        let windRasterIndex = _.findIndex(weatherProducts.products, {
          code: 'WIND_RASTER',
        });
        if (windRasterIndex !== -1) {
          weatherProducts.products.splice(windRasterIndex, 1);
        }
      }
    }
    setLoading(true);
    userSettings.saveUserSettings(_.cloneDeep(settings));
  };

  const settingsFooter = {
    reset: {
      text: I18nKey.SETTINGS_POPUP_RESET,
      onClick: resetSettings,
    },
    action: {
      text: I18nKey.SETTINGS_POPUP_SAVE,
      onClick: saveSettings,
    },
  };

  return (
    <>
      {alert.display && (
        <Snackbar
          type={alert.type}
          display={alert.display}
          message={alert.message}
        ></Snackbar>
      )}
      {loading && <ProgressSpinner showSpinner={loading} />}

      <SettingsDraggablePopup
        popupStyle={userSettingsStyles.popup}
        headerElement={
          <PopupHeader
            title={I18nKey.USER_SETTINGS_SETTINGS}
            onClose={onClose}
          ></PopupHeader>
        }
        bodyElement={<Settings settings={{ settings, setSettings }}></Settings>}
        footerElement={
          <PopupFooter
            resetDefault={settingsFooter.reset}
            actionButton={settingsFooter.action}
          ></PopupFooter>
        }
        draggableRef={draggableRef}
        dragState={state}
        setDragState={setState}
      ></SettingsDraggablePopup>
    </>
  );
};

const Settings: React.FC<any> = ({ settings }) => {
  const intl = useIntl();
  const classes = useStyles(userSettingsStyles, [])();
  const [tabValue, setTabValue] = useState('general');

  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Tabs
          className={classes.tabs}
          value={tabValue}
          onChange={onTabChange}
          TabIndicatorProps={tabIndicatorProps}
        >
          <Tab
            value="general"
            label={intl.formatMessage({
              id: I18nKey.USER_SETTINGS_GENERAL,
            })}
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          ></Tab>
          {settings?.settings?.products?.length ? (
            <Tab
              value="products"
              label={intl.formatMessage({
                id: I18nKey.USER_SETTINGS_PRODUCTS,
              })}
              classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            ></Tab>
          ) : null}
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index="general">
        <GeneralSettings settings={settings}></GeneralSettings>
      </TabPanel>
      {settings.settings.products.length ? (
        <TabPanel value={tabValue} index="products">
          <ProductSettings settings={settings}></ProductSettings>
        </TabPanel>
      ) : null}
    </>
  );
};

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const classes = useStyles(userSettingsStyles, [])();
  return (
    <div>
      {props.value === props.index && (
        <Box className={classes.tabPanelRoot}>{props.children}</Box>
      )}
    </div>
  );
};
