export const popupHeaderStyles: any = {
  header: {
    height: '40px',
  },
  title: {
    color: 'white',
    float: 'left',
    marginLeft: '10px',
    fontSize: '18px',
    marginTop: '7px',
  },
  close: {
    float: 'right',
    backgroundColor: 'black',
    color: 'white',
    border: 'none',
    marginTop: '7px',
    marginRight: '4px',
    marginBottom: '5px',
    cursor: 'pointer',
  },
  closeImage: {
    width: '24px',
    height: '24px',
    filter: 'brightness(0) invert(1)',
    marginTop: '-1px',
    marginRight: '-12px',
  },
  lineStyle: {
    height: '1px',
    backgroundColor: '#242424',
    border: 'none',
  },
};
