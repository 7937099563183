export const colorBoxStyles: any = {
  boxStyle: {
    width: '100%',
    height: '24px',
    zIndex: '25',
    cursor: 'pointer',
  },
  boxTextStyle: {
    fontSize: '14px',
    marginTop: '-3px',
  },
  titleStyle: {
    color: '#FFFFFF',
    fontSize: '14px',
  },
  subTitleStyle: {
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: '600',
  },
};
