import React from 'react';
import { Box } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../../translations/I18nKey';
import { DatePickerProps } from '../DateRangeTypes';
import '../Styles/DatePickerStyles.scss';
import _ from 'lodash';

const DatePicker: React.FC<DatePickerProps> = ({
  defaultSelection,
  format,
  calenderData,
  height,
  width,
  onChange,
  showLabel,
  disabled = false,
  textColor = '#000',
}) => {
  const intl = useIntl();

  const handleOpen = () => {
    setTimeout(() => {
      calenderData?.forEach((el: any) => {
        let data = el;
        let y = document.querySelector(
          `button[aria-label="${moment(el.date).format('MMM D, YYYY')}"]`
        ) as HTMLElement;
        if (y) {
          let text = '';
          if (data.products) {
            data.products.forEach((product: string) => {
              text = text + product + '\n';
            });
          }
          y.setAttribute('title', text);
          y.classList.add('productsData');
        }
      });
    });
  };

  return (
    <Box height={height} width={width}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label={
            showLabel &&
            `${intl.formatMessage({
              id: I18nKey.SELECT_DATE,
            })}`
          }
          format={format}
          value={defaultSelection}
          onOpen={handleOpen}
          onChange={onChange}
          onMonthChange={handleOpen}
          onYearChange={handleOpen}
          slotProps={{
            textField: {
              inputProps: {
                color: `${textColor} !important`,
              },
            },
          }}
          disabled={disabled}
        />
      </LocalizationProvider>
    </Box>
  );
};

DatePicker.defaultProps = {
  height: 46,
  width: 169,
};

export default DatePicker;
