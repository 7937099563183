import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography, Slider } from '@mui/material';
import { SingleSliderStyles, Styles, InputStyle } from '../SliderStyles';
import { SingleSliderProps } from '../SliderTypes';

const SingleSlider: React.FC<SingleSliderProps> = ({
  style,
  step = 1,
  selectedValue,
  unit,
  defaultValue,
  marks,
  onChangeHandler,
  precision = 1,
  setSelectedValue,
  disabled = false,
}) => {
  const [textBoxState, setTextBoxState] = useState<any>(selectedValue);

  useEffect(() => {
    setTextBoxState(selectedValue);
  }, [selectedValue]);

  const handleTextBox = (e: any) => {
    if (e.key === 'Enter' || e.type === 'blur') {
      const newValue = Number(Number(textBoxState).toFixed(precision));
      const scaleMax = Number(marks[marks.length - 1].value);
      const scaleMin = Number(marks[0].value);

      if (newValue > scaleMax) {
        setSelectedValue(scaleMax);
        setTextBoxState(scaleMax);
      } else if (newValue < scaleMin) {
        setSelectedValue(scaleMin);
        setTextBoxState(scaleMin);
      } else {
        setSelectedValue(newValue);
        setTextBoxState(newValue);
      }
    }
  };
  return (
    <Box sx={{ width: '95%', marginLeft: '3px' }}>
      <Box>
        <Box sx={{ display: 'inline' }} height={29} width={61}>
          <TextField
            InputProps={{
              sx: { ...InputStyle(disabled) },
              autoComplete: 'off',
            }}
            id="outlined-size-small"
            size="small"
            value={textBoxState}
            color={'warning'}
            inputProps={{
              type: 'number',
              min: Number(marks[0].value),
              max: Number(marks[marks.length - 1].value),
              step: step,
            }}
            onChange={function (e) {
              setTextBoxState(e.target.value);
            }}
            onFocus={(event) => {
              event.target.select();
            }}
            onKeyPress={handleTextBox}
            onBlur={handleTextBox}
            disabled={disabled}
          />
        </Box>
        <Typography sx={{ ...Styles.typoGraphy, ml: 1 }}>{unit}</Typography>
      </Box>
      <Slider
        min={marks[0].value}
        max={marks[marks.length - 1].value}
        sx={{
          ...SingleSliderStyles,
          marginLeft: '5px',
        }}
        value={selectedValue}
        defaultValue={defaultValue}
        marks={marks}
        onChange={onChangeHandler}
        disabled={disabled}
      />
    </Box>
  );
};

SingleSlider.defaultProps = {
  selectedValue: 30,
  marks: [
    {
      value: 0,
      label: '0',
    },
    {
      value: 100,
      label: '100',
    },
  ],
  defaultValue: 80,
};

export default SingleSlider;
