import moment from 'moment';
import { useIntl } from 'react-intl';
import { DataTableConfigType } from '../../components/DataTable/DataTableTypes';
import { I18nKey } from '../../translations/I18nKey';

export const GeofenceIcons: any = {
  true: {
    name: 'check_circle_outline.svg',
    iconStyle: { color: 'green' },
  },
  false: {
    name: 'remove_circle_outline.svg',
    iconStyle: { color: 'red' },
  },
};

export const vesselLayout: { [key: string]: any } = {
  'icon-size': 0.5,
  'icon-rotation-alignment': 'map',
  'symbol-placement': 'point',
  'icon-allow-overlap': true,
};

export const SunburstConfig = {
  type: 'sunburst',
  leaf: { opacity: 0.4 },
  marker: { line: { width: 2 } },
  branchvalues: 'total',
};

export const vesselTypeBarChartConfig = {
  showLine: true,
};

export const countryColumnConfig = [
  {
    name: 'country',
    icon: false,
    includeInSearch: true,
    displayInOption: true,
    displayInChip: true,
  },
];

export const vesselTypesColumnConfig = [
  {
    name: 'type',
    icon: false,
    includeInSearch: true,
    displayInOption: true,
    displayInChip: true,
  },
];

export const geofencesColumnConfig = [
  {
    name: 'name',
    icon: false,
    includeInSearch: true,
    displayInOption: true,
    displayInChip: true,
  },
  {
    name: 'active',
    icon: false,
    includeInSearch: false,
    displayInOption: false,
    displayInChip: false,
  },
  {
    name: 'fenceTypeIcon',
    icon: true,
    includeInSearch: false,
    displayInOption: true,
    displayInChip: false,
  },
];
export const tableDateFormat = 'MMM DD, YYYY HH:mm:ss';
export const IUUDashboardDataGridConfig: DataTableConfigType = {
  pagination: {
    rowsPerPageOptions: [10, 20, 30],
  },
  search: true,
  gridHeight: '500px',
};

export const DatePickerRanges = () => {
  const intl = useIntl();
  let DatePickerRanges: any = {};
  DatePickerRanges[
    intl.formatMessage({
      id: I18nKey.CALENDAR_LAST24HOURS,
    })
  ] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
  DatePickerRanges[
    intl.formatMessage({
      id: I18nKey.CALENDAR_LAST7DAYS,
    })
  ] = [moment().subtract(6, 'days'), moment()];
  DatePickerRanges[
    intl.formatMessage({
      id: I18nKey.CALENDAR_LAST30DAYS,
    })
  ] = [moment().subtract(29, 'days'), moment()];
  return Object.freeze(DatePickerRanges);
};
