import { Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CopyRightSectionProps } from './FooterTypes';
import maxarLogo from '../../assets/icons/maxar_logo.png';
import { CopyRightSectionStyles } from './CopyRightSectionStyles';

export const CopyRightSection: React.FC<CopyRightSectionProps> = ({
  sectionStyle,
  logo,
  text,
  addDivider,
  dividerStyle,
}) => {
  //let classes = useStyles(CopyRightSectionStyles, [dividerStyle])();

  const useStyles = makeStyles({
    ...CopyRightSectionStyles,
    ...sectionStyle,
    ...dividerStyle,
  });
  const classes = useStyles();

  return (
    <Box className={classes.section}>
      <img src={logo} className={classes.logo} alt=""></img>
      {addDivider && (
        <Divider
          orientation="vertical"
          flexItem
          classes={{ root: classes.divider }}
        ></Divider>
      )}
      <Box className={classes.defaultText}>
        <span className={classes.copyRightIcon}>&copy;</span> {text}
      </Box>
    </Box>
  );
};

CopyRightSection.defaultProps = {
  sectionStyle: CopyRightSectionStyles,
  logo: maxarLogo,
  text: `Maxar Technologies ${new Date().getFullYear()}`,
  addDivider: true,
};
