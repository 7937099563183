import {
  FormControlLabel,
  MenuItem,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { I18nKey } from '../../../translations/I18nKey';
import RadioButton from '../../../components/RadioButton/RadioButton';
import { Toggle } from '../../../components/Toggle/Toggle';
import { LabelTextTypography } from '../../../components/TextFormat/TextFormat';
import { SettingsDefault } from './config';
import { useStyles } from '../../../utils/util';
import { generalSettingsStyles } from './GeneralSettingsStyles';
import { RegionContext } from '../../Contexts/RegionContext';
import { useContextSelector } from 'use-context-selector';

export const GeneralSettings: React.FC<any> = ({ settings }) => {
  const intl = useIntl();
  const classes = useStyles(generalSettingsStyles, [])();
  const settingsDefault = SettingsDefault;
  const regions = useContextSelector(RegionContext, (state) => state);
  const userSettings = settings.settings;
  const setUserSettings = settings.setSettings;
  const onToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const updateSettings = {
      ...userSettings,
    };
    _.set(updateSettings, name, checked);
    if (name === 'map.showBathymetryContour' && checked === false) {
      _.set(updateSettings, 'map.showBathymetryLabel', false);
    }
    if (name === 'map.showBathymetryLabel' && checked === true) {
      _.set(updateSettings, 'map.showBathymetryContour', true);
    }
    setUserSettings(updateSettings);
  };

  const onValueChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    const updateSettings = {
      ...userSettings,
    };
    _.set(updateSettings, name, value);
    setUserSettings(updateSettings);
  };

  return (
    <>
      <Box className={classes.settings}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={4}
        >
          <Stack
            direction="column"
            justifyContent="space-evenly"
            alignItems="flex-start"
            spacing={0}
          >
            <LabelTextTypography>
              {intl.formatMessage({
                id: I18nKey.USER_SETTINGS_DEFAULT_MAP,
              })}
            </LabelTextTypography>
            <br />
            <Select
              value={userSettings.map?.defaultBaseMapCode || ''}
              name="map.defaultBaseMapCode"
              onChange={onValueChange}
              classes={{ select: classes.selectMenu }}
            >
              {Object.keys(settingsDefault.basemap).map((k) => {
                return (
                  <MenuItem value={k} key={k}>
                    {settingsDefault.basemap[k]}
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>
          <Stack
            direction="column"
            justifyContent="space-evenly"
            alignItems="flex-start"
            spacing={0}
          >
            <LabelTextTypography>
              {intl.formatMessage({
                id: I18nKey.USER_SETTINGS_LANGUAGE,
              })}
            </LabelTextTypography>
            <br />
            <Select
              value={userSettings.general?.languageCode || ''}
              name="general.languageCode"
              onChange={onValueChange}
              classes={{ select: classes.selectMenu }}
            >
              {Object.keys(settingsDefault.languages).map((k) => {
                return (
                  <MenuItem key={k} value={k}>
                    <Tooltip
                      title={`${settingsDefault.languages[k].desciption}`}
                    >
                      <div>{settingsDefault.languages[k].name}</div>
                    </Tooltip>
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>
        </Stack>
        <br />
        <Stack
          direction="column"
          justifyContent="space-evenly"
          alignItems="flex-start"
          spacing={0}
        >
          <FormControlLabel
            value="end"
            control={
              <Toggle
                checked={userSettings.map?.showGraticule || false}
                name="map.showGraticule"
                onChange={onToggleChange}
                value={userSettings.map?.showGraticule}
              />
            }
            label={intl.formatMessage({
              id: I18nKey.USER_SETTINGS_GRATICULE,
            })}
            labelPlacement="end"
            className={classes.toggleFormat}
          />
          <FormControlLabel
            value="end"
            control={
              <Toggle
                checked={userSettings.map?.showEez || false}
                name="map.showEez"
                onChange={onToggleChange}
                value={userSettings.map?.showEez}
              />
            }
            label={intl.formatMessage({
              id: I18nKey.USER_SETTINGS_EEZ,
            })}
            labelPlacement="end"
            className={classes.toggleFormat}
          />
        </Stack>
      </Box>
      <hr className={classes.lineStyle} />
      <Box className={classes.settings}>
        <LabelTextTypography>
          {intl.formatMessage({
            id: I18nKey.USER_SETTINGS_REGIONS,
          })}
        </LabelTextTypography>
        <br />
        <Select
          value={
            _.find(
              regions,
              (r) => r.code === userSettings.map?.regionsOfInterest
            ).code || ''
          }
          name="map.regionsOfInterest"
          onChange={onValueChange}
          classes={{ select: classes.selectMenu }}
        >
          {regions.map((k: any) => {
            return (
              <MenuItem key={k.code} value={k.code}>
                {k.name}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
      <hr className={classes.lineStyle} />
      <Box className={classes.settings}>
        <LabelTextTypography>
          {intl.formatMessage({
            id: I18nKey.USER_SETTINGS_BATHYMETRY,
          })}
        </LabelTextTypography>
        <Stack
          direction="column"
          justifyContent="space-evenly"
          alignItems="flex-start"
          spacing={0}
        >
          <FormControlLabel
            value="end"
            control={
              <Toggle
                checked={userSettings.map?.showBathymetryContour || false}
                name="map.showBathymetryContour"
                onChange={onToggleChange}
                value={userSettings.map?.showBathymetryContour}
              />
            }
            label={intl.formatMessage({
              id: I18nKey.USER_SETTINGS_BATHYMETRY_CONTOUR,
            })}
            labelPlacement="end"
            className={classes.toggleFormat}
          />
          <FormControlLabel
            value="end"
            control={
              <Toggle
                checked={userSettings.map?.showBathymetryLabel || false}
                name="map.showBathymetryLabel"
                onChange={onToggleChange}
                value={userSettings.map?.showBathymetryLabel}
              />
            }
            label={intl.formatMessage({
              id: I18nKey.USER_SETTINGS_BATHYMETRY_LABELS,
            })}
            labelPlacement="end"
            className={classes.toggleFormat}
          />
        </Stack>
      </Box>
      <hr className={classes.lineStyle} />
      <Box className={classes.settings}>
        <LabelTextTypography>
          {intl.formatMessage({
            id: I18nKey.USER_SETTINGS_LATITUDE_FORMAT,
          })}
        </LabelTextTypography>
        <RadioGroup
          aria-labelledby="latitude-radio-buttons-group"
          name="general.latLongFormat"
          value={userSettings.general?.latLongFormat || ''}
          onChange={onValueChange}
        >
          {Object.keys(settingsDefault.latitude).map((k) => {
            return (
              <FormControlLabel
                key={k}
                value={k}
                control={<RadioButton />}
                label={settingsDefault.latitude[k]}
              />
            );
          })}
        </RadioGroup>
      </Box>
      <hr className={classes.lineStyle} />
    </>
  );
};
