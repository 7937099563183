export const productSettingsStyles: any = {
  settings: {
    color: 'white',
    marginLeft: '31px',
    marginBottom: '15px',
  },
  lineStyle: {
    height: '1px',
    backgroundColor: '#242424',
    border: 'none',
  },
  units: {
    width: '85px',
  },
  categoryHeading: {
    fontWeight: '600 !important',
  },
  productToggle: {
    marginTop: '8px !important',
  },
  toggleFormat: {
    marginTop: '5px',
  },
};
