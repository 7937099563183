import { useState } from 'react';
import { Alert, Snackbar as SnackbarMUI } from '@mui/material';
import { SnackbarProps } from './SnackbarTypes';

const Snackbar: React.FC<SnackbarProps> = ({
  type = 'error',
  display,
  message,
}) => {
  const [showAlert, setShowALert] = useState(display);

  const onClose = () => {
    setShowALert(false);
  };

  return (
    <>
      <SnackbarMUI
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showAlert}
        onClose={onClose}
      >
        <Alert onClose={onClose} severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </SnackbarMUI>
    </>
  );
};

export default Snackbar;
