export const FooterStyles: any = {
  defaultStyle: {
    display: 'flex',
    textAlign: 'center',
    position: 'fixed',
    fontSize: '14px',
    height: '38px',
    paddingBottom: '0px',
    bottom: '0',
  },
  footer: {
    display: 'flex',
    //width: '300px',
  },
  centerElement: {
    // display: 'flex',
    // height:'inherit'
  }
};
