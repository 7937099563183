import { AxiosRequestConfig } from 'axios';
import {
  CachableUrlConfig,
  CACHABLE_URL_CONFIG,
  CacheEntry,
  max_cache_age,
  StorageType,
} from './cache-entry';
import { AxiosResponse } from 'axios';

export const CACHED_URLS_KEY = 'cachedUrls';
const errorCodes = [400, 404, 422, 500];

export const isRequestCachable = (
  req: AxiosRequestConfig<any>
): boolean | undefined => {
  if (req && req.url) {
    let urlWithParams: string = getUrlWithParams(req);
    const cachableUrl = Object.values(CACHABLE_URL_CONFIG).filter((el) => {
      return req.method === el.method && urlWithParams!.match(el.urlString!);
    });
    return cachableUrl.length > 0;
  }
};

export const isValidResponse = (response: AxiosResponse<any>) => {
  if (errorCodes.includes(response.status)) return false;
  return true;
};

export const getUrlConfig = (req: AxiosRequestConfig<any>) => {
  let urlConfig: CachableUrlConfig[];
  let urlWithParams: string = getUrlWithParams(req);
  urlConfig = Object.values(CACHABLE_URL_CONFIG).filter(
    (el: CachableUrlConfig) => {
      return req?.url && el.urlString && urlWithParams!.match(el.urlString!);
    }
  );
  return urlConfig && urlConfig.length > 0 ? urlConfig[0] : null;
};

export const shouldSendCachedResponse = (
  entryTime: number,
  entry_max_age: number = max_cache_age
): boolean => {
  const isCachedResponseExpired = Date.now() - entryTime > entry_max_age;
  return !isCachedResponseExpired;
};

const storeResponseInCacheStorage = async (url: string, entry: CacheEntry) => {
  if ('caches' in window) {
    try {
      const cache = await caches.open(CACHED_URLS_KEY);

      const response = new Response(JSON.stringify(entry));
      await cache.put(url, response);
    } catch (error) {
      console.log('saveToken error:', { error });
    }
  }
};

const getResponseFromCacheStorage = async (url: string) => {
  if ('caches' in window) {
    try {
      const cache = await caches.open(CACHED_URLS_KEY);
      const entry = await cache.match(url);

      if (!entry) {
        return null;
      }

      return await entry.json();
    } catch (error) {
      console.log('getToken error:', { error });
    }
  }
};

export const storeResponseInBrowserStorage = (
  url: string,
  entry: CacheEntry,
  storageType: StorageType = StorageType.sessionStorage
) => {
  if (storageType === StorageType.cacheStorage) {
    return storeResponseInCacheStorage(url, entry);
  }

  const storage =
    storageType === StorageType.localStorage ? localStorage : sessionStorage;
  const storedData = storage.getItem(CACHED_URLS_KEY);

  if (storedData) {
    const parsedData = JSON.parse(storedData);
    storage.setItem(
      CACHED_URLS_KEY,
      JSON.stringify({ ...parsedData, [url]: entry })
    );
  } else {
    storage.setItem(CACHED_URLS_KEY, JSON.stringify({ [url]: entry }));
  }
};

export const getResponseFromBrowserStorage = (
  url: string,
  storageType: StorageType = StorageType.sessionStorage
): CacheEntry | undefined => {
  if (storageType === StorageType.cacheStorage) {
    return getResponseFromCacheStorage(url) as unknown as CacheEntry;
  }

  const storage =
    storageType === StorageType.localStorage ? localStorage : sessionStorage;
  const storedData = storage.getItem(CACHED_URLS_KEY);

  if (storedData) {
    const parsedData = JSON.parse(storedData);
    return parsedData[url];
  }
};

export const getUrlKey = (urlWithParams: string): string => {
  let url: any;
  url = Object.values(CACHABLE_URL_CONFIG).filter((el) => {
    return (
      el.splitString && urlWithParams && urlWithParams!.match(el.urlString!)
    );
  });
  return url && url.length > 0
    ? urlWithParams.split(url[0].splitString)[0]
    : urlWithParams;
};

export const getUrlWithParams = (req: AxiosRequestConfig<any>): string => {
  let paramsStr: string = '';
  if (req && req.params) {
    paramsStr = Object.keys(req.params)
      .map(function (key) {
        return key + '=' + req.params[key];
      })
      .join('&');
    if (req.url?.indexOf('?') === -1) paramsStr = '?' + paramsStr;
  }
  return req.url + paramsStr;
};

export const deleteFromCacheStorage = async (url: string) => {
  try {
    const cache = await caches.open(CACHED_URLS_KEY);
    (await cache.keys()).forEach((e) => {
      if (e.url.includes(url)) {
        cache.delete(e);
      }
    });
  } catch (error) {
    console.log('getToken error:', { error });
  }
};
