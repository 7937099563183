import { Typography } from '@mui/material';
import classNames from 'classnames';
import { useStyles } from '../../utils/util';
import { contentText, labelText } from './TextFormatStyles';

export const LabelTextTypography: React.FC = ({ children }) => {
  const classes = useStyles(labelText, [])();

  return <Typography classes={{ root: classes.root }}>{children}</Typography>;
};

export const ContentTextTypography: React.FC<any> = ({
  children,
  textStyle,
}) => {
  const classes = useStyles(contentText, [])();

  return (
    <Typography classes={{ root: classNames([classes.root, textStyle]) }}>
      {children}
    </Typography>
  );
};
