import { FC, useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import { Add, Close, Delete } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import SearchIcon from '@mui/icons-material/Search';
import FishCatchCardDetails from '../FishCatchCardDetails';
import { useMutation } from 'react-query';
import { VariantType, closeSnackbar, useSnackbar } from 'notistack';
import FishCatchClient from '../../../../api/fishCatchAPIs';
import { useQueryClient, useQuery } from 'react-query';
import {
  useCardChangeContent,
  useChangeOnData,
  useFetchFishCatch,
} from '../../store/FishCatchStore';
import { isEqual } from 'lodash';
import FishCatchAlert from '../FishCatchAlert';
import { getUserAttributes } from '../../../../utils/auth';
import AddCardIcon from '@mui/icons-material/AddCard';
import _ from 'lodash';
interface updateRecord {
  userName: string;
  recordId: string;
  fishCatchData: any;
}

interface CD {
  id: string;
  uuid: string;
  name: string;
  quantity: string;
  Alive: boolean;
  Finned: boolean;
  time: string;
}

const CDContent: FC<any> = () => {
  const { userName, seastarId } = getUserAttributes();
  // --- Store ---
  const queryClient = useQueryClient();
  const dataGI = useQuery('GI', () => queryClient.getQueryData('GI'));
  const dataCDetails = useQuery('CD', () => queryClient.getQueryData('CD'));
  const changeCD = useChangeOnData((state) => state.changeCD);
  const setChangeCD = useChangeOnData((state) => state.setChangeCD);
  const changeCardReset = useCardChangeContent((state) => state.reFetchCard);
  const setResetCard = useFetchFishCatch((state) => state.setReFetchRecord);
  const setChangeCard = useCardChangeContent((state) => state.setRefetchCard);

  // --- Catch Datails Selection ---
  const [addSelection, setAddSelection] = useState(false);
  const [cardSelection, setCardSelection] = useState(false);
  const [isDeleteSelection, setIsDeleteSelection] = useState(false);
  const [searchSelection, setSearchSelection] = useState<string[]>([]);
  const [idSelected, setIDSelected] = useState<any>();
  const [data, setData] = useState<CD[]>();

  // --- Modal ---
  const [infoCard, setInfoCard] = useState<boolean>(true);
  const [warningCard, setWarningCard] = useState<boolean>(false);
  const [titleCard, setTitleCard] = useState<string>('');
  const [messageCard, setMessageCard] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    setData(dataCDetails.data as any);
  }, [dataCDetails.data as any]);

  function handleCloseModal() {
    if (addSelection) {
      setAddSelection(false);
      setSearchSelection([]);
      formik.resetForm();
    }
    if (cardSelection) {
      setCardSelection(false);
      setIsDeleteSelection(false);
      setInformationSelection([]);
      formikEdit.resetForm();
    }
    setOpenModal(false);
    setData(dataCDetails.data as any);
  }

  // --- Add & Edit the content of the cards ---
  function addCatchDetails(newData: CD) {
    setData([...(data as any), newData]);
  }

  function editCatchDetails(editedData: any, id: string) {
    let newData = [];
    for (let i = 0; i < (data as any).length; i++) {
      if ((data as any)[i].id === id) {
        newData.push({ ...(data as any)[i], ...editedData });
      } else newData.push((data as any)[i]);
    }
    setData(newData);
  }

  const deleteCatchDetails = (id: string) => {
    let newData = (data as any).filter((d: any) => d.id !== id);
    setData(newData);
  };

  // --- Search Filter Content ---
  function filterCatchDetails(
    data: any,
    fishName: string,
    quantity: string,
    property: string[]
  ) {
    setData(
      data.filter((obj: any) => {
        let fishNameMatch,
          quantityMatch,
          propertiesMatch = false;
        if (fishName.length > 0)
          fishNameMatch = obj.name
            .toLowerCase()
            .includes(fishName.toLowerCase());
        if (parseInt(quantity) >= 0)
          quantityMatch = parseInt(obj.quantity) === parseInt(quantity);
        if (property.length > 0)
          propertiesMatch = property.every(
            (prop) => obj.hasOwnProperty(prop) && obj[prop]
          );
        return fishNameMatch || quantityMatch || propertiesMatch;
      })
    );
  }

  // --- Toast Message ---
  const { enqueueSnackbar } = useSnackbar();

  // --- Call Toast ---
  const action = (snackbarId: any) => {
    return (
      <IconButton
        aria-label="close-toast"
        onClick={() => closeSnackbar(snackbarId)}
      >
        <CloseIcon />
      </IconButton>
    );
  };

  const callToast = (variant: VariantType, message: any) => {
    enqueueSnackbar(message, {
      variant,
      action,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
  };

  // --- React Query: PATCH and DELETE---
  const mutationPatch = useMutation({
    mutationFn: async ({ userName, recordId, fishCatchData }: updateRecord) => {
      const response = await FishCatchClient.updateFishCatchRecord(
        userName,
        recordId,
        fishCatchData
      );
      return response;
    },
  });

  // --- Save information API: PATCH ---
  const updateCatchDetails = async () => {
    const result = transformDataForUpdate();

    try {
      const mutationResult = await mutationPatch.mutateAsync({
        userName: userName,
        recordId: (dataGI.data as any)?.uuid,
        fishCatchData: result[0],
      });
      if (isDeleteSelection)
        callToast('success', 'Fish Species has been deleted!');
      else callToast('success', 'Catch Details has been updated!');
    } catch (error) {
      if (error instanceof Error) {
        callToast('error', error.message);
        console.error('Error Message: ', error.message);
      }
    }
    setResetCard();
  };

  // --- Search Information ---
  // --- Style of input checks ---
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300,
      },
    },
  };

  const searchInputs = ['Finned', 'Alive'];

  const handleChange = (event: SelectChangeEvent<typeof searchSelection>) => {
    const {
      target: { value },
    } = event;
    setSearchSelection(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  // --- ADD FUNCTIONALITY CD ---
  const initialValues = {
    fishName: '',
    quantity: '',
  };

  const validationSchemaCD = Yup.object().shape({
    fishName: Yup.string().required('Fish Name is required field.'),
    quantity: Yup.number()
      .typeError('Quality can only accept digits.')
      .required('Quality field is required.'),
  });

  // --- Initialize Formik ---
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaCD,
    onSubmit: (values) => {},
  });

  // --- Sets up the value for editing ---
  const [informationSelection, setInformationSelection] = useState<string[]>(
    []
  );

  const infoSelection = ['Finned', 'Alive'];

  const handleChangeInformation = (
    event: SelectChangeEvent<typeof searchSelection>
  ) => {
    const {
      target: { value },
    } = event;
    setInformationSelection(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  // --- Sets the search value on editing ---
  const [searchData, setSearchData] = useState<string[]>([]);

  const searchInfo = ['Finned', 'Alive'];

  const handleChangeSearchInfo = (
    event: SelectChangeEvent<typeof searchSelection>
  ) => {
    const {
      target: { value },
    } = event;
    setSearchData(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const initialValSearch = {
    fishName: '',
    quantity: '',
  };

  const validationSchemaCDSearch = Yup.object().shape({
    fishName: Yup.string(),
    quantity: Yup.number().typeError('Quality can only accept digits.'),
  });

  // --- Initialize Formik ---
  const formikSearch = useFormik({
    initialValues: initialValSearch,
    validationSchema: validationSchemaCDSearch,
    onSubmit: (values) => {},
  });

  // --- Checks if there is any property available ---
  function filterProperties(obj: any) {
    const result = [];
    if (obj.Finned) {
      result.push('Finned');
    }
    if (obj.Alive) {
      result.push('Alive');
    }

    return result;
  }

  const initialVal = {
    fishName: '',
    quantity: '',
  };

  const validationSchemaCDEdit = Yup.object().shape({
    fishName: Yup.string().required('Fish Name is required field.'),
    quantity: Yup.number()
      .typeError('Quality can only accept digits.')
      .required('Quality field is required.'),
  });

  // --- Initialize Formik ---
  const formikEdit = useFormik({
    initialValues: initialVal,
    validationSchema: validationSchemaCDEdit,
    onSubmit: (values) => {},
  });

  // --- Transform data for the Update Data ---
  function transformDataForUpdate() {
    const transformedData = [];
    const cd: any = {};

    data?.forEach((item: any, index: any) => {
      const catchDetail: any = {};

      for (const key in item) {
        if (item.hasOwnProperty(key) && key !== 'uuid' && key !== 'id') {
          catchDetail[key] = item[key];
        }
      }

      cd[index.toString()] = catchDetail;
    });

    transformedData.push({
      uuid: (dataCDetails.data as any)[0]?.uuid,
      catch_details: cd,
    });
    return transformedData;
  }

  useEffect(() => {
    if (!isEqual(initialValues, formik.values) !== changeCD) {
      if (!isEqual(dataCDetails.data as any, data)) {
        setChangeCD(true);
      } else {
        setChangeCD(false);
      }
    }
  });

  if (changeCardReset === true) {
    setChangeCard(false);
    setData(dataCDetails.data as any);
  }

  function handleOkay() {
    updateCatchDetails();
    setChangeCD(false);
    handleCloseModal();
  }

  return (
    <div>
      {openModal === true && (
        <FishCatchAlert
          info={infoCard}
          warning={warningCard}
          title={titleCard}
          message={messageCard}
          setOkay={handleOkay}
          setClose={handleCloseModal}
        />
      )}
      <Typography>
        Provides information on the catch details, which include the amount of
        fish, type of fish, and location where it was caught. This information,
        once edited make sure to save changes.
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          alignItems: 'end',
        }}
      >
        <Button
          variant="outlined"
          startIcon={<Add />}
          style={{
            borderRadius: '10px',
            background: '#193768',
            color: '#FFFFFF',
            borderColor: '#FFFFFF',
          }}
          onClick={() => {
            setAddSelection(true);
            setChangeCD(true);
          }}
        >
          Add
        </Button>
      </div>
      {addSelection === true && (
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div
              style={{
                background: '#193768',
                borderRadius: '5px',
                padding: '10px 10px 10px 10px',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ marginRight: '30px' }}>
                    <TextField
                      fullWidth
                      id="fishName"
                      name="fishName"
                      placeholder="Fish Name"
                      variant="outlined"
                      style={{
                        border: '1px solid #CDCDCD',
                        borderRadius: '5px',
                        margin: '2px 0px 2px 0px',
                        background: '#FFFFFF',
                      }}
                      value={formik.values.fishName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.fishName && formik.errors.fishName && (
                      <Typography variant="subtitle2" color="error">
                        {formik.errors.fishName}
                      </Typography>
                    )}
                  </div>
                  <div style={{ marginRight: '30px' }}>
                    <TextField
                      fullWidth
                      id="quantity"
                      name="quantity"
                      placeholder="Quantity"
                      variant="outlined"
                      style={{
                        border: '1px solid #CDCDCD',
                        borderRadius: '5px',
                        margin: '2px 0px 2px 0px',
                        background: '#FFFFFF',
                      }}
                      value={formik.values.quantity}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.quantity && formik.errors.quantity && (
                      <Typography variant="subtitle2" color="error">
                        {formik.errors.quantity}
                      </Typography>
                    )}
                  </div>
                  <div style={{ marginRight: '40px' }}>
                    <FormControl
                      sx={{
                        width: 300,
                        background: '#FFFFFF',
                        borderRadius: '5px',
                        color: '#000000',
                      }}
                    >
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={searchSelection}
                        onChange={handleChange}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected: string[]) =>
                          selected.length !== 0
                            ? selected.join(', ')
                            : 'Property'
                        }
                        MenuProps={MenuProps}
                        placeholder="Property"
                        style={{ border: '1px solid #CDCDCD' }}
                      >
                        {searchInputs.map((search) => (
                          <MenuItem key={search} value={search}>
                            <Checkbox
                              checked={searchSelection.indexOf(search) > -1}
                            />
                            <ListItemText primary={search} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>
                    <Tooltip title="Cancel">
                      <IconButton
                        style={{
                          background: '#D9E8F6',
                          padding: '15px',
                          color: '#D84242',
                          fontSize: '20px',
                        }}
                        onClick={() => {
                          setAddSelection(false);
                          setSearchSelection([]);
                          formik.resetForm();
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>
                    <Tooltip title="Save New Record">
                      <IconButton
                        style={{
                          background: '#D9E8F6',
                          padding: '15px',
                          color: '#193768',
                          fontSize: '20px',
                        }}
                        onClick={() => {
                          if (
                            !_.isEmpty(formik.values.fishName) &&
                            !_.isEmpty(formik.values.quantity)
                          ) {
                            setInfoCard(true);
                            setOpenModal(true);
                            setTitleCard('Add new details');
                            setMessageCard(
                              'Are you sure you want to add a new fish?'
                            );
                            setWarningCard(false);
                            addCatchDetails({
                              id: `${data?.length}`,
                              uuid: `${(dataGI.data as any)?.uuid}`,
                              name: formik.values.fishName,
                              quantity: formik.values.quantity,
                              Alive: searchSelection.includes(
                                'Alive'
                              ) as boolean,
                              Finned: searchSelection.includes(
                                'Finned'
                              ) as boolean,
                              time: (dataCDetails.data as any)[0].time,
                            });
                          } else {
                            callToast(
                              'error',
                              'Fish name and quantity are required!'
                            );
                          }
                        }}
                      >
                        <AddCardIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {cardSelection === true && (
        <div
          style={{
            marginTop: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div
              style={{
                background: '#D9E8F6',
                borderRadius: '5px',
                padding: '10px 10px 10px 10px',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ marginRight: '30px' }}>
                    <TextField
                      fullWidth
                      id="fishName"
                      name="fishName"
                      placeholder="Fish Name"
                      variant="outlined"
                      style={{
                        border: '1px solid #CDCDCD',
                        borderRadius: '5px',
                        margin: '2px 0px 2px 0px',
                        background: '#FFFFFF',
                      }}
                      value={formikEdit.values.fishName}
                      onChange={formikEdit.handleChange}
                      onBlur={formikEdit.handleBlur}
                    />
                    {formikEdit.touched.fishName && formikEdit.errors.fishName && (
                      <Typography variant="subtitle2" color="error">
                        {formikEdit.errors.fishName}
                      </Typography>
                    )}
                  </div>
                  <div style={{ marginRight: '30px' }}>
                    <TextField
                      fullWidth
                      id="quantity"
                      name="quantity"
                      placeholder="Quantity"
                      variant="outlined"
                      style={{
                        border: '1px solid #CDCDCD',
                        borderRadius: '5px',
                        margin: '2px 0px 2px 0px',
                        background: '#FFFFFF',
                      }}
                      value={formikEdit.values.quantity}
                      onChange={formikEdit.handleChange}
                      onBlur={formikEdit.handleBlur}
                    />
                    {formikEdit.touched.quantity && formikEdit.errors.quantity && (
                      <Typography variant="subtitle2" color="error">
                        {formikEdit.errors.quantity}
                      </Typography>
                    )}
                  </div>
                  <div style={{ marginRight: '40px' }}>
                    <FormControl
                      sx={{
                        width: 300,
                        background: '#FFFFFF',
                        borderRadius: '5px',
                        color: '#000000',
                      }}
                    >
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={informationSelection}
                        onChange={handleChangeInformation}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) =>
                          selected.length !== 0
                            ? selected.join(', ')
                            : 'Property'
                        }
                        MenuProps={MenuProps}
                        placeholder="Property"
                        style={{ border: '1px solid #CDCDCD' }}
                      >
                        {infoSelection.map((search) => (
                          <MenuItem key={search} value={search}>
                            <Checkbox
                              checked={
                                informationSelection.indexOf(search) > -1
                              }
                            />
                            <ListItemText primary={search} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>
                    <Tooltip title="Cancel">
                      <IconButton
                        style={{
                          background: '#193768',
                          padding: '15px',
                          color: '#193768',
                          fontSize: '20px',
                        }}
                        onClick={() => {
                          setInformationSelection([]);
                          formikEdit.resetForm();
                          setCardSelection(false);
                        }}
                      >
                        <Close style={{ color: '#D9E8F6' }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>
                    <Tooltip title="Update record">
                      <IconButton
                        style={{
                          background: '#193768',
                          padding: '15px',
                          color: '#193768',
                          fontSize: '20px',
                        }}
                        onClick={() => {
                          if (
                            !_.isEmpty(formikEdit.values.fishName) &&
                            !_.isEmpty(formikEdit.values.quantity)
                          ) {
                            setInfoCard(true);
                            setOpenModal(true);
                            setTitleCard('Update Details');
                            setMessageCard(
                              'Are you sure you want to update fish details?'
                            );
                            setWarningCard(false);
                            editCatchDetails(
                              {
                                name: formikEdit.values.fishName,
                                quantity: formikEdit.values.quantity,
                                Alive: informationSelection.includes(
                                  'Alive'
                                ) as boolean,
                                Finned: informationSelection.includes(
                                  'Finned'
                                ) as boolean,
                              },
                              idSelected
                            );
                          } else {
                            callToast(
                              'error',
                              'Fish name and quantity are required!'
                            );
                          }
                        }}
                      >
                        <SaveIcon style={{ color: '#D9E8F6' }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginRight: '10px' }}>
                    <Tooltip title="Delete Record">
                      <IconButton
                        style={{
                          background: '#193768',
                          padding: '15px',
                          color: '#193768',
                          fontSize: '20px',
                        }}
                        onClick={() => {
                          setIsDeleteSelection(true);
                          setWarningCard(true);

                          setOpenModal(true);
                          setTitleCard('Delete Species');
                          setMessageCard(
                            'Are you sure you want to delete fish species?'
                          );

                          deleteCatchDetails(idSelected);
                        }}
                      >
                        <Delete style={{ color: '#D9E8F6' }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          marginTop: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div
          style={{
            background: '#193768',
            borderRadius: '5px',
            padding: '10px 10px 10px 10px',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div style={{ marginRight: '30px' }}>
                <TextField
                  fullWidth
                  id="fishName"
                  name="fishName"
                  placeholder="Fish Name"
                  variant="outlined"
                  style={{
                    border: '1px solid #CDCDCD',
                    borderRadius: '5px',
                    margin: '2px 0px 2px 0px',
                    background: '#FFFFFF',
                  }}
                  value={formikSearch.values.fishName}
                  onChange={formikSearch.handleChange}
                  onBlur={formikSearch.handleBlur}
                />
                {formikSearch.touched.fishName && formikSearch.errors.fishName && (
                  <Typography variant="subtitle2" color="error">
                    {formikSearch.errors.fishName}
                  </Typography>
                )}
              </div>
              <div style={{ marginRight: '30px' }}>
                <TextField
                  fullWidth
                  id="quantity"
                  name="quantity"
                  placeholder="Quantity"
                  variant="outlined"
                  style={{
                    border: '1px solid #CDCDCD',
                    borderRadius: '5px',
                    margin: '2px 0px 2px 0px',
                    background: '#FFFFFF',
                  }}
                  value={formikSearch.values.quantity}
                  onChange={formikSearch.handleChange}
                  onBlur={formikSearch.handleBlur}
                />
                {formikSearch.touched.quantity && formikSearch.errors.quantity && (
                  <Typography variant="subtitle2" color="error">
                    {formikSearch.errors.quantity}
                  </Typography>
                )}
              </div>
              <div style={{ marginRight: '40px' }}>
                <FormControl
                  sx={{
                    width: 300,
                    background: '#FFFFFF',
                    borderRadius: '5px',
                    color: '#000000',
                  }}
                >
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={searchData}
                    onChange={handleChangeSearchInfo}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) =>
                      selected.length !== 0 ? selected.join(', ') : 'Property'
                    }
                    MenuProps={MenuProps}
                    placeholder="Property"
                    style={{ border: '1px solid #CDCDCD' }}
                  >
                    {searchInfo.map((search) => (
                      <MenuItem key={search} value={search}>
                        <Checkbox checked={searchData.indexOf(search) > -1} />
                        <ListItemText primary={search} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ marginRight: '10px' }}>
                <Tooltip title="Clear Search">
                  <IconButton
                    style={{ background: '#FFEBC8', padding: '15px' }}
                    onClick={() => {
                      setSearchData([]);
                      formikSearch.resetForm();
                      setData(dataCDetails.data as any);
                    }}
                  >
                    <CleaningServicesIcon style={{ color: '#FFA500' }} />
                  </IconButton>
                </Tooltip>
              </div>
              <div style={{ marginRight: '10px' }}>
                <Tooltip title="Search">
                  <IconButton
                    style={{ background: '#D9E8F6', padding: '15px' }}
                    onClick={() => {
                      filterCatchDetails(
                        dataCDetails.data as any,
                        formikSearch.values.fishName,
                        formikSearch.values.quantity,
                        searchData
                      );
                    }}
                  >
                    <SearchIcon style={{ color: '#193768' }} />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Grid container spacing={3}>
        {data?.map((fishData: any, index: any) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <div
              onClick={() => {
                setInformationSelection([...filterProperties(fishData)]);
                formikEdit.setFieldValue('fishName', fishData.name);
                formikEdit.setFieldValue('quantity', fishData.quantity);
                setIDSelected(fishData.id);
                setCardSelection(true);
                setChangeCD(true);
              }}
            >
              <FishCatchCardDetails
                quantity={fishData.quantity}
                fishName={fishData.name}
                property={filterProperties(fishData)}
              />
            </div>
          </Grid>
        ))}
      </Grid>
      {/* <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          alignItems: 'end',
        }}
      >
        <Button
          variant="outlined"
          style={{
            borderRadius: '10px',
            background: '#D9E8F6',
            color: '#193768',
            borderColor: '#193768',
            borderWidth: '2px',
            marginRight: '15px',
          }}
          onClick={() => {
            setData(dataCDetails.data as any);
            setChangeCD(true);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          startIcon={<SaveIcon />}
          style={{
            borderRadius: '10px',
            background: '#75DF73',
            color: '#FFFFFF',
            borderColor: '#75DF73',
            borderWidth: '2px',
          }}
          onClick={() => {
            setInfoCard(true);
            setOpenModal(true);
            setTitleCard('Save Changes');
            setMessageCard('Want to save the changes of Catch Details?');
            setWarningCard(false);
          }}
        >
          Save
        </Button>
      </div> */}
    </div>
  );
};

export default CDContent;
