import { useEffect, useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { progressSpinnerStyles } from './ProgressSpinnerStyles';

export const ProgressSpinner: React.FC<any> = (showSpinner: boolean) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(showSpinner);
  }, [showSpinner]);

  return (
    <Backdrop sx={progressSpinnerStyles.spinner} open={Boolean(open)}>
      <CircularProgress color="inherit" thickness={5} size={100} />
    </Backdrop>
  );
};
