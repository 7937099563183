import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {
  Box,
  Stack,
  Typography,
  TextField,
  CircularProgress,
} from '@mui/material';

import { useContextSelector } from 'use-context-selector';
import { PublicHome } from './PublicHome';
import { InfoTooltip } from '../../components/InfoTooltip/InfoTooltip';
import { useStyles } from '../../utils/util';
import { I18nKey } from '../../translations/I18nKey';
import { AlertContext } from '../Contexts/AuthContext';
import { SignUpFormStyles } from './PublicHomeStyles';
import './PublicHome.scss';
import _ from 'lodash';

const SignUpForm: React.FC = () => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [seastarId, setSeastarId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [alertProps, setAlertProps] = useContextSelector(
    AlertContext,
    (state) => state
  );
  const classes = useStyles(SignUpFormStyles, [])();
  const navigate = useNavigate();
  const intl = useIntl();

  const isFormValid = () => {
    let validationMsg = '';
    setAlertProps({
      display: false,
    });
    let pwdValidation = validatePassword(password, intl);
    if (pwdValidation.length) {
      validationMsg = validationMsg.concat(
        `${intl.formatMessage({
          id: I18nKey.PASSWORD_ERROR_MSG,
        })} ${pwdValidation.join(', ')}. `
      );
    }
    let emailValidation = validateEmail(email);
    if (!emailValidation) {
      validationMsg = validationMsg.concat(
        `${intl.formatMessage({
          id: I18nKey.EMAIL_INVALID,
        })}`
      );
    }
    let isFormInvalid = _.isEmpty(userName) || _.isEmpty(seastarId);
    if (isFormInvalid) {
      validationMsg = validationMsg.concat(
        intl.formatMessage({
          id: I18nKey.INVALID_FIELDS_MSG,
        })
      );
    }
    if (validationMsg.length > 0) {
      setAlertProps({
        display: true,
        message: validationMsg,
        type: 'error',
      });
      setTimeout(() => {
        setAlertProps({
          type: '',
          display: false,
          message: '',
        });
      }, 7000);
    }
    return validationMsg.length === 0;
  };

  const onSignUp = () => {
    if (isFormValid()) {
      setIsLoading(true);
      Auth.signUp({
        username: userName,
        password: password,
        attributes: {
          email: email,
          'custom:orbnet_id': seastarId,
        },
      })
        .then(() => {
          setTimeout(() => {
            setAlertProps({
              type: '',
              display: false,
              message: '',
            });
          }, 4000);
          setIsLoading(false);
          navigate('/confirm-sign-up', { state: { userName } });
        })
        .catch((err) => {
          setIsLoading(false);
          setAlertProps({
            display: true,
            message: `${intl.formatMessage({
              id: I18nKey.SIGNUP_ERROR_MSG,
            })}. ${err.message}`,
            type: 'error',
          });
          setTimeout(() => {
            setAlertProps({
              type: '',
              display: false,
              message: '',
            });
          }, 4000);
        });
    }
  };

  return (
    <>
      <Typography variant={'h4'} className={classes.signInSubTitle}>
        {intl.formatMessage({
          id: I18nKey.CREATE_ACCOUNT,
        })}
      </Typography>
      <Box sx={{ height: '68%' }}>
        <Stack
          height={'100%'}
          margin={'8% 8% 0%'}
          className={classes.stackContainer}
          spacing={1.5}
        >
          <Box className={classes.label}>
            <Typography className={classes.label}>
              {intl.formatMessage({
                id: I18nKey.USERNAME,
              })}
              *<InfoTooltip title={<UsernameTooltipInfo />}></InfoTooltip>
            </Typography>
          </Box>

          <Box sx={{ height: '10%' }}>
            <TextField
              fullWidth
              sx={SignUpFormStyles.noOutline}
              InputProps={{
                classes: {
                  input: classes.resizeSignUpTextInput,
                },
              }}
              className={classes.signUpInput}
              name="username"
              value={userName}
              onChange={(u) => setUserName(u.target.value)}
            />
          </Box>

          <Box>
            <Typography className={classes.label}>
              {intl.formatMessage({
                id: I18nKey.PASSWORD,
              })}
              *<InfoTooltip title={<PasswordTooltipInfo />}></InfoTooltip>
            </Typography>
          </Box>

          <Box sx={{ height: '10%' }}>
            <TextField
              fullWidth
              sx={SignUpFormStyles.noOutline}
              InputProps={{
                classes: {
                  input: classes.resizeSignUpTextInput,
                },
              }}
              className={classes.signUpInput}
              name="password"
              type="password"
              value={password}
              onChange={(u) => setPassword(u.target.value)}
            />
          </Box>
          <Box>
            <Typography className={classes.label}>
              {intl.formatMessage({
                id: I18nKey.EMAIL_ADDRESS,
              })}
              *
            </Typography>
          </Box>

          <Box sx={{ height: '10%' }}>
            <TextField
              fullWidth
              sx={SignUpFormStyles.noOutline}
              InputProps={{
                classes: {
                  input: classes.resizeSignUpTextInput,
                },
              }}
              className={classes.signUpInput}
              name="email"
              type="email"
              value={email}
              onChange={(u) => setEmail(u.target.value)}
            />
          </Box>
          <Box>
            <Typography className={classes.label}>
              {intl.formatMessage({
                id: I18nKey.SEASTAR_ID,
              })}
              *
            </Typography>
          </Box>

          <Box sx={{ height: '10%' }}>
            <TextField
              fullWidth
              sx={SignUpFormStyles.noOutline}
              InputProps={{
                classes: {
                  input: classes.resizeSignUpTextInput,
                },
              }}
              className={classes.signUpInput}
              name="seastarId"
              type="seastarId"
              value={seastarId}
              onChange={(u) => setSeastarId(u.target.value)}
            />
          </Box>

          <Box sx={{ height: '13%' }}>
            <button className={classes.signUpBtn} onClick={onSignUp}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                intl
                  .formatMessage({
                    id: I18nKey.CREATE_ACCOUNT,
                  })
                  .toLocaleUpperCase()
              )}
            </button>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export const SignUp: React.FC = () => {
  return (
    <PublicHome>
      <SignUpForm />
    </PublicHome>
  );
};

const UsernameTooltipInfo: React.FC = () => {
  const intl = useIntl();
  return (
    <>
      {intl.formatMessage({
        id: I18nKey.USERNAME_INFO,
      })}
    </>
  );
};

export const PasswordTooltipInfo: React.FC = () => {
  const intl = useIntl();
  return (
    <>
      <Typography sx={{ py: 0, fontSize: '12px' }}>
        {intl.formatMessage({
          id: I18nKey.PASSWORD_INFO,
        })}
      </Typography>
      <li>
        {intl.formatMessage({
          id: I18nKey.PASSWORD_CHAR_INFO,
        })}
      </li>
      <li>
        {intl.formatMessage({
          id: I18nKey.PASSWORD_UPPER_CASE_INFO,
        })}
      </li>
      <li>
        {intl.formatMessage({
          id: I18nKey.PASSWORD_LOWER_CASE_INFO,
        })}
      </li>
      <li>
        {intl.formatMessage({
          id: I18nKey.PASSWORD_NUMBER_INFO,
        })}
      </li>
      <li>
        {intl.formatMessage({
          id: I18nKey.PASSWORD_SPECIAL_CHAR_INFO,
        })}
      </li>
    </>
  );
};

export const validatePassword = (password: string, intl: any) => {
  let errors = [];
  if (password.length < 8) {
    errors.push(
      intl.formatMessage({
        id: I18nKey.PASSWORD_CHAR_LENGTH,
      })
    );
  }
  if (password.search(/[a-z]/) < 0) {
    errors.push(
      intl.formatMessage({
        id: I18nKey.PASSWORD_LOWERCASE,
      })
    );
  }
  if (password.search(/[A-Z]/) < 0) {
    errors.push(
      intl.formatMessage({
        id: I18nKey.PASSWORD_UPPERCASE,
      })
    );
  }
  if (password.search(/[0-9]/) < 0) {
    errors.push(
      intl.formatMessage({
        id: I18nKey.PASSWORD_DIGIT,
      })
    );
  }
  if (password.search(/[~`!@#$%^&*()--+={}\\[\]|\\:;"'<>,.?/_₹]/) < 0) {
    errors.push(
      intl.formatMessage({
        id: I18nKey.PASSWORD_SPECIAL_SYMBOL,
      })
    );
  }
  return errors;
};

export const validateEmail = (email: string) => {
  if (email.search(/^\S+@\S+\.\S+$/) < 0) {
    return false;
  }
  return true;
};
