import React, { useEffect } from 'react';
import { Slider, Card, Box } from '@mui/material';
import { DurationSliderStyles } from '../SliderStyles';
import { DurationSliderProps } from '../SliderTypes';

const DurationSlider: React.FC<DurationSliderProps> = ({
  marks,
  onChange,
  value,
}) => {
  useEffect(() => {
    return () => {
      sessionStorage.setItem('time', '00');
    };
  }, []);

  return (
    <Card sx={DurationSliderStyles.cardStyles}>
      <Box sx={DurationSliderStyles.sliderBoxStyles}>
        <Slider
          min={marks[0].value}
          max={marks[1].value}
          marks={marks}
          value={value}
          step={marks[1].value - marks[0].value}
          sx={DurationSliderStyles.sliderStyles}
          onChange={onChange}
        />
      </Box>
    </Card>
  );
};

export default DurationSlider;
