import axios, { AxiosRequestConfig } from 'axios';
import { MasterCastRules, Geojson } from '../containers/types';

const SERVER_BASE_PATH = process.env.REACT_APP_PRODUCT_SERVER;

export const PATHS = Object.freeze({
  GENERATE_MASTERCAST: `mastercast/generate`,
  MASTERCAST_SETTINGS: `mastercast/settings`,
});

const generateMasterCast = async (
  date: string,
  region: string
): Promise<Geojson> => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: {
        date: date,
        region: region,
      },
    };
    const { data: masterCastGeojson } = await axios.get<Geojson>(
      SERVER_BASE_PATH + PATHS.GENERATE_MASTERCAST,
      config
    );

    return masterCastGeojson;
  } catch (error) {
    throw error;
  }
};

const getMastercastDefaults = async () => {
  try {
    const { data: MastercastData } = await axios.get<any>(
      SERVER_BASE_PATH + PATHS.MASTERCAST_SETTINGS
    );
    return MastercastData;
  } catch (error) {
    throw error;
  }
};

const updateMastercastSettings = async (mastercastRule: MasterCastRules) => {
  try {
    const { data: updatedData } = await axios.patch(
      SERVER_BASE_PATH + PATHS.MASTERCAST_SETTINGS,
      mastercastRule
    );
    return updatedData;
  } catch (error) {
    throw error;
  }
};

export interface MasterCastAPIClient {
  readonly generateMasterCast: (
    date: string,
    region: string
  ) => Promise<Geojson>;
  readonly getMastercastDefaults: () => Promise<any>;
  readonly updateMastercastSettings: (
    mastercastRule: MasterCastRules
  ) => Promise<any>;
}

const MasterCastClient: MasterCastAPIClient = Object.freeze({
  generateMasterCast,
  getMastercastDefaults,
  updateMastercastSettings,
});

export default MasterCastClient;
