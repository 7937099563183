import { FC, useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';
import { useMutation } from 'react-query';
import { VariantType, closeSnackbar, useSnackbar } from 'notistack';
import FishCatchClient from '../../../../api/fishCatchAPIs';
import { useQueryClient, useQuery } from 'react-query';
import {
  useCardChangeContent,
  useChangeOnData,
  useFetchFishCatch,
} from '../../store/FishCatchStore';
import { isEqual } from 'lodash';
import FishCatchAlert from '../FishCatchAlert';
import { getUserAttributes } from '../../../../utils/auth';
import { I18nKey } from '../../../../translations/I18nKey';
import { useIntl } from 'react-intl';

interface updateRecord {
  userName: string;
  recordId: string;
  fishCatchData: any;
}

const CCContent: FC<any> = () => {
  const intl = useIntl();
  const { userName, seastarId } = getUserAttributes();
  // --- Store ---
  const queryClient = useQueryClient();
  const dataGI = useQuery('GI', () => queryClient.getQueryData('GI'));
  const dataCC = useQuery('CC', () => queryClient.getQueryData('CC'));
  const changeCC = useChangeOnData((state) => state.changeCC);
  const setChangeCC = useChangeOnData((state) => state.setChangeCC);
  const changeCardReset = useCardChangeContent((state) => state.reFetchCard);
  const setResetCard = useFetchFishCatch((state) => state.setReFetchRecord);
  const setChangeCard = useCardChangeContent((state) => state.setRefetchCard);

  // --- Modal ---
  const [infoCard, setInfoCard] = useState<boolean>(true);
  const [warningCard, setWarningCard] = useState<boolean>(false);
  const [titleCard, setTitleCard] = useState<string>('');
  const [messageCard, setMessageCard] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);

  function handleCloseModal() {
    setOpenModal(false);
  }

  // --- Toast Message ---
  const { enqueueSnackbar } = useSnackbar();

  // --- Call Toast ---
  const action = (snackbarId: any) => {
    return (
      <IconButton
        aria-label="close-toast"
        onClick={() => closeSnackbar(snackbarId)}
      >
        <CloseIcon />
      </IconButton>
    );
  };

  const callToast = (variant: VariantType, message: any) => {
    enqueueSnackbar(message, {
      variant,
      action,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
  };

  // --- React Query: PATCH ---
  const mutationPatch = useMutation({
    mutationFn: async ({ userName, recordId, fishCatchData }: updateRecord) => {
      const response = await FishCatchClient.updateFishCatchRecord(
        userName,
        recordId,
        fishCatchData
      );
      return response;
    },
  });

  // --- Save information API: PATCH ---
  const updateCatchCondition = async () => {
    const result = {
      uuid: (dataGI.data as any)?.uuid,
      catch_conditions: {
        plankton: formik.values.plankton,
        temp_c: formik.values.temp_c,
        temperature_25m: formik.values.temperature_25m,
        temperature_50m: formik.values.temperature_50m,
        temperature_100m: formik.values.temperature_100m,
        temperature_150m: formik.values.temperature_150m,
        temperature_200m: formik.values.temperature_200m,
        temperature_300m: formik.values.temperature_300m,
        current_speed: formik.values.current_speed,
        current_direction: formik.values.current_direction,
        ssh: formik.values.ssh,
        thermocliene_depth: formik.values.thermocliene_depth,
        bathymetry: formik.values.bathymetry,
        salinity: formik.values.salinity,
        pressure: formik.values.pressure,
        wind_speed: formik.values.wind_speed,
        wind_direction: formik.values.wind_direction,
        wave_height: formik.values.wave_height,
      },
    };

    try {
      const mutationResult = await mutationPatch.mutateAsync({
        userName: userName,
        recordId: (dataGI.data as any)?.uuid,
        fishCatchData: result,
      });

      console.log('Mutation Data: ', mutationResult);
    } catch (error) {
      if (error instanceof Error) {
        callToast('error', error.message);
        console.log('Error Message: ', error.message);
        return;
      }
    }

    callToast('success', 'Catch Conditions has been updated!');
    setResetCard();
  };

  const initialValues = {
    plankton: (dataCC.data as any)?.plankton,
    temp_c: (dataCC.data as any)?.temp_c,
    temperature_25m: (dataCC.data as any)?.temperature_25m,
    temperature_50m: (dataCC.data as any)?.temperature_50m,
    temperature_100m: (dataCC.data as any)?.temperature_100m,
    temperature_150m: (dataCC.data as any)?.temperature_150m,
    temperature_200m: (dataCC.data as any)?.temperature_200m,
    temperature_300m: (dataCC.data as any)?.temperature_300m,
    current_speed: (dataCC.data as any)?.current_speed,
    current_direction: (dataCC.data as any)?.current_direction,
    ssh: (dataCC.data as any)?.ssh,
    thermocliene_depth: (dataCC.data as any)?.thermocliene_depth,
    bathymetry: (dataCC.data as any)?.bathymetry,
    salinity: (dataCC.data as any)?.salinity,
    pressure: (dataCC.data as any)?.pressure,
    wind_speed: (dataCC.data as any)?.wind_speed,
    wind_direction: (dataCC.data as any)?.wind_direction,
    wave_height: (dataCC.data as any)?.wave_height,
  };

  // --- Validation of the inputs of search
  const validationSchemaCC = Yup.object({
    plankton: Yup.number().typeError('Plankton only accepts numbers.'),
    temp_c: Yup.number().typeError('Temperature C only accepts numbers.'),
    temperature_25m: Yup.number().typeError(
      'Temperature 25m only accepts numbers.'
    ),
    temperature_50m: Yup.number().typeError(
      'Temperature 50m only accepts numbers.'
    ),
    temperature_100m: Yup.number().typeError(
      'Temperature 100m only accepts numbers.'
    ),
    temperature_150m: Yup.number().typeError(
      'Temperature 150m only accepts numbers.'
    ),
    temperature_200m: Yup.number().typeError(
      'Temperature 200m only accepts numbers.'
    ),
    temperature_300m: Yup.number().typeError(
      'Temperature 300m only accepts numbers.'
    ),
    current_speed: Yup.number().typeError(
      'Current Speed only accepts numbers.'
    ),
    current_direction: Yup.string().typeError(
      'Current Directions only accepts numbers.'
    ),
    ssh: Yup.number().typeError('SSH only accepts numbers.'),
    thermocliene_depth: Yup.number().typeError(
      'Thermocliene Depth only accepts numbers.'
    ),
    bathymetry: Yup.number().typeError('Bathymetry only accepts numbers.'),
    salinity: Yup.number().typeError('Salinity only accepts numbers.'),
    pressure: Yup.number().typeError('Pressure only accepts numbers.'),
    wind_speed: Yup.number().typeError('Wind Speed only accepts numbers.'),
    wind_direction: Yup.number().typeError(
      'Wind Directions only accepts numbers.'
    ),
    wave_height: Yup.number().typeError('Wave Height only accepts numbers.'),
  });

  // --- Initialize Formik ---
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaCC,
    onSubmit: (values) => {
      // Handle form submission
      console.log('VALUES SUBMIT SEARCH: ', values);
    },
  });

  useEffect(() => {
    if (!isEqual(initialValues, formik.values) !== changeCC) {
      if (!isEqual(initialValues, formik.values)) {
        setChangeCC(true);
      } else {
        setChangeCC(false);
      }
    }
  });

  if (changeCardReset === true) {
    setChangeCard(false);
    formik.resetForm();
  }

  function handleOkay() {
    updateCatchCondition();
    setChangeCC(false);
    handleCloseModal();
  }

  return (
    <div>
      {openModal === true && (
        <FishCatchAlert
          info={infoCard}
          warning={warningCard}
          title={titleCard}
          message={messageCard}
          setOkay={handleOkay}
          setClose={handleCloseModal}
        />
      )}
      <Typography>
        Provides information on the catch conditions such as temperatures and
        others. This information, once edited make sure to save changes.
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_PLANKTON,
            })}
          </Typography>
          <TextField
            fullWidth
            id="plankton"
            name="plankton"
            placeholder="Plankton"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            value={formik.values.plankton}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.plankton && formik.errors.plankton && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.plankton}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_TEMP_C,
            })}
          </Typography>
          <TextField
            fullWidth
            id="temp_c"
            name="temp_c"
            placeholder="Temperature C"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            value={formik.values.temp_c}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.temp_c && formik.errors.temp_c && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.temp_c}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_TEMP_25,
            })}
          </Typography>
          <TextField
            fullWidth
            id="temperature_25m"
            placeholder="Temperature 25m"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            value={formik.values.temperature_25m}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.temperature_25m && formik.errors.temperature_25m && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.temperature_25m}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_TEMP_50,
            })}
          </Typography>
          <TextField
            fullWidth
            id="temperature_50m"
            name="temperature_50m"
            placeholder="Temperature 50m"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            type="number"
            value={formik.values.temperature_50m}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.temperature_50m && formik.errors.temperature_50m && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.temperature_50m}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_TEMP_100,
            })}
          </Typography>
          <TextField
            fullWidth
            id="temperature_100m"
            name="temperature_100m"
            placeholder="Temperature 100m"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            type="number"
            value={formik.values.temperature_100m}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.temperature_100m && formik.errors.temperature_100m && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.temperature_100m}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_TEMP_200,
            })}
          </Typography>
          <TextField
            fullWidth
            id="temperature_200m"
            name="temperature_200m"
            placeholder="Temperature 200m"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            type="number"
            value={formik.values.temperature_200m}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.temperature_200m && formik.errors.temperature_200m && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.temperature_200m}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_TEMP_300,
            })}
          </Typography>
          <TextField
            fullWidth
            id="temperature_300m"
            name="temperature_300m"
            placeholder="Temperature 300m"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            type="number"
            value={formik.values.temperature_300m}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.temperature_300m && formik.errors.temperature_300m && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.temperature_300m}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_CURRENT_SPEED,
            })}
          </Typography>
          <TextField
            fullWidth
            id="current_speed"
            name="current_speed"
            placeholder="Current Speed"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            type="number"
            value={formik.values.current_speed}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.current_speed && formik.errors.current_speed && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.current_speed}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_CURRENT_DIRECTION,
            })}
          </Typography>
          <TextField
            fullWidth
            id="current_direction"
            name="current_direction"
            placeholder="Current Direction"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            type="number"
            value={formik.values.current_direction}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.current_direction && formik.errors.current_direction && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.current_direction}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_SSHA,
            })}
          </Typography>
          <TextField
            fullWidth
            id="ssh"
            name="ssh"
            placeholder="SSH"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            type="number"
            value={formik.values.ssh}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.ssh && formik.errors.ssh && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.ssh}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_THERMOCLIENE_DEPTH,
            })}
          </Typography>
          <TextField
            fullWidth
            id="thermocliene_depth"
            name="thermocliene_depth"
            placeholder="Thermocliene Depth"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            type="number"
            value={formik.values.thermocliene_depth}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.thermocliene_depth &&
            formik.errors.thermocliene_depth && (
              <Typography variant="subtitle2" color="error">
                {formik.errors.thermocliene_depth}
              </Typography>
            )}
        </Box>
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_BATHYMETRY,
            })}
          </Typography>
          <TextField
            fullWidth
            id="bathymetry"
            name="bathymetry"
            placeholder="Bathymetry"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            type="number"
            value={formik.values.bathymetry}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.bathymetry && formik.errors.bathymetry && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.bathymetry}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_SALINITY,
            })}
          </Typography>
          <TextField
            fullWidth
            id="salinity"
            name="salinity"
            placeholder="Salinity"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            value={formik.values.salinity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.salinity && formik.errors.salinity && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.salinity}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_PRESSURE,
            })}
          </Typography>
          <TextField
            fullWidth
            id="pressure"
            name="pressure"
            placeholder="Pressure"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            type="number"
            value={formik.values.pressure}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.pressure && formik.errors.pressure && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.pressure}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_WIND_SPEED,
            })}
          </Typography>
          <TextField
            fullWidth
            id="wind_speed"
            name="wind_speed"
            placeholder="Wind Speed"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            value={formik.values.wind_speed}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.wind_speed && formik.errors.wind_speed && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.wind_speed}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_WIND_DIRECTION,
            })}
          </Typography>
          <TextField
            fullWidth
            id="wind_direction"
            name="wind_direction"
            placeholder="Wind Direction"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            type="number"
            value={formik.values.wind_direction}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.wind_direction && formik.errors.wind_direction && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.wind_direction}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_WAVE_HEIGHT,
            })}
          </Typography>
          <TextField
            fullWidth
            id="wave_height"
            name="wave_height"
            placeholder="Wave Height"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            type="number"
            value={formik.values.wave_height}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.wave_height && formik.errors.wave_height && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.wave_height}
            </Typography>
          )}
        </Box>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          alignItems: 'end',
        }}
      >
        <Button
          variant="outlined"
          style={{
            borderRadius: '10px',
            background: '#D9E8F6',
            color: '#193768',
            borderColor: '#193768',
            borderWidth: '2px',
            marginRight: '15px',
          }}
          onClick={() => {
            formik.resetForm();
            setChangeCC(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          startIcon={<SaveIcon />}
          style={{
            borderRadius: '10px',
            background: '#75DF73',
            color: '#FFFFFF',
            borderColor: '#75DF73',
            borderWidth: '2px',
          }}
          onClick={() => {
            setInfoCard(true);
            setOpenModal(true);
            setTitleCard('Save Changes');
            setMessageCard('Want to save the changes of Catch Conditions?');
            setWarningCard(false);
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default CCContent;
