import { FC, useEffect } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TagIcon from '@mui/icons-material/Tag';
import PhishingIcon from '@mui/icons-material/Phishing';
import moment from 'moment';
import ExploreIcon from '@mui/icons-material/Explore';
import { useQuery, useQueryClient } from 'react-query';
import { useFetchFishCatch } from '../store/FishCatchStore';
import { isEmpty } from 'lodash';
import CommentIcon from '@mui/icons-material/Comment';
import { getPositionForPopUp } from '../../Map/HelperService';

interface CatchDetailItem {
  name: string;
  quantity: number;
  Alive: boolean;
  Finned: boolean;
  time: string;
  id: string;
  uuid: string;
}

interface CatchData {
  uuid: string;
  catch_date: string;
  vessel_name: string;
  latitude: string;
  longitude: string;
  species: string;
  quantity: string;
  fishing_technique: string;
  catch_details: { [key: string]: CatchDetailItem };
  total_quantity: number;
  comment: string;
  catch_conditions: {
    plankton: string;
    temp_c: string;
    temperature_25m: string;
    temperature_50m: string;
    temperature_100m: string;
    temperature_150m: string;
    temperature_200m: string;
    temperature_300m: string;
    current_speed: string;
    current_direction: string;
    ssh: string;
    thermocliene_depth: string;
    bathymetry: string;
    salinity: string;
    pressure: string;
    wind_speed: string;
    wind_direction: string;
    wave_height: string;
  };
  vessel_id: string;
  file_id: string;
}

const FishCatchCard: FC<any> = ({ data }) => {
  // --- Set Store Query ---
  const queryClient = useQueryClient();

  // --- Convertion data ---
  function convertCatchDetailsToArray(catchData: CatchData): CatchDetailItem[] {
    const catchArray: CatchDetailItem[] = [];

    if (catchData !== undefined) {
      for (const key in catchData.catch_details) {
        if (catchData.catch_details.hasOwnProperty(key)) {
          const details = catchData.catch_details[key];
          catchArray.push({
            id: key,
            uuid: catchData.uuid,
            name: details.name,
            quantity: details.quantity,
            Alive: details.Alive,
            Finned: details.Finned,
            time: details.time,
          });
        }
      }
    }

    return catchArray;
  }

  // --- Selected Information By User ---
  function selectedCardInformation(dataInfo: any) {
    queryClient.setQueryData('GI', {
      vessel_Name: dataInfo?.vessel_name,
      catch_Date: dataInfo?.catch_date,
      total_quantity: dataInfo?.total_quantity,
      comment: dataInfo?.comment,
      fishing_technique: dataInfo?.fishing_technique,
      latitude: dataInfo?.latitude,
      longitude: dataInfo?.longitude,
      uuid: dataInfo?.uuid,
    });

    queryClient.setQueryData('CC', {
      plankton: dataInfo?.catch_conditions.plankton,
      temp_c: dataInfo?.catch_conditions.temp_c,
      temperature_25m: dataInfo?.catch_conditions.temperature_25m,
      temperature_50m: dataInfo?.catch_conditions.temperature_100m,
      temperature_150m: dataInfo?.catch_conditions.temperature_150m,
      temperature_200m: dataInfo?.catch_conditions.temperature_200m,
      temperature_300m: dataInfo?.catch_conditions.temperature_300m,
      current_speed: dataInfo?.catch_conditions.current_speed,
      current_direction: dataInfo?.catch_conditions.current_direction,
      ssh: dataInfo?.catch_conditions.ssh,
      thermocliene_depth: dataInfo?.catch_conditions.thermocliene_depth,
      bathymetry: dataInfo?.catch_conditions.bathymetry,
      salinity: dataInfo?.catch_conditions.salinity,
      pressure: dataInfo?.catch_conditions.pressure,
      wind_speed: dataInfo?.catch_conditions.wind_speed,
      wind_direction: dataInfo?.catch_conditions.wind_direction,
      wave_height: dataInfo?.catch_conditions.wave_height,
    });

    queryClient.setQueryData('CD', convertCatchDetailsToArray(dataInfo));

    queryClient.setQueryData('UUIDRecord', dataInfo?.uuid);
  }

  // Verify the reFetch of the record change
  const reFetch = useFetchFishCatch((state) => state.fetchRecord);
  const uuidRecord = useQuery('UUIDRecord', () =>
    queryClient.getQueryData('UUIDRecord')
  );

  useEffect(() => {
    if (!isEmpty(uuidRecord.data)) {
      const selectedCard = data.find(
        (item: any) => item.uuid === uuidRecord.data
      );
      selectedCardInformation(selectedCard);
    }
  }, [reFetch]);

  return (
    <div>
      {data?.map((dataInfo: any, index: any) => (
        <Card
          onClick={() => {
            selectedCardInformation(dataInfo);
          }}
          style={{
            margin: '10px 3rem 10px 0.5rem',
            padding: '10px 10px 10px 10px',
            border: '1px solid rgba(0, 0, 0, 0.25)',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            cursor: 'pointer',
          }}
        >
          <Stack direction="column" spacing={1}>
            <Typography variant="h6">{dataInfo.vessel_name}</Typography>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <div
                style={{
                  background: '#B3B5B8',
                  padding: '3px 3px 3px 3px',
                  borderRadius: '5px',
                }}
              >
                <CalendarMonthIcon />
              </div>
              <Typography
                sx={{ fontSize: '12px', fontWeight: '600' }}
                variant="h6"
              >
                {moment(dataInfo.catch_date).format('L')}
              </Typography>
              <div
                style={{
                  background: '#B3B5B8',
                  padding: '3px 3px 3px 3px',
                  borderRadius: '5px',
                }}
              >
                <TagIcon />
              </div>
              <Typography
                sx={{ fontSize: '12px', fontWeight: '600' }}
                variant="subtitle2"
              >
                {dataInfo.total_quantity}
              </Typography>
              <div
                style={{
                  background: '#B3B5B8',
                  padding: '3px 3px 3px 3px',
                  borderRadius: '5px',
                }}
              >
                <PhishingIcon />
              </div>
              <Typography
                sx={{ fontSize: '12px', fontWeight: '600' }}
                variant="subtitle2"
              >
                {dataInfo.fishing_technique || 'N/A'}
              </Typography>
            </Stack>
            <div
              style={{
                background: '#FFEBC8',
                borderRadius: '5px',
                padding: '5px 5px 5px 5px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <div>
                <ExploreIcon style={{ color: '#FFA500' }} />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  background: '#FFFFFF',
                  padding: '5px',
                  borderRadius: '10px',
                  margin: '0px 10px 0px 10px',
                }}
              >
                <img
                  alt="latitude"
                  src={require('../../../assets/icons/latitude.svg').default}
                />
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: '600',
                    margin: '0px 10px 0px 10px',
                  }}
                  variant="subtitle2"
                >
                  {
                    getPositionForPopUp({
                      latitude: dataInfo.latitude,
                      longitude: dataInfo.longitude,
                    }).lat
                  }
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  background: '#FFFFFF',
                  padding: '5px',
                  borderRadius: '10px',
                  margin: '0px 10px 0px 10px',
                }}
              >
                <img
                  alt="longitude"
                  src={require('../../../assets/icons/longitude.svg').default}
                />
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: '600',
                    margin: '0px 10px 0px 10px',
                  }}
                  variant="subtitle2"
                >
                  {
                    getPositionForPopUp({
                      latitude: dataInfo.latitude,
                      longitude: dataInfo.longitude,
                    }).lon
                  }
                </Typography>
              </div>
            </div>
            <Stack direction="row" spacing={1}>
              <CommentIcon />
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '600',
                  maxWidth: '300px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                variant="subtitle2"
              >
                {dataInfo.comment || 'N/A'}
              </Typography>
            </Stack>
          </Stack>
        </Card>
      ))}
    </div>
  );
};

export default FishCatchCard;
