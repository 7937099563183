import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { LayoutContext } from '../../LayoutContext';
import { AnalysisStyles } from '../AnalysisStyles';
import { Wrapper } from '../../../../components/Wrapper/Wrapper';
import { IUUTimelineStyles } from '../../IUU/IUUSettings/IUUSettingsStyles';
import { ColorBox } from '../../../../components/ColorBox/ColorBox';
import { AISLegendStyles } from '../../IUU/IUUStyles';
import { I18nKey } from '../../../../translations/I18nKey';
import { useStyles } from '../../../../utils/util';
import settingsIcon from '../../../../assets/icons/setting.svg';
import closeIcon from '../../../../assets/icons/close.svg';
import { useContextSelector } from 'use-context-selector';

type FishRecommendation = {
  color: string;
  fishName: string;
  visible: boolean;
};

type ComponentProps = {
  reccLastUpdated: string;
  recommendationData: Array<{
    color: string;
    name: string;
    visible: boolean;
  }>;
  recommendationSettingsData: Array<{
    color: string;
    name: string;
    visible: boolean;
  }>;
  onCloseSettings: () => void;
  onRecommendationSettings: () => void;
};

export const RecommendationLegends = ({
  reccLastUpdated,
  recommendationData,
  recommendationSettingsData,
  onCloseSettings,
  onRecommendationSettings,
}: ComponentProps) => {

  const intl = useIntl();
  const classes = useStyles(IUUTimelineStyles, [])();

  const [colorMapping, setColorMapping] = useState<FishRecommendation[]>([]);
  const [gridColumns, setGridColumns] = useState<number>(4);
  const [wrapperWidth, setWrapperWidth] = useState<string>('360px');

  const setRecommendationLength = useContextSelector(
    LayoutContext,
    (state) => state.setRecommendationLength
  );

  useEffect(() => {
    const initialData =
      recommendationSettingsData.length === 0
        ? recommendationData
        : recommendationSettingsData;

    const data = initialData.map((element) => {
      return {
        fishName: element.name,
        color: element.color,
        visible: element.visible,
      };
    });

    if (data.length < 3) {
      setGridColumns(4);
      setWrapperWidth('190px');
    } else if (data.length > 4) {
      setGridColumns(12);
      setWrapperWidth('360px');
    } else {
      setWrapperWidth('240px');
      setGridColumns(8);
    }
    setRecommendationLength(data.length);
    setColorMapping(data);
  }, [recommendationData, recommendationSettingsData]);

  return (
    <Box id="recommendation-legend-wrapper">
      <Wrapper
        wrapperStyle={{
          height: '135px',
          width: wrapperWidth,
          backgroundColor: '#121212',
          position: 'relative',
          borderRadius: '12px',
          zIndex: 5,
          overflow: 'visible',
        }}
      >
        <Box sx={AnalysisStyles.LegendsBox}>
          <Box
            sx={{
              color: 'white',
              my: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography sx={{ color: 'white' }}>
              {intl.formatMessage({ id: I18nKey.ANALYSIS_RECOMMENDATIONS })}
            </Typography>
            <Typography sx={{ fontSize: '13px' }}>
              {reccLastUpdated &&
                `${intl.formatMessage({
                  id: I18nKey.ANALYSIS_RECOMMENDATION_LAST_UPDATED,
                })}: ${moment(reccLastUpdated).format('Do MMMM YYYY') || ''}`}
            </Typography>
          </Box>
          {colorMapping.length > 0 && (
            <Grid
              container
              spacing={2}
              columns={{
                xs: gridColumns,
                sm: gridColumns,
                md: gridColumns,
                lg: gridColumns,
                xl: gridColumns,
              }}
            >
              {colorMapping.map((recc) => {
                if (recc.visible) {
                  return (
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      key={recc.fishName}
                    >
                      <Box sx={{ width: '80px' }}>
                        <ColorBox
                          color={recc.color}
                          boxStyle={AISLegendStyles.AISLegendColorBoxStyle}
                        />
                      </Box>
                      <Typography sx={{ color: 'white', fontSize: '14px' }}>
                        {_.startCase(_.toLower(recc.fishName))}
                      </Typography>
                    </Grid>
                  );
                }
              })}
            </Grid>
          )}
        </Box>
        <img
          src={settingsIcon}
          alt="settings"
          className={classes.settingsIcon}
          onClick={onRecommendationSettings}
        />
        <img
          src={closeIcon}
          alt="close"
          className={classes.closeIcon}
          onClick={onCloseSettings}
        />
      </Wrapper>
    </Box>
  );
};
