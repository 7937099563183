export const labelText: any = {
  root: {
    color: '#D1D1D1 !important',
    fontSize: '16px !important',
    marginBottom: '10px !important',
  },
};

export const contentText: any = {
  root: {
    color: '#FFFFFF !important',
    fontSize: '16px !important',
    marginBottom: '10px !important  ',
  },
};
