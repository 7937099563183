import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  RadioGroup,
  TextField,
  Typography,
  IconButton,
  Button,
  Chip,
} from '@mui/material';
import axios from 'axios';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import { floor, isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { ColorBox } from '../../../../../components/ColorBox/ColorBox';
import { DatePicker } from '../../../../../components/DateRangePicker/Calender';
import RadioButton from '../../../../../components/RadioButton/RadioButton';
import { Stepper } from '../../../../../components/Stepper/Stepper';
import { Toggle } from '../../../../../components/Toggle/Toggle';
import { I18nKey } from '../../../../../translations/I18nKey';
import * as IUUClient from '../../../../../api/iuuAPIs';
import { GeofenceCRUDStyles } from './GeofenceStyles';
import { GeofenceGeneralProps } from './GeofenceTypes';
import { getUserAttributes } from '../../../../../utils/auth';
import { IUUService } from '../../IUUService';
import { MapContext } from '../../../MapContext';
import { removeLayerSource } from '../../../HelperService';
import { FillAreaService } from '../../../Products/MapLayers/FillAreaService';
import { useContextSelector } from 'use-context-selector';
import { IControl } from 'mapbox-gl';
import { ProgressSpinner } from '../../../../../components/ProgressSpinner/ProgressSpinner';

const precision = 1;
const Input = styled('input')({
  display: 'none',
});

export const GeofenceGeneral: React.FC<GeofenceGeneralProps> = ({
  isGeofenceEdit,
  geofenceGeneral,
  setGeofenceGeneral,
  error,
  displayAlert,
}) => {
  const intl = useIntl();
  const userName = getUserAttributes().userName;
  const map = useContextSelector(MapContext, (state) => state.map);
  const [drawControl, setDrawControl] = useState<IControl>();
  const [file, setFile] = useState<File | null>(null);
  const [fileKey, setFileKey] = useState<string>('');
  const [alertRadioType, setAlertRadioType] = useState<string>('email');
  const [disableDrawingSave, setDisableDrawingSave] = useState<boolean>(true);
  const [drawMode, setDrawMode] = useState(false);
  const [drawGeojson, setDrawGeojson] = useState({});
  const [disableDelete, setDisableDelete] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (geofenceGeneral.hasOwnProperty('url') && isEmpty(drawGeojson)) {
      geofenceGeneral.url && getGeofenceGeojson(geofenceGeneral);
    }
  }, [geofenceGeneral]);

  useEffect(() => {
    return () => {
      drawControl && IUUService.disableDrawing(map, drawControl);
    };
  }, [drawControl]);

  const getGeofenceGeojson = async (fenceData: any) => {
    const data = await axios.get(fenceData.url);
    setDrawGeojson(data.data);
    setGeofenceGeneral({
      ...geofenceGeneral,
      filedata: data.data,
      uploadedFileName: fenceData?.gjsonFileName
        ? fenceData.gjsonFileName
        : fenceData?.filename,
    });
    setLoading(false);
    map &&
      FillAreaService.addFillAreaLayer(
        map,
        'geofenceDrawing',
        data.data,
        true,
        {
          'fill-color': geofenceGeneral.display.fillColor,
          'fill-opacity': 0.5,
        },
        false,
        'geofence',
        true,
        {
          'line-color': geofenceGeneral.display.strokeColor,
          'line-width': 3,
        },
        () => {},
        () => {}
      );
  };

  const onToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGeofenceGeneral({ ...geofenceGeneral, active: event.target.checked });
  };

  const onTypeRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGeofenceGeneral({ ...geofenceGeneral, type: event.target.value });
  };

  const onStartDateChange = (dateSelected: Date | null) => {
    setGeofenceGeneral({
      ...geofenceGeneral,
      startDate: dateSelected ? dateSelected : new Date(),
    });
  };

  const onEndDateChange = (dateSelected: Date | null) => {
    setGeofenceGeneral({
      ...geofenceGeneral,
      endDate: dateSelected ? dateSelected : new Date(),
    });
  };

  const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGeofenceGeneral({
      ...geofenceGeneral,
      description: event.target.value,
    });
  };

  const onDataRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGeofenceGeneral({
      ...geofenceGeneral,
      data: event.target.value,
    });
  };

  const onAlertRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAlertRadioType((event.target as HTMLInputElement).value);
  };

  const onFillColorChange = (event: any) => {
    setGeofenceGeneral({
      ...geofenceGeneral,
      display: { ...geofenceGeneral.display, fillColor: event.hex },
    });
    IUUService.changeDisplayProperty(
      map,
      geofenceGeneral.display.strokeColor,
      event.hex,
      geofenceGeneral.display.fillWidth
    );
  };

  const onStrokeColorChange = (event: any) => {
    setGeofenceGeneral({
      ...geofenceGeneral,
      display: { ...geofenceGeneral.display, strokeColor: event.hex },
    });
    IUUService.changeDisplayProperty(
      map,
      event.hex,
      geofenceGeneral.display.fillColor,
      geofenceGeneral.display.fillWidth
    );
  };

  const onFillWidthChange = (e: any) => {
    setGeofenceGeneral({
      ...geofenceGeneral,
      display: {
        ...geofenceGeneral.display,
        fillWidth: floor(e.target.value, precision),
      },
    });
    IUUService.changeDisplayProperty(
      map,
      geofenceGeneral.display.strokeColor,
      geofenceGeneral.display.fillColor,
      floor(e.target.value, precision)
    );
  };

  const onEmailIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGeofenceGeneral({
      ...geofenceGeneral,
      email: e.target.value,
    });
  };

  const onFileChange = (e: any) => {
    setFileKey(Math.random().toString(36));
    setFile(e.target.files[0]);
    map &&
      removeLayerSource(map, 'geofenceDrawing', [
        'geofenceDrawing',
        'geofenceDrawingoutline',
      ]);
  };

  const onFileDelete = () => {
    setFileKey(Math.random().toString(36));
    setFile(null);
    setGeofenceGeneral({
      ...geofenceGeneral,
      filedata: null,
      uploadedFileName: '',
    });
    map &&
      removeLayerSource(map, 'geofenceDrawing', [
        'geofenceDrawing',
        'geofenceDrawingoutline',
      ]);
  };

  const onFileUpload = () => {
    if (file) {
      let formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      uploadShapeFile(userName, formData, setLoading).then((data) => {
        data && getGeofenceGeojson(data);
      });
    }
  };

  const uploadShapeFile = async (
    userName: string,
    file: any,
    setLoading: any
  ) => {
    try {
      const data = await IUUClient.uploadShapeFile(userName, file);
      displayAlert(I18nKey.IUU_SETTINGS_FILE_UPLOAD_SUCCESS, 'success');
      return data;
    } catch (error: any) {
      setLoading(false);
      if (error.response.status === 422) {
        displayAlert(error.response.data.detail, 'error', true);
      } else {
        displayAlert(I18nKey.IUU_SETTINGS_GEOFENCE_UPLOAD_ERROR_MESSAGE);
      }
      console.error(error.response);
    }
  };

  const onClickDraw = () => {
    if (drawMode) {
      setDisableDrawingSave(true);
      setDrawMode(false);
      if (map && drawControl && map.hasControl(drawControl))
        map?.removeControl(drawControl);
    } else {
      setDisableDrawingSave(false);
      setDrawMode(true);
      IUUService.drawPolygon(map, drawControl, setDrawControl, drawGeojson);
    }
  };

  const onClickSaveDrawing = () => {
    const features = IUUService.savePolygon(
      map,
      drawControl,
      geofenceGeneral.display
    );
    setDrawMode(false);
    setDrawGeojson(features);
    setGeofenceGeneral({
      ...geofenceGeneral,
      filedata: features,
    });
    setDisableDelete(false);
  };

  const onClickDeleteDrawing = () => {
    map &&
      removeLayerSource(map, 'geofenceDrawing', [
        'geofenceDrawing',
        'geofenceDrawingoutline',
      ]);
    setDisableDelete(true);
  };

  return (
    <Box>
      <Divider sx={{ borderColor: '#333333', my: 2 }} flexItem />
      <Grid container spacing={18}>
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography sx={{ mb: 1 }}>
              {intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_GEOFENCE_TYPE,
              })}
            </Typography>
            <Box sx={{ ml: '1%' }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="style"
                  name="style-radio-buttons-group"
                  value={geofenceGeneral.type}
                  onChange={onTypeRadioChange}
                >
                  <FormControlLabel
                    value="alert"
                    control={<RadioButton />}
                    label={intl.formatMessage({
                      id: I18nKey.IUU_SETTINGS_GEOFENCE_ALERT,
                    })}
                  />
                  <FormControlLabel
                    value="safe"
                    control={<RadioButton />}
                    label={intl.formatMessage({
                      id: I18nKey.IUU_SETTINGS_GEOFENCE_SAFE,
                    })}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography sx={{ mb: 1 }}>
              {intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_GEOFENCE_STATUS,
              })}
            </Typography>
            <FormGroup>
              <FormControlLabel
                sx={{
                  color: '#fff',
                  marginLeft: '0px',
                }}
                control={
                  <Toggle
                    checked={geofenceGeneral.active}
                    sx={{ marginLeft: 0 }}
                    onChange={onToggleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_ACTIVE,
                })}
                labelPlacement="end"
              />
            </FormGroup>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ borderColor: '#333333', my: 2 }} flexItem />
      <Grid container spacing={18}>
        <Grid item xs={6}>
          <Box sx={GeofenceCRUDStyles.datePicker}>
            <Typography>
              {intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_GEOFENCE_START_DATE,
              })}
            </Typography>
            <DatePicker
              defaultSelection={geofenceGeneral.startDate}
              onChange={onStartDateChange}
              format="MMM dd, yyyy"
              textColor="#fff"
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={GeofenceCRUDStyles.datePicker}>
            <Typography>
              {intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_GEOFENCE_END_DATE,
              })}
            </Typography>
            <DatePicker
              defaultSelection={geofenceGeneral.endDate}
              onChange={onEndDateChange}
              format="MMM dd, yyyy"
              textColor="#fff"
            />
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ borderColor: '#333333', my: 2 }} flexItem />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
          {intl.formatMessage({
            id: I18nKey.IUU_SETTINGS_GEOFENCE_DESCRIPTION,
          })}
        </Typography>
        <TextField
          id="geofence-description-input"
          placeholder={intl.formatMessage({
            id: I18nKey.IUU_SETTINGS_GEOFENCE_DESCRIPTION_PLACEHOLDER,
          })}
          value={geofenceGeneral.description}
          onChange={onDescriptionChange}
          multiline
          rows={3}
          sx={GeofenceCRUDStyles.geofenceName}
        />
      </Box>
      <Divider sx={{ borderColor: '#333333', my: 2 }} flexItem />
      <Grid container columnSpacing={6} rowSpacing={2}>
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography sx={{ mb: 1 }}>
              {intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_GEOFENCE_DATA,
              })}
            </Typography>
            <Box sx={{ ml: '1%' }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="style"
                  name="style-radio-buttons-group"
                  value={geofenceGeneral.data}
                  onChange={onDataRadioChange}
                >
                  <FormControlLabel
                    value="draw"
                    control={<RadioButton />}
                    label={intl.formatMessage({
                      id: I18nKey.IUU_SETTINGS_GEOFENCE_DRAW,
                    })}
                    disabled={isGeofenceEdit && geofenceGeneral.data !== 'draw'}
                    sx={GeofenceCRUDStyles.radioButton}
                  />
                  <FormControlLabel
                    value="upload"
                    control={<RadioButton />}
                    label={intl.formatMessage({
                      id: I18nKey.IUU_SETTINGS_GEOFENCE_UPLOAD,
                    })}
                    disabled={
                      isGeofenceEdit && geofenceGeneral.data !== 'upload'
                    }
                    sx={GeofenceCRUDStyles.radioButton}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Grid>
        {geofenceGeneral.data === 'draw' && (
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& .MuiTypography-root': { color: 'white' },
              }}
            >
              <IconButton onClick={onClickDraw}>
                <img
                  alt="draw"
                  style={{
                    filter: drawMode
                      ? GeofenceCRUDStyles.drawButton.active
                      : GeofenceCRUDStyles.drawButton.inActive,
                  }}
                  src={require('../../../../../assets/icons/draw.svg').default}
                />
                <Typography sx={{ ml: 1 }}>
                  {intl.formatMessage({
                    id: I18nKey.IUU_SETTINGS_GEOFENCE_DRAW,
                  })}
                </Typography>
              </IconButton>

              <Box sx={{ display: 'flex' }}>
                <IconButton
                  disabled={disableDrawingSave}
                  onClick={onClickSaveDrawing}
                >
                  <img
                    alt="save"
                    style={{
                      filter: drawMode
                        ? GeofenceCRUDStyles.drawButton.active
                        : GeofenceCRUDStyles.drawButton.inActive,
                    }}
                    src={
                      require('../../../../../assets/icons/save.svg').default
                    }
                  />
                  <Typography sx={{ ml: 1 }}>
                    {intl.formatMessage({
                      id: I18nKey.IUU_SETTINGS_GEOFENCE_SAVE,
                    })}
                  </Typography>
                </IconButton>
                <IconButton
                  disabled={disableDelete}
                  onClick={onClickDeleteDrawing}
                >
                  <img
                    alt="delete"
                    style={{
                      filter:
                        !disableDelete && !drawMode
                          ? GeofenceCRUDStyles.drawButton.active
                          : GeofenceCRUDStyles.drawButton.inActive,
                    }}
                    src={
                      require('../../../../../assets/icons/delete.svg').default
                    }
                  />
                  <Typography sx={{ ml: 1 }}>
                    {intl.formatMessage({
                      id: I18nKey.IUU_SETTINGS_GEOFENCE_DELETE,
                    })}
                  </Typography>
                </IconButton>
              </Box>
            </Box>
            {drawMode ? (
              <Typography sx={{ fontSize: '14px' }}>
                <InfoIcon sx={{ fontSize: '18px', marginTop: '10px' }} />
                {intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_DRAW_SAVE,
                })}
              </Typography>
            ) : null}
          </Grid>
        )}
        {geofenceGeneral.data === 'upload' && (
          <>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <label htmlFor="contained-button-file">
                  <Input
                    accept=".zip"
                    id="contained-button-file"
                    type="file"
                    key={fileKey}
                    onInput={onFileChange}
                  />
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      color: 'black',
                      background: 'orange',
                      '&:hover': {
                        background: 'darkOrange',
                      },
                    }}
                  >
                    <CloudUploadIcon />
                  </Button>
                </label>
                {(file || geofenceGeneral.uploadedFileName) && (
                  <>
                    <Chip
                      label={file?.name || geofenceGeneral.uploadedFileName}
                      variant="outlined"
                      onDelete={onFileDelete}
                      sx={{ color: 'white', ml: 1 }}
                    />
                    <Button
                      aria-label="upload-file"
                      variant="contained"
                      component="span"
                      sx={{
                        color: 'black',
                        background: 'orange',
                        '&:hover': {
                          background: 'darkOrange',
                        },
                        ml: 1,
                      }}
                      onClick={onFileUpload}
                    >
                      <CheckBoxIcon
                        sx={{ fontSize: '20px', marginRight: '10px' }}
                      />
                      {intl.formatMessage({
                        id: I18nKey.IUU_SETTINGS_GEOFENCE_UPLOAD_FILE,
                      })}
                    </Button>
                  </>
                )}
              </Box>
            </Grid>
            <Typography
              sx={{ ml: '50px', fontWeight: '500', mb: '10px', mt: '5px' }}
            >
              {intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_GEOFENCE_UPLOAD_INFO,
              })}
            </Typography>
            <Typography sx={{ ml: '50px', fontSize: '15px', color: '#d2691e' }}>
              {intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_GEOFENCE_UPLOAD_INFO_RULES,
              })}
              <br></br>{' '}
              {intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_GEOFENCE_UPLOAD_INFO_RULE_1,
              })}
              <br></br>
              {intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_GEOFENCE_UPLOAD_INFO_RULE_2,
              })}{' '}
              <br></br>{' '}
              {intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_GEOFENCE_UPLOAD_INFO_RULE_3,
              })}
            </Typography>
          </>
        )}
      </Grid>
      <Divider sx={{ borderColor: '#333333', my: 2 }} flexItem />
      <Typography sx={{ mb: 1 }}>
        {intl.formatMessage({
          id: I18nKey.IUU_SETTINGS_GEOFENCE_DISPLAY,
        })}
      </Typography>
      <Grid container columnSpacing={6} rowSpacing={2}>
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              '& .MuiBox-root': {
                top: '0px',
              },
            }}
          >
            <Typography sx={{ mb: 1 }}>
              {intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_GEOFENCE_FILL_COLOR,
              })}
            </Typography>
            <Grid container columnSpacing={2}>
              <Grid item xs={8}>
                <ColorBox
                  key={'fill-color'}
                  showColorPicker={true}
                  onChangeColor={onFillColorChange}
                  color={geofenceGeneral.display.fillColor}
                  boxStyle={GeofenceCRUDStyles.boxStyle}
                ></ColorBox>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              '& .MuiBox-root': {
                top: '0px',
              },
            }}
          >
            <Typography sx={{ mb: 1 }}>
              {intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_GEOFENCE_BORDER_COLOR,
              })}
            </Typography>
            <Grid container columnSpacing={2}>
              <Grid item xs={8}>
                <ColorBox
                  key={'stroke-color'}
                  showColorPicker={true}
                  onChangeColor={onStrokeColorChange}
                  color={geofenceGeneral.display.strokeColor}
                  boxStyle={GeofenceCRUDStyles.boxStyle}
                ></ColorBox>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography sx={{ mb: 1 }}>
              {intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_GEOFENCE_FILL_WIDTH,
              })}
            </Typography>
            <Stepper
              value={geofenceGeneral.display.fillWidth}
              key="fill-width"
              onChange={onFillWidthChange}
            />
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ borderColor: '#333333', my: 2 }} flexItem />
      <Grid container>
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography sx={{ mb: 1 }}>
              {intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_GEOFENCE_ALERT,
              })}
            </Typography>
            <Box sx={{ ml: '1%' }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="style"
                  name="style-radio-buttons-group"
                  value={alertRadioType}
                  onChange={onAlertRadioChange}
                >
                  <FormControlLabel
                    value="email"
                    control={<RadioButton />}
                    label={intl.formatMessage({
                      id: I18nKey.IUU_SETTINGS_GEOFENCE_EMAIL,
                    })}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            error
            id="geofence-email-input"
            placeholder={intl.formatMessage({
              id: I18nKey.IUU_SETTINGS_GEOFENCE_EMAIL_PLACEHOLDER,
            })}
            value={geofenceGeneral.email}
            onChange={onEmailIdChange}
            sx={GeofenceCRUDStyles.geofenceTextArea}
            helperText={
              error.email &&
              intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_GEOFENCE_EMAIL_ERROR_MESSAGE,
              })
            }
          />
        </Grid>
      </Grid>
      {loading && <ProgressSpinner showSpinner={loading} />}
    </Box>
  );
};
