import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useContext } from 'use-context-selector';
import LogoutIcon from '@mui/icons-material/Logout';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AssessmentSharpIcon from '@mui/icons-material/AssessmentSharp';
import { Box } from '@mui/material';
import moment from 'moment';
import axios from 'axios';
import { Header } from '../../components/Header/Header';
import { LogoSection } from '../../components/Header/LogoSection';
import { HeaderMenuSection } from '../../components/Header/HeaderMenuSection';
import { HeaderMenuItemProps } from '../../components/Header/HeaderTypes';
import {
  FishCatchDashboardStyles,
  useFishCatchDashboardStyles,
} from './FishCatchDashboardStyles';
import AppDateRangePicker from '../../components/DateRangePicker/Component/DateRangePicker';
import { DatePickerRanges } from './DashboardConfig';
import { I18nKey } from '../../translations/I18nKey';
import ImportPopUp from './ImportDataSection/ImportPopUp';
import FishCatchClient from '../../api/fishCatchAPIs';
import Snackbar from '../../components/Snackbar/Snackbar';
import { LicensedFeaturesContext } from '../Contexts/LicensedFeaturesContext';
import './FishCatchDashboard.scss';
import { useNavFishCatch } from './store/FishCatchStore';

const FishCatchHeader: React.FC<any> = ({
  userName,
  seastarId,
  onDateRangeChange,
  setRefetchData,
  disableExport,
  dateRange,
  setIsLoading,
  selectedSpecies,
  selectedVessels,
  selectedFishingTechniques,
  setUploadedData,
  uploadedData,
}) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [selectedStartDate, setStartDate] = useState<any>(
    moment().subtract(7, 'days')
  );
  const [selectedEndDate, setEndDate] = useState(moment());
  const [open, setOpen] = useState(false);

  const [alert, setAlert] = useState({
    type: '',
    display: false,
    message: '',
  });

  const { features } = useContext(LicensedFeaturesContext);
  const fishCatchClasses: any = useFishCatchDashboardStyles();

  // --- Zustand Store ---
  const setNav = useNavFishCatch((state) => state.setNav);

  let fromDate = dateRange[0];
  let toDate = dateRange[1];

  /**
   * For the menuList, the uploaded data new feature has been integrated
   *
   //! Take into consideration that the showInDropdown is currently true on key="3",
   //! it must be integrated on the backend the new feature that is included and seek to place the following FishcatchListData
   */
  const menuList: Array<HeaderMenuItemProps> = [
    {
      key: '1',
      icon: UploadFileIcon,
      name: intl.formatMessage({
        id: I18nKey.FISH_CATCH_DASHBOAD_UPLOAD,
      }),
      itemClass: 'menu__iconFormat',
      onClick: () => onUpload(),
      disabled: false,
      showInDropdown: features.includes('FishcatchImportData'),
    },
    {
      key: '2',
      icon: AssessmentSharpIcon,
      name: intl.formatMessage({
        id: I18nKey.FISH_CATCH_DASHBOAD_REPORT,
      }),
      itemClass: 'menu__iconFormat',
      onClick: () => onReportDownloadClick(),
      disabled: disableExport,
      showInDropdown: features.includes('FishcatchDownloadReport'),
    },
    {
      key: '3',
      icon: ListAltIcon,
      name: intl.formatMessage({
        id: I18nKey.FISH_CATCH_DASHBOARD_LIST,
      }),
      itemClass: 'menu__iconFormat',
      onClick: () => onViewList(),
      disabled: false,
      showInDropdown: true,
    },
    {
      key: '4',
      icon: LogoutIcon,
      name: intl.formatMessage({
        id: I18nKey.AUTH_LOGOUT,
      }),
      itemClass: 'menu__iconFormat fishcatch__logout-icon',
      onClick: () => navigate('/logout'),
      disabled: false,
      showInDropdown: true,
    },
  ];

  const fetchFishCatchReport = async () => {
    // Generates a comma seperated string of species expected by BE
    let selectedSpeciesString = selectedSpecies
      .map((el: { name: string }) => el.name)
      .join(',');

    // Generates a comma seperated string of vessels expected by BE
    let selectedVesselsString = selectedVessels
      .map((el: { vessel: string }) => el.vessel)
      .join(',');

    let selectedTechniqueString = selectedFishingTechniques
      .map((el: { fishingTechnique: string }) => el.fishingTechnique)
      .join(',');

    let payload = {
      filters: {
        vesselNames: selectedVesselsString,
        species: selectedSpeciesString,
        fishingTechniques: selectedTechniqueString,
      },
      email: [],
    };
    return await FishCatchClient.getFishCatchReport(
      userName,
      fromDate,
      toDate,
      'pdf',
      payload
    );
  };

  const onReportDownloadClick = () => {
    setIsLoading(true);
    fetchFishCatchReport()
      .then(async (response) => {
        // Fetch PDF from presigned URL
        const { url, fileName } = response.data;
        let data = await axios.get<string, any>(url, {
          responseType: 'arraybuffer',
        });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(
          new Blob([data.data], { type: 'application/pdf' })
        );
        link.download = `${fileName}.pdf`;
        link.click();

        setIsLoading(false);
      })
      .catch(() => {
        setAlert({
          type: 'error',
          display: true,
          message: intl.formatMessage({
            id: I18nKey.ERROR_MESSAGE_GET,
          }),
        });
        setTimeout(() => {
          setAlert({
            type: '',
            display: false,
            message: '',
          });
        }, 5000);
        setIsLoading(false);
      });
  };

  const onUpload = () => {
    setOpen(true);
  };

  const onViewList = () => {
    setUploadedData(false);
    setNav({
      title: 'uploadedFiles',
      selection: 'FileTable',
      submittedDate: '',
    });
  };

  const range = DatePickerRanges();

  const onDateChange = (fromDate: any, toDate: any, label: string) => {
    setStartDate(
      label ===
        intl.formatMessage({
          id: I18nKey.CALENDAR_ALLDATA,
        })
        ? null
        : fromDate
    );
    setEndDate(
      label ===
        intl.formatMessage({
          id: I18nKey.CALENDAR_ALLDATA,
        })
        ? null
        : toDate
    );
    onDateRangeChange([
      label ===
      intl.formatMessage({
        id: I18nKey.CALENDAR_ALLDATA,
      })
        ? null
        : fromDate,
      label ===
      intl.formatMessage({
        id: I18nKey.CALENDAR_ALLDATA,
      })
        ? null
        : toDate,
    ]);
  };

  return (
    <>
      {open && (
        <ImportPopUp setOpen={setOpen} setRefetchData={setRefetchData} />
      )}

      {alert.display && (
        <Snackbar
          type={alert.type}
          display={alert.display}
          message={alert.message}
        ></Snackbar>
      )}

      <Header
        leftElement={
          <LogoSection
            route={intl.formatMessage({
              id: I18nKey.HEADER_FISH_CATCH,
            })}
          ></LogoSection>
        }
        centerElement={
          <Box className={fishCatchClasses.dateRangePickerStyles}>
            <AppDateRangePicker
              defaultSelection={{
                start: selectedStartDate,
                end: selectedEndDate,
              }}
              ranges={range}
              format={'MMMM D, YYYY'}
              onChange={onDateChange}
              width={280}
              datePickerLabelStyles={
                FishCatchDashboardStyles.datePickerLabelExtraStyles
              }
            ></AppDateRangePicker>
          </Box>
        }
        rightElement={
          <HeaderMenuSection
            user={{ userName, seastarId }}
            menuList={menuList}
          ></HeaderMenuSection>
        }
      />
    </>
  );
};

export default FishCatchHeader;
