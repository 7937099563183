import { Box, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { dateToString } from '../../../../utils/util';
import { TimelineSlider } from '../../../../components/Slider/Slider';
import { Wrapper } from '../../../../components/Wrapper/Wrapper';
import closeIcon from '../../../../assets/icons/close.svg';
import { IUUTimelineStyles } from './IUUSettingsStyles';
import { useStyles } from '../../../../utils/util';
import { AISTrackTimelineConfig } from '../config';
import { useIntl } from 'react-intl';
import TrackDurationSlider from '../../../../components/Slider/Component/TrackDurationSlider';
import {
  getSliderTrackDurationMarks,
  trackValueDayMap,
} from './VesselBuoyConfig';
import { VesselTrackingService } from '../VesselTrackingService';
import { I18nKey } from '../../../../translations/I18nKey';

function getDateFormat(date: number) {
  return `${new Date(date).getMonth() + 1}/${new Date(
    date
  ).getDate()}/${new Date(date).getFullYear()}`;
}

const timeLineDefaultValue = () => [
  new Date().getTime() - 86400000 * 7,
  new Date().getTime(),
];

const getTimelineMarks = (totalDuration: number, interval: number) => {
  const list = [];
  const oneDayMillis = 86400000;
  const duration = totalDuration * 86400000;
  const todayInMillis = new Date().getTime();

  for (let d = 0; d <= 27; d += interval) {
    list.push({
      value: todayInMillis - oneDayMillis * d,
      label: `${getDateFormat(todayInMillis - oneDayMillis * d)}`,
    });
  }
  list.push({
    value: todayInMillis - duration,
    label: `${getDateFormat(todayInMillis - duration)}`,
  });
  return list.reverse();
};

export const IUUTimeline: React.FC<any> = ({
  onCloseTrackPopup,
  setTrackDuration,
  setBuoyTrackDuration,
  setVesselTrackDuration,
  AISTrackList,
  buoyTrackList,
  vesselTrackList,
}) => {
  const intl = useIntl();
  const classes = useStyles(IUUTimelineStyles, [])();
  const [defaultAISDuration, setDefaultAISDuration] = useState<number[]>([]);
  const [marks, setMarks] = useState<any[]>([]);
  const [trackDates, setTrackDates] = useState<any>({
    fromDate: '',
    toDate: '',
  });
  const [defaultVesselTrackDuration, setDefaultVesselDuration] =
    useState<any>(0);
  const [defaultBuoyTrackDuration, setDefaultBuoyDuration] = useState<any>(0);

  const onChange = (e: any) => {
    const fromDate = dateToString(
      new Date(e.target.value[0]),
      AISTrackTimelineConfig.dateFormat,
      true
    );
    const toDate = dateToString(
      new Date(e.target.value[1]),
      AISTrackTimelineConfig.dateFormat,
      true
    );
    setTrackDates({ fromDate: fromDate, toDate: toDate });
  };

  const onChangeCommitted = () => {
    setTrackDuration({
      fromDate: trackDates.fromDate,
      toDate: trackDates.toDate,
    });
  };

  useEffect(() => {
    setDefaultAISDuration([...timeLineDefaultValue()]);
    setMarks(
      getTimelineMarks(
        AISTrackTimelineConfig.totalDuration,
        AISTrackTimelineConfig.defaultDuration
      )
    );
  }, []);

  const onChangeVesselBuoyDuration = (e: any, type: string) => {
    let dates = {};
    if (e.target.value === 100) {
      dates = { toDate: null, fromDate: null };
    } else {
      dates = VesselTrackingService.getTrackDates(
        trackValueDayMap[e.target.value]
      );
    }
    if (type === 'vessel') setVesselTrackDuration(dates);
    if (type === 'buoy') setBuoyTrackDuration(dates);
  };

  return (
    <>
      {defaultAISDuration?.length > 0 && marks.length > 0 && (
        <Wrapper
          wrapperStyle={{
            minHeight: '135px',
            maxHeight: '390px',
            width: '542px',
            backgroundColor: 'black',
            position: 'relative',
            borderRadius: '12px',
            zIndex: 5,
            overflow: 'visible',
            paddingTop: '5px',
          }}
        >
          {AISTrackList && AISTrackList?.AIS?.length ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '120px',
                m: 1,
              }}
            >
              <Typography
                sx={{
                  color: 'white',
                  fontSize: '18px',
                }}
              >
                {intl.formatMessage({ id: I18nKey.IUU_SETTINGS_AIS_TRACKS })}
              </Typography>
              <Box
                sx={{
                  width: '90%',
                  mx: 'auto',
                  mt: '6%',
                }}
              >
                <TimelineSlider
                  onChangeHandler={onChange}
                  onChangeCommittedHandler={onChangeCommitted}
                  defaultValue={defaultAISDuration}
                  marks={marks}
                />
              </Box>
            </Box>
          ) : null}
          {AISTrackList &&
          AISTrackList?.AIS?.length &&
          ((vesselTrackList && vesselTrackList.length) ||
            (buoyTrackList && buoyTrackList.length)) ? (
            <Divider sx={{ backgroundColor: '#303030' }} />
          ) : null}
          {vesselTrackList && vesselTrackList.length ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '120px',
                m: 1,
              }}
            >
              {' '}
              <Typography
                sx={{
                  color: 'white',
                  fontSize: '18px',
                }}
              >
                {intl.formatMessage({ id: I18nKey.IUU_SETTINGS_VESSEL_TRACKS })}
              </Typography>
              <Box
                sx={{
                  width: '90%',
                  mx: 'auto',
                  mt: '3%',
                }}
              >
                <TrackDurationSlider
                  onChange={(e: any) => onChangeVesselBuoyDuration(e, 'vessel')}
                  defaultDuration={defaultVesselTrackDuration}
                  marks={getSliderTrackDurationMarks(intl)}
                />
              </Box>
            </Box>
          ) : null}
          {vesselTrackList &&
          vesselTrackList.length &&
          buoyTrackList &&
          buoyTrackList.length ? (
            <Divider sx={{ backgroundColor: '#303030' }} />
          ) : null}
          {buoyTrackList && buoyTrackList.length ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '120px',
                m: 1,
              }}
            >
              <Typography
                sx={{
                  color: 'white',
                  fontSize: '18px',
                }}
              >
                {intl.formatMessage({ id: I18nKey.IUU_SETTINGS_BUOY_TRACKS })}
              </Typography>
              <Box
                sx={{
                  width: '90%',
                  mx: 'auto',
                  mt: '3%',
                }}
              >
                <TrackDurationSlider
                  onChange={(e: any) => onChangeVesselBuoyDuration(e, 'buoy')}
                  defaultDuration={defaultBuoyTrackDuration}
                  marks={getSliderTrackDurationMarks(intl)}
                />
              </Box>
            </Box>
          ) : null}
          <img
            src={closeIcon}
            alt="close"
            className={classes.closeIcon}
            onClick={onCloseTrackPopup}
          />
        </Wrapper>
      )}
    </>
  );
};
