import _ from 'lodash';
import moment from 'moment';
import { SettingsDefault } from './UserSettings/config';

export const removeLayerSource = (
  map: mapboxgl.Map,
  sourceId: string,
  layerList: string[]
) => {
  if (map && map.getSource(sourceId)) {
    layerList.forEach((l) => {
      removeLayer(map, l);
    });
    map.removeSource(sourceId);
  }
};

export const removeLayer = (map: mapboxgl.Map, layerId: string) => {
  if (map?.getLayer(layerId)) map.removeLayer(layerId);
};

export const toFahrenheit = (t: number) => {
  return t * (9 / 5) + 32;
};

export const toCelcius = (t: number) => {
  return (t - 32) * (5 / 9);
};

export const toInch = (t: number) => {
  return t / 2.54;
};

export const toCm = (t: number) => {
  return t * 2.54;
};

export const meterToFeet = (t: number) => {
  return t * 3.281;
};

export const metertoFathom = (t: number) => {
  return t / 1.829;
};

export const feetToMeter = (t: number) => {
  return t / 3.281;
};

export const fathomToMeter = (t: number) => {
  return t * 1.829;
};

export const toLog = (t: number) => {
  return Math.log10(t);
};

export const toAntilog = (t: number) => {
  return 10 ** t;
};

export const convert = (
  min: number,
  max: number,
  code: string,
  defaultPrecision: number,
  currentUnit: string,
  defaultUnit?: string
): any => {
  return [
    convertToUnit(min, code, defaultPrecision, currentUnit, defaultUnit),
    convertToUnit(max, code, defaultPrecision, currentUnit, defaultUnit),
  ];
};

//TODO: handle this using units context
export const convertToUnit = (
  t: number,
  code: string,
  defaultPrecision: number,
  currentUnit: string,
  defaultUnit?: string
): number => {
  let convertedValue = 0;
  switch (code) {
    case 'SST':
    case 'TSS':
      if (defaultUnit && defaultUnit !== currentUnit) {
        convertedValue = toCelcius(t);
      } else if (currentUnit === 'fahrenheit') {
        convertedValue = toFahrenheit(t);
      } else convertedValue = t;
      break;
    case 'PLK':
      if (defaultUnit) {
        convertedValue = toLog(t);
      } else {
        convertedValue = toAntilog(t);
      }
      break;
    case 'SSHA':
      if (defaultUnit && defaultUnit !== currentUnit) {
        convertedValue = toCm(t);
      } else if (currentUnit === 'in') {
        convertedValue = toInch(t);
      } else convertedValue = t;
      break;
    case 'TCD':
      if (defaultUnit && defaultUnit !== currentUnit) {
        if (currentUnit === 'ft') {
          convertedValue = feetToMeter(t);
        } else if (currentUnit === 'fathom') {
          convertedValue = fathomToMeter(t);
        }
      } else if (currentUnit === 'ft') {
        convertedValue = meterToFeet(t);
      } else if (currentUnit === 'fathom') {
        convertedValue = metertoFathom(t);
      } else convertedValue = t;
      break;
    default:
      convertedValue = t;
      break;
  }
  return parseFloat(convertedValue.toFixed(defaultPrecision));
};

export const getSelectedProductsFromStorage = () => {
  let selectedProducts: any = localStorage.getItem('selectedProducts');
  if (selectedProducts) selectedProducts = JSON.parse(selectedProducts);
  return selectedProducts;
};

export const isAnyProductSelected = (selectedProducts: any) => {
  return Object.keys(selectedProducts).find(
    (k) => selectedProducts[k].length > 0
  );
};

export const updateTileDataByUnit = (
  tileDataOb: any,
  pCode: string,
  pType: string,
  minKey: string,
  maxKey: string,
  minValue: any,
  maxValue: any,
  precision: number,
  unit: string
) => {
  [tileDataOb[pCode][pType][minKey], tileDataOb[pCode][pType][maxKey]] =
    convert(minValue, maxValue, pCode, precision, unit);
  return tileDataOb;
};

export const updatePostObDataByUnit = (
  pdata: any,
  pCode: string,
  minKey: string,
  maxKey: string,
  minValue: any,
  maxValue: any,
  precision: number,
  unit: string,
  defaultUnit?: string
) => {
  [pdata[minKey], pdata[maxKey]] = convert(
    minValue,
    maxValue,
    pCode,
    precision,
    unit,
    defaultUnit
  );
  return pdata;
};

export const getPositionForPopUp = (coords: any) => {
  const positionCoordinates = convertDMS(
    coords.latitude,
    coords.longitude,
    'format1'
  );
  return {
    lat: positionCoordinates.latCardinal + ' ' + positionCoordinates.lat,
    lon: positionCoordinates.lonCardinal + ' ' + positionCoordinates.lon,
  };
};

export const toDegreesMinutesAndSeconds = (
  coordinate: number,
  pad: number,
  format: string
) => {
  const absolute = Math.abs(coordinate);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  let seconds = minutesNotTruncated - minutes;
  let latlng =
    degrees.toString().padStart(2 + pad, '0') +
    '° ' +
    minutes.toString().padStart(2, '0');

  if (format === 'format1') {
    seconds = Math.floor(seconds * 60);
    latlng += "' " + seconds.toString().padStart(2, '0') + '"';
  } else {
    seconds = Math.floor(seconds * 1000);
    latlng += '.' + seconds.toString().padStart(3, '0') + "'";
  }

  return latlng;
};

export const convertDMS = (lat: number, lng: number, format: string): any => {
  const latitude = toDegreesMinutesAndSeconds(lat, 0, format);

  const latitudeCardinal = lat >= 0 ? 'N' : 'S';

  const longitude = toDegreesMinutesAndSeconds(lng, 1, format);
  const longitudeCardinal = lng >= 0 ? 'E' : 'W';
  return {
    lat: latitude,
    latCardinal: latitudeCardinal,
    lon: longitude,
    lonCardinal: longitudeCardinal,
  };
};

export const getScaleTypeConvertedValue = (
  scaleType: string,
  value: any,
  converter: (arg0: any) => any
) => {
  return scaleType === 'log10' ? converter(value) : value;
};

export const getProductPrecisionValue = (precision: number) => {
  return 1 / 10 ** precision;
};

export const getDateAndDepth = (productData: any) => {
  let dateValue =
    sessionStorage.getItem('date') ||
    `${moment(new Date()).format('YYYYMMDD')}`;
  let isTimeProduct, isDepthProduct, date, depth;

  isTimeProduct = !_.isEmpty(productData.timeFrequency);
  date = isTimeProduct ? dateValue + sessionStorage.getItem('time') : dateValue;
  isDepthProduct = productData.depths[0] !== '-1';
  depth = isDepthProduct ? getDepth() : '-1';
  return [date, depth];
};

export const getUniqueProducts = (selectedProds: any): string[] => {
  return _.uniq(_.flatten(Object.values(selectedProds)));
};

export const initDepthToStorage = () => {
  let selectedDepth = _.min(
    _.map(SettingsDefault.depths, (d: string) => Number(d))
  )?.toString();
  selectedDepth && localStorage.setItem('depth', selectedDepth);
  return selectedDepth;
};

export const getDepth = () => {
  let selectedDepth: any = localStorage.getItem('depth');
  if (selectedDepth) selectedDepth = JSON.parse(selectedDepth);
  else {
    selectedDepth = initDepthToStorage();
  }
  return selectedDepth;
};

export const handleMaxTextBoxUtils = (
  e: any,
  selectedValue: any[],
  setSelectedValue: any,
  minMaxState: any[],
  setMinMaxState: any,
  markValue: string | any[],
  userData: any,
  syncMinMax: boolean
) => {
  if (e.key === 'Enter' || e.type === 'blur') {
    const newValue = Number(
      Number(minMaxState[1]).toFixed(userData?.precision)
    );
    const scaleMax = Number(markValue[markValue.length - 1].value);
    const scaleMin = Number(markValue[0].value);
    if (syncMinMax) {
      if (newValue > scaleMax || newValue < scaleMin) {
        setSelectedValue([scaleMin, scaleMax]);
        setMinMaxState([scaleMin, scaleMax]);
      } else {
        setSelectedValue([-Math.abs(newValue), Math.abs(newValue)]);
        setMinMaxState([-Math.abs(newValue), Math.abs(newValue)]);
      }
    } else {
      if (newValue > scaleMax || newValue < scaleMin) {
        setSelectedValue((prevState: any[]) => [prevState[0], scaleMax]);
        setMinMaxState((prevState: any[]) => [selectedValue[0], scaleMax]);
      } else if (newValue < selectedValue[0]) {
        setSelectedValue((prevState: any[]) => [newValue, prevState[0]]);
        setMinMaxState((prevState: any[]) => [newValue, selectedValue[0]]);
      } else {
        setSelectedValue((prevState: any) => [prevState[0], newValue]);
        setMinMaxState((prevState: any[]) => [selectedValue[0], newValue]);
      }
    }
  }
};

export const handleMinTextBoxUtils = (
  e: any,
  selectedValue: any[],
  setSelectedValue: any,
  minMaxState: any[],
  setMinMaxState: any,
  markValue: string | any[],
  userData: any,
  syncMinMax: boolean
) => {
  if (e.key === 'Enter' || e.type === 'blur') {
    const newValue = Number(
      Number(minMaxState[0]).toFixed(userData?.precision)
    );
    const scaleMax = Number(markValue[markValue.length - 1].value);
    const scaleMin = Number(markValue[0].value);
    if (syncMinMax) {
      if (newValue > scaleMax || newValue < scaleMin) {
        setSelectedValue([scaleMin, scaleMax]);
        setMinMaxState([scaleMin, scaleMax]);
      } else {
        setSelectedValue([-Math.abs(newValue), Math.abs(newValue)]);
        setMinMaxState([-Math.abs(newValue), Math.abs(newValue)]);
      }
    } else {
      if (newValue > scaleMax || newValue < scaleMin) {
        setSelectedValue((prevState: any[]) => [scaleMin, prevState[1]]);
        setMinMaxState((prevState: any[]) => [scaleMin, selectedValue[1]]);
      } else if (newValue > selectedValue[1]) {
        setSelectedValue((prevState: any[]) => [prevState[1], newValue]);
        setMinMaxState((prevState: any[]) => [selectedValue[1], newValue]);
      } else {
        setSelectedValue((prevState: any) => [newValue, prevState[1]]);
        setMinMaxState((prevState: any[]) => [newValue, selectedValue[1]]);
      }
    }
  }
};

export const getCourse = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const angleDeg =
    Math.round(((Math.atan2(lon2 - lon1, lat2 - lat1) * 180) / Math.PI) * 10) /
    10;
  return angleDeg >= 0 ? angleDeg : 180 + angleDeg + 180;
};

export const getDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit: string
) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') {
      dist = dist * 1.609344;
    }
    if (unit === 'N') {
      dist = dist * 0.868976;
    }
    return Math.round(dist * 10) / 10;
  }
};

//code here is referenced from https://gist.github.com/0x263b/2bdd90886c2036a1ad5bcf06d6e6fb37
export const stringToColorConverter = (string: string): string => {
  var hash = 0;
  if (string.length === 0) return '#000000';
  for (var i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  var color = '#';
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 255;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
};
