import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../../translations/I18nKey';
import { InputStyle, Styles } from '../SliderStyles';
import { toAntilog } from '../../../containers/Map/HelperService';

export const MinMax: React.FC<any> = ({
  showLogScale,
  value,
  minMaxState,
  setMinMaxState,
  handleMinBox,
  handleMaxBox,
  unit,
  min,
  max,
  step,
  onChangeMin,
  onChangeMax,
  disabled,
}) => {
  const intl = useIntl();

  return (
    <Box>
      <Typography sx={{ ...Styles.typoGraphy }}>
        {intl.formatMessage({
          id: I18nKey.MIN_MAX,
        })}
      </Typography>
      <Box sx={{ ...Styles.minBox }} width={61}>
        <TextField
          InputProps={{
            autoComplete: 'off',
            type: 'number',
            sx: {
              ...InputStyle(disabled),
            },
          }}
          inputProps={{ min: min, max: max, step: step }}
          sx={{
            '& .MuiTextField-root': { ...Styles.inputStyle },
          }}
          id="oulined-size-small"
          size="small"
          value={minMaxState && minMaxState[0]}
          color={'warning'}
          onChange={onChangeMin}
          onFocus={(event) => {
            event.target.select();
          }}
          onKeyPress={handleMinBox}
          onBlur={handleMinBox}
          disabled={disabled}
        />
      </Box>
      <Typography sx={{ ...Styles.typoGraphy, left: '16px' }}>-</Typography>
      <Box sx={{ ...Styles.maxBox }} height={29} width={61}>
        <TextField
          InputProps={{
            autoComplete: 'off',
            type: 'number',
            sx: { ...InputStyle(disabled) },
          }}
          inputProps={{ min: min, max: max, step: step }}
          sx={{
            '& .MuiTextField-root': { ...Styles.inputStyle },
          }}
          id="outlined-size-small"
          size="small"
          value={minMaxState && minMaxState[1]}
          onChange={onChangeMax}
          onKeyPress={handleMaxBox}
          onBlur={handleMaxBox}
          color={'warning'}
          onFocus={(event) => {
            event.target.select();
          }}
          disabled={disabled}
        />
      </Box>
      <Typography sx={{ ...Styles.typoGraphy, ml: 1, left: '18px' }}>
        {unit}
      </Typography>
    </Box>
  );
};
