import mapboxgl, { Map } from 'mapbox-gl';
import arrow1 from '../../../../assets/icons/arrow1.png';
import arrow2 from '../../../../assets/icons/arrow2.png';
import wind5 from '../../../../assets/icons/wind5.png';
import wind10 from '../../../../assets/icons/wind10.png';
import wind15 from '../../../../assets/icons/wind15.png';
import wind20 from '../../../../assets/icons/wind20.png';
import wind25 from '../../../../assets/icons/wind25.png';
import wind30 from '../../../../assets/icons/wind30.png';
import wind35 from '../../../../assets/icons/wind35.png';
import wind40 from '../../../../assets/icons/wind40.png';
import { UserSettings } from '../../../types';

export class VectorService {
  public static addVectorLayer(
    map: Map,
    code: string,
    tileData: any,
    displayConfig: any,
    settings: UserSettings,
    addMapLayerId: any,
    layerOrder: any,
    setLoading: any
  ) {
    this.generateGeoJson(
      map,
      code,
      tileData,
      displayConfig,
      addMapLayerId,
      layerOrder
    );
    setLoading(false);
  }

  public static generateGeoJson(
    map: Map,
    code: string,
    tileData: any,
    displayConfig: any,
    addMapLayerId: any,
    layerOrder: any
  ) {
    let minVelocity = 0;
    let maxVelocity = 0;
    let geojsonData: { features: any; type?: string };

    let vectorDataArray = tileData.data?.split('\n');
    vectorDataArray?.shift();
    geojsonData = { type: 'FeatureCollection', features: [] };
    vectorDataArray?.forEach((item: string) => {
      const element = item.split(',').map(Number);
      if (element[0] < minVelocity) {
        minVelocity = element[0];
      }
      if (element[0] > maxVelocity) {
        maxVelocity = element[0];
      }
      geojsonData.features.push({
        type: 'Feature',
        properties: { direction: element[1], velocity: element[0] },
        geometry: { type: 'Point', coordinates: [element[3], element[2]] },
      });
    });

    this.drawVector(
      map,
      code,
      tileData,
      displayConfig,
      addMapLayerId,
      layerOrder,
      {
        data: geojsonData,
        minVelocity: minVelocity,
        maxVelocity: maxVelocity,
      }
    );
  }

  public static drawVector(
    map: mapboxgl.Map,
    code: string,
    tileData: any,
    displayConfig: any,
    addMapLayerId: any,
    layerOrder: any,
    clusterData: { data: any; minVelocity?: number; maxVelocity?: number }
  ) {
    let product: any = {};
    Object.values(displayConfig).forEach((data: any) => {
      if (data.items[code]) product = data.items[code];
    });
    const layerKey = code + 'vector';
    addMapLayerId('vector', layerKey);
    const currentGeoJson = clusterData.data;
    if (!map?.getSource(layerKey)) {
      map?.addSource(layerKey, {
        type: 'geojson',
        data: currentGeoJson,
        cluster: true,
        clusterMaxZoom: 7,
        clusterRadius: 30,
        clusterProperties: {
          velocity: ['max', ['get', 'velocity']],
          direction: ['min', ['get', 'direction']],
        },
      });

      const imageStyle =
        tileData.style === 'arrow_right_alt' ? 'arrow2' : 'arrow1';
      const color = tileData.color;
      const size = tileData.size;
      if (product.extraConfigs.vectorIcon === 'arrow') {
        this.addCurrentsImageLayer(map, product, layerOrder, {
          imageStyle,
          color,
          size,
          clusterData,
        });
      }
      if (product.extraConfigs.vectorIcon === 'barb') {
        this.addBarbsImageLayer(map, product, layerOrder, {
          color,
          size,
          clusterData,
        });
      }
    }
  }

  public static addCurrentsImageLayer(
    map: Map,
    product: any,
    layerOrder: any,
    currentsData: {
      imageStyle: any;
      color: any;
      size: number;
      clusterData: any;
    }
  ) {
    const layerKey = product.code + 'vector';
    if (map) {
      if (!map?.hasImage(currentsData.imageStyle)) {
        map?.loadImage(
          currentsData.imageStyle === 'arrow1' ? arrow1 : arrow2,
          (error, image) => {
            if (error) throw error;
            image &&
              map?.addImage(currentsData.imageStyle, image, { sdf: true });
          }
        );
      }

      map?.addLayer(
        {
          id: layerKey,
          type: 'symbol',
          source: layerKey,
          layout: {
            'icon-image': currentsData.imageStyle,
            'icon-size': [
              'interpolate',
              ['linear'],
              ['get', 'velocity'],
              currentsData.clusterData.minVelocity,
              0.4 + (currentsData.size / 100) * 1,
              currentsData.clusterData.maxVelocity,
              1 + (currentsData.size / 100) * 5,
            ],
            'icon-rotate': ['get', 'direction'],
            'icon-anchor': 'top',
            'icon-rotation-alignment': 'map',
          },
          paint: {
            'icon-color': currentsData.color,
          },
        },
        layerOrder('vector')
      );
    }
  }

  public static addBarbsImageLayer(
    map: mapboxgl.Map,
    product: any,
    layerOrder: any,
    currentsData: {
      color: string;
      size: number;
      clusterData: {
        data: any;
        minVelocity?: number | undefined;
        maxVelocity?: number | undefined;
      };
    }
  ) {
    const layerKey = product.code + 'vector';
    const barbCutoffs = [
      { image: wind5, id: 'wind5' },
      { image: wind10, id: 'wind10' },
      { image: wind15, id: 'wind15' },
      { image: wind20, id: 'wind20' },
      { image: wind25, id: 'wind25' },
      { image: wind30, id: 'wind30' },
      { image: wind35, id: 'wind35' },
      { image: wind40, id: 'wind40' },
    ];
    barbCutoffs.forEach((barbValue) => {
      if (map) {
        if (!map?.hasImage(barbValue.id)) {
          map?.loadImage(barbValue.image, (error, image) => {
            if (error) throw error;
            image && map?.addImage(barbValue.id, image, { sdf: true });
          });
        }
      }
    });
    if (map) {
      map?.addLayer(
        {
          id: layerKey,
          type: 'symbol',
          source: layerKey,
          layout: {
            'icon-image': [
              'step',
              ['get', 'velocity'],
              'wind5',
              10,
              'wind10',
              15,
              'wind15',
              20,
              'wind20',
              25,
              'wind25',
              30,
              'wind30',
              35,
              'wind35',
              40,
              'wind40',
            ],
            'icon-size': 0.35 + (currentsData.size / 100) * 5,
            'icon-rotate': ['get', 'direction'],
            'icon-anchor': 'top',
            'icon-rotation-alignment': 'map',
          },
          paint: {
            'icon-color': currentsData.color,
          },
        },
        layerOrder('vector')
      );
    }
  }
}
