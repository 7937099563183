import { Box, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import _, { floor, isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../../../../translations/I18nKey';
import AutoComplete from '../../../../../components/AutoComplete/AutoComplete';
import { Stepper } from '../../../../../components/Stepper/Stepper';
import { AISStyles } from '../AISnVMS/AISnVMSStyles';
import { IUU_SETTINGS_AIS_VMS_COLUMNS } from '../../config';

const precision = 1;

export const GeofenceAISnVMS: React.FC<any> = ({
  data,
  displaySectionsConfig,
  geofenceWatchlist,
  setGeofenceWatchlist,
  type,
}) => {
  const intl = useIntl();
  const [filteredData, setFilteredData] = useState<any>(data);
  const [localVesselType, setLocalVesselType] = useState<any>([]);
  const [localVesselName, setLocalVesselName] = useState<any>([]);
  const [localCountry, setLocalCountry] = useState<any>([]);
  const [localMMSI, setLocalMMSI] = useState<any>([]);
  const [filteredTypeCountryData, setFilteredTypeCountryData] =
    useState<any>(data);

  useEffect(() => {
    setLocalVesselType(geofenceWatchlist[type].selectedVesselType);
    setLocalVesselName(geofenceWatchlist[type].selectedVesselName);
    setLocalCountry(geofenceWatchlist[type].selectedCountryName);
    setLocalMMSI(geofenceWatchlist[type].selectedMMSI);
    setFilteredTypeCountryData(filteredData);
  }, []);

  const onVesselTypeChange = (e: any) => {
    let filteredVessels = _.filter(data, (vessel: any) => {
      return _.map(e, 'vesselType').includes(vessel.vesselType);
    });

    // To show all vessels when nothing is selected in VesselType
    if (isEmpty(filteredVessels)) filteredVessels = data;

    setFilteredData(filteredVessels);
    setLocalVesselType(e);
    setFilteredTypeCountryData(filteredVessels);

    const updatedVesselCountries = _.filter(localCountry, (name: any) => {
      return _.map(e, 'vesselType').includes(name.vesselType);
    });
    setLocalCountry(updatedVesselCountries);

    const updatedVesselNames = _.filter(localVesselName, (name: any) => {
      return _.map(e, 'vesselType').includes(name.vesselType);
    });
    setLocalVesselName(updatedVesselNames);

    const updatedMMSI = _.filter(localMMSI, (item: any) => {
      return _.map(e, 'vesselType').includes(item.vesselType);
    });
    setLocalMMSI(updatedMMSI);

    setGeofenceWatchlist({
      ...geofenceWatchlist,
      [type]: {
        ...geofenceWatchlist[type],
        selectedVesselType: e,
      },
    });
  };

  const onCountryNameChange = (e: any) => {
    let filteredVesselsByType = _.filter(data, (vessel: any) => {
      return _.map(localVesselType, 'vesselType').includes(vessel.vesselType);
    });
    if (localVesselType.length === 0) filteredVesselsByType = data;

    let filteredVesselsByCountry = filteredVesselsByType;
    if (e.length) {
      filteredVesselsByCountry = _.filter(
        filteredVesselsByType,
        (vessel: any) => {
          return _.map(e, 'flagCountry').includes(vessel.flagCountry);
        }
      );
    }

    setFilteredData(filteredVesselsByCountry);
    setLocalCountry(e);

    const updatedVesselNames = _.filter(localVesselName, (name: any) => {
      return _.map(e, 'flagCountry').includes(name.flagCountry);
    });
    setLocalVesselName(updatedVesselNames);

    const updatedMMSI = _.filter(localMMSI, (item: any) => {
      return _.map(e, 'flagCountry').includes(item.flagCountry);
    });
    setLocalMMSI(updatedMMSI);
    setGeofenceWatchlist({
      ...geofenceWatchlist,
      [type]: {
        ...geofenceWatchlist[type],
        selectedCountryName: e,
      },
    });
  };

  const onVesselNameChange = (e: any) => {
    setLocalVesselName(e);
    setGeofenceWatchlist({
      ...geofenceWatchlist,
      [type]: {
        ...geofenceWatchlist[type],
        selectedVesselName: e,
      },
    });
  };

  const onMMSIChange = (e: any) => {
    setLocalMMSI(e);
    setGeofenceWatchlist({
      ...geofenceWatchlist,
      [type]: {
        ...geofenceWatchlist[type],
        selectedMMSI: e,
      },
    });
  };

  const onFromSpeedChange = (event: any) => {
    setGeofenceWatchlist({
      ...geofenceWatchlist,
      [type]: {
        ...geofenceWatchlist[type],
        speed: {
          ...geofenceWatchlist[type].speed,
          fromSpeed: floor(event.target.value, precision),
        },
      },
    });
  };

  const onToSpeedChange = (event: any) => {
    setGeofenceWatchlist({
      ...geofenceWatchlist,
      [type]: {
        ...geofenceWatchlist[type],
        speed: {
          ...geofenceWatchlist[type].speed,
          toSpeed: floor(event.target.value, precision),
        },
      },
    });
  };

  const getUniqueData = (data: any, type: string) => {
    return _.uniqBy(data, type);
  };

  return (
    <Box
      sx={{
        mr: 1,
        '& .chipWrapper': {
          marginLeft: 0,
        },
        '& .MuiAutocomplete-root': {
          marginLeft: '-10px',
        },
      }}
    >
      {displaySectionsConfig.showVesselTypeAutocomplete && (
        <>
          <Divider
            sx={{ borderColor: '#333333', mt: 2, mb: -2 }}
            flexItem
          ></Divider>
          <Box>
            <AutoComplete
              key={IUU_SETTINGS_AIS_VMS_COLUMNS.vesselTypeColumnConfig[0].name}
              data={getUniqueData(
                data,
                IUU_SETTINGS_AIS_VMS_COLUMNS.vesselTypeColumnConfig[0].name
              )}
              style={AISStyles.autocompleteStyle}
              placeholder={intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_VESSEL_TYPE_PLACEHOLDER,
              })}
              columnConfig={IUU_SETTINGS_AIS_VMS_COLUMNS.vesselTypeColumnConfig}
              onSelectionChange={onVesselTypeChange}
              showSelectAllToggle={false}
              selectionData={localVesselType}
            />
          </Box>
        </>
      )}
      {displaySectionsConfig.showCountryNameAutocomplete && (
        <>
          <Divider
            sx={{ borderColor: '#333333', mt: 2, mb: -2 }}
            flexItem
          ></Divider>
          <Box>
            <AutoComplete
              key={IUU_SETTINGS_AIS_VMS_COLUMNS.countryColumnConfig[0].name}
              data={getUniqueData(
                filteredTypeCountryData,
                IUU_SETTINGS_AIS_VMS_COLUMNS.countryColumnConfig[0].name
              )}
              style={AISStyles.autocompleteStyle}
              placeholder={intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_COUNTRY_PLACEHOLDER,
              })}
              columnConfig={IUU_SETTINGS_AIS_VMS_COLUMNS.countryColumnConfig}
              onSelectionChange={onCountryNameChange}
              showSelectAllToggle={false}
              selectionData={localCountry}
            />
          </Box>
        </>
      )}
      {displaySectionsConfig.showVesselNameAutocomplete && (
        <>
          <Divider
            sx={{ borderColor: '#333333', mt: 2, mb: -2 }}
            flexItem
          ></Divider>
          <Box>
            <AutoComplete
              key={IUU_SETTINGS_AIS_VMS_COLUMNS.vesselNameColumnConfig[0].name}
              data={getUniqueData(
                filteredData,
                IUU_SETTINGS_AIS_VMS_COLUMNS.vesselNameColumnConfig[0].name
              )}
              style={AISStyles.autocompleteStyle}
              placeholder={intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_VESSEL_NAME_PLACEHOLDER,
              })}
              columnConfig={IUU_SETTINGS_AIS_VMS_COLUMNS.vesselNameColumnConfig}
              onSelectionChange={onVesselNameChange}
              showSelectAllToggle={false}
              selectionData={localVesselName}
            />
          </Box>
        </>
      )}
      {displaySectionsConfig.showMMSIAutocomplete && (
        <>
          <Divider
            sx={{ borderColor: '#333333', mt: 2, mb: -2 }}
            flexItem
          ></Divider>
          <Box>
            <AutoComplete
              key={IUU_SETTINGS_AIS_VMS_COLUMNS.MMSIColumnConfig[0].name}
              data={getUniqueData(
                filteredData,
                IUU_SETTINGS_AIS_VMS_COLUMNS.MMSIColumnConfig[0].name
              )}
              style={AISStyles.autocompleteStyle}
              placeholder={intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_MMSI_PLACEHOLDER,
              })}
              columnConfig={IUU_SETTINGS_AIS_VMS_COLUMNS.MMSIColumnConfig}
              onSelectionChange={onMMSIChange}
              showSelectAllToggle={false}
              selectionData={localMMSI}
              multiValueSeparator=" "
            />
          </Box>
        </>
      )}
      {displaySectionsConfig.showSpeed && (
        <>
          <Divider sx={{ borderColor: '#333333', mt: 2 }} flexItem></Divider>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              color: '#FFF',
              mt: 2,
            }}
          >
            <Typography sx={{ mb: 1 }}>
              {intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_SPEED,
              })}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ mr: 2 }}>
                {intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_FROM_TO,
                })}
              </Typography>
              <Box sx={{ width: '12%', mr: 1 }}>
                <Stepper
                  value={geofenceWatchlist[type].speed.fromSpeed}
                  key="from"
                  onChange={onFromSpeedChange}
                />
              </Box>
              <Typography>-</Typography>
              <Box
                sx={{
                  width: '12%',
                  ml: 1,
                  mr: 2,
                  backgroundColor: '#242424',
                }}
              >
                <Stepper
                  value={geofenceWatchlist[type].speed.toSpeed}
                  key="to"
                  onChange={onToSpeedChange}
                />
              </Box>
              <Typography>
                {intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_KNOTS,
                })}
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
