import axios, { AxiosRequestConfig } from 'axios';
import { DataFilesList, UserSettings, RegionsList } from '../containers/types';

const SERVER_BASE_PATH = process.env.REACT_APP_PRODUCT_SERVER;

export const PATHS = Object.freeze({
  GET_DATA_FILES_LIST: (userName: string) =>
    `products/getDataFilesList/${userName}`,
  GET_REGIONS_LIST: `regions/get`,
  GET_USER_SETTINGS: (userName: string) => `userSettings/get/${userName}`,
  SAVE_USER_SETTINGS: (userName: string) => `userSettings/save/${userName}`,
  GET_PRODUCT_UNITS: (userName: string) => `products/units/get/${userName}`,
  GET_LICENSED_FEATURES: (userName: string) => `features/get/${userName}`,
});

const getDataFilesList = async (
  userName: string,
  seastarId: string
): Promise<DataFilesList[]> => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: { seastar_id: seastarId },
    };
    const { data: dataFilesList } = await axios.get<DataFilesList[]>(
      SERVER_BASE_PATH + PATHS.GET_DATA_FILES_LIST(userName),
      config
    );

    return dataFilesList;
  } catch (error) {
    throw error;
  }
};

const getRegionsList = async (): Promise<RegionsList[]> => {
  try {
    const { data: RegionsList } = await axios.get<RegionsList[]>(
      SERVER_BASE_PATH + PATHS.GET_REGIONS_LIST
    );
    return RegionsList;
  } catch (error) {
    throw error;
  }
};

const getUserSettings = async (
  userName: string,
  seastarId: string
): Promise<UserSettings> => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: { seastar_id: seastarId },
    };
    const { data: userSettings } = await axios.get<UserSettings>(
      SERVER_BASE_PATH + PATHS.GET_USER_SETTINGS(userName),
      config
    );
    return userSettings;
  } catch (error) {
    throw error;
  }
};

const saveUserSettings = async (userName: string, settings: UserSettings) => {
  try {
    const { data: userSettings } = await axios.post<UserSettings>(
      SERVER_BASE_PATH + PATHS.SAVE_USER_SETTINGS(userName),
      settings
    );
    return userSettings;
  } catch (error) {
    throw error;
  }
};

const getProductUnits = async (
  userName: string,
  seastarId: string
): Promise<any> => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: { seastar_id: seastarId },
    };
    const { data: ProductUnits } = await axios.get<any>(
      SERVER_BASE_PATH + PATHS.GET_PRODUCT_UNITS(userName),
      config
    );

    return ProductUnits;
  } catch (error) {
    throw error;
  }
};

const getLicensedFeatures = async (
  userName: string,
  seastarId: string
): Promise<any> => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: { seastar_id: seastarId },
    };
    const { data: Features } = await axios.get<any>(
      SERVER_BASE_PATH + PATHS.GET_LICENSED_FEATURES(userName),
      config
    );

    return Features;
  } catch (error) {
    throw error;
  }
};

export interface UserAPIClient {
  readonly getDataFilesList: (
    userName: string,
    seastarId: string
  ) => Promise<DataFilesList[]>;
  readonly getRegionsList: () => Promise<RegionsList[]>;
  readonly getUserSettings: (
    userName: string,
    seastarId: string
  ) => Promise<UserSettings>;
  readonly saveUserSettings: (
    userName: string,
    settings: UserSettings
  ) => Promise<UserSettings>;
  readonly getProductUnits: (
    userName: string,
    seastarId: string
  ) => Promise<any>;
  readonly getLicensedFeatures: (
    userName: string,
    seastarId: string
  ) => Promise<any>;
}

const UserClient: UserAPIClient = Object.freeze({
  getDataFilesList,
  getRegionsList,
  getUserSettings,
  saveUserSettings,
  getProductUnits,
  getLicensedFeatures,
});

export default UserClient;
