export const stepperStyles = {
  root: {
    width: '100%',
    backgroundColor: '#121212',
    '& .MuiInputBase-root': {
      height: 25,
      '& input': {
        color: '#FFFFFF',
        textAlign: 'left',
        fontSize: 14,
        paddingLeft: 5,
        paddingRight: 1,
      },
    },
  },
};
