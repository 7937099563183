import { FC, useState, useEffect, useMemo } from 'react';
import { Box, IconButton } from '@mui/material';
import { useQuery } from 'react-query';
import FishCatchClient from '../../../api/fishCatchAPIs';
import { VariantType, closeSnackbar, useSnackbar } from 'notistack';
import { getUserAttributes } from '../../../utils/auth';
import { useIntl } from 'react-intl';
import { recordDataColumns } from '../DashboardConfig';
import CloseIcon from '@mui/icons-material/Close';
import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './../../../components/DateRangePicker/Styles/DatePickerStyles.scss';
import FishCatchLoadingList from './FishCatchLoadingList';
import FishCatchErrorList from './FishCatchErrorList';
import { useFetchFishCatch } from '../store/FishCatchStore';

const FishCatchRecordsList: FC<any> = () => {
  const intl = useIntl();
  const { userName } = getUserAttributes();
  const [fcRecords, setFcRecords] = useState<any[]>();
  const reFetch = useFetchFishCatch((state) => state.fetchFiles);
  const setReFetch = useFetchFishCatch((state) => state.setReFetchFiles);
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => recordDataColumns(intl),
    []
  );
  const table = useMaterialReactTable({
    data: fcRecords?.length ? fcRecords : [],
    columns,
    enableStickyHeader: true,
    enableStickyFooter: true,
    muiTableHeadCellProps: {
      sx: {
        fontWeight: 700,
        fontSize: '16px',
      },
    },
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100, 200],
    },
    enableColumnResizing: true,
    initialState: {
      showColumnFilters: true,
      pagination: { pageSize: 25, pageIndex: 0 },
    },
  });

  // --- Toast Message ---
  const { enqueueSnackbar } = useSnackbar();

  // --- Fetch Data Function ---
  const getFishCatchRecords = async () => {
    const response = await FishCatchClient.getFishCatchData(userName);
    return response.map((s: any) => {
      return { ...s, id: s.uuid };
    });
  };

  const action = (snackbarId: any) => {
    return (
      <IconButton
        aria-label="close-toast"
        onClick={() => closeSnackbar(snackbarId)}
      >
        <CloseIcon />
      </IconButton>
    );
  };
  // --- Call Toast ---
  const callToast = (variant: VariantType, message: any) => {
    enqueueSnackbar(message, {
      variant,
      action,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
  };

  const {
    isLoading,
    isError,
    error,
    isFetching,
    refetch: FishCatchRecords,
  } = useQuery({
    queryFn: getFishCatchRecords,
    enabled: false,
    onSuccess(data) {
      setFcRecords(data);
    },
  });

  useEffect(() => {
    FishCatchRecords();
  }, []);

  useEffect(() => {
    if (reFetch) {
      FishCatchRecords();
      setReFetch();
    }
  }, [reFetch]);

  if (isLoading || isFetching) {
    return <FishCatchLoadingList option1={true} option2={false} />;
  }

  if (isError) {
    if (error instanceof Error) {
      callToast('error', error.message);
    }

    return <FishCatchErrorList reFetchData={setReFetch} />;
  }

  return (
    <>
      <Box
        sx={{
          height: 600,
          width: '100%',
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: 'rgba(36, 36, 36, 1)',
            color: '#FFFFFF',
          },
          '& .MuiDataGrid-iconButtonContainer': {
            color: '#FFFFFF',
          },
          '& .MuiSvgIcon-root': {
            fill: '#B3B5B8',
          },
          '& .MuiButton-textPrimary': {
            color: '#000000',
            fontWeight: 'bold',
          },
          '& .MuiDateCalendar-root': {
            backgroundColor: 'black',
            color: 'white !important',
          },
          '& .MuiTypography-root-MuiDayCalendar-weekDayLabel': {
            color: '#ccc !important',
          },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MaterialReactTable table={table} />
        </LocalizationProvider>
      </Box>
    </>
  );
};

export default FishCatchRecordsList;
