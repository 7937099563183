import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { useStyles } from '../../utils/util';
import { LegendWrapperProps } from './LegendWrappertypes';
import settingsIcon from '../../assets/icons/setting.svg';
import closeIcon from '../../assets/icons/close.svg';
import { legendWrapperStyles } from './LegendWrapperStyles';

export const LegendWrapper: React.FC<LegendWrapperProps> = ({
  title,
  subTitle,
  titleElement,
  wrapperStyle,
  hasSettingsButton,
  hasCloseButton,
  leftElement,
  leftElementStyle,
  rightElement,
  rightElementStyle,
  onSettingsClick,
  showSeparator,
  separatorStyle,
  onCloseClick,
}) => {
  const classes = useStyles(legendWrapperStyles, [
    wrapperStyle,
    leftElementStyle,
    rightElementStyle,
    separatorStyle,
  ])();

  return (
    <Box
      sx={{
        display: 'flex',
        gridTemplateColumns: 'repeat(2, 1fr)',
      }}
      className={classes.wrapper}
    >
      {hasSettingsButton && (
        <img
          src={settingsIcon}
          alt=""
          className={classes.settingsIcon}
          onClick={onSettingsClick}
        />
      )}
      {leftElement && (
        <Box className={classes.leftElement}>
          <Grid container>
            <Grid item>
              <Stack
                direction="row"
                alignItems="baseline"
                className={classes.headingBox}
              >
                <Typography className={classes.title}>{title}</Typography>
                <Typography className={classes.subtitle}>{subTitle}</Typography>
              </Stack>
            </Grid>
            <Grid item xs>
              <Grid container direction="row-reverse">
                <Box>{titleElement}</Box>
              </Grid>
            </Grid>
          </Grid>

          <Box sx={legendWrapperStyles.leftElementBox}>{leftElement}</Box>
        </Box>
      )}
      {showSeparator && (
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={legendWrapperStyles.separatorStyle}
        ></Divider>
      )}
      {rightElement && (
        <Box className={classes.rightElement}>{rightElement}</Box>
      )}
      {hasCloseButton && (
        <img
          src={closeIcon}
          alt=""
          className={classes.closeIcon}
          onClick={onCloseClick}
        />
      )}
    </Box>
  );
};

LegendWrapper.defaultProps = {
  title: '',
  subTitle: '',
  hasSettingsButton: true,
  hasCloseButton: true,
  onSettingsClick: () => {},
  onCloseClick: () => {},
};
