import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import collapse from '../../assets/icons/collapse.svg';
import expand from '../../assets/icons/expand.svg';
import { theme } from '../../theme';

export const FishCatchDashboardStyles: any = {
  centerElement: {
    centerElement: {
      fontWeight: 'normal',
      color: theme.palette.appBackground?.darkest,
      height: '38px',
      bottom: ' 0',
      position: 'fixed',
      borderTop: '1px solid ' + theme.palette.appBackground?.lighter,
      paddingTop: '3px',
      display: 'initial',
      marginLeft: '-0px !important',
    },
  },
  item: {
    backgroundColor: theme.palette.secondaryHeader?.darker,
    padding: 8,
    textAlign: 'center',
    color: theme.palette.appBackground?.lightest,
    width: '166px',
  },
  mappingSelect: {
    color: theme.palette.appBackground?.lightest,
    width: '211px',
    height: '51px',
    marginTop: '2px',
  },
  footer: {
    footer: {
      borderTop: '1px solid ' + theme.palette.appBackground?.lighter,
      display: 'flex',
      width: '100%',
      backgroundColor: theme.palette.appBackground?.lightest,
      marginBottom: '0px',
    },
  },
  section: {
    section: {
      padding: '8px',
      paddingTop: '7px',
      paddingLeft: '14px',
      display: 'flex',
      backgroundColor: theme.palette.appBackground?.lightest,
      color: theme.palette.appBackground?.darkest,
      opacity: '0.88',
      borderRadius: 'none',
      paddingBottom: '5px',
    },
  },
  datePickerLabelExtraStyles: {
    borderRadius: '9px',
    boxShadow: '0px 3px 6px ' + theme.palette.shadow?.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'space-between',
    padding: '8px',
  },
  autocompleteStyle: {
    width: '235px',
    backgroundColor: theme.palette.appBackground?.darkest,
  },
  bottomBoxStyle: {
    position: 'absolute',
    bottom: 0,
    fontSize: '18px',
    paddingLeft: '28px',
  },
  applyButtonStyle: {
    backgroundColor: theme.palette.primary?.main,
    color: theme.palette.appBackground?.darkest,
    height: '50px',
    width: '220px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.palette.primary?.main,
      color: theme.palette.appBackground?.darkest,
      height: '50px',
      width: '220px',
      cursor: 'pointer',
    },
  },
  resetBoxStyle: {
    paddingLeft: '30px',
    backgroundColor: 'none',
    color: theme.palette.text?.light,
  },
  btnResizeCollapse: {
    position: 'absolute',
    width: '27px',
    height: '27px',
    right: '20px',
    color: theme.palette.appBackground?.lightest,
    top: '98px',
    zIndex: 1,
    backgroundImage: 'url(' + collapse + ')',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  btnResizeExpand: {
    position: 'absolute',
    width: '27px',
    height: '27px',
    right: '20px',
    top: '98px',
    color: theme.palette.appBackground?.lightest,
    zIndex: 1,
    backgroundImage: 'url(' + expand + ')',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
};

export const useFishCatchDashboardStyles = makeStyles((theme: Theme) => {
  return {
    centerElement: {
      fontWeight: 'normal',
      color: theme.palette.appBackground?.darkest,
      height: '38px',
      bottom: ' 0',
      position: 'fixed',
      borderTop: '1px solid ' + theme.palette.appBackground?.lighter,
      paddingTop: '3px',
      display: 'initial',
      marginLeft: '-0px !important',
    },
    importPopUp: {
      border: 1,
      borderRadius: '19px',
      color: theme.palette.text?.lightest,
      zIndex: 6,
      position: 'absolute',
      background: theme.palette.appBackground?.darkest,
      width: '568px',
      right: '0px',
      top: '9%',
      padding: 0,
    },
    csvReader: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 10,
      marginTop: 20,
    },
    acceptedFile: {
      border: '1px solid ' + theme.palette.primary?.light,
      height: 50,
      lineHeight: 3,
      paddingLeft: 10,
      width: '90%',
      marginLeft: '0',
      borderRadius: '15px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    placeholderText: {
      lineHeight: 3,
      color: '#505050',
    },
    progressBarBackgroundColor: {
      backgroundColor: theme.palette.primary?.light + '!important',
    },
    mapperWrapper: {
      width: '100%',
      marginTop: '16px',
      height: '450px',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    alignCenter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    mapperLabel: {
      width: '200px !important',
      padding: '15px !important',
      fontSize: theme.typography.fontSize,
      bordeRadius: '10px',
    },
    popUpHeader: {
      color: theme.palette.appBackground?.lightest,
      float: 'left',
      marginLeft: '10px',
      fontSize: '18px',
      marginTop: '7px',
    },
    closePopUp: {
      float: 'right',
      backgroundColor: theme.palette.appBackground?.darkest,
      color: theme.palette.appBackground?.lightest,
      border: 'none',
      marginTop: '7px',
      marginBottom: '5px',
      cursor: 'pointer',
    },
    popUpHeaderTitle: { height: '40px' },
    closePopUpImage: {
      width: '24px',
      height: '24px',
      marginTop: '-1px',
      marginRight: '-12px',
    },
    popUpLineBreak: {
      height: '1px',
      backgroundColor: theme.palette.popUpBackground?.main,
      border: 'none',
      marginBottom: '10px',
    },
    popUpBodyWrapper: { marginBottom: '16px', padding: '10px' },
    popUpBody: { width: '100%' },
    stepperAlignment: { width: '168%', marginLeft: '-34%' },
    popUpStepLabel: { color: theme.palette.appBackground?.lightest },
    popUpFooterWrapper: {
      height: '65px',
      backgroundColor: theme.palette.popUpBackground?.light,
      width: '100%',
      borderRadius: '0px 0px 12px 12px',
    },
    popUpFloatRight: {
      float: 'right',
      marginRight: '10px',
    },
    popUpWarningColor: {
      background: theme.palette.warning.light,
      '&:hover': {
        background: theme.palette.warning.light,
      },
    },
    popUpFloatLeft: {
      float: 'left',
      marginLeft: '10px',
    },
    popUpPrevBtn: {
      color: theme.palette.text?.light,
      cursor: 'pointer',
      marginRight: '10px',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    closeIcon: {
      width: '35px',
      height: '35px',
      position: 'absolute',
      right: '-15px',
      top: '-15px',
      cursor: 'pointer',
    },
    item: {
      backgroundColor: theme.palette.secondaryHeader?.darker,
      padding: 8,
      textAlign: 'center',
      color: theme.palette.appBackground?.lightest,
      width: '166px',
    },
    mappingSelect: {
      color: theme.palette.appBackground?.lightest,
      width: '211px',
      height: '51px',
      marginTop: '2px',
    },
    footer: {
      borderTop: '1px solid ' + theme.palette.appBackground?.lighter,
      display: 'flex',
      width: '100%',
      backgroundColor: theme.palette.appBackground?.lightest,
      marginBottom: '0px',
    },
    section: {
      padding: '8px',
      paddingTop: '7px',
      paddingLeft: '14px',
      display: 'flex',
      backgroundColor: theme.palette.appBackground?.lightest,
      color: theme.palette.appBackground?.darkest,
      opacity: '0.88',
      borderRadius: 'none',
      paddingBottom: '5px',
    },
    summaryDetailsContainer: {
      display: 'flex',
      height: '200px',
      borderRadius: '9px',
      width: '91%',
      margin: 'auto',
      marginTop: '-75px',
      marginBottom: '30px',
      boxShadow: '0px -5px 23px #00000069',
    },
    summaryDetailsCardContainer: {
      padding: '24px 10px',
      height: '100%',
    },
    summaryDetailsCardHeader: {
      display: 'flex',
      height: '38px',
    },
    summaryDetailsCardName: {
      maxWidth: '120px',
      marginLeft: '8px',
      color: theme.palette.popUpBackground?.main,
      fontWeight: '300',
    },
    summaryDetailsCardMetricTitle: { color: theme.palette.text?.midDark },
    summaryDetailsCardDetails: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      rowGap: '8px',
      columnGap: '12px',
      marginTop: '24px',
      color: theme.palette.text?.midDark,
      fontSize: '14px',
    },
    summaryBoxStyle: {
      height: '260px',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    summaryContainerStyle: {
      display: 'flex',
      width: '60%',
      justifyContent: 'space-around',
      borderRadius: 1,
      paddingTop: '52px',
      height: '97px',
      alignItems: 'center',
    },
    summaryLeftContainerStyle: {
      width: '50%',
      borderRight: '2px solid ' + theme.palette.appBackground?.lightest,
      paddingLeft: '56px',
    },
    summaryLeftBoxStyle: {
      display: 'flex',
      width: '100%',
      borderRadius: 1,
      height: '97px',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
    },
    summaryLeftElementStyle: {
      width: '56px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    summaryRightElementStyle: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '133px',
      borderRadius: 1,
      paddingLeft: '36px',
    },
    summaryTopTextStyle: {
      fontSize: '20px',
      color: theme.palette.appBackground?.lightest,
    },
    summaryBottomTextStyle: {
      fontSize: '45px',
      color: theme.palette.appBackground?.lightest,
    },
    summaryCenterContainerStyle: {
      width: '50%',
      paddingLeft: '56px',
    },
    summaryCenterElementStyle: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      borderRadius: 1,
      paddingLeft: '36px',
    },
    summaryCenterBoxStyle: {
      flexDirection: 'column',
      height: '100%',
      width: '320px',
      borderRadius: 1,
      paddingLeft: '36px',
    },
    dateRangePickerStyles: {
      color: theme.palette.appBackground?.darkest,
      paddingTop: '17px',
      position: 'relative',
      right: '17px',
    },
    datePickerLabelExtraStyles: {
      borderRadius: '9px',
      boxShadow: '0px 3px 6px ' + theme.palette.shadow?.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'space-between',
      padding: '8px',
    },
    chartsContainer: {
      backgroundColor: theme.palette.appBackground?.lightest,
      boxShadow: '0px 3px 6px ' + theme.palette.shadow?.main,
    },
    chartsHeader: {
      borderBottom: '1px solid #D3D0D0',
      fontSize: '20px',
      height: '74px',
      padding: 0,
      paddingLeft: '25px',
    },
    filterContainerStyle: {
      position: 'absolute',
      top: '115px',
      right: '0px',
      width: '171px',
      height: '45px',
      background: theme.palette.popUpBackground?.main,
      borderRadius: '9px 0px 0px 9px',
      padding: '0px',
      textAlign: 'left',
      justifyContent: 'left',
      '&:hover': {
        background: theme.palette.popUpBackground?.main,
      },
      zIndex: '5',
    },
    filterCloseText: {
      paddingLeft: '23px',
      marginRight: '47px',
    },
    filterBoxStyle: {
      backgroundColor: theme.palette.popUpBackground?.main,
      color: theme.palette.appBackground?.lightest,
      height: '100%',
      boxShadow: '-3px 0px 6px ' + theme.palette.shadow?.main,
      width: '275px',
      display: 'flex',
      flexDirection: 'column',
    },
    filterDrawerStyle: {
      backgroundColor: theme.palette.popUpBackground?.main,
      color: theme.palette.appBackground?.lightest,
    },
    filterHeadingStyle: {
      color: theme.palette.appBackground?.lightest,
      paddingLeft: '19px',
      fontSize: '20px',
      marginRight: '113px',
    },
    autocompleteStyle: {
      width: '235px',
      backgroundColor: theme.palette.appBackground?.darkest,
    },
    bottomBoxStyle: {
      position: 'absolute',
      bottom: 0,
      fontSize: '18px',
      paddingLeft: '28px',
    },
    applyButtonStyle: {
      backgroundColor: theme.palette.primary?.main,
      color: theme.palette.appBackground?.darkest,
      height: '50px',
      width: '220px',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: theme.palette.primary?.main,
        color: theme.palette.appBackground?.darkest,
        height: '50px',
        width: '220px',
        cursor: 'pointer',
      },
    },
    resetBoxStyle: {
      paddingLeft: '30px',
      backgroundColor: 'none',
      color: theme.palette.text?.light,
    },
    mapsContainerNotExpand: {
      height: '540px',
      marginBottom: '60px',
      boxShadow: '0px 3px 6px ' + theme.palette.shadow?.main,
    },
    mapsContainerExpand: {
      height: '700px',
      marginBottom: '60px',
      boxShadow: '0px 3px 6px ' + theme.palette.shadow?.main,
    },
    mapsSelectButton: {
      display: 'flex',
      justifyContent: 'space-between',
      boxShadow: '0px 3px 6px ' + theme.palette.shadow?.main,
      backgroundColor: theme.palette.appBackground?.lightest,
      color: theme.palette.appBackground?.darkest,
      borderRadius: '9px',
      paddingLeft: '16px',
      paddingRight: '16px',
      marginBottom: '8px',
      marginTop: '8px',
      textTransform: 'capitalize',
    },
    mapsSelectMap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
    },
    mapsSelectMenu: {
      position: 'absolute',
      right: '0px',
      zIndex: '1',
      backgroundColor: theme.palette.appBackground?.lightest,
      borderRadius: '4px',
      padding: '8px',
      boxShadow: '0px 3px 6px ' + theme.palette.shadow?.main,
    },

    btnResizeCollapse: {
      position: 'absolute',
      width: '27px',
      height: '27px',
      right: '20px',
      color: theme.palette.appBackground?.lightest,
      top: '98px',
      zIndex: 1,
      backgroundImage: 'url(' + collapse + ')',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    btnResizeExpand: {
      position: 'absolute',
      width: '27px',
      height: '27px',
      right: '20px',
      top: '98px',
      color: theme.palette.appBackground?.lightest,
      zIndex: 1,
      backgroundImage: 'url(' + expand + ')',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    'summary-details--svg': {
      '& svg': {
        '& path': {
          fill: theme.palette.secondaryHeader?.main,
        },
        '& g': {
          '& path': {
            fill: theme.palette.secondaryHeader?.main,
          },
        },
      },
    },
    'summary-details--metrics': {
      color: theme.palette.text.darkest,
      fontSize: theme.typography.fontSize,
      fontWeight: '450',
      margin: '0',
      padding: '0',
      paddingTop: '4px',
    },
    'active-step-label': {
      '& .Mui-active': {
        color: theme.palette.text.lightest + ' !important',
      },
      '& svg': {
        backgroundColor: theme.palette.primary.light,
        padding: '7px',
        borderRadius: '50%',
        marginTop: '-8px',
      },
    },
    'upload-csv-action-btn': {
      paddingRight: '0px !important',
      '& svg': {
        backgroundColor: 'transparent',
        padding: '7px',
        fontSize: '35px',
        marginTop: '-8px',
        color: theme.palette.primary.light,
        border: '1px solid ' + theme.palette.primary.light,
        borderRadius: '15px',
      },
    },
    'fishcatch__logout-icon': {
      paddingLeft: '3px',
      marginRight: '7px',
    },
    'fishcatch__mapper__required-label': {
      '&::after': { content: '" *"', color: theme.palette.status?.required },
    },
    'apply-btn-wrapper': {
      '& button.Mui-disabled': { background: theme.palette.primary.disabled },
    },
  };
});

export const useFishCatchListStyles = makeStyles((theme: Theme) => {
  return {
    listBoxContainer: {
      background: '#FFFFFF',
      border: '1px solid rgba(0, 0, 0, 0.25)',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      borderRadius: 2,
    },
    summaryContainerStyle: {
      padding: '4%',
    },
    summaryTopTextStyle: {
      fontSize: '25px',
      color: '#000000',
      paddingBottom: '15px',
    },
    headerTableList: {
      display: 'flex',
      flexDirection: 'row',
      margin: '10px',
    },
    boxBtnArrow: {
      background: '#D9E8F6',
      padding: '2px 1px 1px 10px',
      marginBottom: '15px',
      borderRadius: 4,
    },
    stackContainerBox: {
      display: 'flex',
      flexDirection: 'column',
    },
    toolbarContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    showInformationFile: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
    },
    dividerTable: {
      paddingTop: '10px',
      paddingBottom: '2px',
    },
    headerTableVersion: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      background: '#193768',
      alignItems: 'center',
      height: '45px',
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    titleHeader: {
      color: '#FFFFFF',
      fontSize: '20px',
    },
    messageRecordNoAvailable: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      border: '1px solid rgba(0, 0, 0, 0.25)',
      alignItems: 'center',
      margin: '10px',
      borderRadius: '10px',
      fontSize: '20px',
      padding: '25px',
      background: '#D9E8F6',
    },
  };
});
