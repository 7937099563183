/* eslint-disable react-hooks/exhaustive-deps */
import _, { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { createContext, useContextSelector } from 'use-context-selector';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { I18nKey } from '../../translations/I18nKey';
import UserClient from '../../api/userAPIs';
import Snackbar from '../../components/Snackbar/Snackbar';
import { getUserAttributes } from '../../utils/auth';
import { UserSettings } from '../types';
import { LanguageContext } from './LanguageChange';

export interface SettingsDetails {
  userSettings: UserSettings;
  saveStatus: saveSettingsStatus;
  setSaveStatus: any;
  setUserSettings: any;
  getUserSettings: any;
  saveUserSettings: any;
  closeProductsOnUnitChange: any;
  setCloseProductsOnUnitChange: any;
}

export enum saveSettingsStatus {
  success = 'success',
  error = 'error',
  idle = '',
}

export const UserSettingsContext = createContext<SettingsDetails>({
  userSettings: {},
  saveStatus: saveSettingsStatus.idle,
  setSaveStatus: () => {},
  setUserSettings: () => {},
  getUserSettings: () => {},
  saveUserSettings: () => {},
  closeProductsOnUnitChange: false,
  setCloseProductsOnUnitChange: () => {},
});

export const UserSettingsContextProvider: React.FC = (props) => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  let userData = getUserAttributes();
  const [userSettings, setUserSettings] = useState<UserSettings>({});
  const [saveStatus, setSaveStatus] = useState(saveSettingsStatus.idle);
  const [closeProductsOnUnitChange, setCloseProductsOnUnitChange] =
    useState<boolean>(false);
  const [alert, setAlert] = useState({
    type: '',
    display: false,
    message: '',
  });
  const setLanguage = useContextSelector(
    LanguageContext,
    (state) => state
  ).setLanguage;

  const getUserSettings = async () =>
    await UserClient.getUserSettings(userData.userName, userData.seastarId);

  const { refetch: userSettingsData } = useQuery<UserSettings>(
    'UserSettings',
    getUserSettings,
    {
      retry: false,
      enabled: false,
      onSuccess: (data: UserSettings) => {
        if (!isNil(data)) {
          data = updateVisibilityData(data);
          setUserSettings(data);
          setLanguage(data.general?.languageCode);
        }
      },
      onError: (error: any) => {
        setAlert({
          type: 'error',
          display: true,
          message: intl.formatMessage({
            id: I18nKey.ERROR_MESSAGE_GET,
          }),
        });
        setTimeout(() => {
          setAlert({
            type: '',
            display: false,
            message: '',
          });
        }, 5000);
      },
    }
  );

  useEffect(() => {
    userSettingsData();
  }, []);

  const saveSetting = async (settings: any) =>
    await UserClient.saveUserSettings(userData.userName, settings.postOb);

  const { mutate: saveUserSetting } = useMutation<any, AxiosError, any>(
    saveSetting,
    {
      onSuccess: (data, settings) => {
        setLanguage(settings.postOb.general?.languageCode);
        settings.currentUserSettings = updateVisibilityData(
          settings.currentUserSettings
        );
        setUserSettings(settings.currentUserSettings);
        setSaveStatus(saveSettingsStatus.success);
        setAlert({
          type: 'success',
          display: true,
          message: intl.formatMessage({
            id: I18nKey.USER_SETTINGS_SAVE_SUCCESS,
          }),
        });
        setTimeout(() => {
          setAlert({
            type: '',
            display: false,
            message: '',
          });
        }, 5000);

        getUnitsAlertMessage(settings.currentUserSettings);
        getWeatherAlertMessage(settings.currentUserSettings);
      },
      onError: () => {
        setSaveStatus(saveSettingsStatus.error);
        setAlert({
          type: 'error',
          display: true,
          message: intl.formatMessage({
            id: I18nKey.ERROR_MESSAGE_SAVE_SETTINGS,
          }),
        });
        setTimeout(() => {
          setAlert({
            type: '',
            display: false,
            message: '',
          });
        }, 5000);
      },
      onSettled: () => {
        queryClient.invalidateQueries('UserSettings');
        setSaveStatus(saveSettingsStatus.idle);
      },
    }
  );

  const saveUserSettings = (currentUserSettings: any) => {
    let ob: any = {};
    currentUserSettings.products.forEach((category: any) => {
      category.products.forEach((product: any) => {
        ob[product.code] = product;
        ob[product.code].depths = ob[product.code].depths.toString();
      });
    });
    const postOb = { ...currentUserSettings, ...{ products: ob } };
    saveUserSetting({ postOb, currentUserSettings });
  };

  const updateVisibilityData = (userData: UserSettings) => {
    userData.products.forEach((c: any) => {
      c['visible'] = _.find(c.products, (p) => p.showProduct === true)
        ? true
        : false;
    });
    return userData;
  };

  const getUnitsAlertMessage = (savedSettings: UserSettings) => {
    let message = '';
    if (
      userSettings.general?.unitsDepth !== savedSettings.general?.unitsDepth
    ) {
      message =
        message +
        ' ' +
        intl.formatMessage({
          id: I18nKey.USER_SETTINGS_DEPTH,
        });
    }
    if (
      userSettings.general?.unitsHeight !== savedSettings.general?.unitsHeight
    ) {
      message =
        message === ''
          ? ' ' +
            intl.formatMessage({
              id: I18nKey.USER_SETTINGS_HEIGHT,
            })
          : message +
            ', ' +
            intl.formatMessage({
              id: I18nKey.USER_SETTINGS_HEIGHT,
            });
    }
    if (
      userSettings.general?.unitsTemperature !==
      savedSettings.general?.unitsTemperature
    ) {
      message =
        message === ''
          ? ' ' +
            intl.formatMessage({
              id: I18nKey.USER_SETTINGS_TEMPERATURE,
            })
          : message +
            ', ' +
            intl.formatMessage({
              id: I18nKey.USER_SETTINGS_TEMPERATURE,
            });
    }
    if (message !== '') {
      setCloseProductsOnUnitChange(true);
      setAlert({
        type: 'success',
        display: true,
        message: intl.formatMessage(
          {
            id: I18nKey.USER_SETTINGS_RELOAD,
          },
          {
            value: message,
            type: intl.formatMessage({
              id: I18nKey.USER_SETTINGS_UNITS,
            }),
          }
        ),
      });
      setTimeout(() => {
        setAlert({
          type: '',
          display: false,
          message: '',
        });
        setCloseProductsOnUnitChange(false);
      }, 5000);
    }
  };

  const getWeatherAlertMessage = (savedSettings: UserSettings) => {
    if (
      savedSettings.map?.showWindRaster !== userSettings.map?.showWindRaster
    ) {
      setAlert({
        type: 'success',
        display: true,
        message: intl.formatMessage(
          {
            id: I18nKey.USER_SETTINGS_RELOAD,
          },
          {
            value: intl.formatMessage({
              id: I18nKey.CATEGORY_WEATHER,
            }),
            type: intl.formatMessage({
              id: I18nKey.USER_SETTINGS_CHANGES,
            }),
          }
        ),
      });
      setTimeout(() => {
        setAlert({
          type: '',
          display: false,
          message: '',
        });
      }, 5000);
    }
  };

  return (
    <UserSettingsContext.Provider
      value={{
        userSettings,
        saveStatus,
        setSaveStatus,
        setUserSettings,
        getUserSettings,
        saveUserSettings,
        closeProductsOnUnitChange,
        setCloseProductsOnUnitChange,
      }}
    >
      {alert.display && (
        <Snackbar
          type={alert.type}
          display={alert.display}
          message={alert.message}
        ></Snackbar>
      )}
      {props.children}
    </UserSettingsContext.Provider>
  );
};
