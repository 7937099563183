import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Button } from '@mui/material';
import { I18nKey } from '../../../translations/I18nKey';
import { useStyles } from '../../../utils/util';
import { PopupHeaderProps } from '../SettingsPopupTypes';
import clear from './../../../assets/icons/clear_all_grey.svg';
import { popupHeaderStyles } from './PopupHeaderStyles';

export const PopupHeader: React.FC<PopupHeaderProps> = ({
  headerStyle,
  title,
  titleStyle,
  closeIcon = clear,
  closeIconStyle,
  dataElement,
  onClose,
}) => {
  const intl = useIntl();
  const classes = useStyles(popupHeaderStyles, [
    headerStyle,
    titleStyle,
    closeIconStyle,
  ])();

  return (
    <>
      <Box className={classes.header}>
        <Box className={classes.title}>{intl.formatMessage({ id: title })}</Box>
        <Button
          className={classes.close}
          onClick={onClose}
          startIcon={
            <img
              className={classes.closeImage}
              src={closeIcon}
              alt={intl.formatMessage({
                id: I18nKey.CLOSE,
              })}
            />
          }
        ></Button>
      </Box>
      {dataElement && dataElement}
      <hr className={classes.lineStyle} />
    </>
  );
};
