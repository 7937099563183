import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useContextSelector } from 'use-context-selector';
import { Footer } from '../../components/Footer/Footer';
import Snackbar from '../../components/Snackbar/Snackbar';
import { AlertContext } from '../Contexts/AuthContext';
import { I18nKey } from '../../translations/I18nKey';
import { useStyles } from '../../utils/util';
import { getPathConfig } from './PublicHomeConfig';
import { SignUpFormStyles } from './PublicHomeStyles';

export const PublicHome = (props: any) => {
  const { children } = props;
  const { pathname = '' } = useLocation();
  const [alertProps, setAlertProps] = useContextSelector(
    AlertContext,
    (state) => state
  );
  const classes = useStyles(SignUpFormStyles, [])();
  const intl = useIntl();
  const navigate = useNavigate();
  const pathConfig = getPathConfig(pathname);

  const onClickFooterLink = (state: string) => {
    navigate('/' + state);
  };

  return (
    <>
      {alertProps.display && (
        <Snackbar
          type={alertProps.type}
          display={alertProps.display}
          message={alertProps.message}
        ></Snackbar>
      )}
      <Box className="backdrop">
        <Box className={classes.formContainer}>
          <Typography variant="h1" className={classes.loginTitle}>
            {intl.formatMessage({
              id: I18nKey.SEASTAR,
            })}
          </Typography>
          {children}
          {pathConfig.displayBottomSection && (
            <Box className={classes.bottomTextContainer}>
              <Typography
                position={'relative'}
                marginTop={'3px'}
                className={classes.bottomText}
              >
                {intl.formatMessage({
                  id: Object.values(I18nKey)[pathConfig['footerTextKey']],
                })}
              </Typography>
              <Typography
                fontFamily={'Roboto'}
                fontSize={'18px'}
                className={[classes.bottomTextLink, classes.linkStyle].join(
                  ' '
                )}
                onClick={() => onClickFooterLink(pathConfig.footerAction)}
              >
                {intl.formatMessage({
                  id: Object.values(I18nKey)[pathConfig['footerSubTextKey']],
                })}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Footer />
    </>
  );
};
