import { FC, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {
  Button,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FishCatchLbContext from './FishCatchLbContent';
import moment from 'moment';
import ExploreIcon from '@mui/icons-material/Explore';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import FishCatchCardDetails from './FishCatchCardDetails';

import Drawer from '@mui/material/Drawer';
import FishCatchDrawerContent from './FishCatchDrawer/FishCatchDrawerContent';
import { useQueryClient, useQuery } from 'react-query';
import {
  useCardChangeContent,
  useChangeOnData,
  useFetchFishCatch,
} from '../store/FishCatchStore';
import FishCatchAlert from './FishCatchAlert';
import { isEmpty } from 'lodash';

interface CatchDetailItem {
  name: string;
  quantity: number;
  alive: boolean;
  finned: boolean;
  time: string;
  id: string;
  uuid: string;
}

const FishCatchCardInfo: FC<any> = () => {
  // --- State Management of Drawer and Search ---
  const [searchSelection, setSearchSelection] = useState<string[]>([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [valueInfo, setValueInfo] = useState(0);
  const [dataDetails, setDataDetails] = useState<any>([]);

  // --- Store on Query ---
  const queryClient = useQueryClient();
  const dataGeneralInfo = useQuery('GI', () => queryClient.getQueryData('GI'));
  const dataCatchCondition = useQuery('CC', () =>
    queryClient.getQueryData('CC')
  );
  const dataCatchDetails = useQuery('CD', () => queryClient.getQueryData('CD'));

  const changeGI = useChangeOnData((state) => state.changeGI);
  const changeCC = useChangeOnData((state) => state.changeCC);
  const changeCD = useChangeOnData((state) => state.changeCD);

  const setResetCardInfo = useCardChangeContent(
    (state) => state.setRefetchCard
  );

  useEffect(() => {
    if (dataCatchDetails.status === 'success' && dataCatchDetails.data) {
      setDataDetails(dataCatchDetails.data);
    }
  }, [dataCatchDetails.isSuccess, dataCatchDetails.data]);

  // --- Modal ---
  const [infoCard, setInfoCard] = useState<boolean>(true);
  const [warningCard, setWarningCard] = useState<boolean>(false);
  const [titleCard, setTitleCard] = useState<string>('');
  const [messageCard, setMessageCard] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);

  function handleCloseModal() {
    setOpenModal(false);
  }

  // --- Style of input checks ---
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300,
      },
    },
  };

  // --- Constant Values To Selected on Search---
  const searchInputs = ['Finned', 'Alive'];

  // --- Manages the selected values on Search ---
  const handleChange = (event: SelectChangeEvent<typeof searchSelection>) => {
    const {
      target: { value },
    } = event;
    setSearchSelection(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  // --- Format navigation ---
  function decimalToDMS(decimal: any) {
    var direction = '';
    if (decimal < 0) {
      direction = decimal <= -180 ? 'W ' : 'S ';
    } else {
      direction = decimal >= 180 ? 'E ' : 'N ';
    }

    decimal = Math.abs(decimal);

    var degrees = Math.floor(decimal);
    var minutes = Math.floor((decimal - degrees) * 60);
    var seconds = ((decimal - degrees - minutes / 60) * 3600).toFixed(2);

    return direction + degrees + '° ' + minutes + "' " + seconds + '"';
  }

  // --- Takes Value and tranform data for chip on Card ---
  function filterProperties(obj: any) {
    const result = [];
    if (obj.Finned) {
      result.push('Finned');
    }
    if (obj.Alive) {
      result.push('Alive');
    }

    return result;
  }

  // --- Formik Validation Schema ---
  const initialValues = {
    fishName: '',
    quantity: '',
  };

  const validationSchemaCD = Yup.object().shape({
    fishName: Yup.string(),
    quantity: Yup.number().typeError('Quality can only accept digits.'),
  });

  // --- Initialize Formik ---
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaCD,
    onSubmit: (values) => {
      // Handle form submission
      console.log('VALUES SUBMIT SEARCH: ', values);
    },
  });

  // --- Search and Clear Functionality ---
  function searchCD(
    data: any,
    fishName: string,
    quantity: string,
    property: string[]
  ) {
    setDataDetails(
      data.filter((obj: any) => {
        let fishNameMatch,
          quantityMatch,
          propertiesMatch = false;
        if (fishName.length > 0)
          fishNameMatch = obj.name
            .toLowerCase()
            .includes(fishName.toLowerCase());
        if (parseInt(quantity) >= 0)
          quantityMatch = parseInt(obj.quantity) === parseInt(quantity);
        if (property.length > 0)
          propertiesMatch = property.every(
            (prop) => obj.hasOwnProperty(prop) && obj[prop]
          );
        return fishNameMatch || quantityMatch || propertiesMatch;
      })
    );
  }

  // --- Handles the Close Outside of Drawer ---
  const handleDrawerClose = (event: any, reason: any) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      if (changeCC) {
        setInfoCard(false);
        setOpenModal(true);
        setTitleCard('Leave Changes');
        setMessageCard('Want to discard the Catch Conditions changes?');
        setWarningCard(true);
      } else if (changeGI) {
        setInfoCard(false);
        setOpenModal(true);
        setTitleCard('Leave Changes');
        setMessageCard('Want to discard the General Information changes?');
        setWarningCard(true);
      } else if (changeCD) {
        setInfoCard(false);
        setOpenModal(true);
        setTitleCard('Leave Changes');
        setMessageCard('Want to discard the Catch Conditions changes?');
        setWarningCard(true);
      } else {
        setOpenDrawer(!openDrawer);
      }
    }
  };

  function handleOkay() {
    setOpenDrawer(!openDrawer);
    setResetCardInfo(true);
    handleCloseModal();
  }

  return (
    <div>
      {openModal === true && (
        <FishCatchAlert
          info={infoCard}
          warning={warningCard}
          title={titleCard}
          message={messageCard}
          setOkay={handleOkay}
          setClose={handleCloseModal}
        />
      )}
      {(isEmpty(dataGeneralInfo.data) ||
        isEmpty(dataCatchCondition.data) ||
        isEmpty(dataCatchDetails.data)) && (
        <div style={{ margin: '20px 10px 20px 10px' }}>
          <Card
            style={{
              height: '60vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              border: '1px solid rgba(0, 0, 0, 0.25)',
              background: '#FFF',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '10px 10px 10px 10px',
              }}
            >
              <img
                alt="latitude"
                src={require('../../../assets/icons/fileFishCatch.svg').default}
                width={150}
                height={90}
              />
              <Typography
                variant="h5"
                style={{
                  color: '#7F7F7F',
                  textAlign: 'center',
                  fontSize: '25px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  margin: '10px 10px 10px 10px',
                }}
              >
                No record has been selected, please select a record to view
                information.
              </Typography>
            </div>
          </Card>
        </div>
      )}
      {!isEmpty(dataGeneralInfo.data) &&
        !isEmpty(dataCatchCondition.data) &&
        !isEmpty(dataCatchDetails.data) && (
          <div>
            <div style={{ margin: '20px 10px 20px 10px' }}>
              <Card
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  borderRadius: '10px',
                  border: '1px solid rgba(0, 0, 0, 0.25)',
                  background: '#FFF',
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                  padding: '20px',
                }}
              >
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                  General Information
                </Typography>
                <Typography variant="subtitle1">
                  Provides basic information of the type of fish caught, vessel
                  name, location and quantity. This information is provided by
                  the submitted csv form.
                </Typography>
                <Button
                  style={{ color: '#193768' }}
                  startIcon={<EditIcon />}
                  onClick={() => {
                    setOpenDrawer(!openDrawer);
                    setValueInfo(0);
                  }}
                >
                  Edit
                </Button>

                <Grid
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ marginTop: '20px', marginBottom: '20px' }}
                  container
                  spacing={2}
                >
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'Vessel Name'}
                      content={(dataGeneralInfo.data as any)?.vessel_Name}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'Catch Date'}
                      content={moment(
                        (dataGeneralInfo.data as any)?.catch_Date
                      ).format('L')}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'Total Quantity'}
                      content={(dataGeneralInfo.data as any)?.total_quantity}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'Fishing Technique'}
                      content={
                        (dataGeneralInfo.data as any)?.fishing_technique ||
                        'N/A'
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  style={{
                    marginTop: '10px',
                    marginBottom: '20px',
                    textAlign: 'justify',
                  }}
                  spacing={2}
                >
                  <Grid item xs={10}>
                    <FishCatchLbContext
                      label={'Comments'}
                      content={(dataGeneralInfo.data as any)?.comment || 'N/A'}
                    />
                  </Grid>
                </Grid>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    flexShrink: '0',
                    marginTop: '20px',
                    marginBottom: '20px',
                  }}
                >
                  <ExploreIcon style={{ color: '#FFA500' }} />
                  <Typography
                    variant="h5"
                    style={{
                      color: '#000',
                      fontSize: '20px',
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Location
                  </Typography>
                </div>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  style={{ marginBottom: '20px' }}
                  spacing={2}
                >
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'Latitude'}
                      content={decimalToDMS(
                        (dataGeneralInfo.data as any)?.latitude
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'Longitude'}
                      content={decimalToDMS(
                        (dataGeneralInfo.data as any)?.longitude
                      )}
                    />
                  </Grid>
                  <Grid></Grid>
                  <Grid></Grid>
                </Grid>
              </Card>
            </div>
            <div style={{ margin: '20px 10px 20px 10px' }}>
              <Card
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  borderRadius: '10px',
                  border: '1px solid rgba(0, 0, 0, 0.25)',
                  background: '#FFF',
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                  padding: '20px',
                }}
              >
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                  Catch Details
                </Typography>
                <Typography variant="subtitle1">
                  Provides information on the catch details, which include the
                  amount of fish, type of fish, and location where it was
                  caught.
                </Typography>
                <Button
                  style={{ color: '#193768' }}
                  startIcon={<EditIcon />}
                  onClick={() => {
                    setOpenDrawer(!openDrawer);
                    setValueInfo(1);
                  }}
                >
                  Edit
                </Button>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      background: '#193768',
                      borderRadius: '5px',
                      padding: '10px 10px 10px 10px',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <div style={{ marginRight: '30px' }}>
                          <TextField
                            fullWidth
                            id="fishName"
                            name="fishName"
                            placeholder="Fish Name"
                            variant="outlined"
                            style={{
                              border: '1px solid #CDCDCD',
                              borderRadius: '5px',
                              margin: '2px 0px 2px 0px',
                              background: '#FFFFFF',
                            }}
                            value={formik.values.fishName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.fishName && formik.errors.fishName && (
                            <Typography variant="subtitle2" color="error">
                              {formik.errors.fishName}
                            </Typography>
                          )}
                        </div>
                        <div style={{ marginRight: '30px' }}>
                          <TextField
                            fullWidth
                            id="quantity"
                            name="quantity"
                            placeholder="Quantity"
                            variant="outlined"
                            style={{
                              border: '1px solid #CDCDCD',
                              borderRadius: '5px',
                              margin: '2px 0px 2px 0px',
                              background: '#FFFFFF',
                            }}
                            value={formik.values.quantity}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.quantity && formik.errors.quantity && (
                            <Typography variant="subtitle2" color="error">
                              {formik.errors.quantity}
                            </Typography>
                          )}
                        </div>
                        <div style={{ marginRight: '40px' }}>
                          <FormControl
                            sx={{
                              width: 300,
                              background: '#FFFFFF',
                              borderRadius: '5px',
                              color: '#000000',
                            }}
                          >
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              multiple
                              value={searchSelection}
                              onChange={handleChange}
                              input={<OutlinedInput label="Tag" />}
                              renderValue={(selected) =>
                                selected.length !== 0
                                  ? selected.join(', ')
                                  : 'Property'
                              }
                              MenuProps={MenuProps}
                              placeholder="Property"
                              style={{ border: '1px solid #CDCDCD' }}
                            >
                              {searchInputs.map((search) => (
                                <MenuItem key={search} value={search}>
                                  <Checkbox
                                    checked={
                                      searchSelection.indexOf(search) > -1
                                    }
                                  />
                                  <ListItemText primary={search} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ marginRight: '10px' }}>
                          <Tooltip title="Clear Search">
                            <IconButton
                              style={{ background: '#FFEBC8', padding: '15px' }}
                              onClick={() => {
                                setSearchSelection([]);
                                formik.resetForm();
                                setDataDetails(
                                  dataCatchDetails.data as CatchDetailItem[]
                                );
                              }}
                            >
                              <CleaningServicesIcon
                                style={{ color: '#FFA500' }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div style={{ marginRight: '10px' }}>
                          <Tooltip title="Search">
                            <IconButton
                              style={{ background: '#D9E8F6', padding: '15px' }}
                              onClick={() => {
                                searchCD(
                                  dataCatchDetails.data as CatchDetailItem[],
                                  formik.values.fishName,
                                  formik.values.quantity,
                                  searchSelection
                                );
                              }}
                            >
                              <SearchIcon style={{ color: '#193768' }} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Grid container spacing={3}>
                  {dataDetails.map((fishData: any, index: any) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <FishCatchCardDetails
                        quantity={fishData.quantity}
                        fishName={fishData.name}
                        property={filterProperties(fishData)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Card>
            </div>
            <div style={{ margin: '20px 10px 20px 10px' }}>
              <Card
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  borderRadius: '10px',
                  border: '1px solid rgba(0, 0, 0, 0.25)',
                  background: '#FFF',
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                  padding: '20px',
                }}
              >
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                  Catch Conditions
                </Typography>
                <Typography variant="subtitle1">
                  Provides information on the catch conditions such as
                  temperatures and others.
                </Typography>
                <Button
                  style={{ color: '#193768' }}
                  startIcon={<EditIcon />}
                  onClick={() => {
                    setOpenDrawer(!openDrawer);
                    setValueInfo(2);
                  }}
                >
                  Edit
                </Button>

                <Grid
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ marginTop: '20px', marginBottom: '20px' }}
                  container
                  spacing={2}
                >
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'Plankton'}
                      content={
                        ((dataCatchCondition.data as any)?.plankton || [])
                          .length === 0
                          ? 'N/A'
                          : (dataCatchCondition.data as any)?.plankton
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'Temperature C'}
                      content={
                        ((dataCatchCondition.data as any)?.temp_c || [])
                          .length === 0
                          ? 'N/A'
                          : (dataCatchCondition.data as any)?.temp_c
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'Temperature 25m'}
                      content={
                        (
                          (dataCatchCondition.data as any)?.temperature_25m ||
                          []
                        ).length === 0
                          ? 'N/A'
                          : (dataCatchCondition.data as any)?.temperature_25m
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'Temperature 50m'}
                      content={
                        (
                          (dataCatchCondition.data as any)?.temperature_50m ||
                          []
                        ).length === 0
                          ? 'N/A'
                          : (dataCatchCondition.data as any)?.temperature_50m
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  style={{ marginTop: '20px', marginBottom: '20px' }}
                >
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'Temperature 100m'}
                      content={
                        (
                          (dataCatchCondition.data as any)?.temperature_100m ||
                          []
                        ).length === 0
                          ? 'N/A'
                          : (dataCatchCondition.data as any)?.temperature_100m
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'Temperature 150m'}
                      content={
                        (
                          (dataCatchCondition.data as any)?.temperature_150m ||
                          []
                        ).length === 0
                          ? 'N/A'
                          : (dataCatchCondition.data as any)?.temperature_150m
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'Temperature 200m'}
                      content={
                        (
                          (dataCatchCondition.data as any)?.temperature_200m ||
                          []
                        ).length === 0
                          ? 'N/A'
                          : (dataCatchCondition.data as any)?.temperature_200m
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'Temperature 300m'}
                      content={
                        (
                          (dataCatchCondition.data as any)?.temperature_300m ||
                          []
                        ).length === 0
                          ? 'N/A'
                          : (dataCatchCondition.data as any)?.temperature_300m
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ marginTop: '20px', marginBottom: '20px' }}
                  spacing={2}
                >
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'Current Speed'}
                      content={
                        ((dataCatchCondition.data as any)?.current_speed || [])
                          .length === 0
                          ? 'N/A'
                          : (dataCatchCondition.data as any)?.current_speed
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'Current Directions'}
                      content={
                        (
                          (dataCatchCondition.data as any)?.current_direction ||
                          []
                        ).length === 0
                          ? 'N/A'
                          : (dataCatchCondition.data as any)?.current_direction
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'SSH'}
                      content={
                        ((dataCatchCondition.data as any)?.ssh || []).length ===
                        0
                          ? 'N/A'
                          : (dataCatchCondition.data as any)?.ssh
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'Thermocliene Depth'}
                      content={
                        (
                          (dataCatchCondition.data as any)
                            ?.thermocliene_depth || []
                        ).length === 0
                          ? 'N/A'
                          : (dataCatchCondition.data as any)?.thermocliene_depth
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ marginTop: '20px', marginBottom: '20px' }}
                  spacing={2}
                >
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'Salinity'}
                      content={
                        ((dataCatchCondition.data as any)?.salinity || [])
                          .length === 0
                          ? 'N/A'
                          : (dataCatchCondition.data as any)?.salinity
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'Pressure'}
                      content={
                        ((dataCatchCondition.data as any)?.pressure || [])
                          .length === 0
                          ? 'N/A'
                          : (dataCatchCondition.data as any)?.pressure
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'Wind Speed'}
                      content={
                        ((dataCatchCondition.data as any)?.wind_speed || [])
                          .length === 0
                          ? 'N/A'
                          : (dataCatchCondition.data as any)?.wind_speed
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'Wind Direction'}
                      content={
                        ((dataCatchCondition.data as any)?.wind_direction || [])
                          .length === 0
                          ? 'N/A'
                          : (dataCatchCondition.data as any)?.wind_direction
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ marginTop: '20px', marginBottom: '20px' }}
                  spacing={2}
                >
                  <Grid item xs={3}>
                    <FishCatchLbContext
                      label={'Wave Height'}
                      content={
                        ((dataCatchCondition.data as any)?.wave_height || [])
                          .length === 0
                          ? 'N/A'
                          : (dataCatchCondition.data as any)?.wave_height
                      }
                    />
                  </Grid>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
              </Card>
            </div>
          </div>
        )}
      {!isEmpty(dataGeneralInfo.data) &&
        !isEmpty(dataCatchCondition.data) &&
        !isEmpty(dataCatchDetails.data) && (
          <div>
            <Drawer
              anchor={'right'}
              open={openDrawer}
              onClose={handleDrawerClose}
              BackdropProps={{ invisible: true }}
              PaperProps={{
                elevation: 0,
                sx: {
                  width: '50vw',
                  height: '90.5vh',
                  marginTop: '80px',
                },
              }}
            >
              <FishCatchDrawerContent infoSelected={valueInfo} />
            </Drawer>
          </div>
        )}
    </div>
  );
};

export default FishCatchCardInfo;
