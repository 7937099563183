export const STANDARD_COLORS = [
  '#FFFFFF',
  '#585858',
  '#000000',
  '#DC143C',
  '#008C00',
  '#0700FF',
  '#FFFF00',
  '#FFAE00',
  '#8C00AE',
];

export const VESSEL_TYPE_COLORS: { [key: string]: string } = {
  Fishing: 'purple',
  Cargo: 'blue',
  Tanker: 'yellow',
  'Medical Transport': 'white',
  'Ships Not Party to Armed Conflict': 'green',
  Pilot: 'magenta',
  WIG: '#4deeea',
  'Pleasure Craft': '#b32453',
  Towing: '#ffe700',
  'Law Enforcement': '#FF00CC',
  Dredging: '#CC00FF',
  Reserved: '#9900FF',
  Tug: '#00FF33',
  Other: '#FFFF33',
  Passenger: '#FF6600',
  Sailing: '#00FFFF',
  Diving: '#FF3300',
  Spare: '#0062FF',
  'Port Tender': '#FF0000',
  'Vessel With Anti-Pollution Equipment': '#FF0065',
  Military: '#FF3400',
  SAR: '#56ff00',
  HSC: '#465700',
  Unknown: '#0062FF',
  'Not Available': '#362419',
  'Track End': 'black',
  UNAVAILABLE: '#362419',
  Buoy: '#FF6699',
  Vessel: '#006699',
};

export const RecommendationColors: { [key: string]: string } = {
  bigeye: 'red',
  billfish: '#88fd00',
  bluefin: 'blue',
  bluemarlin: 'orange',
  kingfish: '#38c300',
  mahi: '#f37d00',
  sailfish: 'black',
  skipjack: '#686de0',
  swordfish: '#52feff',
  tuna: '#e91000',
  wahoo: '#ec1afe',
  yellowfin: 'yellow',
  whitemarlin: 'pink',
  default: '#a3903c',
  bigeyetuna: '#feca57',
  squid: '#ff6b6b',
  skipjacktuna: '#686de0',
};
