import { styled } from '@mui/material/styles';
import Radio, { RadioProps } from '@mui/material/Radio';
import { radioButtonStyles, iconStyle } from './RadioButtonStyles';

//code inspired from: https://mui.com/components/radio-buttons/#CustomizedRadios.tsx
const Icon = styled('span')(({ theme }) => radioButtonStyles);
const CheckedIcon = styled(Icon)(iconStyle);

export default function RadioButton(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<CheckedIcon />}
      icon={<Icon />}
      {...props}
    />
  );
}
