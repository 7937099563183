import { useState } from 'react';
import { createContext } from 'use-context-selector';
import _ from 'lodash';

export interface Features {
  displayConfig: any;
  initDataNSortNonProducts: any;
  setClickedProduct: any;
}

export const OtherFeaturesContext = createContext<Features>({
  displayConfig: [],
  initDataNSortNonProducts: () => {},
  setClickedProduct: () => {},
});

export const OtherFeaturesContextProvider: React.FC = (props) => {
  const [displayConfig, setDisplayConfig] = useState<any>({});

  const initDataNSortNonProducts = (data: any): any => {
    Object.values(data).forEach((p: any) => {
      p['visible'] = true;
      p['productSortedList'] = _.orderBy(
        Object.keys(p.items),
        (pr) => p.items[pr].displayOrder
      );
      Object.keys(p.items).forEach((k) => {
        p.items[k]['selected'] = false;
      });
    });
    setDisplayConfig({ data: data });
  };

  const setClickedProduct = (category: any, code: any) => {
    let displayDefaults = Object.assign({}, displayConfig);
    let prod = displayDefaults.data[category]?.items[code];
    prod.selected = !prod.selected;
    setDisplayConfig({ ...displayDefaults });
  };

  return (
    <OtherFeaturesContext.Provider
      value={{
        displayConfig,
        initDataNSortNonProducts,
        setClickedProduct,
      }}
    >
      {props.children}
    </OtherFeaturesContext.Provider>
  );
};
