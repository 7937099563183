import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../../translations/I18nKey';
import { Wrapper } from '../../../components/Wrapper/Wrapper';
import { VESSEL_TYPE_COLORS } from '../../../constants/Constants';
import settingsIcon from '../../../assets/icons/setting.svg';
import closeIcon from '../../../assets/icons/close.svg';
import { ColorBox } from '../../../components/ColorBox/ColorBox';
import { useStyles } from '../../../utils/util';
import { IUUTimelineStyles } from './IUUSettings/IUUSettingsStyles';
import { AISLegendStyles } from './IUUStyles';

export const AISLegends: React.FC<any> = ({
  AISLegendFilteredData,
  onCloseSettings,
  onAISLegendSettings,
}) => {
  const intl = useIntl();
  const classes = useStyles(IUUTimelineStyles, [])();

  const [colorMapping, setColorMapping] = useState<any>([]);
  const [gridColumns, setGridColumns] = useState<number>(4);
  const [wrapperWidth, setWrapperWidth] = useState<string>('360px');

  useEffect(() => {
    const data = AISLegendFilteredData.map((element: any) => {
      if (VESSEL_TYPE_COLORS.hasOwnProperty(element.vesselType)) {
        return {
          vesselType: element.vesselType,
          color: VESSEL_TYPE_COLORS[element.vesselType],
        };
      } else {
        return {
          vesselType: element.vesselType,
          color: 'black',
        };
      }
    });
    if (data.length < 3) {
      setGridColumns(4);
      setWrapperWidth('190px');
    } else if (data.length > 4) {
      setGridColumns(12);
      setWrapperWidth('360px');
    } else {
      setWrapperWidth('240px');
      setGridColumns(8);
    }
    setColorMapping(data);
  }, [AISLegendFilteredData]);

  return (
    <Wrapper
      wrapperStyle={{
        height: '135px',
        width: wrapperWidth,
        backgroundColor: '#121212',
        position: 'relative',
        borderRadius: '12px',
        zIndex: 5,
        overflow: 'visible',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '120px',
          overflowY: 'scroll',
          m: 1,
        }}
      >
        <Typography sx={{ color: 'white', my: 1 }}>
          {intl.formatMessage({ id: I18nKey.IUU_SETTINGS_TABS_AIS })}
        </Typography>
        {colorMapping.length > 0 && (
          <Grid
            container
            spacing={2}
            columns={{
              xs: gridColumns,
              sm: gridColumns,
              md: gridColumns,
              lg: gridColumns,
              xl: gridColumns,
            }}
          >
            {colorMapping.map((vessel: any) => {
              return (
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  key={vessel.vesselType}
                >
                  <Box sx={{ width: '80px' }}>
                    <ColorBox
                      color={vessel.color}
                      boxStyle={AISLegendStyles.AISLegendColorBoxStyle}
                    />
                  </Box>
                  <Typography sx={{ color: 'white', fontSize: '14px' }}>
                    {vessel.vesselType}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
      <img
        src={settingsIcon}
        alt="settings"
        className={classes.settingsIcon}
        onClick={onAISLegendSettings}
      />
      <img
        src={closeIcon}
        alt="close"
        className={classes.closeIcon}
        onClick={onCloseSettings}
      />
    </Wrapper>
  );
};
