import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { deepmerge } from '@mui/utils';
import moment, { Moment } from 'moment';
import { CACHED_URLS_KEY } from '../api/cacheUtils';

export const useStyles = (componentStyles: any, userStyles: any[]) => {
  const mergedUserStyles = userStyles.reduce((r, c) => ({ ...r, ...c }), {});
  const styles = _.merge(componentStyles, mergedUserStyles);
  return makeStyles(styles);
};

export const mergeStyles = (componentDefaultStyles: any, userStyles: {}) => {
  const styles = deepmerge(componentDefaultStyles, userStyles);
  return styles;
};

export const getRandomColor = () => {
  return (
    '#' +
    Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, '0')
      .toUpperCase()
  );
};

export const getSelectedRegion = (
  regions: any,
  userRegion: string | undefined
) => {
  return _.find(regions, ['code', userRegion]);
};

export const updateUserRegion = (
  map: any,
  regions: any,
  userRegion: string | undefined
) => {
  const selectedRegion = getSelectedRegion(regions, userRegion);
  map?.setZoom(selectedRegion.initial_zoom);
  map.setCenter([
    parseInt(selectedRegion.center.split(',')[0]),
    parseInt(selectedRegion.center.split(',')[1]),
  ]);
};

export const clearApplicationStorage = async () => {
  localStorage.clear();
  sessionStorage.clear();
  if ('caches' in window) {
    try {
      const cache = await caches.open(CACHED_URLS_KEY);
      (await cache.keys()).forEach((e) => {
        cache.delete(e);
      });
    } catch (error) {
      console.log('getToken error:', { error });
    }
  }
};

export const dateToString = (
  date: Moment | Date,
  dateFormat = 'YYYY-MM-DD',
  convertToUtc = false
) => {
  if (convertToUtc) {
    return moment.utc(date).format(dateFormat);
  }
  return moment(date).format(dateFormat);
};
