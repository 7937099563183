import { getDate } from 'date-fns';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DatePickerProps } from '../DateRangeTypes';
import moment from 'moment';
import '../Styles/DatePickerStyles.scss';

const DatePickerV2: React.FC<DatePickerProps> = ({
  defaultSelection,
  format,
  calenderData,
  height,
  width,
  onChange,
  showLabel,
  disabled = false,
  textColor = '#000',
}) => {
  const renderDayContents: any = (day: any, date: any) => {
    return <span title={getTooltipText(new Date(date))}>{getDate(date)}</span>;
  };

  const getTooltipText = (date: Date) => {
    let tooltipText = '';
    if (calenderData && calenderData.length) {
      const rec = calenderData.find((d) => {
        return d.date === moment(date).format('YYYY-MM-DD');
      });
      if (rec) {
        tooltipText = rec.products.join('\n');
      }
    }
    return tooltipText;
  };

  const getDayClassName = (date: any) => {
    if (calenderData && calenderData.length) {
      const rec = calenderData.find((d) => {
        return d.date === moment(date).format('YYYY-MM-DD');
      });
      if (rec) {
        return 'productsData';
      }
    }
    return '';
  };

  return (
    <DatePicker
      selected={defaultSelection}
      onChange={onChange}
      dateFormat={format}
      renderDayContents={renderDayContents}
      dayClassName={getDayClassName}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      showIcon
      fixedHeight
      disabledKeyboardNavigation
    />
  );
};

export default DatePickerV2;
