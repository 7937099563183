import { isNil } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { FeaturesContext } from '../FeaturesContext';
import { Box, Toolbar } from '@mui/material';
import { psdbStyle } from '../MapStyles';
import { ProductLegend } from './ProductLegend/ProductLegend';
import ProductSettingDialogBox from './ProductSettingDialogBox/ProductSettingDialogBox';
import {
  isAnyProductSelected,
  getSelectedProductsFromStorage,
  getUniqueProducts,
  getDepth,
  initDepthToStorage,
  getPositionForPopUp,
} from '../HelperService';
import { ObjectKeys } from '../../types';
import { DepthSlider } from '../../../components/Slider/Slider';
import { Wrapper } from '../../../components/Wrapper/Wrapper';
import { useContextSelector } from 'use-context-selector';
import { StormPopUp } from './PopUp/StormPopUp';
import { MapContext } from '../MapContext';
import * as turf from '@turf/turf';

interface SelectedProducts extends ObjectKeys {
  readonly raster: string[];
  readonly vector: string[];
  readonly contour: string[];
  readonly weather: string[];
}

interface SelectedProducts {
  readonly raster: string[];
  readonly vector: string[];
  readonly contour: string[];
  readonly weather: string[];
}

const marks = [
  {
    value: 0,
    label: '10',
  },
  {
    value: -1,
    label: '50',
  },
  {
    value: -2,
    label: '100',
  },
  {
    value: -3,
    label: '150',
  },
  {
    value: -4,
    label: '200',
  },
  {
    value: -5,
    label: '300',
  },
  {
    value: -6,
    label: '400',
  },
  {
    value: -7,
    label: '500',
  },
];

export const ProductContainer: React.FC<any> = ({ openSettings }) => {
  const displayConfig = useContextSelector(
    FeaturesContext,
    (state) => state.displayConfig
  );
  const tileData = useContextSelector(
    FeaturesContext,
    (state) => state.tileData
  );
  const depthSliderValue = useContextSelector(
    FeaturesContext,
    (state) => state.depthSliderValue
  );
  const setDepthSliderValue = useContextSelector(
    FeaturesContext,
    (state) => state.setDepthSliderValue
  );
  const selectedDepthProducts = useContextSelector(
    FeaturesContext,
    (state) => state.selectedDepthProducts
  );

  const map = useContextSelector(MapContext, (state) => state.map);
  const layerOrder = useContextSelector(
    MapContext,
    (state) => state.layerOrder
  );
  const [showProductLegend, setShowProductLegend] = useState(false);
  const [openProductSetting, setOpenProductSetting] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<SelectedProducts>({
    raster: [],
    vector: [],
    contour: [],
    weather: [],
  });
  const [defaultProductsClick, setDefaultProductsClick] = useState<any>();
  const [productList, setProductList] = useState<string[]>([]);
  const [allProducts, setAllProducts] = useState<any>();
  const [selectedDepthValue, setSelectedDepthValue] =
    useState<any>(depthSliderValue);
  const [showPopUp, setShowPopUp] = useState<Record<string, any>>({
    display: false,
    stormData: {},
    layer: '',
  });

  useEffect(() => {
    setSelectedProducts(getSelectedProductsFromStorage());
    let productObj: any = {};
    if (!_.isEmpty(displayConfig)) {
      Object.keys(displayConfig.data).forEach((catagory) => {
        productObj = { ...productObj, ...displayConfig.data[catagory].items };
      });
    }
    setAllProducts(productObj);
  }, [displayConfig, tileData]);

  useEffect(() => {
    const depth = !isAnyProductSelected(selectedDepthProducts)
      ? initDepthToStorage()
      : getDepth();
    setDepthSliderValue(getDepthSliderValue(depth));
    setSelectedDepthValue(getDepthSliderValue(depth));
  }, [selectedDepthProducts]);

  useEffect(() => {
    if (!isNil(selectedProducts)) {
      toggleProductLegend();
      let mergedList: string[] = getUniqueProducts(selectedProducts);
      mergedList = _.filter(
        mergedList,
        (c) => !allProducts[c]?.extraConfigs?.noSettingsDisplay
      );
      setProductList(mergedList);
      let defaultObj: any = {};
      mergedList &&
        mergedList.forEach((el: any, index) => {
          defaultObj[el] = false;
        });
      setDefaultProductsClick(defaultObj);
    }
  }, [selectedProducts]);

  useEffect(() => {
    if (
      !isNil(selectedProducts) &&
      selectedProducts?.weather?.includes('STORM')
    ) {
      loadPopUp('STORMweatherIcons');
    }
  }, [selectedProducts?.weather]);

  const hideLegend = () => {
    setShowProductLegend(false);
  };

  const toggleProductLegend = () => {
    let key = isAnyProductSelected(selectedProducts);
    if (key) {
      setShowProductLegend(true);
    } else {
      hideLegend();
      onCloseProductSettings();
    }
  };

  const getDepthSliderValue = (depth: any) => {
    return _.find(marks, ['label', String(depth)])?.value;
  };

  const getDepthValue = (value: number) => {
    return _.find(marks, ['value', value])?.label;
  };

  const onCloseProductSettings = () => {
    setOpenProductSetting(false);
  };

  const onOpenProductSettings = () => {
    setOpenProductSetting((showProductLegend) => !showProductLegend);
  };

  const onChangeCommittedHandler = (e: any, newValue: number) => {
    localStorage.setItem('depth', getDepthValue(newValue) || '');
    setDepthSliderValue(newValue);
  };

  const onChangeHandler = (e: any, newValue: any) => {
    setSelectedDepthValue(newValue);
  };

  const loadPopUp = (source: string) => {
    map?.on('mouseenter', source, (e: any) => {
      if (map && map.getSource('radius') && map.getLayer('radius')) {
        map.removeLayer('radius');
        map.removeSource('radius');
      }
      addStormRadius(e, source);
    });
    map?.on('click', source, (e) => {
      addPopUp(e, source);
    });
    map?.on('mouseleave', source, (e: any) => {
      if (map && map.getSource('radius') && map.getLayer('radius')) {
        map.removeLayer('radius');
        map.removeSource('radius');
      }
    });
  };

  const addStormRadius = (e: any, source: string) => {
    let stormDetails = e.features[0].properties;
    if (
      Object.keys(stormDetails).includes('radii') &&
      JSON.parse(stormDetails?.radii).length
    ) {
      let circleGeojson: any = { type: 'FeatureCollection', features: [] };
      JSON.parse(stormDetails.radii).forEach((r: any) => {
        let polygonData: any = turf.polygon([r.points]);
        let smoothedPolygon = turf.polygonSmooth(polygonData, {
          iterations: 3,
        });
        circleGeojson.features.push({
          ...smoothedPolygon.features[0],
          properties: { threshold: r.thold },
        });
      });
      if (!map?.getSource('radius')) {
        map?.addSource('radius', {
          type: 'geojson',
          data: circleGeojson,
        });

        map?.addLayer(
          {
            id: 'radius',
            type: 'fill',
            source: 'radius',
            paint: {
              'fill-color': [
                'match',
                ['get', 'threshold'],
                34,
                'cyan',
                50,
                'yellow',
                64,
                'red',
                'red',
              ],
              'fill-opacity': 0.5,
            },
          },
          layerOrder('vector')
        );
      }
    }
  };

  const addPopUp = (e: any, source: string) => {
    const divElement = document.getElementById('storm-popup');
    const elemHeight = divElement && divElement.clientHeight;
    const elemWidth = divElement && divElement.clientWidth;
    const point = map && map?.project([e.lngLat.lng, e.lngLat.lat]);
    const stormDetails = e.features[0].properties;
    stormDetails['position'] = getPositionForPopUp({
      latitude: e.lngLat.lat,
      longitude: e.lngLat.lng,
    });
    if (point) {
      setShowPopUp({
        display: true,
        stormData: stormDetails,
        layer: source,
        position: {
          top: point.y - (elemHeight || 0),
          left: point.x - (elemWidth || 0),
        },
      });
    }
  };

  return (
    <>
      <Toolbar />
      <div style={{ marginTop: '1em' }} />
      {isAnyProductSelected(selectedDepthProducts) && showProductLegend && (
        <Wrapper
          wrapperStyle={{
            height: '300px',
            width: '120px',
            backgroundColor: '#121212',
            position: 'relative',
            borderRadius: '12px',
            top: '5px',
            left: '4%',
          }}
        >
          <DepthSlider
            steps={1}
            marks={marks}
            onChangeHandler={onChangeHandler}
            onChangeCommittedHandler={onChangeCommittedHandler}
            selectedValue={selectedDepthValue}
            defaultValue={selectedDepthValue}
          />
        </Wrapper>
      )}
      {showProductLegend && (
        <ProductLegend
          selectedProducts={selectedProducts}
          onOpenProductSettings={onOpenProductSettings}
          hideLegend={hideLegend}
        />
      )}
      {openProductSetting && productList.length && (
        <Box
          sx={
            openSettings
              ? psdbStyle(
                  isAnyProductSelected(selectedDepthProducts) ? true : false
                ).openSetting
              : psdbStyle(
                  isAnyProductSelected(selectedDepthProducts) ? true : false
                ).closeSetting
          }
        >
          <ProductSettingDialogBox
            onCloseProductSettings={onCloseProductSettings}
            selectedProducts={selectedProducts}
            productList={productList}
            defaultProductsClick={defaultProductsClick}
            allProducts={allProducts}
          />
        </Box>
      )}
      {showPopUp.display && (
        <Box sx={{ position: 'fixed' }}>
          <StormPopUp
            stormData={showPopUp.stormData}
            setShowStormPopUp={setShowPopUp}
            position={showPopUp.position}
            isShowPopUp={showPopUp.display}
          />
        </Box>
      )}
    </>
  );
};
