import { useIntl } from 'react-intl';
import Paper from '@mui/material/Paper';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box/Box';
import closeIcon from '../../assets/icons/close.svg';
import { PopUpStyles } from '../Map/IUU/PopUp/VesselPopUpStyles';
import { useStyles } from '../../utils/util';
import { I18nKey } from '../../translations/I18nKey';
import { isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import Plot from 'react-plotly.js';
import { FishCatchDashboardService } from './FishCatchDashboardService';
import _ from 'lodash';

const Value = styled(Paper)(PopUpStyles.ValueStyle);

export const FishCatchPopUp: React.FC<any> = ({
  fishColorsList,
  fcData,
  setShowFishCatchPopUp,
  position,
  isShowPopUp,
}) => {
  const intl = useIntl();
  const classes = useStyles(PopUpStyles, [])();
  const [chartData, setChartData] = useState<any>({});
  const [isVisible, setIsVisible] = useState(isShowPopUp);
  const popupRef = useRef<any>();

  const onClose = () => {
    setShowFishCatchPopUp({ display: false, fcData: {} });
  };

  useEffect(() => {
    setCatchChartData();
  }, [fcData]);

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible]);

  const handleClickOutside = (event: any) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsVisible(false);
      onClose();
    }
  };

  const setCatchChartData = () => {
    const chartData = FishCatchDashboardService.getCatchesPieChartData(fcData);
    const data: any[] = [
      {
        values: chartData['quantity'],
        labels: chartData['names'],
        type: 'pie',
        marker: {
          colors: chartData['names'].map((n: any) => {
            return _.filter(fishColorsList, (c) => _.has(c, n))[0][n];
          }),
        },
      },
    ];
    const layout = {
      title: chartData['vesselName'],
      width: 250,
      height: 130,
      paper_bgcolor: 'rgba(0,0,0,0)',
      plot_bgcolor: 'rgba(0,0,0,0)',
      legend: {
        x: 1,
        y: 0.5,
        font: {
          color: 'white',
        },
      },
      margin: {
        l: 24,
        r: 0,
        b: 0,
        t: 0,
      },
    };
    const config = {
      displaylogo: false,
    };
    setChartData({ data: data, layout: layout, config: config });
  };

  return (
    <Box
      id="fishcatch-popup"
      sx={{
        position: 'fixed',
        backgroundColor: 'black',
        borderRadius: 3,
        width: 'auto',
        top: position.top,
        left: position.left,
      }}
      style={{ display: isVisible ? 'inline-block' : 'none' }}
      ref={popupRef}
    >
      <Box
        sx={{
          backgroundColor: '#3D3D3D',
          display: 'flex',
          flexDirection: 'column',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          padding: 1,
          color: 'white',
          mb: 1,
        }}
      >
        <Typography>{fcData.vesselID}</Typography>
      </Box>
      <Box
        onClick={onClose}
        sx={{ borderRadius: 50, color: 'white', borderColor: 'white' }}
      >
        <img
          src={closeIcon}
          onClick={onClose}
          className={classes.closeIcon}
          alt="x"
        />
      </Box>
      <Grid
        container
        sx={{ p: 1, width: '295px' }}
        columnSpacing={2}
        rowSpacing={1}
      >
        <Grid item xs={12}>
          {!isEmpty(chartData) && (
            <Plot
              data={chartData.data}
              layout={chartData.layout}
              config={chartData.config}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOARD_POPUP_CATCH_DATE,
            })}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Value>{fcData.date}</Value>
        </Grid>

        <Grid item xs={4}>
          <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
            {intl.formatMessage({
              id: I18nKey.IUU_POPUP_POSITION,
            })}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography sx={{ color: 'white', fontSize: '14px' }}>
            {fcData.position.lat} <br></br>
            {fcData.position.lon}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_FISHING_TECHNIQUE,
            })}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography sx={{ color: 'white', fontSize: '14px' }}>
            {fcData.fishingTechnique || 'N/A'}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
