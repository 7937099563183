import React, { useEffect, useState } from 'react';
import { Box, Slider } from '@mui/material';
import { MinMax } from './MinMax';
import { MinMaxSliderStyles } from '../SliderStyles';
import { MinMaxProps } from '../SliderTypes';
import { toAntilog, toLog } from '../../../containers/Map/HelperService';

const MinMaxSlider: React.FC<MinMaxProps> = ({
  style,
  showLogScale,
  selectedValue,
  defaultValue,
  onChangeHandler,
  unit,
  marks,
  step,
  disabled = false,
  setSelectedValue,
  precision = 1,
  syncMinMax = false,
}) => {
  const scaleValues = (value: number) => {
    return showLogScale ? toAntilog(value) : value;
  };

  const [minMaxState, setMinMaxState] = useState([
    selectedValue[0],
    selectedValue[1],
  ]);

  useEffect(() => {
    setMinMaxState([
      showLogScale
        ? toAntilog(selectedValue[0]).toFixed(precision)
        : selectedValue[0],
      showLogScale
        ? toAntilog(selectedValue[1]).toFixed(precision)
        : selectedValue[1],
    ]);
  }, [selectedValue]);

  const handleMinTextBox = (e: any) => {
    if (e.key === 'Enter' || e.type === 'blur') {
      const newValue = Number(
        Number(showLogScale ? toLog(minMaxState[0]) : minMaxState[0]).toFixed(
          precision
        )
      );
      const scaleMax = Number(marks[marks.length - 1].value);
      const scaleMin = Number(marks[0].value);
      if (syncMinMax) {
        if (newValue > scaleMax || newValue < scaleMin) {
          setSelectedValue([scaleMin, scaleMax]);
          setMinMaxState([scaleMin, scaleMax]);
        } else {
          setSelectedValue([-Math.abs(newValue), Math.abs(newValue)]);
          setMinMaxState([-Math.abs(newValue), Math.abs(newValue)]);
        }
      } else {
        if (newValue > scaleMax || newValue < scaleMin) {
          setSelectedValue([scaleMin, selectedValue[1]]);
          setMinMaxState([scaleMin, selectedValue[1]]);
        } else if (newValue > selectedValue[1]) {
          setSelectedValue([selectedValue[1], newValue]);
          setMinMaxState([
            selectedValue[1],
            showLogScale ? toAntilog(newValue).toFixed(precision) : newValue,
          ]);
        } else {
          setSelectedValue([newValue, selectedValue[1]]);
          setMinMaxState([
            showLogScale ? toAntilog(newValue).toFixed(precision) : newValue,
            selectedValue[1],
          ]);
        }
      }
    }
  };

  const handleMaxTextBox = (e: any) => {
    if (e.key === 'Enter' || e.type === 'blur') {
      const newValue = Number(
        Number(showLogScale ? toLog(minMaxState[1]) : minMaxState[1]).toFixed(
          precision
        )
      );
      const scaleMax = Number(marks[marks.length - 1].value);
      const scaleMin = Number(marks[0].value);
      if (syncMinMax) {
        if (newValue > scaleMax || newValue < scaleMin) {
          setSelectedValue([scaleMin, scaleMax]);
          setMinMaxState([scaleMin, scaleMax]);
        } else {
          setSelectedValue([-Math.abs(newValue), Math.abs(newValue)]);
          setMinMaxState([-Math.abs(newValue), Math.abs(newValue)]);
        }
      } else {
        if (newValue > scaleMax || newValue < scaleMin) {
          setSelectedValue([selectedValue[0], scaleMax]);
          setMinMaxState([selectedValue[0], scaleMax]);
        } else if (newValue < selectedValue[0]) {
          setSelectedValue((prevState: any[]) => [newValue, prevState[0]]);
          setMinMaxState([
            showLogScale ? toAntilog(newValue).toFixed(precision) : newValue,
            selectedValue[0],
          ]);
        } else {
          setSelectedValue([selectedValue[0], newValue]);
          setMinMaxState([
            selectedValue[0],
            showLogScale ? toAntilog(newValue).toFixed(precision) : newValue,
          ]);
        }
      }
    }
  };

  const onChangeMin = (e: any) => {
    setMinMaxState((prevState: any[]) => [e.target.value, prevState[1]]);
  };

  const onChangeMax = (e: any) => {
    setMinMaxState((prevState: any[]) => [prevState[0], e.target.value]);
  };

  return (
    <Box sx={{ width: '95%', marginLeft: '3px' }}>
      <MinMax
        minMaxState={minMaxState}
        onChangeMin={onChangeMin}
        onChangeMax={onChangeMax}
        showLogScale={showLogScale}
        value={selectedValue}
        handleMinBox={handleMinTextBox}
        handleMaxBox={handleMaxTextBox}
        unit={unit}
        min={
          showLogScale
            ? toAntilog(marks[0].value).toFixed(precision)
            : marks[0].value
        }
        max={
          showLogScale
            ? toAntilog(marks[marks.length - 1].value).toFixed(precision)
            : marks[marks.length - 1].value
        }
        step={step}
        disabled={disabled}
      />
      <Slider
        min={marks[0].value}
        max={marks[marks.length - 1].value}
        value={selectedValue}
        step={step}
        sx={{
          ...MinMaxSliderStyles,
          marginLeft: '6px',
        }}
        defaultValue={defaultValue}
        onChange={onChangeHandler}
        marks={marks}
        disableSwap
        scale={scaleValues}
        disabled={disabled}
      />
    </Box>
  );
};

MinMaxSlider.defaultProps = {
  selectedValue: [20, 60],
  marks: [
    {
      value: 0,
      label: '0',
    },
    {
      value: 100,
      label: '100',
    },
  ],
  defaultValue: [20, 60],
};

export default MinMaxSlider;
