/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Box } from '@mui/material';
import { convertDMS } from './HelperService';
import { Footer } from '../../components/Footer/Footer';
import { CopyRightSection } from '../../components/Footer/CopyRightSection';
import { FooterStyles } from './MapStyles';
import { UserSettingsContext } from '../Contexts/UserSettingsContext';
import { MapContext } from './MapContext';
import { useContextSelector } from 'use-context-selector';
export const MapFooter: React.FC = () => {
  const [cordinates, setCordinates] = useState(' LAT - LONG - ');
  const userSettings = useContextSelector(
    UserSettingsContext,
    (state) => state.userSettings
  );
  const map = useContextSelector(MapContext, (state) => state.map);

  useEffect(() => {
    if (!isEmpty(map)) {
      map?.on(
        'mousemove',
        showLatLong(userSettings.general?.latLongFormat || 'format1')
      );
    }
  }, [map, userSettings.general?.latLongFormat]);

  const getFormattedCoordinates = (coordinates: any) => {
    return (
      'LAT ' +
      coordinates.latCardinal +
      ' ' +
      coordinates.lat +
      ' LONG ' +
      coordinates.lonCardinal +
      ' ' +
      coordinates.lon
    );
  };

  const showLatLong = (latLongFormat: string) => {
    return (e: { lngLat: { lat: number; lng: number } }) => {
      let lat = e.lngLat.lat;
      let lng = e.lngLat.lng;
      const coordinates = convertDMS(lat, lng, latLongFormat);
      setCordinates(getFormattedCoordinates(coordinates));
    };
  };

  return (
    <Footer
      footerStyle={{ footer: FooterStyles.footer }}
      leftElement={
        <CopyRightSection
          addDivider={true}
          dividerStyle={{ divider: FooterStyles.divider }}
          sectionStyle={{ section: FooterStyles.section }}
        />
      }
      leftElementStyle={{ leftElement: FooterStyles.leftElement }}
      centerElement={<Box>{cordinates}</Box>}
      centerElementStyle={{ centerElement: FooterStyles.centerElement }}
      centerAlignSelf="auto"
    />
  );
};
