export const DataTableStyles = {
  searchBarStyle: {
    padding: '0.25rem',
    borderRadius: '3px',
  },
  SearchOutlinedIconStyle: { marginLeft: '0.5rem', marginRight: '0.5rem' },
  ActionButtonStyles: {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  AddRowButton: {
    border: 'none',
    '&focus': {
      border: 'none',
    },
  },
};
