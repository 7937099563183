import InfoIcon from '@mui/icons-material/Info';
import { Box } from '@mui/material';
import { useStyles } from '../../utils/util';
import { ToolTip } from '../ToolTip/ToolTip';
import { InfoTooltipStyles } from './InfoTooltipStyles';
import { InfoTooltipProps } from './InfoTooltipTypes';

export const InfoTooltip: React.FC<InfoTooltipProps> = ({ title = <></> }) => {
  const classes = useStyles(InfoTooltipStyles, [])();

  return (
    <ToolTip title={<Box sx={{ py: 1 }}>{title}</Box>} placement={'right'}>
      <InfoIcon fontSize="small" className={classes.info}></InfoIcon>
    </ToolTip>
  );
};
