import React from 'react';
import { Box, Typography } from '@mui/material';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { DateRangeProps } from '../DateRangeTypes';
import { DateRangeStyles } from '../Styles/DateRangeSelectionstyles';
import calenderSVG from '../../../assets/icons/calender.svg';
import 'bootstrap-daterangepicker/daterangepicker.css';
import '../Styles/DateRangeStyles.scss';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../../translations/I18nKey';

const AppDateRangePicker: React.FC<DateRangeProps> = ({
  defaultSelection,
  ranges,
  onChange,
  format,
  height,
  width,
  datePickerLabelStyles,
}) => {
  const intl = useIntl();
  const { start, end } = defaultSelection;
  const label = `${start ? start.format(`${format}`) : '_____'} - ${
    end ? end.format(`${format}`) : '_____'
  }`;

  return (
    <Box height={height} width={'100%'}>
      <DateRangePicker
        initialSettings={{
          startDate: start && start.toDate(),
          endDate: end && end.toDate(),
          showDropdowns: true,
          linkedCalendars: false,
          ranges: {
            ...ranges,
          },
          locale: {
            applyLabel: intl.formatMessage({
              id: I18nKey.DATERANGEPICKER_APPLY,
            }),
            cancelLabel: intl.formatMessage({
              id: I18nKey.DATERANGEPICKER_CANCEL,
            }),
            customRangeLabel: intl.formatMessage({
              id: I18nKey.DATERANGEPICKER_CUSTOM_RANGE,
            }),
          },
        }}
        onCallback={onChange}
      >
        <Box
          id="dateLabel"
          className="col-4"
          sx={{ ...DateRangeStyles.dateLabelBox, ...datePickerLabelStyles }}
        >
          <Box component="img" src={calenderSVG} /> &nbsp;
          <Typography sx={DateRangeStyles.dateLabelTypograpghy}>
            {label}
          </Typography>
        </Box>
      </DateRangePicker>
    </Box>
  );
};

AppDateRangePicker.defaultProps = {
  format: 'MMMM D, YYYY',
  height: 46,
  width: 360,
};

export default AppDateRangePicker;
