import React from 'react';
import { useIntl } from 'react-intl';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import { Box, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { I18nKey } from '../../translations/I18nKey';

import {
  DataTableProps,
  CustomTableToolbarProps,
  ActionButtonProps,
  actionButtonsConst,
} from './DataTableTypes';
import { DataTableStyles } from './DataTableStyles';

const ActionButtons: React.FC<ActionButtonProps> = ({
  tooltip,
  icon,
  handleClick,
}) => {
  const intl = useIntl();
  return (
    <Tooltip
      title={intl.formatMessage({
        id: I18nKey[tooltip],
      })}
    >
      <button onClick={handleClick} style={DataTableStyles.ActionButtonStyles}>
        <img src={require(`../../assets/icons/${icon}`)} alt={icon} />
      </button>
    </Tooltip>
  );
};

const CustomTableToolbar: React.FC<CustomTableToolbarProps> = ({
  value,
  onChange,
  clearSearch,
  addNewRow,
  tableProperties,
}) => {
  const intl = useIntl();

  return (
    <GridToolbarContainer>
      <Grid
        container
        spacing={{ xs: 2, md: 4 }}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        flexGrow={1}
      >
        {tableProperties.search && (
          <Grid item>
            <TextField
              variant="standard"
              value={value}
              onChange={onChange}
              placeholder={intl.formatMessage({
                id: I18nKey.DATA_TABLE_SEARCH,
              })}
              sx={DataTableStyles.searchBarStyle}
              InputProps={{
                startAdornment: (
                  <SearchOutlinedIcon
                    sx={DataTableStyles.SearchOutlinedIconStyle}
                    fontSize="small"
                  />
                ),
                endAdornment: (
                  <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    style={{
                      visibility: value ? 'visible' : 'hidden',
                      paddingBottom: '0.5rem',
                    }}
                    onClick={clearSearch}
                  >
                    <ClearOutlinedIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
          </Grid>
        )}
        {tableProperties.addNewRow && (
          <Grid item margin="dense">
            <button
              style={DataTableStyles.AddRowButton}
              onClick={addNewRow}
              className="addNewRowButton"
            >
              {/* {intl.formatMessage({
                id: I18nKey.DATA_TABLE_ADD_ROW,
              })} */}
              <Tooltip
                title={intl.formatMessage({
                  id: I18nKey.DATA_TABLE_ADD_ROW,
                })}
              >
                <img src={require('../../assets/icons/add_row.svg').default} />
              </Tooltip>
            </button>
          </Grid>
        )}
      </Grid>
    </GridToolbarContainer>
  );
};

const escapeRegExp = (value: string) =>
  value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

export const DataTable: React.FC<DataTableProps> = ({
  tableColumns,
  dataRows,
  onRowClick,
  onCellClick,
  tableConfig,
}) => {
  const intl = useIntl();
  const [searchText, setSearchText] = React.useState('');
  const [data, setData] = React.useState<any>([]);
  const [displayColumns, setDisplayColumns] = React.useState<GridColDef[]>([]);

  const tableProperties = {
    dataTableId: tableConfig?.id || 'id',
    pagination: {
      rowsPerPageOptions: tableConfig?.pagination?.rowsPerPageOptions || [
        5, 20, 50,
      ],
    },
    search: tableConfig?.search || false,
    addNewRow: tableConfig?.addNewRow?.allowAdd || false,
    gridHeight: tableConfig?.gridHeight || '380px',
    showActions: tableConfig?.actions?.showActions || false,
    showToolBar: tableConfig?.search || tableConfig?.addNewRow ? true : false,
  };

  React.useEffect(() => {
    const additionalColumns: GridColDef[] = [];

    if (tableProperties.showActions && tableConfig?.actions) {
      const buttonArray = tableConfig.actions.actionButtons
        .sort((button1, button2) => button1.position - button2.position)
        .map((userButton) => {
          const button = actionButtonsConst.find(
            (item) => item.name === userButton.name
          );
          if (button) {
            return (params: any) => (
              <ActionButtons
                key={button.name}
                tooltip={button.tooltip}
                icon={button.icon}
                handleClick={() => userButton.onClick(params)}
              />
            );
          }
        });

      const actionColumn = {
        field: 'actions',
        headerName: intl.formatMessage({
          id: I18nKey.DATA_TABLE_ACTIONS_HEADER,
        }),
        width: tableConfig.actions?.width || 70,
        minWidth: tableConfig.actions?.minWidth || 70,
        flex: tableConfig.actions?.flex,
        headerClassName: tableConfig.actions?.actionColumnHeaderClassName || '',
        cellClassName: tableConfig.actions?.actionColumnCellClassName || '',
        disableColumnMenu: tableConfig.actions?.disableColumnMenu || true,
        renderCell: (params: GridCellParams) => {
          return (
            <>{buttonArray.map((element) => element && element(params))}</>
          );
        },
      };
      additionalColumns.push(actionColumn);
    }

    setDisplayColumns([...tableColumns, ...additionalColumns]);
  }, []);

  React.useEffect(() => {
    setData(dataRows);
  }, [dataRows]);

  const tableInitialState = {
    pagination: {
      page: tableConfig?.pagination?.page || 0,
      pageSize:
        (tableConfig?.pagination?.rowsPerPageOptions &&
          tableConfig?.pagination?.rowsPerPageOptions[0]) ||
        undefined,
    },
  };

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = dataRows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        if (row[field]) {
          return searchRegex.test(row[field].toString());
        }
        return false;
      });
    });
    setData(filteredRows);
  };

  return (
    <Box height={tableProperties.gridHeight}>
      <DataGrid
        hideFooter={tableConfig?.disableFooter || false}
        columns={displayColumns}
        rows={data}
        initialState={tableInitialState}
        getRowId={(row) => row[tableProperties.dataTableId]}
        rowsPerPageOptions={tableProperties.pagination.rowsPerPageOptions}
        components={{
          Toolbar: tableProperties.showToolBar ? CustomTableToolbar : null,
        }}
        onRowClick={onRowClick || undefined}
        onCellClick={onCellClick}
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
              requestSearch(event.target.value),
            clearSearch: () => requestSearch(''),
            addNewRow: tableConfig?.addNewRow?.onAddNewRow,
            tableProperties: tableProperties,
          },
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
};
