import { createTheme, PaletteColorOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    primaryHeader?: PaletteColor;
    secondaryHeader?: PaletteColor;
    appBackground?: PaletteColor;
    popUpBackground?: PaletteColor;
    status?: PaletteColor;
    shadow?: PaletteColor;
  }
  interface PaletteOptions {
    primaryHeader?: PaletteColorOptions;
    secondaryHeader?: PaletteColorOptions;
    appBackground?: PaletteColorOptions;
    popUpBackground?: PaletteColorOptions;
    status?: PaletteColorOptions;
    shadow?: PaletteColorOptions;
  }

  interface TypeText {
    lightest?: string;
    light?: string;
    midDark?: string;
    darkest?: string;
  }

  interface PaletteColor {
    lightest?: string;
    lighter?: string;
    midDark?: string;
    darker?: string;
    darkest?: string;
    disabled?: string;
    required?: string;
  }

  interface SimplePaletteColorOptions {
    lightest?: string;
    lighter?: string;
    midDark?: string;
    darker?: string;
    darkest?: string;
    disabled?: string;
    required?: string;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      // Orange
      light: '#ffa500',
      main: '#FFA901',
      disabled: 'rgba(255, 168, 1, 0.5)',
    },
    secondaryHeader: {
      // Dark blue
      main: '#193768',
      dark: '#0C2144',
      darker: '#1A2027',
    },
    primaryHeader: {
      //Light blue
      main: '#EEF5FA', //light blue in the header
    },
    appBackground: {
      lightest: '#FFFFFF',
      lighter: '#F5F5F5', //Dashboard background
      main: '#5C5C5C', // Used in pop up footer
      darkest: '#000000',
    },
    popUpBackground: {
      light: '#5C5C5C',
      main: '#242424',
    },
    text: {
      lightest: '#FFFFFF',
      light: '#DDD6D6',
      midDark: '#6F6F6F',
      darkest: '#000000',
      disabled: '#7F7F7F', //Gray text used in filters
    },
    status: {
      main: '',
      required: 'red',
    },
    shadow: {
      main: '#00000029',
    },
  },
  typography: {
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
});
