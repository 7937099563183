import _ from 'lodash';
import mapboxgl from 'mapbox-gl';
import moment from 'moment';
import { dateToString, getRandomColor } from '../../utils/util';
import { FishCatchData, Geojson } from '../types';
import { SummaryDetailCardProps } from './FishCatchDashboardSummaryDetails';
import fish from '../../assets/icons/fish.png';
import { I18nKey } from '../../translations/I18nKey';
import { VESSEL_COLORS } from './DashboardConfig';
import { stringToColorConverter } from '../Map/HelperService';

export class FishCatchDashboardService {
  static getDefaultDateRange = () => {
    return [
      dateToString(moment().subtract(6, 'days'), 'YYYYMMDDHHmmss'),
      dateToString(moment(), 'YYYYMMDDHHmmss'),
    ];
  };

  static getVesselsList = (data: FishCatchData[]) => {
    return _.uniq(_.map(data, 'vesselName')).map((c) => {
      return {
        vessel: c,
      };
    });
  };

  static getFishingTechniqueList = (data: FishCatchData[]) => {
    return _.uniq(_.map(data, 'fishingTechnique')).map((c) => {
      return {
        fishingTechnique: c,
      };
    });
  };

  static getSpeciesList = (data: FishCatchData[]) => {
    const speciesFromAllVessels = _.map(_.map(data, 'catchDetails'), (c) => {
      return _.map(_.keys(c), (k) => {
        return { name: c[k].name };
      });
    });

    return _.uniqBy(
      _.flatten(speciesFromAllVessels).map((c) => {
        return {
          name: c.name,
        };
      }),
      'name'
    );
  };

  static getTotalCatches = (data: FishCatchData[]) => {
    return data.length;
  };

  static getCatchDataBySelectedVessel = (
    data: FishCatchData[],
    vessels: { vessel: string }[]
  ) => {
    const filteredVessels = vessels.map((vessel) => Object.values(vessel)[0]);

    if (filteredVessels.length) {
      return data.filter((d) => filteredVessels.includes(d.vesselName));
    }
    return data;
  };

  static getCatchDataBySelectedSpecies = (
    data: FishCatchData[],
    species: { name: string }[]
  ): any => {
    const filteredSpecies = species.map((s) => Object.values(s)[0]);

    if (filteredSpecies.length) {
      return _.filter(
        _.map(data, (d) => {
          const catchdetails = d['catchDetails'];

          const filtered = _.filter(catchdetails, (c: any) =>
            filteredSpecies.includes(c.name)
          );

          const totalQuantity = _.sumBy(filtered, (f) => {
            return Number(f.quantity);
          });

          Object.defineProperty(d, 'catchDetails', {
            value: filtered,
          });

          Object.defineProperty(d, 'totalQuantity', {
            value: totalQuantity,
          });

          return d;
        }),
        (d) => d['catchDetails'].length > 0
      );
    }
    return data;
  };

  static getCatchDataBySelectedFishingTechnique = (
    data: FishCatchData[],
    fishingTechniques: { fishingTechnique: string }[]
  ) => {
    const filteredTechniques = fishingTechniques.map(
      (tech) => Object.values(tech)[0]
    );
    if (filteredTechniques.length) {
      return data.filter((d) =>
        filteredTechniques.includes(d.fishingTechnique)
      );
    }
    return data;
  };

  static getMostCaughtSpecies = (data: FishCatchData[]) => {
    const species = this.getUniqueSpeciesList(data);

    let totalBySpecies = Object.keys(species).map((e: any, index: number) => {
      const res: any = {};
      {
        res[e] = _.sumBy(species[e], 'quantity');
      }
      return res;
    });
    totalBySpecies = Object.assign({}, ...totalBySpecies);

    const mostCaughtSpecies: string = _.maxBy(
      _.keys(totalBySpecies),
      function (o: any) {
        return totalBySpecies[o];
      }
    );

    return mostCaughtSpecies;
  };

  static getFishPerVesselData = (data: FishCatchData[]) => {
    const chartData: any = [];
    const species = this.getUniqueSpeciesList(data);
    // The List is sorted in alphabetical order of vessel names to match the order printed in the PDF.
    const vesselList = _.uniq(_.map(data, 'vesselName')).sort();
    let fishColorList = [];
    for (const s of Object.keys(species).sort().reverse()) {
      const y = [];
      const color = stringToColorConverter(s.toLowerCase());
      fishColorList.push({ [s]: color });
      for (const vessel of vesselList) {
        const index = _.findIndex(
          species[s],
          (e: any) => {
            return e['vesselId'] === vessel;
          },
          0
        );
        const quantity = index >= 0 ? species[s][index]['quantity'] : 0;
        y.push(quantity);
      }

      chartData.push({
        x: vesselList,
        y: y,
        name: s,
        type: 'bar',
        marker: {
          color: color,
          size: y.map((s) => _.toNumber(s) * 1.1),
        },
      });
    }

    return [chartData, fishColorList];
  };

  static getCatchPerSetData = (data: FishCatchData[]) => {
    const catchPerSet: any = [];

    _.map(_.groupBy(data, 'vesselId'), (catches: any) => {
      catchPerSet[catches[0]['vesselId']] = _.meanBy(catches, (set: any) => {
        return set.totalQuantity;
      });
    });

    return catchPerSet;
  };

  static getPerSetChartData(data: FishCatchData[]) {
    // The List is sorted in alphabetical order of vessel names to match the order printed in the PDF.
    const vessels = _.uniq(data.map((e: any) => e.vesselName)).sort();
    const catchPerSet = this.getCatchPerSetData(data);
    const catchPerSetY: any = [];
    vessels.map((v: any) => catchPerSetY.push(catchPerSet[v]));
    let chartColors = vessels.map((v, i) =>
      i < 40 ? VESSEL_COLORS[i] : getRandomColor()
    );
    return [
      {
        x: vessels,
        y: catchPerSetY,
        type: 'bar',
        marker: { color: chartColors },
        width: 0.5,
      },
    ];
  }

  static getUniqueSpeciesList = (data: FishCatchData[]) => {
    const species: any = [];
    const filteredCatchData = _.map(data, function (value: any, index: any) {
      value['catchDetails'] = Object.values(value['catchDetails']);
      return value;
    });

    filteredCatchData.map((data: any) => {
      data['catchDetails'].map((catchData: any) => {
        const obj: any = {};
        obj['vesselId'] = data['vesselId'];
        obj['quantity'] = Number(catchData['quantity']);
        if (species.hasOwnProperty(catchData['name'])) {
          const index = _.findIndex(
            species[catchData['name']],
            (e: any) => {
              return e['vesselId'] === obj['vesselId'];
            },
            0
          );
          if (index !== -1) {
            species[catchData['name']][index]['quantity'] += obj['quantity'];
          } else {
            species[catchData['name']].push(obj);
          }
        } else species[catchData['name']] = [obj];
      });
    });

    return species;
  };

  static isNumeric = (num: any) =>
    (typeof num === 'number' ||
      (typeof num === 'string' && num.trim() !== '')) &&
    !isNaN(num as number);

  static setCatchConditionStats = (
    data: FishCatchData[]
  ): SummaryDetailCardProps[] => {
    const conditions: any = {
      tempC: 'SST',
      plankton: 'PLK',
      ssh: 'SSHA',
      bathymetry: 'BTH',
      salinity: 'SAL',
      pressure: 'BPS',
      thermoclieneDepth: 'TCD',
    };

    let catchConditionsData: any = [];
    if (data.length) {
      catchConditionsData = Object.keys(conditions).map((c: any) => {
        let filterData = data.filter((d: any) => {
          if (this.isNumeric(d.catchConditions[c])) return d;
        });
        if (filterData.length === 0) {
          return {
            name: conditions[c],
            average: null,
            min: null,
            max: null,
            weightedAverage: null,
          };
        }
        return {
          name: conditions[c],
          average: _.meanBy(filterData, (d: any) =>
            Number(d.catchConditions[c])
          ),
          min: _.minBy(filterData, (d: any) => Number(d.catchConditions[c]))
            ?.catchConditions[c],
          max: _.maxBy(filterData, (d: any) => Number(d.catchConditions[c]))
            ?.catchConditions[c],
          weightedAverage:
            _.sumBy(
              filterData,
              (d) => Number(d.catchConditions[c]) * Number(d.totalQuantity)
            ) / _.sumBy(filterData, (d) => Number(d.totalQuantity)),
        };
      });
    }

    return catchConditionsData;
  };

  static setHeatMapData = (data: FishCatchData[], intl: any) => {
    return [
      {
        lon: _.map(data, 'longitude'),
        lat: _.map(data, 'latitude'),
        radius: 10,
        z: _.map(data, 'totalQuantity'),
        text: data.map((p) => {
          if (p.catchDetails) {
            let c = p.catchDetails.map((c: any) => {
              return `  ${c.name}:${c.quantity}<br>`;
            });
            return c.join('');
          } else return 'None';
        }),
        type: 'densitymapbox',
        coloraxis: 'coloraxis',
        hoverinfo: 'z+text',
        hovertemplate: `<b>${intl.formatMessage({
          id: I18nKey.FISH_CATCH_FILTER_SPECIES,
        })}:</b><br>%{text}<br><b>${intl.formatMessage({
          id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_TOTAL_QUANTITY,
        })}:</b> %{z}<br><extra></extra>`,
      },
    ];
  };

  static setAnalysisMapData = (data: FishCatchData[]) => {
    return [
      {
        lon: _.map(data, 'longitude'),
        lat: _.map(data, 'latitude'),
        hovertemplate: _.map(data, (e) => {
          return `<b>${e.vesselName}</b><br>Catch date: ${moment(
            e.catchDate
          ).format('MM-DD-YYYY')}<br>Position: lat ${e.latitude.toFixed(
            2
          )} lon ${e.longitude.toFixed(2)}<extra></extra>`;
        }),
        marker: {
          size: 10,
          symbol: 'aquarium',
          allowoverlap: true,
          color: '#7f017f',
          angle: 180,
        },
        text: _.map(data, 'vesselName'),
        mode: 'markers',
        type: 'scattermapbox',
      },
    ];
  };

  public static removeCatchLayers(map: mapboxgl.Map) {
    if (map && map.getSource('fishcatches') && map.getLayer('fishcatches')) {
      map.removeLayer('fishcatches');
      map.removeSource('fishcatches');
    }
  }

  public static getCatchLocationGeojson(catchData: any): any {
    return {
      type: 'Feature',
      properties: {
        vesselID: catchData.vesselId,
        date: catchData.catchDate,
        color: 'purple',
        latitude: catchData.latitude,
        longitude: catchData.longitude,
        catchDetails: Object.values(catchData.catchDetails),
        fishingTechnique: catchData.fishingTechnique,
      },
      geometry: {
        type: 'Point',
        coordinates: [catchData.longitude, catchData.latitude],
      },
    };
  }

  public static addCatchestoMap(map: mapboxgl.Map, position: Geojson) {
    if (!map?.getSource('fishcatches')) {
      map?.addSource('fishcatches', {
        type: 'geojson',
        data: position,
      });
      this.drawCatchesLocations(map, 'fishcatches');
    }
  }

  public static drawCatchesLocations(map: mapboxgl.Map, sourceDataId: string) {
    if (!map?.getLayer('fishcatches')) {
      if (!map?.hasImage('fish')) {
        map?.loadImage(fish, (error: any, image: any) => {
          if (error) throw error;
          image && map?.addImage('fish', image, { sdf: true });
        });
      }
      map?.addLayer({
        id: sourceDataId,
        type: 'symbol',
        source: sourceDataId,
        layout: {
          'icon-size': 0.6,
          'icon-rotation-alignment': 'map',
          'symbol-placement': 'point',
          'icon-allow-overlap': true,
        },
        paint: {
          'icon-color': { type: 'identity', property: 'color' },
        },
      });
      map?.setLayoutProperty(sourceDataId, 'icon-image', 'fish');
    }
  }

  static getCatchesPieChartData = (catchDetails: any): any => {
    const catchData = JSON.parse(catchDetails.catchDetails);
    const chartInfo: any = {};
    chartInfo['vesselName'] = catchDetails.vesselID;
    chartInfo['names'] = _.map(catchData, (o) => o.name);
    chartInfo['quantity'] = _.map(catchData, (o) => o.quantity);
    return chartInfo;
  };
}
