export const Styles: any = {
  typoGraphy: {
    color: '#FFF',
    position: 'relative',
    top: '3px',
    display: 'inline',
    fontSize: '14px',
  },
  minBox: { display: 'inline', position: 'relative', left: '11px' },
  maxBox: { display: 'inline', position: 'relative', left: '20px' },
};

export const InputStyle = (disabled: boolean) => ({
  color: '#fff',
  width: '76px',
  height: '29px',
  background: disabled ? '#3D3D3D ' : '#121212',
  '& .MuiOutlinedInputRoot': {
    '& fieldset': {
      borderColor: '',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFA901',
    },
  },
  '& .MuiInputBase-inputSizeSmall': {
    padding: '0px 3px',
    textAlign: 'center',
    fontSize: '12px',
  },
});

export const colorSliderStyle = (
  left0: number,
  left1: number,
  continuous: boolean | undefined,
  colorScale: any
): any => {
  let color = JSON.parse(localStorage.getItem(colorScale) || '');

  return {
    '& .MuiSlider-rail': {
      background: `linear-gradient(to right, ${
        color?.continuous?.split(',')[0]
      } ${left0}%,${
        color?.continuous?.split(',')[color?.continuous?.split(',').length - 1]
      } ${left1}%) `,
      height: '6px ',
    },
    '&	.MuiSlider-track': {
      backgroundImage: `linear-gradient(to right,${
        continuous ? color.continuous : color.discrete
      })`,
    },
  };
};

export const RasterSliderStyles = {
  '& .MuiSlider-thumb': { color: '#FFA901' },
  '& .MuiSlider-thumbColorPrimary': {
    color: '#FFF ',
  },
  '& .MuiSlider-markLabel': {
    color: '#7F7F7F ',
    top: '25px',
    fontSize: '12px',
    marginLeft: '4px',
  },
  '& .MuiSlider-mark': { display: 'none' },
};

export const MinMaxSliderStyles: any = {
  '& .MuiSlider-rail': { color: '#7F7F7F' },
  '& .MuiSlider-thumb': { width: '13px ', height: '13px ' },
  '& .MuiSlider-thumbColorPrimary': { color: '#FFA901' },
  '&	.MuiSlider-track': { color: '#7F7F7F ', height: '2px ' },
  '& .MuiSlider-markLabel': {
    color: '#7F7F7F ',
    top: '25px',
    fontSize: '12px',
    marginLeft: '4px',
  },
  '& .MuiSlider-mark': { display: 'none' },
  '& .Mui-disabled': {
    color: '#7F7F7F ',
  },
};

export const SingleSliderStyles: any = {
  '& .MuiSlider-rail': { color: '#7F7F7F' },
  '& .MuiSlider-thumb': { width: '13px ', height: '13px ' },
  '& .MuiSlider-thumbColorPrimary': { color: '#FFA901' },
  '&	.MuiSlider-track': { color: '#7F7F7F ', height: '2px ' },
  '& .MuiSlider-markLabel': { color: '#7F7F7F ', top: '18px ' },
  '& .MuiSlider-mark': { display: 'none' },
};

export const DepthSliderStyles: any = {
  sliderStyles: {
    '& .MuiSlider-mark': {
      width: '10px',
      height: '10px',
      borderRadius: '5px ',
      color: '#FFF ',
    },
    '& .MuiSlider-markActive': {
      width: '10px',
      height: '10px',
      borderRadius: '5px ',
      color: '#FFF ',
    },
    '& .MuiSlider-thumb': { color: '#FFA901' },
    '& .MuiSlider-thumbColorPrimary': { color: '#FFA901' },
    '& .MuiSlider-rail': { color: '#FFA901' },
    '& .MuiSlider-track': {
      backgroundColor: '#7F7F7F ',
      borderColor: '#7F7F7F ',
      width: '2px ',
    },
    '& .MuiSlider-markLabel': { color: '#FFF ' },
    '& .Mui-active': { color: '#FFA901' },
  },
  depthSliderBox: {
    height: '210px',
    marginLeft: '5%',
    marginTop: '22px',
  },
  depth: {
    color: '#FFF',
    marginBottom: ' 7.66px',
    marginLeft: '0%',
    marginTop: ' 7.66px',
  },
  clickToDepth: {
    fontSize: '14px',
    color: '#D5D5D5',
    marginBottom: '19.34px',
    marginLeft: '14px',
  },
};

export const TimeLineSliderStyles: any = {
  sliderStyles: {
    '& .MuiSlider-mark': {
      width: '5px',
      height: '5px',
      borderRadius: '2.5px ',
      color: '#FFF ',
    },
    '& .MuiSlider-markActive': {
      width: '10px',
      height: '10px',
      borderRadius: '5px ',
      color: '#FFF ',
    },
    '& .MuiSlider-thumb': {
      color: '#FFA901',
    },
    '& .MuiSlider-thumbColorPrimary': {
      color: '#FFA901',
    },
    '& .MuiSlider-colorPrimary': {
      color: '#FFA901',
    },
    '&	.MuiSlider-track': {
      backgroundColor: '#7F7F7F ',
      borderColor: '#7F7F7F ',
      height: '2px ',
    },
    '&	.MuiSlider-rail': {
      backgroundColor: '#7F7F7F ',
      borderColor: '#7F7F7F ',
    },
    '& .MuiSlider-markLabel': {
      color: '#7F7F7F ',
      top: '27px ',
      fontSize: '13px',
    },
    '& .Mui-active': {
      color: '#FFA901',
    },
  },
};

export const DurationSliderStyles: any = {
  sliderStyles: {
    ...DepthSliderStyles.sliderStyles,
    '& .MuiSlider-markLabel': {
      color: '#6D6D6D ',
      top: '23px',
      fontSize: '14px',
    },
    '& .MuiSlider-rail': { color: '#6D6D6D' },
    '& .MuiSlider-track': {
      backgroundColor: '#FFA901',
      borderColor: '#FFA901',
      width: '2px ',
    },
    '& .MuiSlider-mark': {
      width: '10px',
      height: '10px',
      borderRadius: '5px ',
      color: '#6D6D6D',
    },
    '& .MuiSlider-markActive': {
      width: '10px',
      height: '10px',
      borderRadius: '5px ',
      backgroundColor: '#FFA901',
    },
  },
  cardStyles: {
    height: '54px',
    width: '150px',
  },
  sliderBoxStyles: {
    height: '24px',
    width: '80px',
    marginTop: '7px',
    marginLeft: '36px',
  },
};
