import { Box, LinearProgress } from '@mui/material';
import React from 'react';

export const CustomLinearProgress = () => {
  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <LinearProgress />
    </Box>
  );
};
