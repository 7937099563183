import { Button, Typography } from '@mui/material';
import classNames from 'classnames';
import { IconProps } from './IconTypes';
import { ToolTip } from '../ToolTip/ToolTip';
import './Icon.scss';

//TODO: check enum for button state
export const Icon: React.FC<IconProps> = ({
  iconName,
  title = '',
  clicked = false,
  iconPlacement = 'left',
  disabled = false,
  onClick = () => {},
  index = 0,
}) => {
  return (
    <ToolTip title={title} placement={iconPlacement} index={index}>
      <Typography component={'span'}>
        <Button
          onClick={onClick}
          className={classNames({
            iconButton: true,
            selectedButton: clicked,
          })}
          disabled={disabled}
          children={
            // eslint-disable-next-line jsx-a11y/alt-text
            <img
              src={require(`../../assets/icons/${iconName}`)}
              className={classNames({
                icon: true,
                selectedIcon: clicked,
                disabledIcon: disabled,
              })}
            />
          }
        />
      </Typography>
    </ToolTip>
  );
};
