import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../../../translations/I18nKey';
import { SettingsPopup } from '../../../../components/SettingsPopup/SettingsPopup';
import { PopupHeader } from '../../../../components/SettingsPopup/PopupHeader/PopupHeader';
import { PopupFooter } from '../../../../components/SettingsPopup/PopupFooter/PopupFooter';
import { IUUSettingsStyles, tabIndicatorProps } from './IUUSettingsStyles';
import { TabPanelProps } from './IUUSettingsTypes';
import { useStyles } from '../../../../utils/util';
import { AISnVMS } from './AISnVMS/AISnVMS';
import {
  IUU_SETTINGS_AIS_DISPLAY_CONFIG,
  IUU_SETTINGS_VMS_DISPLAY_CONFIG,
} from '../config';
import { CustomLinearProgress } from '../../../../components/CustomLinearProgress/CustomLinearProgress';
import { GeofenceList } from './Geofence/GeofenceList';
import { GeofenceCRUD } from './Geofence/GeofenceCRUD';
import { useContextSelector } from 'use-context-selector';
import { LicensedFeaturesContext } from '../../../Contexts/LicensedFeaturesContext';
import { BuoyList } from './BuoyList';
import { VesselList } from './VesselList';
import FishCatchFilters from './FishCatchFilters';

export const IUUSettings: React.FC<any> = ({
  onClose,
  AISData,
  VMSData,
  onApply,
  GeofenceListData,
  buoyData,
  filteredBuoyData,
  vesselData,
  filteredVesselData,
  onUploadVBData,
  onApplyBuoyFilters,
  onApplyVesselFilters,
  buoyFileData,
  deleteBuoyFileData,
  vesselFileData,
  deleteVesselFileData,
  geofenceDataLoaded,
  deleteGeofence,
  onSaveGeofence,
  dataIsLoading,
  setLoading,
  displayAlert,
  fishCatchData,
  onApplyFishCatchFilters,
}) => {
  const intl = useIntl();
  const features = useContextSelector(
    LicensedFeaturesContext,
    (state) => state.features
  );

  const [tabName, setTabName] = useState<string>(
    features.includes('AIS') && features.includes('Vessel') ? 'VESSEL' : 'AIS'
  );
  const classes = useStyles(IUUSettingsStyles, [])();

  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabName(newValue);
  };
  const [applyFishCatchChanges, setApplyFishCatchChanges] =
    useState<boolean>(false);
  const [applyAISChanges, setApplyAISChanges] = useState<boolean>(false);
  const [applyVMSChanges, setApplyVMSChanges] = useState<boolean>(false);
  const [geofenceEditMode, setGeofenceEditMode] = useState<boolean>(false);
  const [saveGeofenceData, setSaveGeofenceData] = useState<boolean>(false);

  const [selectedGeofenceRow, setSelectedGeofenceRow] = useState<any>(null);

  const FishcatchFooterConfig = {
    action: {
      text: I18nKey.IUU_SETTINGS_ACTION_BUTTON,
      onClick: () => setApplyFishCatchChanges(true),
    },
  };
  const AISFooterConfig = {
    action: {
      text: I18nKey.IUU_SETTINGS_ACTION_BUTTON,
      onClick: () => setApplyAISChanges(true),
    },
  };
  const VMSFooterConfig = {
    action: {
      text: I18nKey.IUU_SETTINGS_ACTION_BUTTON,
      onClick: () => setApplyVMSChanges(true),
    },
  };

  const GeofenceCRUDConfig = {
    action: {
      text: I18nKey.IUU_SETTINGS_ACTION_BUTTON,
      onClick: () => setSaveGeofenceData(true),
    },
  };

  return (
    <SettingsPopup
      popupStyle={{}}
      headerElement={
        <PopupHeader
          title={I18nKey.IUU_SETTINGS_TITLE}
          onClose={onClose}
        ></PopupHeader>
      }
      bodyElement={
        <>
          {!geofenceEditMode ? (
            <Box sx={{ m: 2 }}>
              <Box sx={{ width: '100%' }}>
                <Box>
                  <Tabs
                    value={tabName}
                    onChange={onTabChange}
                    TabIndicatorProps={tabIndicatorProps}
                  >
                    {features.includes('Vessel') && (
                      <Tab
                        value="VESSEL"
                        label={intl.formatMessage({
                          id: I18nKey.OTHER_PRODUCTS_VESSEL,
                        })}
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                      />
                    )}
                    {features.includes('Buoys') && (
                      <Tab
                        value="BUOY"
                        label={intl.formatMessage({
                          id: I18nKey.OTHER_PRODUCTS_BUOYS,
                        })}
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                      />
                    )}
                    {features.includes('FishCatch') && (
                      <Tab
                        value="FISHCATCH"
                        label={intl.formatMessage({
                          id: I18nKey.OTHER_PRODUCTS_FISHCATCH,
                        })}
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                      />
                    )}
                    {features.includes('AIS') && (
                      <Tab
                        value="AIS"
                        label={intl.formatMessage({
                          id: I18nKey.IUU_SETTINGS_TABS_AIS,
                        })}
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                      />
                    )}
                    {features.includes('VMS') && (
                      <Tab
                        value="VMS"
                        label={intl.formatMessage({
                          id: I18nKey.IUU_SETTINGS_TABS_VMS,
                        })}
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                      />
                    )}
                    <Tab
                      value="GEOFENCING"
                      label={intl.formatMessage({
                        id: I18nKey.IUU_SETTINGS_TABS_GEOFENCING,
                      })}
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                    />
                  </Tabs>
                </Box>
                <Box
                  sx={{
                    minHeight: '350px',
                    maxHeight: '485px',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                  }}
                >
                  <TabPanel name={tabName} index="VESSEL">
                    <VesselList
                      VesselListData={vesselData?.length ? vesselData : []}
                      filteredVesselData={filteredVesselData}
                      onUploadVBData={onUploadVBData}
                      setLoading={setLoading}
                      displayAlert={displayAlert}
                      onApplyFilters={onApplyVesselFilters}
                      fileData={vesselFileData}
                      deleteFiles={deleteVesselFileData}
                    />
                  </TabPanel>
                  <TabPanel name={tabName} index="BUOY">
                    <BuoyList
                      BuoyListData={buoyData?.length ? buoyData : []}
                      filteredBuoyData={filteredBuoyData}
                      onUploadVBData={onUploadVBData}
                      setLoading={setLoading}
                      displayAlert={displayAlert}
                      onApplyFilters={onApplyBuoyFilters}
                      fileData={buoyFileData}
                      deleteFiles={deleteBuoyFileData}
                    />
                  </TabPanel>
                  <TabPanel name={tabName} index="FISHCATCH">
                    <FishCatchFilters
                      data={fishCatchData}
                      applyFilter={applyFishCatchChanges}
                      setApplyFilter={setApplyFishCatchChanges}
                      onApplyFilter={onApplyFishCatchFilters}
                    ></FishCatchFilters>
                  </TabPanel>
                  <TabPanel name={tabName} index="AIS">
                    {!dataIsLoading['AIS'] ? (
                      <AISnVMS
                        data={AISData}
                        displaySectionsConfig={IUU_SETTINGS_AIS_DISPLAY_CONFIG}
                        applyChanges={applyAISChanges}
                        setApplyChanges={setApplyAISChanges}
                        onApply={onApply}
                        tabType={'AIS'}
                      />
                    ) : (
                      <CustomLinearProgress />
                    )}
                  </TabPanel>
                  <TabPanel name={tabName} index="VMS">
                    {!dataIsLoading['VMS'] ? (
                      <AISnVMS
                        data={VMSData}
                        displaySectionsConfig={IUU_SETTINGS_VMS_DISPLAY_CONFIG}
                        applyChanges={applyVMSChanges}
                        setApplyChanges={setApplyVMSChanges}
                        onApply={onApply}
                        tabType={'VMS'}
                      />
                    ) : (
                      <CustomLinearProgress />
                    )}
                  </TabPanel>
                  <TabPanel name={tabName} index="GEOFENCING">
                    {geofenceDataLoaded ? (
                      <GeofenceList
                        GeofenceListData={GeofenceListData}
                        setGeofenceEditMode={setGeofenceEditMode}
                        deleteGeofence={deleteGeofence}
                        setSelectedGeofenceRow={setSelectedGeofenceRow}
                      />
                    ) : (
                      <CustomLinearProgress />
                    )}
                  </TabPanel>
                </Box>
              </Box>
            </Box>
          ) : (
            <GeofenceCRUD
              setGeofenceEditMode={setGeofenceEditMode}
              selectedGeofenceRow={selectedGeofenceRow}
              AISData={AISData}
              VMSData={VMSData}
              saveGeofenceData={saveGeofenceData}
              setSaveGeofenceData={setSaveGeofenceData}
              onSaveGeofence={onSaveGeofence}
              dataIsLoading={dataIsLoading}
              displayAlert={displayAlert}
            />
          )}
        </>
      }
      footerElement={
        !geofenceEditMode ? (
          tabName === 'GEOFENCING' ? undefined : (
            <>
              <TabPanel name={tabName} index="BUOY">
                <PopupFooter></PopupFooter>
              </TabPanel>
              <TabPanel name={tabName} index="VESSEL">
                <PopupFooter></PopupFooter>
              </TabPanel>
              <TabPanel name={tabName} index="FISHCATCH">
                <PopupFooter
                  actionButton={FishcatchFooterConfig.action}
                ></PopupFooter>
              </TabPanel>
              <TabPanel name={tabName} index="AIS">
                <PopupFooter
                  actionButton={AISFooterConfig.action}
                ></PopupFooter>
              </TabPanel>
              <TabPanel name={tabName} index="VMS">
                <PopupFooter
                  actionButton={VMSFooterConfig.action}
                ></PopupFooter>
              </TabPanel>
            </>
          )
        ) : (
          <PopupFooter actionButton={GeofenceCRUDConfig.action} />
        )
      }
    ></SettingsPopup>
  );
};

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, name, index } = props;
  return <>{name === index && <> {children} </>}</>;
};
