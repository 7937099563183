import React, { useState } from 'react';
import { ThemeProvider } from '@mui/styles';
import { IntlProvider } from 'react-intl';
import { useContext } from 'use-context-selector';
import flat from 'flat';
import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { getAmplifyConfig } from './utils/auth';
import './api/interceptors';
import { AlertContext } from './containers/Contexts/AuthContext';
import { AppRouter } from './containers/Router/AppRoutes';
import { theme } from './theme';
import { LanguageContext } from './containers/Contexts/LanguageChange';
import enMessages from './translations/en.json';
import esMessages from './translations/es.json';
import twMessages from './translations/zh-tw.json';
import { LicensedFeaturesContextProvider } from './containers/Contexts/LicensedFeaturesContext';
import './App.scss';
import { InjectAxiosInterceptors } from './api/inject-interceptors';

import { QueryClient, QueryClientProvider } from 'react-query';

Amplify.configure(getAmplifyConfig());

const localeConfig: Record<string, any> = {
  //[langCode: langFile] entries to be added when translations are available.
  en: enMessages,
  es: esMessages,
  'zh-TW': twMessages,
};

const App: React.FC = () => {
  const [alertProps, setAlertProps] = useState({
    display: false,
    message: '',
    type: 'error',
  });
  const locale = useContext(LanguageContext).language;

  const queryClient = new QueryClient();

  /*
  Instead of AlertContext, we can introduce SnackbarContext. In
  that we can expose a function, setSnackbarProps()
  <Snackbar/> can be added in <App/> and generic for all.
  */

  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider
        locale={localeConfig.hasOwnProperty(locale) ? locale : 'en'}
        messages={flat(
          localeConfig.hasOwnProperty(locale)
            ? localeConfig[locale]
            : localeConfig['en']
        )}
      >
        <ThemeProvider theme={theme}>
          <AlertContext.Provider value={[alertProps, setAlertProps]}>
            <LicensedFeaturesContextProvider>
              <Router>
                <InjectAxiosInterceptors />
                <AppRouter />
              </Router>
            </LicensedFeaturesContextProvider>
          </AlertContext.Provider>
        </ThemeProvider>
      </IntlProvider>
    </QueryClientProvider>
  );
};

export default App;
