import { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { ColorBoxProps } from './ColorBoxTypes';
import ColorPicker from './ColorPicker/ColorPicker';
import { mergeStyles } from '../../utils/util';
import { colorBoxStyles } from './ColorBoxStyles';

export const ColorBox: React.FC<ColorBoxProps> = ({
  color,
  boxStyle,
  boxText,
  boxTextStyle,
  title,
  titleStyle,
  subTitle,
  subTitleStyle,
  showColorPicker,
  onChangeColor,
}) => {
  const styles = mergeStyles(colorBoxStyles, {
    boxStyle,
    boxTextStyle,
    titleStyle,
    subTitleStyle,
  });
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

  const onToggleColorPicker = (e: any) => {
    isColorPickerOpen && e.stopPropagation();
    setIsColorPickerOpen((isColorPickerOpen) => !isColorPickerOpen);
  };

  return (
    <>
      <Stack
        direction="column"
        justifyContent="space-evenly"
        alignItems="flex-start"
        spacing={0}
        sx={{ width: '100%' }}
      >
        {boxText?.length ? (
          <Stack direction="row">
            <Box
              sx={{ ...styles.boxStyle, backgroundColor: color }}
              onClick={showColorPicker ? onToggleColorPicker : undefined}
            ></Box>
            {boxText?.length && (
              <Typography sx={styles.boxTextStyle}>{boxText}</Typography>
            )}
          </Stack>
        ) : (
          <Box
            sx={{ ...styles.boxStyle, backgroundColor: color }}
            onClick={showColorPicker ? onToggleColorPicker : undefined}
          ></Box>
        )}
        <Typography sx={styles.titleStyle}>{title}</Typography>
        <Typography sx={styles.subTitleStyle}>{subTitle}</Typography>
      </Stack>
      <ColorPicker
        color={color}
        colorPickerStyle={styles.boxStyle}
        onChange={onChangeColor}
        onClose={onToggleColorPicker}
        isOpen={isColorPickerOpen}
      />
    </>
  );
};

ColorBox.defaultProps = {
  color: 'red',
  boxStyle: colorBoxStyles.boxStyle,
  title: '',
  titleStyle: colorBoxStyles.titleStyle,
  subTitle: '',
  subTitleStyle: colorBoxStyles.subTitleStyle,
  showColorPicker: false,
  onChangeColor: () => {},
};
