import {
  Button,
  Divider,
  Drawer,
  Fab,
  Toolbar,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import {
  FishCatchDashboardStyles,
  useFishCatchDashboardStyles,
} from './FishCatchDashboardStyles';
import FilterClose from '../../assets/icons/filter_closed.svg';
import FilterOpen from '../../assets/icons/filter_open.svg';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import reset from '../../assets/icons/reset.svg';
import { I18nKey } from '../../translations/I18nKey';
import { useIntl } from 'react-intl';
import {
  fishingTechniquesTypesColumnConfig,
  speciesTypesColumnConfig,
  vesselTypesColumnConfig,
} from './DashboardConfig';
import { isEmpty } from 'lodash';

const FishCatchFilters: React.FC<any> = ({
  onFilterApply,
  species,
  defaultSelectedSpecies,
  vessels,
  defaultSelectedVessels,
  fishingTechniques,
  defaultSelectedFishingTechniques,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [localSelectedSpecies, setLocalSelectedSpecies] = useState(
    defaultSelectedSpecies
  );
  const [localSelectedVessels, setLocalSelectedVessels] = useState(
    defaultSelectedVessels
  );
  const [localSelectedFishingTechniques, setLocalSelectedFishingTechniques] =
    useState(defaultSelectedFishingTechniques);
  const fishCatchClasses: any = useFishCatchDashboardStyles();

  useEffect(() => {
    if (!isEmpty(defaultSelectedSpecies) && !isEmpty(defaultSelectedVessels))
      setLocalSelectedSpecies(defaultSelectedSpecies);
    setLocalSelectedVessels(defaultSelectedVessels);
    setLocalSelectedFishingTechniques(defaultSelectedFishingTechniques);
  }, [
    defaultSelectedSpecies,
    defaultSelectedVessels,
    defaultSelectedFishingTechniques,
  ]);

  const onVesselTypeChange = (e: any) => {
    setLocalSelectedVessels(e);
  };

  const onSpeciesTypeChange = (e: any) => {
    setLocalSelectedSpecies(e);
  };

  const onFishingTechniqueChange = (e: any) => {
    setLocalSelectedFishingTechniques(e);
  };

  const onApply = () => {
    onFilterApply({
      species: localSelectedSpecies,
      vessels: localSelectedVessels,
      fishingTechniques: localSelectedFishingTechniques,
    });
  };

  const onReset = () => {
    onVesselTypeChange(defaultSelectedVessels);
    onSpeciesTypeChange(defaultSelectedSpecies);
    onFishingTechniqueChange(defaultSelectedFishingTechniques);
    onFilterApply({
      species: defaultSelectedSpecies,
      vessels: defaultSelectedVessels,
      fishingTechniques: defaultSelectedFishingTechniques,
    });
  };

  const intl = useIntl();

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <>
      <Fab
        color="primary"
        variant="extended"
        className={fishCatchClasses.filterContainerStyle}
        onClick={toggleFilters}
      >
        <Typography className={fishCatchClasses.filterCloseText}>
          {intl.formatMessage({
            id: I18nKey.IUUDASHBOARD_FILTER,
          })}
        </Typography>

        <img height="30px" width="30px" src={FilterClose} alt=""></img>
      </Fab>
      <Drawer
        anchor="right"
        open={showFilters}
        variant="persistent"
        onClose={() => {}}
      >
        <div style={{ marginTop: '1em' }} />
        <Toolbar />
        <Box className={fishCatchClasses.filterBoxStyle}>
          <Box className={fishCatchClasses.filterDrawerStyle}>
            <Button onClick={toggleFilters}>
              <Typography className={fishCatchClasses.filterHeadingStyle}>
                {intl.formatMessage({
                  id: I18nKey.IUUDASHBOARD_FILTER,
                })}
              </Typography>
              <img height="30px" width="30px" src={FilterOpen} alt=""></img>
            </Button>
          </Box>
          <Box>
            <Box>
              <AutoComplete
                key={'vessel'}
                data={vessels}
                style={FishCatchDashboardStyles.autocompleteStyle}
                placeholder={intl.formatMessage({
                  id: I18nKey.FISH_CATCH_FILTER_VESSEL,
                })}
                columnConfig={vesselTypesColumnConfig}
                onSelectionChange={onVesselTypeChange}
                showSelectAllToggle={true}
                multiValueSeparator="-"
                showClearAll={false}
                selectionData={localSelectedVessels}
              />
            </Box>
            <Divider flexItem></Divider>
            <Box>
              <AutoComplete
                key={'species'}
                data={species}
                style={FishCatchDashboardStyles.autocompleteStyle}
                placeholder={intl.formatMessage({
                  id: I18nKey.FISH_CATCH_FILTER_SPECIES,
                })}
                columnConfig={speciesTypesColumnConfig}
                onSelectionChange={onSpeciesTypeChange}
                showSelectAllToggle={true}
                multiValueSeparator="-"
                showClearAll={false}
                selectionData={localSelectedSpecies}
              />
            </Box>
            <Divider flexItem></Divider>
            <Box>
              <AutoComplete
                key={'fishingTechnique'}
                data={fishingTechniques}
                style={FishCatchDashboardStyles.autocompleteStyle}
                placeholder={intl.formatMessage({
                  id: I18nKey.FISH_CATCH_FILTER_FISHING_TECHNIQUE,
                })}
                columnConfig={fishingTechniquesTypesColumnConfig}
                onSelectionChange={onFishingTechniqueChange}
                showSelectAllToggle={true}
                multiValueSeparator="-"
                showClearAll={false}
                selectionData={localSelectedFishingTechniques}
              />
            </Box>
          </Box>
          <Box
            className={`${fishCatchClasses['apply-btn-wrapper']} ${fishCatchClasses.bottomBoxStyle}`}
          >
            <Button
              className={fishCatchClasses.applyButtonStyle}
              onClick={onApply}
              disabled={
                localSelectedSpecies.length === 0 ||
                localSelectedVessels.length === 0
              }
            >
              {intl.formatMessage({
                id: I18nKey.IUUDASHBOARD_APPLY,
              })}
            </Button>
            <Button
              className={fishCatchClasses.resetBoxStyle}
              onClick={onReset}
              children={
                <>
                  <img height="17px" width="17px" src={reset} alt=""></img>
                  &nbsp;
                  {intl.formatMessage({
                    id: I18nKey.IUUDASHBOARD_RESET_TO_DEFAULT,
                  })}
                </>
              }
            />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default FishCatchFilters;
