import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {
  Box,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContextSelector } from 'use-context-selector';
import { useStyles } from '../../utils/util';
import { I18nKey } from '../../translations/I18nKey';
import { AlertContext } from '../Contexts/AuthContext';
import { PublicHome } from './PublicHome';
import { SignUpFormStyles } from './PublicHomeStyles';
import './PublicHome.scss';

export const ConfirmSignUpForm: React.FC = () => {
  const { state }: any = useLocation();
  const [userName, setUserName] = useState(state?.userName);
  const [verificationCode, setVerificationCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [alertProps, setAlertProps] = useContextSelector(
    AlertContext,
    (state) => state
  );
  const intl = useIntl();
  const navigate = useNavigate();
  const classes = useStyles(SignUpFormStyles, [])();

  useEffect(() => {
    setTimeout(() => {
      setAlertProps({
        display: true,
        message: intl.formatMessage({
          id: I18nKey.VERIFICATION_PAGE_EXPIRY_MSG,
        }),
        type: 'error',
      });
      setTimeout(() => {
        setAlertProps({
          type: '',
          display: false,
          message: '',
        });
      }, 4000);
      navigate('/sign-in');
    }, 300000);
  }, []);

  const onConfirmSignUp = () => {
    setIsLoading(true);
    Auth.confirmSignUp(userName, verificationCode)
      .then(() => {
        setAlertProps({
          display: true,
          message: intl.formatMessage({
            id: I18nKey.ACCOUNT_CREATION_SUCCESS_MSG,
          }),
          type: 'success',
        });
        setTimeout(() => {
          setAlertProps({
            type: '',
            display: false,
            message: '',
          });
        }, 4000);
        navigate('/sign-in');
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertProps({
          display: true,
          message: intl.formatMessage({
            id: I18nKey.VERIFICATION_ERROR_MSG,
          }),
          type: 'error',
        });
        setTimeout(() => {
          setAlertProps({
            type: '',
            display: false,
            message: '',
          });
        }, 4000);
      });
  };

  const onClickResendCode = () => {
    Auth.resendSignUp(userName).then((msg) => {
      setAlertProps({
        display: true,
        message: `${intl.formatMessage({
          id: I18nKey.VERIFICATION_CODE_SENT_MSG,
        })} ${msg.CodeDeliveryDetails.DeliveryMedium.toLowerCase()}`,
        type: 'success',
      });
      setTimeout(() => {
        setAlertProps({
          type: '',
          display: false,
          message: '',
        });
      }, 4000);
    });
  };

  const onClickSignIn = () => {
    navigate('/sign-in');
  };

  return (
    <>
      <Typography variant={'h4'} className={classes.signInSubTitle}>
        {intl.formatMessage({
          id: I18nKey.ACCOUNT_VERIFICATION,
        })}
      </Typography>
      <Stack height={'118%'} margin={'10% 8% 0%'} spacing={2}>
        <Typography className={classes.label}>
          {intl.formatMessage({
            id: I18nKey.USERNAME,
          })}
        </Typography>

        <Box>
          <TextField
            fullWidth
            sx={SignUpFormStyles.noOutline}
            InputProps={{
              classes: {
                input: classes.resizeTextInput,
              },
              readOnly: true,
            }}
            className={classes.signInInput}
            name="username"
            value={userName}
            onChange={(u) => setUserName(u.target.value)}
          />
        </Box>

        <Typography className={classes.label}>
          {intl.formatMessage({
            id: I18nKey.VERIFICATION_CODE,
          })}
        </Typography>

        <Box>
          <TextField
            fullWidth
            sx={SignUpFormStyles.noOutline}
            InputProps={{
              classes: {
                input: classes.resizeTextInput,
              },
            }}
            autoFocus
            className={classes.signInInput}
            name="verificationCode"
            type="verificationCode"
            value={verificationCode}
            onChange={(u) => setVerificationCode(u.target.value)}
          />
        </Box>

        <Box height={'10%'}>
          <button className={classes.signInButton} onClick={onConfirmSignUp}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              intl.formatMessage({
                id: I18nKey.CREATE_ACCOUNT,
              })
            )}
          </button>
        </Box>
        <Box height={'10%'} className={classes.bottomTextSubpageContainer}>
          <Typography
            fontFamily={'Roboto'}
            fontSize={'21px'}
            className={[classes.btnBackText, classes.linkStyle].join(' ')}
            onClick={onClickSignIn}
          >
            {intl.formatMessage({
              id: I18nKey.BACK_TO_SIGN_IN,
            })}
          </Typography>
          <Typography
            fontFamily={'Roboto'}
            fontSize={'21px'}
            className={[classes.btnFooterText, classes.linkStyle].join(' ')}
            onClick={onClickResendCode}
          >
            {intl.formatMessage({
              id: I18nKey.RESEND_VERIFICATION_CODE,
            })}
          </Typography>
        </Box>
      </Stack>
    </>
  );
};

export const ConfirmSignUp: React.FC = () => {
  return (
    <PublicHome>
      <ConfirmSignUpForm />
    </PublicHome>
  );
};
