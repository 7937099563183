import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import { Box, Container } from '@mui/material';
import { I18nKey } from '../../translations/I18nKey';
import { FishCatchFiltersData } from './DashboardConfig';
import { FishCatchData } from '../types';
import DashboardImage from '../../assets/images/banner.png';
import { FishCatchDashboardService } from './FishCatchDashboardService';
import FishCatchClient from '../../api/fishCatchAPIs';
import FishCatchFilters from './FishCatchFilters';
import FishCatchWidgets from './FishCatchWidgets';
import {
  FishCatchSummaryDetails,
  SummaryDetailCardProps,
} from './FishCatchDashboardSummaryDetails';
import { useFishCatchDashboardStyles } from './FishCatchDashboardStyles';
import fishCatchCount from '../../assets/icons/fish_catch_count.svg';
import mostCaughtFish from '../../assets/icons/species_most_caught.png';
import { ProgressSpinner } from '../../components/ProgressSpinner/ProgressSpinner';
import Snackbar from '../../components/Snackbar/Snackbar';
import _ from 'lodash';

const Summary: React.FC<any> = ({
  totalCatches,
  mostCaughtSpecies,
  catchConditionData,
}) => {
  const intl = useIntl();
  const fishCatchClasses: any = useFishCatchDashboardStyles();

  return (
    <>
      <Box
        className={fishCatchClasses.summaryBoxStyle}
        sx={{
          backgroundImage: 'url(' + DashboardImage + ')',
        }}
      >
        <Box className={fishCatchClasses.summaryContainerStyle}>
          <Box className={fishCatchClasses.summaryLeftContainerStyle}>
            <Box className={fishCatchClasses.summaryLeftBoxStyle}>
              <Box className={fishCatchClasses.summaryLeftElementStyle}>
                <img src={fishCatchCount} alt=""></img>
              </Box>
              <Box className={fishCatchClasses.summaryRightElementStyle}>
                <Box className={fishCatchClasses.summaryTopTextStyle}>
                  {intl.formatMessage({
                    id: I18nKey.FISH_CATCH_DASHBOARD_SUMMARY_TOTAL_CATCHES,
                  })}
                </Box>
                <Box className={fishCatchClasses.summaryBottomTextStyle}>
                  {totalCatches ? totalCatches : 0}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={fishCatchClasses.summaryCenterContainerStyle}>
            <Box className={fishCatchClasses.summaryLeftBoxStyle}>
              <Box className={fishCatchClasses.summaryLeftElementStyle}>
                <img src={mostCaughtFish} alt="" width={'59px'}></img>
              </Box>
              <Box className={fishCatchClasses.summaryCenterElementStyle}>
                <Box className={fishCatchClasses.summaryTopTextStyle}>
                  {intl.formatMessage({
                    id: I18nKey.FISH_CATCH_DASHBOARD_SUMMARY_SPECIES_CAUGHT_MOST,
                  })}
                </Box>
                <Box className={fishCatchClasses.summaryBottomTextStyle}>
                  {mostCaughtSpecies ? mostCaughtSpecies : '-'}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <FishCatchSummaryDetails items={catchConditionData} />
    </>
  );
};

const FishCatchMain: React.FC<any> = ({
  userName,
  dateRange,
  refetchData,
  setRefetchData,
  setDisableExport,
  isLoading,
  setIsLoading,
  setSelectedSpecies,
  setSelectedVessels,
  setSelectedFishingTechniques,
}) => {
  const intl = useIntl();
  const [apiData, setApiData] = useState<FishCatchData[]>([]);
  const [species, setSpecies] = useState([{}]);
  const [vessels, setVessels] = useState([{}]);
  const [fishingTechniques, setFishingTechniques] = useState([{}]);
  const [totalCatches, setTotalCatches] = useState(0);
  const [mostCaughtSpecies, setMostCaughtSpecies] = useState('');
  const [avgCatchPerSet, setAvgCatchPerSet] = useState({});
  const [fishPerVesselData, setFishPerVesselData] = useState([]);
  const [filteredMapData, setFilteredMapData] = useState<FishCatchData[]>([]);
  const [catchConditionData, setCatchConditionData] = useState<
    SummaryDetailCardProps[]
  >([]);
  const [alert, setAlert] = useState({
    type: '',
    display: false,
    message: '',
  });
  const [fishColorsList, setFishColorsList] = useState([]);
  //TODO: move date conversion code to useEffect for date range
  //TODO: remove this with actual co-ordinates
  //TODO: Since dev does not have data, hardcoding dates where we will get data.
  //Once date range picker is incorporated, this hard-coding needs to be removed.
  let fromDate = dateRange[0];
  let toDate = dateRange[1];
  let bottomLeft = '-64.22,41.14';
  let topRight = '45.50,346.23';

  useEffect(() => {
    if (!isEmpty(dateRange)) {
      fetchFishCatchData();
    }
  }, [dateRange]);

  useEffect(() => {
    if (!isEmpty(dateRange) && refetchData) {
      fetchFishCatchData();
      setRefetchData(false);
    }
  }, [refetchData]);

  const fetchFishCatchData = () => {
    setIsLoading(true);
    getFishCatchData()
      .then((response) => {
        if (!isNil(response)) {
          setDisableExport(response.length === 0);
          setApiData(response);
          //setting filters
          setFilterData(response);
          setFishCatchData(response);
          if (isEmpty(response)) {
            setAlert({
              type: 'error',
              display: true,
              message: intl.formatMessage({
                id: I18nKey.PRODUCTS_NO_DATA_MSG,
              }),
            });
          }
        }
        setIsLoading(false);
      })
      .catch(() => {
        setAlert({
          type: 'error',
          display: true,
          message: intl.formatMessage({
            id: I18nKey.ERROR_MESSAGE_GET,
          }),
        });
        setTimeout(() => {
          setAlert({
            type: '',
            display: false,
            message: '',
          });
        }, 5000);
        setIsLoading(false);
      });

    // let fishCatchData: FishCatchData[] =
    // [species, vessles] = setFilterData(fishCatchData);
    // let processedData = applyFilters();
  };

  const getFishCatchData = async () => {
    if (_.isNull(fromDate) && _.isNull(toDate))
      return await FishCatchClient.getFishCatchData(userName);
    else
      return await FishCatchClient.getFishCatchData(userName, fromDate, toDate);
  };

  const setFilterData = (data: FishCatchData[]) => {
    let species = FishCatchDashboardService.getSpeciesList(data);
    setSpecies(species);
    setSelectedSpecies(species);
    let vessels = FishCatchDashboardService.getVesselsList(data);
    setVessels(vessels);
    setSelectedVessels(vessels);
    let fishingTechniques =
      FishCatchDashboardService.getFishingTechniqueList(data);
    setFishingTechniques(fishingTechniques);
    setSelectedFishingTechniques(fishingTechniques);
  };

  const setFishCatchData = (data: FishCatchData[]) => {
    // get fishPerVessel and avgCatchPerSet chart data
    const catchPerSetData = FishCatchDashboardService.getPerSetChartData(data);
    const [fishPerVesselChartData, fishColorsList] =
      FishCatchDashboardService.getFishPerVesselData(data);
    //set map data
    setFilteredMapData(data);
    //set fishPerVessel and avgCatchPerSet chart data
    setAvgCatchPerSet(catchPerSetData);
    setFishPerVesselData(fishPerVesselChartData);
    setFishColorsList(fishColorsList);
    // set summary data
    calculateSummary(data);

    // set catch conditions data
    setCatchConditionData(
      FishCatchDashboardService.setCatchConditionStats(data)
    );
  };

  // this.map.getBounds()

  const calculateSummary = (processedData: FishCatchData[]) => {
    setTotalCatches(FishCatchDashboardService.getTotalCatches(processedData));
    setMostCaughtSpecies(
      FishCatchDashboardService.getMostCaughtSpecies(processedData)
    );
  };

  const onFilterApply = (filtersData: FishCatchFiltersData) => {
    setIsLoading(true);
    setSelectedSpecies(filtersData.species);
    setSelectedVessels(filtersData.vessels);
    setSelectedFishingTechniques(filtersData.fishingTechniques);

    let data = cloneDeep(apiData);
    data = FishCatchDashboardService.getCatchDataBySelectedVessel(
      data,
      filtersData.vessels
    );

    data = FishCatchDashboardService.getCatchDataBySelectedSpecies(
      data,
      filtersData.species
    );

    data = FishCatchDashboardService.getCatchDataBySelectedFishingTechnique(
      data,
      filtersData.fishingTechniques
    );

    if (filtersData.vessels.length === 0 && filtersData.species.length === 0)
      setFishCatchData([]);
    else setFishCatchData(data);

    if (filtersData.vessels.length === 0 || filtersData.species.length === 0)
      setDisableExport(true);
    else setDisableExport(false);

    setIsLoading(false);
  };

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{ backgroundColor: '#F5F5F5' }}
    >
      {alert.display && (
        <Snackbar
          type={alert.type}
          display={alert.display}
          message={alert.message}
        ></Snackbar>
      )}
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <FishCatchFilters
        onFilterApply={onFilterApply}
        species={species}
        vessels={vessels}
        fishingTechniques={fishingTechniques}
        defaultSelectedSpecies={species}
        defaultSelectedVessels={vessels}
        defaultSelectedFishingTechniques={fishingTechniques}
      />
      <Summary
        totalCatches={totalCatches}
        mostCaughtSpecies={mostCaughtSpecies}
        catchConditionData={catchConditionData}
      />
      <FishCatchWidgets
        fishColorsList={fishColorsList}
        fishPerVesselData={fishPerVesselData}
        avgCatchPerSetData={avgCatchPerSet}
        fishCatchData={filteredMapData}
      />
    </Container>
  );
};

export default FishCatchMain;
