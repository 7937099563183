export const DepthMarks = [
  {
    value: 0,
    label: '10',
  },
  {
    value: -50,
    label: '50',
  },
  {
    value: -100,
    label: '100',
  },
  {
    value: -150,
    label: '150',
  },
  {
    value: -200,
    label: '200',
  },
  {
    value: -250,
    label: '>250',
  },
];


export function getDateFormat(date: number) {
  return `${new Date(date).getMonth() + 1}/${new Date(
    date
  ).getDate()}/${new Date(date).getFullYear()}`;
}

export const getTimelineMarks = (totalDuration: number, interval: number) => {
  let list = [];
  const oneDayMillis = 86400000;
  const duration = totalDuration * 86400000;
  const todayInMillis = new Date().getTime();

  for (let d = 0; d < 31; d += 7) {
    list.push({
      value: todayInMillis - oneDayMillis * d,
      label: `${getDateFormat(todayInMillis - oneDayMillis * d)}`,
    });
  }
  list.push({
    value: todayInMillis - duration,
    label: `${getDateFormat(todayInMillis - duration)}`,
  });
  return list.reverse();
};

export const timeLineDefaultValue = () => [
  new Date().getTime() - 86400000 * 7,
  new Date().getTime()
];


