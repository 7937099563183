import React, { FC, useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import GIContent from './FishCatchDrawerGI';
import CCContent from './FishCatchDrawerCC';
import CDContent from './FishCatchDrawerCD';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const FishCatchDrawerContent: FC<any> = ({ infoSelected }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (infoSelected !== undefined) {
      setValue(infoSelected);
    }
  }, [infoSelected]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="General Information" {...a11yProps(0)} />
          <Tab label="Catch Details" {...a11yProps(1)} />
          <Tab label="Catch Conditions" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <GIContent />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <CDContent />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <CCContent />
      </CustomTabPanel>
    </Box>
  );
};

export default FishCatchDrawerContent;
