import { FC, useState, useEffect } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import {
  useFishCatchDashboardStyles,
  useFishCatchListStyles,
} from '../FishCatchDashboardStyles';
import DashboardImage from '../../../assets/images/banner.png';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FishCatchFileList from './FishCatchFileList';
import FishCatchFileInfo from './FishCatchFileInfo';
import { useNavFishCatch } from '../store/FishCatchStore';
import FishCatchRecordsList from './FishCatchRecordsList';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../../translations/I18nKey';

const FishCatchUploadedDataList: FC<any> = ({ setUploadedData }) => {
  const intl = useIntl();
  //--- Styling Classes for component ---
  const fishCatchClasses: any = useFishCatchDashboardStyles();
  const fishCatchList: any = useFishCatchListStyles();

  // --- Nav Store --
  const nav = useNavFishCatch((state) => state.nav);
  const setNav = useNavFishCatch((state) => state.setNav);

  // --- State Navigation of Tables and Page ---
  const [fileID, setFileID] = useState<string>('');
  const [tabName, setTabName] = useState<string>('UploadedFiles');
  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabName(newValue);
  };

  return (
    <>
      {nav.selection === 'FileTable' && (
        <Box
          className={fishCatchClasses.summaryBoxStyle}
          sx={{
            backgroundImage: 'url(' + DashboardImage + ')',
          }}
        >
          <Box className={fishCatchList.summaryContainerStyle}>
            <Box className={fishCatchList.listBoxContainer}>
              <Box className={fishCatchList.stackContainerBox}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  style={{ padding: '15px 15px 0px 15px' }}
                >
                  <Box className={fishCatchList.boxBtnArrow}>
                    <IconButton
                      aria-label="delete"
                      size="large"
                      style={{ color: '#193768' }}
                      onClick={() => {
                        setNav({
                          title: 'uploadedFiles',
                          selection: 'FileTable',
                          submittedDate: '',
                        });
                        setUploadedData(true);
                      }}
                    >
                      <ArrowBackIosIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                  <Box className={fishCatchList.summaryTopTextStyle}>
                    <Tabs value={tabName} onChange={onTabChange}>
                      <Tab
                        label={intl.formatMessage({
                          id: I18nKey.FISH_CATCH_DASHBOARD_UPLOADED_FILES,
                        })}
                        value={'UploadedFiles'}
                      />
                      <Tab
                        label={intl.formatMessage({
                          id: I18nKey.FISH_CATCH_DASHBOARD_RECORDS,
                        })}
                        value={'CatchRecords'}
                      />
                    </Tabs>
                  </Box>
                </Stack>
                <TabPanel name={tabName} index="UploadedFiles">
                  <FishCatchFileList setID={setFileID} />
                </TabPanel>
                <TabPanel name={tabName} index="CatchRecords">
                  <FishCatchRecordsList />
                </TabPanel>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {nav.selection === 'InfoTable' && <FishCatchFileInfo fileID={fileID} />}
    </>
  );
};

const TabPanel: React.FC<any> = (props) => {
  const { children, name, index } = props;
  return <>{name === index && <> {children} </>}</>;
};

export default FishCatchUploadedDataList;
