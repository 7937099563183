export const SignUpFormStyles: any = {
  backdrop: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundImage: "url('./../../assets/images/login_background.png')",
  },
  formContainer: {
    position: 'absolute',
    justifyContent: 'center',
    left: '33%',
    //top: '10%',
    width: '35%',
    height: '85%',
    backgroundColor: 'rgba(1, 9, 42, 0.479)',
    borderRadius: '49px',
    opacity: '1',
    backdropFilter: 'blur(17px)',
    webkitBackdropFilter: 'blur(17px)',
  },
  stackContainer: {
    // height: '110%',
    // margin: '8% 8% 0%',
  },
  signUpInputContainer: {
    marginLeft: '50px',
    marginTop: '15px',
    color: '#ffffff',
  },
  signInInputContainer: {
    marginLeft: '53px',
    marginTop: '81px',
    color: '#ffffff',
  },
  inputRow: {
    height: '100%',
  },
  signInInputRow: {
    height: '106px',
  },
  loginTitle: {
    '&.MuiTypography-h1': {
      font: 'normal normal 900 44px/62px Arial',
      letterSpacing: '-1.06px',
      color: '#ffffff',
      opacity: ' 1',
      textAlign: 'center',
      fontSize: '45px',
      marginTop: '8%',
      marginBottom: '0px',
    },
  },
  signUpInput: {
    '&.MuiTextField-root': {
      color: 'white',
      height: '36px',
      border: '1px solid white',
    },
  },
  fpStackContainer: {
    margin: '18% 8% 0%',
  },
  textFieldStyle: {
    '&.MuiInputBase-input': {
      fontSize: 'x-large',
      color: '#ffffff',
    },
  },
  noOutline: {
    '& .MuiOutlinedInput-notchedOutline': {
      display: 'none',
    },
    '& .MuiFormControl-root': {
      height: '100%',
    },
    '& .MuiOutlinedInput-root': {
      height: '100%',
    },
  },
  signInInput: {
    '&.MuiTextField-root': {
      color: 'white',
      border: '1px solid white',
      height: '100%',
    },
  },
  signUpBtn: {
    width: '100%',
    height: '100%',
    fontWeight: '800',
    fontFamily: 'sans-serif',
    font: '20px/30px Roboto',
    background: '#ffa901 0% 0% no-repeat padding-box',
    opacity: '1',
    // margin: '31px 0px',
    cursor: 'pointer',
  },
  bottomTextLink: {
    // margin: '0px 0px 0px 10%x !important',
  },
  bottomText: {
    '&.MuiTypography-root': {
      color: 'white',
      font: '18px Roboto',
      fontFamily: 'sans-serif',
      // margin: '0px 5px 0px 18%',
    },
    postition: 'relative',
    right: '6%',
  },
  btnFooterText: {
    float: 'right',
    margin: '0px',
    color: 'white',
    // marginTop: '10px',
    // marginRight: ' 58px',
    font: '18px/21px Roboto',
    fontFamily: 'sans-serif',
    marginTop: '3%',
  },
  btnBackText: {
    float: 'left',
    margin: '0px',
    color: 'white',
    font: '18px/21px Roboto',
    fontFamily: 'sans-serif',
    marginTop: '3%',
  },
  linkStyle: {
    '&.MuiTypography-root': {
      color: 'white',
      cursor: 'pointer',
      fontWeight: 'bold',
      //font: '21px/25px Roboto',
      fontFamily: 'sans-serif',
      // marginRight: '2%',
      textDecoration: 'underline',
    },
  },
  bottomTextContainer: {
    display: 'flex',
    borderTop: '2px solid gray',
    font: '21px/25px Roboto',
    fontFamily: 'sans-serif',
    marginTop: '1%',
    marginRight: '8%',
    marginLeft: '8%',
    justifyContent: 'end',
  },
  bottomTextSubpageContainer: {
    borderTop: '2px solid gray',
    font: '21px/25px Roboto',
    fontFamily: 'sans-serif',
    marginTop: '1%',
    marginRight: '8%',
    marginLeft: '8%',
  },
  resizeSignUpTextInput: {
    fontSize: '15px !important ',
    color: 'white !important',
    padding: '7px',
    '& .MuiOutlinedInput-input': {
      height: 'webkitFillAvailable',
    },
  },
  resizeTextInput: {
    fontSize: '25px !important ',
    color: 'white !important',
    padding: '24px',
    height: '-webkit-fill-available',
  },
  lineBreak: {
    //marginBottom: '10%',
  },
  signInSubTitle: {
    '&.MuiTypography-h4': {
      letterSpacing: '0px',
      font: '25px Roboto ',
      color: ' #ffffff',
      opacity: '1',
      fontWeight: '600',
      fontFamily: 'sans-serif',
      textAlign: 'center',
      // marginTop: '27px',
      marginBottom: '-36px',
    },
  },
  signInButton: {
    width: '100%',
    height: '75%',
    fontWeight: '800',
    fontFamily: 'sans-serif',
    font: '18px/30px Roboto',
    background: '#ffa901 0% 0% no-repeat padding-box',
    opacity: '1',
    marginTop: '3%',
    cursor: 'pointer',
  },
  label: {
    fontFamily: 'sans-serif',
    color: 'white',
    font: '18px/22px Roboto',
    fontWeight: '600',
  },

  root: {
    background: 'transparent',
  },
};
