import { Box, Grid, Step, StepLabel, Stepper } from '@mui/material';
import { useState } from 'react';
import { I18nKey } from '../../../../translations/I18nKey';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CompareIcon from '@mui/icons-material/Compare';
import { useIntl } from 'react-intl';
import { useVesselUploadStyles } from './VesselBuoyStyles';
import { UploadFileMetadata } from '../../../FishCatchDashboard/ImportDataSection/ImportPopUp';
import {
  VesselBuoyImportDataUpload,
  VesselBuoyUploadFooter,
} from './VesselBuoyImportDataUpload';
import {
  VesselBuoyImportDataMapper,
  VesselBuoyMapperFooter,
} from './VesselBuoyImportDataMapper';
import * as IUUClient from '../../../../api/iuuAPIs';
import { colMappingConfig, dbHeadersConfig } from './VesselBuoyConfig';

const popUpSteps = (): Record<number, { label: string; icon: any }> => {
  return {
    0: {
      label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_STEP_ONE_LABEL,
      icon: UploadFileIcon,
    },
    1: {
      label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_STEP_TWO_LABEL,
      icon: CompareIcon,
    },
  };
};

const VesselBuoyFileUpload: React.FC<{
  dataType: string;
  setFileUploadMode: any;
  onUploadVBData: any;
  setLoading: any;
  displayAlert: any;
  allVesselNames?: any;
}> = ({
  dataType,
  setFileUploadMode,
  onUploadVBData,
  setLoading,
  displayAlert,
  allVesselNames,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const intl = useIntl();
  const steps = popUpSteps();
  const VesselUploadStyles: any = useVesselUploadStyles();
  const [fileData, setFileData] = useState<string[] | any[]>([]);
  const [file, setFile] = useState<any>(null);
  const [fileMetadata, setFileMetadata] = useState<UploadFileMetadata | {}>({});

  const [isLoading, setIsLoading] = useState(false);
  const [columnMapping, setColumnMapping] = useState<any>(
    colMappingConfig[dataType]
  );
  const [vesselName, setVesselName] = useState<string>('');

  const handleNext = () => {
    let tempColumnMapping = { ...columnMapping };
    // Auto map fields using regex
    Object.keys(columnMapping).forEach((key) => {
      tempColumnMapping[key] =
        fileData[0].find((header: any) =>
          dbHeadersConfig[dataType][key].pattern?.test(header)
        ) || null;
    });

    setColumnMapping(tempColumnMapping);

    setActiveStep(activeStep + 1);
  };
  const handleBack = () => {
    activeStep > 0 && setActiveStep(activeStep - 1);
    setFileData([]);
    setFile(null);
    setFileMetadata({});
  };
  const handleUploadClick = () => {
    if (dataType === 'vessel' && vesselName === '') {
      displayAlert(I18nKey.IUU_SETTINGS_VESSEL_NAME_REQUIRED_MSG);
    } else {
      let colMapping: any = {};
      colMapping['column_mapping'] = Object.keys(columnMapping)
        .map((k) => {
          return {
            source_column: columnMapping[k],
            destination_column: k,
          };
        })
        .filter((m: any) => m?.source_column !== null);
      let formData = new FormData();
      formData.append('file', file);
      formData.append('data', JSON.stringify(colMapping));
      if (dataType === 'vessel') {
        uploadVesselBuoyFile(formData, vesselName);
      } else {
        uploadVesselBuoyFile(formData);
      }
    }
  };

  const uploadVesselBuoyFile = async (
    fileFormData: any,
    vesselName?: string
  ) => {
    try {
      setLoading(true);
      const data = await IUUClient.uploadVesselBuoysFile(
        dataType,
        fileFormData,
        vesselName || ''
      );
      onUploadVBData(dataType);
      setFileUploadMode(false);
      displayAlert(I18nKey.IUU_SETTINGS_FILE_UPLOAD_SUCCESS, 'success');
      return data;
    } catch (error: any) {
      setLoading(false);
      if (error.response.status === 400) {
        displayAlert(error.response.data.detail);
        console.error(error.response);
      } else {
        displayAlert(I18nKey.IUU_SETTINGS_FILE_UPLOAD_FAILURE);
        console.error('Error Message: ', error.response);
        return;
      }
    }
  };

  const onMappingSelectionChange = (
    field: string,
    value: { header: string }
  ) => {
    setColumnMapping({
      ...columnMapping,
      [field]: value.header,
    });
  };
  const getMappingData = () => {
    const data = fileData[0].map((header: any) => {
      let obj: any = {};
      obj['header'] = header;
      return obj;
    });
    return data;
  };
  const onVesselNameChange = (value: string) => {
    setVesselName(value);
  };

  const popUpBodyComponents = [
    <VesselBuoyImportDataUpload
      displayAlert={displayAlert}
      setFileData={setFileData}
      setFile={setFile}
      setFileMetadata={setFileMetadata}
    ></VesselBuoyImportDataUpload>,
    <VesselBuoyImportDataMapper
      dataType={dataType}
      allVesselNames={allVesselNames}
      columnMapping={columnMapping}
      onMappingSelectionChange={onMappingSelectionChange}
      getMappingData={getMappingData}
      vesselName={vesselName}
      onNameChange={onVesselNameChange}
    ></VesselBuoyImportDataMapper>,
  ];

  const popUpFooterComponents = [
    <VesselBuoyUploadFooter fileData={fileData} handleNext={handleNext} />,
    <VesselBuoyMapperFooter
      dataType={dataType}
      columnMapping={columnMapping}
      handleBack={handleBack}
      handleUploadClick={handleUploadClick}
      isLoading={isLoading}
    />,
  ];

  return (
    <Box className={VesselUploadStyles.popUpBodyWrapper}>
      <Box className={VesselUploadStyles.popUpBody}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          className={VesselUploadStyles.stepperAlignment}
        >
          {Object.keys(steps).map((key, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step active={+key === activeStep} key={key} {...stepProps}>
                <StepLabel
                  className={
                    +key === activeStep
                      ? `${VesselUploadStyles['active-step-label']}`
                      : ``
                  }
                  StepIconComponent={steps[index].icon}
                  {...labelProps}
                >
                  {intl.formatMessage({
                    id: steps[index].label,
                  })}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {popUpBodyComponents[activeStep]}
      </Box>
      <Box className={VesselUploadStyles.popUpFooterWrapper}>
        <Grid container spacing={2}>
          {popUpFooterComponents[activeStep]}
        </Grid>
      </Box>
    </Box>
  );
};

export default VesselBuoyFileUpload;
