import { FC } from 'react';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import { Grid, Typography } from '@mui/material';

const FishCatchCardDetails: FC<any> = ({ quantity, fishName, property }) => {
  return (
    <div>
      <Card
        style={{
          borderRadius: '15px',
          margin: '10px 3rem 10px 0.5rem',
          border: '1px solid rgba(0, 0, 0, 0.25)',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            {/* fish quantity will be placed here */}
            <Typography
              style={{
                background: '#193768',
                borderRadius: '0px 15px',
                padding: '5px 10px 5px 10px',
                color: '#FFFFFF',
                fontSize: '15px',
              }}
            >
              {quantity}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            {/* Icon of the fish to be placed here */}
            <img
              alt="latitude"
              src={
                require('../../../assets/icons/FishCatchDetails-Icon.svg')
                  .default
              }
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            {/* Name of the fish to be placed here */}
            <Typography
              style={{ fontSize: '15px', fontWeight: '500', margin: '5px' }}
            >
              {fishName}
            </Typography>
          </div>
          <div>
            {/* Property of the card */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                margin: '10px',
              }}
            >
              <Grid container spacing={1} style={{ marginLeft: '20px' }}>
                {property.map((properties: any, index: any) => (
                  <Grid item xs={6} key={index}>
                    <Chip label={properties} />
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default FishCatchCardDetails;
