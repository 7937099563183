import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContextSelector } from 'use-context-selector';
import { PublicHome } from './PublicHome';
import { useStyles } from '../../utils/util';
import { I18nKey } from '../../translations/I18nKey';
import { AlertContext } from '../Contexts/AuthContext';
import { SignUpFormStyles } from './PublicHomeStyles';
import './PublicHome.scss';
import { InfoTooltip } from '../../components/InfoTooltip/InfoTooltip';
import { PasswordTooltipInfo, validatePassword } from './SignUp';

export const ForgotPasswordForm: React.FC = () => {
  const [userName, setUserName] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showVerificationForm, setShowVerificationForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertProps, setAlertProps] = useContextSelector(
    AlertContext,
    (state) => state
  );
  const intl = useIntl();
  const navigate = useNavigate();
  const classes = useStyles(SignUpFormStyles, [])();

  useEffect(() => {
    setTimeout(() => {
      setAlertProps({
        display: true,
        message: intl.formatMessage({
          id: I18nKey.VERIFICATION_PAGE_EXPIRY_MSG,
        }),
        type: 'error',
      });
      setTimeout(() => {
        setAlertProps({
          type: '',
          display: false,
          message: '',
        });
      }, 4000);
      navigate('/sign-in');
    }, 500000);
  }, []);

  const forgotPassword = () => {
    if (userName) {
      setIsLoading(true);
      Auth.forgotPassword(userName)
        .then(() => {
          setShowVerificationForm(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setAlertProps({
            display: true,
            message: intl.formatMessage({
              id: I18nKey.VERIFICATION_ERROR_MSG,
            }),
            type: 'error',
          });
          setTimeout(() => {
            setAlertProps({
              type: '',
              display: false,
              message: '',
            });
          }, 4000);
        });
    }
  };

  const submitPassword = () => {
    let validation = validatePassword(newPassword, intl);
    if (validation.length) {
      setAlertProps({
        display: true,
        message: `${intl.formatMessage({
          id: I18nKey.PASSWORD_ERROR_MSG,
        })} ${validation.join(', ')}`,
        type: 'error',
      });
      setTimeout(() => {
        setAlertProps({
          type: '',
          display: false,
          message: '',
        });
      }, 6000);
    } else if (verificationCode) {
      setIsLoading(true);
      Auth.forgotPasswordSubmit(userName, verificationCode, newPassword)
        .then(() => {
          setIsLoading(false);
          setAlertProps({
            display: true,
            message: intl.formatMessage({
              id: I18nKey.PASSWORD_RESET_MSG,
            }),
            type: 'success',
          });
          setTimeout(() => {
            setAlertProps({
              type: '',
              display: false,
              message: '',
            });
          }, 4000);
          navigate('/sign-in');
        })
        .catch((err) => {
          setIsLoading(false);
          setAlertProps({
            display: true,
            message: `${err.message}`,
            type: 'error',
          });
          setTimeout(() => {
            setAlertProps({
              type: '',
              display: false,
              message: '',
            });
          }, 4000);
        });
    }
  };

  const onClickSignIn = () => {
    navigate('/sign-in');
  };

  const onClickResendCode = () => {
    Auth.forgotPassword(userName).then((msg) => {
      setAlertProps({
        display: true,
        message: `${intl.formatMessage({
          id: I18nKey.VERIFICATION_CODE_SENT_MSG,
        })} ${msg.CodeDeliveryDetails.DeliveryMedium.toLowerCase()}`,
        type: 'success',
      });
      setTimeout(() => {
        setAlertProps({
          type: '',
          display: false,
          message: '',
        });
      }, 4000);
    });
  };

  const UsernameForm: React.FC = () => {
    return (
      <Stack height={'118%'} spacing={2} className={classes.fpStackContainer}>
        <Box>
          <Typography className={classes.label}>
            {intl.formatMessage({
              id: I18nKey.USERNAME,
            })}
          </Typography>
        </Box>
        <Box>
          <TextField
            fullWidth
            sx={SignUpFormStyles.noOutline}
            key={'username'}
            InputProps={{
              classes: {
                input: classes.resizeTextInput,
              },
            }}
            className={classes.signInInput}
            name="username"
            value={userName}
            onChange={(u) => setUserName(u.target.value)}
          />
        </Box>
        <Box height={'10%'}>
          <button className={classes.signInButton} onClick={forgotPassword}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              intl
                .formatMessage({
                  id: I18nKey.GET_VERIFICATION_CODE,
                })
                .toLocaleUpperCase()
            )}
          </button>
        </Box>
        <Box className={classes.bottomTextSubpageContainer}>
          <Typography
            fontFamily={'Roboto'}
            fontSize={'21px'}
            className={[classes.btnBackText, classes.linkStyle].join(' ')}
            onClick={onClickSignIn}
          >
            {intl.formatMessage({
              id: I18nKey.BACK_TO_SIGN_IN,
            })}
          </Typography>
        </Box>
      </Stack>
    );
  };

  const VerificationForm: React.FC = () => {
    return (
      <Stack height={'118%'} spacing={2} className={classes.fpStackContainer}>
        <Box>
          <Typography className={classes.label}>
            {intl.formatMessage({
              id: I18nKey.VERIFICATION_CODE,
            })}
          </Typography>
        </Box>

        <Box>
          <TextField
            fullWidth
            sx={SignUpFormStyles.noOutline}
            InputProps={{
              classes: {
                input: classes.resizeTextInput,
              },
            }}
            className={classes.signInInput}
            name="verificationCode"
            type="verificationCode"
            value={verificationCode}
            onChange={(u) => setVerificationCode(u.target.value)}
          />
        </Box>
        <Typography className={classes.label}>
          {intl.formatMessage({
            id: I18nKey.NEW_PASSWORD,
          })}
          <InfoTooltip title={<PasswordTooltipInfo />}></InfoTooltip>
        </Typography>

        <Box>
          <TextField
            fullWidth
            sx={SignUpFormStyles.noOutline}
            InputProps={{
              classes: {
                input: classes.resizeTextInput,
              },
            }}
            className={classes.signInInput}
            name="newPassword"
            type="password"
            value={newPassword}
            onChange={(u) => setNewPassword(u.target.value)}
          />
        </Box>

        <Box height={'10%'}>
          <button className={classes.signInButton} onClick={submitPassword}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              intl
                .formatMessage({
                  id: I18nKey.SUBMIT,
                })
                .toLocaleUpperCase()
            )}
          </button>
        </Box>
        <Box className={classes.bottomTextSubpageContainer}>
          <Typography
            fontFamily={'Roboto'}
            fontSize={'21px'}
            className={[classes.btnBackText, classes.linkStyle].join(' ')}
            onClick={onClickSignIn}
          >
            {intl.formatMessage({
              id: I18nKey.BACK_TO_SIGN_IN,
            })}
          </Typography>
          <Typography
            fontFamily={'Roboto'}
            fontSize={'21px'}
            className={[classes.btnFooterText, classes.linkStyle].join(' ')}
            onClick={onClickResendCode}
          >
            {intl.formatMessage({
              id: I18nKey.RESEND_VERIFICATION_CODE,
            })}
          </Typography>
        </Box>
      </Stack>
    );
  };

  return (
    <>
      <Typography variant="h4" className={classes.signInSubTitle}>
        {intl.formatMessage({
          id: I18nKey.RESET_PASSWORD,
        })}
      </Typography>
      {/* https://stackoverflow.com/questions/46965309/react-functional-component-calling-as-function-vs-as-component */}
      {!showVerificationForm ? UsernameForm({}) : VerificationForm({})}
    </>
  );
};

export const ForgotPassword: React.FC = () => {
  return (
    <PublicHome>
      <ForgotPasswordForm />
    </PublicHome>
  );
};
