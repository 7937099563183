import axios, { AxiosRequestConfig } from 'axios';
import { Auth } from 'aws-amplify';
import {
  getResponseFromBrowserStorage,
  getUrlKey,
  getUrlConfig,
  getUrlWithParams,
  isRequestCachable,
  isValidResponse,
  shouldSendCachedResponse,
  storeResponseInBrowserStorage,
} from './cacheUtils';
import { max_cache_age } from './cache-entry';

export const setupInterceptors = (navigate: any) => {
  axios.interceptors.request.use(function (config: AxiosRequestConfig) {
    if (config.url?.includes(process.env.REACT_APP_S3_URL || '')) return config;
    return Auth.currentSession()
      .then((session) => {
        config.headers = {
          Authorization: `Bearer ${session.getAccessToken().getJwtToken()}`,
        };
        return Promise.resolve(config);
      })
      .catch(() => {
        // No logged-in user: don't set auth header
        navigate('/logout');
        return Promise.reject(config);
      });
  });
};

axios.interceptors.response.use(function (response) {
  if (isRequestCachable(response.config) && isValidResponse(response)) {
    const urlConfig = getUrlConfig(response.config);
    storeResponseInBrowserStorage(
      getUrlKey(getUrlWithParams(response.config)),
      {
        response,
        entryTime: Date.now(),
        max_age: urlConfig?.max_age || max_cache_age,
      },
      urlConfig?.storageType
    );
  }
  return response;
});

axios.interceptors.request.use(async function (config) {
  if (isRequestCachable(config) && config.url) {
    const urlConfig = getUrlConfig(config);
    const cachedResponse = await getResponseFromBrowserStorage(
      getUrlKey(getUrlWithParams(config)),
      urlConfig?.storageType
    );
    if (
      cachedResponse &&
      shouldSendCachedResponse(cachedResponse.entryTime, cachedResponse.max_age)
    ) {
      config.adapter = function (config) {
        return new Promise((resolve, reject) => {
          return resolve(cachedResponse.response);
        });
      };
    }
  }
  return config;
});
