export const menuStyles = {
  menuFormat: {
    //width: '135px',
  },
  menuPadding: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    paddingRight: '0px !important',
  },
};
