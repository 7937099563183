import mapboxgl, { Map } from 'mapbox-gl';
import HImage from '../../../../assets/icons/H.png';
import LImage from '../../../../assets/icons/L.png';
import triangle from '../../../../assets/icons/triangle.png';
import semicircle from '../../../../assets/icons/semicircle.png';
import storm from '../../../../assets/icons/storm.png';
import moment from 'moment';

export class WeatherProductService {
  public static addWeatherLayer(
    map: Map,
    code: string,
    tileData: any,
    addMapLayerId: any,
    layerOrder: any,
    setLoading: any
  ) {
    if (code === 'HAL') {
      this.drawHighsAndLows(map, code, tileData, addMapLayerId, layerOrder);
    }
    if (code === 'FNT') {
      this.drawFronts(map, code, tileData, addMapLayerId, layerOrder);
    }

    if (code === 'STORM') {
      this.drawStorms(map, code, tileData, addMapLayerId, layerOrder);
    }

    setLoading(false);
  }

  public static drawHighsAndLows = (
    map: mapboxgl.Map,
    code: string,
    data: any,
    addMapLayerId: any,
    layerOrder: any
  ) => {
    const layerKey = code + 'weather';

    if (!map?.getSource(layerKey)) {
      map?.addSource(layerKey, {
        type: 'geojson',
        data: data.data,
      });
    }
    let images: any = { HImage: HImage, LImage: LImage };
    Object.keys(images).forEach((img: any) => {
      if (!map?.hasImage(img)) {
        map?.loadImage(images[img], (error, image) => {
          if (error) throw error;
          image && map?.addImage(img, image, { sdf: true });
        });
      }
    });
    addMapLayerId('weather', layerKey);
    map?.addLayer(
      {
        id: layerKey,
        type: 'symbol',
        source: layerKey,
        layout: {
          'icon-size': 0.7,
          'icon-image': [
            'match',
            ['get', 'letter'],
            'H',
            'HImage',
            'L',
            'LImage',
            'HImage',
          ],

          'icon-rotation-alignment': 'map',
          'text-field': ['concat', '(', ['get', 'pressure'], ')'],
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-offset': [0, 2],
          'text-anchor': 'bottom',
        },
      },
      layerOrder('weather')
    );
  };

  public static drawFronts = (
    map: mapboxgl.Map,
    code: string,
    data: any,
    addMapLayerId: any,
    layerOrder: any
  ) => {
    const layerKey = code + 'weather';
    let geojsonPoints = data.data.features.map((f: any) => {
      let feature = f;
      let coordinates = f.geometry.coordinates.map((c: any) => [c[1], c[0]]);
      feature.geometry.coordinates = coordinates;
      return feature;
    });

    addMapLayerId('weather', layerKey);
    if (!map?.getSource(layerKey)) {
      map?.addSource(layerKey, {
        type: 'geojson',
        data: {
          ...data.data,
          features: geojsonPoints,
        },
      });

      let images: any = { triangle: triangle, semicircle: semicircle };
      Object.keys(images).forEach((img: any) => {
        if (!map?.hasImage(img)) {
          map?.loadImage(images[img], (error, image) => {
            if (error) throw error;
            image && map?.addImage(img, image, { sdf: true });
          });
        }
      });
      map?.addLayer(
        {
          id: layerKey,
          type: 'line',
          source: layerKey,
          layout: {},
          paint: {
            'line-width': 3,
            'line-color': [
              'match',
              ['get', 'frontType'],
              'warm',
              'red',
              'cold',
              'blue',
              'occluded',
              'purple',
              'red',
            ],
          },
        },
        layerOrder('weather')
      );

      map?.addLayer(
        {
          id: layerKey + 'Icons',
          type: 'symbol',
          source: layerKey,
          layout: {
            'symbol-placement': 'line',
            'symbol-spacing': 50,
            'icon-size': 0.4,
            'icon-image': [
              'match',
              ['get', 'frontType'],
              'warm',
              'semicircle',
              'cold',
              'triangle',
              'occluded',
              'triangle',
              '',
            ],
            'icon-rotate': 180,
            'icon-anchor': 'bottom',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
          },
          paint: {
            'icon-color': [
              'match',
              ['get', 'frontType'],
              'warm',
              'red',
              'cold',
              'blue',
              'occluded',
              'purple',
              'red',
            ],
          },
        },
        layerOrder('weather')
      );
      map?.addLayer(
        {
          id: layerKey + 'iconsOccluded',
          type: 'symbol',
          source: layerKey,
          layout: {
            'symbol-placement': 'line',
            'symbol-spacing': 100,
            'icon-size': 0.4,
            'icon-image': [
              'match',
              ['get', 'frontType'],
              'occluded',
              'semicircle',
              '',
            ],
            'icon-rotate': 180,
            'icon-anchor': 'bottom',
            'icon-ignore-placement': true,
          },
          paint: {
            'icon-color': [
              'match',
              ['get', 'frontType'],
              'occluded',
              'purple',
              'red',
            ],
          },
        },
        layerOrder('weather')
      );
    }
  };

  static createStormGeoJSON = (data: any) => {
    data.features.forEach((pos: any) => {
      if (pos.geometry.type === 'Point') {
        let dateTime = moment(pos.properties.time).utc();
        pos.properties['label'] = `${dateTime.format('DD')}/${dateTime.format(
          'HH'
        )}Z${pos.properties.hcp === 'C' ? '\n' + pos.properties.name : ''}`;
        if (
          Object.keys(pos.properties).includes('radii') &&
          pos.properties?.radii.length
        ) {
          let radiiUpdated: any = [];
          pos.properties.radii.forEach((th: any) => {
            let radData = {};
            const R = 6378.1; //radius of the earth
            //bearings in radians
            const bearingNE = (45 * Math.PI) / 180;
            const bearingSE = (135 * Math.PI) / 180;
            const bearingSW = (225 * Math.PI) / 180;
            const bearingNW = (315 * Math.PI) / 180;

            const bearings = [bearingNE, bearingSE, bearingSW, bearingNW];
            //distance in kms =>nautical miles to kms
            const distNE = th.ne * 1.852;
            const distNW = th.nw * 1.852;
            const distSW = th.sw * 1.852;
            const distSE = th.se * 1.852;
            const distances = [distNE, distSE, distSW, distNW];
            let points: any = [];
            bearings.forEach((b, i) => {
              let lat = (pos.properties.lat * Math.PI) / 180; // center coords to radians
              let lon = (pos.properties.lon * Math.PI) / 180;
              // Calculate lat/lon for all 4 directions
              lat = Math.asin(
                Math.sin(lat) * Math.cos(distances[i] / R) +
                  Math.cos(lat) * Math.sin(distances[i] / R) * Math.cos(b)
              );
              lon += Math.atan2(
                Math.sin(b) * Math.sin(distances[i] / R) * Math.cos(lat),
                Math.cos(distances[i] / R) - Math.sin(lat) * Math.sin(lat)
              );

              points.push([(lon * 180) / Math.PI, (lat * 180) / Math.PI]);
            });
            radData = { ...th, points: [...points, points[0]] };
            radiiUpdated.push(radData);
          });
          pos.properties.radii = radiiUpdated;
        }
      }
    });
  };

  public static drawStorms = (
    map: mapboxgl.Map,
    code: string,
    data: any,
    addMapLayerId: any,
    layerOrder: any
  ) => {
    const layerKey = code + 'weather';
    this.createStormGeoJSON(data.data);
    addMapLayerId('weather', layerKey);

    if (!map?.hasImage('storm')) {
      map?.loadImage(storm, (error, image) => {
        if (error) throw error;
        image && map?.addImage('storm', image, { sdf: true });
      });
    }

    if (!map?.getSource(layerKey)) {
      map?.addSource(layerKey, {
        type: 'geojson',
        data: data.data,
      });

      map?.addLayer(
        {
          id: layerKey,
          type: 'line',
          source: layerKey,
          filter: ['==', '$type', 'LineString'],
          layout: {},
          paint: {
            'line-width': 3,
            'line-color': 'black',
          },
        },
        layerOrder('weather')
      );

      map?.addLayer(
        {
          id: layerKey + 'Icons',
          type: 'symbol',
          source: layerKey,
          filter: ['==', '$type', 'Point'],
          layout: {
            'icon-size': 0.3,
            'icon-image': 'storm',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'text-field': ['get', 'label'],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-size': ['match', ['get', 'hcp'], 'C', 17, 15],
            'text-offset': [2, 0],
            'text-anchor': 'left',
          },
          paint: {
            'icon-color': { type: 'identity', property: 'stormPosColor' },
          },
        },
        layerOrder('weather')
      );
    }
  };
}
