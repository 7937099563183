import { Box, FormControlLabel, FormGroup, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../../../translations/I18nKey';
import { ColorBox } from '../../../../components/ColorBox/ColorBox';
import { PopupHeader } from '../../../../components/SettingsPopup/PopupHeader/PopupHeader';
import { SettingsPopup } from '../../../../components/SettingsPopup/SettingsPopup';
import { Toggle } from '../../../../components/Toggle/Toggle';
import { AnalysisRecommendationStyles } from '../AnalysisStyles';
import { PopupFooter } from '../../../../components/SettingsPopup/PopupFooter/PopupFooter';

export const AnalysisRecommendationPopup: React.FC<any> = ({
  recommendationLayerList,
  setRecommendationLayerList,
  onClose,
  onRecommendationLegendSettingsApply,
}) => {
  const intl = useIntl();
  const [colorMapping, setColorMapping] = useState<any>([]);
  const [selectAll, setSelectAll] = useState<boolean>(true);

  const footerItems = {
    action: {
      text: I18nKey.ANALYSIS_APPLY,
      onClick: onRecommendationLegendSettingsApply,
    },
  };

  useEffect(() => {
    let allToggleState = true;
    const data = recommendationLayerList.map((element: any) => {
      if (!element.visible) {
        allToggleState = false;
      }
      return {
        ...element,
        name: element.name,
        color: element.color,
      };
    });
    setColorMapping(data);
    setSelectAll(allToggleState);
  }, [recommendationLayerList]);

  const onToggleChange = (event: any) => {
    if (event.target.name === 'all') {
      setSelectAll(event.target.checked);
    }
    const newRecommendationList = recommendationLayerList.map((item: any) => {
      if (event.target.name !== 'all') {
        if (item.name === event.target.name) {
          return { ...item, visible: event.target.checked };
        }
        return item;
      } else {
        return { ...item, visible: event.target.checked };
      }
    });

    setRecommendationLayerList(newRecommendationList);
  };

  return (
    <SettingsPopup
      popupStyle={{
        wrapper: {
          height: '333px',
          maxHeight: '333px',
        },
      }}
      headerElement={
        <PopupHeader
          title={I18nKey.ANALYSIS_RECOMMENDATIONS}
          onClose={onClose}
          headerStyle={{
            header: {
              marginLeft: '10px',
            },
          }}
        ></PopupHeader>
      }
      bodyElement={
        <Grid
          container
          rowSpacing={1}
          sx={{
            maxHeight: '200px',
            overflowY: 'scroll',
            overflowX: 'hidden',
            marginLeft: 0,
            width: '100%',
            mt: 1,
            ml: '10px',
          }}
        >
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                sx={{
                  color: '#fff',
                  marginLeft: '0px',
                }}
                control={
                  <Toggle
                    name="all"
                    checked={selectAll}
                    onChange={onToggleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={intl.formatMessage({
                  id: I18nKey.ANALYSIS_ALL,
                })}
                labelPlacement="end"
              />
            </FormGroup>
          </Grid>
          {colorMapping.map((element: any) => {
            return (
              <Grid item xs={6} key={element.name}>
                <Grid container columnSpacing={2}>
                  <Grid item xs={7} sx={{ pt: 0 }}>
                    <FormGroup>
                      <FormControlLabel
                        sx={{
                          color: '#fff',
                          marginLeft: '0px',
                        }}
                        control={
                          <Toggle
                            checked={element.visible}
                            onChange={onToggleChange}
                            name={element.name}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label={_.startCase(_.toLower(element.name))}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={4}>
                    <Box sx={{ width: '51px' }}>
                      <ColorBox
                        color={element.color}
                        boxStyle={AnalysisRecommendationStyles.colorBox}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      }
      footerElement={<PopupFooter actionButton={footerItems.action} />}
    ></SettingsPopup>
  );
};
