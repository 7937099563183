import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useIntl } from 'react-intl';
import {
  Box,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContextSelector } from 'use-context-selector';
import { useStyles } from '../../utils/util';
import { AlertContext } from '../Contexts/AuthContext';
import { I18nKey } from '../../translations/I18nKey';
import { PublicHome } from './PublicHome';
import { setUserAttributes } from '../../utils/auth';
import { SignUpFormStyles } from './PublicHomeStyles';
import './PublicHome.scss';
import { LicensedFeaturesContext } from '../Contexts/LicensedFeaturesContext';

const SignInForm = () => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [alertProps, setAlertProps] = useContextSelector(
    AlertContext,
    (state) => state
  );
  const { fetchFeatures } = useContextSelector(
    LicensedFeaturesContext,
    (state) => state
  );
  const classes = useStyles(SignUpFormStyles, [])();
  const navigate = useNavigate();
  const intl = useIntl();

  const onSignIn = () => {
    setIsLoading(true);
    Auth.signIn(userName, password)
      .then(({ username, attributes }) => {
        setUserAttributes(username, attributes);
        fetchFeatures();
        setTimeout(() => {
          setAlertProps({
            type: '',
            display: false,
            message: '',
          });
        }, 4000);
        navigate('/');
      })
      .catch((err) => {
        if (err.code === 'UserNotConfirmedException') {
          setAlertProps({
            display: true,
            message: intl.formatMessage({
              id: I18nKey.COMPLETE_ACCOUNT_VERIFICATION,
            }),
            type: 'error',
          });
          setTimeout(() => {
            setAlertProps({
              type: '',
              display: false,
              message: '',
            });
          }, 4000);
          Auth.resendSignUp(userName).then((msg) => {
            navigate('/confirm-sign-up', { state: { userName } });
          });
        } else {
          setAlertProps({
            display: true,
            message: intl.formatMessage({
              id: I18nKey.LOGIN_ERROR_MSG,
            }),
            type: 'error',
          });
          setTimeout(() => {
            setAlertProps({
              type: '',
              display: false,
              message: '',
            });
          }, 4000);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onKeypress = (e: any) => {
    if (e.charCode === 13) onSignIn();
  };

  const onClickForgotPassword = () => {
    navigate('/forgot-password');
  };

  return (
    <Box height={'65%'}>
      <Box>
        <Typography variant={'h4'} className={classes.signInSubTitle}>
          {intl.formatMessage({
            id: I18nKey.SIGN_IN_TO_YOUR_ACCOUNT,
          })}
        </Typography>
      </Box>
      <Stack height={'118%'} margin={'10% 8% 0%'} spacing={2}>
        <Box height={'2%'}>
          <Typography className={classes.label}>
            {intl.formatMessage({
              id: I18nKey.USERNAME,
            })}
          </Typography>
        </Box>
        <Box height={'14%'}>
          <TextField
            fullWidth
            sx={SignUpFormStyles.noOutline}
            InputProps={{
              classes: {
                input: classes.resizeTextInput,
              },
              sx: { height: '-webkit-fill-available' },
            }}
            className={[classes.signInInput, classes.textFieldStyle].join(' ')}
            name="username"
            value={userName}
            onChange={(u) => setUserName(u.target.value)}
          />
        </Box>
        <Box height={'2%'}>
          <Typography className={classes.label}>
            {intl.formatMessage({
              id: I18nKey.PASSWORD,
            })}
          </Typography>
        </Box>
        <Box height={'14%'}>
          <TextField
            fullWidth
            InputProps={{
              classes: {
                input: classes.resizeTextInput,
              },
            }}
            classes={{ root: classes.root }}
            className={classes.signInInput}
            sx={SignUpFormStyles.noOutline}
            name="password"
            type="password"
            value={password}
            onKeyPress={onKeypress}
            onChange={(u) => setPassword(u.target.value)}
          />
        </Box>
        <Box height={'20%'}>
          <button className={classes.signInButton} onClick={onSignIn}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              intl
                .formatMessage({
                  id: I18nKey.SIGN_IN,
                })
                .toLocaleUpperCase()
            )}
          </button>
          <Box height={'10%'}>
            <Typography
              fontFamily={'Roboto'}
              fontSize={'21px'}
              className={[classes.btnFooterText, classes.linkStyle].join(' ')}
              onClick={onClickForgotPassword}
            >
              {intl.formatMessage({
                id: I18nKey.FORGOT_PASSWORD,
              })}
            </Typography>
          </Box>
          <Box height={'2%'}>
            <Typography style={SignUpFormStyles.lineBreak}></Typography>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export const SignIn: React.FC = () => {
  return (
    <PublicHome>
      <SignInForm />
    </PublicHome>
  );
};
