export const IUUSettingsStyles: any = {
  dateWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  dateDurationText: {
    fontSize: '16px',
    color: '#6F6F6F',
  },
  dateFormControl: {
    minWidth: 120,
    backgroundColor: '#242424',
    color: 'white',
  },
  dateSelect: {
    color: 'white',
    '& .MuiSelect-icon': {
      color: 'white',
      opacity: '0.5',
    },
  },
  tabRoot: {
    color: '#6F6F6F !important',
    fontSize: '16px',
  },
  tabSelected: {
    color: 'white !important',
    fontSize: '16px',
  },
  tabIndicator: {
    style: { background: '#FFA901', marginTop: '20px', height: '3px' },
  },
};

export const tabIndicatorProps: any = {
  style: {
    background: 'orange',
    marginTop: '20px',
    height: '3px',
  },
};

export const IUUTimelineStyles: any = {
  closeIcon: {
    width: '35px',
    height: '35px',
    position: 'absolute',
    right: '-15px',
    top: '-15px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  settingsIcon: {
    width: '35px',
    height: '35px',
    position: 'absolute',
    left: '-15px',
    top: '-15px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  lineStyle: {
    height: '1px',
    backgroundColor: '#242424',
    border: 'none',
  },
};
