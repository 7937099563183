export const toggleStyles: any = {
  marginRight: '12px',
  marginLeft: '12px',
  width: 27,
  height: 15,
  padding: 0,
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 12,
    height: 12,
  },
  '& .MuiSwitch-switchBase': {
    top: '-52%',
    left: '-25%',
    '&.Mui-checked': {
      color: 'orange',
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        transform: 'translateX(-80%)',
        width: 12,
        height: 12,
      },
      '& + .MuiSwitch-track': {
        backgroundColor: '#3d3d3d',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 1,
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        backgroundColor: 'orange',
        opacity: 0.5,
      },
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#ffffff70',
      backgroundColor: '#3D3D3D',
      width: 12,
      height: 12,
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 1,
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: 'gray',
  },
};
