import { FC, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import { VariantType, closeSnackbar, useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Pagination from '@mui/material/Pagination';
import FishCashMultiSearch from './FishCatchMultiSearch';

import { useQuery } from 'react-query';
import FishCatchClient from '../../../api/fishCatchAPIs';
import FishCatchLoadingList from './FishCatchLoadingList';
import FishCatchErrorList from './FishCatchErrorList';
import FishCatchCard from './FishCatchCard';
import { useFetchFishCatch } from '../store/FishCatchStore';
import { getUserAttributes } from '../../../utils/auth';

const FishCatchDrawerInfo: FC<any> = ({ fileID }) => {
  const { userName, seastarId } = getUserAttributes();
  // --- Store ---
  const reFetch = useFetchFishCatch((state) => state.fetchRecord);
  const setReFetch = useFetchFishCatch((state) => state.setReFetchRecord);

  // --- State Manager ---
  const [hideSearch, setHideSearch] = useState<boolean>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  // --- Setting CheckUp of ID ---
  const [fileIdData, setFileIDData] = useState<string>('');

  // --- Fetch Data Function ---
  const getFishCatchRecords = async () => {
    const response = await FishCatchClient.getFishCatchAllRecords(
      userName,
      fileID
    );
    return response.results;
  };

  // --- React Query: GET ---
  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    refetch: RecordsFishCatch,
  } = useQuery({
    queryFn: getFishCatchRecords,
    enabled: false,
    onSuccess(data) {
      setRecordData(data);
    },
  });
  const [recordData, setRecordData] = useState<any[]>(data);
  // --- Toast Message ---
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    RecordsFishCatch();
  }, []);

  useEffect(() => {
    if (reFetch) {
      RecordsFishCatch();
      setReFetch();
    }
  }, [reFetch]);

  const action = (snackbarId: any) => {
    return (
      <IconButton
        aria-label="close-toast"
        onClick={() => closeSnackbar(snackbarId)}
      >
        <CloseIcon />
      </IconButton>
    );
  };

  // --- Call Toast ---
  const callToast = (variant: VariantType, message: any) => {
    enqueueSnackbar(message, {
      variant,
      action,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
  };

  // --- Handle the dialog ---
  const handleClickOpen = () => {
    setOpenDialog(true);
    setHideSearch(false);
    setSearchSelection([]);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  // --- Handle the search block ---
  const handleSearchOpen = () => {
    if (searchSelection.length === 0) {
      callToast('error', 'Must select an option to search!');
    } else {
      setHideSearch(true);
      setOpenDialog(false);
    }
  };

  const handleSearchClose = () => {
    setHideSearch(false);
    setRecordData(data);
  };

  // --- Style of input checks ---
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300,
      },
    },
  };

  // --- Check Values ---
  const searchInputs = [
    'Vessel Name',
    'Catch Date',
    'Fishing Technique',
    'Total Quantity',
    'Comments',
    'Species',
    // 'Latitude',
    // 'Longitude',
  ];

  const [searchSelection, setSearchSelection] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof searchSelection>) => {
    const {
      target: { value },
    } = event;
    setSearchSelection(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  if (isLoading || isFetching) {
    return <FishCatchLoadingList option1={false} option2={true} />;
  }

  if (isError) {
    if (error instanceof Error) {
      callToast('error', error.message);
    }

    return <FishCatchErrorList reFetchData={setReFetch} />;
  }

  return (
    <div>
      {/* Presents the Dialog of the Search Inputs */}
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>Select values wished to search</DialogTitle>
        <DialogContent>
          <FormControl sx={{ m: 1, width: 300 }}>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={searchSelection}
              onChange={handleChange}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
              style={{ border: '1px solid #CDCDCD' }}
            >
              {searchInputs.map((search) => (
                <MenuItem key={search} value={search}>
                  <Checkbox checked={searchSelection.indexOf(search) > -1} />
                  <ListItemText primary={search} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSearchOpen}>Okay</Button>
        </DialogActions>
      </Dialog>

      {/* Presents the drawer content */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '30vw',
          height: '90vh',
        }}
      >
        <div
          style={{
            margin: '10px 4rem 10px 0rem',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
          }}
        >
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            style={{
              background: '#193768',
              borderRadius: '5px',
              border: '1px solid rgba(0, 0, 0, 0.25)',
            }}
            onClick={handleClickOpen}
          >
            Search
          </Button>
          <Button
            variant="contained"
            startIcon={<CleaningServicesIcon />}
            style={{
              background: '#FFA500',
              borderRadius: '5px',
              border: '1px solid rgba(0, 0, 0, 0.25)',
            }}
            onClick={handleSearchClose}
          >
            Clear
          </Button>
        </div>
        {hideSearch === true && searchInputs.length !== 0 && (
          <div
            style={{
              margin: '10px 3rem 10px 0.5rem',
              border: '1px solid #CDCDCD',
              padding: '10px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'center',
              width: '70%',
            }}
          >
            <FishCashMultiSearch
              selectedValues={searchSelection}
              data={data}
              setFilteredData={setRecordData}
            />
          </div>
        )}

        {/* Placing all the cards in a list */}
        <div
          style={{
            overflowY: 'scroll',
            marginBottom: '30px',
          }}
        >
          <FishCatchCard
            data={recordData}
            isMultiSearch={hideSearch}
            amountItemsSearch={searchSelection.length}
          />
        </div>

        {/* Contains the pagination of the cards */}
        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '10px 4rem 50px 0rem',
          }}
        >
          <Pagination count={5} variant="outlined" shape="rounded" />
        </div> */}
      </div>
    </div>
  );
};

export default FishCatchDrawerInfo;
