export const iconGridStyles = {
  gridStyle: {
    width: 177,
    border: '1px solid #707070',
    backgroundColor: '#4D4D4D !important',
    borderRadius: '12px !important',
    transition: 'none !important',
    marginBottom: '20px',
  },
  headerStyle: {
    minHeight: '43px !important',
    maxHeight: '43px !important',
    backgroundColor: '#171717 !important',
    borderRadius: '12px !important',
    color: '#FFFFFF !important',
    border: '1px solid #707070 !important',
    transition: 'none !important',
  },
  panelExpanded: {
    transition: 'none !important',
  },
  expandIconStyle: {
    color: '#B2B2B2',
  },
  contentStyle: {
    padding: '0px 0px 0px 8px !important',
    marginTop: '-12px !important',
    backgroundColor: '#4D4D4D',
    borderRadius: '0px 0px 12px 12px !important',
  },
  empty: {
    width: '74px',
    height: '44px',
  },
};
