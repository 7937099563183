import _, { isEmpty } from 'lodash';
import moment from 'moment';
import mapboxgl from 'mapbox-gl';
import { VESSEL_TYPE_COLORS } from '../../constants/Constants';
import { FENCE_CHART_COLORS } from '../Map/IUU/config';
import {
  GeofenceIcons,
  SunburstConfig,
  tableDateFormat,
  vesselLayout,
} from './DashboardConfig';
import { getRandomColor } from '../../utils/util';
import breachCircleRed from '../../assets/icons/breach_circle_red.png';
import aisShipIcon from '../../assets/icons/ais_ship.png';
import { BreachVesselDetails, GeofenceDetails, Geojson } from '../types';
import { FillAreaService } from '../Map/Products/MapLayers/FillAreaService';
import axios from 'axios';
export interface selectedFilters {
  country?: any[];
  vesselTypeList?: any[];
  fenceNameList?: any[];
}

export class IUUDashboardService {
  static regroupAll = (filteredData: any[], isBreachData: boolean) => {
    const vesselTypesGrouping: Record<string, any> = {};
    const fenceNamesGrouping: Record<string, any> = {};
    let res: any = {};
    res = _.groupBy(
      filteredData,
      isBreachData ? 'vessel.flagCountry' : 'flagCountry'
    );
    _.map(res, function (item, itemId) {
      vesselTypesGrouping[itemId] = _.countBy(
        item,
        isBreachData ? 'vessel.vesselType' : 'vesselType'
      );
      if (isBreachData)
        fenceNamesGrouping[itemId] = _.countBy(item, 'geofence.geofenceName');
    });
    return [vesselTypesGrouping, fenceNamesGrouping];
  };

  static getfilteredBreachData = (
    data: any,
    selectedCountry: any[],
    selectedVesselType: any[],
    selectedFenceName: any[]
  ) => {
    const filteredData = _.filter(data, (o) => {
      return (
        selectedCountry.some(
          (c: any) => c.country === o['vessel']['flagCountry']
        ) &&
        selectedFenceName.includes(o['geofence']['geofenceName']) &&
        selectedVesselType.some((t) => t.type === o['vessel']['vesselType'])
      );
      // if (fromDate && toDate) {
      //   this.filteredBreachData = _.filter(this.filteredBreachData, (o) => {
      //     const breachDate = new Date(o['breachDate']);
      //     if (fromDate && toDate) {
      //       return breachDate >= fromDate && breachDate <= toDate;
      //     } else return;
      //   });
      // }
    });
    return filteredData;
  };

  static getfilteredVesselData = (
    data: any,
    selectedCountry: any[],
    selectedVesselType: any[]
  ) => {
    const filteredData = _.filter(data, (o) => {
      return (
        selectedCountry.some((c: any) => c.country === o['flagCountry']) &&
        selectedVesselType.some((t) => t.type === o['vesselType'])
      );
    });
    return filteredData;
  };

  static getfilteredFenceData(fenceData: any, selectedFenceName: any[]) {
    const filteredFenceData = _.filter(fenceData, (o) => {
      return selectedFenceName.includes(o['name']);
    });
    return filteredFenceData;
  }

  static renderCharts = (filteredData: any[], isBreachData: boolean) => {
    const [groupedByVesselTypes, groupedByFenceNames] = this.regroupAll(
      filteredData,
      isBreachData
    );
    let vesselTypeBarChartData: any = [];
    let fenceNameBarChartData: any = [];
    vesselTypeBarChartData = this.getChartData(
      _.uniq(
        _.map(filteredData, isBreachData ? 'vessel.vesselType' : 'vesselType')
      ),
      _.uniq(
        _.map(filteredData, isBreachData ? 'vessel.flagCountry' : 'flagCountry')
      ),
      'vessel',
      groupedByVesselTypes
    );
    if (isBreachData) {
      fenceNameBarChartData = this.getChartData(
        _.uniq(_.map(filteredData, 'geofence.geofenceName')),
        _.uniq(_.map(filteredData, 'vessel.flagCountry')),
        'fence',
        groupedByFenceNames
      );
    }
    return [vesselTypeBarChartData, fenceNameBarChartData];
  };

  static renderSunburstChart = (
    filteredBreachData: any[],
    filteredGeofenceData: any[]
  ) => {
    const fenceSunburstData = this.setSunburstChartData(
      filteredGeofenceData,
      filteredBreachData
    );
    return fenceSunburstData;
  };

  static getTotalBreaches(data: any[]) {
    const totalBreaches = data.length;
    const countByCountries = _.countBy(data, 'vessel.flagCountry');
    const countByVesselType = _.countBy(data, 'vessel.vesselType');
    const maxBreachesCountry = _.maxBy(_.keys(countByCountries), function (o) {
      return countByCountries[o];
    });
    const maxBreachesVessel = _.maxBy(_.keys(countByVesselType), function (o) {
      return countByVesselType[o];
    });
    return [totalBreaches, maxBreachesCountry, maxBreachesVessel];
  }

  static setGeofenceData = (geofenceData: any) => {
    let fenceData = geofenceData.map((d: any) => {
      return {
        id: d.id,
        name: d.name,
        type: d.type,
        active: d.active,
        fenceTypeIcon: GeofenceIcons[d.active],
      };
    });
    return fenceData;
  };

  static getGeofenceGeojson = async (
    map: mapboxgl.Map | null,
    fence: GeofenceDetails
  ) => {
    const geojson = await axios.get(fence.url || '');
    if (map) {
      FillAreaService.addFillAreaLayer(
        map,
        'breachGeofence' + fence.id,
        geojson.data,
        true,
        {
          'fill-color': fence.display.fillColor,
          'fill-opacity': 0.1,
        },
        true,
        'geofence',
        true,
        {
          'line-color': fence.display.strokeColor,
          'line-width': 3,
        },
        () => {},
        () => {}
      );
    }
  };

  static getDataForFilters = (
    data: any,
    filterKey: string,
    objectKey: string
  ) => {
    return _.uniq(_.map(data, filterKey)).map((c) => {
      return {
        [objectKey]: c,
      };
    });
  };

  static getChartData = (
    yAxisList: any[],
    xAxisList: string[],
    graphType: string,
    groupedData: Record<any, any>
  ) => {
    const trace = [];

    for (const k of yAxisList) {
      const y = [];
      for (const c of xAxisList) {
        y.push(groupedData[c][k] || 0);
      }
      trace.push({
        x: xAxisList,
        y: y, //count of fence_type per country
        name: k, //Fishing, fence_type
        type: 'bar',
        width: 0.4,
        tickformat: '.0f',
        marker:
          graphType === 'fence'
            ? {
                color: getRandomColor(),
              }
            : { color: VESSEL_TYPE_COLORS[k] },
      });
    }

    return trace;
  };

  static setSunburstChartData = (fenceData: any, breachData: any) => {
    const centralNode = 'Geofences';
    let fenceList = [centralNode];
    fenceList = fenceList.concat(_.keys(FENCE_CHART_COLORS));
    const fenceTypeList = ['', centralNode, centralNode];
    const totalBreaches = breachData.length;
    const countSafeAlert = _.countBy(breachData, 'geofence.geofenceType');
    const countVesselsByFence = _.countBy(breachData, 'geofence.geofenceName');
    const vesselValues = [
      totalBreaches,
      countSafeAlert['safe'] || 0,
      countSafeAlert['alert'] || 0,
    ];

    fenceData.forEach((f: any) => {
      fenceList.push(f.name);
      fenceTypeList.push(_.find(fenceData, ['name', f.name]).type);
      vesselValues.push(countVesselsByFence[f.name] || 0);
    });

    const colors = fenceList?.map((c: any) => {
      return FENCE_CHART_COLORS[c] ? FENCE_CHART_COLORS[c] : '';
    });

    let data = [
      _.merge(
        {
          labels: fenceList,
          parents: fenceTypeList,
          values: vesselValues,
          marker: {
            colors: colors,
          },
        },
        SunburstConfig
      ),
    ];
    return data;
  };

  static getTableData(filteredBreachData: any, geofenceData: any) {
    let formattedTableData = filteredBreachData.map((data: any) => {
      return {
        breachDate: this.dateToString(data.breachDate, tableDateFormat),
        vesselId: data.vessel.vesselId,
        vesselName: data.vessel.vesselName,
        vesselType: data.vessel.vesselType,
        vesselSource: data.vessel.source,
        flagCountry: data.vessel.flagCountry,
        speed: data.vessel.sog,
        geofenceType: data.geofence.geofenceType,
        geofenceName: data.geofence.geofenceName,
        geofenceId: data.geofence.geofenceId,
        isAlertEnabled: this.getAlertEnabledVessel(
          data.vessel.source,
          geofenceData,
          data.geofence.geofenceId,
          data.vessel.vesselId
        ),
      };
    });
    return formattedTableData;
  }

  static getAlertEnabledVessel = (
    source: any,
    geofenceData: any,
    geofenceId: any,
    vesselId: any
  ) => {
    const fence = _.find(geofenceData, (g) => {
      return g.id === geofenceId;
    });
    return source === 'AIS'
      ? !fence.aisWatchFilter?.avoid?.includes(vesselId)
      : !fence.vmsWatchFilter?.avoid?.includes(vesselId);
  };

  static dateToString(
    date: Date,
    dateFormat = 'YYYY-MM-DD',
    convertToUtc = false
  ): string {
    if (convertToUtc) {
      return moment(date).format(dateFormat);
    }
    return moment(date).format(dateFormat);
  }

  //Map
  static createBreachedVesselsGeojson(allVesselsData: any) {
    const breachVesselPositions: Geojson = {
      type: 'FeatureCollection',
      features: [],
    };
    if (allVesselsData) {
      Object.values(allVesselsData).forEach((vessels: any) => {
        breachVesselPositions.features.push(
          this.getVesselPointGeojson(vessels.vessel)
        );
      });
    }
    return breachVesselPositions;
  }

  static getVesselPointGeojson(vessel: any): any {
    return {
      type: 'Feature',
      properties: {
        vesselId: vessel.vesselId,
        color: VESSEL_TYPE_COLORS[vessel.vesselType],
        course: vessel.otherDetails.cog,
        date: vessel.date,
        name: vessel.vesselName,
        lon: vessel.longitude,
        lat: vessel.latitude,
      },
      geometry: {
        type: 'Point',
        coordinates: [vessel.longitude, vessel.latitude],
      },
    };
  }
  // Breach vessels draw on Map
  static addBreachVesselsLayer = (
    map?: mapboxgl.Map | null,
    breachVesselDrawObject?: any
  ) => {
    // map?.on('load', () => {
    if (map) {
      if (!map?.hasImage('breachCircleRed')) {
        map?.loadImage(breachCircleRed, (error: any, image: any) => {
          if (error) throw error;
          image && map?.addImage('breachCircleRed', image, { sdf: true });
        });
      }
      if (!map?.hasImage('breachVessels')) {
        map?.loadImage(aisShipIcon, (error: any, image: any) => {
          if (error) throw error;
          image && map?.addImage('breachVessels', image, { sdf: true });
        });
      }
      if (!map?.getSource(breachVesselDrawObject.layerId)) {
        map?.addSource(breachVesselDrawObject.layerId, {
          type: 'geojson',
          data: breachVesselDrawObject.source,
        });

        map?.addLayer({
          id: breachVesselDrawObject.layerId,
          type: 'symbol',
          source: breachVesselDrawObject.layerId,
          layout: {
            'icon-size': 0.4,
            'icon-rotation-alignment': 'map',
            'symbol-placement': 'point',
            'icon-allow-overlap': true,
            'icon-image': 'breachCircleRed',
          }, //move this to config
          paint: {
            'icon-color': 'red',
          },
        });
      }
      if (!map?.getSource('breachLayer')) {
        map?.addSource('breachLayer', {
          type: 'geojson',
          data: breachVesselDrawObject.source,
        });

        map?.addLayer({
          id: 'breachLayer',
          type: 'symbol',
          source: 'breachLayer',
          layout: {
            'icon-size': 0.4,
            'icon-rotation-alignment': 'map',
            'symbol-placement': 'point',
            'icon-allow-overlap': true,
            'icon-image': 'breachVessels',
          }, //move this to config
          paint: {
            'icon-color': { type: 'identity', property: 'color' },
          },
        });
        map?.setLayoutProperty('breachLayer', 'icon-rotate', {
          type: 'identity',
          property: 'course',
        });
      }
    }
    // });
  };

  static removeAllBreachLayers = (
    map: mapboxgl.Map | null,
    sourceId: string
  ) => {
    if (map) {
      if (map.getSource(sourceId)) {
        this.removeLayer(map, sourceId);
        map.removeSource(sourceId);
      }
    }
  };

  static removeLayer(map: mapboxgl.Map, layerId: string) {
    if (map?.getLayer(layerId)) map.removeLayer(layerId);
  }

  static onVesselClick(vesselData: any) {
    let selectedVessel: any = {};
    if (vesselData !== selectedVessel) {
      selectedVessel = vesselData;
    }
    return {
      vesselId: selectedVessel.vesselId,
    };
  }

  static getTimeFilterBounds(getDate = false) {
    const toDate = new Date();
    const fromDate = new Date(toDate);
    fromDate.setDate(fromDate.getDate() - 7);
    if (getDate) return [toDate, fromDate];
    else
      return [
        this.dateToString(toDate, 'YYYYMMDDHHmmss', true),
        this.dateToString(fromDate, 'YYYYMMDDHHmmss', true),
      ];
  }

  static renderVesselTrackData(
    showVesselId: string,
    vesselData?: any,
    breachVesselData?: any
  ) {
    const vesselPosition: Geojson = {
      type: 'FeatureCollection',
      features: [],
    };
    if (!isEmpty(breachVesselData)) {
      vesselPosition.features.push(
        this.getVesselPointGeojson(breachVesselData.vessel)
      );
    }
    const currentVesselPosition: Geojson = {
      type: 'FeatureCollection',
      features: [],
    };
    const vesselPathData: Geojson = {
      type: 'FeatureCollection',
      features: [],
    };
    const latlon: number[][] = [];
    const sortedVesselData = _.sortBy(vesselData, function (dateObj) {
      return new Date(breachVesselData ? dateObj.breachDate : dateObj.date);
    });
    const currentPosition = sortedVesselData[vesselData.length - 1];
    currentVesselPosition.features.push(
      this.getVesselPointGeojson(currentPosition)
    );
    sortedVesselData.map((data: any) => {
      const values: string[] = data.positions.split(',');
      latlon.push([parseFloat(values[1]), parseFloat(values[0])]);
    });
    vesselPathData.features.push({
      type: 'Feature',
      properties: {},
      geometry: { type: 'LineString', coordinates: latlon },
    });
    const vesselPointData = this.getTrackPointJSON(sortedVesselData);
    return [
      vesselPathData,
      currentVesselPosition,
      vesselPointData,
      vesselPosition,
    ];
  }
  public static getTrackPointJSON = (sortedVesselData: any) => {
    const vesselPointData: Geojson = {
      type: 'FeatureCollection',
      features: [],
    };

    sortedVesselData.forEach((data: any) => {
      let point = {
        type: 'Feature',
        properties: {
          date: data.date,
          name: data.vesselName,
          vesselId: data.vesselId,
        },
        geometry: {
          type: 'Point',
          coordinates: [data.longitude, data.latitude],
        },
      };

      vesselPointData.features.push(point);
    });

    return vesselPointData;
  };

  static addVesselTrackToMap(
    map: mapboxgl.Map | null,
    vesselPathData: Geojson,
    vesselPosition: Geojson,
    vesselPointData: Geojson,
    currentVesselPosition: Geojson
  ) {
    if (!map?.getSource('vesselPath')) {
      map?.addSource('vesselPath', {
        type: 'geojson',
        data: vesselPathData,
      });
      map?.addLayer({
        id: 'vesselPathLine',
        type: 'line',
        source: 'vesselPath',
        paint: {
          'line-color': 'gray',
          'line-width': 2,
          'line-dasharray': [2, 1],
        },
      });
    }
    if (!map?.getSource('vesselPoints')) {
      map?.addSource('vesselPoints', {
        type: 'geojson',
        data: vesselPointData,
      });
      map?.addLayer({
        id: 'vesselPathDots',
        type: 'circle',
        source: 'vesselPoints',
        paint: {
          'circle-color': 'brown',
          'circle-radius': 5,
        },
      });
    }
    if (!map?.getSource('breachVessels') && vesselPosition.features.length) {
      map?.addSource('breachVessels', {
        type: 'geojson',
        data: vesselPosition,
      });
      map?.addLayer({
        id: 'breachVessels',
        type: 'circle',
        source: 'breachVessels',
        paint: {
          'circle-color': 'black',
          'circle-radius': 6,
        },
      });
    }
    if (!map?.getSource('currentPosition')) {
      map?.addSource('currentPosition', {
        type: 'geojson',
        data: currentVesselPosition,
      });
    }
    this.drawBreachedVessels(map, 'currentPosition');
    map?.flyTo({
      center: currentVesselPosition.features[0].geometry.coordinates,
      speed: 0.5,
      zoom: 6,
    });
  }

  static drawBreachedVessels(map: mapboxgl.Map | null, sourceDataId: string) {
    map?.addLayer({
      id: sourceDataId,
      type: 'symbol',
      source: sourceDataId,
      layout: vesselLayout,
      paint: {
        'icon-color': { type: 'identity', property: 'color' },
      },
    });
    map?.setLayoutProperty(sourceDataId, 'icon-rotate', {
      type: 'identity',
      property: 'course',
    });
    map?.setLayoutProperty(sourceDataId, 'icon-image', 'breachVessels');
    if (sourceDataId !== 'currentPosition') {
      map?.addLayer({
        id: 'breachVesselsOutline',
        type: 'symbol',
        source: sourceDataId,
        layout: vesselLayout,
        paint: {
          'icon-color': 'red',
        },
      });
      map?.setLayoutProperty('breachVesselsOutline', 'icon-image', [
        'match',
        ['get', 'breach'],
        'true',
        'breachCircleRed',
        '',
      ]);
    }
  }

  static breachVesselSuccess(breachData: BreachVesselDetails[]) {
    //TODO: Revisit this code by geofence perspective
    let breachVesselData: any = {};
    const grouped = _.groupBy(breachData, 'vessel.vesselId');
    Object.keys(grouped).forEach((vessel) => {
      if (grouped[vessel].length) {
        breachVesselData[vessel] = _.sortBy(
          grouped[vessel],
          function (dateObj) {
            return new Date(dateObj.breachDate);
          }
        )[0];
      }
    });
    return breachVesselData;
  }

  static removeAllLayers(map: mapboxgl.Map | null): void {
    this.removeAllBreachLayers(map, 'breachVesselsOutline');
    this.removeAllBreachLayers(map, 'breachLayer');
    this.removeAllBreachLayers(map, 'AIS');
    if (map) {
      this.removeLayerSource(map, 'breachVessels', [
        'breachVesselsOutline',
        'breachVessels',
      ]);
      this.removeTrackLayers(map);
    }
  }

  static removeGeofenceLayer(
    map: mapboxgl.Map | null,
    geofenceList: GeofenceDetails[]
  ): void {
    if (map) {
      geofenceList.forEach((g: any) => {
        this.removeLayerSource(map, `breachGeofence${g.id}`, [
          `breachGeofence${g.id}outline`,
          `breachGeofence${g.id}`,
        ]);
      });
    }
  }

  static removeTrackLayers(map: mapboxgl.Map | null): void {
    if (map) {
      this.removeLayerSource(map, 'currentPosition', ['currentPosition']);
      this.removeLayerSource(map, 'vesselPath', ['vesselPathLine']);
      this.removeLayerSource(map, 'vesselPoints', ['vesselPathDots']);
    }
  }

  static removeLayerSource(
    map: mapboxgl.Map,
    sourceId: string,
    layerList: string[]
  ) {
    if (map && map.getSource(sourceId)) {
      layerList.forEach((l) => {
        this.removeLayer(map, l);
      });
      map.removeSource(sourceId);
    }
  }

  static getUniqueBreachVessels = (
    breachData: BreachVesselDetails[]
  ): BreachVesselDetails[] => {
    let breachVesselData: any = {};
    const grouped = _.groupBy(breachData, 'vessel.vesselId');
    Object.keys(grouped).forEach((vessel) => {
      if (grouped[vessel].length) {
        breachVesselData[vessel] = _.sortBy(
          grouped[vessel],
          function (dateObj) {
            return new Date(dateObj.breachDate);
          }
        )[0];
      }
    });
    return Object.values(breachVesselData);
  };
}
