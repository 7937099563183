import {
  Box,
  Divider,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useEffect, useState } from 'react';
import _, { floor, isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../../../../translations/I18nKey';
import AutoComplete from '../../../../../components/AutoComplete/AutoComplete';
import { Stepper } from '../../../../../components/Stepper/Stepper';
import { Toggle } from '../../../../../components/Toggle/Toggle';
import { AISStyles } from './AISnVMSStyles';
import {
  IUU_SETTINGS_AIS_VMS_COLUMNS,
  IUU_SETTINGS_DEFAULT_DATE_DURATION,
} from '../../config';
import { IUUSettingsStyles } from '../IUUSettingsStyles';
import { useStyles } from '../../../../../utils/util';
import { getArrayOfObjects, getListFromArray } from '../../IUUService';

const precision = 1;

export const AISnVMS: React.FC<any> = ({
  data,
  displaySectionsConfig,
  applyChanges,
  setApplyChanges,
  onApply,
  tabType,
}) => {
  const intl = useIntl();
  const [filteredData, setFilteredData] = useState<any>(data);
  const [selectedVesselType, setSelectedVesselType] = useState<any>([]);
  const [selectedCountryName, setSelectedCountryName] = useState<any>([]);
  const [selectedVesselName, setSelectedVesselName] = useState<any>([]);
  const [selectedMMSI, setSelectedMMSI] = useState<any>([]);
  const [fromSpeed, setFromSpeed] = useState(10);
  const [toSpeed, setToSpeed] = useState(25);
  const [showTracks, setShowTracks] = useState<boolean>(false);
  const [disableToggle, setDisableToggle] = useState<boolean>(true);
  const [filteredTypeCountryData, setFilteredTypeCountryData] =
    useState<any>(data);
  const [dataDuration, setDataDuration] = useState<string>(
    localStorage.getItem('dataDuration') || IUU_SETTINGS_DEFAULT_DATE_DURATION
  );
  const onDateChange = (event: SelectChangeEvent) => {
    setDataDuration(event.target.value);
  };
  const classes = useStyles(IUUSettingsStyles, [])();
  const onVesselTypeChange = (e: any) => {
    let filteredVessels = _.filter(data, (vessel: any) => {
      return _.map(e, 'vesselType').includes(vessel.vesselType);
    });

    // To show all vessels when nothing is selected in VesselType
    if (isEmpty(filteredVessels)) filteredVessels = data;

    setFilteredData(filteredVessels);
    setSelectedVesselType(e);
    setFilteredTypeCountryData(filteredVessels);

    const updatedVesselCountries = _.filter(
      selectedCountryName,
      (name: any) => {
        return _.map(e, 'vesselType').includes(name.vesselType);
      }
    );
    setSelectedCountryName(updatedVesselCountries);

    const updatedVesselNames = _.filter(selectedVesselName, (name: any) => {
      return _.map(e, 'vesselType').includes(name.vesselType);
    });
    setSelectedVesselName(updatedVesselNames);

    const updatedMMSI = _.filter(selectedMMSI, (item: any) => {
      return _.map(e, 'vesselType').includes(item.vesselType);
    });
    setSelectedMMSI(updatedMMSI);
  };

  const onCountryNameChange = (e: any) => {
    let filteredVesselsByType = _.filter(data, (vessel: any) => {
      return _.map(selectedVesselType, 'vesselType').includes(
        vessel.vesselType
      );
    });
    if (selectedVesselType.length === 0) filteredVesselsByType = data;

    let filteredVesselsByCountry = filteredVesselsByType;
    if (e.length) {
      filteredVesselsByCountry = _.filter(
        filteredVesselsByType,
        (vessel: any) => {
          return _.map(e, 'flagCountry').includes(vessel.flagCountry);
        }
      );
    }

    setFilteredData(filteredVesselsByCountry);
    setSelectedCountryName(e);

    const updatedVesselNames = _.filter(selectedVesselName, (name: any) => {
      return _.map(e, 'flagCountry').includes(name.flagCountry);
    });
    setSelectedVesselName(updatedVesselNames);

    const updatedMMSI = _.filter(selectedMMSI, (item: any) => {
      return _.map(e, 'flagCountry').includes(item.flagCountry);
    });
    setSelectedMMSI(updatedMMSI);
  };

  const onVesselNameChange = (e: any) => {
    setSelectedVesselName(e);
  };

  const onMMSIChange = (e: any) => {
    setSelectedMMSI(e);
  };

  const onToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowTracks(event.target.checked);
  };

  const getUniqueData = (data: any, type: string) => {
    return _.uniqBy(data, type);
  };

  const onFromSpeedChange = (event: any) => {
    setFromSpeed(floor(event.target.value, precision));
  };

  const onToSpeedChange = (event: any) => {
    setToSpeed(floor(event.target.value, precision));
  };

  useEffect(() => {
    const filters = JSON.parse(
      localStorage.getItem(displaySectionsConfig.localStorageKey) || '""'
    );
    if (
      displaySectionsConfig.showVesselTypeAutocomplete &&
      filters.vesselType !== undefined
    ) {
      setSelectedVesselType(
        getArrayOfObjects(filters.vesselType, 'vesselType')
      );
    }
    if (
      displaySectionsConfig.showCountryNameAutocomplete &&
      filters.flagCountry !== undefined
    ) {
      setSelectedCountryName(
        getArrayOfObjects(filters.flagCountry, 'flagCountry')
      );
    }
    if (
      displaySectionsConfig.showVesselNameAutocomplete &&
      filters.vesselName !== undefined
    ) {
      setSelectedVesselName(
        getArrayOfObjects(filters.vesselName, 'vesselName')
      );
    }
    if (
      displaySectionsConfig.showMMSIAutocomplete &&
      filters.vesselId !== undefined
    ) {
      setSelectedMMSI(getArrayOfObjects(filters.vesselId, 'vesselId'));
    }
    if (filters.speed) {
      setFromSpeed(floor(filters.speed.from, precision));
      setToSpeed(floor(filters.speed.to, precision));
    }

    setFilteredTypeCountryData(filteredData);
  }, []);

  useEffect(() => {
    if (applyChanges) {
      let filterData: any = {
        vesselName: getListFromArray(selectedVesselName, 'vesselName'),
        flagCountry: getListFromArray(selectedCountryName, 'flagCountry'),
        speed: {
          from: fromSpeed,
          to: toSpeed,
        },
      };
      if (tabType === 'AIS') {
        filterData['vesselId'] = getListFromArray(selectedMMSI, 'vesselId');
        filterData['vesselType'] = getListFromArray(
          selectedVesselType,
          'vesselType'
        );
      }
      onApply(tabType, filterData, parseInt(dataDuration), {
        [tabType]: showTracks,
      });
      setApplyChanges(false);
    }
  }, [applyChanges]);

  useEffect(() => {
    if (selectedVesselName.length > 0 || selectedMMSI.length > 0) {
      setDisableToggle(false);
    } else {
      setShowTracks(false);
      setDisableToggle(true);
    }
  }, [selectedVesselName, selectedMMSI]);

  return (
    <>
      <Box className={classes.dateWrapper}>
        <Typography className={classes.dateDurationText} sx={{ mr: 1 }}>
          {intl.formatMessage({ id: I18nKey.IUU_DATA_DURATION })}
        </Typography>
        <FormControl
          className={classes.dateFormControl}
          sx={{ m: 1 }}
          size="small"
        >
          <Select
            id="iuu-select"
            displayEmpty
            value={dataDuration}
            onChange={onDateChange}
            className={classes.dateSelect}
          >
            <MenuItem value={6}>
              {intl.formatMessage({ id: I18nKey.IUU_DATE_6_HOURS })}
            </MenuItem>
            <MenuItem value={12}>
              {intl.formatMessage({ id: I18nKey.IUU_DATE_12_HOURS })}
            </MenuItem>
            <MenuItem value={24}>
              {intl.formatMessage({ id: I18nKey.IUU_DATE_24_HOURS })}
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      {_.isEmpty(data) ? (
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: '30%',
            color: 'white',
          }}
        >
          {' '}
          {intl.formatMessage({
            id: I18nKey.PRODUCTS_NO_DATA_MSG,
          })}
        </Typography>
      ) : (
        <Box
          sx={{
            mr: 1,
            '& .chipWrapper': {
              marginLeft: 0,
            },
            '& .MuiAutocomplete-root': {
              marginLeft: '-10px',
            },
          }}
        >
          {displaySectionsConfig.showVesselTypeAutocomplete && (
            <>
              <Divider
                sx={{ borderColor: '#333333', mt: 2, mb: -2 }}
                flexItem
              ></Divider>
              <Box>
                <AutoComplete
                  key={
                    IUU_SETTINGS_AIS_VMS_COLUMNS.vesselTypeColumnConfig[0].name
                  }
                  data={getUniqueData(
                    data,
                    IUU_SETTINGS_AIS_VMS_COLUMNS.vesselTypeColumnConfig[0].name
                  )}
                  style={AISStyles.autocompleteStyle}
                  placeholder={intl.formatMessage({
                    id: I18nKey.IUU_SETTINGS_VESSEL_TYPE_PLACEHOLDER,
                  })}
                  columnConfig={
                    IUU_SETTINGS_AIS_VMS_COLUMNS.vesselTypeColumnConfig
                  }
                  onSelectionChange={onVesselTypeChange}
                  showSelectAllToggle={false}
                  selectionData={selectedVesselType}
                />
              </Box>
            </>
          )}
          {displaySectionsConfig.showCountryNameAutocomplete && (
            <>
              <Divider
                sx={{ borderColor: '#333333', mt: 2, mb: -2 }}
                flexItem
              ></Divider>
              <Box>
                <AutoComplete
                  key={IUU_SETTINGS_AIS_VMS_COLUMNS.countryColumnConfig[0].name}
                  data={getUniqueData(
                    filteredTypeCountryData,
                    IUU_SETTINGS_AIS_VMS_COLUMNS.countryColumnConfig[0].name
                  )}
                  style={AISStyles.autocompleteStyle}
                  placeholder={intl.formatMessage({
                    id: I18nKey.IUU_SETTINGS_COUNTRY_PLACEHOLDER,
                  })}
                  columnConfig={
                    IUU_SETTINGS_AIS_VMS_COLUMNS.countryColumnConfig
                  }
                  onSelectionChange={onCountryNameChange}
                  showSelectAllToggle={false}
                  selectionData={selectedCountryName}
                />
              </Box>
            </>
          )}
          {displaySectionsConfig.showVesselNameAutocomplete && (
            <>
              <Divider
                sx={{ borderColor: '#333333', mt: 2, mb: -2 }}
                flexItem
              ></Divider>
              <Box>
                <AutoComplete
                  key={
                    IUU_SETTINGS_AIS_VMS_COLUMNS.vesselNameColumnConfig[0].name
                  }
                  data={getUniqueData(
                    filteredData,
                    IUU_SETTINGS_AIS_VMS_COLUMNS.vesselNameColumnConfig[0].name
                  )}
                  style={AISStyles.autocompleteStyle}
                  placeholder={intl.formatMessage({
                    id: I18nKey.IUU_SETTINGS_VESSEL_NAME_PLACEHOLDER,
                  })}
                  columnConfig={
                    IUU_SETTINGS_AIS_VMS_COLUMNS.vesselNameColumnConfig
                  }
                  onSelectionChange={onVesselNameChange}
                  showSelectAllToggle={false}
                  selectionData={selectedVesselName}
                />
              </Box>
            </>
          )}
          {displaySectionsConfig.showMMSIAutocomplete && (
            <>
              <Divider
                sx={{ borderColor: '#333333', mt: 2, mb: -2 }}
                flexItem
              ></Divider>
              <Box>
                <AutoComplete
                  key={IUU_SETTINGS_AIS_VMS_COLUMNS.MMSIColumnConfig[0].name}
                  data={getUniqueData(
                    filteredData,
                    IUU_SETTINGS_AIS_VMS_COLUMNS.MMSIColumnConfig[0].name
                  )}
                  style={AISStyles.autocompleteStyle}
                  placeholder={intl.formatMessage({
                    id: I18nKey.IUU_SETTINGS_MMSI_PLACEHOLDER,
                  })}
                  columnConfig={IUU_SETTINGS_AIS_VMS_COLUMNS.MMSIColumnConfig}
                  onSelectionChange={onMMSIChange}
                  showSelectAllToggle={false}
                  selectionData={selectedMMSI}
                  multiValueSeparator=" "
                />
              </Box>
            </>
          )}
          {displaySectionsConfig.showSpeed && (
            <>
              <Divider
                sx={{ borderColor: '#333333', mt: 2 }}
                flexItem
              ></Divider>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  color: '#FFF',
                  mt: 2,
                }}
              >
                <Typography sx={{ mb: 1 }}>
                  {intl.formatMessage({
                    id: I18nKey.IUU_SETTINGS_SPEED,
                  })}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ mr: 2 }}>
                    {intl.formatMessage({
                      id: I18nKey.IUU_SETTINGS_FROM_TO,
                    })}
                  </Typography>
                  <Box sx={{ width: '12%', mr: 1 }}>
                    <Stepper
                      value={fromSpeed}
                      key="from"
                      onChange={onFromSpeedChange}
                    />
                  </Box>
                  <Typography>-</Typography>
                  <Box
                    sx={{
                      width: '12%',
                      ml: 1,
                      mr: 2,
                      backgroundColor: '#242424',
                    }}
                  >
                    <Stepper
                      value={toSpeed}
                      key="from"
                      onChange={onToSpeedChange}
                    />
                  </Box>
                  <Typography>
                    {intl.formatMessage({
                      id: I18nKey.IUU_SETTINGS_KNOTS,
                    })}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
          {displaySectionsConfig.showTracks && (
            <>
              <Divider
                sx={{ borderColor: '#333333', my: 2 }}
                flexItem
              ></Divider>
              <Box sx={{ display: 'flex', mb: 1 }}>
                <FormGroup>
                  <FormControlLabel
                    sx={{
                      color: '#fff',
                      marginLeft: '0px',
                      '& .MuiFormControlLabel-label.Mui-disabled': {
                        color: '#6F6F6F',
                      },
                    }}
                    control={
                      <Toggle
                        disabled={disableToggle}
                        checked={showTracks}
                        onChange={onToggleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label={intl.formatMessage({
                      id: I18nKey.IUU_SETTINGS_SHOW_TRACKS,
                    })}
                    labelPlacement="start"
                  />
                </FormGroup>
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
};
