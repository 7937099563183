export const I18nKey: { [key: string]: any } = {
  SEASTAR: 'seastar',
  MAXAR: 'maxar',
  SIGN_IN_TO_YOUR_ACCOUNT: 'auth.signInToYourAccount',
  USERNAME: 'auth.username',
  USERNAME_INFO: 'auth.usernameInfo',
  PASSWORD: 'auth.password',
  PASSWORD_INFO: 'auth.passwordInfo',
  PASSWORD_CHAR_INFO: 'auth.passwordCharInfo',
  PASSWORD_UPPER_CASE_INFO: 'auth.passwordUpperCaseInfo',
  PASSWORD_LOWER_CASE_INFO: 'auth.passwordLowerCaseInfo',
  PASSWORD_NUMBER_INFO: 'auth.passwordNumberInfo',
  PASSWORD_SPECIAL_CHAR_INFO: 'auth.passwordSpecialCharInfo',
  NEW_PASSWORD: 'auth.newPassword',
  EMAIL_ADDRESS: 'auth.emailAddress',
  SEASTAR_ID: 'auth.seastarId',
  CREATE_ACCOUNT: 'auth.createAccount',
  ALREADY_HAVE_AN_ACCOUNT: 'auth.alreadyHaveAnAccount',
  SIGN_IN: 'auth.signIn',
  FORGOT_PASSWORD: 'auth.forgotPassword',
  DONT_HAVE_AN_ACCOUNT: 'auth.dontHaveAnAccount',
  CREATE_YOUR_ACCOUNT: 'auth.createYourAccount',
  VERIFICATION_CODE: 'auth.verificationCode',
  GET_VERIFICATION_CODE: 'auth.getVerificationCode',
  PASSWORD_RESET_MSG: 'auth.passwordResetMsg',
  LOGIN_ERROR_MSG: 'auth.loginErrorMsg',
  SIGNUP_ERROR_MSG: 'auth.signUpErrorMsg',
  ACCOUNT_CREATION_SUCCESS_MSG: 'auth.accountCreationSuccessMsg',
  INVALID_FIELDS_MSG: 'auth.invalidFieldMsg',
  VERIFICATION_ERROR_MSG: 'auth.verificationErrorMsg',
  VERIFICATION_CODE_SENT_MSG: 'auth.verificationCodeSentMsg',
  PASSWORD_RESET_ERROR_MSG: 'auth.passwordResetErrorMsg',
  PASSWORD_ERROR_MSG: 'auth.passwordErrorMsg',
  PASSWORD_CHAR_LENGTH: 'auth.passwordCharLength',
  PASSWORD_UPPERCASE: 'auth.passwordUppercase',
  PASSWORD_LOWERCASE: 'auth.passwordLowercase',
  PASSWORD_DIGIT: 'auth.passwordDigit',
  PASSWORD_SPECIAL_SYMBOL: 'auth.passwordSpecialSymbol',
  EMAIL_INVALID: 'auth.emailIdInvalid',
  COMPLETE_ACCOUNT_VERIFICATION: 'auth.completeAccountVerification',
  RESET_PASSWORD: 'auth.resetPassword',
  BACK_TO_SIGN_IN: 'auth.backToSignIn',
  RESEND_VERIFICATION_CODE: 'auth.resendVerificationCode',
  ACCOUNT_VERIFICATION: 'auth.accountVerification',
  VERIFICATION_PAGE_EXPIRY_MSG: 'auth.verificationPageExpiryMsg',
  SIGN_UP: 'auth.signUp',
  SUBMIT: 'auth.submit',
  CLOSE: 'close',
  SIGN_OUT: 'signOut',
  ROUTER_MAPS: 'router.maps',
  ROUTER_FISH_CATCH: 'router.fishCatch',
  ROUTER_IUU: 'router.iuu',
  COLOR_BOX_COLOR_PICKER: 'colorBox.colorPicker',
  COLOR_BOX_STANDARD_COLORS: 'colorBox.standardColors',
  USER_SETTINGS_SETTINGS: 'userSettings.settings',
  USER_SETTINGS_DEFAULT_MAP: 'userSettings.defaultMap',
  USER_SETTINGS_LANGUAGE: 'userSettings.language',
  USER_SETTINGS_GRATICULE: 'userSettings.graticule',
  USER_SETTINGS_EEZ: 'userSettings.eez',
  USER_SETTINGS_REGIONS: 'userSettings.regions',
  USER_SETTINGS_BATHYMETRY: 'userSettings.bathymetry',
  USER_SETTINGS_BATHYMETRY_CONTOUR: 'userSettings.bathymetryContours',
  USER_SETTINGS_BATHYMETRY_LABELS: 'userSettings.bathymetryLabels',
  USER_SETTINGS_LATITUDE_FORMAT: 'userSettings.latitudeFormat',
  USER_SETTINGS_CONTOUR: 'userSettings.contour',
  USER_SETTINGS_CONTOUR_LABEL: 'userSettings.contourLabel',
  USER_SETTINGS_OVERLAYS: 'userSettings.overlays',
  USER_SETTINGS_DISPLAY: 'userSettings.display',
  USER_SETTINGS_WIND_RASTER: 'userSettings.windRaster',
  USER_SETTINGS_UNITS: 'userSettings.units',
  USER_SETTINGS_DEPTH: 'userSettings.depth',
  USER_SETTINGS_HEIGHT: 'userSettings.height',
  USER_SETTINGS_TEMPERATURE: 'userSettings.temperature',
  USER_SETTINGS_SAVE_SUCCESS: 'userSettings.saveSuccess',
  USER_SETTINGS_RELOAD: 'userSettings.reloadProducts',
  USER_SETTINGS_CHANGES: 'userSettings.changes',
  SETTINGS_POPUP_SAVE: 'settingsPopup.save',
  SETTINGS_POPUP_APPLY: 'settingsPopup.apply',
  SETTINGS_POPUP_RESET: 'settingsPopup.reset',
  SETTINGS_POPUP_SAVE_AS_DEFAULT: 'settingsPopup.saveAsdefault',
  SETTINGS_POPUP_RESET_TO_DEFAULT: 'settingsPopup.resetToDefault',
  SXMUSE: 'regions.SXMUSE',
  SXMUSW: 'regions.SXMUSW',
  PCIDN: 'regions.PCIDN',
  PCMDV: 'regions.PCMDV',
  CHL: 'regions.CHL',
  PCCHLN: 'regions.PCCHLN',
  PCCHLS: 'regions.PCCHLS',
  PCKOR: 'regions.PCKOR',
  PCTHA: 'regions.PCTHA',
  PCPER: 'regions.PCPER',
  SELECT: 'select',
  SELECT_ALL: 'selectAll',
  CLEAR_ALL: 'clearAll',
  NOT_FOUND_TITLE: '404.title',
  NOT_FOUND_SUBTITLE: '404.subTitle',
  NOT_FOUND_MESSAGE: '404.message',
  NOT_FOUND_BTN_TITLE: '404.btnTitle',
  NOT_FOUND_ACTION_MSG: '404.actionMsg',
  PRODUCTS_SST: 'products.SST',
  PRODUCTS_CUR: 'products.CUR',
  PRODUCTS_FAI: 'products.FAI',
  PRODUCTS_PLK: 'products.PLK',
  PRODUCTS_SSHA: 'products.SSHA',
  PRODUCTS_TSS: 'products.TSS',
  PRODUCTS_CSS: 'products.CSS',
  PRODUCTS_TCD: 'products.TCD',
  PRODUCTS_SWL: 'products.SWL',
  PRODUCTS_WIND: 'products.WIND',
  PRODUCTS_BPS: 'products.BPS',
  PRODUCTS_PRPA: 'products.PRPA',
  PRODUCTS_WAV: 'products.WAV',
  PRODUCTS_RHU: 'products.RHU',
  PRODUCTS_DOX: 'products.DOX',
  PRODUCTS_PPR: 'products.PPR',
  PRODUCTS_HAB: 'products.HAB',
  PRODUCTS_SSD: 'products.SSD',
  PRODUCTS_SAL: 'products.SAL',
  PRODUCTS_STP: 'products.STP',
  PRODUCTS_SMT: 'products.SMT',
  PRODUCTS_CBAA: 'products.CBAA',
  PRODUCTS_CBHW: 'products.CBHW',
  PRODUCTS_CBHS: 'products.CBHS',
  PRODUCTS_SSTFS: 'products.SSTFS',
  PRODUCTS_PLKFS: 'products.PLKFS',
  PRODUCTS_BTH: 'products.BTH',
  PRODUCTS_HAL: 'products.HAL',
  PRODUCTS_FNT: 'products.FNT',
  PRODUCTS_STORM: 'products.STORM',
  PRODUCTS_WIND_RASTER: 'userSettings.windRaster',
  PRODUCTS_WEATHER_SETTINGS: 'userSettings.weatherSettings',
  PRODUCT_CODE_SST: 'productCodes.SST',
  PRODUCT_CODE_CUR: 'productCodes.CUR',
  PRODUCT_CODE_FAI: 'productCodes.FAI',
  PRODUCT_CODE_PLK: 'productCodes.PLK',
  PRODUCT_CODE_SSHA: 'productCodes.SSHA',
  PRODUCT_CODE_TSS: 'productCodes.TSS',
  PRODUCT_CODE_CSS: 'productCodes.CSS',
  PRODUCT_CODE_TCD: 'productCodes.TCD',
  PRODUCT_CODE_SWL: 'productCodes.SWL',
  PRODUCT_CODE_WIND: 'productCodes.WIND',
  PRODUCT_CODE_BPS: 'productCodes.BPS',
  PRODUCT_CODE_PRPA: 'productCodes.PRPA',
  PRODUCT_CODE_WAV: 'productCodes.WAV',
  PRODUCT_CODE_RHU: 'productCodes.RHU',
  PRODUCT_CODE_DOX: 'productCodes.DOX',
  PRODUCT_CODE_PPR: 'productCodes.PPR',
  PRODUCT_CODE_HAB: 'productCodes.HAB',
  PRODUCT_CODE_SSD: 'productCodes.SSD',
  PRODUCT_CODE_SAL: 'productCodes.SAL',
  PRODUCT_CODE_STP: 'productCodes.STP',
  PRODUCT_CODE_SMT: 'productCodes.SMT',
  PRODUCT_CODE_CBAA: 'productCodes.CBAA',
  PRODUCT_CODE_CBHW: 'productCodes.CBHW',
  PRODUCT_CODE_CBHS: 'productCodes.CBHS',
  PRODUCT_CODE_SSTFS: 'productCodes.SSTFS',
  PRODUCT_CODE_PLKFS: 'productCodes.PLKFS',
  PRODUCT_CODE_HAL: 'productCodes.HAL',
  PRODUCT_CODE_FNT: 'productCodes.FNT',
  PRODUCT_CODE_STORM: 'productCodes.STORM',
  PRODUCTS_WEATHER: 'products.weather',
  PRODUCTS_SWELL: 'products.swell',
  PRODUCTS_WAVE: 'products.wave',
  OTHER_PRODUCTS_IUUSETTINGS: 'otherProducts.iuuSettings',
  OTHER_PRODUCTS_AIS: 'otherProducts.ais',
  OTHER_PRODUCTS_BUOYS: 'otherProducts.buoys',
  OTHER_PRODUCTS_VESSEL: 'otherProducts.vessel',
  OTHER_PRODUCTS_FISHCATCH: 'otherProducts.fishCatch',
  OTHER_PRODUCTS_RFSIGNALS: 'otherProducts.rfSignals',
  OTHER_PRODUCTS_DARKTARGETS: 'otherProducts.darkTargets',
  OTHER_PRODUCTS_LRIT: 'otherProducts.lrit',
  OTHER_PRODUCTS_VMS: 'otherProducts.vms',
  OTHER_PRODUCTS_OILSPIL: 'otherProducts.oilSpil',
  OTHER_PRODUCTS_MARINEDEBRIS: 'otherProducts.marineDebris',
  OTHER_PRODUCTS_MASTERCAST: 'otherProducts.mastercast',
  OTHER_PRODUCTS_MASTERCASTSETTINGS: 'otherProducts.mastercastSettings',
  OTHER_PRODUCTS_RECOMMENDATIONS: 'otherProducts.recommendation',
  OTHER_PRODUCTS_GEOFENCING: 'otherProducts.geofencing',
  OTHER_PRODUCTS_TRIPDATA: 'otherProducts.tripData',
  CATEGORY_INLAND: 'category.inland',
  CATEGORY_OCEAN: 'category.ocean',
  CATEGORY_OCEANHEALTH: 'category.oceanHealth',
  CATEGORY_WEATHER: 'category.weather',
  CATEGORY_IUU: 'category.iuu',
  CATEGORY_MARPOL: 'category.marpol',
  CATEGORY_ANALYSIS: 'category.analysis',
  PRODUCTS_NO_DATA_MSG: 'errorMessage.productNoDataMsg',
  PRECIPITATION_NO_DATA_MSG: 'errorMessage.precipitationNoDataMsg',
  PRODUCTS_ERR_MSG: 'errorMessage.get',
  UNITS_CELCIUS: 'units.celcius',
  UNITS_FAHRENHEIT: 'units.fahrenheit',
  UNITS_M: 'units.m',
  UNITS_CM: 'units.cm',
  UNITS_IN: 'units.in',
  UNITS_FT: 'units.ft',
  UNITS_FATHOM: 'units.fathom',
  'UNITS_MG/M³': 'units.mg/m³',
  UNITS_KTS: 'units.kts',
  'UNITS_MG/L': 'units.mg/L',
  UNITS_MB: 'units.mb',
  'UNITS_%': 'units.%',
  'UNITS_MMOL/M³': 'units.mmol/m³',
  UNITS_MIN: 'units.min',
  UNITS_KNOTS: 'units.knots',
  UNITS_DEG: 'units.deg',
  'UNITS_W/M²/UM/SR': 'units.W/m²/um/sr',
  UNITS_PSU: 'units.PSU',
  'UNITS_C-WEEKS': 'units.C-Weeks',
  LEGEND_PRODUCT_AVAILABILITY_TOOLTIP: 'legend.productAvailableTooltip',
  DATERANGEPICKER_CANCEL: 'dateRangePicker.cancel',
  DATERANGEPICKER_APPLY: 'dateRangePicker.apply',
  DATERANGEPICKER_CUSTOM_RANGE: 'dateRangePicker.customRange',
  IUUDASHBOARD_SUNBURST: 'iuuDashboard.sunburstChart',
  IUUDASHBOARD_FENCENAMEBARCHART: 'iuuDashboard.fenceNameBarChart',
  IUUDASHBOARD_VESSELTYPEBARCHART: 'iuuDashboard.vesselTypesBarChart',
  IUUDASHBOARD_VESSELBREACHMAP: 'iuuDashboard.vesselBreachMap',
  IUUDASHBOARD_FILTER: 'iuuDashboard.filter',
  IUUDASHBOARD_APPLY: 'iuuDashboard.apply',
  IUUDASHBOARD_FILTERCOUNTRY: 'iuuDashboard.country',
  IUUDASHBOARD_FILTERGEOFENCES: 'iuuDashboard.geofences',
  IUUDASHBOARD_FILTERTYPES: 'iuuDashboard.types',
  IUUDASHBOARD_RESET_TO_DEFAULT: 'iuuDashboard.resetToDefault',
  IUUDASHBOARD_TOTALBREACHES: 'iuuDashboard.totalBreaches',
  IUUDASHBOARD_MAXCOUNTRYBREACHES: 'iuuDashboard.maxCountryBreaches',
  IUUDASHBOARD_VESSELTYPEBREACHES: 'iuuDashboard.maxVesselTypeBreaches',
  IUUDASHBOARD_TABLE_TITLE: 'iuuDashboard.tableTitle',
  IUUDASHBOARD_BREACH_DATE: 'iuuDashboard.breachDate',
  IUUDASHBOARD_VESSEL_NAME: 'iuuDashboard.vesselName',
  IUUDASHBOARD_VESSEL_TYPE: 'iuuDashboard.vesselType',
  IUUDASHBOARD_SPEED: 'iuuDashboard.speed',
  IUUDASHBOARD_GEOFENCE_TYPE: 'iuuDashboard.geofenceType',
  IUUDASHBOARD_GEOFENCE_NAME: 'iuuDashboard.geofenceName',
  IUUDASHBOARD_TRACKS_POPUP_DATE: 'iuu.popup.tracksDate',
  IUUDASHBOARD_ALERTS_SAVE_MSG: 'iuuDashboard.alertSaveMsg',
  IUUDASHBOARD_NO_BREACH_MSG: 'iuuDashboard.noBreachMsg',
  IUUDASHBOARD_SAVE_ALERT: 'iuuDashboard.geofenceSaveAlerts',
  IUUDASHBOARD_ALL_VESSELS: 'iuuDashboard.allVessels',
  IUUDASHBOARD_ONLY_BREACHED_VESSELS: 'iuuDashboard.onlyBreachedVessels',
  DATERANGER_PICKER_RANGE_HOUR: 'iuuDashboard.yesterday',
  DATERANGER_PICKER_RANGE_DAY: 'iuuDashboard.lastWeek',
  DATERANGER_PICKER_RANGE_MONTH: 'iuuDashboard.lastMonth',
  MIN_MAX: 'slider.minMax',
  DEPTH: 'slider.depth',
  CLICK_HERE_TO_CHANGE_DEPTH: 'slider.clickHereToChangeDepth',
  ERROR_MESSAGE_SAVE: 'errorMessage.save',
  ERROR_MESSAGE_GET: 'errorMessage.get',
  ERROR_MESSAGE_GEOFENCE: 'errorMessage.geofence',
  ERROR_MESSAGE_NO_ACTIVE_GEOFENCE: 'errorMessage.noActiveGeofence',
  ERROR_VESSEL_TRACK: 'errorMessage.vesselTrack',
  ERROR_NO_FISH_CATCH_DATA: 'errorMessage.noFishCatchDataMsg',
  ERROR_MESSAGE_UNAVAILABLE_FOR_REGION:
    'errorMessage.productForRegionNotAvailable',
  DATA_TABLE_SEARCH: 'dataTable.searchPlaceholder',
  DATA_TABLE_ACTIONS_HEADER: 'dataTable.actionsHeader',
  DATA_TABLE_ACTIONS_DOWNLOAD: 'dataTable.actions.download',
  DATA_TABLE_ACTIONS_DELETE: 'dataTable.actions.delete',
  DATA_TABLE_ACTIONS_SAVE: 'dataTable.actions.save',
  DATA_TABLE_ACTIONS_EDIT: 'dataTable.actions.edit',
  DATA_TABLE_ACTIONS_ADD: 'dataTable.actions.add',
  DATA_TABLE_ADD_ROW: 'dataTable.addRow',
  SELECT_DATE: 'calendar.selectDate',
  PRODUCT_SETTING_DIALOG_BOX: 'productSettings.productSettingsDialogBox',
  PRODUCT_SETTING_DIALOG_BOX_DISCRETE: 'productSettings.discrete',
  PRODUCT_SETTING_DIALOG_BOX_CONTINUOUS: 'productSettings.continuous',
  PRODUCT_SETTING_DIALOG_BOX_STYLE: 'productSettings.style',
  PRODUCT_SETTING_DIALOG_BOX_COLOR: 'productSettings.color',
  PRODUCT_SETTING_DIALOG_BOX_INTERVAL: 'productSettings.interval',
  PRODUCT_SETTING_DIALOG_BOX_STYLESIZE: 'productSettings.styleSize',
  PRODUCT_SETTING_DIALOG_BOX_VECTOR: 'productSettings.vector',
  PRODUCT_SETTING_DIALOG_BOX_RASTER: 'productSettings.raster',
  PRODUCT_SETTING_DIALOG_BOX_CONTOUR: 'productSettings.contour',
  PRODUCT_SETTING_DIALOG_BOX_APPLY: 'productSettings.apply',
  PRODUCT_SETTING_DIALOG_BOX_RESTORE: 'productSettings.restore',
  PRODUCT_SETTING_DIALOG_BOX_SAVEDEFAULT: 'productSettings.saveDefault',
  PRODUCT_SETTING_DIALOG_BOX_SAVEMESSAGE: 'productSettings.saveMessage',
  PRODUCT_STORM_POP_UP_TIME: 'productSettings.stormPopup.time',
  PRODUCT_STORM_POP_UP_BASIN: 'productSettings.stormPopup.basin',
  PRODUCT_STORM_POP_UP_NOA_NUMBER: 'productSettings.stormPopup.noaNumber',
  PRODUCT_STORM_POP_UP_MAX_WIND_SPEED:
    'productSettings.stormPopup.maxWindSpeed',
  PRODUCT_STORM_POP_UP_MIN_PRESSURE: 'productSettings.stormPopup.minPressure',
  PRODUCT_STORM_POP_UP_SPEED: 'productSettings.stormPopup.speed',
  PRODUCT_STORM_POP_UP_HEADING: 'productSettings.stormPopup.heading',
  PRODUCT_STORM_POP_UP_WIND_SPEED: 'productSettings.stormPopup.windSpeed',
  IUU_SETTINGS_TITLE: 'iuu.settings.title',
  IUU_DATA_DURATION: 'iuu.settings.dataDuration',
  IUU_DATE_6_HOURS: 'iuu.settings.6Hours',
  IUU_DATE_12_HOURS: 'iuu.settings.12Hours',
  IUU_DATE_24_HOURS: 'iuu.settings.24Hours',
  IUU_SETTINGS_TABS_AIS: 'iuu.settings.tabs.ais',
  IUU_SETTINGS_TABS_VMS: 'iuu.settings.tabs.vms',
  IUU_SETTINGS_TABS_GEOFENCING: 'iuu.settings.tabs.geofencing',
  IUU_SETTINGS_ACTION_BUTTON: 'iuu.settings.actionButton',
  IUU_SETTINGS_VESSEL_TYPE_PLACEHOLDER: 'iuu.settings.vesselTypePlaceholder',
  IUU_SETTINGS_VESSEL_NAME_PLACEHOLDER: 'iuu.settings.vesselNamePlaceholder',
  IUU_SETTINGS_MMSI_PLACEHOLDER: 'iuu.settings.MMSIPlaceholder',
  IUU_SETTINGS_COUNTRY_PLACEHOLDER: 'iuu.settings.countryNamePlaceholder',
  IUU_SETTINGS_SPEED: 'iuu.settings.speed',
  IUU_SETTINGS_FROM_TO: 'iuu.settings.fromTo',
  IUU_SETTINGS_KNOTS: 'iuu.settings.knots',
  IUU_SETTINGS_SHOW_TRACKS: 'iuu.settings.showTracks',
  IUU_SETTINGS_AIS_TRACKS: 'iuu.settings.aisTracks',
  IUU_SETTINGS_VESSEL_TRACKS: 'iuu.settings.vesselTracks',
  IUU_SETTINGS_BUOY_TRACKS: 'iuu.settings.buoyTracks',
  IUU_SETTINGS_GEOFENCE_LIST: 'iuu.settings.geofenceList',
  IUU_SETTINGS_CREATE_GEOFENCE: 'iuu.settings.createGeofence',
  IUU_SETTINGS_GEOFENCE_NAME: 'iuu.settings.geofenceName',
  IUU_SETTINGS_GEOFENCE_START_DATE: 'iuu.settings.geofenceStartDate',
  IUU_SETTINGS_GEOFENCE_END_DATE: 'iuu.settings.geofenceEndDate',
  IUU_SETTINGS_GEOFENCE_ACTIVE: 'iuu.settings.geofenceActive',
  IUU_SETTINGS_BUOYS_LIST: 'iuu.settings.buoysList',
  IUU_SETTINGS_VESSELS_LIST: 'iuu.settings.vesselsList',
  IUU_SETTINGS_FILE_NAME: 'iuu.settings.fileName',
  IUU_SETTINGS_NO_OF_RECORDS: 'iuu.settings.noOfRecords',
  IUU_SETTINGS_CREATED_AT: 'iuu.settings.createdAt',
  IUU_SETTINGS_VIEW_BUOY_FILES: 'iuu.settings.viewBuoyFiles',
  IUU_SETTINGS_UPLOAD_BUOYS_DATA: 'iuu.settings.uploadBuoysData',
  IUU_SETTINGS_BUOYS_UPLOADED_DATA: 'iuu.settings.buoysUploadedData',
  IUU_SETTINGS_VIEW_VESSEL_FILES: 'iuu.settings.viewVesselFiles',
  IUU_SETTINGS_UPLOAD_VESSEL_DATA: 'iuu.settings.uploadVesselData',
  IUU_SETTINGS_VESSELS_UPLOADED_DATA: 'iuu.settings.vesselsUploadedData',
  IUU_SETTINGS_7_DAYS: 'iuu.settings.duration.7d',
  IUU_SETTINGS_30_DAYS: 'iuu.settings.duration.30d',
  IUU_SETTINGS_3_MONTHS: 'iuu.settings.duration.3m',
  IUU_SETTINGS_6_MONTHS: 'iuu.settings.duration.6m',
  IUU_SETTINGS_1_YEAR: 'iuu.settings.duration.1y',
  IUU_SETTINGS_ALL: 'iuu.settings.duration.all',
  IUU_SETTINGS_SEARCH_BY_VESSEL_NAME: 'iuu.settings.searchByVesselName',
  IUU_SETTINGS_SEARCH_BY_BUOY_ID: 'iuu.settings.searchByBuoyId',
  IUU_SETTINGS_BACK: 'iuu.settings.back',
  IUU_SETTINGS_FILTERS: 'iuu.settings.filters',
  IUU_SETTINGS_DELETE_ERROR_MSG: 'iuu.settings.deleteErrorMsg',
  IUU_SETTINGS_GET_ERROR_MSG: 'iuu.settings.getErrorMsg',
  IUU_SETTINGS_BUOY_NO_DATA_MSG: 'iuu.settings.buoyNoData',
  IUU_SETTINGS_VESSEL_NO_DATA_MSG: 'iuu.settings.vesselNoData',
  IUU_SETTINGS_DELETE_FILE_SUCCESS: 'iuu.settings.deleteSuccess',
  BREACH_ERROR: 'iuu.breachError',
  IUU_POPUP_MMSI: 'iuu.popup.mmsi',
  IUU_POPUP_TYPE: 'iuu.popup.type',
  IUU_POPUP_COUNTRY: 'iuu.popup.country',
  IUU_POPUP_SPEED: 'iuu.popup.speed',
  IUU_POPUP_COURSE: 'iuu.popup.course',
  IUU_POPUP_POSITION: 'iuu.popup.position',
  IUU_POPUP_LAST_SEEN_AT: 'iuu.popup.lastSeenAt',
  IUU_POPUP_UTC: 'iuu.popup.utc',
  IUU_POPUP_BREACH_DETAILS: 'iuu.popup.breachDetails',
  IUU_POPUP_BREACH_DATE: 'iuu.popup.breachDate',
  IUU_POPUP_GEOFENCE_TYPE: 'iuu.popup.geofenceType',
  IUU_POPUP_GEOFENCE_NAME: 'iuu.popup.geofenceName',
  IUU_POPUP_DETECTED_LOCATION: 'iuu.popup.detectedLocation',
  IUU_POPUP_ORIENTATION: 'iuu.popup.orientation',
  IUU_POPUP_SAR_TARGETS: 'iuu.popup.sarTargets',
  IUU_POPUP_LENGTH: 'iuu.popup.length',
  IUU_POPUP_WIDTH: 'iuu.popup.width',
  IUU_POPUP_HEADING: 'iuu.popup.heading',
  IUU_SETTINGS_BACK_TO_GEOFENCE_LIST: 'iuu.settings.backToGeofenceList',
  IUU_SETTINGS_GEOFENCE_NAME_LABEL: 'iuu.settings.geofenceNameLabel',
  IUU_SETTINGS_GEOFENCE_GENERAL: 'iuu.settings.geofenceGeneral',
  IUU_SETTINGS_GEOFENCE_FILTERS: 'iuu.settings.geofenceFilters',
  IUU_SETTINGS_GEOFENCE_TYPE: 'iuu.settings.geofenceType',
  IUU_SETTINGS_GEOFENCE_ALERT: 'iuu.settings.geofenceAlert',
  IUU_SETTINGS_GEOFENCE_SAFE: 'iuu.settings.geofenceSafe',
  IUU_SETTINGS_GEOFENCE_STATUS: 'iuu.settings.geofenceStatus',
  IUU_SETTINGS_GEOFENCE_DESCRIPTION: 'iuu.settings.geofenceDescription',
  IUU_SETTINGS_GEOFENCE_DESCRIPTION_PLACEHOLDER:
    'iuu.settings.geofenceDescriptionPlaceholder',
  IUU_SETTINGS_GEOFENCE_DATA: 'iuu.settings.geofenceData',
  IUU_SETTINGS_GEOFENCE_DRAW: 'iuu.settings.geofenceDraw',
  IUU_SETTINGS_GEOFENCE_UPLOAD: 'iuu.settings.geofenceUpload',
  IUU_SETTINGS_GEOFENCE_EMAIL: 'iuu.settings.geofenceEmail',
  IUU_SETTINGS_GEOFENCE_SMS: 'iuu.settings.geofenceSms',
  IUU_SETTINGS_GEOFENCE_EMAIL_PLACEHOLDER:
    'iuu.settings.geofenceEmailPlaceholder',
  IUU_SETTINGS_GEOFENCE_DISPLAY: 'iuu.settings.geofenceDisplay',
  IUU_SETTINGS_GEOFENCE_FILL_COLOR: 'iuu.settings.geofenceFillColor',
  IUU_SETTINGS_GEOFENCE_BORDER_COLOR: 'iuu.settings.geofenceBorderColor',
  IUU_SETTINGS_GEOFENCE_FILL_WIDTH: 'iuu.settings.geofenceFillWidth',
  IUU_SETTINGS_GEOFENCE_DARK_TARGETS: 'iuu.settings.geofenceDarkTargets',
  IUU_SETTINGS_GEOFENCE_RF_SIGNALS: 'iuu.settings.geofenceRFSignals',
  IUU_SETTINGS_GEOFENCE_LRIT: 'iuu.settings.geofenceLRIT',
  IUU_SETTINGS_GEOFENCE_SAVE: 'iuu.settings.geofenceSave',
  IUU_SETTINGS_GEOFENCE_DELETE: 'iuu.settings.geofenceDelete',
  IUU_SETTINGS_GEOFENCE_DRAW_SAVE: 'iuu.settings.geofenceDrawSave',
  IUU_SETTINGS_GEOFENCE_UPLOAD_FILE: 'iuu.settings.geofenceUploadFile',
  IUU_SETTINGS_GEOFENCE_UPLOAD_INFO: 'iuu.settings.geofenceUploadInformation',
  IUU_SETTINGS_GEOFENCE_UPLOAD_INFO_RULES:
    'iuu.settings.geofenceUploadInformationRules',
  IUU_SETTINGS_GEOFENCE_UPLOAD_INFO_RULE_1:
    'iuu.settings.geofenceUploadInformationRule1',
  IUU_SETTINGS_GEOFENCE_UPLOAD_INFO_RULE_2:
    'iuu.settings.geofenceUploadInformationRule2',
  IUU_SETTINGS_GEOFENCE_UPLOAD_INFO_RULE_3:
    'iuu.settings.geofenceUploadInformationRule3',
  IUU_SETTINGS_GEOFENCE_DELETE_ERROR_MESSAGE:
    'iuu.settings.geofenceDeleteErrorMessage',
  IUU_SETTINGS_GEOFENCE_UPLOAD_ERROR_MESSAGE:
    'iuu.settings.geofenceUploadErrorMessage',
  IUU_SETTINGS_GEOFENCE_SAVE_ERROR_MESSAGE:
    'iuu.settings.geofenceSaveErrorMessage',
  IUU_SETTINGS_TOGGLE_TRACKS_ERROR_MESSAGE:
    'iuu.settings.toggleTracksErrorMessage',
  IUU_SETTINGS_VESSEL_NAME_REQUIRED_MSG: 'iuu.settings.vesselNameRequired',
  IUU_SETTINGS_FILE_UPLOAD_SUCCESS: 'iuu.settings.fileUploadSuccess',
  IUU_SETTINGS_FILE_UPLOAD_FAILURE: 'iuu.settings.fileUploadFailure',
  IUU_SETTINGS_DATE: 'iuu.settings.date',
  IUU_SETTINGS_VELOCITY: 'iuu.settings.velocity',
  IUU_SETTINGS_COURSE: 'iuu.settings.course',
  IUU_SETTINGS_TEMPERATURE: 'iuu.settings.temperature',
  IUU_SETTINGS_BUOY_ID: 'iuu.settings.buoyId',
  ANALYSIS_RECOMMENDATIONS: 'analysis.recommendations',
  ANALYSIS_RECOMMENDATION_LAST_UPDATED: 'analysis.lastUpdated',
  ANALYSIS_ALL: 'analysis.all',
  ANALYSIS_APPLY: 'analysis.apply',
  ANALYSIS_SAVE: 'analysis.save',
  ANALYSIS_TRIPS: 'analysis.trips',
  ANALYSIS_TRIPS_LIST: 'analysis.tripsList',
  ANALYSIS_CREATE_TRIP: 'analysis.createTrip',
  ANALYSIS_NAME: 'analysis.name',
  ANALYSIS_CREATED: 'analysis.created',
  ANALYSIS_WAYPOINTS: 'analysis.waypoints',
  ANALYSIS_DISTANCE: 'analysis.distance',
  ANALYSIS_TRIPS_ERROR: 'analysis.tripsError',
  ANALYSIS_TRIPS_DOWNLOAD_ERROR: 'analysis.tripsDownloadError',
  ANALYSIS_TRIPS_DELETE_ERROR: 'analysis.tripsDeleteError',
  ANALYSIS_TRIPS_DELETE_SUCCESS: 'analysis.tripsDeleteSuccess',
  ANALYSIS_TRIPS_BACK_TO_TRIP_LIST: 'analysis.backToTripList',
  ANALYSIS_TRIPS_NAME: 'analysis.tripName',
  ANALYSIS_TRIPS_COMMENTS: 'analysis.comments',
  ANALYSIS_TRIPS_COMMENTS_HERE: 'analysis.commentsHere',
  ANALYSIS_TRIPS_DRAW: 'analysis.draw',
  ANALYSIS_TRIPS_DRAW_HELP: 'analysis.drawHelp',
  ANALYSIS_TRIPS_ORDER: 'analysis.order',
  ANALYSIS_TRIPS_LATITUDE: 'analysis.latitude',
  ANALYSIS_TRIPS_LONGITUDE: 'analysis.longitude',
  ANALYSIS_TRIPS_BEARING: 'analysis.bearing',
  ANALYSIS_TRIPS_SAVE_SUCCESS: 'analysis.tripsSaveSuccess',
  ANALYSIS_TRIPS_SAVE_ERROR: 'analysis.tripsSaveError',
  FISH_CATCH_DASHBOAD_UPLOAD: 'fishCatch.dashboard.upload',
  FISH_CATCH_DASHBOAD_REPORT: 'fishCatch.dashboard.report',
  FISH_CATCH_DASHBOARD_LIST: 'fishCatch.dashboard.list',
  FISH_CATCH_DASHBOARD_UPLOADED_FILES: 'fishCatch.dashboard.uploadedFiles',
  FISH_CATCH_DASHBOARD_FILE_NAME: 'fishCatch.dashboard.fileName',
  FISH_CATCH_DASHBOARD_SIZE: 'fishCatch.dashboard.size',
  FISH_CATCH_DASHBOARD_CREATED_AT: 'fishCatch.dashboard.createdAt',
  FISH_CATCH_DASHBOARD_TYPE: 'fishCatch.dashboard.type',
  FISH_CATCH_DASHBOARD_NO_OF_RECORDS: 'fishCatch.dashboard.noOfRecords',
  FISH_CATCH_DASHBOARD_USER_ID: 'fishCatch.dashboard.userId',
  FISH_CATCH_DASHBOARD_RECORDS: 'fishCatch.dashboard.records',
  FISH_CATCH_DASHBOAD_SELECT_MAP: 'fishCatch.dashboard.selectMap',
  FISH_CATCH_DASHBOAD_POPUP_UPLOAD_SUCCESS:
    'fishCatch.dashboard.popup.successMsg',
  FISH_CATCH_DASHBOAD_POPUP_UPLOAD_ERROR: 'fishCatch.dashboard.popup.errorMsg',
  FISH_CATCH_DASHBOAD_POPUP_CSV_UPLOAD_ERROR:
    'fishCatch.dashboard.popup.uploadErr',
  FISH_CATCH_DASHBOAD_POPUP_POPUP_TITLE:
    'fishCatch.dashboard.popup.popUpHeader',
  FISH_CATCH_DASHBOAD_POPUP_STEP_ONE_LABEL:
    'fishCatch.dashboard.popup.stepOneLable',
  FISH_CATCH_DASHBOAD_POPUP_STEP_TWO_LABEL:
    'fishCatch.dashboard.popup.stepTwoLable',
  FISH_CATCH_DASHBOAD_POPUP_BROWSE_FILE: 'fishCatch.dashboard.popup.browseFile',
  FISH_CATCH_DASHBOAD_POPUP_REMOVE_FILE: 'fishCatch.dashboard.popup.removeFile',
  FISH_CATCH_DASHBOAD_POPUP_PLACEHOLDER:
    'fishCatch.dashboard.popup.uploadPlaceholder',
  FISH_CATCH_DASHBOAD_POPUP_NEXT: 'fishCatch.dashboard.popup.next',
  FISH_CATCH_DASHBOAD_POPUP_CLOSE: 'fishCatch.dashboard.popup.close',
  FISH_CATCH_DASHBOAD_POPUP_BACK: 'fishCatch.dashboard.popup.back',
  FISH_CATCH_DASHBOAD_POPUP_UPLOAD: 'fishCatch.dashboard.popup.upload',
  FISH_CATCH_DASHBOAD_POPUP_CATCH_DATE: 'fishCatch.dashboard.popup.catchDate',
  FISH_CATCH_DASHBOAD_POPUP_VESSEL_NAME: 'fishCatch.dashboard.popup.vesselName',
  FISH_CATCH_DASHBOAD_POPUP_LATITUDE: 'fishCatch.dashboard.popup.latitude',
  FISH_CATCH_DASHBOAD_POPUP_LONGITUDE: 'fishCatch.dashboard.popup.longitude',
  FISH_CATCH_DASHBOAD_POPUP_FISHING_TECHNIQUE:
    'fishCatch.dashboard.popup.fishingTechnique',
  FISH_CATCH_DASHBOAD_POPUP_SPECIES: 'fishCatch.dashboard.popup.species',
  FISH_CATCH_DASHBOAD_POPUP_QUANTITY: 'fishCatch.dashboard.popup.quantity',
  FISH_CATCH_DASHBOAD_POPUP_CATCH_DETAILS:
    'fishCatch.dashboard.popup.catchDetails',
  FISH_CATCH_DASHBOAD_POPUP_TOTAL_QUANTITY:
    'fishCatch.dashboard.popup.totalQuantity',
  FISH_CATCH_DASHBOAD_POPUP_COMMENT: 'fishCatch.dashboard.popup.comment',
  FISH_CATCH_DASHBOAD_POPUP_PLANKTON: 'fishCatch.dashboard.popup.plankton',
  FISH_CATCH_DASHBOAD_POPUP_TEMP_C: 'fishCatch.dashboard.popup.temp_c',
  FISH_CATCH_DASHBOAD_POPUP_TEMP_25: 'fishCatch.dashboard.popup.temp_25',
  FISH_CATCH_DASHBOAD_POPUP_TEMP_50: 'fishCatch.dashboard.popup.temp_50',
  FISH_CATCH_DASHBOAD_POPUP_TEMP_100: 'fishCatch.dashboard.popup.temp_100',
  FISH_CATCH_DASHBOAD_POPUP_TEMP_150: 'fishCatch.dashboard.popup.temp_150',
  FISH_CATCH_DASHBOAD_POPUP_TEMP_200: 'fishCatch.dashboard.popup.temp_200',
  FISH_CATCH_DASHBOAD_POPUP_TEMP_300: 'fishCatch.dashboard.popup.temp_300',
  FISH_CATCH_DASHBOAD_POPUP_CURRENT_SPEED:
    'fishCatch.dashboard.popup.currentSpeed',
  FISH_CATCH_DASHBOAD_POPUP_CURRENT_DIRECTION:
    'fishCatch.dashboard.popup.currentDirection',
  FISH_CATCH_DASHBOAD_POPUP_SSHA: 'fishCatch.dashboard.popup.ssha',
  FISH_CATCH_DASHBOAD_POPUP_THERMOCLIENE_DEPTH:
    'fishCatch.dashboard.popup.thermoclieneDepth',
  FISH_CATCH_DASHBOAD_POPUP_BATHYMETRY: 'fishCatch.dashboard.popup.bathymetry',
  FISH_CATCH_DASHBOAD_POPUP_SALINITY: 'fishCatch.dashboard.popup.salinity',
  FISH_CATCH_DASHBOAD_POPUP_PRESSURE: 'fishCatch.dashboard.popup.pressure',
  FISH_CATCH_DASHBOAD_POPUP_WIND_SPEED: 'fishCatch.dashboard.popup.windSpeed',
  FISH_CATCH_DASHBOAD_POPUP_WIND_DIRECTION:
    'fishCatch.dashboard.popup.windDirection',
  FISH_CATCH_DASHBOAD_POPUP_WAVE_HEIGHT: 'fishCatch.dashboard.popup.waveHeight',
  FISH_CATCH_DASHBOARD_SUMMARY_TOTAL_CATCHES:
    'fishCatch.dashboard.summary.totalCatches',
  FISH_CATCH_DASHBOARD_SUMMARY_SPECIES_CAUGHT_MOST:
    'fishCatch.dashboard.summary.speciesCaughtMost',
  FISH_CATCH_DASHBOARD_SUMMARY_MIN: 'fishCatch.dashboard.summary.min',
  FISH_CATCH_DASHBOARD_SUMMARY_MAX: 'fishCatch.dashboard.summary.max',
  FISH_CATCH_DASHBOARD_SUMMARY_AVG: 'fishCatch.dashboard.summary.avg',
  FISH_CATCH_DASHBOARD_SUMMARY_W_AVG: 'fishCatch.dashboard.summary.wAvg',
  FISH_CATCH_DASHBOARD_FISH_PER_VESSEL_HEADER:
    'fishCatch.dashboard.widgets.fishPerVessel.header',
  FISH_CATCH_DASHBOARD_AVG_CATCH_PER_SET_HEADER:
    'fishCatch.dashboard.widgets.avgCatchPerSet.header',
  FISH_CATCH_DASHBOARD_POPUP_CATCH_DATE: 'fishCatch.dashboard.popup.catchDate',
  FISH_CATCH_FILTER_VESSEL: 'fishCatch.dashboard.vessel',
  FISH_CATCH_FILTER_SPECIES: 'fishCatch.dashboard.species',
  FISH_CATCH_FILTER_FISHING_TECHNIQUE: 'fishCatch.dashboard.fishingTechnique',
  MASTERCAST_SETTINGS: 'mastercastSettings.mastercastSetting',
  MASTERCAST_SETTINGS_NO_DATA_MSG: 'mastercastSettings.noDataMsg',
  MASTERCAST_SETTINGS_RANGE_RULES: 'mastercastSettings.rangeRules',
  MASTERCAST_SETTINGS_SETTINGS: 'mastercastSettings.settings',
  MASTERCAST_SETTINGS_OPACITY: 'mastercastSettings.opacity',
  MASTERCAST_SETTINGS_SAVE_ERROR_MSG: 'mastercastSettings.settingsSaveError',
  FISH_CATCH_DASHBOARD_MAP_HEATMAP: 'fishCatch.dashboard.widgets.maps.heatMap',
  FISH_CATCH_DASHBOARD_MAP_ANALYSIS:
    'fishCatch.dashboard.widgets.maps.analysis',
  MASTERCAST_SETTINGS_FILLWIDTH: 'mastercastSettings.fillWidth',
  SLIDER_DURATION: 'slider.duration',
  IUU_SETTINGS_GEOFENCE_NAME_ERROR_MESSAGE:
    'iuu.settings.geofenseNameErrorMessage',
  IUU_SETTINGS_GEOFENCE_EMAIL_ERROR_MESSAGE:
    'iuu.settings.geofenseEmailErrorMessage',
  IUU_SETTINGS_GEOFENCE_DATERANGE_ERROR_MESSAGE:
    'iuu.settings.geofenseDateRangeErrorMessage',
  IUU_SETTINGS_GEOFENCE_DATA_ERROR_MESSAGE:
    'iuu.settings.geofenseDataErrorMessage',
  CALENDAR_LAST24HOURS: 'calendar.last24Hours',
  CALENDAR_LAST7DAYS: 'calendar.last7Days',
  CALENDAR_LAST30DAYS: 'calendar.last30Days',
  CALENDAR_LAST1YEAR: 'calendar.last1Year',
  CALENDAR_ALLDATA: 'calendar.allData',
  HEADER_MAPS: 'header.maps',
  HEADER_IUU: 'header.iuu',
  HEADER_FISH_CATCH: 'header.fishCatch',
  AUTH_LOGOUT: 'auth.logout',
  USER_SETTINGS_GENERAL: 'userSettings.general',
  USER_SETTINGS_PRODUCTS: 'userSettings.products',
};

// export const isI18nKey = (value: I18nKey | string): value is I18nKey =>
//   includes(values(I18nKey), value);
