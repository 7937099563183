export const legendWrapperStyles: any = {
  wrapper: {
    height: '135px',
    width: 'fit-content',
    backgroundColor: '#121212',
    color: '#ffffff',
    borderRadius: '12px',
    position: 'absolute',
    left: '4%',
    bottom: '5%',
  },
  title: {
    fontSize: '18px !important',
    color: '#ffffff',
  },
  headingBox: {
    marginTop: '5px',
    marginLeft: '30px',
  },
  subtitle: {
    fontSize: '14px !important',
    color: '#ffffff',
  },
  settingsIcon: {
    width: '35px',
    height: '35px',
    position: 'absolute',
    left: '-15px',
    top: '-15px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  closeIcon: {
    width: '35px',
    height: '35px',
    position: 'relative',
    right: '-15px',
    top: '-15px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  leftElement: {
    width: 'fit-content',
  },
  rightElement: {
    paddingLeft: '5px',
    width: 'fit-content',
  },
  leftElementBox: {
    marginTop: '21px',
  },
  separatorStyle: {
    borderColor: 'white',
    paddingLeft: '24px',
  },
};
