import { AxiosResponse } from 'axios';

export enum StorageType {
  localStorage = 'localStorage',
  sessionStorage = 'sessionStorage',
  cacheStorage = 'cacheStorage',
}

export enum RequestMethod {
  get = 'get',
  post = 'post',
}

export interface CacheEntry {
  max_age: number;
  response: AxiosResponse<any>;
  entryTime: number;
}

export interface CachableUrlConfig {
  urlString?: RegExp;
  max_age?: number;
  splitString?: string;
  age?: number;
  storageType: StorageType;
  method: RequestMethod;
}
export interface CacheUrlConfig {
  [key: string]: CachableUrlConfig;
}

export const max_cache_age = 100000000; // in milliseconds

export const VESSEL_DATA_AGE = 3600000;

export const CACHABLE_URL_CONFIG: CacheUrlConfig = {
  // products: {
  //   urlString: new RegExp('default=true'),
  //   age: max_cache_age,
  //   storageType: StorageType.cacheStorage,
  //   method: RequestMethod.post,
  // },
  productDefaults: {
    urlString: new RegExp('products/get/'),
    age: max_cache_age,
    storageType: StorageType.sessionStorage,
    method: RequestMethod.get,
  },
  // featureDefaults: {
  //   urlString: new RegExp('features/displayDefaults'),
  //   age: max_cache_age,
  //   storageType: StorageType.sessionStorage,
  //   method: RequestMethod.get,
  // },
  // unitDefaults: {
  //   urlString: new RegExp('/units/get/'),
  //   age: max_cache_age,
  //   storageType: StorageType.sessionStorage,
  //   method: RequestMethod.get,
  // },
  regionDefaults: {
    urlString: new RegExp('regions/get'),
    age: max_cache_age,
    storageType: StorageType.sessionStorage,
    method: RequestMethod.get,
  },
  // colorScales: {
  //   urlString: new RegExp('colorScale/get'),
  //   age: max_cache_age,
  //   storageType: StorageType.cacheStorage,
  //   method: RequestMethod.get,
  // },
  // iuu: {
  //   urlString: new RegExp('/vesselData/'),
  //   max_age: VESSEL_DATA_AGE,
  //   splitString: '&from',
  //   storageType: StorageType.cacheStorage,
  //   method: RequestMethod.get,
  // },
  // s3: {
  //   urlString: new RegExp(
  //     `${process.env.REACT_APP_S3_URL}/\\d{4}/\\d{2}/\\d{2}/(vector|raster|contour)`
  //   ),
  //   age: max_cache_age,
  //   splitString: '?',
  //   storageType: StorageType.cacheStorage,
  //   method: RequestMethod.get,
  // },
  // eezBTH: {
  //   urlString: new RegExp(`${process.env.REACT_APP_S3_URL}/(EEZ|BTH)`),
  //   age: max_cache_age,
  //   splitString: '?',
  //   storageType: StorageType.cacheStorage,
  //   method: RequestMethod.get,
  // },
};
