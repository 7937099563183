import { Dispatch, FC, SetStateAction } from 'react';
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useCSVReader } from 'react-papaparse';
import { Cancel, FileOpen } from '@mui/icons-material';
import { UploadFileMetadata } from '../../../FishCatchDashboard/ImportDataSection/ImportPopUp';
import { I18nKey } from '../../../../translations/I18nKey';
import { useVesselUploadStyles } from './VesselBuoyStyles';

interface ImportPopupDataUploadProps {
  setFileData: Dispatch<SetStateAction<any[] | string[]>>;
  setFile: Dispatch<SetStateAction<any>>;
  displayAlert: (type: string, message: string) => void;
  setFileMetadata: Dispatch<SetStateAction<{} | UploadFileMetadata>>;
}

interface UploadFooterProps {
  fileData: string[] | any[];
  handleNext: () => void;
}

export const VesselBuoyImportDataUpload: FC<ImportPopupDataUploadProps> = ({
  setFileData,
  setFile,
  displayAlert,
  setFileMetadata,
}) => {
  const intl = useIntl();
  const { CSVReader } = useCSVReader();
  const VesselUploadStyles: any = useVesselUploadStyles();

  return (
    <>
      <CSVReader
        onUploadAccepted={(results: any) => {
          setFileData(results.data);
        }}
        onUploadRejected={() =>
          displayAlert(
            'error',
            intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_CSV_UPLOAD_ERROR,
            })
          )
        }
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
        }: any) => {
          if (acceptedFile !== null && acceptedFile && acceptedFile.name) {
            setFileMetadata(acceptedFile);
            setFile(acceptedFile);
          }
          return (
            <>
              <Box className={VesselUploadStyles.csvReader}>
                <Box className={VesselUploadStyles.acceptedFile}>
                  {acceptedFile && acceptedFile.name ? (
                    acceptedFile.name
                  ) : (
                    <Typography className={VesselUploadStyles.placeholderText}>
                      {intl.formatMessage({
                        id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_PLACEHOLDER,
                      })}
                    </Typography>
                  )}
                </Box>
                {acceptedFile && acceptedFile.name ? (
                  <Tooltip
                    title={intl.formatMessage({
                      id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_REMOVE_FILE,
                    })}
                  >
                    <Button
                      {...getRemoveFileProps()}
                      className={VesselUploadStyles['upload-csv-action-btn']}
                    >
                      <Cancel />
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={intl.formatMessage({
                      id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_BROWSE_FILE,
                    })}
                  >
                    <Button
                      type="button"
                      {...getRootProps()}
                      className={VesselUploadStyles['upload-csv-action-btn']}
                    >
                      <FileOpen />
                    </Button>
                  </Tooltip>
                )}
              </Box>
              <ProgressBar
                className={VesselUploadStyles.progressBarBackgroundColor}
              />
            </>
          );
        }}
      </CSVReader>
    </>
  );
};

export const VesselBuoyUploadFooter: FC<UploadFooterProps> = ({
  fileData,
  handleNext,
}) => {
  const intl = useIntl();
  const VesselUploadStyles: any = useVesselUploadStyles();

  return (
    <Grid item xs={12}>
      <Button
        className={`${VesselUploadStyles.popUpFloatRight}
          ${VesselUploadStyles.popUpWarningColor}`}
        variant="contained"
        disabled={fileData.length === 0}
        onClick={handleNext}
      >
        {intl.formatMessage({
          id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_NEXT,
        })}
      </Button>
    </Grid>
  );
};
