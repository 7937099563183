import { FC, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import { useFishCatchListStyles } from '../FishCatchDashboardStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
//This is for testing the data grid
import {
  DataGrid,
  GridSelectionModel,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { useMutation, useQuery } from 'react-query';
import FishCatchClient from '../../../api/fishCatchAPIs';
import FishCatchLoadingList from './FishCatchLoadingList';
import FishCatchErrorList from './FishCatchErrorList';
import moment from 'moment';
import { VariantType, closeSnackbar, useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { saveAs } from 'file-saver';
import { useFetchFishCatch, useNavFishCatch } from '../store/FishCatchStore';
import FishCatchAlert from './FishCatchAlert';
import { useQueryClient } from 'react-query';
import { getUserAttributes } from '../../../utils/auth';
import { I18nKey } from '../../../translations/I18nKey';
import { useIntl } from 'react-intl';

interface FishCatchFile {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  size: number;
  no_of_records: number;
  type: string;
}

interface deleteFiles {
  userName: string;
  fileId: any[];
}

const FishCatchFileList: FC<any> = ({ setID }) => {
  const intl = useIntl();
  const { userName, seastarId } = getUserAttributes();
  // --- Store ---
  const setNav = useNavFishCatch((state) => state.setNav);
  const reFetch = useFetchFishCatch((state) => state.fetchFiles);
  const setReFetch = useFetchFishCatch((state) => state.setReFetchFiles);

  // --- Set Store Query ---
  const queryClient = useQueryClient();

  // --- State Selection Data ---
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridSelectionModel>([]);

  // --- Columns Table ---
  const [columnsTable, setColumnsTable] = useState<GridColDef[] | any>([]);

  // --- Styling Class for component
  const fishCatchClasses: any = useFishCatchListStyles();

  // --- Modal ---
  const [infoCard, setInfoCard] = useState<boolean>(true);
  const [warningCard, setWarningCard] = useState<boolean>(false);
  const [titleCard, setTitleCard] = useState<string>('');
  const [messageCard, setMessageCard] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);

  function handleCloseModal() {
    setOpenModal(false);
  }

  // --- Toast Message ---
  const { enqueueSnackbar } = useSnackbar();

  const action = (snackbarId: any) => {
    return (
      <IconButton
        aria-label="close-toast"
        onClick={() => closeSnackbar(snackbarId)}
      >
        <CloseIcon />
      </IconButton>
    );
  };

  // --- Call Toast ---
  const callToast = (variant: VariantType, message: any) => {
    enqueueSnackbar(message, {
      variant,
      action,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
  };

  // --- Fetch Data Function ---
  const getFishCatchFiles = async () => {
    const response = await FishCatchClient.getFishCatchFile(userName);

    return response.results;
  };

  // --- React Query: GET & DELETE ---
  const {
    isLoading,
    isError,
    error,
    isFetching,
    refetch: FishCatchFiles,
  } = useQuery({
    queryFn: getFishCatchFiles,
    enabled: false,
    onSuccess(data) {
      setFilesData(data);
    },
  });

  const [filesData, setFilesData] = useState<any[]>();

  const mutationDelete = useMutation({
    mutationFn: async ({ userName, fileId }: deleteFiles) => {
      const response = await FishCatchClient.deleteFishCatchFile(
        userName,
        fileId
      );
      return response;
    },
  });

  const mutationCSV = useMutation({
    mutationFn: async (fileID: string) => {
      return await FishCatchClient.getFishCatchRecordCSV(fileID);
    },
  });

  useEffect(() => {
    FishCatchFiles();
  }, []);

  useEffect(() => {
    if (reFetch) {
      FishCatchFiles();
      setReFetch();
    }
  }, [reFetch]);

  useEffect(() => {
    // Check the conditions before updating the state
    if (
      !isLoading &&
      !isFetching &&
      columnsTable.length === 0 &&
      filesData?.length
    ) {
      setColumnsTable(transformDataToColumns);
    }
  }, [isLoading, isFetching, columnsTable.length]);

  // --- Size of File formatter ---
  function formatFileSize(sizeInBytes: number): string {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let unitIndex = 0;

    while (sizeInBytes >= 1024 && unitIndex < units.length - 1) {
      sizeInBytes /= 1024;
      unitIndex++;
    }

    return sizeInBytes.toFixed(2) + ' ' + units[unitIndex];
  }

  // --- Modifies the render view of the first column to include clickable nav ---
  const viewDataFile = (props: GridRenderCellParams<string>) => {
    const { value, id } = props;
    const viewSelectionOption = () => {
      setID(id);
      const dataSubmitted = getFileSubmittedDateById(id);
      setNav({
        title: props.row.name,
        selection: 'InfoTable',
        submittedDate: dataSubmitted,
      });
    };

    return (
      <Box className={fishCatchClasses.showInformationFile}>
        <Box>{value}</Box>
        <IconButton
          aria-label="fingerprint"
          style={{ color: '#242424' }}
          onClick={viewSelectionOption}
        >
          <OpenInNewIcon />
        </IconButton>
      </Box>
    );
  };

  const viewDateOnly = (props: GridRenderCellParams<string>) => {
    const { value } = props;
    const dateValue = value ? new Date(value) : null;
    return (
      <Box className={fishCatchClasses.showInformationFile}>
        <Box>{moment(dateValue).format('LL')}</Box>
      </Box>
    );
  };

  const viewDateFile = (props: GridRenderCellParams<string>) => {
    const { value } = props;

    if (value === null) {
      return (
        <Box className={fishCatchClasses.showInformationFile}>
          <Box>No Date</Box>
        </Box>
      );
    }
    if (value !== null) {
      const dateValue = value ? new Date(value) : null;

      return (
        <Box className={fishCatchClasses.showInformationFile}>
          <Box>{moment(dateValue).format('LLL')}</Box>
        </Box>
      );
    }
  };

  const viewSizeFile = (props: GridRenderCellParams<string>) => {
    const { value } = props;

    const sizeNum: number | null = value ? parseFloat(value) : null;

    return (
      <Box className={fishCatchClasses.showInformationFile}>
        <Box>{sizeNum !== null ? formatFileSize(sizeNum) : 'N/A'}</Box>
      </Box>
    );
  };
  const translateKeys: any = {
    type: intl.formatMessage({
      id: I18nKey.FISH_CATCH_DASHBOARD_TYPE,
    }),
    no_of_records: intl.formatMessage({
      id: I18nKey.FISH_CATCH_DASHBOARD_NO_OF_RECORDS,
    }),
    user_id: intl.formatMessage({
      id: I18nKey.FISH_CATCH_DASHBOARD_USER_ID,
    }),
  };

  const transformDataToColumns = (): GridColDef[] => {
    const columns: GridColDef[] = [];
    if (filesData) {
      for (const key in filesData[0]) {
        // Skip 'id' field
        if (key === 'id') continue;

        if (key === 'name') {
          columns.push({
            field: key,
            headerName: intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOARD_FILE_NAME,
            }),
            width: 400,
            hide: false,
            renderCell: (props: GridRenderCellParams<string>) =>
              viewDataFile(props),
          });
        } else if (key === 'created_at' || key === 'updated_at') {
          columns.push({
            field: key,
            headerName: intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOARD_CREATED_AT,
            }),
            width: 250,
            hide: false,
            renderCell: viewDateFile,
          });
        } else if (key === 'size') {
          columns.push({
            field: key,
            headerName: intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOARD_SIZE,
            }),
            width: 100,
            hide: false,
            renderCell: viewSizeFile,
          });
        } else {
          columns.push({
            field: key,
            headerName: translateKeys[key],
            width: 200,
            hide: false,
          });
        }
      }
    }
    return columns;
  };

  // --- Return the File Submitted Date selected---
  function getFileSubmittedDateById(selectedId: any) {
    if (filesData?.length)
      for (const result of filesData) {
        if (result.id === selectedId) {
          return result.created_at;
        }
      }

    return 'submitted_date_file';
  }

  // --- Return the File Name selected ---
  function getFileNameById(selectedId: any) {
    for (const result of filesData as FishCatchFile[]) {
      if (result.id === selectedId) {
        return result.name;
      }
    }
    return 'data-file';
  }

  // --- Download the CSV it can be single or multi download ---
  const csvOptionsDownload = async () => {
    const idsToDownload = rowSelectionModel;

    for (const id of idsToDownload) {
      try {
        const response = await mutationCSV.mutateAsync(id.toString());
        const nameFile = getFileNameById(id);

        // Create a Blob containing the CSV data
        const blob = new Blob([response], { type: 'text/csv' });

        // Use file-saver to save the Blob as a CSV file
        saveAs(blob, `${nameFile}.csv`);
      } catch (error) {
        if (error instanceof Error) {
          callToast('error', error.message);
        }

        return;
      }
    }

    callToast('success', 'Records of the file have been fetched.');
  };

  const deleteOption = async () => {
    const idsToDelete = rowSelectionModel;

    handleCloseModal();

    try {
      const response = await mutationDelete.mutateAsync({
        userName: userName,
        fileId: idsToDelete,
      });
      if (response) {
        callToast('success', `File has been eliminated and all its records.`);
        setReFetch();
      }
      console.log(response);
    } catch (error) {
      if (error instanceof Error) {
        callToast('error', error.message);
        console.log('Error Message: ', error.message);
      }

      return;
    }
  };

  function CustomToolBar() {
    return (
      <GridToolbarContainer>
        <Box className={fishCatchClasses.toolbarContainer}>
          <Box>
            <Button
              startIcon={<DeleteIcon />}
              onClick={() => {
                setInfoCard(true);
                setOpenModal(true);
                setTitleCard('Delete Files');
                setMessageCard('Want to delete the files selected ?');
                setWarningCard(false);
              }}
              disabled={rowSelectionModel.length === 0}
              sx={{
                '& .Mui-disabled': {
                  color: '#7F7F7F ',
                },
              }}
            >
              Delete
            </Button>
            <Button
              startIcon={<GetAppIcon />}
              onClick={csvOptionsDownload}
              disabled={rowSelectionModel.length === 0}
              sx={{
                '& .Mui-disabled': {
                  color: '#7F7F7F ',
                },
              }}
            >
              Export
            </Button>
          </Box>
          <Box>
            <GridToolbarQuickFilter />
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }

  if (isLoading || isFetching) {
    return <FishCatchLoadingList option1={true} option2={false} />;
  }

  if (isError) {
    if (error instanceof Error) {
      callToast('error', error.message);
    }

    return <FishCatchErrorList reFetchData={setReFetch} />;
  }

  return (
    <>
      {openModal === true && (
        <FishCatchAlert
          info={infoCard}
          warning={warningCard}
          title={titleCard}
          message={messageCard}
          setOkay={deleteOption}
          setClose={handleCloseModal}
        />
      )}
      <Box
        sx={{
          height: 600,
          width: '100%',
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#FFFFFF',
            color: 'rgba(36, 36, 36, 1)',
            fontWeight: 700,
          },
          '& .MuiDataGrid-iconButtonContainer': {
            color: '#FFFFFF',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 700,
            fontSize: '16px',
          },
          '& .MuiSvgIcon-root': {
            fill: '#B3B5B8',
          },
          '& .MuiButton-textPrimary': {
            color: '#000000',
            fontWeight: '700',
          },
        }}
      >
        <DataGrid
          disableColumnFilter
          disableColumnSelector
          checkboxSelection
          columns={columnsTable}
          rows={filesData?.length ? filesData : []}
          components={{ Toolbar: CustomToolBar }}
          onSelectionModelChange={(newSelectionMododel) => {
            setRowSelectionModel(newSelectionMododel);
          }}
          selectionModel={rowSelectionModel}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              printOptions: { disableToolbarButton: true },
            },
          }}
        />
      </Box>
    </>
  );
};

export default FishCatchFileList;
