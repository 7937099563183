import { useState, createElement, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { useContext } from 'use-context-selector';
import { LicensedFeaturesContext } from '../Contexts/LicensedFeaturesContext';
import Default from '../Default/Default';
import Logout from '../../containers/PublicHome/Logout';
import PrivateHome from '../PrivateHome/PrivateHome';
import { withPrivateRoute } from './routeHOCs';
import { DEFAULT_PRIVATE_ROUTES, PUBLIC_ROUTES } from './RouterConfig';

const AppRoutes = () => {
  const { licensedRoutes, isLoading } = useContext(LicensedFeaturesContext);
  const [licencedRoutesConfig, setLicencedRoutesConfig] = useState<any>([
    {
      path: '/',
      element: createElement(withPrivateRoute(PrivateHome)),
      children: [...DEFAULT_PRIVATE_ROUTES],
    },
    ...PUBLIC_ROUTES,
    {
      path: '/logout',
      element: createElement(Logout),
    },
    {
      path: '*',
      element: createElement(Default),
    },
  ]);

  useEffect(() => {
    if (licensedRoutes.length && !isLoading) {
      // Load Route Config with the updated licensed routes.
      let _licencedRoutesConfig = [...licencedRoutesConfig];
      _licencedRoutesConfig[0].children = [...licensedRoutes];
      setLicencedRoutesConfig(_licencedRoutesConfig);
    }
  }, [isLoading, licensedRoutes]);

  return useRoutes(licencedRoutesConfig);
};

export const AppRouter: React.FC = () => {
  return <AppRoutes />;
};
