import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../../../translations/I18nKey';

export const ButtonSection: React.FC<any> = ({
  title,
  onSaveDefault,
  onApply,
  onRestore,
}) => {
  const intl = useIntl();
  return (
    <Box sx={{ textAlign: 'end' }}>
      <Typography
        sx={{
          fontSize: '14px',
          color: '#B8B8B8',
          marginLeft: '3%',
          display: 'inline',
          float: 'left',
          marginTop: '2%',
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: '14px',
          color: '#B8B8B8',
          display: 'inline',
        }}
      >
        <Button
          onClick={onSaveDefault}
          variant="text"
          sx={{ color: '#B8B8B8', fontSize: '14px', textTransform: 'none' }}
        >
          <u>
            {intl.formatMessage({
              id: I18nKey.PRODUCT_SETTING_DIALOG_BOX_SAVEDEFAULT,
            })}
          </u>
        </Button>
      </Typography>
      <Typography
        sx={{
          fontSize: '14px',
          color: '#B8B8B8',
          marginLeft: '3%',
          display: 'inline',
        }}
      >
        <Button
          onClick={onRestore}
          sx={{ color: '#B8B8B8', fontSize: '14px', textTransform: 'none' }}
          variant="text"
        >
          <u>
            {intl.formatMessage({
              id: I18nKey.PRODUCT_SETTING_DIALOG_BOX_RESTORE,
            })}
          </u>
        </Button>
      </Typography>
      <Typography
        sx={{
          fontSize: '14px',
          color: '#B8B8B8',
          marginLeft: '2%',
          display: 'inline',
        }}
      >
        <Button
          onClick={onApply}
          sx={{ color: '#ffa500', fontSize: '14px', textTransform: 'none' }}
          variant="text"
        >
          <u>
            {intl.formatMessage({
              id: I18nKey.PRODUCT_SETTING_DIALOG_BOX_APPLY,
            })}
          </u>
        </Button>
      </Typography>
    </Box>
  );
};

export default ButtonSection;
