import { FC, useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';
import { useMutation } from 'react-query';
import { VariantType, closeSnackbar, useSnackbar } from 'notistack';
import FishCatchClient from '../../../../api/fishCatchAPIs';
import { useQueryClient, useQuery } from 'react-query';
import {
  useCardChangeContent,
  useChangeOnData,
  useFetchFishCatch,
} from '../../store/FishCatchStore';
import { isEqual } from 'lodash';
import FishCatchAlert from '../FishCatchAlert';
import { getUserAttributes } from '../../../../utils/auth';
import { I18nKey } from '../../../../translations/I18nKey';
import { useIntl } from 'react-intl';

interface updateRecord {
  userName: string;
  recordId: string;
  fishCatchData: any;
}

const GIContent: FC<any> = () => {
  const intl = useIntl();
  const { userName, seastarId } = getUserAttributes();
  // --- Store ---
  const queryClient = useQueryClient();
  const dataGI = useQuery('GI', () => queryClient.getQueryData('GI'));

  const setChangeGI = useChangeOnData((state) => state.setChangeGI);
  const changeGI = useChangeOnData((state) => state.changeGI);
  const changeCardReset = useCardChangeContent((state) => state.reFetchCard);
  const setResetCard = useFetchFishCatch((state) => state.setReFetchRecord);
  const setChangeCard = useCardChangeContent((state) => state.setRefetchCard);

  // --- Modal ---
  const [infoCard, setInfoCard] = useState<boolean>(true);
  const [warningCard, setWarningCard] = useState<boolean>(false);
  const [titleCard, setTitleCard] = useState<string>('');
  const [messageCard, setMessageCard] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);

  function handleCloseModal() {
    setOpenModal(false);
  }

  // --- Toast Message ---
  const { enqueueSnackbar } = useSnackbar();

  // --- Call Toast ---
  const action = (snackbarId: any) => {
    return (
      <IconButton
        aria-label="close-toast"
        onClick={() => closeSnackbar(snackbarId)}
      >
        <CloseIcon />
      </IconButton>
    );
  };

  const callToast = (variant: VariantType, message: any) => {
    enqueueSnackbar(message, {
      variant,
      action,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
  };

  // --- React Query: PATCH ---
  const mutationPatch = useMutation({
    mutationFn: async ({ userName, recordId, fishCatchData }: updateRecord) => {
      const response = await FishCatchClient.updateFishCatchRecord(
        userName,
        recordId,
        fishCatchData
      );
      return response;
    },
  });

  // --- Save information API: PATCH ---
  const updateGeneralInfo = async () => {
    const result = {
      uuid: (dataGI.data as any)?.uuid,
      catch_date: new Date(formik.values.catchDate),
      vessel_name: formik.values.vesselName,
      latitude: formik.values.latitude,
      longitude: formik.values.longitude,
      fishing_technique: formik.values.fishingTechnique,
      total_quantity: formik.values.totalQuantity,
      comment: formik.values.comment,
    };

    try {
      const mutationResult = await mutationPatch.mutateAsync({
        userName: userName,
        recordId: (dataGI.data as any)?.uuid,
        fishCatchData: result,
      });
      setChangeGI(false);
      console.log('Mutation Data: ', mutationResult);
    } catch (error) {
      if (error instanceof Error) {
        callToast('error', error.message);
        console.log('Error Message: ', error.message);
        return;
      }
    }

    callToast('success', 'General Information has been updated!');
    setResetCard();
  };

  const initialValues = {
    vesselName: (dataGI.data as any)?.vessel_Name,
    catchDate: moment((dataGI.data as any)?.catch_Date).format('yyyy-MM-DD'),
    fishingTechnique: (dataGI.data as any)?.fishing_technique,
    totalQuantity: (dataGI.data as any)?.total_quantity,
    comment: (dataGI.data as any)?.comment,
    latitude: (dataGI.data as any)?.latitude,
    longitude: (dataGI.data as any)?.longitude,
  };

  // --- Validation of the inputs of search
  const validationSchemaGI = Yup.object({
    vesselName: Yup.string().required('Vessel Name is required'),
    catchDate: Yup.date().required('Catch Date is required'),
    fishingTechnique: Yup.string().required('Fishing Technique is required'),
    comment: Yup.string().optional(),
    totalQuantity: Yup.number().required('Total Quantity is required'),
    latitude: Yup.number().required('Latitude is required'),
    longitude: Yup.number().required('Longitude is required'),
  });

  // --- Initialize Formik ---
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaGI,
    onSubmit: (values) => {
      // Handle form submission
      console.log('VALUES SUBMIT SEARCH: ', values);
    },
  });

  useEffect(() => {
    if (!isEqual(initialValues, formik.values) !== changeGI) {
      if (!isEqual(initialValues, formik.values)) {
        setChangeGI(true);
      } else {
        setChangeGI(false);
      }
    }
  });

  if (changeCardReset === true) {
    setChangeCard(false);
    formik.resetForm();
  }

  function handleOkay() {
    updateGeneralInfo();
    handleCloseModal();
  }

  return (
    <div>
      {openModal === true && (
        <FishCatchAlert
          info={infoCard}
          warning={warningCard}
          title={titleCard}
          message={messageCard}
          setOkay={handleOkay}
          setClose={handleCloseModal}
        />
      )}
      <Typography>
        Provides basic information of the type of fish cached, vessel name,
        location and quantity. This information can be edited, once edited make
        sure to save changes.
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_VESSEL_NAME,
            })}
          </Typography>
          <TextField
            fullWidth
            id="vesselName"
            placeholder="Vessel Name"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            value={formik.values.vesselName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.vesselName && formik.errors.vesselName && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.vesselName}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_CATCH_DATE,
            })}
          </Typography>
          <TextField
            fullWidth
            id="catchDate"
            name="catchDate"
            placeholder="Catch Date"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            type="date"
            value={formik.values.catchDate}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.catchDate && formik.errors.catchDate && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.catchDate}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_FISHING_TECHNIQUE,
            })}
          </Typography>
          <TextField
            fullWidth
            id="fishingTechnique"
            name="fishingTechnique"
            placeholder="Fishing Technique"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            value={formik.values.fishingTechnique}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.fishingTechnique && formik.errors.fishingTechnique && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.fishingTechnique}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_TOTAL_QUANTITY,
            })}
          </Typography>
          <TextField
            fullWidth
            id="totalQuantity"
            name="totalQuantity"
            placeholder="Total Quantity"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            type="number"
            value={formik.values.totalQuantity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.totalQuantity && formik.errors.totalQuantity && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.totalQuantity}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_COMMENT,
            })}
          </Typography>
          <TextField
            fullWidth
            id="comment"
            name="comment"
            placeholder="Comments"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            value={formik.values.comment}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.comment && formik.errors.comment && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.comment}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_LATITUDE,
            })}
          </Typography>
          <TextField
            fullWidth
            id="latitude"
            name="latitude"
            placeholder="Latitude"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            type="number"
            value={formik.values.latitude}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.latitude && formik.errors.latitude && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.latitude}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            margin: '1rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_LONGITUDE,
            })}
          </Typography>
          <TextField
            fullWidth
            id="longitude"
            name="longitude"
            placeholder="Longitude"
            variant="outlined"
            style={{
              border: '1px solid #CDCDCD',
              borderRadius: '5px',
              margin: '2px 0px 2px 0px',
            }}
            type="number"
            value={formik.values.longitude}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.longitude && formik.errors.longitude && (
            <Typography variant="subtitle2" color="error">
              {formik.errors.longitude}
            </Typography>
          )}
        </Box>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          alignItems: 'end',
        }}
      >
        <Button
          variant="outlined"
          style={{
            borderRadius: '10px',
            background: '#D9E8F6',
            color: '#193768',
            borderColor: '#193768',
            borderWidth: '2px',
            marginRight: '15px',
          }}
          onClick={() => {
            formik.resetForm();
            setChangeGI(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          startIcon={<SaveIcon />}
          style={{
            borderRadius: '10px',
            background: '#75DF73',
            color: '#FFFFFF',
            borderColor: '#75DF73',
            borderWidth: '2px',
          }}
          onClick={() => {
            setInfoCard(true);
            setOpenModal(true);
            setTitleCard('Save Changes');
            setMessageCard('Want to save the changes of General Information?');
            setWarningCard(false);
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default GIContent;
