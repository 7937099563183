import { FC, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import HelpIcon from '@mui/icons-material/Help';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';

const FishCatchAlert: FC<any> = ({
  info,
  warning,
  title,
  message,
  setOkay,
  setClose,
}) => {
  return (
    <div>
      {info === true && (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              background: '#FFA500',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <div>
              <HelpIcon style={{ color: '#FFFFFF', fontSize: '90px' }} />
            </div>
          </DialogTitle>
          <DialogContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h5"
              style={{
                color: '#000',
                fontSize: '25px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: 'normal',
                margin: '15px',
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                color: '#000',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                margin: '5px',
              }}
            >
              {message}
            </Typography>
          </DialogContent>
          <DialogActions
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => {
                setClose();
              }}
              style={{
                borderRadius: '10px',
                background: '#D9E8F6',
                color: '#193768',
                borderColor: '#D9E8F6;',
                borderWidth: '2px',
                paddingLeft: '30px',
                paddingRight: '30px',
                marginRight: '10px',
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setOkay();
              }}
              style={{
                borderRadius: '10px',
                background: '#193768',
                color: '#FFFFFF',
                borderColor: '#193768',
                borderWidth: '2px',
                paddingLeft: '40px',
                paddingRight: '40px',
                marginLeft: '10px',
              }}
            >
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {warning === true && (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              background: '#193768',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <div>
              <ErrorIcon style={{ color: '#FFFFFF', fontSize: '90px' }} />
            </div>
          </DialogTitle>
          <DialogContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h5"
              style={{
                color: '#000',
                fontSize: '25px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: 'normal',
                margin: '15px',
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                color: '#000',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                margin: '5px',
              }}
            >
              {message}
            </Typography>
          </DialogContent>
          <DialogActions
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => {
                setClose();
              }}
              style={{
                borderRadius: '10px',
                background: '#D9E8F6',
                color: '#193768',
                borderColor: '#D9E8F6;',
                borderWidth: '2px',
                paddingLeft: '30px',
                paddingRight: '30px',
                marginRight: '10px',
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setOkay();
              }}
              style={{
                borderRadius: '10px',
                background: '#193768',
                color: '#FFFFFF',
                borderColor: '#193768',
                borderWidth: '2px',
                paddingLeft: '40px',
                paddingRight: '40px',
                marginLeft: '10px',
              }}
            >
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default FishCatchAlert;
