export const settingsPopupStyles: any = {
  wrapper: {
    backgroundColor: 'black',
    zIndex: '10',
    width: '568px',
    borderRadius: '12px',
    position: 'relative',
    maxHeight: '100vh',
    float: 'right',
    marginTop: '5em',
  },
  dragSection: {
    position: 'absolute',
    marginLeft: '-18px',
    marginTop: '-18px',
  },
  dragIcon: {
    color: 'white',
    backgroundColor: '#343434',
    padding: '4px',
    cursor: 'pointer',
    borderRadius: '20px',
  },
};
