import { FC, useState } from 'react';
import { Box } from '@mui/material';
import { useFishCatchListStyles } from '../FishCatchDashboardStyles';
import DashboardImage from '../../../assets/images/banner.png';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import Divider from '@mui/material/Divider';
import FishCatchDrawerInfo from './FishCatchDrawerInfo';
import FishCatchCardInfo from './FishCatchCardInfo';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import IconButton from '@mui/material/IconButton';
import { useChangeOnData, useNavFishCatch } from '../store/FishCatchStore';
import { useQueryClient } from 'react-query';
import FishCatchAlert from './FishCatchAlert';

const FishCatchFileInfo: FC<any> = ({ fileID }) => {
  // --- Store ---
  const nav = useNavFishCatch((state) => state.nav);
  const setNav = useNavFishCatch((state) => state.setNav);
  const changeGI = useChangeOnData((state) => state.changeGI);
  const changeCC = useChangeOnData((state) => state.changeCC);
  const changeCD = useChangeOnData((state) => state.changeCD);

  // ---Store Query Client ---
  const queryClient = useQueryClient();

  // --- Modal ---
  const [infoCard, setInfoCard] = useState<boolean>(true);
  const [warningCard, setWarningCard] = useState<boolean>(false);
  const [titleCard, setTitleCard] = useState<string>('');
  const [messageCard, setMessageCard] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);

  function handleCloseModal() {
    setOpenModal(false);
  }

  // --- Style ---
  const fishCatchList: any = useFishCatchListStyles();

  function handleNav() {
    setNav({
      title: 'uploadedFiles',
      selection: 'FileTable',
      submittedDate: '',
    });

    queryClient.setQueryData('GI', undefined);
    queryClient.setQueryData('CC', undefined);
    queryClient.setQueryData('CD', undefined);
    queryClient.setQueryData('UUIDRecord', undefined);
  }

  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      {openModal === true && (
        <FishCatchAlert
          info={infoCard}
          warning={warningCard}
          title={titleCard}
          message={messageCard}
          setOkay={handleNav}
          setClose={handleCloseModal}
        />
      )}
      <Box
        sx={{
          backgroundImage: `url(${DashboardImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '260px',
        }}
      ></Box>
      <div
        style={{
          marginLeft: '2.5rem',
          background: 'transparent',
          border: 'none',
          position: 'absolute',
          top: 0,
          height: '90vh',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'row',
          width: '100vw',
        }}
      >
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <Box
            style={{
              background: 'transparent',
              height: '20vh',
              borderRadius: '0px',
              border: 'none',
              boxShadow: 'none',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginRight: '20px',
                marginLeft: '20px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Box className={fishCatchList.boxBtnArrow}>
                  <IconButton
                    aria-label="delete"
                    size="large"
                    style={{ color: '#193768' }}
                    onClick={() => {
                      if (changeCC || changeCD || changeGI) {
                        setInfoCard(false);
                        setOpenModal(true);
                        setTitleCard('Leave Session');
                        setMessageCard(
                          'Want to leave session even if there is change on data?'
                        );
                        setWarningCard(true);
                      } else {
                        setNav({
                          title: 'uploadedFiles',
                          selection: 'FileTable',
                          submittedDate: '',
                        });

                        queryClient.setQueryData('GI', undefined);
                        queryClient.setQueryData('CC', undefined);
                        queryClient.setQueryData('CD', undefined);
                      }
                    }}
                  >
                    <ArrowBackIosIcon fontSize="inherit" />
                  </IconButton>
                </Box>

                <Typography
                  variant="h4"
                  style={{ color: '#FFFFFF', marginLeft: '20px' }}
                >
                  {nav.title}
                </Typography>
              </Box>
              <Typography variant="h6" style={{ color: '#FFFFFF' }}>
                Submitted Date: {moment(nav.submittedDate).format('LL')}
              </Typography>
            </Box>
            <Divider variant="middle" style={{ borderColor: '#FFFFFF' }} />
          </Box>
          <Box
            style={{
              background: 'transparent',
              height: '80vh',
              borderRadius: '0px',
              border: 'none',
              boxShadow: 'none',
              overflowY: 'scroll',
              scrollbarWidth: 'thin',
              scrollbarColor: 'transparent transparent',
            }}
          >
            <FishCatchCardInfo />
          </Box>
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '30vw' }}
        >
          <Box
            style={{
              background: '#FFFFFF',
              height: '100vh',
              borderRadius: '0px',
              borderLeft: '1px solid rgba(0, 0, 0, 0.25)',
              width: '30vw',
            }}
          >
            <FishCatchDrawerInfo fileID={fileID} />
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default FishCatchFileInfo;
