export const MastercastSettingStyles = {
  root: {
    color: '#6F6F6F !important',
    fontSize: '16px',
  },
  selected: {
    color: '#fff !important',
    fontSize: '16px',
  },
  popup: {
    wrapper: {
      zIndex: 1400,
    },
  },
};

export const settingTabStyles = {
  contourColorPickerWrapper: {
    width: '50%',
    height: '70px',
    backgroundColor: '#242424',
  },
  colorStepperBox: { position: 'relative', display: 'flex', width: '536px' },
  stepperBox: { width: '100%', height: '30px' },
};

export const tabIndicatorProps: any = {
  style: {
    background: 'orange',
    marginTop: '20px',
    height: '3px',
  },
};
