import { FC } from 'react';
import Typography from '@mui/material/Typography';

const FishCatchLbContext: FC<any> = ({ label, content }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '3px',
        flexShrink: '0',
      }}
    >
      <Typography
        variant="h5"
        style={{
          color: '#193768',
          fontSize: '15px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: 'normal',
        }}
      >
        {label}
      </Typography>
      <Typography
        variant="subtitle1"
        style={{
          color: '#000',
          fontSize: '15px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: 'normal',
        }}
      >
        {content}
      </Typography>
    </div>
  );
};

export default FishCatchLbContext;
