import React, { useState } from 'react';
import { createContext } from 'use-context-selector';

interface Language {
  language: string;
  setLanguage: any;
}
export const LanguageContext = createContext<Language>({} as any);

export const LanguageContextProvider: React.FC = (props) => {
  const [language, setLanguage] = useState('en');
  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {props.children}
    </LanguageContext.Provider>
  );
};
