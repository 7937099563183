import {
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridRowParams,
  GridRowsProp,
  MuiEvent,
} from '@mui/x-data-grid';

export interface DataTableProps {
  readonly tableColumns: GridColDef[];
  readonly dataRows: GridRowsProp;
  readonly tableConfig?: DataTableConfigType;
  readonly onCellClick?: (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) => void;
  readonly onRowClick?: (
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent<HTMLElement, MouseEvent>>
  ) => void;
}

export interface DataTableConfigType {
  readonly id?: string;
  readonly pagination?: {
    readonly rowsPerPageOptions?: number[];
    readonly page?: number;
  };
  readonly search?: boolean;
  readonly gridHeight?: string;
  readonly disableFooter?: boolean;
  readonly addNewRow?: {
    readonly allowAdd: boolean;
    readonly newRowFormat: any;
    readonly onAddNewRow: () => void;
  };
  actions?: {
    readonly showActions: boolean;
    readonly actionButtons: {
      name: actionButtonsEnum;
      position: number;
      onClick: (params: any) => void;
    }[];
    readonly actionColumnHeaderClassName?: string;
    readonly actionColumnCellClassName?: string;
    readonly width?: number;
    readonly minWidth?: number;
    readonly flex?: number;
    readonly disableColumnMenu?: boolean;
  };
}

export interface CustomTableToolbarProps {
  readonly value: string;
  readonly onChange: () => void;
  readonly clearSearch: () => void;
  readonly addNewRow: () => void;
  readonly tableProperties: TableProperties;
}

export interface ActionButtonProps {
  readonly tooltip: string;
  readonly icon: string;
  readonly handleClick: (params: any) => void;
}

export interface TableProperties {
  readonly pagination: {
    rowsPerPageOptions: number[];
  };
  readonly search: boolean;
  readonly gridHeight: string;
  readonly showActions: boolean;
  readonly showToolBar: boolean;
  readonly addNewRow: boolean;
}

export enum dataTableId {
  id = 'dataTableId',
}

export enum actionButtonsEnum {
  add = 'add',
  edit = 'edit',
  delete = 'delete',
  save = 'save',
  download = 'download',
}

export const actionButtonsConst = [
  {
    name: 'add',
    position: 0,
    icon: 'add_row.svg',
    tooltip: 'DATA_TABLE_ACTIONS_ADD',
  },
  {
    name: 'delete',
    position: 0,
    icon: 'delete.svg',
    tooltip: 'DATA_TABLE_ACTIONS_DELETE',
  },
  {
    name: 'edit',
    position: 0,
    icon: 'edit.svg',
    tooltip: 'DATA_TABLE_ACTIONS_EDIT',
  },
  {
    name: 'save',
    position: 0,
    icon: 'save.svg',
    tooltip: 'DATA_TABLE_ACTIONS_SAVE',
  },
  {
    name: 'download',
    position: 0,
    icon: 'download.svg',
    tooltip: 'DATA_TABLE_ACTIONS_DOWNLOAD',
  },
];
