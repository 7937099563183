import { Box, Link, Tooltip, Typography } from '@mui/material';
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../../../../translations/I18nKey';
import { DataTable } from '../../../../../components/DataTable/DataTable';
import {
  actionButtonsEnum,
  DataTableConfigType,
} from '../../../../../components/DataTable/DataTableTypes';
import { Toggle } from '../../../../../components/Toggle/Toggle';
import { DatePicker } from '../../../../../components/DateRangePicker/Calender';
import { GeofenceStyles } from './GeofenceStyles';
import moment from 'moment';

export const GeofenceList: React.FC<any> = ({
  GeofenceListData,
  setGeofenceEditMode,
  deleteGeofence,
  setSelectedGeofenceRow,
}) => {
  const intl = useIntl();
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(GeofenceListData);
  }, [GeofenceListData]);

  const storeSelectedRow = (params: GridCellParams) => {
    setSelectedGeofenceRow(params.row);
    setGeofenceEditMode(true);
  };

  const openGeofenceCRUD = () => {
    setSelectedGeofenceRow(null);
    setGeofenceEditMode(true);
  };

  const geofenceDataTableConfig: DataTableConfigType = {
    gridHeight: '300px',
    disableFooter: true,
    actions: {
      showActions: true,
      width: 90,
      actionButtons: [
        // {
        //   name: actionButtonsEnum.save,
        //   position: 1,
        //   onClick: (params: any) => {
        //     console.log('params', params);
        //   },
        // },
        {
          name: actionButtonsEnum.edit,
          position: 1,
          onClick: (params: GridCellParams) => {
            storeSelectedRow(params);
          },
        },
        {
          name: actionButtonsEnum.delete,
          position: 1,
          onClick: (params: GridCellParams) => {
            deleteGeofence(params.row.id);
          },
        },
      ],
      actionColumnHeaderClassName: 'tableHeaderStyle',
      actionColumnCellClassName: 'tableCellStyle',
    },
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      width: 100,
      headerName: intl.formatMessage({
        id: I18nKey.IUU_SETTINGS_GEOFENCE_NAME,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={String(params.value)}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'startDate',
      width: 130,
      headerName: intl.formatMessage({
        id: I18nKey.IUU_SETTINGS_GEOFENCE_START_DATE,
      }),
      type: 'date',
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return (
          <>{moment(new Date(params.row.startDate)).format('MMM DD, yyy')}</>
          // <DatePicker
          //   defaultSelection={new Date(params.row.startDate)}
          //   onChange={() => {}}
          //   format="MMM dd, yyyy"
          //   disabled={true}
          // />
        );
      },
    },
    {
      field: 'endDate',
      width: 130,
      headerName: intl.formatMessage({
        id: I18nKey.IUU_SETTINGS_GEOFENCE_END_DATE,
      }),
      type: 'date',
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return (
          <>{moment(new Date(params.row.endDate)).format('MMM DD, yyy')}</>
          // <DatePicker
          //   defaultSelection={new Date(params.row.endDate)}
          //   onChange={() => {}}
          //   format="MMM dd, yyyy"
          //   disabled={true}
          // />
        );
      },
    },
    {
      field: 'active',
      width: 75,
      headerName: intl.formatMessage({
        id: I18nKey.IUU_SETTINGS_GEOFENCE_ACTIVE,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return <Toggle checked={params.row.active} disabled={true} />;
      },
    },
  ];

  return (
    <Box sx={{ color: 'white' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 1 }}>
        <Typography>
          {intl.formatMessage({
            id: I18nKey.IUU_SETTINGS_GEOFENCE_LIST,
          })}
        </Typography>
        <Link
          sx={{
            color: '#FFA901',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          underline="none"
          onClick={openGeofenceCRUD}
        >
          {intl.formatMessage({
            id: I18nKey.IUU_SETTINGS_CREATE_GEOFENCE,
          })}
        </Link>
      </Box>
      <Box sx={GeofenceStyles.dataTableStyles}>
        <DataTable
          tableColumns={columns}
          dataRows={data}
          tableConfig={geofenceDataTableConfig}
        />
      </Box>
    </Box>
  );
};
