import React from 'react';
import { Box } from '@mui/material';
import { useFishCatchDashboardStyles } from './FishCatchDashboardStyles';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../translations/I18nKey';
import { CATCH_CONDITION_ICON_LIST } from './DashboardConfig';
import './FishCatchDashboard.scss';

export interface SummaryDetailCardProps {
  readonly icon?: JSX.Element;
  readonly name: string;
  readonly max: number;
  readonly min: number;
  readonly average: number;
  readonly weightedAverage?: number;
}

interface SummaryDetailsProps {
  readonly items: SummaryDetailCardProps[];
}

const isEven = (num: number) => num === 0 || !!(num && !(num % 2));

const SummaryDetailCard: React.FC<SummaryDetailCardProps> = ({
  icon,
  name,
  max,
  min,
  average,
  weightedAverage,
}) => {
  const intl = useIntl();
  const FishCatchDashboardStyles: any = useFishCatchDashboardStyles();

  return (
    <Box className={FishCatchDashboardStyles.summaryDetailsCardContainer}>
      <Box
        className={FishCatchDashboardStyles.summaryDetailsCardHeader}
        alignItems={'center'}
      >
        <div className={FishCatchDashboardStyles['summary-details--svg']}>
          {icon}
        </div>
        <Box className={FishCatchDashboardStyles.summaryDetailsCardName}>
          {intl.formatMessage({
            id: I18nKey[`PRODUCTS_${name}`],
          })}
        </Box>
      </Box>
      <Box className={FishCatchDashboardStyles.summaryDetailsCardDetails}>
        <Box>
          {intl.formatMessage({
            id: I18nKey.FISH_CATCH_DASHBOARD_SUMMARY_MIN,
          })}
          <p className={FishCatchDashboardStyles['summary-details--metrics']}>
            {min !== null ? Number(min).toFixed(2) : '-'}
          </p>
        </Box>
        <Box>
          {intl.formatMessage({
            id: I18nKey.FISH_CATCH_DASHBOARD_SUMMARY_MAX,
          })}
          <p className={FishCatchDashboardStyles['summary-details--metrics']}>
            {max !== null ? Number(max).toFixed(2) : '-'}
          </p>
        </Box>
        <Box>
          {intl.formatMessage({
            id: I18nKey.FISH_CATCH_DASHBOARD_SUMMARY_AVG,
          })}
          <p className={FishCatchDashboardStyles['summary-details--metrics']}>
            {average !== null ? average.toFixed(2) : '-'}
          </p>
        </Box>
        <Box>
          {intl.formatMessage({
            id: I18nKey.FISH_CATCH_DASHBOARD_SUMMARY_W_AVG,
          })}
          <p className={FishCatchDashboardStyles['summary-details--metrics']}>
            {weightedAverage !== null ? weightedAverage?.toFixed(2) : '-'}
          </p>
        </Box>
      </Box>
    </Box>
  );
};

export const FishCatchSummaryDetails: React.FC<SummaryDetailsProps> = ({
  items,
}) => {
  const isFirst = (index: number) => index === 0;
  const isLast = (index: number) => index === items.length - 1;

  const FishCatchDashboardStyles: any = useFishCatchDashboardStyles();

  return (
    <Box className={FishCatchDashboardStyles.summaryDetailsContainer}>
      {items.map((item, index) => (
        <Box
          key={index}
          sx={{
            backgroundColor: isEven(index) ? '#FFFFFF' : '#EAE7E7',
            borderTopLeftRadius: isFirst(index) ? '9px' : '0px',
            borderBottomLeftRadius: isFirst(index) ? '9px' : '0px',
            borderTopRightRadius: isLast(index) ? '9px' : '0px',
            borderBottomRightRadius: isLast(index) ? '9px' : '0px',
            width: 'calc(100%/7)',
          }}
        >
          <SummaryDetailCard
            {...item}
            icon={CATCH_CONDITION_ICON_LIST[index]}
          />
        </Box>
      ))}
    </Box>
  );
};
