import {
  Fab,
  Typography,
  Drawer,
  Box,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Divider,
  Toolbar,
} from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import FilterOpen from '../../assets/icons/filter_open.svg';
import FilterClose from '../../assets/icons/filter_closed.svg';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import RadioButton from '../../components/RadioButton/RadioButton';
import reset from '../../assets/icons/reset.svg';
import { I18nKey } from '../../translations/I18nKey';
import {
  geofencesColumnConfig,
  countryColumnConfig,
  vesselTypesColumnConfig,
} from './DashboardConfig';
import {
  useIUUDashboardStyles,
  IUUDashboardStyles,
} from './IUUDashboardStyles';

const Filters: React.FC<any> = ({
  onCountryChange,
  onVesselTypeChange,
  onFenceChange,
  selectedVesselType,
  selectedCountry,
  selectedFenceName,
  countryList = [],
  vesselTypeList = [],
  fenceNameList = [],
  onApply,
  onFilterReset,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const intl = useIntl();
  let country = countryList.map((d: any) => {
    return {
      country: d.country,
    };
  });
  let vesselTypes = vesselTypeList.map((d: any) => {
    return {
      type: d.type,
    };
  });
  let fenceNames = _.cloneDeep(fenceNameList);
  const IUUDashboardClasses = useIUUDashboardStyles();

  return (
    <>
      <Fab
        color="primary"
        variant="extended"
        className={IUUDashboardClasses.filterContainerStyle}
        onClick={() => {
          setShowFilters(true);
        }}
      >
        <Typography className={IUUDashboardClasses.filterCloseText}>
          {intl.formatMessage({
            id: I18nKey.IUUDASHBOARD_FILTER,
          })}
        </Typography>

        <img height="30px" width="30px" src={FilterClose} alt=""></img>
      </Fab>
      <Drawer
        anchor="right"
        open={showFilters}
        variant="persistent"
        onClose={() => {}}
      >
        <div style={{marginTop: "1em"}} />
        <Toolbar />
        <Box className={IUUDashboardClasses.filterBoxStyle}>
          <Box className={IUUDashboardClasses.filterDrawerStyle}>
            <Button
              onClick={() => {
                setShowFilters(false);
              }}
            >
              <Typography className={IUUDashboardClasses.filterHeadingStyle}>
                {intl.formatMessage({
                  id: I18nKey.IUUDASHBOARD_FILTER,
                })}
              </Typography>
              <img height="30px" width="30px" src={FilterOpen} alt=""></img>
            </Button>
          </Box>
          <Box>
            <Box>
              <AutoComplete
                key={'fences'}
                data={fenceNames}
                style={IUUDashboardStyles.autocompleteStyle}
                placeholder={intl.formatMessage({
                  id: I18nKey.IUUDASHBOARD_FILTERGEOFENCES,
                })}
                columnConfig={geofencesColumnConfig}
                onSelectionChange={onFenceChange}
                showSelectAllToggle={true}
                multiValueSeparator="-"
                groupBy={'type'}
                showClearAll={false}
                selectionData={selectedFenceName}
              />
            </Box>
            <Divider flexItem></Divider>
            <Box>
              <AutoComplete
                key={'country'}
                data={country}
                style={IUUDashboardStyles.autocompleteStyle}
                placeholder={intl.formatMessage({
                  id: I18nKey.IUUDASHBOARD_FILTERCOUNTRY,
                })}
                columnConfig={countryColumnConfig}
                onSelectionChange={onCountryChange}
                showSelectAllToggle={true}
                multiValueSeparator="-"
                showClearAll={false}
                selectionData={selectedCountry}
              />
            </Box>
            <Divider flexItem></Divider>
            <AutoComplete
              key={'vessel'}
              data={vesselTypes}
              style={IUUDashboardStyles.autocompleteStyle}
              placeholder={intl.formatMessage({
                id: I18nKey.IUUDASHBOARD_FILTERTYPES,
              })}
              columnConfig={vesselTypesColumnConfig}
              onSelectionChange={onVesselTypeChange}
              showSelectAllToggle={true}
              multiValueSeparator="-"
              showClearAll={false}
              selectionData={selectedVesselType}
            />
          </Box>

          <Divider flexItem></Divider>
          <Box
            className={`${IUUDashboardClasses['apply-btn-wrapper']} ${IUUDashboardClasses.bottomBoxStyle}`}
          >
            <Button
              className={IUUDashboardClasses.applyButtonStyle}
              onClick={onApply}
              disabled={
                selectedVesselType.length === 0 || selectedCountry.length === 0
              }
            >
              {intl.formatMessage({
                id: I18nKey.IUUDASHBOARD_APPLY,
              })}
            </Button>
            <Button
              className={IUUDashboardClasses.resetBoxStyle}
              onClick={onFilterReset}
              children={
                <>
                  <img height="17px" width="17px" src={reset} alt=""></img>
                  &nbsp;
                  {intl.formatMessage({
                    id: I18nKey.IUUDASHBOARD_RESET_TO_DEFAULT,
                  })}
                </>
              }
            />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
export default Filters;
