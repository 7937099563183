import { UserSettings } from '../../../types';
import { toAntilog, toLog } from '../../HelperService';

const scalePartsConfig: { [key: number]: number } = { 3: 8, 4: 7, 5: 6 };
export class RasterService {
  public static diff = 0;
  public static minMaxBoundary = 0;
  public static min = 0;
  public static max = 0;
  public static scaleLength = 20;

  public static addRasterLayer = (
    map: mapboxgl.Map,
    code: string,
    tileData: any,
    settings: UserSettings,
    addMapLayerId: any,
    layerOrder: any,
    setLoading: any
  ) => {
    const layerKey = code + 'raster';
    addMapLayerId('raster', layerKey);
    if (!map?.getSource(layerKey)) {
      map?.addSource(layerKey, {
        type: 'image',
        coordinates: tileData.data?.coordinates?.map((p: any) => {
          return p.map((i: any) => {
            return Number(i);
          });
        }),
        url: tileData.tile?.url[0],
      });
      map?.addLayer(
        {
          id: layerKey,
          type: 'raster',
          source: layerKey,
        },
        layerOrder('raster')
      );
    }
    setLoading(false);
  };

  public static getSliderBackground = (
    colorscale: string,
    style: string,
    direction: any
  ) => {
    let backgroundImage = '';
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const colorScaleObject = localStorage.getItem(colorscale) || '';
    if (colorScaleObject) {
      const colorObject = JSON.parse(colorScaleObject);
      const continuous = colorObject.continuous;
      const discrete = this.getDiscreteString(colorObject.discrete);
      backgroundImage = `linear-gradient(to ${direction},${
        style === 'continuous' ? continuous : discrete
      })`;
    } else {
      return '';
    }
    return backgroundImage;
  };

  public static getDiscreteString(discreteString: string): string {
    const discrete = discreteString.split(',');
    const size = 100 / (discrete.length + 2);
    const values = discrete.length - 1;
    let initial = size * 2;
    discrete[0] = discrete[0].concat(` ${initial}%`);
    for (let i = 1; i < discrete.length - 1; i++) {
      const next = initial + size;
      discrete[i] = discrete[i].concat(` ${initial}% ${next}%`);
      initial = next;
    }
    discrete[values] = discrete[values].concat(` ${initial}%`);
    return discrete.join(',');
  }

  public static setRasterColorLegend(
    scaleType: string,
    min: any,
    max: any,
    precision: number | undefined
  ): any {
    let scale = [];
    // Logic here is inspired from https://numpy.org/doc/stable/reference/generated/numpy.logspace.html
    this.min = this.getValue(scaleType || '', min || 0);
    this.max = this.getValue(scaleType || '', max || 0);
    this.diff = this.max - this.min;
    let parts = this.getParts();
    for (let i = 0; i < parts; i++) {
      const value = this.max - (i * this.diff) / (parts - 1);
      scale.push(
        scaleType === 'log10'
          ? +toAntilog(value).toFixed(precision)
          : +value.toFixed(precision)
      );
    }
    return min !== max ? scale.reverse() : [min];
  }

  public static getParts() {
    let parts = scalePartsConfig[Math.trunc(this.max).toString().length]
      ? scalePartsConfig[Math.trunc(this.max).toString().length]
      : 10;
    return parts;
  }

  public static getValue(scale: string, value: number) {
    if (scale === 'log10') return toLog(value);
    else return value;
  }

  public static updatePointerPosition(
    scaleType: string,
    cursorData: number
  ): number {
    this.minMaxBoundary = this.getValue(scaleType || '', cursorData);
    this.minMaxBoundary =
      this.minMaxBoundary < this.min
        ? this.min
        : this.minMaxBoundary > this.max
        ? this.max
        : this.minMaxBoundary;

    let value =
      this.scaleLength +
      (this.scaleLength / this.diff) *
        ((this.minMaxBoundary - this.min) * 18.5);
    return value ? (value > 379 ? 379 : value) : 20;
  }
}
