import { useState, forwardRef, useRef, useEffect, useContext } from 'react';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { useIntl } from 'react-intl';
import * as _ from 'lodash';
import { useTheme, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import Autocomplete, {
  autocompleteClasses,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import clearAllIcon from './../../assets/icons/clear_all_grey.svg';
import SquareIcon from '@mui/icons-material/Square';
import { useStyles } from './../../../src/utils/util';
import { Toggle } from '../Toggle/Toggle';
import { I18nKey } from '../../translations/I18nKey';
import AutoCompleteOptionContext from './AutoCompleteContext';
import { AutoCompleteProps } from './AutoCompleteTypes';
import { AutocompleteStyles } from './AutocompleteStyles';
import './AutoComplete.scss';

const AutoComplete: React.FC<AutoCompleteProps> = ({
  data,
  style,
  columnConfig,
  groupBy = undefined,
  placeholder,
  showSelectAllToggle = true,
  multiValueSeparator = '',
  onSelectionChange,
  selectionData,
  enableSingleSelection = false,
  showClearAll = true,
  showChips = true,
}) => {
  const classes = useStyles(AutocompleteStyles, [])();
  const intl = useIntl();
  const [selectedOptions, setSelectedOptions] = useState(selectionData);
  const [selectedData, setSelectedData] = useState(selectionData);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const dataKey: string = _.filter(columnConfig, { displayInChip: true })[0]
    .name;
  const showSelectAllOption: boolean = showSelectAllToggle;
  const checkBoxIcon: JSX.Element = (
    <CheckBoxOutlineBlankIcon className="checkboxIcon" fontSize="small" />
  );
  const checkedIcon: JSX.Element = <SquareIcon fontSize="small" />;
  const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = useContext(AutoCompleteOptionContext);
    return <div ref={ref} {...props} {...outerProps} />;
  });

  const LISTBOX_PADDING = showSelectAllToggle ? 45 : 5;

  useEffect(() => {
    if (!_.isEqual(selectedOptions, selectionData)) {
      onChangeCheckbox(selectionData);
      setSelectedOptions(selectionData);
    }
  }, [selectionData]);

  useEffect(() => {
    onSelectionChange(selectedData);
  }, [selectedData]);

  const onDelete = (name: any) => () => {
    setSelectedData((selectedData: any) =>
      selectedData.filter((v: any) => v[dataKey] !== name)
    );
  };

  const onChangeSelectAll = (e: any, checked: any) => {
    setIsSelectAllChecked(checked);
    setSelectedData(checked ? data : []);
  };

  const onChangeCheckbox = (newValue: any) => {
    newValue.length === data.length
      ? setIsSelectAllChecked(true)
      : setIsSelectAllChecked(false);
    setSelectedData(newValue);
  };

  const onAutoCompleteChange = (e: any, newValue: any) => {
    onChangeCheckbox(newValue);
  };

  const getToggleSwitch = () => {
    return (
      <Box className="switchWrapper">
        <Toggle
          className="switch"
          checked={isSelectAllChecked}
          onChange={onChangeSelectAll}
        />
        <span>
          {intl.formatMessage({
            id: I18nKey.SELECT_ALL,
          })}
        </span>
      </Box>
    );
  };

  const getCheckBox = (dataSet: any, data: any[]) => {
    return (
      <>
        <Checkbox
          icon={checkBoxIcon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={
            selectedData.length === data.length
              ? true
              : dataSet['aria-selected']
          }
        />
      </>
    );
  };

  const getOptionRow = (props: ListChildComponentProps) => {
    const { data, index, style } = props;
    const dataSet = data[index];
    const rowData = dataSet[1];
    const inlineStyle = {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
      backgroundColor: '#242424',
      color: '#fff',
    };

    if (dataSet.hasOwnProperty('group')) {
      return (
        <>
          {index === 0 && showSelectAllOption && getToggleSwitch()}
          <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
            {dataSet.group}
          </ListSubheader>
        </>
      );
    }

    const getFilteredColumns = (filter: Record<string, boolean>) => {
      return _.filter(columnConfig, filter);
    };

    const getIconProperties = (prop: string) => {
      // only one column with icons is supported for now
      return rowData[_.filter(columnConfig, { icon: true })[0].name][prop];
    };

    return (
      <>
        {index === 0 && showSelectAllOption && getToggleSwitch()}
        <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
          {getCheckBox(dataSet[0], data)}
          {getFilteredColumns({ icon: false, displayInOption: true })
            .map((c: any) => rowData[c.name])
            .join(multiValueSeparator)}
          {getFilteredColumns({ icon: true, displayInOption: true }).length >
            0 && (
            <img
              style={getIconProperties('style')}
              src={require(`../../assets/icons/${getIconProperties('name')}`)}
              alt={getIconProperties('name')}
              className="listIcon"
            />
          )}
        </Typography>
      </>
    );
  };

  const getChips = (value: Record<string, any>) => {
    const getLabel = () => {
      // for now we are returning only the first record where displayInChip is true
      return value[_.filter(columnConfig, { displayInChip: true })[0].name];
    };

    return (
      <Chip
        className={'chip'}
        sx={{
          '& .MuiChip-deleteIcon': {
            display: 'none',
          },
          '&:hover': {
            '& .MuiChip-deleteIcon': {
              display: 'block',
            },
          },
        }}
        key={value[dataKey]}
        label={getLabel()}
        onDelete={onDelete(value[dataKey])}
      />
    );
  };

  const useResetCache = (data: any) => {
    const ref = useRef<VariableSizeList>(null);
    useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
  };

  // Adapter for react-window:  code here is referenced from https://mui.com/components/autocomplete/#virtualization
  const ListboxComponent = forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement>
  >(function ListboxComponent(props, ref) {
    const { children, ...otherProps } = props;
    const itemData: React.ReactChild[] = [];
    (children as React.ReactChild[]).forEach(
      (item: React.ReactChild & { children?: React.ReactChild[] }) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
      }
    );
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
      noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;
    const gridRef = useResetCache(itemCount);

    const getChildSize = (child: React.ReactChild) => {
      if (child.hasOwnProperty('group')) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    return (
      <div ref={ref}>
        <AutoCompleteOptionContext.Provider value={otherProps}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {getOptionRow}
          </VariableSizeList>
        </AutoCompleteOptionContext.Provider>
      </div>
    );
  });

  const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.paper}`]: {
      backgroundColor: '#242424',
      width: 501,
      borderRadius: 9,
    },
    [`& .${autocompleteClasses.listbox}`]: {
      boxSizing: 'border-box',
      width: 801,
      '& ul': {
        padding: 0,
        margin: 0,
      },
    },
  });

  function isOptionEqualToValue(option: any, value: any) {
    return option[dataKey] === value[dataKey];
  }

  function getOptionLabel(option: any) {
    return option[dataKey] || '';
  }

  function renderTags() {
    return null;
  }

  function renderInput(params: any) {
    return <TextField {...params} label={placeholder} />;
  }

  function renderOption(props: any, option: any) {
    return [props, option];
  }

  function renderGroup(params: any) {
    return params;
  }

  const filter = (option: any) => {
    const filterOptions = createFilterOptions({
      stringify: (option: any) => {
        return columnConfig
          .map((c) => (c.includeInSearch ? option[c.name] : ''))
          .join();
      },
    });
    return filterOptions;
  };

  const extraProps = () => {
    let optionalProps: any = {};
    if (groupBy) optionalProps['groupBy'] = (option: any) => option[groupBy];
    if (!enableSingleSelection) optionalProps['renderTags'] = renderTags;
    return optionalProps;
  };

  const onClearAll = () => {
    setSelectedData([]);
    setIsSelectAllChecked(false);
  };

  return (
    <>
      <Box>
        <Autocomplete
          multiple={!enableSingleSelection}
          className="autoComplete"
          style={style}
          getOptionLabel={getOptionLabel}
          value={selectedData}
          isOptionEqualToValue={isOptionEqualToValue}
          onChange={onAutoCompleteChange}
          filterOptions={filter(Option)}
          disableListWrap
          disableCloseOnSelect
          PopperComponent={StyledPopper}
          ListboxComponent={ListboxComponent}
          options={data}
          renderInput={renderInput}
          renderOption={renderOption}
          renderGroup={renderGroup}
          {...extraProps()}
        />
        {showClearAll && (
          <Box className={classes.clearAllBox}>
            <Typography onClick={onClearAll} className={classes.clearText}>
              {intl.formatMessage({
                id: I18nKey.CLEAR_ALL,
              })}
              <img
                src={clearAllIcon}
                alt="clear"
                className={classes.clearAllIcon}
              />
            </Typography>
          </Box>
        )}
      </Box>
      {showChips && (
        <>
          <br></br>
          <div className="chipWrapper">
            {selectedData.map((v: any) => getChips(v))}
          </div>
        </>
      )}
    </>
  );
};

export default AutoComplete;
