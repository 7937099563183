import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  FormControlLabel,
  Grid,
  RadioGroup,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../../translations/I18nKey';
import RadioButton from '../../../components/RadioButton/RadioButton';
import {
  ContentTextTypography,
  LabelTextTypography,
} from '../../../components/TextFormat/TextFormat';
import { Toggle } from '../../../components/Toggle/Toggle';
import { NoDisplayInSettingsProducts, SettingsDefault } from './config';
import { useStyles } from '../../../utils/util';
import { productSettingsStyles } from './ProductSettingsStyles';

export const ProductSettings: React.FC<any> = ({ settings }) => {
  //TODO:revisit for ordering of methods and states
  const intl = useIntl();
  const classes = useStyles(productSettingsStyles, [])();
  const userSettings = settings.settings;
  const setUserSettings = settings.setSettings;
  const settingsDefault = SettingsDefault;
  //TODO: use the depth list to display the number of depths in the slider bar
  const [userProductSettings, setUserProductSettings] = useState({
    depthList: settingsDefault.depths,
  });

  const productsList = _.map(userSettings['products'], (category) => {
    category['select'] = false;
    category.products = _.sortBy(category.products, ['productDisplayOrder']);
    return category;
  });
  const [products, setProducts] = useState(
    _.sortBy(productsList, ['categoryDisplayOrder'])
  );

  useEffect(() => {
    let weatherProducts =
      products[_.findIndex(products, { category: 'weather' })];
    if (weatherProducts?.products) {
      let windRasterIndex = _.findIndex(weatherProducts.products, {
        code: 'WIND_RASTER',
      });
      if (windRasterIndex !== -1) {
        userSettings.map.showWindRaster =
          weatherProducts.products[windRasterIndex].showProduct;
      }
      let weatherProductIndex = _.findIndex(weatherProducts.products, {
        code: 'WIND',
      });
      NoDisplayInSettingsProducts.forEach((p) => {
        let weatherProductHiddenIndex = _.findIndex(weatherProducts.products, {
          code: p,
        });
        if (weatherProductIndex !== -1 && weatherProductHiddenIndex !== -1) {
          weatherProducts.products[weatherProductHiddenIndex].showProduct =
            weatherProducts?.products[weatherProductIndex]?.showProduct;
        }
      });
      const updateSettings = {
        ...userSettings,
      };
      setUserSettings(updateSettings);
    }
  }, [products]);

  const addEntryForWindRaster = () => {
    let weatherProducts =
      products[_.findIndex(products, { category: 'weather' })];
    if (
      weatherProducts &&
      _.findIndex(weatherProducts.products, { code: 'WIND_RASTER' }) === -1
    ) {
      let windRaster = {
        depths: ['-1'],
        productDisplayOrder: 5,
        cursorData: false,
        showProduct: userSettings.map?.showWindRaster,
        code: 'WIND_RASTER',
      };
      weatherProducts.products.push(windRaster);
    }
  };

  const onProductInit = () => {
    addEntryForWindRaster();
    products.forEach((p: any) => {
      if (p && p.category) {
        updateProducts(p.category);
      }
    });
  };

  const updateProducts = (category: any) => {
    let categoryProduct =
      products[_.findIndex(products, { category: category })];
    categoryProduct.select = false;
    categoryProduct.select = categoryProduct.products.every(
      (p: any) => p.showProduct === true
    );
  };

  onProductInit();

  const setDepthValues = (selectedDepths: string | string[] | undefined) => {
    return settingsDefault.depths.map((d: string) => {
      return { value: d, select: true };
    });
  };

  const [depthList, setDepthList] = useState<any>(
    setDepthValues(userProductSettings.depthList)
  );

  const onToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    const updateSettingss = {
      ...userSettings,
    };
    _.set(updateSettingss, name, checked);
    setUserSettings(updateSettingss);
  };

  const onValueChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;

    const updateSettingss = {
      ...userSettings,
    };
    _.set(updateSettingss, name, value);
    setUserSettings(updateSettingss);
  };

  const onCategoryToggle =
    (category: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      products[_.findIndex(products, { category: category })].select =
        event.target.checked;
      products[_.findIndex(products, { category: category })].products.forEach(
        (p: any) => {
          p.showProduct = event.target.checked;
        }
      );
      setProducts([...products]);
    };

  const onProductToggle =
    (prod: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      let category = _.filter(products, { products: [{ code: prod }] })[0];
      let product = _.filter(category.products, {
        code: prod,
      })[0];
      product.showProduct = event.target.checked;
      updateProducts(category.category);
      setProducts([...products]);
    };

  const onDepthSelectionChange =
    (depth: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      let dList = setDepthValues(userProductSettings.depthList);
      setDepthList([...dList]);
      userSettings.products.map((category: any) => {
        category.products.map((p: any) => {
          if (p.depths.toString() !== '-1') {
            p.depths = _.filter(dList, { select: true }).map((d) => d.value);
          }
        });
      });
    };

  return (
    <>
      <Box className={classes.settings}>
        <LabelTextTypography>
          {intl.formatMessage({
            id: I18nKey.USER_SETTINGS_CONTOUR,
          })}
        </LabelTextTypography>
        <Stack
          direction="column"
          justifyContent="space-evenly"
          alignItems="flex-start"
          spacing={0}
        >
          <FormControlLabel
            value="end"
            control={
              <Toggle
                checked={userSettings.map?.showProductContourLabel || false}
                name="map.showProductContourLabel"
                onChange={onToggleChange}
                value="contour"
              />
            }
            label={intl.formatMessage({
              id: I18nKey.USER_SETTINGS_CONTOUR_LABEL,
            })}
            labelPlacement="end"
            className={classes.toggleFormat}
          />
        </Stack>
      </Box>
      <hr className={classes.lineStyle} />
      <Box className={classes.settings}>
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <LabelTextTypography>
              {intl.formatMessage({
                id: I18nKey.USER_SETTINGS_OVERLAYS,
              })}
            </LabelTextTypography>
          </Grid>
          <Grid item xs={4}>
            <LabelTextTypography>
              {intl.formatMessage({
                id: I18nKey.USER_SETTINGS_DISPLAY,
              })}
            </LabelTextTypography>
          </Grid>
        </Grid>

        {products.map((types: any) => {
          return (
            <Box key={`${types.category}header-div`}>
              <DisplaySection
                key={`${types.category}section`}
                code={types.category}
                label={I18nKey[`CATEGORY_${types.category.toUpperCase()}`]}
                labelStyle={classes.categoryHeading}
                toggleValue={types.select}
                toggleStyle={classes.productToggle}
                onToggle={onCategoryToggle}
                showToggle={types.category !== 'weather' ? true : false}
              />
              {types.products.map((p: any) => {
                if (!NoDisplayInSettingsProducts.includes(p.code)) {
                  return (
                    <DisplaySection
                      key={`${p.code}category`}
                      code={p.code}
                      label={
                        p.code === 'WIND'
                          ? I18nKey[`PRODUCTS_WEATHER_SETTINGS`]
                          : I18nKey[`PRODUCTS_${p.code.toUpperCase()}`]
                      }
                      toggleValue={p.showProduct}
                      toggleSyle={classes.productToggle}
                      onToggle={onProductToggle}
                      showToggle={true}
                    />
                  );
                }
              })}
              <br />
            </Box>
          );
        })}
      </Box>
      <hr className={classes.lineStyle} />
      <Box className={classes.settings}>
        <LabelTextTypography>
          {intl.formatMessage({
            id: I18nKey.USER_SETTINGS_UNITS,
          })}
        </LabelTextTypography>

        <Grid container>
          <Grid item xs={4}>
            <LabelTextTypography>
              {intl.formatMessage({
                id: I18nKey.USER_SETTINGS_DEPTH,
              })}
            </LabelTextTypography>
          </Grid>
          <Grid item xs={8}>
            <RadioGroup
              row
              aria-labelledby="depth-radio-buttons-group"
              name="general.unitsDepth"
              value={userSettings.general?.unitsDepth || ''}
              onChange={onValueChange}
            >
              {settingsDefault.depth.map((k: any) => {
                return (
                  <FormControlLabel
                    className={classes.units}
                    key={k}
                    value={k}
                    control={<RadioButton />}
                    label={intl.formatMessage({
                      id: I18nKey[`UNITS_${k.toUpperCase()}`],
                    })}
                  />
                );
              })}
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <LabelTextTypography>
              {intl.formatMessage({
                id: I18nKey.USER_SETTINGS_HEIGHT,
              })}
            </LabelTextTypography>
          </Grid>
          <Grid item xs={8}>
            <RadioGroup
              row
              aria-labelledby="height-radio-buttons-group"
              name="general.unitsHeight"
              value={userSettings.general?.unitsHeight || ''}
              onChange={onValueChange}
            >
              {settingsDefault.height.map((k: any) => {
                return (
                  <FormControlLabel
                    className={classes.units}
                    key={k}
                    value={k}
                    control={<RadioButton />}
                    label={intl.formatMessage({
                      id: I18nKey[`UNITS_${k.toUpperCase()}`],
                    })}
                  />
                );
              })}
            </RadioGroup>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={4}>
            <LabelTextTypography>
              {intl.formatMessage({
                id: I18nKey.USER_SETTINGS_TEMPERATURE,
              })}
            </LabelTextTypography>
          </Grid>
          <Grid item xs={8}>
            <RadioGroup
              row
              aria-labelledby="temperature-radio-buttons-group"
              name="general.unitsTemperature"
              value={userSettings.general?.unitsTemperature || ''}
              onChange={onValueChange}
            >
              {Object.keys(settingsDefault.temperature).map((k: any) => {
                return (
                  <FormControlLabel
                    className={classes.units}
                    key={k}
                    value={k}
                    control={<RadioButton />}
                    label={intl.formatMessage({
                      id: I18nKey[`UNITS_${k.toUpperCase()}`],
                    })}
                  />
                );
              })}
            </RadioGroup>
          </Grid>
        </Grid>
      </Box>
      <hr className={classes.lineStyle} />
      <Box className={classes.settings}>
        <LabelTextTypography>
          {`${intl.formatMessage({
            id: I18nKey.USER_SETTINGS_DEPTH,
          })} (m)`}
          <Toggle key={'depth'} checked={true} value={true} disabled={true} />
        </LabelTextTypography>
        <Grid container spacing={2}>
          {depthList.map((d: any) => {
            return (
              <Grid item xs={3} key={d.value}>
                <FormControlLabel
                  value="end"
                  sx={{
                    '& .MuiFormControlLabel-label.Mui-disabled': {
                      color: '#6F6F6F',
                    },
                  }}
                  control={
                    <Toggle
                      key={d.value}
                      checked={d.select}
                      onChange={onDepthSelectionChange(d.value)}
                      value={d.value}
                      disabled={true}
                    />
                  }
                  label={d.value}
                  labelPlacement="end"
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <hr className={classes.lineStyle} />
    </>
  );
};

const DisplaySection: React.FC<any> = ({
  code,
  label,
  labelStyle,
  toggleValue,
  toggleStyle,
  onToggle,
  showToggle = true,
}) => {
  const intl = useIntl();
  return (
    <Grid container spacing={4} key={`${code}header-container`}>
      <Grid item xs={8} key={`${code}header-item`}>
        <ContentTextTypography
          key={`${label}header-label`}
          textStyle={labelStyle}
        >
          {intl.formatMessage({ id: label })}
        </ContentTextTypography>
        {/* TODO:use I18NKeys */}
      </Grid>
      <Grid item xs={4} key={`${code}header-button`}>
        {showToggle && (
          <Toggle
            key={code}
            checked={toggleValue}
            onChange={onToggle(code)}
            value={label}
            className={toggleStyle}
          />
        )}
      </Grid>
    </Grid>
  );
};
