import { Box, Link, Tooltip, Typography } from '@mui/material';
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import React from 'react';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../../../translations/I18nKey';
import { dateToString } from '../../../../utils/util';
import { CustomLinearProgress } from '../../../../components/CustomLinearProgress/CustomLinearProgress';
import { DataTable } from '../../../../components/DataTable/DataTable';
import {
  actionButtonsEnum,
  DataTableConfigType,
} from '../../../../components/DataTable/DataTableTypes';
import { TripsStyles } from '../AnalysisStyles';

export const TripsList: React.FC<any> = ({
  setTripsEditMode,
  tripsListDataLoading,
  tripsListData,
  exportTripsData,
  deleteTripsData,
}) => {
  const intl = useIntl();

  const columns: GridColDef[] = [
    {
      field: 'tripId',
      width: 140,
      headerName: intl.formatMessage({
        id: I18nKey.ANALYSIS_NAME,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={String(params.value)}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'created_at',
      width: 120,
      headerName: intl.formatMessage({
        id: I18nKey.ANALYSIS_CREATED,
      }),
      type: 'date',
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return dateToString(params.row.created_at, 'MMM DD, yyyy');
      },
    },
    {
      field: 'waypoints',
      width: 90,
      headerName: intl.formatMessage({
        id: I18nKey.ANALYSIS_WAYPOINTS,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
    },
    {
      field: 'distance',
      width: 120,
      headerName: intl.formatMessage({
        id: I18nKey.ANALYSIS_DISTANCE,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        return params.value.toFixed(2);
      },
    },
  ];

  const tripsListDataTableConfig: DataTableConfigType = {
    gridHeight: '384px',
    disableFooter: true,
    id: 'tripId',
    actions: {
      showActions: true,
      width: 70,
      minWidth: 70,
      flex: 1,
      actionButtons: [
        {
          name: actionButtonsEnum.download,
          position: 1,
          onClick: exportTripsData,
        },
        {
          name: actionButtonsEnum.delete,
          position: 1,
          onClick: deleteTripsData,
        },
      ],
      actionColumnHeaderClassName: 'tableHeaderStyle',
      actionColumnCellClassName: 'tableCellStyle',
    },
  };

  const openListCRUD = () => {
    setTripsEditMode(true);
  };

  return (
    <Box sx={{ color: 'white' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          m: 2,
          mx: 4,
        }}
      >
        <Typography>
          {intl.formatMessage({
            id: I18nKey.ANALYSIS_TRIPS_LIST,
          })}
        </Typography>
        <Link
          sx={{
            color: '#FFA901',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          underline="none"
          onClick={openListCRUD}
        >
          {intl.formatMessage({
            id: I18nKey.ANALYSIS_CREATE_TRIP,
          })}
        </Link>
      </Box>
      {tripsListDataLoading ? (
        <CustomLinearProgress />
      ) : (
        <Box sx={TripsStyles.dataTableStyles}>
          <DataTable
            tableColumns={columns}
            dataRows={tripsListData}
            tableConfig={tripsListDataTableConfig}
          />
        </Box>
      )}
    </Box>
  );
};
