import { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { MinMaxSlider } from '../../../../components/Slider/Slider';
import { Wrapper } from '../../../../components/Wrapper/Wrapper';
import { I18nKey } from '../../../../translations/I18nKey';
import { Box } from '@mui/material';

const MastercastRangeRules: React.FC<any> = ({
  selectedValue,
  disableConfig,
  markValues,
  setSelectedValue,
  setDisableConfig,
  mastercastSettingData,
}) => {
  const intl = useIntl();
  const onToggle = (e: any, product: any) => {
    const { checked } = e.target;
    setDisableConfig((prevState: any) => ({
      ...prevState,
      [product]: checked,
    }));
  };

  const onChangeHandler = (e: any, newValue: any[], product: any) => {
    setSelectedValue((prevState: any) => ({
      ...prevState,
      [product]: newValue,
    }));
  };

  return (
    <Box
      sx={{
        maxHeight: '412px',
        overflowY: 'scroll',
        width: 'fit-content',
      }}
    >
      <br />
      {Object.keys(disableConfig).map((product: any) => (
        <Fragment key={product}>
          <Wrapper
            title={intl.formatMessage({
              id: I18nKey[`PRODUCTS_${product}`],
            })}
            wrapperStyle={{
              width: '536px',
              height: '110px',
              backgroundColor: '#242424',
            }}
            showToggle
            onToggle={function (e: any) {
              onToggle(e, product);
            }}
            checked={disableConfig[product]}
          >
            <MinMaxSlider
              marks={markValues[product]}
              selectedValue={selectedValue[product]}
              disabled={!disableConfig[product]}
              onChangeHandler={function (e: any, newValue: any[]) {
                onChangeHandler(e, newValue, product);
              }}
              setSelectedValue={function (newValue: any) {
                setSelectedValue((prevState: any) => ({
                  ...prevState,
                  [product]: newValue,
                }));
              }}
              showLogScale={product === 'PLK'}
              precision={
                mastercastSettingData.rangeRules.find(
                  (el: any) => el.id === product
                ).options.precision
              }
              unit={intl.formatMessage({
                id: I18nKey[
                  `UNITS_${mastercastSettingData.rangeRules
                    .find((el: any) => el.id === product)
                    .units.toUpperCase()}`
                ],
              })}
              step={
                mastercastSettingData.rangeRules.find(
                  (el: any) => el.id === product
                ).options.step
              }
            />
          </Wrapper>
          <br />
        </Fragment>
      ))}
    </Box>
  );
};

export default MastercastRangeRules;
