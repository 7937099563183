import { I18nKey } from '../../../../translations/I18nKey';

export const VesselColumnMapping = {
  date: null,
  latitude: null,
  longitude: null,
  velocity: null,
  course: null,
};

export const BuoysColumnMapping = {
  date: null,
  buoys_id: null,
  vessel_name: null,
  latitude: null,
  longitude: null,
  temperature: null,
  velocity: null,
  course: null,
};

export const colMappingConfig: any = {
  vessel: VesselColumnMapping,
  buoy: BuoysColumnMapping,
};

export const VesselDBHeaders: Record<
  string,
  { label: string; group: string; required: boolean; pattern: RegExp | null }
> = {
  date: {
    label: I18nKey.IUU_SETTINGS_DATE,
    group: 'date',
    required: true,
    pattern: /date|fech[a]/i,
  },
  latitude: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_LATITUDE,
    group: 'latitude',
    required: true,
    pattern: /latitude|lat/i,
  },
  longitude: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_LONGITUDE,
    group: 'longitude',
    required: true,
    pattern: /longitude|long|lon/i,
  },
  velocity: {
    label: I18nKey.IUU_SETTINGS_VELOCITY,
    group: 'velocity',
    required: false,
    pattern: /velocity|velocidad/i,
  },
  course: {
    label: I18nKey.IUU_SETTINGS_COURSE,
    group: 'course',
    required: false,
    pattern: /course|rumbo/i,
  },
};

export const BuoyDBHeaders: Record<
  string,
  { label: string; group: string; required: boolean; pattern: RegExp | null }
> = {
  date: {
    label: I18nKey.IUU_SETTINGS_DATE,
    group: 'date',
    required: true,
    pattern: /date|fech[a]/i,
  },
  buoys_id: {
    label: I18nKey.IUU_SETTINGS_BUOY_ID,
    group: 'buoys_id',
    required: true,
    pattern: /buoy[\s*|\-|_]*id|codigo_boya/i,
  },
  vessel_name: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_VESSEL_NAME,
    group: 'vessel_name',
    required: false,
    pattern: /vessel[\s*|\-|_]*name|barco/i,
  },
  latitude: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_LATITUDE,
    group: 'latitude',
    required: true,
    pattern: /latitude|lat/i,
  },
  longitude: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_LONGITUDE,
    group: 'longitude',
    required: true,
    pattern: /longitude|long|lon/i,
  },
  velocity: {
    label: I18nKey.IUU_SETTINGS_VELOCITY,
    group: 'velocity',
    required: false,
    pattern: /velocity|velocidad/i,
  },
  course: {
    label: I18nKey.IUU_SETTINGS_COURSE,
    group: 'course',
    required: false,
    pattern: /course|rumbo/i,
  },
  temperature: {
    label: I18nKey.IUU_SETTINGS_TEMPERATURE,
    group: 'temperature',
    required: false,
    pattern: /temperature|temperatura/i,
  },
};

export const dbHeadersConfig: any = {
  vessel: VesselDBHeaders,
  buoy: BuoyDBHeaders,
};

export const getSliderTrackDurationMarks = (intl: any) => [
  {
    value: 0,
    label: intl.formatMessage({ id: I18nKey.IUU_SETTINGS_7_DAYS }),
  },
  {
    value: 20,
    label: intl.formatMessage({ id: I18nKey.IUU_SETTINGS_30_DAYS }),
  },
  {
    value: 40,
    label: intl.formatMessage({ id: I18nKey.IUU_SETTINGS_3_MONTHS }),
  },
  {
    value: 60,
    label: intl.formatMessage({ id: I18nKey.IUU_SETTINGS_6_MONTHS }),
  },
  {
    value: 80,
    label: intl.formatMessage({ id: I18nKey.IUU_SETTINGS_1_YEAR }),
  },
  {
    value: 100,
    label: intl.formatMessage({ id: I18nKey.IUU_SETTINGS_ALL }),
  },
];

export const trackDefaultDuration = 7;

export const trackValueDayMap: any = {
  0: 7,
  20: 30,
  40: 90,
  60: 180,
  80: 365,
};

export const BUOY_VESSEL_DATE_DURATION = '0';
