import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useIndexedDB } from 'react-indexed-db';
import { Navigate } from 'react-router-dom';
import { clearApplicationStorage } from '../../utils/util';

const Logout = () => {
  const [redirect, setRedirect] = useState(false);
  const { clear } = useIndexedDB('cursorData');

  const clearOnLogout = () => {
    clearApplicationStorage();
    clear();
  };

  useEffect(() => {
    Auth.signOut()
      .then(() => {
        clearOnLogout();
        setRedirect(true);
      })
      .catch((err) => {
        console.log('error signing out: ', err);
      });
  }, []);

  if (redirect) {
    return <Navigate replace to="/" />;
  }

  return null;
};

export default Logout;
