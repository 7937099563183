import { CursorData, CursorDataRemote, locationPoint } from '../../../types';
import { HaversineGeolocationService } from './HaversineGeolocationService';
import {
  getDateAndDepth,
  metertoFathom,
  meterToFeet,
  toFahrenheit,
  toInch,
} from '../../HelperService';
import _ from 'lodash';
import moment from 'moment';
import { PREVIOUS_DATA_FETCH_DAYS } from '../../config';

export const mapCursorData = (data: any, code: string) => {
  return data.map((item: CursorDataRemote) => {
    const cursorMapping: any = {
      latitude: item.lat,
      longitude: item.lon,
    };
    switch (code) {
      case 'CUR':
        cursorMapping.cur = item.water_velocity;
        break;
      case 'SST':
        cursorMapping.sstcelcius = item.water_surf_temp;
        cursorMapping.sstfahrenheit = toFahrenheit(item.water_surf_temp);
        break;
      case 'FAI':
        cursorMapping.fai = item.floating_algae;
        break;
      case 'PLK':
        cursorMapping.plk = item.chlorophyll_concentration_orig;
        break;
      case 'SSHA':
        cursorMapping.sshacm = item.surf_el;
        cursorMapping.sshain = toInch(item.surf_el);
        break;
      case 'TCD':
        cursorMapping.tcdm = item.thermocline_depth;
        cursorMapping.tcdft = meterToFeet(item.thermocline_depth);
        cursorMapping.tcdfathom = metertoFathom(item.thermocline_depth);
        break;
      case 'TSS':
        cursorMapping.tsscelcius = item.water_temp;
        cursorMapping.tssfahrenheit = toFahrenheit(item.water_temp);
        break;
      case 'CSS':
        cursorMapping.css = item.water_velocity;
        break;
      case 'RHU':
        cursorMapping.rhu = item.rh;
        break;
      case 'WAV':
        cursorMapping.wav = item.velocity;
        break;
      case 'WIND':
        cursorMapping.wind = item.wind_velocity;
        break;
      case 'SWL':
        cursorMapping.swl = item.velocity;
        break;
      case 'BPS':
        cursorMapping.bps = item.baro_press;
        break;
      case 'PRPA':
        cursorMapping.prpa = item.total_precip;
        break;
      case 'DOX':
        cursorMapping.dox = item.dissolved_oxygen;
        break;
      case 'PPR':
        cursorMapping.ppr = item.primary_production;
        break;
      case 'SSD':
        cursorMapping.ssd = item.sunshine_duration;
        break;
      case 'HAB':
        cursorMapping.hab = item.Band1;
        break;
      case 'SAL':
        cursorMapping.sal = item.salinity;
        break;
      case 'CBAA':
        cursorMapping.cbaa = item.coral_bleaching_alert_areas;
        break;
      case 'CBHS':
        cursorMapping.cbhs = item.coral_bleaching_hotspots;
        break;
      case 'CBHW':
        cursorMapping.cbhw = item.coral_bleaching_heating_weeks;
        break;
      default:
        break;
    }
    return cursorMapping;
  });
};

export const findCursorData = async (
  lngLat: { lat: number; lng: number },
  pCode: any,
  region: string,
  userData: any,
  units: any,
  getByIndex: any,
  getValueForPreviousDate?: boolean
) => {
  let [date, depth] = getDateAndDepth(userData[pCode]);
  let cursorDataValues: any = {};
  let data = await getByIndex('name', date + region + pCode + depth);
  //In case where data files arrive late for any product, we make use of pervious day's data
  //This case will be handled only when current date will be the same as the date passed in the API call
  //We will retrieve the previous day's cursor data from Indexed DB when the getValueForPreviousDate flag is true
  //For ex. if data for 20240702 is not present, date will be set to 20240701
  if (data === undefined && getValueForPreviousDate) {
    let inputDate = moment(date, 'YYYYMMDD').toDate();
    let todaysDate = new Date();
    if (inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
      let newDate = moment()
        .subtract(PREVIOUS_DATA_FETCH_DAYS, 'd')
        .format('YYYYMMDD');
      let isTimeProduct = !_.isEmpty(userData[pCode].timeFrequency);
      let dateValue = isTimeProduct
        ? newDate + sessionStorage.getItem('time')
        : newDate;
      data = await getByIndex('name', dateValue + region + pCode + depth);
    }
  }
  if (data?.data) {
    const cursorDataPointLocal: any = getLocation(
      lngLat.lat,
      lngLat.lng,
      data.data
    ) as CursorData;
    let u = units[pCode].unitSection !== ' ' ? units[pCode].unit : '';
    cursorDataValues[(pCode.toLowerCase() as keyof CursorData) + u] =
      cursorDataPointLocal[pCode.toLowerCase() + u]?.toFixed(
        userData[pCode].precision
      );
    if (!_.isEmpty(cursorDataValues)) return cursorDataValues;
  }
  return {};
};

export const getLocation = (
  lat: number,
  lng: number,
  cursorData: CursorData[]
): locationPoint => {
  const currentPoint = {
    latitude: lat,
    longitude: lng,
    accuracy: 1,
  };
  const cursorDataLocal: CursorData[] = [];
  for (let i = 0; i < cursorData.length; i = i + 25000) {
    cursorDataLocal.push(
      HaversineGeolocationService.getClosestPosition(
        currentPoint,
        cursorData.slice(i, i + 25000) as locationPoint[],
        'mi'
      )
    );
  }
  return HaversineGeolocationService.getClosestPosition(
    currentPoint,
    cursorDataLocal as locationPoint[],
    'mi'
  );
};
