import { Box, Drawer } from '@mui/material';
import _ from 'lodash';
import { IconGrid } from '../../../components/IconGrid/IconGrid';
import { FeaturesContext } from '../FeaturesContext';
import { useStyles } from '../../../utils/util';
import { featureNavigationStyles } from './FeatureNavigationStyles';
import { useContextSelector } from 'use-context-selector';
import { OtherFeaturesContext } from '../OtherFeaturesContext';
import { Toolbar } from '@mui/material';

export const FeatureNavigation: React.FC = () => {
  const classes = useStyles(featureNavigationStyles, [])();
  const productsDisplayConfig = useContextSelector(
    FeaturesContext,
    (state) => state.displayConfig
  );
  const othersDisplayConfig = useContextSelector(
    OtherFeaturesContext,
    (state) => state.displayConfig
  );

  return (
    <Drawer variant="permanent" anchor="right">
      <Toolbar />
      <Box className={classes.sidePanel}>
        {productsDisplayConfig && othersDisplayConfig && (
          <IconGrid
            categoryData={_.merge(productsDisplayConfig, othersDisplayConfig)}
          />
        )}
      </Box>
    </Drawer>
  );
};
