import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../../../translations/I18nKey';
import { DataTable } from '../../../../components/DataTable/DataTable';
import {
  DataTableConfigType,
  actionButtonsEnum,
} from '../../../../components/DataTable/DataTableTypes';
import moment from 'moment';
import { GeofenceStyles } from './Geofence/GeofenceStyles';
import VesselBuoyFileUpload from './VesselBuoyFileUpload';
import AutoComplete from '../../../../components/AutoComplete/AutoComplete';
import {
  IUU_SETTINGS_BUOY_COLUMNS,
  durationListForVesselBuoys,
} from '../config';
import { AISStyles } from './AISnVMS/AISnVMSStyles';
import _ from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useVesselUploadStyles } from './VesselBuoyStyles';
import { getArrayOfObjects, getListFromArray } from '../IUUService';
import { BUOY_VESSEL_DATE_DURATION } from './VesselBuoyConfig';
import { VesselTrackingService } from '../VesselTrackingService';
import { Toggle } from '../../../../components/Toggle/Toggle';
import { getPositionForPopUp } from '../../HelperService';

export const BuoyList: React.FC<any> = ({
  BuoyListData,
  onUploadVBData,
  setLoading,
  displayAlert,
  onApplyFilters,
  filteredBuoyData,
  fileData,
  deleteFiles,
}) => {
  const intl = useIntl();
  const [fileUploadMode, setFileUploadMode] = useState(false);
  const [filesViewMode, setFilesViewMode] = useState(false);
  const [selectedVesselName, setSelectedVesselName] = useState<any>([]);
  const [selectedBuoysId, setSelectedBuoysId] = useState<any>([]);
  const vesselUploadClasses: any = useVesselUploadStyles();
  const [dataDuration, setDataDuration] = useState<string>(
    JSON.parse(localStorage.getItem('buoyFilter') || '{}')?.dataDurationValue ||
      BUOY_VESSEL_DATE_DURATION
  );
  const [showTracks, setShowTracks] = useState<boolean>(false);
  const [disableToggle, setDisableToggle] = useState<boolean>(true);
  const onDateChange = (event: SelectChangeEvent) => {
    setDataDuration(event.target.value);
  };

  const viewFilesList = () => {
    setFilesViewMode(!filesViewMode);
  };

  const openfileUpload = () => {
    setFileUploadMode(!fileUploadMode);
  };

  const onVesselNameChange = (e: any) => {
    setSelectedVesselName(e);
  };

  const onBuoysIdChange = (e: any) => {
    setSelectedBuoysId(e);
  };

  useEffect(() => {
    const filters = JSON.parse(localStorage.getItem('buoyFilter') || '{}');
    if (filters.vesselName !== undefined) {
      setSelectedVesselName(
        getArrayOfObjects(filters.vesselName, 'vesselName')
      );
    }
    if (filters.buoysId !== undefined) {
      setSelectedBuoysId(getArrayOfObjects(filters.buoysId, 'buoysId'));
    }
    if (filters.dataDurationValue) {
      setDataDuration(filters.dataDurationValue);
    }
    if (filters.showTracks) {
      setShowTracks(true);
      setDisableToggle(false);
    }
  }, []);

  useEffect(() => {
    if (selectedBuoysId.length > 0) {
      setDisableToggle(false);
    } else {
      setShowTracks(false);
      setDisableToggle(true);
    }
  }, [selectedBuoysId]);

  const buoysDataTableConfig: DataTableConfigType = {
    gridHeight: '300px',
    disableFooter: true,
  };

  const buoysFileConfig = {
    actions: {
      showActions: true,
      width: 90,
      actionButtons: [
        {
          name: actionButtonsEnum.delete,
          position: 1,
          onClick: (params: GridCellParams) => {
            onDeleteFiles(params.row.id);
          },
        },
      ],
      actionColumnHeaderClassName: 'tableHeaderStyle',
      actionColumnCellClassName: 'tableCellStyle',
    },
  };

  const onDeleteFiles = (id: any) => {
    deleteFiles([id]);
    setLoading(true);
  };

  const columns: GridColDef[] = [
    {
      field: 'buoysId',
      width: 120,
      headerName: 'Buoy ID',
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={String(params.value)}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'vesselName',
      width: 150,
      headerName: intl.formatMessage({
        id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_VESSEL_NAME,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={String(params.value)}>
          <span>{params.value || 'N/A'}</span>
        </Tooltip>
      ),
    },
    {
      field: 'date',
      width: 160,
      headerName: intl.formatMessage({
        id: I18nKey.IUU_POPUP_LAST_SEEN_AT,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      headerAlign: 'center',
      renderCell: (params: GridCellParams) => {
        return (
          <>
            {moment(new Date(params.row.date)).format('MMM DD, yyy HH:mm:ss')}
          </>
        );
      },
    },
    {
      field: 'latitude',
      width: 110,
      headerName: intl.formatMessage({
        id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_LATITUDE,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip
          title={
            getPositionForPopUp({
              latitude: params.row.latitude,
              longitude: params.row.longitude,
            }).lat
          }
        >
          <span>
            {
              getPositionForPopUp({
                latitude: params.row.latitude,
                longitude: params.row.longitude,
              }).lat
            }
          </span>
        </Tooltip>
      ),
    },
    {
      field: 'longitude',
      width: 110,
      headerName: intl.formatMessage({
        id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_LONGITUDE,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip
          title={
            getPositionForPopUp({
              latitude: params.row.latitude,
              longitude: params.row.longitude,
            }).lon
          }
        >
          <span>
            {
              getPositionForPopUp({
                latitude: params.row.latitude,
                longitude: params.row.longitude,
              }).lon
            }
          </span>
        </Tooltip>
      ),
    },
    {
      field: 'course',
      width: 100,
      headerName: intl.formatMessage({
        id: I18nKey.IUU_POPUP_COURSE,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={`${Math.round(params.value)} °`}>
          <span>{`${Math.round(params.value)} °`}</span>
        </Tooltip>
      ),
    },
  ];

  const fileDataColumns: GridColDef[] = [
    {
      field: 'fileName',
      width: 200,
      headerName: intl.formatMessage({ id: I18nKey.IUU_SETTINGS_FILE_NAME }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={String(params.value)}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'noOfRecords',
      width: 120,
      headerName: intl.formatMessage({
        id: I18nKey.IUU_SETTINGS_NO_OF_RECORDS,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      align: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={String(params.value)}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'createdAt',
      width: 160,
      headerName: intl.formatMessage({ id: I18nKey.IUU_SETTINGS_CREATED_AT }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      headerAlign: 'center',
      renderCell: (params: GridCellParams) => {
        return (
          <>
            {moment(new Date(params.row.createdAt)).format(
              'MMM DD, yyy HH:mm:ss'
            )}
          </>
        );
      },
    },
  ];

  const getUniqueData = (data: any, type: string) => {
    return _.uniqBy(data, type);
  };

  const onToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowTracks(event.target.checked);
  };

  const getDates = () => {
    let dates = {};
    if (parseInt(dataDuration) === 0) {
      dates = { toDate: null, fromDate: null };
    } else {
      dates = VesselTrackingService.getTrackDates(parseInt(dataDuration));
    }
    return dates;
  };

  const onApply = (e: any) => {
    let filterData: any = {
      vesselName: getListFromArray(selectedVesselName, 'vesselName'),
      buoysId: getListFromArray(selectedBuoysId, 'buoysId'),
      dataDuration: getDates(),
      dataDurationValue: dataDuration,
      showTracks: showTracks,
    };
    localStorage.setItem('buoyFilter', JSON.stringify(filterData));
    onApplyFilters();
  };

  return (
    <Box sx={{ color: 'white' }}>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        justifyContent="flex-end"
        spacing={2}
        marginBottom={'5px'}
      >
        {!fileUploadMode && (
          <Link
            sx={{
              color: '#FFA901',
              cursor: 'pointer',
              textDecoration: 'underline',

              textAlign: 'center',
            }}
            underline="none"
            onClick={viewFilesList}
          >
            {filesViewMode
              ? intl.formatMessage({ id: I18nKey.IUU_SETTINGS_BACK })
              : intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_VIEW_BUOY_FILES,
                })}
          </Link>
        )}

        {!filesViewMode && (
          <Link
            sx={{
              color: '#FFA901',
              cursor: 'pointer',
              textDecoration: 'underline',
              marginLeft: 'auto',
            }}
            underline="none"
            onClick={openfileUpload}
          >
            {fileUploadMode
              ? intl.formatMessage({ id: I18nKey.IUU_SETTINGS_BACK })
              : intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_UPLOAD_BUOYS_DATA,
                })}
          </Link>
        )}
      </Stack>
      {filesViewMode && (
        <Box sx={GeofenceStyles.dataTableStyles}>
          <Typography>
            {intl.formatMessage({
              id: I18nKey.IUU_SETTINGS_BUOYS_UPLOADED_DATA,
            })}
          </Typography>
          <DataTable
            tableColumns={fileDataColumns}
            dataRows={fileData}
            tableConfig={buoysFileConfig}
          />
        </Box>
      )}
      {fileUploadMode && (
        <VesselBuoyFileUpload
          dataType="buoy"
          setFileUploadMode={setFileUploadMode}
          onUploadVBData={onUploadVBData}
          setLoading={setLoading}
          displayAlert={displayAlert}
        />
      )}
      {!fileUploadMode && !filesViewMode && (
        <>
          <Accordion
            sx={{
              backgroundColor: 'black',
              border: '1px solid #333333',
              borderRadius: '1px',
              marginRight: '8px !important',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ color: 'white' }}
            >
              <Typography>
                {intl.formatMessage({ id: I18nKey.IUU_SETTINGS_FILTERS })}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ marginTop: '-37px' }}>
              <Box className={vesselUploadClasses.dateWrapper}>
                <Typography
                  className={vesselUploadClasses.dateDurationText}
                  sx={{ mr: 1 }}
                >
                  {intl.formatMessage({ id: I18nKey.IUU_DATA_DURATION })}
                </Typography>
                <FormControl
                  className={vesselUploadClasses.dateFormControl}
                  sx={{ m: 1 }}
                  size="small"
                >
                  <Select
                    id="iuu-select"
                    displayEmpty
                    value={dataDuration}
                    onChange={onDateChange}
                    className={vesselUploadClasses.dateSelect}
                  >
                    {durationListForVesselBuoys.map((d) => {
                      return (
                        <MenuItem value={d.value}>
                          {intl.formatMessage({
                            id: d.name,
                          })}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <AutoComplete
                key={IUU_SETTINGS_BUOY_COLUMNS.vesselNameColumnConfig[0].name}
                data={getUniqueData(
                  BuoyListData,
                  IUU_SETTINGS_BUOY_COLUMNS.vesselNameColumnConfig[0].name
                )}
                style={{
                  ...AISStyles.autocompleteStyle,
                  ...{ marginLeft: '-10px' },
                }}
                placeholder={intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_SEARCH_BY_VESSEL_NAME,
                })}
                columnConfig={IUU_SETTINGS_BUOY_COLUMNS.vesselNameColumnConfig}
                onSelectionChange={onVesselNameChange}
                showSelectAllToggle={false}
                selectionData={selectedVesselName}
                multiValueSeparator=" "
              />
              <Divider
                sx={{ borderColor: '#333333', mt: 2, mb: -2 }}
                flexItem
              ></Divider>
              <AutoComplete
                key={IUU_SETTINGS_BUOY_COLUMNS.buoyIdColumnConfig[0].name}
                data={getUniqueData(
                  BuoyListData,
                  IUU_SETTINGS_BUOY_COLUMNS.buoyIdColumnConfig[0].name
                )}
                style={{
                  ...AISStyles.autocompleteStyle,
                  ...{ marginLeft: '-10px' },
                }}
                placeholder={intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_SEARCH_BY_BUOY_ID,
                })}
                columnConfig={IUU_SETTINGS_BUOY_COLUMNS.buoyIdColumnConfig}
                onSelectionChange={onBuoysIdChange}
                showSelectAllToggle={false}
                selectionData={selectedBuoysId}
                multiValueSeparator=" "
              />

              <Divider
                sx={{ borderColor: '#333333', my: 2 }}
                flexItem
              ></Divider>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {' '}
                  <Box sx={{ display: 'flex', mb: 1 }}>
                    <FormGroup>
                      <FormControlLabel
                        sx={{
                          color: '#fff',
                          marginLeft: '0px',
                          '& .MuiFormControlLabel-label.Mui-disabled': {
                            color: '#6F6F6F',
                          },
                        }}
                        control={
                          <Toggle
                            disabled={disableToggle}
                            checked={showTracks}
                            onChange={onToggleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label={intl.formatMessage({
                          id: I18nKey.IUU_SETTINGS_SHOW_TRACKS,
                        })}
                        labelPlacement="start"
                      />
                    </FormGroup>
                  </Box>
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                  <Button
                    className={vesselUploadClasses.popUpWarningColor}
                    variant="contained"
                    onClick={onApply}
                  >
                    {intl.formatMessage({
                      id: I18nKey.IUU_SETTINGS_ACTION_BUTTON,
                    })}
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <br />
          <Box sx={GeofenceStyles.dataTableStyles}>
            <Typography>
              {intl.formatMessage({ id: I18nKey.IUU_SETTINGS_BUOYS_LIST })}
            </Typography>
            <DataTable
              tableColumns={columns}
              dataRows={filteredBuoyData}
              tableConfig={buoysDataTableConfig}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
