import { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import _, { isEmpty } from 'lodash';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box/Box';
import closeIcon from '../../assets/icons/close.svg';
import { I18nKey } from '../../translations/I18nKey';
import { useStyles } from '../../utils/util';
import { BreachVesselDetails } from '../types';
import { PopUpStyles } from '../Map/IUU/PopUp/VesselPopUpStyles';

const Value = styled(Paper)(PopUpStyles.ValueStyle);

export const DashboardVesselPopUp: React.FC<any> = ({
  vessel,
  layerType,
  setShowPopUp,
  position,
  breachData,
  isShowPopUp,
}) => {
  const intl = useIntl();
  const classes = useStyles(PopUpStyles, [])();
  const [breachVessel, setBreachVessel] = useState<BreachVesselDetails>(
    {} as any
  );
  const [isVisible, setIsVisible] = useState(isShowPopUp);
  const popupRef = useRef<any>();

  useEffect(() => {
    let breachV = breachData.filter(
      (b: any) => b.vessel.vesselId === vessel.vesselID
    );
    if (breachV.length) setBreachVessel(breachV[0]);
  }, [vessel.vesselID]);

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible]);

  const handleClickOutside = (event: any) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsVisible(false);
      onClose();
    }
  };

  const onClose = () => {
    setShowPopUp({ display: false, vessel: {}, layer: '' });
  };

  return (
    <Box
      id="iuu-vessel-popup"
      sx={{
        position: 'fixed',
        backgroundColor: 'black',
        borderRadius: 3,
        width: 'auto',
        top: position.top,
        left: position.left,
      }}
      style={{ display: isVisible ? 'inline-block' : 'none' }}
      ref={popupRef}
    >
      <Box
        sx={{
          backgroundColor: '#3D3D3D',
          display: 'flex',
          flexDirection: 'column',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          padding: 1,
          color: 'white',
          mb: 1,
        }}
      >
        <Typography sx={{ fontSize: '14px' }}>{vessel.name} </Typography>
        <img
          src={closeIcon}
          onClick={onClose}
          className={classes.closeIcon}
          alt="x"
        />
      </Box>
      <Grid
        container
        sx={{ p: 1, width: '260px' }}
        columnSpacing={6}
        rowSpacing={0}
      >
        <Grid item xs={4}>
          <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
            {intl.formatMessage({
              id: I18nKey.IUU_POPUP_MMSI,
            })}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Value>{vessel.vesselID}</Value>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
            {intl.formatMessage({
              id: I18nKey.IUU_POPUP_TYPE,
            })}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Value>{vessel.type}</Value>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
            {intl.formatMessage({
              id: I18nKey.IUU_POPUP_COUNTRY,
            })}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Value>{vessel.country}</Value>
        </Grid>

        <Grid item xs={4}>
          <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
            {intl.formatMessage({
              id: I18nKey.IUU_POPUP_SPEED,
            })}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Value>{vessel.sog.toFixed(2)}</Value>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
            {intl.formatMessage({
              id: I18nKey.IUU_POPUP_COURSE,
            })}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Value>{vessel.course}</Value>
        </Grid>

        <Grid item xs={4}>
          <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
            {intl.formatMessage({
              id: I18nKey.IUU_POPUP_POSITION,
            })}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Value>
            {vessel.position.lat} <br></br>
            {vessel.position.lon}
          </Value>
        </Grid>
      </Grid>
      <Stack sx={{ mt: 1, fontSize: '14px', p: 1 }}>
        <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
          {intl.formatMessage({
            id: I18nKey.IUU_POPUP_LAST_SEEN_AT,
          })}
        </Typography>
        <Typography sx={{ color: 'white', fontSize: '14px' }}>
          {vessel.date.split('T')[0]} {vessel.date.split('T')[1]} (
          {intl.formatMessage({
            id: I18nKey.IUU_POPUP_UTC,
          })}
          )
        </Typography>
      </Stack>
      {!isEmpty(breachVessel) && (
        <>
          <Box sx={{ borderTop: 1, borderColor: 'white', p: 1 }}>
            <Stack direction="row" mb={1} mt={1} spacing={2}>
              <Box sx={{ color: 'white' }}>
                {intl.formatMessage({
                  id: I18nKey.IUU_POPUP_BREACH_DETAILS,
                })}
              </Box>
            </Stack>
            <Grid
              container
              sx={{ width: '260px' }}
              columnSpacing={6}
              rowSpacing={0}
            >
              <Grid item xs={4}>
                <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
                  {intl.formatMessage({
                    id: I18nKey.IUU_POPUP_GEOFENCE_NAME,
                  })}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Value>{breachVessel.geofence.geofenceName}</Value>
              </Grid>

              <Grid item xs={4}>
                <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
                  {intl.formatMessage({
                    id: I18nKey.IUU_POPUP_GEOFENCE_TYPE,
                  })}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Value>{breachVessel.geofence.geofenceType}</Value>
              </Grid>
            </Grid>
            <Stack sx={{ mt: 1, fontSize: '14px' }}>
              <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
                {intl.formatMessage({
                  id: I18nKey.IUU_POPUP_BREACH_DATE,
                })}
              </Typography>
              <Typography sx={{ color: 'white', fontSize: '14px' }}>
                {breachVessel.breachDate.split('T')[0]}
                {breachVessel.breachDate.split('T')[1]} (
                {intl.formatMessage({
                  id: I18nKey.IUU_POPUP_UTC,
                })}
                )
              </Typography>
            </Stack>
          </Box>
        </>
      )}
    </Box>
  );
};
