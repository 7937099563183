import { FC, Fragment } from 'react';

import { useIntl } from 'react-intl';
import { Box, Button, Grid, Paper, Link } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { styled } from '@mui/styles';

import AutoComplete from '../../../components/AutoComplete/AutoComplete';
import { FishcatchDBHeaders, mappingCSVColumnConfig } from '../DashboardConfig';
import { I18nKey } from '../../../translations/I18nKey';

import {
  FishCatchDashboardStyles,
  useFishCatchDashboardStyles,
} from '../FishCatchDashboardStyles';

interface ImportPopUpDataMapperProps {
  fishCatchColumnMapping: Record<string, null | undefined | string>;
  onMappingSelectionChange: (
    field: string,
    value: {
      header: string;
    }
  ) => void;
  getMappingData: () => any[];
}

interface MapperFooterProps {
  handleBack: () => void;
  fishCatchColumnMapping: Record<string, null | undefined | string>;
  handleUploadClick: () => Promise<void>;
  isLoading: boolean;
}

export const ImportPopUpDataMapper: FC<ImportPopUpDataMapperProps> = ({
  fishCatchColumnMapping,
  onMappingSelectionChange,
  getMappingData,
}) => {
  const Item = styled(Paper)(() => FishCatchDashboardStyles.item);
  const intl = useIntl();
  const fishCatchClasses: any = useFishCatchDashboardStyles();

  return (
    <Box>
      <Box className={fishCatchClasses.mapperWrapper}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {Object.keys(fishCatchColumnMapping).map(
            (field: string, index: number) => {
              return (
                <Fragment key={field + index}>
                  <Grid item xs={5} className={fishCatchClasses.alignCenter}>
                    <Item
                      className={
                        FishcatchDBHeaders[field].required
                          ? `${fishCatchClasses['fishcatch__mapper__required-label']} ${fishCatchClasses.mapperLabel}`
                          : `${fishCatchClasses.mapperLabel}`
                      }
                    >
                      {intl.formatMessage({
                        id: FishcatchDBHeaders[field].label,
                      })}
                    </Item>
                  </Grid>
                  <Grid item xs={2} className={fishCatchClasses.alignCenter}>
                    <DragHandleIcon />
                  </Grid>
                  <Grid item xs={5} className={fishCatchClasses.alignCenter}>
                    <AutoComplete
                      key={'header'}
                      data={getMappingData()}
                      style={FishCatchDashboardStyles.mappingSelect}
                      columnConfig={mappingCSVColumnConfig}
                      onSelectionChange={(e: any) =>
                        onMappingSelectionChange(field, e)
                      }
                      showSelectAllToggle={false}
                      enableSingleSelection={true}
                      showClearAll={false}
                      showChips={false}
                      selectionData={{
                        header: fishCatchColumnMapping[field] || '',
                      }}
                    ></AutoComplete>
                  </Grid>
                </Fragment>
              );
            }
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export const MapperFooter: FC<MapperFooterProps> = ({
  handleBack,
  fishCatchColumnMapping,
  handleUploadClick,
  isLoading,
}) => {
  const intl = useIntl();
  const requiredValues = Object.keys(FishcatchDBHeaders)
    .filter((key) => FishcatchDBHeaders[key].required)
    .map((key) => fishCatchColumnMapping[key]);
  const fishCatchClasses: any = useFishCatchDashboardStyles();

  return (
    <Grid item xs={12}>
      <Box className={fishCatchClasses.popUpFloatRight}>
        <Link className={fishCatchClasses.popUpPrevBtn} onClick={handleBack}>
          {intl.formatMessage({
            id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_BACK,
          })}
        </Link>
        <Button
          className={fishCatchClasses.popUpWarningColor}
          variant="contained"
          disabled={Object.values(requiredValues).includes(null) || isLoading}
          onClick={handleUploadClick}
        >
          {intl.formatMessage({
            id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_UPLOAD,
          })}
        </Button>
      </Box>
    </Grid>
  );
};
