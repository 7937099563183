import { useState } from 'react';
import Box from '@mui/material/Box';
import { useContext } from 'use-context-selector';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { LicensedFeaturesContext } from '../Contexts/LicensedFeaturesContext';
import './SideNav.scss';

const SideNav: React.FC = () => {
  const intl = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);
  const currentRoute = useLocation();

  const { isLoading, licensedRoutes } = useContext(LicensedFeaturesContext);

  const getExpandedNav = (route: any) => {
    return (
      <Link to={route.path} className="sideNav-link">
        <p
          className={classNames('', {
            'link--active': currentRoute.pathname === route.path,
          })}
        >
          {intl.formatMessage({
            id: route.label,
          })}
        </p>
      </Link>
    );
  };

  return (
    <>
      {!isLoading && (
        <nav className="navigation">
          <Box
            className={classNames('sideNav', {
              'sideNav--expanded': isExpanded,
            })}
            onMouseOver={() => setIsExpanded(true)}
            onMouseLeave={() => setIsExpanded(false)}
          >
            {licensedRoutes.map((route: any) => {
              return (
                route.includeInNav && (
                  <li
                    key={intl.formatMessage({
                      id: route.label,
                    })}
                  >
                    <span
                      className={classNames('', {
                        'nav-border': currentRoute.pathname === route.path,
                      })}
                    ></span>
                    <Link to={route.path}>
                      <img
                        className={classNames('', {
                          'icon--active': currentRoute.pathname === route.path,
                        })}
                        alt={intl.formatMessage({
                          id: route.label,
                        })}
                        src={route.icon}
                      ></img>
                    </Link>
                    {isExpanded && getExpandedNav(route)}
                  </li>
                )
              );
            })}
          </Box>
        </nav>
      )}
    </>
  );
};

export default SideNav;
