import { useState } from 'react';
import { TextField } from '@mui/material';
import { useStyles } from '../../utils/util';
import { StepperProps } from './StepperTypes';
import { stepperStyles } from './StepperStyles';
import { floor } from 'lodash';

export const Stepper: React.FC<StepperProps> = ({
  min,
  max,
  step,
  value = 0,
  precision,
  stepperStyle,
  onChange,
}) => {
  const classes = useStyles(stepperStyles, [stepperStyle])();
  const [stepperValue, setStepperValue] = useState(floor(value, precision));


  return (
    <TextField
      type="number"
      value={value}
      variant="outlined"
      className={classes.root}
      inputProps={{
        min: floor(min || 0, precision),
        max: floor(max || 100, precision),
        step: step,
      }}
      onChange={onChange}
    ></TextField>
  );
};

Stepper.defaultProps = {
  min: 0,
  max: 100,
  step: 0.1,
  value: 2,
  precision: 1,
  stepperStyle: stepperStyles,
  onChange: () => {},
};
