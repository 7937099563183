import moment from 'moment';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CompareIcon from '@mui/icons-material/Compare';
import { ReactComponent as SeaSurfaceTempSvg } from '../../assets/icons/sea_surface_temp.svg';
import { ReactComponent as PlanktonSvg } from '../../assets/icons/plankton.svg';
import { ReactComponent as SeaSurfaceHeightSvg } from '../../assets/icons/sea_surface_height.svg';
import { ReactComponent as BathymetrySvg } from '../../assets/icons/bathymetry.svg';
import { ReactComponent as SalinitySvg } from '../../assets/icons/salinity.svg';
import { ReactComponent as PressureSvg } from '../../assets/icons/pressure.svg';
import { ReactComponent as ThermoclineDepthSvg } from '../../assets/icons/thermocline_depth.svg';
import { MapTypes } from './FishCatchWidgets';
import { I18nKey } from '../../translations/I18nKey';
import { useIntl } from 'react-intl';
import { AddFishCatchData } from '../types';
import _ from 'lodash';
import dayjs from 'dayjs';
import { getPositionForPopUp } from '../Map/HelperService';

export const MAP_DEFAULT_ZOOM = 1.5;
export const MAP_DEFAULT_CENTER = [0, 0];

export const DatePickerRanges = () => {
  const intl = useIntl();
  let DatePickerRanges: any = {};
  DatePickerRanges[
    intl.formatMessage({
      id: I18nKey.CALENDAR_LAST24HOURS,
    })
  ] = [moment().subtract(24, 'hours'), moment()];
  DatePickerRanges[
    intl.formatMessage({
      id: I18nKey.CALENDAR_LAST7DAYS,
    })
  ] = [moment().subtract(6, 'days'), moment()];
  DatePickerRanges[
    intl.formatMessage({
      id: I18nKey.CALENDAR_LAST30DAYS,
    })
  ] = [moment().subtract(29, 'days'), moment()];
  DatePickerRanges[
    intl.formatMessage({
      id: I18nKey.CALENDAR_LAST1YEAR,
    })
  ] = [moment().subtract(365, 'days'), moment()];
  DatePickerRanges[
    intl.formatMessage({
      id: I18nKey.CALENDAR_ALLDATA,
    })
  ] = '';
  return Object.freeze(DatePickerRanges);
};

export const CATCH_CONDITION_ICON_LIST = [
  <SeaSurfaceTempSvg />,
  <PlanktonSvg />,
  <SeaSurfaceHeightSvg />,
  <BathymetrySvg width={34} height={34} />,
  <SalinitySvg />,
  <PressureSvg />,
  <ThermoclineDepthSvg />,
];

export const vesselTypesColumnConfig = [
  {
    name: 'vessel',
    icon: false,
    includeInSearch: true,
    displayInOption: true,
    displayInChip: true,
  },
];

export const mappingCSVColumnConfig = [
  {
    name: 'header',
    icon: false,
    includeInSearch: true,
    displayInOption: true,
    displayInChip: true,
  },
];

export const speciesTypesColumnConfig = [
  {
    name: 'name',
    icon: false,
    includeInSearch: true,
    displayInOption: true,
    displayInChip: true,
  },
];

export const fishingTechniquesTypesColumnConfig = [
  {
    name: 'fishingTechnique',
    icon: false,
    includeInSearch: true,
    displayInOption: true,
    displayInChip: true,
  },
];

export interface FishCatchFiltersData {
  readonly vessels: { vessel: string }[];
  readonly species: { name: string }[];
  readonly fishingTechniques: { fishingTechnique: string }[];
}

export const FishCatchMapEnumToI18nKeyMapper: Record<MapTypes, string> = {
  [MapTypes.Heat]: I18nKey.FISH_CATCH_DASHBOARD_MAP_HEATMAP,
  [MapTypes.Analysis]: I18nKey.FISH_CATCH_DASHBOARD_MAP_ANALYSIS,
};

export const HeatMapLayout = (region: any) => {
  const intl = useIntl();
  const latLong: any = {
    lon: parseInt(region?.center?.split(',')[0]),
    lat: parseInt(region?.center?.split(',')[1]),
  };
  return {
    layout: {
      mapbox: {
        center: latLong,
        style: 'light',
        zoom: MAP_DEFAULT_ZOOM,
      },
      coloraxis: {
        colorscale: [
          [0.0, 'rgba(33,102,172,0)'],
          [0.2, 'rgb(103,169,207)'],
          [0.4, 'rgb(209,229,240)'],
          [0.6, 'rgb(253,219,199)'],
          [0.8, 'rgb(239,138,98)'],
          [1.0, 'rgb(178,24,43)'],
        ],
      },
      title: intl.formatMessage({
        id: I18nKey.FISH_CATCH_DASHBOARD_MAP_HEATMAP,
      }),
      margin: { t: 30, b: 0, r: 0, l: 0 },
    },
    config: {
      mapboxAccessToken: process.env.REACT_APP_MAPBOX_ACCESSTOKEN || '',
      responsive: true,
      displaylogo: false,
    },
  };
};

export const FishcatchDBHeaders: Record<
  string,
  { label: string; group: string; required: boolean; pattern: RegExp | null }
> = {
  catch_date: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_CATCH_DATE,
    group: 'catch_date',
    required: true,
    pattern: /catch[\s*|\-|_]*date/i,
  },
  vessel_name: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_VESSEL_NAME,
    group: 'vessel_name',
    required: true,
    pattern: /vessel[\s*|\-|_]*name/i,
  },
  latitude: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_LATITUDE,
    group: 'latitude',
    required: true,
    pattern: /latitude|lat/i,
  },
  longitude: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_LONGITUDE,
    group: 'longitude',
    required: true,
    pattern: /longitude|long|lon/i,
  },
  species: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_SPECIES,
    group: 'species',
    required: true,
    pattern: /(specie[s]*)|(catch[\s*|\-|_]*specie[s]*)/i,
  },
  quantity: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_QUANTITY,
    group: 'quantity',
    required: true,
    pattern: /(quantit[y|ies]*)|(catch[\s*|\-|_]*quantit[y|ies]*)/i,
  },
  catch_details: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_CATCH_DETAILS,
    group: 'catch_details',
    required: false,
    pattern: /(catch[\s*|\-|_]*detail[s]*)/i,
  },
  total_quantity: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_TOTAL_QUANTITY,
    group: 'total_quantity',
    required: false,
    pattern: /total[\s*|\-|_]*quantity/i,
  },
  comment: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_COMMENT,
    group: 'comment',
    required: false,
    pattern: /comment|comments/i,
  },
  'temp_(c)': {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_TEMP_C,
    group: 'catch_conditions',
    required: false,
    pattern: /temp|(temperature)/i,
  },
  plankton: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_PLANKTON,
    group: 'catch_conditions',
    required: false,
    pattern: /plankton/i,
  },
  ssh: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_SSHA,
    group: 'catch_conditions',
    required: false,
    pattern: /ssh|ssha/i,
  },
  bathymetry: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_BATHYMETRY,
    group: 'catch_conditions',
    required: false,
    pattern: /bathymetry/i,
  },
  salinity: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_SALINITY,
    group: 'catch_conditions',
    required: false,
    pattern: /salinity/i,
  },
  pressure: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_PRESSURE,
    group: 'catch_conditions',
    required: false,
    pattern: /pressure/i,
  },
  thermocliene_depth: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_THERMOCLIENE_DEPTH,
    group: 'catch_conditions',
    required: false,
    pattern: /thermocliene[\s*|\-|_]*depth/i,
  },
  fishing_technique: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_FISHING_TECHNIQUE,
    group: 'fishing_technique',
    required: false,
    pattern: /fishing[\s*|\-|_]*technique/i,
  },
  'temperature_(25m)': {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_TEMP_25,
    group: 'catch_conditions',
    required: false,
    pattern: null,
  },
  'temperature_(50m)': {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_TEMP_50,
    group: 'catch_conditions',
    required: false,
    pattern: null,
  },
  'temperature_(100m)': {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_TEMP_100,
    group: 'catch_conditions',
    required: false,
    pattern: null,
  },
  'temperature_(150m)': {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_TEMP_150,
    group: 'catch_conditions',
    required: false,
    pattern: null,
  },
  'temperature_(200m)': {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_TEMP_200,
    group: 'catch_conditions',
    required: false,
    pattern: null,
  },
  'temperature_(300m)': {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_TEMP_300,
    group: 'catch_conditions',
    required: false,
    pattern: null,
  },
  current_speed: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_CURRENT_SPEED,
    group: 'catch_conditions',
    required: false,
    pattern: null,
  },
  current_direction: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_CURRENT_DIRECTION,
    group: 'catch_conditions',
    required: false,
    pattern: null,
  },
  wind_speed: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_WIND_SPEED,
    group: 'catch_conditions',
    required: false,
    pattern: null,
  },
  wind_direction: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_WIND_DIRECTION,
    group: 'catch_conditions',
    required: false,
    pattern: null,
  },
  wave_height: {
    label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_WAVE_HEIGHT,
    group: 'catch_conditions',
    required: false,
    pattern: null,
  },
};

export const popUpSteps = (): Record<number, { label: string; icon: any }> => {
  return {
    0: {
      label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_STEP_ONE_LABEL,
      icon: UploadFileIcon,
    },
    1: {
      label: I18nKey.FISH_CATCH_DASHBOAD_POPUP_STEP_TWO_LABEL,
      icon: CompareIcon,
    },
  };
};

export const getEmptyCatchData = (): AddFishCatchData => {
  return {
    vessel_id: '',
    catch_date: '',
    vessel_name: '',
    latitude: '',
    longitude: '',
    species: '',
    quantity: '',
    fishing_technique: '',
    catch_details: '',
    total_quantity: '',
    comment: '',
    catch_conditions: {
      plankton: '',
      'temp_(c)': '',
      'temperature_(25m)': '',
      'temperature_(50m)': '',
      'temperature_(100m)': '',
      'temperature_(150m)': '',
      'temperature_(200m)': '',
      'temperature_(300m)': '',
      current_speed: '',
      current_direction: '',
      ssh: '',
      thermocliene_depth: '',
      bathymetry: '',
      salinity: '',
      pressure: '',
      wind_speed: '',
      wind_direction: '',
      wave_height: '',
    },
  };
};

// --- Check validation of the data ---
export const isEmpty = (value: any): boolean => !value || value.trim() === '';

export const isInvalidDate = (value: any): boolean =>
  isNaN(new Date(value).valueOf());

export const isInvalidLatLon = (value: any): boolean => {
  const DegreeStr = '\u00b0'; // °
  const MinuteStr = '\u0027'; // '
  const SecondStr = '\u0022'; // "

  //(N/S) (E/W) (+/-) 110° 57' 54"
  const LatLonPattern =
    '(([NS\\+\\-EW]?)\\s+(\\d{2,3})' +
    DegreeStr +
    '\\s+(\\d{2})' +
    MinuteStr +
    '\\s+(\\d{2})' +
    SecondStr +
    ')';

  //(N/S) (E/W) (+/-)  110° 57.900'
  const LatLonPattern2 =
    '(([NS\\+\\-EW]?)\\s+(\\d{2,3})' +
    DegreeStr +
    '\\s+(\\d{2}\\.\\d{3})' +
    MinuteStr +
    ')';
  //(N/S) (E/W) (+/-)  110.57900°
  const LatLonPattern3 =
    '(([NS\\+\\-EW]?)\\s+(\\d{0,3}\\.\\d{0,6})' + DegreeStr + '{0,1})';

  return !(
    new RegExp(LatLonPattern).test(value) ||
    new RegExp(LatLonPattern2).test(value) ||
    new RegExp(LatLonPattern3).test(value)
  );
};

export const isInvalidSpecies = (value: any): boolean => {
  const speciesPattern = '^(\\w+(?:\\s+\\w+)*)(?::\\w+(?:\\s+\\w+)*)*$';
  return !new RegExp(speciesPattern).test(value);
};

export const isInvalidQuantity = (value: any): boolean => {
  const quantityPattern = '^(?:\\d+(\\.\\d+)?)(?::\\d+(\\.\\d+)?)*$';
  return !new RegExp(quantityPattern).test(value);
};

export const FileFormats: any = {
  catch_date: 'Valid date/datetime - 1/23/2024 9:47:00',
  latitude: `N 80° 57' 54" OR  N 80° 57.900'  OR  N 80.5700°`,
  longitude: `W 110° 57' 54"  OR  W 110° 57.900'  OR  W 110.5700°`,
  species: 'Fish  OR  Fish1:Fish2',
  quantity: 'Qty  OR  Qty1:Qty2',
};

export const SPECIES_COLORS: any = [
  '#2A2FE4',
  '#EB23BF ',
  '#99FA1E',
  '#55093D',
  '#6FABDE',
  '#C8B9C4',
  '#1E33C7',
  '#1AF5E8',
  '#9561D9',
  '#FA1B64',
  '#EEA49E',
  '#C4E5BF',
  '#5C4260',
  '#467D54',
  '#F2ADCC',
  '#9D4547',
  '#F51CF5',
  '#FF6618',
  '#5436E6',
  '#77F40A',
  '#973101',
  '#468BE8',
  '#2E25B5',
  '#B83B45',
  '#587309',
  '#44AD8A',
  '#E6A65A',
  '#5A9A8D',
  '#E1D6C4',
  '#63638A',
  '#86756E',
  '#C0BCB9',
  '#27EFE6',
  '#D9646C',
  '#50B130',
  '#60884F',
  '#E8863A',
  '#366033',
  '#DCAF8D',
  '#2B19A6',
  '#5789E0',
  '#E1CBAE',
  '#1C0E65',
  '#E77594',
  '#43CE09',
  '#3D9450',
  '#519F61',
  '#60630C',
  '#AD440C',
  '#87F4F6',
  '#5EFDCE',
  '#B8D34D',
  '#32DEA2',
  '#E9330D',
  '#137838',
  '#F5A6EA',
  '#E1FAE7',
  '#84BEA5',
  '#A3F923',
  '#EC777A',
  '#E25821',
  '#48B1FA',
  '#206832',
  '#E8DBF3',
  '#89F37F',
  '#2F8A8C',
  '#45F215',
  '#1F5C81',
  '#5D797E',
  '#3B3452',
  '#302A76',
  '#33B503',
  '#DB3440',
  '#E99638',
  '#D6E35B',
  '#A6AC54',
  '#36A6AF',
  '#F902DF',
  '#7B465C',
  '#F9A4F9',
  '#6B2FEE',
  '#BF6A2A',
  '#8E7B44',
  '#A44859',
  '#DC6626',
  '#3498E8',
  '#C5035C',
  '#99CD1B',
  '#B9EDDF',
  '#F705C6',
  '#D9303E',
  '#F988FF',
  '#B4F522',
  '#D18046',
  '#0804DC',
  '#77EF60',
  '#514FBC',
  '#165CFD',
  '#DA1718',
  '#15A605',
];

export const VESSEL_COLORS: any = [
  '#000080',
  '#E77B00',
  '#33A1C2',
  '#036073',
  '#034466',
  '#8585FF',
  '#FFA947',
  '#3EAACC',
  '#60E1FB',
  '#61C4FA',
  '#00003D',
  '#FFC058',
  '#0F5D7D',
  '#12117D',
  '#D9543D',
  '#62779D',
  '#1E1D71',
  '#D6D5C2',
  '#F0854F',
  '#EEB4AA',
  '#CDD3E0',
  '#BFBEEE',
  '#0B0E31',
  '#938A80',
  '#FF961F',
  '#1E5D71',
  '#02323C',
  '#02344F',
  '#FFD899',
  '#91D7F2',
  '#0B0A48',
  '#000000',
  '#413D39',
  '#9D9B6C',
  '#EF7C43',
  '#AA3622',
  '#4F5D7D',
  '#121141',
  '#494931',
  '#712C0A',
];

export const recordDataColumns = (intl: any): any => {
  return [
    {
      accessorKey: 'catchDate',
      header: intl.formatMessage({
        id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_CATCH_DATE,
      }),
      size: 320,
      accessorFn: (originalRow: any) => dayjs(originalRow.catchDate),
      Cell: ({ cell }: any) => {
        return cell.getValue().format('LL');
      },
      filterVariant: 'date-range',
      filterFn: (row: any, id: any, filterValue: any) => {
        let date = new Date(row.getValue(id));
        date.setHours(0, 0, 0, 0);
        let fromDate = new Date(filterValue[0]);
        let toDate = new Date(filterValue[1]);
        if (fromDate.getTime() && toDate.getTime()) {
          if (
            date.getTime() >= fromDate.getTime() &&
            date.getTime() <= toDate.getTime()
          ) {
            return true;
          }
        }
        if (fromDate.getTime() && _.isNaN(toDate.getTime())) {
          if (date.getTime() >= fromDate.getTime()) {
            return true;
          }
        }
        if (toDate.getTime() && _.isNaN(fromDate.getTime())) {
          if (date.getTime() <= toDate.getTime()) {
            return true;
          }
        }
        return false;
      },
      id: 'catchDate',
    },
    {
      accessorKey: 'vesselName',
      size: 200,
      header: intl.formatMessage({
        id: I18nKey.IUUDASHBOARD_VESSEL_NAME,
      }),
    },
    {
      accessorKey: 'fishingTechnique',
      size: 200,
      header: intl.formatMessage({
        id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_FISHING_TECHNIQUE,
      }),
    },
    {
      accessorKey: 'catchDetails',
      header: intl.formatMessage({
        id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_SPECIES,
      }),
      accessorFn: (originalRow: any) =>
        _.values(originalRow.catchDetails)
          .map((f: any) => `${f.name}: ${f.quantity}`)
          .join(', '),
      id: 'catchDetails',
      size: 380,
    },
    {
      accessorKey: 'latitude',
      size: 120,
      header: intl.formatMessage({
        id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_LATITUDE,
      }),
      enableColumnFilter: false,
      accessorFn: (originalRow: any) =>
        getPositionForPopUp({
          latitude: originalRow.latitude,
          longitude: originalRow.longitude,
        }).lat,
      id: 'latitude',
    },
    {
      accessorKey: 'longitude',
      size: 140,
      header: intl.formatMessage({
        id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_LONGITUDE,
      }),
      enableColumnFilter: false,
      accessorFn: (originalRow: any) =>
        getPositionForPopUp({
          latitude: originalRow.latitude,
          longitude: originalRow.longitude,
        }).lon,
      id: 'longitude',
    },
    {
      accessorKey: 'totalQuantity',
      enableColumnFilter: false,
      header: intl.formatMessage({
        id: I18nKey.FISH_CATCH_DASHBOAD_POPUP_TOTAL_QUANTITY,
      }),
    },
  ];
};
