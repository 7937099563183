/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { RasterSlider } from '../../../../components/Slider/Slider';
import { Wrapper } from '../../../../components/Wrapper/Wrapper';
import { I18nKey } from '../../../../translations/I18nKey';
import { ProductUnitsContext } from '../../../Contexts/UnitsContext';
import { FeaturesContext } from '../../FeaturesContext';
import {
  convert,
  getProductPrecisionValue,
  getScaleTypeConvertedValue,
  toAntilog,
  toLog,
  updatePostObDataByUnit,
} from '../../HelperService';
import ButtonSection from './ButtonSection';
import { productSettingDialogBoxStyles } from './ProductSettingsStyles';

const RasterSetting: React.FC<any> = ({ tileData, userData, product }) => {
  const intl = useIntl();
  const getMapTileData = useContextSelector(
    FeaturesContext,
    (state) => state.getMapTileData
  );
  const saveDefault = useContextSelector(
    FeaturesContext,
    (state) => state.saveDefault
  );
  const units = useContextSelector(ProductUnitsContext, (state) => state);

  const [markValue, setMarkValue] = useState([
    {
      value: getScaleTypeConvertedValue(
        userData.scaleType,
        userData?.raster?.scale?.scaleMin,
        toLog
      ),
      label: `${userData?.raster?.scale?.scaleMin}`,
    },
    {
      value: getScaleTypeConvertedValue(
        userData.scaleType,
        userData?.raster?.scale?.scaleMax,
        toLog
      ),
      label: `${userData?.raster?.scale?.scaleMax}`,
    },
  ]);
  const [sliderValue, setSliderValue] = useState([
    getScaleTypeConvertedValue(userData.scaleType, tileData?.min, toLog),
    getScaleTypeConvertedValue(userData.scaleType, tileData?.max, toLog),
  ]);
  const [selectedValue, setSelectedValue] = useState<any>([
    getScaleTypeConvertedValue(userData.scaleType, tileData?.min, toLog),
    getScaleTypeConvertedValue(userData.scaleType, tileData?.max, toLog),
  ]);

  const [colorScaleType, setColorScaleType] = useState(
    tileData?.colorScaleType
  );

  const onChangeHandler = (event: Event, newValue: number[]) => {
    if (product === 'SSHA') {
      if (selectedValue[0] !== newValue[0]) {
        setSelectedValue([newValue[0], Math.abs(newValue[0])]);
      }
      if (selectedValue[1] !== newValue[1]) {
        setSelectedValue([-newValue[1], Math.abs(newValue[1])]);
      }
    } else {
      setSelectedValue(newValue);
    }
  };

  const handleChangeStyle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setColorScaleType((event.target as HTMLInputElement).value);
  };

  const onApplyClick = () => {
    let pdata: any = {
      color_scale_type: colorScaleType,
      tile_min: getScaleTypeConvertedValue(
        userData.scaleType,
        selectedValue[0],
        toAntilog
      ),
      tile_max: getScaleTypeConvertedValue(
        userData.scaleType,
        selectedValue[1],
        toAntilog
      ),
    };
    const tdata: any = {
      color_scale_type: tileData?.colorScaleType,
      tile_min: tileData?.min,
      tile_max: tileData?.max,
    };
    if (!_.isEqual(pdata, tdata)) {
      pdata = updatePostObDataByUnit(
        pdata,
        product,
        'tile_min',
        'tile_max',
        pdata.tile_min,
        pdata.tile_max,
        userData?.precision,
        units[product].unit,
        units[product].defaultUnit
      );
      let selectedProd: any = {};
      selectedProd['raster'] = [product];
      getMapTileData(selectedProd, false, false, pdata);
    }
  };

  const onSaveDefault = () => {
    let pdata = {
      color_scale: userData?.raster?.colorScale,
      scale_max: userData?.raster?.scale?.scaleMax,
      scale_min: userData?.raster?.scale?.scaleMin,
      user_color_scale_type: colorScaleType,
      user_max: getScaleTypeConvertedValue(
        userData.scaleType,
        selectedValue[1],
        toAntilog
      ),
      user_min: getScaleTypeConvertedValue(
        userData.scaleType,
        selectedValue[0],
        toAntilog
      ),
    };
    pdata = updatePostObDataByUnit(
      pdata,
      product,
      'scale_min',
      'scale_max',
      pdata.scale_min,
      pdata.scale_max,
      userData?.precision,
      units[product].unit,
      units[product].defaultUnit
    );
    pdata = updatePostObDataByUnit(
      pdata,
      product,
      'user_min',
      'user_max',
      pdata.user_min,
      pdata.user_max,
      userData?.precision,
      units[product].unit,
      units[product].defaultUnit
    );
    saveDefault(product, 'raster', pdata);
  };

  const onRestore = () => {
    let [userMin, userMax] = convert(
      userData?.raster?.user?.min,
      userData?.raster?.user?.max,
      product,
      userData?.precision,
      units[product].unit
    );
    setSelectedValue([
      getScaleTypeConvertedValue(userData.scaleType, userMin, toLog),
      getScaleTypeConvertedValue(userData.scaleType, userMax, toLog),
    ]);
    setColorScaleType(userData?.raster?.user?.colorScaleType);
  };

  return (
    <Box>
      <ButtonSection
        title={intl.formatMessage({
          id: I18nKey.PRODUCT_SETTING_DIALOG_BOX_RASTER,
        })}
        onApply={onApplyClick}
        onRestore={onRestore}
        onSaveDefault={onSaveDefault}
      />
      <Wrapper wrapperStyle={productSettingDialogBoxStyles.sliderWrapper}>
        <RasterSlider
          selectedValue={selectedValue}
          defaultValue={sliderValue}
          marks={markValue}
          step={getProductPrecisionValue(userData.precision)}
          showLogScale={userData.scaleType === 'log10' ? true : false}
          onChangeHandler={onChangeHandler}
          continuous={colorScaleType === 'continuous' ? true : false}
          colorScale={userData.raster.colorScale}
          setSelectedValue={setSelectedValue}
          precision={userData?.precision}
          syncMinMax={product === 'SSHA'}
        />
      </Wrapper>
      <br></br>
      <Wrapper
        title={intl.formatMessage({
          id: I18nKey.PRODUCT_SETTING_DIALOG_BOX_STYLE,
        })}
        wrapperStyle={productSettingDialogBoxStyles.rasterStylesWrapper}
      >
        <FormControl sx={{ width: '104%' }}>
          <RadioGroup
            row
            aria-labelledby="style"
            name="style-radio-buttons-group"
            value={colorScaleType}
            onChange={handleChangeStyle}
          >
            <FormControlLabel
              value="discrete"
              control={<Radio />}
              label={intl.formatMessage({
                id: I18nKey.PRODUCT_SETTING_DIALOG_BOX_DISCRETE,
              })}
              sx={productSettingDialogBoxStyles.rasterFormLabel}
            />
            <FormControlLabel
              value="continuous"
              control={<Radio />}
              label={intl.formatMessage({
                id: I18nKey.PRODUCT_SETTING_DIALOG_BOX_CONTINUOUS,
              })}
              sx={productSettingDialogBoxStyles.rasterFormLabel}
            />
          </RadioGroup>
        </FormControl>
      </Wrapper>
    </Box>
  );
};

export default RasterSetting;
