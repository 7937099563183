import Plot from 'react-plotly.js';
import { ChartProps } from './StackBarChartTypes';

export const StackBarChart: React.FC<ChartProps> = ({
  data,
  chartConfig,
  useResizeHandler,
  onChartClick,
  onChartDoubleClick,
}) => {
  const layout: any = {
    responsive: true,
    barmode: 'stack',
    showline: chartConfig?.showLine || false,
    tickformat: '.0f',
  };
  const config: any = {
    displaylogo: false
  };
  return (
    <Plot
      data={data}
      layout={layout}
      config={config}
      useResizeHandler={useResizeHandler}
      onClick={onChartClick}
      onDoubleClick={onChartDoubleClick}
    />
  );
};

StackBarChart.defaultProps = {
  chartConfig: { width: 611, height: 379, showLine: false },
};
