import { useEffect, useState } from 'react';
import {
  EditableInput,
  Hue,
  Saturation,
} from 'react-color/lib/components/common';
import { CustomPicker, CompactPicker } from 'react-color';
import { useIntl } from 'react-intl';
import { Box, Button, Typography } from '@mui/material';
import { I18nKey } from '../../../translations/I18nKey';
import { ColorPickerProps } from './ColorPickerTypes';
import { STANDARD_COLORS } from '../../../constants/Constants';
import { mergeStyles } from '../../../utils/util';
import clear from './../../../assets/icons/clear_all_grey.svg';
import { colorPickerStyles } from './ColorPickerStyles';
import './ColorPicker.scss';

export const ColorPicker: React.FC<ColorPickerProps> = (props) => {
  const styles = mergeStyles(colorPickerStyles, {
    userPickerStyle: props.colorPickerStyle,
  });
  const intl = useIntl();
  const [colorCoding, setColorCoding] = useState('hex');
  const colorCodes = [
    { name: 'Hex', value: 'hex' },
    { name: 'RGB', value: 'rgb' },
  ];
  const [space, setSpace] = useState(false);

  useEffect(() => {
    if (props.isOpen) {
      let picker = document.getElementById('settingsPopup');
      if (picker) {
        const rect = picker.getBoundingClientRect();
        if (rect.bottom < 440) {
          setSpace(true);
        } else {
          setSpace(false);
        }
      }
    }
  }, [props.isOpen]);

  const onColorCodingChange = (e: any) => {
    setColorCoding(e.target.value);
  };

  //The following code has been referenced from https://stackoverflow.com/a/28056903
  const hexToRGB = (hex: string) => {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
    return `rgb(${r},${g},${b})`;
  };

  if (!props.isOpen) {
    return null;
  }

  //TODO: create a separate component for hex/rgb select component
  return (
    <Box
      sx={{
        ...styles.userPickerStyle,
        ...styles.colorPickerStyle,
        ...styles.wrapperStyle,
        marginTop: space ? '2px' : '-391px',
      }}
    >
      <Box sx={styles.headerStyle}>
        {intl.formatMessage({
          id: I18nKey.COLOR_BOX_COLOR_PICKER,
        })}
      </Box>
      <Button sx={styles.colorCloseStyle} onClick={props.onClose}>
        <Box
          component="img"
          sx={styles.closeIconStyle}
          src={clear}
          alt={intl.formatMessage({
            id: I18nKey.CLOSE,
          })}
        />
      </Button>
      <Box sx={styles.hueStyle}>
        <Hue {...props} />
      </Box>
      <Box sx={styles.saturationStyle}>
        <Saturation {...props} />
      </Box>
      <Box sx={styles.editColorStyle}>
        <Box
          component="select"
          sx={styles.colorLabelStyle}
          value={colorCoding}
          onChange={onColorCodingChange}
        >
          {colorCodes.map((v) => (
            <option key={v.value} value={v.value}>
              {v.name}
            </option>
          ))}
        </Box>
        <Box sx={styles.colorInputStyle}>
          <EditableInput
            value={colorCoding === 'hex' ? props.hex : hexToRGB(props.hex)}
          />
        </Box>
        <Box sx={{ ...styles.colorWindowStyle, background: props.hex }} />
      </Box>
      <Box component="hr" sx={styles.lineStyle} />
      <Typography sx={styles.pickerTextStyle}>
        {intl.formatMessage({
          id: I18nKey.COLOR_BOX_STANDARD_COLORS,
        })}
      </Typography>
      <Box>
        <CompactPicker colors={STANDARD_COLORS} {...props} />
      </Box>
    </Box>
  );
};

export default CustomPicker(ColorPicker);
