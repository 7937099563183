import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

// --- Interfaces ---
interface navFishCatch {
  title: string;
  selection: string;
  submittedDate: string;
}

// --- Store Types ---
type HeaderManagerFishList = {
  title: string;
  option: string;
  submittedDate: string;
  setHeaderList: (title: string, option: string, date: string) => void;
  resetHeaderList: () => void;
};

type navFeatureFishCatch = {
  nav: navFishCatch;
  setNav: (data: navFishCatch) => void;
};

type reFetchData = {
  fetchFiles: boolean;
  fetchRecord: boolean;
  setReFetchFiles: () => void;
  setReFetchRecord: () => void;
};

type changeOnData = {
  changeGI: boolean;
  changeCC: boolean;
  changeCD: boolean;
  setChangeGI: (val: boolean) => void;
  setChangeCC: (val: boolean) => void;
  setChangeCD: (val: boolean) => void;
};

type temperatureCheck = {
  C: boolean;
  F: boolean;
  setF: (val: boolean) => void;
  setC: (val: boolean) => void;
};

type reFetchCard = {
  reFetchCard: boolean;
  setRefetchCard: (val: boolean) => void;
};

// --- Stores ---
export const useCardChangeContent = create<reFetchCard>()(
  immer((set) => ({
    reFetchCard: false,
    setRefetchCard: (val) =>
      set((state) => {
        state.reFetchCard = val;
      }),
  }))
);

export const useCheckTemperature = create<temperatureCheck>()(
  immer((set) => ({
    C: false,
    F: false,
    setF: (val) =>
      set((state) => {
        state.F = val;
      }),
    setC: (val) =>
      set((state) => {
        state.C = val;
      }),
  }))
);

export const useChangeOnData = create<changeOnData>()(
  immer((set) => ({
    changeCC: false,
    changeCD: false,
    changeGI: false,
    setChangeCC: (val) =>
      set((state) => {
        state.changeCC = val;
      }),
    setChangeCD: (val) =>
      set((state) => {
        state.changeCD = val;
      }),
    setChangeGI: (val) =>
      set((state) => {
        state.changeGI = val;
      }),
  }))
);

export const useFetchFishCatch = create<reFetchData>()(
  immer((set) => ({
    fetchFiles: false,
    fetchRecord: false,
    setReFetchFiles: () =>
      set((state) => {
        state.fetchFiles = !state.fetchFiles;
      }),
    setReFetchRecord: () =>
      set((state) => {
        state.fetchRecord = !state.fetchRecord;
      }),
  }))
);

export const useNavFishCatch = create<navFeatureFishCatch>()(
  immer((set) => ({
    nav: { title: 'uploadedFiles', selection: 'FileTable', submittedDate: '' },
    setNav: (data) =>
      set((state) => {
        state.nav = data;
      }),
  }))
);

export const useFishCatchStore = create<HeaderManagerFishList>()(
  immer((set) => ({
    title: '',
    option: '',
    submittedDate: '',
    setHeaderList: (title, option, date) =>
      set((state) => {
        state.title = title;
        state.option = option;
        state.submittedDate = date;
      }),
    resetHeaderList: () =>
      set((state) => {
        state.title = '';
        state.option = '';
        state.submittedDate = '';
      }),
  }))
);
