import React, { useContext, useEffect, useState } from 'react';
import { createContext } from 'use-context-selector';
import { useQuery } from 'react-query';
import _ from 'lodash';
import UserClient from '../../api/userAPIs';
import { getUserAttributes } from '../../utils/auth';
import { UserSettingsContext } from './UserSettingsContext';
import { useContextSelector } from 'use-context-selector';

interface ProductUnit {
  [key: string]: {
    unit: string;
    unitSection: string;
    defaultUnit?: string;
  };
}
export const ProductUnitsContext = createContext<ProductUnit>({} as any);

export const ProductUnitsContextProvider: React.FC = (props) => {
  const { userName, seastarId } = getUserAttributes();
  const userSettings = useContextSelector(
    UserSettingsContext,
    (state) => state.userSettings
  );
  const [productUnits, setProductUnits] = useState<ProductUnit | undefined>({});
  const getProductUnits = async () =>
    await UserClient.getProductUnits(userName, seastarId);
  const { data: units, refetch: unitsData } = useQuery<ProductUnit>(
    'ProductUnits',
    getProductUnits,
    {
      enabled: false,
      onSuccess: (data) => {
        _.forEach(data, (value, code) => {
          value.unit = _.cloneDeep(value.defaultUnit || '');
        });
        setProductUnits(data);
      },
    }
  );

  useEffect(() => {
    unitsData();
  }, []);

  const updateUnits = () => {
    _.forEach(units, (value, code) => {
      if (!(value.unitSection === ' ')) {
        value.unit = userSettings.general
          ? userSettings.general[value.unitSection || '']
          : '';
      }
    });
    setProductUnits(units);
  };

  useEffect(() => {
    updateUnits();
  }, [
    userSettings.general?.unitsTemperature,
    userSettings.general?.unitsDepth,
    userSettings.general?.unitsHeight,
  ]);
  return (
    <ProductUnitsContext.Provider value={productUnits || {}}>
      {props.children}
    </ProductUnitsContext.Provider>
  );
};
