import { Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ToolTipProps } from './ToolTipTypes';

export const ToolTip: React.FC<ToolTipProps> = ({
  title = '',
  placement = 'left',
  children,
  color = '#fff',
  radius = 10,
  bgColor = '#000000 !important',
  index = 0,
}) => {
  const IconTooltip = withStyles({
    tooltip: {
      maxWidth: '500px',
      color: color,
      backgroundColor: bgColor,
      borderRadius: radius,
      marginRight: index % 2 === 0 ? '0px' : '100px !important',
    },
  })(Tooltip);

  return (
    <IconTooltip
      title={title}
      placement={placement}
      children={children}
    ></IconTooltip>
  );
};
