import _ from 'lodash';
import { UserSettings } from '../../../types';
import { ContourService } from './ContourService';
import { FillAreaService } from './FillAreaService';
import { removeLayerSource } from '../../HelperService';
import { RasterService } from './RasterService';
import { VectorService } from './VectorService';
import { WeatherProductService } from './WeatherProductService';

const FillProducts = ['FAI'];

export class ProductTileService {
  public static addMapLayer = (
    map: mapboxgl.Map,
    code: string,
    type: string,
    tileData: any,
    displayConfig: any,
    settings: UserSettings,
    units: any,
    addMapLayerId: any,
    layerOrder: any,
    setLoading: any
  ) => {
    if (FillProducts.includes(code)) {
      FillAreaService.drawFillProduct(
        map,
        code,
        type,
        tileData,
        addMapLayerId,
        layerOrder,
        setLoading
      );
    } else {
      type === 'raster' &&
        RasterService.addRasterLayer(
          map,
          code,
          tileData,
          settings,
          addMapLayerId,
          layerOrder,
          setLoading
        );
      type === 'contour' &&
        ContourService.addContourLayer(
          map,
          code,
          tileData,
          settings,
          units,
          displayConfig,
          addMapLayerId,
          layerOrder,
          setLoading
        );
      type === 'vector' &&
        VectorService.addVectorLayer(
          map,
          code,
          tileData,
          displayConfig,
          settings,
          addMapLayerId,
          layerOrder,
          setLoading
        );
      type === 'weather' &&
        WeatherProductService.addWeatherLayer(
          map,
          code,
          tileData,
          addMapLayerId,
          layerOrder,
          setLoading
        );
    }
  };

  public static removeMapLayer = (
    map: mapboxgl.Map,
    code: string,
    type: string
  ) => {
    let layerKey = code + type;
    let layers = [layerKey];
    type === 'contour' && layers.push(code + 'contourLabels');
    if (type === 'weather' && code === 'FNT')
      layers = [
        ...layers,
        code + 'weatherIcons',
        code + 'weathericonsOccluded',
      ];
    if (type === 'weather' && code === 'STORM')
      layers = [...layers, code + 'weatherIcons'];
    removeLayerSource(map, layerKey, layers);
  };
}
