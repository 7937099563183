import { QueryClient, QueryClientProvider } from 'react-query';
import { Outlet } from 'react-router-dom';
import { initDB } from 'react-indexed-db';
import { RegionContextProvider } from '../Contexts/RegionContext';
import { ProductUnitsContextProvider } from '../Contexts/UnitsContext';
import { UserSettingsContextProvider } from '../Contexts/UserSettingsContext';
import SideNav from '../Router/SideNav';

initDB({
  name: 'seastar-platform',
  version: 1,
  objectStoresMeta: [
    {
      store: 'cursorData',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'name', keypath: 'name', options: { unique: false } },
        { name: 'data', keypath: 'data', options: { unique: false } },
      ],
    },
  ],
});
const PrivateHome: React.FC = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <RegionContextProvider>
        <UserSettingsContextProvider>
          <ProductUnitsContextProvider>
            <SideNav />
            <Outlet />
          </ProductUnitsContextProvider>
        </UserSettingsContextProvider>
      </RegionContextProvider>
    </QueryClientProvider>
  );
};

export default PrivateHome;
