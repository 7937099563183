import React from 'react';
import Grid from '@mui/material/Grid';
import { AppBar, Toolbar } from '@mui/material';
import { HeaderProps } from './HeaderTypes';
import './Header.scss';

export const Header: React.FC<HeaderProps> = (props) => {
  return (
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar disableGutters>
        <div className="header">
          <Grid container spacing={4}>
            <Grid item md={6} lg={6} xs={6}>
              {props.leftElement}
            </Grid>
            <Grid display="flex" item md={6} lg={6} xs={6}>
              <Grid container justifyContent={'flex-end'}>
              {props.centerElement}
              {props.rightElement}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Toolbar>
    </AppBar>
  );
};
