import { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { isEmpty } from 'lodash';
import { Box } from '@mui/material';
import moment from 'moment';
import { useIntl } from 'react-intl';
import ProductClient from '../../../api/productAPIs';
import MasterCastClient from '../../../api/masterCastAPIs';
import { ProgressSpinner } from '../../../components/ProgressSpinner/ProgressSpinner';
import Snackbar from '../../../components/Snackbar/Snackbar';
import { I18nKey } from '../../../translations/I18nKey';
import { getUserAttributes } from '../../../utils/auth';
import { UserSettingsContext } from '../../Contexts/UserSettingsContext';
import { Geojson } from '../../types';
import { MapContext } from '../MapContext';
import { FillAreaService } from '../Products/MapLayers/FillAreaService';
import { removeLayerSource, stringToColorConverter } from '../HelperService';
import { reccMetaData } from './config';
import { RecommendationLegends } from './Recommendation/RecommendationLegend';
import { AnalysisRecommendationPopup } from './Recommendation/AnalysisRecommendationPopup';
import { TripsContainer } from './Trips/TripsContainer';
import { MapMainStyles, psdbStyle } from '../MapStyles';
import { MastercastSetting } from './Mastercast/MasterCastSetting';
import { LayoutContext } from '../LayoutContext';
import { useContextSelector } from 'use-context-selector';
import { OtherFeaturesContext } from '../OtherFeaturesContext';

export const AnalysisContainer: React.FC<any> = ({ openSettings }) => {
  const intl = useIntl();
  const userName = getUserAttributes().userName;

  const layoutState = useContextSelector(
    LayoutContext,
    (state) => state.layoutState
  );
  const userSettings = useContextSelector(
    UserSettingsContext,
    (state) => state.userSettings
  );
  const removeMapLayerId = useContextSelector(
    MapContext,
    (state) => state.removeMapLayerId
  );
  const region = userSettings.map?.regionsOfInterest || '';
  const date =
    sessionStorage.getItem('date') ||
    `${moment(new Date()).format('YYYYMMDD')}`;
  const displayConfig = useContextSelector(
    OtherFeaturesContext,
    (state) => state.displayConfig
  );
  const setClickedProduct = useContextSelector(
    OtherFeaturesContext,
    (state) => state.setClickedProduct
  );
  const map = useContextSelector(MapContext, (state) => state.map);
  const selectedDate = useContextSelector(
    MapContext,
    (state) => state.selectedDate
  );

  const addMapLayerId = useContextSelector(
    MapContext,
    (state) => state.addMapLayerId
  );

  const layerOrder = useContextSelector(
    MapContext,
    (state) => state.layerOrder
  );
  const [mcSettingsApplied, setmcSettingsApplied] = useState(1);
  const [recommendationLayerList, setRecommendationLayerList] = useState(
    [] as any
  );

  const [recommendationSettings, setRecommendationSettings] = useState(
    [] as any
  );
  const [reccLastUpdated, setReccLastUpdated] = useState('');
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    type: '',
    display: false,
    message: '',
  });
  const [showRecommendationsSettings, setShowRecommendationSettings] =
    useState<boolean>(false);
  const [mastercastSettingData, setMastercastSettings] = useState<any>();

  useEffect(() => {
    getMastercastDefaults();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      loadMasterCast();
      loadRecommendation();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (userSettings.map?.regionsOfInterest) {
      loadMasterCast();
      loadRecommendation();
    }
  }, [userSettings.map?.regionsOfInterest]);

  useEffect(() => {
    loadRecommendation();
  }, [displayConfig?.data?.analysis?.items.recommendations.selected]);

  useEffect(() => {
    loadMasterCast();
  }, [
    displayConfig?.data?.analysis?.items.mastercast.selected,
    mcSettingsApplied,
  ]);

  const loadRecommendation = () => {
    removeRecommendations();
    if (displayConfig?.data?.analysis?.items.recommendations.selected) {
      setLoading(true);
      getRecommendationData();
    } else {
      setShowRecommendationSettings(false);
    }
  };

  const removeRecommendations = () => {
    if (!isEmpty(recommendationLayerList)) {
      recommendationLayerList.forEach((layer: any) => {
        if (map) {
          removeLayerSource(map, layer.name, [layer.name]);
          removeMapLayerId('recommendation', layer.name);
        }
      });
      setRecommendationLayerList([]);
    }
  };

  const getMastercastDefaults = async () => {
    try {
      const settings = await MasterCastClient.getMastercastDefaults();
      if (!_.isEmpty(settings)) setMastercastSettings(settings);
    } catch (error) {
      console.error(error);
      displayAlert('error', I18nKey.ERROR_MESSAGE_GET);
    }
  };

  const loadMasterCast = () => {
    if (map) {
      removeLayerSource(map, 'mastercast', ['mastercast']);
      removeMapLayerId('mastercast', 'mastercast');
    }
    if (displayConfig?.data?.analysis?.items.mastercast.selected) {
      setLoading(true);
      getMasterCastData();
    }
  };

  useEffect(() => {
    if (mcSettingsApplied > 1)
      displayAlert('success', I18nKey.USER_SETTINGS_SAVE_SUCCESS);
  }, [mcSettingsApplied]);

  const getRecommendationData = async () => {
    try {
      const data = await ProductClient.getRecommendations(
        userName,
        date,
        region,
        'all',
        'recommendation',
        -1,
        true
      );
      if (!isEmpty(data)) {
        setReccLastUpdated(data.begin_effective_time);
        _.map(data, (url, fish) => {
          if (!reccMetaData.includes(fish)) {
            const color = stringToColorConverter(fish.toLowerCase());
            setRecommendationLayerList((prevState: any[]) => [
              ...prevState,
              {
                name: fish,
                color: color,
                visible: true,
              },
            ]);
            getRecommendationGeojson(fish, url, color);
          }
        });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setClickedProduct('analysis', 'recommendations');
      console.log({ error });
      if (error.response.status === 400)
        displayAlert('error', I18nKey.ERROR_MESSAGE_UNAVAILABLE_FOR_REGION);
      else {
        displayAlert('error', I18nKey.ERROR_MESSAGE_GET);
      }
    }
  };

  const getRecommendationGeojson = async (
    fish: string,
    url: string,
    color: string
  ) => {
    const data = await axios.get(url);
    if (data.data) {
      map &&
        FillAreaService.addFillAreaLayer(
          map,
          fish,
          data.data,
          true,
          {
            'fill-color': color,
          },
          true,
          'recommendation',
          false,
          {},
          addMapLayerId,
          layerOrder
        );
    }
  };

  const displayAlert = (type: string, messageKey: any) => {
    setAlert({
      type: type,
      display: true,
      message: intl.formatMessage({
        id: messageKey,
      }),
    });
    setTimeout(() => {
      setAlert({
        type: '',
        display: false,
        message: '',
      });
    }, 5000);
  };

  const onCloseRecommendation = () => {
    setClickedProduct('analysis', 'recommendations');
    removeRecommendations();
    setShowRecommendationSettings(false);
  };

  const onRecommendationLegendSettings = () => {
    setShowRecommendationSettings((prevState) => !prevState);
  };

  const onRecommendationLegendSettingsApply = () => {
    onRecommendationLegendSettings();
    recommendationLayerList.forEach((layer: any) => {
      if (map) {
        map.setLayoutProperty(
          layer.name,
          'visibility',
          layer.visible === true ? 'visible' : 'none'
        );
      }
    });

    setRecommendationSettings(recommendationLayerList);
  };

  const onCloseTrips = () => {
    setClickedProduct('analysis', 'tripData');
  };

  const closeMastecastSetting = () => {
    setClickedProduct('analysis', 'mastercastSettings');
  };

  const getMasterCastData = async () => {
    try {
      const masterCastGeojson = await MasterCastClient.generateMasterCast(
        date,
        region
      );
      if (!isEmpty(masterCastGeojson.features)) {
        drawMasterCastLayer(
          masterCastGeojson,
          mastercastSettingData.displaySettings
        );
      } else {
        displayAlert('error', I18nKey.MASTERCAST_SETTINGS_NO_DATA_MSG);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      displayAlert('error', I18nKey.ERROR_MESSAGE_GET);
    }
  };

  const drawMasterCastLayer = (
    masterCastGeojson: Geojson,
    displaySettings: { width: any; color: any; opacity: any }
  ) => {
    map && removeLayerSource(map, 'mastercast', ['mastercast']);
    addMapLayerId('mastercast', 'mastercast');
    map?.addSource('mastercast', {
      type: 'geojson',
      data: masterCastGeojson,
    });
    map?.addLayer(
      {
        id: 'mastercast',
        source: 'mastercast',
        type: 'circle',
        paint: {
          'circle-radius': displaySettings.width,
          'circle-color': displaySettings.color,
          'circle-opacity': parseInt(displaySettings.opacity) / 100,
        },
      },
      layerOrder('mastercast')
    );
  };
  return (
    <>
      {loading && <ProgressSpinner showSpinner={loading} />}
      {alert.display && (
        <Snackbar
          type={alert.type}
          display={alert.display}
          message={alert.message}
        ></Snackbar>
      )}
      {displayConfig?.data?.analysis?.items.mastercastSettings.selected && (
        <Box
          sx={
            //TODO: change this code using LayoutContext/any other alternative
            openSettings
              ? psdbStyle(layoutState.Depth).openSetting
              : psdbStyle(layoutState.Depth).closeSetting
          }
        >
          <MastercastSetting
            mastercastSettingData={mastercastSettingData}
            setMastercastSettings={setMastercastSettings}
            setmcSettingsApplied={setmcSettingsApplied}
            closeMastecastSetting={closeMastecastSetting}
            displayAlert={displayAlert}
          />
        </Box>
      )}

      <Box
        sx={[
          { position: 'absolute' },
          layoutState.ProductLegend ? { bottom: '26%' } : { bottom: '5%' },
          layoutState.AISLegend &&
          layoutState.AISnVMSTracks &&
          layoutState.ProductLegend
            ? { top: '85px' }
            : {},
          layoutState.Depth &&
          layoutState.AISLegend &&
          layoutState.AISnVMSTracks
            ? { left: '14%' }
            : { left: '4%' },
        ]}
      >
        {displayConfig?.data?.analysis?.items.recommendations.selected && (
          <RecommendationLegends
            reccLastUpdated={reccLastUpdated}
            onRecommendationSettings={onRecommendationLegendSettings}
            recommendationData={recommendationLayerList}
            recommendationSettingsData={recommendationSettings}
            onCloseSettings={onCloseRecommendation}
          />
        )}
      </Box>
      {showRecommendationsSettings && (
        <Box
          sx={
            openSettings
              ? MapMainStyles.openSetting
              : MapMainStyles.closeSetting
          }
        >
          <AnalysisRecommendationPopup
            recommendationLayerList={recommendationLayerList}
            setRecommendationLayerList={setRecommendationLayerList}
            onClose={onRecommendationLegendSettings}
            onRecommendationLegendSettingsApply={
              onRecommendationLegendSettingsApply
            }
          />
        </Box>
      )}

      {displayConfig?.data?.analysis?.items.tripData.selected && (
        <Box
          sx={
            openSettings
              ? MapMainStyles.openSetting
              : MapMainStyles.closeSetting
          }
        >
          <TripsContainer onClose={onCloseTrips} />
        </Box>
      )}
    </>
  );
};
