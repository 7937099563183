import { isNil } from 'lodash';
import React, { useState, useEffect } from 'react';
import { FeaturesContext } from './FeaturesContext';
import { createContext, useContextSelector } from 'use-context-selector';
import {
  getSelectedProductsFromStorage,
  isAnyProductSelected,
} from './HelperService';

interface LayoutStateType {
  AISLegend: boolean;
  AISnVMSTracks: boolean;
  Recommendations: boolean;
  ProductLegend: boolean;
  Depth: boolean;
}

interface LayoutContextType {
  layoutState: LayoutStateType;
  setLayoutState: any;
  recommendationLength: number;
  setRecommendationLength: any;
}

export const LayoutContext = createContext<LayoutContextType>({
  layoutState: {
    AISLegend: false,
    AISnVMSTracks: false,
    Recommendations: false,
    ProductLegend: false,
    Depth: false,
  },
  setLayoutState: undefined,
  recommendationLength: 0,
  setRecommendationLength: undefined,
});

export const LayoutProvider: React.FC = (props) => {
  const [layoutState, setLayoutState] = useState<LayoutStateType>({
    AISLegend: false,
    AISnVMSTracks: false,
    Recommendations: false,
    ProductLegend: false,
    Depth: false,
  });
  const [recommendationLength, setRecommendationLength] = useState<number>(4);

  const displayConfig = useContextSelector(
    FeaturesContext,
    (state) => state.displayConfig
  );
  const tileData = useContextSelector(
    FeaturesContext,
    (state) => state.tileData
  );
  const selectedDepthProducts = useContextSelector(
    FeaturesContext,
    (state) => state.selectedDepthProducts
  );
  useEffect(() => {
    setLayoutState((prevState) => ({
      ...prevState,
      Recommendations:
        displayConfig?.data?.analysis?.items?.recommendations?.selected,
    }));
  }, [displayConfig?.data?.analysis?.items?.recommendations?.selected]);

  useEffect(() => {
    const data = getSelectedProductsFromStorage();
    if (!isNil(data)) {
      if (
        data?.contour.length > 0 ||
        data?.raster.length > 0 ||
        data?.vector.length > 0
      ) {
        setLayoutState((prevState) => ({ ...prevState, ProductLegend: true }));
      } else {
        setLayoutState((prevState) => ({ ...prevState, ProductLegend: false }));
      }
    }
  }, [tileData]);

  useEffect(() => {
    setLayoutState((prevState) => ({
      ...prevState,
      AISLegend: displayConfig?.data?.IUU?.items?.AIS?.selected,
    }));
  }, [displayConfig?.data?.IUU?.items?.AIS?.selected]);

  useEffect(() => {
    if (
      isAnyProductSelected(selectedDepthProducts) &&
      layoutState.ProductLegend
    ) {
      setLayoutState((prevState) => ({ ...prevState, Depth: true }));
    } else {
      setLayoutState((prevState) => ({ ...prevState, Depth: false }));
    }
  }, [selectedDepthProducts, layoutState.ProductLegend]);

  return (
    <LayoutContext.Provider
      value={{
        layoutState,
        setLayoutState,
        recommendationLength,
        setRecommendationLength,
      }}
    >
      {props.children}
    </LayoutContext.Provider>
  );
};
