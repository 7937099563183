export const DateRangeStyles: any = {
  dateLabelBox: {
    background: '#fff',
    cursor: 'pointer',
    padding: '5px 10px',
    width: '100%',
  },
  dateLabelTypograpghy: {
    display: 'inline',
    padding: '2px 0px 0px 4px',
    fontSize: '12px',
    width: 'max-content',
  },
};
