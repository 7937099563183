import { useEffect, useState } from 'react';
import { FishCatchDashboardService } from '../../../FishCatchDashboard/FishCatchDashboardService';
import { FishCatchData } from '../../../types';
import AutoComplete from '../../../../components/AutoComplete/AutoComplete';
import {
  Box,
  Divider,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { I18nKey } from '../../../../translations/I18nKey';
import {
  IUU_SETTINGS_FISHCATCH_COLUMNS,
  durationListForVesselBuoys,
} from '../config';
import { useIntl } from 'react-intl';
import { AISStyles } from './AISnVMS/AISnVMSStyles';
import { getArrayOfObjects, getListFromArray } from '../IUUService';
import { BUOY_VESSEL_DATE_DURATION } from './VesselBuoyConfig';
import { useVesselUploadStyles } from './VesselBuoyStyles';
import { VesselTrackingService } from '../VesselTrackingService';

const FishCatchFilters: React.FC<any> = ({
  data,
  applyFilter,
  setApplyFilter,
  onApplyFilter,
}) => {
  const intl = useIntl();
  const [species, setSpecies] = useState([{}]);
  const [vessels, setVessels] = useState([{}]);
  const [fishingTechniques, setFishingTechniques] = useState([{}]);
  const [selectedVessel, setSelectedVessel] = useState<any>([]);
  const [selectedSpecies, setSelectedSpecies] = useState<any>([]);
  const [selectedFishingTechnique, setSelectedFishingTechnique] = useState<any>(
    []
  );
  const [dataDuration, setDataDuration] = useState<string>(
    JSON.parse(localStorage.getItem('fishCatchFilter') || '{}')
      ?.dataDurationValue || BUOY_VESSEL_DATE_DURATION
  );
  const vesselUploadClasses: any = useVesselUploadStyles();

  useEffect(() => {
    const filters = JSON.parse(localStorage.getItem('fishCatchFilter') || '{}');
    if (filters && filters.vessels.length) {
      setSelectedVessel(getArrayOfObjects(filters.vessels, 'vessel'));
    }
    if (filters && filters.species.length) {
      setSelectedSpecies(getArrayOfObjects(filters.species, 'name'));
    }
    if (filters && filters.fishingTechniques.length) {
      setSelectedFishingTechnique(
        getArrayOfObjects(filters.fishingTechniques, 'fishingTechnique')
      );
    }
    if (filters.dataDurationValue) {
      setDataDuration(filters.dataDurationValue);
    }
  }, []);

  useEffect(() => {
    setFilterData(data);
  }, [data]);

  const getDates = () => {
    let dates = {};
    if (parseInt(dataDuration) === 0) {
      dates = { toDate: null, fromDate: null };
    } else {
      dates = VesselTrackingService.getTrackDates(parseInt(dataDuration));
    }
    return dates;
  };

  useEffect(() => {
    if (applyFilter) {
      let filterData: any = {
        vessels: getListFromArray(selectedVessel, 'vessel'),
        species: getListFromArray(selectedSpecies, 'name'),
        fishingTechniques: getListFromArray(
          selectedFishingTechnique,
          'fishingTechnique'
        ),
        dataDurationValue: dataDuration,
        dataDuration: getDates(),
      };
      localStorage.setItem('fishCatchFilter', JSON.stringify(filterData));
      onApplyFilter();
      setApplyFilter(false);
    }
  }, [applyFilter]);

  const setFilterData = (data: FishCatchData[]) => {
    let species = FishCatchDashboardService.getSpeciesList(data);
    setSpecies(species);
    let vessels = FishCatchDashboardService.getVesselsList(data);
    setVessels(vessels);
    let fishingTechniques =
      FishCatchDashboardService.getFishingTechniqueList(data);
    setFishingTechniques(fishingTechniques);
  };
  const onVesselNameChange = (e: any) => {
    setSelectedVessel(e);
  };
  const onSpeciesChange = (e: any) => {
    setSelectedSpecies(e);
  };
  const onFishingTechniqueChange = (e: any) => {
    setSelectedFishingTechnique(e);
  };
  const onDateChange = (event: SelectChangeEvent) => {
    setDataDuration(event.target.value);
  };

  return (
    <>
      <Box className={vesselUploadClasses.dateWrapper}>
        <Typography
          className={vesselUploadClasses.dateDurationText}
          sx={{ mr: 1 }}
        >
          {intl.formatMessage({ id: I18nKey.IUU_DATA_DURATION })}
        </Typography>
        <FormControl
          className={vesselUploadClasses.dateFormControl}
          sx={{ m: 1 }}
          size="small"
        >
          <Select
            id="iuu-select"
            displayEmpty
            value={dataDuration}
            onChange={onDateChange}
            className={vesselUploadClasses.dateSelect}
          >
            {durationListForVesselBuoys.map((d) => {
              return (
                <MenuItem value={d.value}>
                  {intl.formatMessage({
                    id: d.name,
                  })}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <AutoComplete
        key={IUU_SETTINGS_FISHCATCH_COLUMNS.vesselColumnConfig[0].name}
        data={vessels}
        style={{
          ...AISStyles.autocompleteStyle,
          ...{ marginLeft: '-10px' },
        }}
        placeholder={intl.formatMessage({
          id: I18nKey.FISH_CATCH_FILTER_VESSEL,
        })}
        columnConfig={IUU_SETTINGS_FISHCATCH_COLUMNS.vesselColumnConfig}
        onSelectionChange={onVesselNameChange}
        showSelectAllToggle={false}
        selectionData={selectedVessel}
        multiValueSeparator=" "
      />
      <Divider sx={{ borderColor: '#333333', my: 2 }} flexItem></Divider>
      <AutoComplete
        key={IUU_SETTINGS_FISHCATCH_COLUMNS.speciesColumnConfig[0].name}
        data={species}
        style={{
          ...AISStyles.autocompleteStyle,
          ...{ marginLeft: '-10px' },
        }}
        placeholder={intl.formatMessage({
          id: I18nKey.FISH_CATCH_FILTER_SPECIES,
        })}
        columnConfig={IUU_SETTINGS_FISHCATCH_COLUMNS.speciesColumnConfig}
        onSelectionChange={onSpeciesChange}
        showSelectAllToggle={false}
        selectionData={selectedSpecies}
        multiValueSeparator=" "
      />
      <Divider sx={{ borderColor: '#333333', my: 2 }} flexItem></Divider>
      <AutoComplete
        key={
          IUU_SETTINGS_FISHCATCH_COLUMNS.fishingTechniqueColumnConfig[0].name
        }
        data={fishingTechniques}
        style={{
          ...AISStyles.autocompleteStyle,
          ...{ marginLeft: '-10px' },
        }}
        placeholder={intl.formatMessage({
          id: I18nKey.FISH_CATCH_FILTER_FISHING_TECHNIQUE,
        })}
        columnConfig={
          IUU_SETTINGS_FISHCATCH_COLUMNS.fishingTechniqueColumnConfig
        }
        onSelectionChange={onFishingTechniqueChange}
        showSelectAllToggle={false}
        selectionData={selectedFishingTechnique}
        multiValueSeparator=" "
      />
    </>
  );
};
export default FishCatchFilters;
