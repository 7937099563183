import { Box, Slider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../../translations/I18nKey';
import { DepthMarks } from '../MockData/MockData';
import { DepthSliderStyles } from '../SliderStyles';
import { DepthSliderProps } from '../SliderTypes';
import { useContextSelector } from 'use-context-selector';
import { UserSettingsContext } from '../../../containers/Contexts/UserSettingsContext';
import { convertToUnit } from '../../../containers/Map/HelperService';
import _ from 'lodash';

export const DepthSlider: React.FC<DepthSliderProps> = ({
  style,
  selectedValue,
  defaultValue,
  steps,
  marks,
  onChangeHandler,
  onChangeCommittedHandler,
}) => {
  const intl = useIntl();
  const userSettings = useContextSelector(
    UserSettingsContext,
    (state) => state.userSettings
  );
  const [depthUnitConverted, setDepthUnitConverted] = useState<any>(marks);

  useEffect(() => {
    setDepthUnitConverted(() =>
      marks.map((d: any) => {
        return {
          ...d,
          ...{
            label: String(
              convertToUnit(
                Number(d.label),
                'TCD',
                1,
                userSettings.general?.unitsDepth || ''
              )
            ),
          },
        };
      })
    );
  }, [userSettings.general?.unitsDepth]);

  return (
    <Box height={245}>
      <Box height={30} width={30} sx={DepthSliderStyles.depth}>
        {`${intl.formatMessage({
          id: I18nKey.DEPTH,
        })}(${userSettings.general?.unitsDepth})`}
      </Box>
      {/* <Box width={137} height={17} sx={DepthSliderStyles.clickToDepth}>
        {intl.formatMessage({
          id: I18nKey.CLICK_HERE_TO_CHANGE_DEPTH,
        })}
      </Box> */}
      <Box sx={DepthSliderStyles.depthSliderBox}>
        <Slider
          defaultValue={defaultValue}
          value={selectedValue}
          sx={DepthSliderStyles.sliderStyles}
          orientation="vertical"
          step={steps}
          track={'inverted'}
          marks={depthUnitConverted}
          max={marks[0].value}
          min={marks[marks.length - 1].value}
          onChange={onChangeHandler}
          onChangeCommitted={onChangeCommittedHandler}
          id={'depth-slider'}
        />
      </Box>
    </Box>
  );
};

DepthSlider.defaultProps = {
  selectedValue: 30,
  defaultValue: 40,
  marks: DepthMarks,
};

export default DepthSlider;
