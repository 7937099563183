import React from 'react';
import { Slider } from '@mui/material';
import {
  getDateFormat,
  getTimelineMarks,
  timeLineDefaultValue,
} from '../MockData/MockData';
import { TimeLineSliderStyles } from '../SliderStyles';
import { TimelineSliderProps } from '../SliderTypes';

function valueLabelFormat(value: number) {
  return getDateFormat(value);
}

const TimelineSlider: React.FC<TimelineSliderProps> = ({
  style,
  selectedValue,
  defaultValue,
  marks,
  onChangeHandler,
  onChangeCommittedHandler,
}) => {
  return (
    <Slider
      sx={TimeLineSliderStyles.sliderStyles}
      step={20}
      defaultValue={defaultValue}
      max={marks[marks.length - 1].value}
      min={marks[0].value}
      value={selectedValue}
      valueLabelFormat={valueLabelFormat}
      marks={marks}
      valueLabelDisplay="on"
      disableSwap={true}
      onChange={onChangeHandler}
      onChangeCommitted={onChangeCommittedHandler}
    />
  );
};

TimelineSlider.defaultProps = {
  defaultValue: [...timeLineDefaultValue()],
  marks: getTimelineMarks(31, 7),
};

export default TimelineSlider;
