import axios, { AxiosRequestConfig } from 'axios';
import _ from 'lodash';
import { isEmpty, isNil } from 'lodash';
import {
  BreachVesselDetails,
  GeofenceDetails,
  shapeFileDetails,
  AISData,
  AISDataAPIParams,
  VesselTrackDetails,
  vesselBuoyAPIParams,
} from '../containers/types';

const SERVER_BASE_PATH = process.env.REACT_APP_PRODUCT_SERVER;
const AIS_SERVER_BASE_PATH = process.env.REACT_APP_AIS_SERVER;
// const AIS_SERVER_BASE_PATH = process.env.REACT_APP_AIS_SERVER;

export const PATHS = Object.freeze({
  GET_BREACH_DETAILS: `breaching/getVessels`,
  GET_GEOFENCE_DETAILS: `geofencing/get`,
  GET_VESSELTRACK_DETAILS: (vesselSource: string) =>
    `iuu/vesselDetails/${vesselSource}`,
  GET_ALL_VESSELS_DATA: (vesselSource: string) =>
    `iuu/vesselData/${vesselSource}`,
  UPLOAD_VESSEL: 'vessel/upload',
  UPLOAD_BUOYS: 'buoys/upload',
  GET_VESSEL_TRACKS: `vessel/track`,
  GET_BUOY_TRACKS: `buoys/track`,
  GET_BUOY_DATA: 'buoys/',
  GET_VESSEL_DATA: 'vessel/',
  UPLOAD_SHAPE_FILE: `geofencing/uploadShapeFile`,
  SAVE_GEOFENCE: `geofencing/save`,
  DELETE_GEOFENCE: `geofencing/delete`,
  SAVE_GEOFENCE_AVOID_LIST: `geofencing/saveAvoidList`,
  GENERATE_PRODUCT_LAYERS: `productLayers/generate_product_layer`,
  BUOY_FILES: `buoys/files`,
  VESSELS_FILES: `vessel/files`,
});

export const getBreachDetails = async (
  userName: string,
  fromDate: string,
  toDate: string
): Promise<BreachVesselDetails[]> => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: { user: userName, date_from: fromDate, date_to: toDate },
    };
    const { data: breachedVesselDetails } = await axios.get<
      BreachVesselDetails[]
    >(SERVER_BASE_PATH + PATHS.GET_BREACH_DETAILS, config);

    return breachedVesselDetails;
  } catch (error) {
    throw error;
  }
};

export const getGeofenceDetails = async (
  userName: string,
  region: string,
  geofenceId?: string,
  active?: boolean
): Promise<GeofenceDetails[]> => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: { user: userName, region: region },
    };
    if (!isNil(geofenceId) || !isEmpty(geofenceId)) {
      config = { ...config, params: { geofence_id: geofenceId } };
    }
    if (!isNil(active)) {
      config = { ...config, params: { active: active } };
    }
    const { data: geofenceDetails } = await axios.get<GeofenceDetails[]>(
      SERVER_BASE_PATH + PATHS.GET_GEOFENCE_DETAILS,
      config
    );
    return geofenceDetails;
  } catch (error) {
    throw error;
  }
};

export const getVesselTrackDetails = async (
  userName: string,
  fromDate: string,
  toDate: string,
  vesselId: string,
  vesselSource: string
): Promise<VesselTrackDetails> => {
  let config: AxiosRequestConfig = {};
  config = {
    ...config,
    params: {
      user: userName,
      from_date: fromDate,
      to_date: toDate,
      vessel_id: vesselId,
    },
  };
  const { data: vesselTrackData } = await axios.get<VesselTrackDetails>(
    AIS_SERVER_BASE_PATH + PATHS.GET_VESSELTRACK_DETAILS(vesselSource),
    config
  );
  return vesselTrackData;
};

export const getAllAISData = async (
  aisParams: AISDataAPIParams
): Promise<AISData[]> => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: {
        user: aisParams.userName,
        from_date: aisParams.fromDate,
        to_date: aisParams.toDate,
        bottom_left: aisParams.bottomLeft,
        top_right: aisParams.topRight,
        region: aisParams.region,
      },
    };
    const optionalParams: Record<string, any> = {
      vesselName: aisParams.vesselName,
      vesselType: aisParams.vesselType,
      groupID: aisParams.groupID,
      speedTo: aisParams.speedTo,
      country: aisParams.country,
      speedFrom: aisParams.speedFrom,
      vesselId: aisParams.vesselId,
      operator: aisParams.operator,
      latest: aisParams.latest,
    };

    config.params = { ...config.params, ..._.omitBy(optionalParams, isNil) };
    const { data: vesselsData } = await axios.get<any[]>(
      AIS_SERVER_BASE_PATH + PATHS.GET_ALL_VESSELS_DATA(aisParams.vesselSource),
      config
    );
    return vesselsData;
  } catch (error) {
    throw error;
  }
};

export const getAllBuoysData = async (
  buoyParams: vesselBuoyAPIParams
): Promise<any[]> => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: {
        from_date: buoyParams.fromDate,
        to_date: buoyParams.toDate,
      },
    };
    const { data: buoyData } = await axios.get<any[]>(
      AIS_SERVER_BASE_PATH + PATHS.GET_BUOY_DATA,
      config
    );
    return buoyData;
  } catch (error) {
    throw error;
  }
};

export const getBuoysFiles = async (): Promise<any[]> => {
  try {
    const { data: buoyFilesData } = await axios.get<any[]>(
      AIS_SERVER_BASE_PATH + PATHS.BUOY_FILES
    );
    return buoyFilesData;
  } catch (error) {
    throw error;
  }
};

export const deleteBuoysFiles = async (fileIds: any[]): Promise<any[]> => {
  let config: AxiosRequestConfig = {
    data: {
      file_ids: fileIds,
    },
  };
  try {
    const { data: deleteBuoyFile } = await axios.delete<any[]>(
      AIS_SERVER_BASE_PATH + PATHS.BUOY_FILES,
      config
    );
    return deleteBuoyFile;
  } catch (error) {
    throw error;
  }
};

export const getVesselFiles = async (): Promise<any[]> => {
  try {
    const { data: deleteVesselFiles } = await axios.get<any[]>(
      AIS_SERVER_BASE_PATH + PATHS.VESSELS_FILES
    );
    return deleteVesselFiles;
  } catch (error) {
    throw error;
  }
};

export const deleteVesselFiles = async (fileIds: any[]): Promise<any[]> => {
  let config: AxiosRequestConfig = {
    data: {
      file_ids: fileIds,
    },
  };
  try {
    const { data: deleteVesselFiles } = await axios.delete<any[]>(
      AIS_SERVER_BASE_PATH + PATHS.VESSELS_FILES,
      config
    );
    return deleteVesselFiles;
  } catch (error) {
    throw error;
  }
};

export const getAllVesselsData = async (
  vesselParams: vesselBuoyAPIParams
): Promise<any[]> => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: {
        from_date: vesselParams.fromDate,
        to_date: vesselParams.toDate,
      },
    };
    const { data: vesselData } = await axios.get<any[]>(
      AIS_SERVER_BASE_PATH + PATHS.GET_VESSEL_DATA,
      config
    );
    return vesselData;
  } catch (error) {
    throw error;
  }
};
export const uploadVesselBuoysFile = async (
  type: string,
  fileData: any,
  vesselName?: string
) => {
  let config: AxiosRequestConfig = {};
  config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };
  if (type === 'vessel') {
    config = {
      ...config,
      params: {
        vessel_name: vesselName,
      },
    };
  }
  let URL = AIS_SERVER_BASE_PATH;
  if (type === 'vessel') {
    URL += PATHS.UPLOAD_VESSEL;
  }
  if (type === 'buoy') {
    URL += PATHS.UPLOAD_BUOYS;
  }
  if (URL) {
    const { data: vesselFileDetails } = await axios.post<any>(
      URL,
      fileData,
      config
    );
    return vesselFileDetails;
  }
};

export const getVesselBuoyTrack = async (
  type: string,
  ids: string,
  toDate: string,
  fromDate: string
): Promise<any> => {
  let config: AxiosRequestConfig = {};
  let URL: any = AIS_SERVER_BASE_PATH;
  if (type === 'vessel') {
    URL += PATHS.GET_VESSEL_TRACKS;
    config = {
      ...config,
      params: {
        from_date: fromDate,
        to_date: toDate,
        vessel_id: ids,
      },
    };
  }
  if (type === 'buoy') {
    URL += PATHS.GET_BUOY_TRACKS;
    config = {
      ...config,
      params: {
        from_date: fromDate,
        to_date: toDate,
        buoys_id: ids,
      },
    };
  }
  const { data: trackData } = await axios.get<any>(URL, config);
  return trackData;
};

export const uploadShapeFile = async (userName: string, file: any) => {
  let config: AxiosRequestConfig = {};
  config = {
    params: { user: userName },
  };
  const { data: shapeFileDetails } = await axios.post<shapeFileDetails>(
    SERVER_BASE_PATH + PATHS.UPLOAD_SHAPE_FILE,
    file,
    config
  );
  return shapeFileDetails;
};

export const saveGeofence = async (
  userName: string,
  geofenceDetails: GeofenceDetails,
  geofenceId?: string
) => {
  let config: AxiosRequestConfig = {};
  config = {
    ...config,
    params: { user: userName },
  };
  if (!isNil(geofenceId) || !isEmpty(geofenceId)) {
    config.params = { ...config.params, ...{ geofence_id: geofenceId } };
  }
  const { data: saveGeofence } = await axios.post<any>(
    SERVER_BASE_PATH + PATHS.SAVE_GEOFENCE,
    geofenceDetails,
    config
  );
  return saveGeofence;
};

export const deleteGeofence = async (userName: string, geofenceId?: string) => {
  let config: AxiosRequestConfig = {};
  config = {
    ...config,
    params: { user: userName },
  };
  if (!isNil(geofenceId) || !isEmpty(geofenceId)) {
    config = {
      ...config,
      params: { ...config.params, geofence_id: geofenceId },
    };
  }
  const { data: deleteFence } = await axios.delete<any>(
    SERVER_BASE_PATH + PATHS.DELETE_GEOFENCE,
    config
  );
  return deleteFence;
};

export const saveAvoidAlertVessel = async (
  userName: string,
  geofenceDetails: any
) => {
  let config: AxiosRequestConfig = {};
  config = {
    ...config,
    params: { user: userName },
  };
  const { data: saveGeofenceAvoidList } = await axios.post<any>(
    SERVER_BASE_PATH + PATHS.SAVE_GEOFENCE_AVOID_LIST,
    geofenceDetails,
    config
  );
  return saveGeofenceAvoidList;
};

export const generateProductLayer = async (
  userName: string,
  date: string,
  region: string,
  product: string,
  product_type: string,
  defaultTile: boolean
) => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: {
        user: userName,
        date: date,
        region: region,
        product: product,
        product_type: product_type,
        default: defaultTile,
      },
    };
    const { data: layers } = await axios.post<any>(
      SERVER_BASE_PATH + PATHS.GENERATE_PRODUCT_LAYERS,
      {},
      config
    );
    return layers;
  } catch (error) {
    throw error;
  }
};
