import React, { useEffect, useRef, useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import { Box, Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { PopupFooter } from '../../../../components/SettingsPopup/PopupFooter/PopupFooter';
import { PopupHeader } from '../../../../components/SettingsPopup/PopupHeader/PopupHeader';
import { SettingsDraggablePopup } from '../../../../components/SettingsPopup/SettingsPopup';
import { I18nKey } from '../../../../translations/I18nKey';
import { tabIndicatorProps } from './MasterCastSettingsStyles';
import { MastercastSettingStyles } from './MasterCastSettingsStyles';
import MastercastRangeRules from './MastercastRangeRules';
import { Settings } from './Settings';
import { ProductUnitsContext } from '../../../Contexts/UnitsContext';
import { convert, convertToUnit } from '../../HelperService';
import { OtherFeaturesContext } from '../../OtherFeaturesContext';
import MasterCastClient from '../../../../api/masterCastAPIs';
import { CustomLinearProgress } from '../../../../components/CustomLinearProgress/CustomLinearProgress';
import _ from 'lodash';
import { UserSettingsContext } from '../../../Contexts/UserSettingsContext';

const useStyles = makeStyles(MastercastSettingStyles);

const getScaleMinMax = (product: any, productObj: any) => {
  return convert(
    productObj.min,
    productObj.max,
    product,
    productObj.options.precision,
    productObj.units
  );
};

const getScaleMark = (product: any, productObj: any, type: any) => {
  return convertToUnit(
    productObj.options[type],
    product,
    productObj.options.precision,
    productObj.units
  );
};

export const MastercastSetting: React.FC<any> = ({
  mastercastSettingData,
  setMastercastSettings,
  setmcSettingsApplied,
  closeMastecastSetting,
  displayAlert,
}) => {
  const intl = useIntl();
  const draggleRef = useRef<HTMLDivElement>();
  const [state, setState] = useState({
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, right: 0, bottom: 0 },
    showFooter: false,
  });
  const units = useContextSelector(ProductUnitsContext, (state) => state);
  const setClickedProduct = useContextSelector(
    OtherFeaturesContext,
    (state) => state.setClickedProduct
  );
  const closeProductsOnUnitChange = useContextSelector(
    UserSettingsContext,
    (state) => state.closeProductsOnUnitChange
  );
  const classes = useStyles();
  const [tabName, setTabName] = useState<string>('RangeRules');
  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabName(newValue);
  };
  const [disableConfig, setDisableConfig] = useState<any>({});
  const [selectedValue, setSelectedValue] = useState<any>({});
  const [markValues, setMarkValues] = useState<any>({});
  const [displaySettings, setDisplaySettings] = useState<any>({});

  useEffect(() => {
    if (!_.isEmpty(mastercastSettingData)) {
      mastercastSettingData?.rangeRules.forEach((product: any) => {
        product.units = units[product.id].unit;
      });

      setRangeProducts(mastercastSettingData);
      setDisplayProperties(mastercastSettingData);
    }
  }, [mastercastSettingData]);

  useEffect(() => {
    if (closeProductsOnUnitChange) {
      closeMastecastSetting();
    }
  }, [closeProductsOnUnitChange]);

  const setRangeProducts = (settings: any) => {
    let productDisabled: any = {};
    let productSelectedValue: any = {};
    let unitsValue: any = {};
    settings.rangeRules.forEach((r: any) => {
      productDisabled[r['id']] = r['select'];
      productSelectedValue[r['id']] =
        r['id'] === 'PLK' ? [r.min, r.max] : getScaleMinMax(r['id'], r);
      unitsValue[r['id']] = [
        {
          value:
            r['id'] === 'PLK'
              ? r.options.floor
              : getScaleMark(r['id'], r, 'floor'),
          label: `${getScaleMark(r['id'], r, 'floor')}`,
        },
        {
          value:
            r['id'] === 'PLK'
              ? r.options.ceil
              : getScaleMark(r['id'], r, 'ceil'),
          label: `${getScaleMark(r['id'], r, 'ceil')}`,
        },
      ];
    });
    setDisableConfig(productDisabled);
    setSelectedValue(productSelectedValue);
    setMarkValues(unitsValue);
  };

  const setDisplayProperties = (settings: any) => {
    setDisplaySettings(settings.displaySettings);
  };

  const onApply = async () => {
    let data: any = { range_rules: {}, display_settings: {} };
    Object.keys(selectedValue).forEach((product) => {
      let productObj = mastercastSettingData.rangeRules.find(
        (el: any) => el.id === product
      );
      productObj.min =
        product === 'PLK'
          ? selectedValue[product][0]
          : convertToUnit(
              selectedValue[product][0],
              product,
              productObj.options.precision,
              units[product]?.unit,
              units[product].defaultUnit
            );
      productObj.max =
        product === 'PLK'
          ? selectedValue[product][1]
          : convertToUnit(
              selectedValue[product][1],
              product,
              productObj.options.precision,
              productObj.units,
              units[product].defaultUnit
            );
      productObj.select = disableConfig[product];
    });
    mastercastSettingData.rangeRules.forEach((rule: any) => {
      data.range_rules[rule.id] = {
        min: rule.min,
        max: rule.max,
        select: rule.select,
      };
    });
    data.display_settings = displaySettings;

    try {
      const settings = await MasterCastClient.updateMastercastSettings(data);
      if (settings) {
        setMastercastSettings({
          ...mastercastSettingData,
          displaySettings,
        });
        setmcSettingsApplied((prevState: number) => prevState + 1);
      }
    } catch (error) {
      console.error(error);
      displayAlert('error', I18nKey.MASTERCAST_SETTINGS_SAVE_ERROR_MSG);
    }
  };

  return (
    <SettingsDraggablePopup
      popupStyle={MastercastSettingStyles.popup}
      headerElement={
        <PopupHeader
          title={I18nKey.MASTERCAST_SETTINGS}
          onClose={closeMastecastSetting}
          dataElement={<></>}
        ></PopupHeader>
      }
      bodyElement={
        <>
          <Box sx={{ width: '100%', m: 2 }}>
            <Box>
              <Tabs
                value={tabName}
                onChange={onTabChange}
                TabIndicatorProps={tabIndicatorProps}
              >
                <Tab
                  value="RangeRules"
                  label={intl.formatMessage({
                    id: I18nKey.MASTERCAST_SETTINGS_RANGE_RULES,
                  })}
                  classes={{
                    root: classes.root,
                    selected: classes.selected,
                  }}
                  sx={{ textTransform: 'none' }}
                />
                <Tab
                  value="Settings"
                  label={intl.formatMessage({
                    id: I18nKey.MASTERCAST_SETTINGS_SETTINGS,
                  })}
                  classes={{
                    root: classes.root,
                    selected: classes.selected,
                  }}
                  sx={{ textTransform: 'none' }}
                />
              </Tabs>
            </Box>
            <Box>
              <TabPanel name={tabName} index="RangeRules">
                {_.isEmpty(mastercastSettingData) ? (
                  <CustomLinearProgress />
                ) : (
                  <MastercastRangeRules
                    selectedValue={selectedValue}
                    disableConfig={disableConfig}
                    markValues={markValues}
                    setSelectedValue={setSelectedValue}
                    setDisableConfig={setDisableConfig}
                    mastercastSettingData={mastercastSettingData}
                  />
                )}
              </TabPanel>
              <TabPanel name={tabName} index="Settings">
                {_.isEmpty(mastercastSettingData) ? (
                  <CustomLinearProgress />
                ) : (
                  <Settings
                    settings={displaySettings}
                    setSettings={setDisplaySettings}
                  />
                )}
              </TabPanel>
            </Box>
          </Box>
        </>
      }
      footerElement={
        <PopupFooter
          actionButton={{
            text: I18nKey.SETTINGS_POPUP_APPLY,
            onClick: onApply,
          }}
        ></PopupFooter>
      }
      draggableRef={draggleRef}
      dragState={state}
      setDragState={setState}
    ></SettingsDraggablePopup>
  );
};

const TabPanel: React.FC<any> = (props) => {
  const { children, name, index } = props;
  return <>{name === index && <> {children} </>}</>;
};
