export const featureNavigationStyles = {
  sidePanel: {
    backgroundColor: 'black',
    float: 'right',
    width: '177px',
    padding: '19px',
    height: '1000vh',
    position: 'relative',
    overflow: 'auto'
  },
};
