import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Box } from '@mui/system';
import _, { floor } from 'lodash';
import { I18nKey } from '../../../../translations/I18nKey';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { ColorBox } from '../../../../components/ColorBox/ColorBox';
import { Stepper } from '../../../../components/Stepper/Stepper';
import { Wrapper } from '../../../../components/Wrapper/Wrapper';
import { productSettingDialogBoxStyles } from './ProductSettingsStyles';
import { FeaturesContext } from '../../FeaturesContext';
import ButtonSection from './ButtonSection';
import { useContextSelector } from 'use-context-selector';

const stylesArrCSSCUR = (color: string) => [
  {
    value: 'east',
    icon: (
      <MenuItem key={'east'} value="east">
        <ArrowUpwardIcon sx={{ color: color }} />
      </MenuItem>
    ),
  },
  {
    value: 'check',
    icon: (
      <MenuItem key={'arrow_right_alt'} value="arrow_right_alt">
        <ArrowRightAltIcon sx={{ transform: 'rotate(-90deg)', color: color }} />
      </MenuItem>
    ),
  },
];

const menuIcons = (color: string, product: any) => {
  let iconObj: any = {
    WIND: [
      {
        value: 'east',
        icon: (
          <MenuItem key={'arrow_right_alt'} value="arrow_right_alt">
            <CheckIcon sx={{ transform: 'rotate(180deg)', color: '#a3a29e' }} />
          </MenuItem>
        ),
      },
    ],
    CUR: stylesArrCSSCUR('#a3a29e'),
    CSS: stylesArrCSSCUR('#a3a29e'),
  };
  return iconObj[product];
};

const VectorSettings: React.FC<any> = ({ userData, tileData, product }) => {
  const intl = useIntl();
  const precision = 1;
  const getMapTileData = useContextSelector(
    FeaturesContext,
    (state) => state.getMapTileData
  );
  const saveDefault = useContextSelector(
    FeaturesContext,
    (state) => state.saveDefault
  );
  const [icon, setIcon] = useState(tileData?.style);
  const [color, setColor] = useState(tileData?.color);
  const [size, setSize] = useState(tileData?.size);

  const handleChange = (event: SelectChangeEvent) => {
    setIcon(event.target.value as string);
  };

  const onChangeColor = (color: any) => {
    setColor(color.hex);
  };

  const onChange = (event: any) => {
    setSize(floor(event.target.value, precision));
  };

  const onApplyClick = () => {
    const pdata: any = {
      color: color,
      style: icon,
      size: size,
    };
    const tdata: any = {
      color: tileData?.color,
      style: tileData?.style,
      size: tileData?.size,
    };
    if (!_.isEqual(pdata, tdata)) {
      let selectedProd: any = {};
      selectedProd['vector'] = [product];
      getMapTileData(selectedProd, false, false, pdata);
    }
  };

  const onSaveDefault = () => {
    const pdata = {
      color: color,
      size: size,
      style: icon,
    };
    saveDefault(product, 'vector', pdata);
  };

  const onRestore = () => {
    setIcon(userData?.user?.style);
    setColor(userData?.user?.color);
    setSize(userData?.user?.size);
  };

  return (
    <Box>
      <ButtonSection
        title={intl.formatMessage({
          id: I18nKey.PRODUCT_SETTING_DIALOG_BOX_VECTOR,
        })}
        onApply={onApplyClick}
        onRestore={onRestore}
        onSaveDefault={onSaveDefault}
      />
      <Box sx={productSettingDialogBoxStyles.colorStepperBox}>
        <Wrapper
          title={intl.formatMessage({
            id: I18nKey.PRODUCT_SETTING_DIALOG_BOX_COLOR,
          })}
          wrapperStyle={productSettingDialogBoxStyles.contourColorPickerWrapper}
        >
          <ColorBox
            showColorPicker
            color={`${color}`}
            onChangeColor={onChangeColor}
          />
        </Wrapper>

        <Wrapper
          title={intl.formatMessage({
            id: I18nKey.PRODUCT_SETTING_DIALOG_BOX_STYLESIZE,
          })}
          wrapperStyle={productSettingDialogBoxStyles.contourColorPickerWrapper}
        >
          <Box
            sx={{ width: '100%', height: 25 }}
            display={'flex'}
            position="relative"
          >
            <Box sx={{ width: '50%', height: 25 }}>
              <FormControl sx={{ width: '95%' }}>
                <InputLabel
                  color="warning"
                  id="demo-simple-select-label"
                ></InputLabel>
                <Select
                  sx={{
                    backgroundColor: '#121212',
                    borderColor: 'black',
                    height: 25,
                  }}
                  id="vector-styles"
                  value={icon}
                  label="Age"
                  onChange={handleChange}
                  color={'warning'}
                  autoWidth
                >
                  {menuIcons(color, product).map(
                    (item: { icon: any }) => item.icon
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: 116, height: 25 }}>
              <Stepper
                value={size}
                onChange={onChange}
                precision={1}
                step={1}
                min={1}
                max={10}
              />
            </Box>
          </Box>
        </Wrapper>
      </Box>
    </Box>
  );
};

export default VectorSettings;
