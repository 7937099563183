import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { checkUserLogin } from '../../utils/auth';

const withRoute =
  (
    { isPrivate }: any // returns HOC
  ) =>
  (
    Component: React.ComponentType // returns HOC wrapped Component
  ) =>
  (props: any) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
      checkUserLogin()
        .then(() => setIsAuthenticated(true))
        .catch(() => setIsAuthenticated(false))
        .finally(() => setIsLoading(false));
    }, []);

    if (isLoading) {
      return (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      );
    }
    
    if (isPrivate) {
      if (!isAuthenticated) {
        return <Navigate replace to="/sign-in" />;
      }
    } else {
      if (isAuthenticated) {
        return <Navigate replace to="/" />;
      }
    }

    return <Component {...props} />;
  };

export const withPrivateRoute = withRoute({ isPrivate: true });
export const withRedirectRoute = withRoute({ isPrivate: false });
