export const WrapperStyles: any = {
  wrapperStyle: {
    height: '110px',
    width: '524px',
    backgroundColor: '#242424 ',
  },
  titleContainerStyle: {
    marginLeft: '11px',
    marginTop: '10.37px',
  },
  titleStyle: {
    color: '#d1d1d1 ',
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 'medium ',
    fontSize: '14px ',
    lineHeight: '17px',
    opacity: '1',
    display: 'inline',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  },
  toggleConatinerStyle: {
    float: 'right',
    display: ' inline',
    marginRight: '13.23px',
  },
  childContainerStyle: {
    marginTop: '1%',
    marginLeft: '11px',
    marginRight: '11px',
    marginBottom: '1%',
  },
};
