/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';
import { getUserAttributes } from '../../utils/auth';
import { MapHeader } from './MapHeader';
import { MapFooter } from './MapFooter';
import { MapMain } from './MapMain';
import { FeaturesContextProvider } from './FeaturesContext';
import { MapProvider } from './MapContext';
import ProductClient from '../../api/productAPIs';
import { LayoutProvider } from './LayoutContext';
import { OtherFeaturesContextProvider } from './OtherFeaturesContext';

const Map: React.FC = () => {
  const { seastarId, userName } = getUserAttributes();
  const [openSettings, setOpenSettings] = useState(false);
  const [openProductSetting, setOpenProductSetting] = useState(false);

  const getColorScale = async () => await ProductClient.getColorScale();

  const { refetch: colorScale } = useQuery<any>('Colors', getColorScale, {
    enabled: false,
    onSuccess: (data: any) => {
      _.forEach(data, (value: any, colorScale: any) => {
        localStorage.setItem(colorScale, JSON.stringify(value));
      });
    },
    onError: (error: any) => {
      console.log(error);
    },
  });

  useEffect(() => {
    colorScale();
  }, []);

  const onCloseSettings = () => {
    setOpenSettings(false);
  };

  const onSettingsOpen = () => {
    setOpenSettings(!openSettings);
  };

  const onCloseProductSettings = () => {
    setOpenProductSetting(false);
  };

  const onOpenProductSettings = () => {
    setOpenProductSetting(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  return (
    <MapProvider>
      <OtherFeaturesContextProvider>
        <FeaturesContextProvider>
          <LayoutProvider>
            <MapHeader
              userName={userName}
              seastarId={seastarId}
              onSettingsOpen={onSettingsOpen}
            />
            <MapMain
              openSettings={openSettings}
              onCloseSettings={onCloseSettings}
              openProductSetting={openProductSetting}
              onCloseProductSettings={onCloseProductSettings}
              onOpenProductSettings={onOpenProductSettings}
            />
            <MapFooter />
          </LayoutProvider>
        </FeaturesContextProvider>
      </OtherFeaturesContextProvider>
    </MapProvider>
  );
};

export default Map;
