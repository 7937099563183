import { Box } from '@mui/material';
import { floor } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { ColorBox } from '../../../../components/ColorBox/ColorBox';
import { SingleSlider } from '../../../../components/Slider/Slider';
import { Stepper } from '../../../../components/Stepper/Stepper';
import { Wrapper } from '../../../../components/Wrapper/Wrapper';
import { I18nKey } from '../../../../translations/I18nKey';
import { settingTabStyles } from './MasterCastSettingsStyles';

export const Settings: React.FC<any> = ({ settings, setSettings }) => {
  const intl = useIntl();
  const precision = 1;
  const setSelectedValue = (newValue: any) => {
    setSettings((prevState: any) => ({ ...prevState, opacity: newValue }));
  };
  const onChangeOpacity = (e: any, newValue: any) => {
    setSettings((prevState: any) => ({ ...prevState, opacity: newValue }));
  };

  const onChangeColor = (color: any) => {
    setSettings((prevState: any) => ({ ...prevState, color: color.hex }));
  };

  const onChangeWidth = (event: any) => {
    setSettings((prevState: any) => ({
      ...prevState,
      width: floor(event.target.value, precision),
    }));
  };

  return (
    <>
      <br />
      <Wrapper
        title={intl.formatMessage({
          id: I18nKey.MASTERCAST_SETTINGS_OPACITY,
        })}
        wrapperStyle={{
          width: '536px',
          height: '110px',
          backgroundColor: '#242424',
        }}
      >
        <SingleSlider
          onChangeHandler={onChangeOpacity}
          selectedValue={settings.opacity}
          setSelectedValue={setSelectedValue}
          unit="%"
        />
      </Wrapper>
      <br />
      <Box sx={settingTabStyles.colorStepperBox}>
        <Wrapper
          title={intl.formatMessage({
            id: I18nKey.PRODUCT_SETTING_DIALOG_BOX_COLOR,
          })}
          wrapperStyle={settingTabStyles.contourColorPickerWrapper}
        >
          <ColorBox
            color={settings.color}
            showColorPicker
            onChangeColor={onChangeColor}
          />
        </Wrapper>
        <Wrapper
          title={intl.formatMessage({
            id: I18nKey.MASTERCAST_SETTINGS_FILLWIDTH,
          })}
          wrapperStyle={{
            ...settingTabStyles.contourColorPickerWrapper,
            marginLeft: '16px',
          }}
        >
          <Box sx={settingTabStyles.stepperBox}>
            <Stepper
              value={settings.width}
              onChange={onChangeWidth}
              precision={1}
            />
          </Box>
        </Wrapper>
      </Box>
    </>
  );
};
