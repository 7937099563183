import React from 'react';
import totalBreach from '../../assets/icons/total_breach.svg';
import countryBreach from '../../assets/icons/country_breach.svg';
import most from '../../assets/icons/most.svg';
import DashboardImage from '../../assets/images/banner.png';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../translations/I18nKey';
import { useIUUDashboardStyles } from './IUUDashboardStyles';

export const Summary: React.FC<any> = ({
  totalBreaches,
  maxBreachesCountry,
  maxBreachesVessel,
}) => {
  const intl = useIntl();
  const IUUDashboardStyles = useIUUDashboardStyles();

  return (
    <Box
      className={IUUDashboardStyles.summaryBoxStyle}
      sx={{
        backgroundImage: 'url(' + DashboardImage + ')',
      }}
    >
      <Box className={IUUDashboardStyles.summaryContainerStyle}>
        <Box className={IUUDashboardStyles.summaryLeftContainerStyle}>
          <Box className={IUUDashboardStyles.summaryLeftBoxStyle}>
            <Box className={IUUDashboardStyles.summaryLeftElementStyle}>
              <img src={totalBreach} alt=""></img>
            </Box>
            <Box className={IUUDashboardStyles.summaryRightElementStyle}>
              <Box className={IUUDashboardStyles.summaryTopTextStyle}>
                {intl.formatMessage({
                  id: I18nKey.IUUDASHBOARD_TOTALBREACHES,
                })}
              </Box>
              <Box className={IUUDashboardStyles.summaryBottomTextStyle}>
                {totalBreaches}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={IUUDashboardStyles.summaryCenterContainerStyle}>
          <Box className={IUUDashboardStyles.summaryLeftBoxStyle}>
            <Box className={IUUDashboardStyles.summaryLeftElementStyle}>
              <img src={countryBreach} alt=""></img>
            </Box>
            <Box className={IUUDashboardStyles.summaryCenterBoxStyle}>
              <Box className={IUUDashboardStyles.summaryTopTextStyle}>
                {intl.formatMessage({
                  id: I18nKey.IUUDASHBOARD_MAXCOUNTRYBREACHES,
                })}
              </Box>
              <Box className={IUUDashboardStyles.summaryBottomTextStyle}>
                {maxBreachesCountry || '-'}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={IUUDashboardStyles.summaryRightContainerStyle}>
          <Box className={IUUDashboardStyles.summaryLeftBoxStyle}>
            <Box className={IUUDashboardStyles.summaryLeftElementStyle}>
              <img src={most} alt=""></img>
            </Box>
            <Box className={IUUDashboardStyles.summaryCenterElementStyle}>
              <Box className={IUUDashboardStyles.summaryTopTextStyle}>
                {intl.formatMessage({
                  id: I18nKey.IUUDASHBOARD_VESSELTYPEBREACHES,
                })}
              </Box>
              <Box className={IUUDashboardStyles.summaryBottomTextStyle}>
                {maxBreachesVessel || '-'}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
